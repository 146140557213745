import React, { useEffect, useState } from 'react';
import { updateDoc, addDoc, collection, serverTimestamp, query, where, getDocs } from 'firebase/firestore';
import { Tabs, Tab, Nav, Row, Col, Table, Form, Button, Accordion, Card, Badge, Alert } from 'react-bootstrap';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import { toast } from 'react-toastify';
import { db } from "config/firebase.js";


function VariantLevelRepricer({ product, variants, repricerData}) {

    return (
        <DisplayChannelPills product={product} variants={variants}/>
    )
}
export default VariantLevelRepricer;

const DisplayChannelPills = ({ product, variants, history }) => {
    return (
        <Tabs 
            variant='pills'
            defaultActiveKey="amazon"
            id="channelIntelTab"
            className='mb-3'
            mountOnEnter={true}
            >
        <Tab eventKey="amazon" title="Amazon" className='border p-3'>
            <AmazonRepricerSettings product={product} variants={variants} history={history}/>
        </Tab>
        <Tab eventKey="walmart" title="Walmart" className='border p-3' disabled>
            <p>
                Coming Soon
            </p>
        </Tab>
        <Tab eventKey="shopify" title="Shopify" className='border p-3' disabled>
            <p>
                Coming Soon
            </p>
        </Tab>
        </Tabs>
    )
}

const AmazonRepricerSettings = ({ product, variants, history}) => {

    const [fbaPricing, setFbaPricing] = useState(null);
    const [fbmPricing, setFbmPricing] = useState(null);

    useEffect(() => {
        if (!product) return;
        if (!variants) return;
        const fbaPricing = [];
        const fbmPricing = [];
        for (const variant of variants) {
            if (variant.repricerData) {
                if (variant.repricerData.fbaPricing) {
                    fbaPricing.push({
                        sku: variant.sku,
                        title: variant.title,
                        pricing: variant.repricerData.fbaPricing
                    })
                }
                if (variant.repricerData.merchantPricing) {
                    fbmPricing.push({
                        sku: variant.sku,
                        title: variant.title,
                        pricing: variant.repricerData.merchantPricing
                    })
                }
            }
        }
        setFbaPricing(fbaPricing);
        setFbmPricing(fbmPricing);
    }, [product, variants])

    return (
        <>
            <VariantRepricerTable variants={variants} product={product}/>
            {fbmPricing && fbmPricing.length ?
                <>
                    <Row className='mt-3'>
                        <Col>
                            <h6>Merchant Pricing (Landed Price)</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <PriceListTable pricing={fbmPricing} />
                        </Col>
                    </Row>
                </>
                
            : null}
            {fbaPricing && fbaPricing.length ?
                <>
                    <Row className='mt-3'>
                        <Col>
                            <h6>FBA Pricing (Landed Price)</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <PriceListTable pricing={fbaPricing} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <span className='text-muted fs--1'>Please note that cost to bulk ship items to FBA warehouse locations not included.
                            </span>
                        </Col>
                    </Row>
                </>
                
            : null}  
        </>
    )
}

const VariantRepricerTable = ({ variants, product }) => {

    const [repricerWorking, setRepricerWorking] = useState(false);
    const [numOpenJobs, setNumOpenJobs] = useState(0);

    async function checkForSettings(variants) {
        const running = await checkForActiveRepricerJobs(product.sku);
        setRepricerWorking(running);
        if (variants) {
            for (const variant of variants) {
                if (!variant.repricerSettings) {
                    // todo: use settings default
                    variant.repricerSettings = {
                        default: true,
                        markupFactor: 1.15,
                        targetMargin: 0.15,
                        discountRate: 0.05,
                        businessRate: 0.05
                    }
                }
            }
            return variants
        }
    }

    const [variantsSettings, setVariantsSettings] = useState(null)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (!variants) return;
        checkForSettings(variants).then((newVariants) => {

            // check if newVariants already has "master row"
            const masterRow = newVariants.find((variant) => variant.sku === "All Variants")
            if (!masterRow) {
                // add "master row" 
                newVariants.push({
                    sku: "All Variants",
                    title: "Override All Settings",
                    repricerSettings: {
                        default: 'override',
                        markupFactor: 1.15,
                        targetMargin: 0.15,
                        discountRate: 0.05,
                        businessRate: 0.05
                    }
                })
            }
            
            // ensure master row is at top
            newVariants.sort((a, b) => {
                if (a.sku === "All Variants") return -1;
                if (b.sku === "All Variants") return 1;
                return 0;
            })

            setVariantsSettings(newVariants)
            setLoading(false)
        })
    }, [variants])

    useEffect(() => {
        const interval = setInterval(async () => {
            const running = await checkForActiveRepricerJobs(product.sku);
            setRepricerWorking(running);
        }, 60000);
        return () => clearInterval(interval);
    }, [product.sku])

    async function handleFieldUpdate(e, variantRef, field, newValue, currentValue) {
        e.preventDefault();
        try {
            if (variantRef) {
                if (newValue === currentValue) return; // short circuit if no change
                await updateDoc(variantRef, {
                    [`repricerSettings.${field}`]: newValue,
                    [`repricerSettings.default`]: false
                })
                toast.success('Settings Updated', {theme: 'colored'})
            } else {
                console.log(field, variantsSettings)
                if (newValue === currentValue) return; // short circuit if no change
                const updatedVariants = variantsSettings.map((variant) => {
                    variant.repricerSettings[field] = newValue;
                    variant.repricerSettings.default = 'override';
                    return variant;
                })
                // iterate over variantsSettings and update each variant
                const updates = updatedVariants.map((variant) => {
                    if (variant.sku === 'All Variants') return;
                    return updateDoc(variant.vRef, {
                        [`repricerSettings.${field}`]: newValue,
                        [`repricerSettings.default`]: false,
                    })
                })
                setVariantsSettings(updatedVariants)
                toast.success('All Settings Updated', {theme: 'colored'})
            }
            
        } catch (error) {
            console.log(error)
        }

    }

    async function addNewRepricerJob(variant, product, targetPrice) {
        await addDoc(collection(db, 'repricerJobs'), {
            sku: variant.sku,
            parentSku: variant.parentSku,
            targetPrice: Number(targetPrice),
            barcodes: product.barcodes,
            variant,
            product,
            createdAt: serverTimestamp(),
            status: 'pending'
        })
        toast.success('Repricer Job Created', {theme: 'colored'})
    }

    async function checkForActiveRepricerJobs(parentSku) {
        const repricerJobsRef = collection(db, 'repricerJobs');
        const q1 = query(repricerJobsRef, 
            where('parentSku', '==', parentSku), 
            where('status', '==', 'pending')
        );
        const q2 = query(repricerJobsRef, 
            where('parentSku', '==', parentSku), 
            where('status', '==', 'processing')
        );
        const [pendingJobs, processingJobs] = await Promise.all([
            getDocs(q1),
            getDocs(q2)
        ])
        if (pendingJobs.size > 0 || processingJobs.size > 0) {
            setNumOpenJobs(pendingJobs.size + processingJobs.size)
            return true;
        } else {
            return false;
        }
    }

    const columns = [
        {
            accessor: 'sku',
            Header: 'SKU'
        },
        {
          accessor: 'title',
          Header: 'Name'
        },
        {
            accessor: 'repricerSettings.default',
            Header: 'Default',
            Cell: ({ cell }) => {
                let defaultBadgeBg, defaultBadgeText;
                const isDefault = cell.value;
                switch (isDefault) {
                    case true:
                        defaultBadgeBg = 'danger';
                        defaultBadgeText = 'Default';
                        break;
                    case false:
                        defaultBadgeBg = 'success';
                        defaultBadgeText = 'Custom';
                        break;
                    case 'override':
                        defaultBadgeBg = 'info';
                        defaultBadgeText = 'Override';
                        break;
                    default:
                        defaultBadgeBg = 'warning';
                        defaultBadgeText = 'Settings Not Available';
                        break;
                }

                return (
                    <Badge bg={defaultBadgeBg}>{defaultBadgeText}</Badge>
                )
            }
        },
        {
            accessor: 'repricerSettings.markupFactor',
            Header: 'Markup Factor',
            Cell: rowData => {
                const vRef = rowData.row.original.vRef;
                const repricerSettings = rowData.row.original.repricerSettings;
                let currentMarkupFactor = repricerSettings.markupFactor || 1.15; // TODO default
                return (
                    <Form.Control
                        type='number'
                        defaultValue={currentMarkupFactor}
                        onBlur={(e) => {
                            const newMarkupFactor = e.target.value;
                            handleFieldUpdate(e, vRef, 'markupFactor', newMarkupFactor, currentMarkupFactor)
                            currentMarkupFactor = newMarkupFactor;
                        }}
                    />
                )
            }
        },
        {
            accessor: 'repricerSettings.targetMargin',
            Header: 'Target Margin',
            Cell: rowData => {
                const vRef = rowData.row.original.vRef;
                const repricerSettings = rowData.row.original.repricerSettings;
                const currentTargetMargin = repricerSettings.targetMargin || 0.15; // TODO default
                return (
                    <Form.Control
                        type='number'
                        defaultValue={currentTargetMargin}
                        onBlur={(e) => {
                            const newTargetMargin = e.target.value;
                            handleFieldUpdate(e, vRef, 'targetMargin', newTargetMargin, currentTargetMargin)
                        }}
                    />
                )
            }
        },
        {
            accessor: 'repricerSettings.discountRate',
            Header: 'Discount Rate',
            Cell: rowData => {
                const vRef = rowData.row.original.vRef;
                const repricerSettings = rowData.row.original.repricerSettings;
                const currentMarkupFactor = repricerSettings.discountRate || 0.00; // TODO default
                return (
                    <Form.Control
                        type='number'
                        defaultValue={currentMarkupFactor}
                        onBlur={(e) => {
                            const newMarkupFactor = e.target.value;
                            handleFieldUpdate(e, vRef, 'discountRate', newMarkupFactor, currentMarkupFactor)
                        }}
                    />
                )
            }
        },
        {
            accessor: 'repricerSettings.businessRate',
            Header: 'Business Discount',
            Cell: rowData => {
                const vRef = rowData.row.original.vRef;
                const repricerSettings = rowData.row.original.repricerSettings;
                const currentMarkupFactor = repricerSettings.businessRate || 0.00; // TODO default
                return (
                    <Form.Control
                        type='number'
                        defaultValue={currentMarkupFactor}
                        onBlur={(e) => {
                            const newMarkupFactor = e.target.value;
                            handleFieldUpdate(e, vRef, 'businessRate', newMarkupFactor, currentMarkupFactor)
                        }}
                    />
                )
            }
        },
        {
            accessor: 'repricerSettings',
            Header: "Actions",
            Cell: (rowData) => {
                const variant = rowData.row.original;
                
                async function createNewRepricerRequest(e) {

                    e.preventDefault();

                    if (variant.sku === 'All Variants') {
                        // reprice all variants
                        const results = await Promise.all(variants.map(async (variant) => {
                            if (variant.sku === 'All Variants') return;
                            const targetMargin = variant.repricerSettings.targetMargin || '1.25'; // todo use settings default
                            const targetPrice = Number(
                                (product.cost * variant.productFactor) + ((product.cost * variant.productFactor) * targetMargin)
                            ).toFixed(2);
                            try {
                                await addNewRepricerJob(variant, product, targetPrice)
                            } catch (error) {
                                console.log(error)
                            }
                        }))

                    } else {
                        // single variant repricer request
                        const targetMargin = variant.targetMargin || '1.25'; // todo use settings default
                        const targetPrice = Number(
                            (product.cost * variant.productFactor) + ((product.cost * variant.productFactor) * targetMargin)
                        ).toFixed(2);
                        try {
                            
                            addNewRepricerJob(variant, product, targetPrice)
                        } catch (error) {
                            console.log(error)
                        }
                    }
                    
                }

                return (
                    <>
                        <Button
                            variant='falcon-primary'
                            onClick={(e) => createNewRepricerRequest(e)}
                            size='sm'
                        >Reprice</Button>
                    </>
                )
            }
        }
        
      ];
      
    function RepricerTable() {
    
        return (
            <AdvanceTableWrapper
            columns={columns}
            data={variantsSettings}
            sortable
            >
            <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                bordered: true,
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
                }}
            />
            </AdvanceTableWrapper>
        )
    }

    return (
        !loading ?
            <>
                {repricerWorking ?
                    <Alert variant='warning'>
                        <p>
                            Repricer is currently working on this product. Please wait for repricer to finish before making any changes.
                        </p>
                        <p>
                            There are currently {numOpenJobs} open jobs.
                        </p>
                    </Alert>
                :   null
                }
                <RepricerTable />
            </>
        :
        <p>
            No Variants
        </p>
    )
}

const PriceListTable = ({ pricing }) => {

    const columns = [
        {
            accessor: 'sku',
            Header: 'SKU'
        },
        {
            accessor: 'title',
            Header: 'Name'
        },
        {
            accessor: 'pricing.minimumPrice',
            Header: 'Minimum'
        },
        {
            accessor: 'pricing.targetMarginPrice',
            Header: 'Target Margin'
        },
        {
            accessor: 'pricing.preferredPrice',
            Header: 'Preferred'
        },
        {
            accessor: 'pricing.maximumPrice',
            Header: 'Maximum'
        },
        {
            accessor: 'pricing.businessPrice',
            Header: 'Business'
        },
    ]

    function PriceTable() {
        return (
            <AdvanceTableWrapper
            columns={columns}
            data={pricing}
            sortable
            >
            <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                bordered: true,
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
                }}
            />
            </AdvanceTableWrapper>
        )
    }

    return (
        <PriceTable />
    )
        
}
