import React, { useState, useEffect, useRef } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useNavigate } from 'react-router-dom';
import { auth, db } from 'config/firebase';
import { query, collection, limit, where, getDocs, setDoc, doc, deleteDoc } from 'firebase/firestore';
import {  Row, Col, Modal, Button, Form} from 'react-bootstrap';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import ActionButton from 'components/common/ActionButton';
import { toast } from 'react-toastify';
import JsBarcode from 'jsbarcode';
import { useReactToPrint } from 'react-to-print';

const ManualSlots = (props) => {

    const imgLabelRef = useRef(null);
    const handlePrint = useReactToPrint({
      content: () => imgLabelRef.current,
    });
    const [user, authLoading] = useAuthState(auth);
    const navigate = useNavigate();
    const [modalData, setModalData] = useState('');
    const [values, collectionLoading, error, snapshot] = useCollectionData( 
        query(
            collection(db, "locationCountData"), 
            where("locationId", "==", props.locationId)
            ), {
        snapshotListenOptions: { includeMetadataChanges: true },
    });

    const [editModalShow, setEditModalShow] = useState(false);

    const columns = [
        {
            accessor: 'row',
            Header: 'Row',
            Cell: rowData => {
              const { row, section, shelf } = rowData.row.original;

              return (
                <div className='mt-1 mb-5'>
                  
                    {row}
                  
                </div>
              );
            }
        },
        {
          accessor: 'section',
          Header: 'Section',
          Cell: rowData => {
            const { row, section, shelf } = rowData.row.original;

            return (
              <div className='mt-1 mb-5'>
                
                  {section}
                
              </div>
            );
          }
          },
          {
            accessor: 'shelf',
            Header: 'Shelf',
            Cell: rowData => {
              const { row, section, shelf } = rowData.row.original;

              return (
                <div className='mt-1 mb-5'>
                  
                    {shelf}
                  
                </div>
              );
            }
        },
        {
            accessor: 'slot',
            Header: 'Slot',
            Cell: rowData => {
              const { slot } = rowData.row.original;
              
              return (
                <span>
                  {slot}
                </span>
              );
            }
        },
        {
          accessor: 'sku',
          Header: 'SKU',
          Cell: rowData => {
            const { sku, } = rowData.row.original;
           
            return (
              <span>{sku}</span>
            )
          }
        },
        {
          accessor: 'name',
          Header: 'Name',
          Cell: rowData => {
            const { name } = rowData.row.original;
           
            return (
              <div className='w-25'>{name}</div>
            )
          }
        },
        {
          accessor: 'count',
          Header: 'Count',
          Cell: rowData => {
            const { count } = rowData.row.original;

            return (
              <div className='mw-100'>{count}</div>
            )
          }
        },
        {
          accessor: '',
          Header: 'Actions',
          Cell: rowData => {
            const { sku, slot } = rowData.row.original;
            return (
              <div className='mw-100'>
                <ActionButton icon="edit" title="Edit" variant="action" className="p-0 me-2" onClick={() => displayModal(sku)} />
                <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-0" onClick={() => deleteSlot(slot)}/>
              </div>
            )
          }
        }
      ];

    async function deleteSlot(slot) {
      try {

        const q = query(
          collection(db, "locationCountData"),
          where("slot", "==", slot),
          limit(1)
        )
        const snapshot = await getDocs(q);
        if (!snapshot.empty) {
          // found the slot
          snapshot.forEach((docRef) => {
            deleteDoc( doc(db, "locationCountData", docRef.id) )
          })
          toast.success(`${slot} has been deleted`, {
            theme: 'colored'
          });

        }

      } catch (error) {
        console.log(error)
      }
    }

    async function displayModal(sku) {
      try {
        const q = query(
          collection(db, "locationCountData"),
          where("sku", "==", sku),
          limit(1)
        )
        const snapshot = await getDocs(q);
        let slotData;
        if (!snapshot.empty) snapshot.forEach((doc) => slotData = {id: doc.id, ...doc.data()});
        setModalData(slotData);
        setEditModalShow(true);
        generateLabel(slotData.name, slotData.sku)
      }
      catch (error) {
        console.log(error)
      }
    }

    function processModalChanges() {

      const name = document.getElementById('modalProductName').value || document.getElementById('modalProductName').placeholder;
      const row = document.getElementById('modalRow').value || document.getElementById('modalRow').placeholder;
      const section = document.getElementById('modalSection').value || document.getElementById('modalSection').placeholder;
      const shelf = document.getElementById('modalShelf').value || document.getElementById('modalShelf').placeholder;
      const slot = document.getElementById('modalSlot').value || document.getElementById('modalSlot').placeholder;
      const count = document.getElementById('modalCount').value || document.getElementById('modalCount').placeholder;
      const sku = document.getElementById('modalSKU').value || document.getElementById('modalSKU').placeholder;
      const parent = document.getElementById('modalParent').value || document.getElementById('modalParent').placeholder;
      const factor = document.getElementById('modalFactor').value || document.getElementById('modalFactor').placeholder;
      const notes = document.getElementById('modalNotes').value;
      const slotReference = doc(db, "locationCountData", modalData.id);
      //const reference = modalData.id;

      setDoc(slotReference, {
        count: count,
        factor: factor,
        lastUpdate: new Date(),
        name: name,
        note: notes,
        parentSku: parent,
        row: row,
        section: section,
        shelf: shelf,
        slot: slot,
        sku: sku
      }, {merge: true}).then(() => {
        setModalData('');
        setEditModalShow(false);
      })


      
    }


    function generateLabel(Name, Sku) {
      JsBarcode("#barcode", Sku, {
        width: 6,
        height: 100,
        fontSize: 25,
        margin: 10,
        lineColor: "#111111",
        textAlign: "center",
        font: "monospace",
        text: `${Sku} - ${Name}`,
      })
    }

    useEffect(() => {
        if (authLoading) return;
        if (!user) return navigate('/');
        
      }, [user, authLoading, navigate]);

      return (
        <>
            {!collectionLoading ?

                <div className="m-3">

                    <AdvanceTableWrapper
                        columns={columns}
                        data={values}
                        sortable
                        pagination
                        setPageSize
                    >
                
                    <div className="m-3">

                        <Row className="flex-end-center mb-3">
                            <Col>
                                <AdvanceTableSearchBox table/>
                            </Col>
                        </Row>
                        <Row className="flex-end-center mb-3">
                            <Col>
                                <AdvanceTableFooter
                                    rowCount={values?.length}
                                    table
                                    rowInfo
                                    navButtons
                                    rowsPerPageSelection
                                />
                            </Col>
                        </Row>
                        

                    </div>
                
                    <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                        bordered: false,
                        striped: true,
                        className: 'fs--1 mb-0 overflow-hidden'
                    }}
                    />

                    <div className="m-3">

                        <AdvanceTablePagination table />
                    
                    </div>
            
                    </AdvanceTableWrapper>

                    <Modal
                      show={editModalShow}
                      onHide={() => setEditModalShow(false)}
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">{modalData.name}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                      <Form onSubmit={processModalChanges}>
                        <Form.Group className="mb-3">
                          <Form.Label>Product Name</Form.Label>
                          <Form.Control type="text" id="modalProductName" placeholder={modalData.name} />
                        </Form.Group>
                      </Form>
                      <Row>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>Row</Form.Label>
                            <Form.Control type="text" id="modalRow" placeholder={modalData.row} />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>Section</Form.Label>
                            <Form.Control type="text" id="modalSection" placeholder={modalData.section} />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>Shelf</Form.Label>
                            <Form.Control type="text" id="modalShelf" placeholder={modalData.shelf} />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>Slot</Form.Label>
                            <Form.Control type="text" id="modalSlot" placeholder={modalData.slot} />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>Count</Form.Label>
                            <Form.Control type="text" id="modalCount" placeholder={modalData.count} />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>Product SKU</Form.Label>
                            <Form.Control type="text" id="modalSKU" placeholder={modalData.sku} />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>Parent SKU</Form.Label>
                            <Form.Control type="text" id="modalParent" placeholder={modalData.parentSku} />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>Product Factor</Form.Label>
                            <Form.Control type="text" id="modalFactor" placeholder={modalData.factor} />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>Notes</Form.Label>
                            <Form.Control as="textarea" rows={3} id="modalNotes" placeholder={modalData.notes} />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className='mb-3'>
                        <Col><Button onClick={handlePrint}>Print Label</Button></Col>
                      </Row>
                      <Row>
                        
                        <Col>
                          <img className='border' id="barcode" ref={imgLabelRef}></img>
                        </Col>
                      </Row>
                      </Modal.Body>
                      <Modal.Footer>
                        <Row>
                          <Col xs={6}>
                            <Button className={'mt-4'} variant="falcon-danger" onClick={() => setEditModalShow(false)}>Close</Button>
                          </Col>
                          <Col>
                            <Button variant="success" className="mt-4 float-end" onClick={() => processModalChanges()}>Save</Button>
                          </Col>
                        </Row>
                      </Modal.Footer>
                    </Modal>
                </div>
            :
                <h1>Loading...</h1>
            }
        </>
      )
      

}

export default ManualSlots;