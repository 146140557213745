import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';

import PageHeader from 'components/common/PageHeader';
import { Card, Col, Row, Form, Button, Image, Modal, Tabs, Tab, Spinner, InputGroup, FormControl } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/merlin/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/merlin/advance-table/AdvanceTableFooter';
import IconButton from 'components/common/IconButton';
import SoftBadge from 'components/common/SoftBadge';
import { toast } from 'react-toastify';
import FalconCloseButton from 'components/common/FalconCloseButton';

import { db } from 'config/firebase.js';
import { useCollection } from 'react-firebase-hooks/firestore';
import { collection, query, orderBy, collectionGroup, getDocs, where, getDoc, addDoc, deleteDoc, serverTimestamp, updateDoc, onSnapshot, doc } from 'firebase/firestore';

import useRoleData from 'hooks/useRoleData.js';
import useSearchData from 'hooks/useSearchData';

import * as fuzz from 'fuzzball';
import { use } from 'echarts';

const Bundles = () => {

    const navigate = useNavigate();
    const { user, authLoading }= useContext(FirebaseAuthContext);
    const [viewBundleModalShow, setViewBundleModalShow] = useState(false);
    const [roleData, orgData, userLoading, userError] = useRoleData(user?.uid);
    const [choiceData, choicesLoading, choicesError, excludeDisc, message] = useSearchData(/** Data Expired */ false, orgData);
    const [choiceLibrary, setChoiceLibrary] = useState([]);
    const [bundleActionChoice, setBundleActionChoice] = useState('');
    const [editModalShow, setEditModalShow] = useState(false);
    const [activeEditBundle, setActiveEditBundle] = useState({});

    useEffect(() => {
        if (choicesLoading) return;
        if (choicesError) return;
        if (choiceData?.length > 0) {
          setChoiceLibrary(choiceData);
        }
      }, [choiceData, choicesLoading, choicesError])

    const [bundles, bundlesLoading, bundlesError] = useCollection (
        query ( 
            collection(db, 'productBundles'),
            orderBy('timestamp.modified', 'desc')
        ), {
        snapshotListenOptions: { includeMetadataChanges: true },
    });

    const [bundlesData, setBundlesData] = useState([]);

    useEffect(() => {
        if (!bundlesLoading && !bundlesError && bundles) {
            const docs = bundles.docs.map(doc => {
                return {
                    id: doc.id,
                    docRef: doc.ref,
                    ...doc.data()
                }
            })
            setBundlesData(docs);
        } else {
            setBundlesData([]);
            if (bundlesError) {
                console.log(bundlesError);
            }
        }
    
    }, [bundles, bundlesLoading])

    const bundleDataColumns = [
        {
            accessor: 'primaryImageUrl',
            Header: 'Primary Image',
            Cell: rowData => {
                const { primaryImageUrl } = rowData.row.original
                return( 
                    <Image src={primaryImageUrl ? primaryImageUrl : '/assets/merlin/missing_image.png'} width={50} height={50} roundedCircle />
                )
            }
        },
        {
            accessor: 'sku',
            Header: 'SKU',
            cellProps:{
              className:'fw-medium'
            },
            Cell: rowData => {
                const { sku } = rowData.row.original
                return( 
                    <div className='link-primary' onClick={() => {
                        setEditModalShow(true);
                        setActiveEditBundle(rowData.row.original);
                    }}>
                        {sku}
                    </div>
                )
            }
        },
        {
          accessor: 'name',
          Header: 'Name',
          Cell: rowData => {
                const { name } = rowData.row.original
                return( 
                    <div className='d-block text-truncate' style={{width: '250px'}}>
                        {name}
                    </div>
                )
            }
        },
        {
            accessor: 'calculatedCost',
            Header: 'Cost',
            cellProps:{
              className:'fw-medium'
            },
            Cell: rowData => {
                const { calculatedCost } = rowData.row.original
                return( 
                    <div>
                        ${Number(calculatedCost).toFixed(2)}
                    </div>
                )
            }
        },
        {
            accessor: 'includedVariants',
            Header: 'Products In Bundle',
            cellProps:{
              className:'fw-medium'
            },
            Cell: rowData => {
                const { includedVariants, sku } = rowData.row.original

                const variantList = includedVariants.map(variant => {
                    return (
                        {
                            sku: variant.sku,
                            title: variant.title,
                            parentRef: variant.parentRef,
                            parentUrl: `topicz/products/${variant.parentRef.id}`
                        }
                    )
                })
                // includedVariants is an array, cycle through array and display the SKUs of each variant
                return( 
                    <>
                        <div>
                            {includedVariants.length > 1 ? <> {includedVariants.length} Products</> : <> {includedVariants.length} Product</>}
                        </div>
                        <div>
                            {variantList.map((variant, index) => {
                                return (
                                    <div key={`${sku}-${variant.sku}-${index}`} className='d-block text-truncate' style={{width: '150px'}}>
                                        <a href={variant.parentUrl}>{variant.title}</a>
                                    </div>
                                )
                            })}
                        </div>
                    </>
                )
            }
        },
        {
            accessor: 'tags',
            Header: 'Tags',
            Cell: rowData => {
                const { tags } = rowData.row.original

                // iterate over tags array and display each tag
                return(
                    <>
                        {tags.map(tag => {
                            return (
                                <span  className='me-1' key={tag}>
                                    <SoftBadge variant='secondary' className='me-2'>{tag}</SoftBadge>
                                </span>
                            )
                        })}
                    </>
                )
            }
        },

    ];

    function handleBundlesAction(e, data) {
        e.preventDefault();
        const selected = data.picked;
        const table = data.tableData;
        switch (bundleActionChoice) {
            case 'delete':
                console.log('delete');
                Object.keys(selected).forEach(async (id) => {
                    console.log('delete', id);
                    const data = table[id]?.original;
                    deleteDoc(data.docRef).then(() => {
                        toast.success(`Bundle ${data.name} deleted successfully`, { theme: 'colored'});
                    }).catch((error) => {
                        toast.error(`Error deleting bundle ${data.name}`, { theme: 'colored'});
                        console.log(error);
                    })
                })  
                break;
            case 'edit':
                console.log('edit');
                break;
            default:
                console.log('No action selected');
                break;
        }

    }

    function EditModal({ showModal, setShowModal, bundle }) {
        //const [show, setShow] = useState(false);

        const [parentResults, setParentResults] = useState([]);
        const [showParentResults, setShowParentResults] = useState(false);
        const [selectedParent, setSelectedParent] = useState({});
        const [variantResults, setVariantResults] = useState([]);
        const [showVariantResults, setShowVariantResults] = useState(false);
        const [newVariants, setNewVariants] = useState([]);

        useEffect(() => {
            if (parentResults.length > 0) {
                setShowParentResults(true);
            } else {
                setShowParentResults(false);
            }
        }, [parentResults])

        useEffect(() => {
            if (selectedParent.sku) {
                setShowParentResults(false);
                collectVariants(selectedParent.sku).then((variants) => {
                    setVariantResults(variants);
                    setShowVariantResults(true);
                })
            }
        }, [selectedParent])
      
        const handleClose = () => {
            setShowModal(false);
            setActiveEditBundle({});
            setNewVariants([]);
            setParentResults([]);
            setSelectedParent({});
            setShowParentResults(false);
            setVariantResults([]);
            setShowVariantResults(false);
        }

        async function searchProducts(searchTerm) {
            try {
              const options = {
                scorer: fuzz.partial_ratio,
                processor: (choice) => choice.proc_sorted,
                limit: 500,
                cutoff: 90,
                unsorted: false,
                full_process: false,
              }
              const lib = choiceLibrary.filter((item) => item !== undefined);
              const results = fuzz.extract(searchTerm, /** fuzzyChoices */ lib, options)
              return results;
            } catch (error) {
              console.log(error);
            }
        }

        async function handleNewProductAdd(searchTerm) {
            const parentResults = await searchProducts(searchTerm);
            const flattenedParentResults = parentResults.map((result) => {
                return result[0]
            })
            setParentResults(flattenedParentResults);
            setShowParentResults(true);
        }

        async function collectVariants(masterSku) {
            const q = query(
                collectionGroup(db, 'variants'),
                where('parentSku', '==', masterSku)
            )
            const docs = await getDocs(q);
            if (!docs.empty) {
                const variants = [];
                docs.forEach(doc => {
                    let temp = doc.data();
                    temp.ref = doc.ref;
                    variants.push(temp)
                })
                return variants;
            } else {    
                return [];
            }
        }

        async function handleNewVariantAdd(variant) {
            if (!variant) return;
            let cost = Number(selectedParent.cost) * Number(variant.productFactor);
            variant.cost = cost;
            setNewVariants((newVariants) => [...newVariants, variant]);
            setParentResults([]);
            setSelectedParent({});
            setShowParentResults(false);
            setVariantResults([]);
            setShowVariantResults(false);
            const el = document.getElementById('searchProductsInput');
            el.value = '';

        }

        async function handleBundleEditSave() {
            const bundleName = document.getElementById('formBundleNameControl').value;
            const bundleSku = document.getElementById('formBundleSkuControl').value;
            const bundleTags = document.getElementById('formBundleTagsControl').value;
            const bundleDescription = document.getElementById('formBundleDescriptionControl').value;
            const includedVariants = [...bundle.includedVariants, ...newVariants];
            const calcCost = includedVariants.reduce((acc, variant) => {
                return acc + Number(variant.cost);
            }, 0)
            const listOfSkus = includedVariants.map(variant => {
                return variant.sku;
            })
            // round up the calcCost to hundredths place
            const roundedCost = Math.round(calcCost * 100) / 100;
            // convert the tags string to an array thats trimmed and lowercased
            const bundleTagsArray = bundleTags.split(',').map(tag => {
                return tag.trim().toLowerCase();
            })
            const bundleData = {
                name: bundleName,
                sku: bundleSku,
                tags: bundleTagsArray,
                description: bundleDescription,
                includedVariants: includedVariants,
                calculatedCost: Number(roundedCost).toFixed(2),
                includedSkus: listOfSkus,
                "timestamp.modified": serverTimestamp()
            }
            updateDoc(bundle.docRef, bundleData).then(() => {
                toast.success(`Bundle ${bundleName} updated successfully`, { theme: 'colored'});
                handleClose();
            }).catch((error) => {
                toast.error(`Error updating bundle ${bundleName}`, { theme: 'colored'});
                console.log(error);
            })
        }
        return (
          <>
            <Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false} size='lg' fullscreen={true}>
              <Modal.Header>
                <Modal.Title>Edit Bundle</Modal.Title>
                <FalconCloseButton onClick={handleClose}/>
              </Modal.Header>
              <Modal.Body>
                {/** Display the Bundle Details in a Form */}
                {bundles !== {} ?
                <Form>
                    <Form.Group className="mb-3" >
                        <Form.Label>Bundle Name</Form.Label>
                        <Form.Control id="formBundleNameControl" type="text" placeholder="Enter Bundle Name" defaultValue={bundle.name} />
                        <Form.Text className="text-muted">
                        Enter the name of the bundle
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label>Bundle SKU</Form.Label>
                        <Form.Control id="formBundleSkuControl" type="text" placeholder="Enter Bundle SKU" defaultValue={bundle.sku} />
                        <Form.Text className="text-muted">
                        Enter the SKU of the bundle
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label>Bundle Tags</Form.Label>
                        <Form.Control id={'formBundleTagsControl'} type="text" placeholder="Enter Bundle Tags" defaultValue={bundle.tags} />
                        <Form.Text className="text-muted">
                        Enter the tags of the bundle separated by commas
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        {/** Products are an array of variants, display them in a table with a delete icon and at the end have a + sign to add an additional variant by SKU */}
                        <Form.Label>Products in Bundle</Form.Label>
                        {/** Display the products in a table */
                            bundle?.includedVariants?.map(variant => {
                                return (
                                    <div key={variant.sku}>
                                        <Row className='mb-2'>
                                            <Col sm={2}>
                                                {variant.sku}
                                            </Col>
                                            <Col sm={6}>
                                                <a href={`topicz/products/${variant.parentRef.id}`}>{variant.title}</a>
                                            </Col>
                                            <Col sm={2}>
                                                ${Number(variant.cost).toFixed(2)}
                                            </Col>
                                            <Col sm={2}>
                                                <IconButton
                                                    variant="falcon-default"
                                                    size="sm"
                                                    icon="times"
                                                    transform="shrink-3"
                                                    className='me-2'
                                                    onClick={() => {
                                                        console.log('delete variant')
                                                    }
                                                }>
                                                </IconButton>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            })
                        }
                        {newVariants.length > 0 ?
                            newVariants.map(variant => {
                                return (
                                    <div key={variant.sku}>
                                        <Row className='mb-2'>
                                            <Col sm={2}>
                                                {variant.sku}
                                            </Col>
                                            <Col sm={6}>
                                                <a href={`topicz/products/${variant.parentRef?.id}`}>{variant.title}</a>
                                            </Col>
                                            <Col sm={2}>
                                                ${Number(variant.cost).toFixed(2)}
                                            </Col>
                                            <Col sm={2}>
                                                <IconButton
                                                    variant="falcon-default"
                                                    size="sm"
                                                    icon="times"
                                                    transform="shrink-3"
                                                    className='me-2'
                                                    onClick={() => {
                                                        console.log('delete variant')
                                                    }
                                                }>
                                                </IconButton>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            })
                        : null}
                        {/** Add a row to add a new variant by SKU */}
                        <Row className='mt-2 mb-2'>
                            <Col>
                                
                                <Form.Control 
                                    type="text" 
                                    placeholder="Search for Product to Add"
                                    id="searchProductsInput"
                                    onChange={(e) => handleNewProductAdd(e.target.value)}
                                />
                                
                                {showParentResults ?
                                    <div className='mt-2'>
                                        <Row className='mb-3'><Col><span className='fw-bold'>Product Results</span></Col></Row>
                                        {parentResults.map(result => {
                                            return (
                                                <div key={result.sku}>
                                                    <Row className='mb-2 border-bottom'>
                                                        <Col>
                                                            <Image src={result.image} width={50} height={50} roundedCircle />
                                                        </Col>
                                                        <Col sm={2}>
                                                            {result.sku} - ${Number(result.cost).toFixed(2)}
                                                        </Col>
                                                        <Col sm={6}>
                                                            <a href={`topicz/products/${result.parentRef?.id}`}>{result.name}</a>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <IconButton
                                                                variant="falcon-default"
                                                                size="sm"
                                                                icon="plus"
                                                                transform="shrink-3"
                                                                className='me-2'
                                                                onClick={() => {
                                                                    setSelectedParent(result);
                                                                }
                                                            }>
                                                            </IconButton>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                        })}   
                                    </div>
                                : null}

                                {showVariantResults ?
                                    <div className='mt-2'>
                                        <Row className='mb-2'><Col><span className='fw-bold'>Variant Results</span></Col></Row>
                                        <Row className='mb-3 border-bottom'>
                                        <Col sm={2}>Sku</Col>
                                        <Col sm={2}>Product Factor</Col>
                                        <Col sm={6}>Name</Col>
                                        <Col sm={2}>Add to Bundle</Col>
                                        </Row>
                                        {variantResults.map(result => {
                                            
                                            return (
                                                <div key={result.sku}>
                                                    <Row className='mb-2'>
                                                        <Col sm={2}>
                                                            <span>{result.sku}</span>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <span>{result.productFactor}</span>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <span>{result.title}</span>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <IconButton
                                                                variant="falcon-default"
                                                                size="sm"
                                                                icon="plus"
                                                                transform="shrink-3"
                                                                className='me-2'
                                                                onClick={() => {
                                                                    handleNewVariantAdd(result);
                                                                }
                                                            }>
                                                            </IconButton>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                        })}   
                                    </div>
                                : null}
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label>Bundle Description</Form.Label>
                        <Form.Control id={"formBundleDescriptionControl"} type="text" placeholder="Enter Bundle Description" defaultValue={bundle.description} />
                        <Form.Text className="text-muted">
                        Enter the description of the bundle
                        </Form.Text>
                    </Form.Group>
                </Form>
                : null}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleBundleEditSave}>Save Bundle</Button>
              </Modal.Footer>
            </Modal>
          </>
        );
    } 
      
    function BundlesAction(table){
        const { selectedRowIds, page } = table;
        return (
            <Row className="flex-between-center mb-3">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                {
                    Object.keys(selectedRowIds).length > 0 ?
                    'You have selected ' + Object.keys(selectedRowIds).length + ' rows' 
                    :
                    ''
                }
                </h5>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                {Object.keys(selectedRowIds).length > 0 ? (
                <div className="d-flex">
                        <Form.Select size="sm" aria-label="Bulk actions" value={bundleActionChoice} onChange={(e) => setBundleActionChoice(e.target.value)}>
                            <option>Bulk Actions</option>
                            <option value="delete">Delete</option>
                        </Form.Select>
                        <Button
                        type="submit"
                        variant="falcon-default"
                        size="sm"
                        className="ms-2"
                        onClick={(e) => handleBundlesAction(e, {picked: selectedRowIds, tableData: page})}
                        >
                        Apply
                        </Button>
                   
                </div>
                ) : (
                    <div id="orders-actions">
                    {!authLoading && <SaveBundleWrapper uid={user?.uid} /> }
                    </div>
                )}
            </Col>
            </Row>
        );
    };
      
    function BundlesAdvanceTable() {
    
        return(
            <AdvanceTableWrapper
            columns={bundleDataColumns}
            data={bundlesData}
            sortable
            pagination
            perPage={500}
            selection
            selectionColumnWidth={30}
            >
            <BundlesAction table/>
            <Row className="flex-end-center mb-3">
                <Col>
                <AdvanceTableSearchBox table/>
                </Col>
            </Row>
            <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
                }}
            />
            <div className="fs--1 mt-3">
                <AdvanceTableFooter
                    rowCount={bundlesData.length}
                    table
                    rowInfo
                    navButtons
                    rowsPerPageSelection
                    />
            </div>
            </AdvanceTableWrapper>
        )
    }

    function NewBundleModal({ heading, selectedBundle, uid, showModal, setShowModal, setSaveStatus }) {
    
        const [newBundleName, setNewBundleName] = useState(selectedBundle?.name || '');
        const randomSku = Math.floor(10000000 + Math.random() * 90000000);  
        const [newBundleSku, setNewBundleSku] = useState(selectedBundle?.sku || `B-${randomSku}`);
        const [searchInputValue, setSearchInputValue] = useState('');
        const [resultItems, setResultItems] = useState([]);
        const [bundledProducts, setBundledProducts] = useState(selectedBundle?.includedVariants || []);
        const [selectedSearchedProduct, setSelectedSearchedProduct] = useState('');
        const [tags, setTags] = useState(selectedBundle?.tags || []);
        const [key, setKey] = useState('home');
        const [bundlePrimaryImage, setBundlePrimaryImage] = useState(selectedBundle?.primaryImageUrl || '');
        const [newBundleDescription, setNewBundleDescription] = useState(selectedBundle?.description || '');
        const [bundleCost, setBundleCost] = useState(Number(selectedBundle?.calculatedCost).toFixed(2) || 0);
        const [finalizeStatus, setFinalizeStatus] = useState(false);
        const [showResults, setShowResults] = useState(false);
        const [tempBundleData, setTempBundleData] = useState({});
        const [lastSaveData, setLastSaveData] = useState({});

        useEffect(() => {
            if (searchInputValue.length > 2) {
                searchProducts(searchInputValue).then((results) => {
                  const resultItems = [];
                  results.map((result) => {
                    const resultData = result[0];
                    resultItems.push(resultData);
                   });
                  setResultItems(resultItems);         
                  //setResults(results);  
                })
            } else {
                setResultItems([]);
                //setResults([]);
            }
        }, [searchInputValue])

        const cancelAndClear = (e) => {
            e.preventDefault();
            saveProgress(uid);
            setKey('home');
            setNewBundleName('');
            setSearchInputValue('');
            setShowResults([]);
            setResultItems([]);
            setBundledProducts([]);
            setSelectedSearchedProduct();
            setSaveStatus('INITIAL');
            setShowModal(false);

        }

        async function saveProgress(uid) {
            const bundleData = {
                uid: uid,
                name: newBundleName,
                sku: newBundleSku,
                tags: tags,
                description: newBundleDescription,
                primaryImageUrl: bundlePrimaryImage,
                includedVariants: bundledProducts,
                calculatedCost: Number(bundleCost).toFixed(2),
                includedSkus: bundledProducts.map(variant => {
                    return variant.sku;
                }),
            }

            if (!bundleData.name) return;

            // check each field in bundleData for undefined and replace with 'N/A' 
            Object.keys(bundleData).forEach((key) => {
                if (bundleData[key] === undefined) {
                    bundleData[key] = 'N/A';
                }
            })

            try {
                if (selectedBundle?.id || tempBundleData?.id) {
                    const bundleRef = selectedBundle?.ref || tempBundleData?.ref;
                    await updateDoc(bundleRef, bundleData);
                    setLastSaveData(bundleData);
                } else {
                    if (bundleData !== lastSaveData) {
                        const newRef = await addDoc(collection(db, 'inProgressBundles'), bundleData);
                        await updateDoc(newRef, {ref: newRef});
                        const data = {
                            id: newRef.id,
                            ref: newRef,
                        }
                        setTempBundleData(data);
                        setLastSaveData(bundleData);
                    }
                }
                // toast.success(`Bundle ${newBundleName} saved successfully`, { theme: 'colored', duration: 500 });
            } catch (error) {
                console.log(error)
            }
             
        }

        async function searchProducts(searchTerm) {
            try {
              const options = {
                scorer: fuzz.partial_ratio,
                processor: (choice) => choice.proc_sorted,
                limit: 500,
                cutoff: 90,
                unsorted: false,
                full_process: false,
              }
              const lib = choiceLibrary.filter((item) => item !== undefined);
              const results = fuzz.extract(searchTerm, /** fuzzyChoices */ lib, options)
              return results;
            } catch (error) {
              console.log(error);
            }
        }

        const ResultsTable = () => {

            // useEffect(() => {
            //     return saveProgress(uid);
            // }, [])

            const columns = [
                {
                    accessor: 'name',
                    Header: 'Title',
                    Cell: rowData => {
                        const all = rowData.row.original;
                        const { name } = rowData.row.original;
                        return(
                            <div
                                onClick={() => { 
                                    setSelectedSearchedProduct(all)
                                    setShowResults(false)
                                }}
                            >{name}</div>
                        )
                    }
                },
                {
                    accessor: 'cost',
                    Header: 'Cost',
                }
            ];

            return (
                <>
                <h6>Search Results</h6>
                <AdvanceTableWrapper
                columns={columns}
                data={resultItems}
                sortable
                pagination
                perPage={50}
                className="mb-3"
                >
                 <div className="mt-3 mb-3">
                    <AdvanceTableFooter
                    rowCount={resultItems.length}
                    table
                    rowInfo
                    navButtons
                    rowsPerPageSelection
                    />
                </div>
                <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                    bordered: true,
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                    }}
                />
                </AdvanceTableWrapper>
                </>
            )
        }

        const VariantResultsTable = () => {

            // useEffect(() => {
            //     return saveProgress(uid);
            // }, [])

            const [variants, setVariants] = useState([]);

            async function collectVariants(masterSku) {
                const q = query(
                    collectionGroup(db, 'variants'),
                    where('parentSku', '==', masterSku)
                )
                const imageQuery = query(
                    collection(db, 'productCatalog/topicz/products'),
                    where('sku', '==', masterSku)
                )
                const docs = await getDocs(q);
                if (!docs.empty) {
                    const snap = await getDocs(imageQuery);
                    const images = [];
                    const costs = [];
                    snap.forEach(doc => {
                        const data = doc.data();
                        if (data.primaryImage) images.push(data.primaryImage)
                        if (data.files?.length > 0) {
                            data.files.forEach(file => {
                                images.push(file.src)
                            })
                        }
                        costs.push(data.cost)
                    })
                    if (images?.length < 1) images.push('https://firebasestorage.googleapis.com/v0/b/stk-merlin.appspot.com/o/images%2Fassets%2FMissingImage.png?alt=media&token=680bf53b-33e1-489d-aa58-8c11f1246a23 ')
                    const variants = [];
                    docs.forEach(doc => {
                        let temp = doc.data();
                        temp.images = images;
                        temp.cost = Number(costs[0]) * Number(temp.productFactor);
                        variants.push(temp)
                    })
                    return variants;
                } else {
                    console.log('no variants found')
                    return [];
                }
                
            }

            useEffect(() => {
                collectVariants(selectedSearchedProduct.sku)
                .then((variants) => setVariants(variants))
                .catch((error) => console.log(error))
            }, [selectedSearchedProduct])

            const columns = [
                {
                    accessor: 'images',
                    Header: 'Image',
                    Cell: rowData => {
                        const all = rowData.row.original;
                        const { images } = rowData.row.original;
                        return(
                            <Image src={images[0]} alt={all.title} width={50} height={50} />
                        )
                    }
                },
                {
                    accessor: 'title',
                    Header: 'Title',
                    Cell: rowData => {
                        const all = rowData.row.original;
                        const { title } = rowData.row.original;
                        return(
                            <div
                                onClick={() => { 
                                    setBundledProducts((bundledProducts) => [...bundledProducts, all])
                                    toast.success('Product added to bundle', { theme: 'colored', position: 'top-center' });
                                    setSelectedSearchedProduct();
                                    setShowResults(true)
                                    setVariants([])
                                }}
                            >{title}</div>
                        )
                    }
                },
                {
                    accessor: 'productFactor',
                    Header: 'Product Factor',
                },
                {
                    accessor: 'cost',
                    Header: 'Cost',
                    Cell: rowData => {
                        const { cost } = rowData.row.original;
                        const formatted = Number(cost).toFixed(2);
                        return(
                            <div
                            >
                                ${formatted}
                            </div>
                        )
                    }    
                }
            ];

            return (
                <>
                <h6>Select Desired Product Variant</h6>
                <AdvanceTableWrapper
                columns={columns}
                data={variants}
                sortable
                pagination
                perPage={50}
                className="mb-3"
                >
                <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                    bordered: true,
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                    }}
                />
                </AdvanceTableWrapper>
                </>
            )
        }

        const NewBundleProductsTable = () => {

            // useEffect(() => {
            //     return saveProgress(uid);
            // }, [])

            function removeFromBundledProducts(product) {
                toast.warn('Product removed from bundle', { theme: 'colored', position: 'top-center' });
                setBundledProducts(bundledProducts.filter(item => item !== product))
            }


            const columns = [
                {
                    accessor: 'images',
                    Header: 'Image',
                    Cell: rowData => {
                        const all = rowData.row.original;
                        const { images } = rowData.row.original;
                        return(
                            <Image src={images[0]} alt={all.title} width={50} height={50} />
                        )
                    }
                },
                {
                    accessor: 'title',
                    Header: 'Title',
                    Cell: rowData => {
                        const all = rowData.row.original;
                        const { title } = rowData.row.original;
                        return(
                            <div
                                onClick={() => removeFromBundledProducts(all)}
                            >{title}</div>
                        )
                    }
                },
                {
                    accessor: 'productFactor',
                    Header: 'Product Factor',
                }
            ];

            

            return (
                <>
                <h6>Current Bundle</h6>
                <AdvanceTableWrapper
                columns={columns}
                data={bundledProducts}
                sortable
                pagination
                perPage={50}
                >
                <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                    bordered: true,
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                    }}
                />
                </AdvanceTableWrapper>
                
                </>
            )
        }

        function handlePrimaryClick(e, image, id) {
            e.preventDefault();
            setBundlePrimaryImage(image);
            const element = document.getElementById(id);
            
        }

        const FinalizeBundle = () => {

            // useEffect(() => {
            //     return saveProgress(uid);
            // }, [])

            async function calcBundleCost(products) {
                const costs = [];
                for (let i = 0; i < products.length; i++) {
                    const product = products[i];
                    const data = await getDoc(product.parentRef);
                    const { cost } = data.data();
                    costs.push(cost * product.productFactor);
                }
                let finalCost = 0;
                costs.forEach((cost) => {
                    finalCost += cost;
                })
                return Number(finalCost);
            }

            const DescriptionTextArea = () => {
                function updateDescription(e, description) {
                    e.preventDefault();
                    setNewBundleDescription(description);
                    toast.success('Bundle description updated', { theme: 'colored', position: 'top-center' });
                }
                return (
                    <Form onSubmit={(e) => updateDescription(e, e.target.value)}>
                        <Form.Control
                            as="textarea" rows={3} 
                            placeholder="Enter Bundle Description"
                            defaultValue={newBundleDescription}
                        />
                        <Button type="submit" size="sm" variant="primary" className="mt-2">Update Description</Button>
                    </Form>
                   
                )
            }

            useEffect(() => {
                calcBundleCost(bundledProducts).then((cost) => setBundleCost(cost))
            }, [bundledProducts])
               
            const columns = [
                {
                    accessor: 'propertyName',
                    Header: 'Please Confirm',
                }, 
                {
                    accessor: 'propertyValue',
                    Header: 'Value',
                    Cell: rowData => {
                        const { propertyName, propertyValue } = rowData.row.original;
                        switch (propertyName) {
                            case 'Bundle Name':
                                return (
                                    <>
                                        {!propertyValue ? <Button size="sm" variant="primary" onClick={() => setKey('home')}>Set Name</Button> : propertyValue}
                                    </>
                                )
                            case 'Products':
                                return (
                                    <>
                                        <NewBundleProductsTable />
                                    </>
                                )
                            case 'Primary Image':
                                return (
                                    <>
                                        {bundlePrimaryImage ? <Image src={bundlePrimaryImage} alt="Primary Image" width={50} height={50} /> : null }
                                    </>
                                )
                            case 'Description':
                                return (    
                                    <>
                                        {!newBundleDescription ? <DescriptionTextArea /> : newBundleDescription}
                                    </>
                                )
                            case 'Tags':
                                if (!propertyValue) return;
                                let tagsArray = [];
                                if (typeof propertyValue === 'string') {
                                    tagsArray = propertyValue?.split(',') || [];
                                }
                                return (
                                    tagsArray.length > 0 &&
                                    tagsArray.map((tag, index) => {
                                        return (
                                            <SoftBadge key={index} bg="secondary" className="me-1">{tag}</SoftBadge>
                                        )
                                    })
                                )
                            case 'Bundle Cost':
                                return (
                                    <div>${Number(propertyValue).toFixed(2)}</div>
                                )
                            case 'Bundle SRP':
                                const cost = Number(propertyValue).toFixed(2);
                                const margin = Number(cost * 0.4).toFixed(2);
                                const srp = Number(cost) + Number(margin);
                                return (
                                    <div>
                                        ${ srp }
                                    </div>
                                )
                            case 'Bundle Sku':
                                return (
                                    <div>
                                        <div className="mb-3">Automatic: <strong>{newBundleSku}</strong></div>
                                        <Form>
                                        <InputGroup className="mb-3">
                                            <FormControl
                                            placeholder="Or enter a custom SKU"
                                            aria-label="Or enter a custom SKU"
                                            id="customSkuInput"
                                            />
                                            <Button variant="outline-secondary" id="button-addon2" onClick={() => {
                                                setNewBundleSku(document.getElementById('customSkuInput').value)
                                            }}>
                                            Save Custom SKU
                                            </Button>
                                        </InputGroup>
                                        </Form>
                                        
                                    </div>
                                )
                            default:
                                return (
                                    <div>{propertyValue}</div>
                                )
                        }
                    }
                }
            ];

            const data = [
                {
                    propertyName: 'Bundle Name',
                    propertyValue: newBundleName
                },
                {
                    propertyName: 'Bundle Sku',
                    propertyValue: ''
                },
                {
                    propertyName: 'Bundle Cost',
                    propertyValue: bundleCost
                },
                {
                    propertyName: 'Bundle SRP',
                    propertyValue: bundleCost
                },
                {
                    propertyName: 'Tags',
                    propertyValue: tags
                },
                {
                    propertyName: 'Products',
                    propertyValue: bundledProducts
                },
                {
                    propertyName: 'Primary Image',
                    propertyValue: bundlePrimaryImage
                },
                {
                    propertyName: 'Description',
                    propertyValue: newBundleDescription
                },
            ]

            return (
                <>
                <h6>Finalize Bundle</h6>
                <AdvanceTableWrapper
                columns={columns}
                data={data}
                sortable
                pagination
                perPage={50}
                >
                <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                    bordered: true,
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                    }}
                />
                </AdvanceTableWrapper>                

                </>
            )
        }
    
        function handleFinalize(e){
            e.preventDefault();
            setFinalizeStatus(true);
            if (
                !newBundleName ||
                !bundlePrimaryImage ||
                !tags ||
                !newBundleSku ||
                !bundledProducts ||
                !bundleCost
            ) {
                toast.error('Please fill out all required fields', { theme: 'colored', position: 'top-center' });
                setFinalizeStatus(false);
                return;
            }
            const bundle = {
                name: newBundleName,
                includedVariants: bundledProducts,
                calculatedCost: bundleCost,
                calcualtedAvailableCount: 0,
                description: newBundleDescription || 'None',
                primaryImageUrl: bundlePrimaryImage,
                tags: tags.split(','),
                timestamp: {
                    created: new Date(),
                    modified: new Date(),
                },
                sku: newBundleSku,
            }
            try {
                addDoc(collection(db, 'productBundles'), bundle).then((docRef) => {
                    toast.success('Bundle created successfully', { theme: 'colored', position: 'top-center' });
                    const variantRef = docRef.collection('variants');
                    addDoc(variantRef, {
                        id: bundle.sku,
                        isBundle: true,
                        parentRef: docRef,
                        parentSku: 'BUNDLE',
                        productFactor: 1,
                        ref: variantRef,
                        sku: bundle.sku,
                        title: bundle.name,
                    }).then(() => {
                        // add default variant to the newly created bundle
                        setFinalizeStatus(false)
                        // wait 1.4 seconds before closing modal
                        setTimeout(() => {
                            setViewBundleModalShow(false);
                            setNewBundleName('');
                            setNewBundleDescription('');
                            setBundleCost(0);
                            setBundlePrimaryImage('');
                            setTags('');
                            setBundledProducts([]);
                            setKey('home');
                        }, 1400);
                    }).catch((error) => {
                        toast.error('Error creating bundle variant', { theme: 'colored', position: 'top-center' });
                        console.log(error)
                    });                    
                });
            } catch (error) {
                console.log(error);
            }
        }

        async function progressWizard(toTabKeyName) {
            saveProgress(uid);
            setKey(toTabKeyName);
        }

        return (
            <>
                <Modal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    size="lg"
                    fullscreen
                    aria-labelledby="contained-modal-title-vcenter"
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">{heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    >
                        <Tab eventKey="home" title="Home" className='border-bottom border-x p-3'>
                            <Form className="position-relative" onSubmit={(e) => e.preventDefault()}>
                                <Form.Control
                                    type="text"
                                    placeholder="Bundle Name"
                                    aria-label="Bundle Name"
                                    className="rounded-pill search-input mb-3"
                                    value={newBundleName}
                                    onChange={({ target }) => setNewBundleName(target.value)}
                                    disabled={choicesLoading}
                                    id='bundle-name-input'
                                />
                                <Form.Control
                                type="search"
                                placeholder="Search..."
                                aria-label="Search"
                                className="rounded-pill search-input mb-3"
                                value={searchInputValue}
                                onChange={({ target }) => {
                                    setSearchInputValue(target.value)
                                    setShowResults(true)
                                }}
                                disabled={choicesLoading}
                                id='search-input'
                                />
                            </Form>
                            <div className="mb-3">
                                {resultItems.length > 0 && showResults ? <ResultsTable /> : null}
                            </div>
                            <div className="mb-3">
                                {selectedSearchedProduct ? <VariantResultsTable /> : null}
                            </div>
                            <div className="mb-3">
                                {bundledProducts.length > 0 ? <NewBundleProductsTable /> : null}
                            </div>
                        </Tab>
                        <Tab eventKey="details" title="Details" className='border-bottom border-x p-3'>
                            <div className='mb-3'>
                                <NewBundleProductsTable />
                            </div>
                            <Form className="position-relative" onSubmit={(e) => e.preventDefault()}>
                                    <Form.Label className="mb-3">Enter Tags Separated by a Comma</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Bundle Name"
                                        aria-label="Bundle Name"
                                        className="rounded-pill search-input mb-3"
                                        value={tags}
                                        onChange={({ target }) => setTags(target.value)}
                                        disabled={choicesLoading}
                                        id='bundle-name-input'
                                    />
                            </Form>
                            <Form className="position-relative">
                                <Form.Label className="mb-3">Select Primary Image</Form.Label>
                                <Row>
                                    <Col xs={12} sm={6} md={4} lg={3} className='mb-3'>
                                        {bundlePrimaryImage && <Image src={bundlePrimaryImage} alt={newBundleName} width={100} height={100} /> }
                                    </Col>
                                    <Col>
                                        {bundledProducts.length > 0 ? 
                                        <>
                                            {bundledProducts.map((variant, vIndex) => {
                                                const images = variant.images;
                                                return (
                                                    images.map((image, iIndex) => {
                                                        return (
                                                        <div key={`d-${vIndex}-${iIndex}`} className='mb-3'>
                                                            <Image src={image} alt={variant.title} width={50} height={50} onClick={(e) => handlePrimaryClick(e, image, `d-${vIndex}-${iIndex}`)}/>
                                                        </div>
                                                        )   
                                                    })
                                                )
                                            })}
                                        </>
                                        : null
                                        }
                                    </Col>
                                </Row>
                                
                            </Form>
                        </Tab>
                        <Tab eventKey="finalize" title="Finalize" className='border-bottom border-x p-3'>
                            <FinalizeBundle />
                        </Tab>
                    </Tabs>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='falcon-secondary' className='me-2' onClick={(e) => cancelAndClear(e)}>Close</Button>
                    {key === 'home' ? <Button variant='falcon-primary' className='me-2' onClick={(e) => progressWizard('details')}>Next</Button> : null}
                    {key === 'details' ? <Button variant='falcon-primary' className='me-2' onClick={(e) => progressWizard('home')}>Back</Button> : null}
                    {key === 'details' ? <Button variant='falcon-success' className='me-2' onClick={(e) => progressWizard('finalize')}>Finalize</Button> : null}
                    {key === 'finalize' ? 
                        <Button variant='falcon-success' className='me-2' onClick={(e) => handleFinalize(e)}>
                            {!finalizeStatus ? <> Save Bundle</> : <Spinner />}
                        </Button> 
                    : null}
                </Modal.Footer>
                </Modal>
            </>
        );
    }

    const SaveBundleWrapper = ({ uid }) => {
        // this component will be used to save a bundle in progress
        // it will wrap the NewBundleModal component. It checks firebase for the user's in progress bundles by uid
        // if there are bundles in progress it displays a list of them and allows the user to select one to continue working on
        // before displaying NewBundleModal. If there are no bundles in progress it will display NewBundleModal

        const [inProgressBundles, setInProgressBundles] = useState([]);
        const [selectedBundle, setSelectedBundle] = useState('');
        const [showModal, setShowModal] = useState(false);
        const [saveStatus, setSaveStatus] = useState('INITIAL');
        const [saveLoading, setSaveLoading] = useState(false);

        useEffect(() => {
            setSaveLoading(true);
            const q = query(
                collection(db, 'inProgressBundles'),
                where('uid', '==', uid)
            )
            const unsubscribe = onSnapshot(q, (snapshot) => {
                const data = [];
                snapshot.forEach(doc => {
                    let temp = doc.data();
                    temp.id = doc.id;
                    temp.ref = doc.ref;
                    data.push(temp)
                })
                setInProgressBundles(data);
            })
            setSaveLoading(false);
            return () => unsubscribe();
        }, [uid])

        const handleContinue = (e) => {
            e.preventDefault();
            setShowModal(true);
        }

        const SaveUI = ({ showModal = true, setShowModal, setSaveStatus }) => {

            const handleDelete = (e, id) => {
                e.preventDefault();
                deleteDoc(doc(db, 'inProgressBundles', id)).then(() => {
                    toast.success('In Progress Bundle Deleted', { theme: 'colored', position: 'top-center' });
                    setShowModal(false)
                }).catch((error) => {
                    toast.error('Error deleting In Progress Bundle', { theme: 'colored', position: 'top-center' });
                    console.log(error)
                })
            }
    
            const handleSelect = (e, id) => {
                setSaveStatus('SELECTED');
                e.preventDefault();
                setSelectedBundle(id);
            }

            const columns = [
                {
                    accessor: 'name',
                    Header: 'Name',
                },
                {
                    accessor: 'id',
                    Header: 'Actions',
                    Cell: rowData => {
                        const { id } = rowData.row.original;
                        const bundle = rowData.row.original;
                        return(
                            <>
                                <Button variant='falcon-primary' className='me-2' onClick={(e) => handleSelect(e, bundle)}>Select</Button>
                                <Button variant='falcon-danger' className='me-2' onClick={(e) => handleDelete(e, id)}>Delete</Button>
                            </>
                        )
                    }
                }
            ];

            return (
                <Modal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    size="lg"
                    fullscreen
                    aria-labelledby="contained-modal-title-vcenter"
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Load Saved Bundle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AdvanceTableWrapper
                    columns={columns}
                    data={inProgressBundles}
                    sortable
                    pagination
                    perPage={50}
                    >
                    
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                        bordered: true,
                        striped: true,
                        className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                    </AdvanceTableWrapper>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='falcon-secondary' className='me-2' onClick={(e) => setShowModal(false)}>Close</Button>
                    <Button size="sm" variant="falcon-default" onClick={() => setSaveStatus('NEW')}>Or Create New Bundle</Button>
                </Modal.Footer>
                </Modal>
            )
        }

        return (
            <>
                <IconButton
                        variant="falcon-default"
                        size="sm"
                        icon="plus"
                        transform="shrink-3"
                        className='me-2'
                        onClick={(e) => handleContinue(e)}
                    >
                        <span className="d-none d-sm-inline-block ms-1">Create New Bundle</span>
                    </IconButton>
                {!saveLoading ?

                    saveStatus === 'INITIAL' && inProgressBundles.length > 0 ?
                        <SaveUI setShowModal={setShowModal} showModal={showModal} setSaveStatus={setSaveStatus} /> 
                        : null
                    ||
                    saveStatus === 'INITIAL' && inProgressBundles.length < 1 ?
                        <NewBundleModal
                            heading="Create New Bundle"
                            selectedBundle={selectedBundle}
                            uid={uid} 
                            showModal={showModal}
                            setShowModal={setShowModal}
                            setSaveStatus={setSaveStatus}
                            /> 
                        : null
                    ||
                    saveStatus === 'SELECTED' ?
                        <NewBundleModal
                                heading="Create New Bundle"
                                selectedBundle={selectedBundle}
                                uid={uid} 
                                showModal={showModal}
                                setShowModal={setShowModal}    
                                setSaveStatus={setSaveStatus}
                        />
                        : null
                    ||
                    saveStatus === 'NEW' ?
                        <NewBundleModal
                                heading="Create New Bundle"
                                selectedBundle={{}}
                                uid={uid}
                                showModal={showModal}
                                setShowModal={setShowModal}
                                setSaveStatus={setSaveStatus}
                        />
                        : null
            : null
            }
            </>
        )

    }
    
    return (
        <>
            
            <EditModal showModal={editModalShow} setShowModal={setEditModalShow} bundle={activeEditBundle} />
            <PageHeader title="Product Bundles" className="mb-3" />
            <Card className="mb-3">
                <Card.Body>
                    <BundlesAdvanceTable />
                </Card.Body>
            </Card>
        </>
    )  
}


  
  

export default Bundles;