
import React, { useRef, useState, useEffect } from 'react';
import { db } from 'config/firebase';
import { addDoc, collection, query, where, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { Modal, Button, Tabs, Tab, Form, ListGroup, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

export const ManageShippingTemplatesModal = ({ show, onClose }) => {

    const [templates, setTemplates] = useState([]);
    const [packageTypes, setPackageTypes] = useState([]);
    useEffect(() => {
        let isSubbed = true;
        const getTemplates = async () => {
            const q = query(
                collection(db, 'systemTemplates'),
                where('type', '==', 'shipping')
            );
            const querySnapshot = await getDocs(q);
            const templateData = [];
            querySnapshot.forEach((doc) => {
                templateData.push({ id: doc.id, ref: doc.ref, ...doc.data() });
            });
            return templateData;
        }
        getTemplates().then((data) => {
            if (isSubbed) {
                setTemplates(data);
            }
        });
        return () => isSubbed = false;
    }, [templates]);

    useEffect(() => {
        let isSubbed = true;
        const gatherPackageTypes = async () => {
            const q = query(
                collection(db, 'packageTypes'),
            );
            const querySnapshot = await getDocs(q);
            const packageTypes = [];
            querySnapshot.forEach((doc) => {
                packageTypes.push({ id: doc.id, ref: doc.ref, ...doc.data() });
            });
            return packageTypes;
        }
        gatherPackageTypes().then((data) => {
            if (isSubbed) {
                setPackageTypes(data);
            }
        });
        return () => isSubbed = false;
    }, [packageTypes]);

    

    const ManageExistingTemplatesForm = () => {
        return (
            <ListGroup>
                {templates.map((template) => {
                    return (
                        <ListGroup.Item key={template.id}>
                            <Row>
                                <Col>
                                    {template.name}
                                </Col>
                                {/* button should flow to the right side **/}
                                <Col className='text-end'>
                                    <Button variant="danger" size="sm" onClick={(e) => deleteTemplate(e, template.id)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        )
    }

    const deleteTemplate = async (e, templateId) => {
        e.preventDefault();
        try {
            await deleteDoc(doc(db, 'systemTemplates', templateId));
            toast.success('Template deleted', { theme: 'colored' });
        } catch (error) {
            console.log(error);
            toast.error(error.message, { theme: 'colored' });
        }
    }

    return (
        <Modal show={show} onHide={onClose} size="lg">
            <Modal.Header>
                <Modal.Title>Manage Shipping Templates</Modal.Title>
            </Modal.Header>
                <Tabs defaultActiveKey="createNew" id="shippingTemplateTabs" className='m-1'>
                    <Tab eventKey="createNew" title="Create New Template" className='border-bottom border-x p-3'>
                        <NewShippingTemplateForm packageTypes={packageTypes} onClose={onClose}/>
                    </Tab>
                    <Tab eventKey="manage" title="Manage Existing" className='border-bottom border-x p-3'>
                        <ManageExistingTemplatesForm />
                    </Tab>
                </Tabs>

            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
    
}


const NewShippingTemplateForm = ({packageTypes, onClose}) => {
        /** The following will need to be captured in the form
         * 1. Template Name
         * 2. Setting for B2B Discount Rate
         * 3. Setting for General Discount Rate
         * 4. Setting for Markup Factor (Used for Max Price)
         * 5. Setting for Target Margin
         */
        const templateNameRef = useRef();
        const materialCost = useRef();
        const packLabor = useRef();
        const pickLabor = useRef();
        const packageTypeId = useRef();
    
        const handleNewTemplateFormSubmission = async (e) => {
            e.preventDefault();
            const newName = templateNameRef.current.value;
            if (!newName) {
                toast.error('Template name is required', { theme: 'colored' });
                return;
            }
            const newTemplate = {
                name: templateNameRef.current.value,
                settings: {
                    materialCost: materialCost.current.value,
                    packLabor: packLabor.current.value,
                    pickLabor: pickLabor.current.value,
                    packageTypeId: packageTypeId.current.value,
                },
                type: 'shipping',
                timestamp: {
                    created: new Date(),
                    updated: new Date(),
                }
            }
            try {
                await addDoc(collection(db, 'systemTemplates'), newTemplate)
                toast.success('New template created', { theme: 'colored' });
                // wait a half sec then close modal
                setTimeout(() => {
                    onClose();
                }, 500);
            } catch (error) {
                console.log(error);
                toast.error(error.message, { theme: 'colored' });
            }
    
        }
    
        return (
            <Form onSubmit={(e) => handleNewTemplateFormSubmission(e)}>
                <Form.Group className="mb-3" controlId="templateName">
                    <Form.Label>Template Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter Template Name" ref={templateNameRef}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="materialCost">
                    <Form.Label>Material Cost</Form.Label>
                    <Form.Control type="text" placeholder="Enter total cost of shipping materials" ref={materialCost}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="packLabor">
                    <Form.Label>Pack Labor</Form.Label>
                    <Form.Control type="text" placeholder="Enter the cost of Pack Labor per shipment" ref={packLabor}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="markupFactor">
                    <Form.Label>Pick Labor</Form.Label>
                    <Form.Control type="text" placeholder="Enter the cost of Pick Labor per shipment" ref={pickLabor}/>
                </Form.Group>
                {/** Create a dropdown option menu populated with packageTypes */}
                <Form.Group className="mb-3" controlId="packageType">
                    <Form.Label>Package Type</Form.Label>
                    <Form.Select aria-label="Select Package Type" ref={packageTypeId}>
                        {packageTypes.map((packageType) => {
                            return (
                                <option key={packageType.id} value={packageType.id}>{packageType.name}</option>
                            )
                        })}
                    </Form.Select>
                </Form.Group>
                <Button variant="primary" type="submit">
                    Create New Shipping Template
                </Button>
            </Form>
        )
    }

    