
export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'chart-pie',
      to: '/',
      exact: true
    },
    {
      name: 'Products',
      active: true,
      icon: 'tags',
      children: [
        {
          name: 'Products',
          to: '/products',
          active: true
        },
        {
          name: 'Bundles',
          to: '/bundles',
          active: true
        },
        {
          name: 'Launchpad',
          to: '/launchpad',
          active: true
        },
      ]
    },
    {
      name: 'Orders',
      active: true,
      icon: 'shopping-cart',
      children: [
        {
          name: 'View Orders',
          to: '/orders',
          exact: true,
          active: true
        },
        {
          name: 'Old Batch Processing',
          to: '/batches',
          active: true
        },
        {
          name: 'Batch Management',
          to: '/fulfillment/batches',
          active: true
        },
        {
          name: 'Pick Wizard',
          to: '/fulfillment/pick',
          active: true
        },
        {
          name: 'Shipping Labels',
          to: '/fulfillment/shipping-labels',
          active: true
        },
        {
          name: 'Pack Wizard',
          to: '/fulfillment/pack',
          active: true
        },
        {
          name: 'Shipping Calculator',
          to: '/fulfillment/shipping-calculator',
          active: true
        },
      ]
    },
    {
      name: 'Inventory',
      active: true,
      icon: 'shapes',
      children: [
        {
          name: 'Locations',
          to: '/inventory/locations',
          exact: true,
          active: true
        },
        {
          name: 'Scanner',
          to: '/inventory/scanner',
          active: true
        },
        {
          name: 'Reports',
          to: '/inventory/report',
          active: true
        }
      ]
    },
    {
      name: 'TradeHub',
      active: true,
      icon: 'book',
      children: [
        {
          name: 'User Management',
          to: '/suppliers',
          exact: true,
          active: true
        },
        {
          name: 'Product Management',
          to: '/suppliers/products',
          active: true
        }
      ]
    },
    {
      name: 'Marketing',
      active: true,
      icon: 'fire',
      children: [
        {
          name: 'Roundtable Management',
          to: '/marketing/roundtable/management',
          active: true
        },
        {
          name: 'Direct Mail Management',
          to: '/marketing/direct-mail/management',
          active: true
        }
      ]
    },
    {
      name: 'Advanced',
      active: true,
      icon: 'tools',
      children: [
        {
          name: 'Repricer Settings',
          to: '/advanced/repricer/management',
          active: true
        },
        {
          name: 'Fulfillment Settings',
          to: '/advanced/fulfillment/management',
          active: true
        }
      ]
    }
  ]
};


export const vendorDashboardRoutes = {
  label: 'Vendor Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'chart-pie',
      to: '/',
      exact: true
    },
    {
      name: 'Products',
      active: true,
      icon: 'tags',
      children: [
        {
          name: 'Search',
          to: '/search',
          active: true
        }
      ]
    },
    {
      name: 'Utilities',
      active: true,
      icon: 'fire',
      children: [
        {
          name: 'Settings',
          to: '/',
          active: true
        }
      ]
    }
  ]
};


export const documentationRoutes = {
  label: 'Help & Documentation',
  children: [
    {
      name: 'Self-Help Docs',
      icon: 'rocket',
      to: 'https://app.nuclino.com/MerlinWiki/Product-Docs/Guides-18a31065-c0bf-4b20-aaf8-62fbd98e41d2',
      active: true
    },
    {
      name: 'Contact Support',
      icon: 'rocket',
      to: 'https://app.nuclino.com/MerlinWiki/Product-Docs/Guides-18a31065-c0bf-4b20-aaf8-62fbd98e41d2',
      active: true
    }

  ]
};


const routeArray =  [
  dashboardRoutes, vendorDashboardRoutes, documentationRoutes
]

export default routeArray;
