export const shipStationTags = {
    "meta": {
        "format": "JSON",
        "version": "1.1.0",
        "projectId": "stk-merlin",
        "resourcePath": [
            "shipStationTags"
        ],
        "recursive": false,
        "creationTime": 1706637331,
        "app": "firefoo"
    },
    "data": {
        "59210": {
            "color": "#3366FF",
            "name": "*Meltable*",
            "tagId": 59210,
            "__collections__": {}
        },
        "59250": {
            "color": "#00FF00",
            "name": "Xtra Shipping Paid",
            "tagId": 59250,
            "text": "black",
            "__collections__": {}
        },
        "59252": {
            "color": "#008080",
            "name": "Signature Needed",
            "tagId": 59252,
            "__collections__": {}
        },
        "59532": {
            "color": "#1A3E94",
            "name": "*Cold Pack*",
            "tagId": 59532,
            "__collections__": {}
        },
        "59606": {
            "color": "#FF0000",
            "name": "International",
            "tagId": 59606,
            "__collections__": {}
        },
        "60841": {
            "color": "#FFFF00",
            "name": "Billed Product",
            "tagId": 60841,
            "text": "black",
            "__collections__": {}
        },
        "63136": {
            "color": "#FF00FF",
            "name": "Do Not Ship/Zonos",
            "tagId": 63136,
            "__collections__": {}
        },
        "66263": {
            "color": "#000000",
            "name": "No Packing Slip",
            "tagId": 66263,
            "__collections__": {}
        },
        "72641": {
            "color": "#008000",
            "name": "B2B Customer",
            "tagId": 72641,
            "__collections__": {}
        },
        "73379": {
            "color": "#CCFFFF",
            "name": "Amazon Prime Order",
            "tagId": 73379,
            "__collections__": {}
        },
        "74799": {
            "color": "#993300",
            "name": "Buyer Waiting for ReStock. ",
            "tagId": 74799,
            "__collections__": {}
        },
        "75969": {
            "color": "#700303",
            "name": "OUT OF STOCK",
            "tagId": 75969,
            "__collections__": {}
        },
        "76910": {
            "color": "#D6D6D6",
            "name": "FROZEN/DRY ICE",
            "tagId": 76910,
            "text": "black",
            "__collections__": {}
        },
        "77747": {
            "color": "#FFCC00",
            "name": "Transparency Label",
            "tagId": 77747,
            "__collections__": {}
        },
        "77940": {
            "color": "#CCFFCC",
            "name": "Aerosol",
            "tagId": 77940,
            "text": "black",
            "__collections__": {}
        },
        "79469": {
            "color": "#FF6600",
            "name": "Explosive",
            "tagId": 79469,
            "__collections__": {}
        },
        "81049": {
            "color": "#808000",
            "name": "Contacted",
            "tagId": 81049,
            "__collections__": {}
        },
        "81194": {
            "color": "#808080",
            "name": "RTS",
            "tagId": 81194,
            "__collections__": {}
        },
        "90300": {
            "color": "#99CCFF",
            "name": "Calm Caterpillar",
            "tagId": 90300,
            "text": "black",
            "__collections__": {}
        },
        "90948": {
            "color": "#B7B7B7",
            "name": "Seasonal Dry Ice",
            "tagId": 90948,
            "__collections__": {}
        },
        "95841": {
            "color": "#CC99FF",
            "name": "Heavy Glass",
            "tagId": 95841,
            "__collections__": {}
        }
    }
}