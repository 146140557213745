import { useEffect, useState, useRef } from "react";
import { db } from 'config/firebase';
import { query, collection, where, getDocs, updateDoc } from "firebase/firestore";

const useGridState = (userId) => {
    
    const [gridApi, setGridApi] = useState(null);
    const [columnApi, setColumnApi] = useState(null);
    const gridRef = useRef(null);
    const timeoutRef = useRef(null);

    const saveColumnState = async (params) => {
        if (!params.columnApi) return;
        const state = params.columnApi.getColumnState()
        const userQuery = query(
            collection(db, "users"),
            where("uid", "==", userId)
        );
        const userSnapshot = await getDocs(userQuery);
        const userDoc = userSnapshot.docs[0];
        await updateDoc(userDoc.ref, {
            'product-grid-settings': state
        });
    }

    const loadColumnState = async (params) => {
        if (!columnApi) return;
        const userQuery = query(
            collection(db, "users"),
            where("uid", "==", userId)
        );
        const userSnapshot = await getDocs(userQuery);
        const userDoc = userSnapshot.docs[0];
        const userData = userDoc.data();
        const columnState = userData['product-grid-settings'];
        if (userData && columnState) {
            // columnApi.setColumnState(columnState);
            try {
                // i'm not crazy about this but the column state
                // won't update unless i set a timeout
                timeoutRef.current = setTimeout(() => {
                    columnApi.applyColumnState({
                        state: columnState,
                        applyOrder: true,
                        // defaultState: { width: 100 },
                    });
                }, 1000);
            } catch (err) {
                console.log(err);
            
            }
        }
    }

    // clearTimeout on unmount
    useEffect(() => {
        return () => clearTimeout(timeoutRef.current);
    }, []);

    useEffect(() => {
        if (columnApi && userId) {
            loadColumnState();
        }
    }, [columnApi, userId]);


    return {
        gridRef,
        onGridReady: (params) => {
          setGridApi(params.api);
          setColumnApi(params.columnApi);
        },
        onColumnChanged: saveColumnState,
        loadColumnState,
    };
}

export default useGridState;