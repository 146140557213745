import { useState, useEffect, useContext } from 'react'
import { useHttpsCallable } from 'react-firebase-hooks/functions';
import { db, functions } from 'config/firebase';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import { collection, query, where, limit, getDocs, addDoc, doc, updateDoc, collectionGroup, getDoc} from 'firebase/firestore';
import { isArray } from 'lodash';
const merlinAssistantCollection = 'merlinAssistants';

export const useMerlinAssistant = () => {
    const {user, authLoading}= useContext(FirebaseAuthContext)
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [assistantId, setAssistantId] = useState("");
    const [currentThread, setCurrentThread] = useState("");
    const [currentRunId, setCurrentRunId] = useState("");
    const [messages, setMessages] = useState([]);
    const [callMerlin, callableLoading, callableError] = useHttpsCallable(functions, 'useMerlinAssistant');
    const [awaitingResponse, setAwaitingResponse] = useState(false);
    const [refreshChat, setRefreshChat] = useState(false);

    // helper functions

    const toggleChatRefresh = () => {
        setRefreshChat(!refreshChat);
    }

    const saveUserData = async (uid, saveData) => {
        try {
            const assistantQuery = query(
                collection(db, merlinAssistantCollection),
                where('uid', '==', uid),
                limit(1)
            );
            const snapshot = await getDocs(assistantQuery);
            if (snapshot.size > 0) {
                const docId = snapshot.docs[0].id;
                const assistantRef = doc(db, merlinAssistantCollection, docId);
                await updateDoc(assistantRef, {
                    ...saveData
                });
            } else {
                const newAssistantRef = await addDoc(collection(db, merlinAssistantCollection), {
                    uid: uid,
                    ...saveData
                });
            }
            return { success: true };
        } catch (error) {
            console.log(error, saveData)
            return { success: false };
        }
    }
    
    const checkForSavedAssistant = async (uid) => {
        
        try {
            const assistantQuery = query(
                collection(db, merlinAssistantCollection),
                where('uid', '==', uid),
                limit(1)
            );
            const snapshot = await getDocs(assistantQuery);
            let assistantId = "asst_OzjTPy8GSBFEXU7GnYRR7QV4"; // merlin-alpha-001
            if (snapshot.size > 0) {
                assistantId = snapshot.docs[0].data().assistantId;   
            } else {
                await saveUserData(uid, { assistantId: assistantId });
            }
            return assistantId;
        } catch (error) {
            console.log(error)
        }
    }
    
    const checkForCurrentThread = async (uid) => {

        try {
            const threadQuery = query(
                collection(db, merlinAssistantCollection),
                where('uid', '==', uid),
                limit(1)
            );
            const snapshot = await getDocs(threadQuery);
            let threadId;
            if (snapshot.size > 0) {
                let temp = snapshot.docs[0].data();
                if (temp.threadId) {
                    threadId = temp.threadId;
                    // const initMessage = `!!!Important!!! The products i'm currently viewing are:\n\n${workspaceDetails}. Please do not acknowledge this message, only greet me. Thanks!`
                    // addMessageToThread(threadId, initMessage);
                } else {
                    threadId = await startNewThread(uid);
                }
            } else {
                threadId = await startNewThread(uid);
            }
            return threadId;
        } catch (error) {
            console.log(error)
        }
    }
    
    const startNewThread = async (uid) => {
        const starterMessage = ``
        try {
            const result = await callMerlin({
                service: "createEmptyThread",
            });
            const threadId = result?.data.id;
            await saveUserData(uid, { threadId: threadId });
            // const initMessage = `!!!Important!!! The products I'm currently viewing are:\n\n${workspaceDetails}. Please do not acknowledge this message, only greet me. Thanks!`
            // await addMessageToThread(threadId, initMessage);
            return threadId;
        } catch (error) {
            console.log(error);
        }
    }
    
    const listMessages = async (threadId) => {
        if (!threadId) return;
        try {
            const result = await callMerlin({
                service: "listMessages",
                threadId: threadId,
            });
            return result.data;
        } catch (error) {
            console.log(error);
        }
    }
    
    const addMessageToThread = async (threadId, message) => {
        try {
            const result = await callMerlin({
                service: "createMessage",
                threadId: threadId,
                message: message,
            });
            if (result?.data.id) {
                return result.data.id;
            } else {
                return false;
            }
        } catch (error) {
            console.log(error);
        }
    }
    
    const executeThread = async (threadId, assistantId) => {
        try {
            const result = await callMerlin({
                service: "runThread",
                threadId: threadId,
                assistantId: assistantId,
            });
            console.log("exec", result, threadId, assistantId)
            if (result?.data.id && !result?.data.failed_at) {
                const expiresAt = new Date(result.data.expires_at * 1000);
                setCurrentRunId(result.data.id);
                return { success: true, status: result.data.status, id: result.data.id, expires: expiresAt };
            } else {
                console.log("res", result)
                return { success: false };
            }
        } catch (error) {
            console.log(error);
            return { success: false };
        }
    }
    
    const checkRunStatus = async (threadId, runId) => {
        try {
            const result = await callMerlin({
                service: "retrieveRun",
                threadId: threadId,
                runId: runId,
            });
            if (result && result.data) {
                switch (result.data.status) {
                    case "in_progress":
                        return { completed: false, status: result.data.status, id: result.data.id, message: "Request in progress" };
                    case "completed":
                        return { completed: true, status: result.data.status, id: result.data.id, message: "completed" };
                    case "cancelling":
                        return { completed: false, status: result.data.status, id: result.data.id, message: "Request cancelling" };
                    case "cancelled":
                        return { completed: false, status: result.data.status, id: result.data.id, message: "Request cancelled" };
                    case "failed":
                        return { completed: false, status: result.data.status, id: result.data.id, message: "Request failed"};
                    default:
                        return { completed: false };
                }
            }
        //    if (result?.data.status === "completed") {
        //         return { completed: true, status: result.data.status, id: result.data.id };
        //     } else {
        //         return { completed: false };
        //     }
        } catch (error) {
            console.log(error);
            return { completed: false };
        }
    }

    // look up the current Skus and return some information about them as a string
    const getCurrentWorkspaceIntel = async (currentSkuList) => {
        if (!currentSkuList) return;
        const results = [];
        try {
            for (const sku of currentSkuList) {
                const skuQuery = query(
                    collectionGroup(db, 'variants'),
                    where('sku', '==', sku),
                    limit(1)
                );
                const snapshot = await getDocs(skuQuery);
                if (snapshot.size > 0) {
                    const skuData = snapshot.docs[0].data();
                    const parentData = await getDoc(skuData.parentRef);
                    results.push({
                        sku: skuData.sku,
                        parentSku: skuData.parentSku,
                        title: skuData.title,
                        parentTitle: parentData.data().name,
                        variantTags: skuData.tags || [],
                        productFactor: skuData.productFactor,
                        count: parentData.data().ecomm_onhand,
                        parentTags: parentData.data().tags || [],
                        cost: Number(parentData.data().cost * skuData.productFactor).toFixed(2),
                    });
                }
            }
            // convert results to string
            let resultString = '';
            for (const result of results) {
                resultString += `Count ${result.count} SKU: ${result.sku} Name: ${result.title} Cost: ${result.cost} TAGS: ${result.variantTags.join(' ')} ${result.parentTags.join(' ')}\n`;
            }
            return resultString;
        } catch (error) {
            console.log(error);
        }
    }

    const cancelAllRunsForThread = async (threadId) => {
        try {
            const result = await callMerlin({
                service: "cancelAllRuns",
                threadId: threadId,
            });
            if (result?.data.id) {
                return result.data.id;
            } else {
                return false;
            }
        } catch (error) {
            console.log(error);
        }
    }

    // user exposed functions

    async function sendMessage(message){
        try {
            console.log(assistantId, currentThread, message)
            await addMessageToThread(currentThread, message);
            await executeThread(currentThread, assistantId);
            setAwaitingResponse(true);
        } catch (error) {
            console.log(error);
        }
    }

    async function createNewThread(){
        try {
            const result = await callMerlin({
                service: "createEmptyThread",
            });
            await saveUserData(user.uid, { threadId: result.data.id });
        } catch (error) {
            console.log(error);
        }
    }

    // on load initialize assistant
    useEffect(() => {
        if (authLoading) return;
        if (callableLoading) return;
        if (callableError) {
            setError(callableError);
            setIsLoading(false);
            return;
        }
        if (!user) return;
        const initializeMerlinAssistant = async () => {
            setIsLoading(true);
            const initPromises = [];
            // const workspaceProductString = await getCurrentWorkspaceIntel(currentSkuList);
            initPromises.push(checkForSavedAssistant(user.uid));
            initPromises.push(checkForCurrentThread(user.uid));
            const [assistantId, threadId] = await Promise.all(initPromises);
            await cancelAllRunsForThread(threadId);
            return { assistantId, threadId};
        }
        initializeMerlinAssistant().then(({assistantId, threadId}) => {
            setAssistantId(assistantId);
            setCurrentThread(threadId);
            listMessages(threadId).then((messages) => {
                // reverse messages so newest is at bottom
                if (isArray(messages.data)) messages.data.reverse();
                setMessages(messages.data);
                setIsLoading(false);
            });
        }).catch((error) => {
            setError(error);
            setIsLoading(false);
        });
    
    }, [authLoading, user, refreshChat]);

    // when awaiting response check run status on interval
    useEffect(() => {
        if (!awaitingResponse) return;
        const interval = setInterval(() => {
            checkRunStatus(currentThread, currentRunId).then((run) => {
                if (run.completed) {
                    listMessages(currentThread).then((messages) => {
                        // reverse messages so newest is at bottom
                        if (isArray(messages.data)) messages.data.reverse();
            
                        setMessages(messages.data);
                        setAwaitingResponse(false);
                    });
                } else {
                    setMessages(prevMessages => [...prevMessages, { role: 'status', text: run.message }]);
                }
            }).catch((error) => {
                console.log(error);
            });
        }, 1500);
        return () => clearInterval(interval);
    }, [awaitingResponse]);

    return {sendMessage, createNewThread, toggleChatRefresh, awaitingResponse, messages, isLoading, error};

}

