import { useState, useContext, useEffect, useRef, useReducer } from 'react';
import { db } from "config/firebase";
import { Link, useNavigate } from 'react-router-dom';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import PageHeader from 'components/common/PageHeader';
import { Card, Row, Col, Button, Form, Tabs, Tab, Image, Badge, Modal, InputGroup, FormControl } from 'react-bootstrap';
import Lottie from 'lottie-react';
import dotsAnimation from 'assets/lottie/dots.json';
import { useCollection } from 'react-firebase-hooks/firestore';
import { collection, where, query, getDocs, limit, collectionGroup, getDoc, doc, updateDoc, orderBy, serverTimestamp } from "firebase/firestore";
import AdvanceTableSearchBox from 'components/merlin/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/merlin/advance-table/AdvanceTableFooter';
import IconButton from 'components/common/IconButton'
import SoftBadge from 'components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { toast } from 'react-toastify';

const NeedsLabelTable = ({ labels, printModal, exclusionsModal, overrideModal, overrideData, labelsLoading }) => {
    const [showPrintModal, setShowPrintModal] = printModal;
    const [showExclusionsModal, setShowExclusionsModal] = exclusionsModal;
    const [showOverrideModal, setShowOverrideModal] = overrideModal;
    const [od, setOverrideData] = overrideData;

    const colDef = [
        { 
            accessor: 'orderNumber',
            Header: 'Order #',
            Cell: (rowData) => {
                const { orderId, orderNumber } = rowData.row.original;
                
                return (
                    <Link
                        to={`/orders/${orderId}`}
                    >
                        {orderNumber}
                    </Link>
                )
            }
        },
        {
            Header: 'Batch',
            accessor: 'batchId',
            Cell: ({ cell }) => {
                const { batchId, batchName } = cell.row.original;

                let displayName = batchName || "Coming Soon";

                return (
                    <Link
                        to={`/fulfillment/batches/${batchId}`}
                        
                    >
                        {displayName}
                    </Link>
                )
            }

        },
        { 
            accessor: 'status',
            Header: 'Status',
            Cell: (rowData) => {
                const { status, finanicals, selectedOption, ref } = rowData.row.original;
                let statusMessage = status;
                let statusBadgeBg = 'warning';
                switch (status) {
                    case 'REVIEW':
                        const selectedCost = Number(selectedOption?.shipmentCost) || 0;
                        const budget = Number(finanicals?.budget) || 0;
                        const exceededBy = budget - selectedCost;
                        statusMessage = 'REVIEW' + ' ($' + exceededBy.toFixed(2) + ')';
                        statusBadgeBg = 'danger';
                        break;
                    case 'LABEL ASSIGNED':
                        statusMessage = 'LABEL ASSIGNED';
                        statusBadgeBg = 'success';
                        break;
                    case 'LABEL OVERIDE USED':
                        statusMessage = 'LABEL OVERIDE USED';
                        statusBadgeBg = 'success';
                        break;
                    case 'LABELING IN PROCESS':
                        statusMessage = 'LABELING IN PROCESS';
                        statusBadgeBg = 'primary';
                        break;
                    case 'DISCOVERING OPTIONS':
                        statusMessage = 'DISCOVERING OPTIONS';
                        statusBadgeBg = 'info';
                        break;
                    default:
                        break;
                }

                return (
                    <div
                        style={{minWidth: '120px'}}
                    >
                        <SoftBadge
                            bg={statusBadgeBg}
                        >
                            {statusMessage}
                        </SoftBadge>
                        {/** Retry Button */}
                        <FontAwesomeIcon
                            variant="falcon-default"
                            size="sm"
                            icon="redo"
                            transform="shrink-3"
                            className="ms-2"
                            onClick={() => updateDoc(ref, { status: 'OPEN'})}
                        />
                    </div>       
                )
            }
        },
        { 
            accessor: 'items',
            Header: 'Item SKU, Quantity, Name',
            Cell: (rowData) => {
                const { items } = rowData.row.original;

                // consolidate the items if they have the same SKU
                const consolidatedItems = [];
                items?.forEach((item) => {
                    const existingItem = consolidatedItems.find((i) => i.sku === item.sku);
                    if (existingItem) {
                        existingItem.quantity += item.quantity;
                    } else {
                        consolidatedItems.push({ ...item });
                    }
                });

                return (
                    <div>
                        {consolidatedItems?.map((item, index) => {
                            return (
                                <div key={index} className='align-text-bottom'>
                                    <Image 
                                        src={item.imageUrl}
                                        width={25}
                                        className='me-2'
                                    />
                                    <SoftBadge bg='primary' className='me-1'>{item.sku}</SoftBadge>
                                    <SoftBadge bg='secondary' className='me-1'>{item.quantity}</SoftBadge>
                                    <div className="d-inline-block text-truncate"  style={{ maxWidth: '250px' }}>
                                        <Badge
                                            bg='light'
                                            className='mt-1'
                                        >
                                            <span className='text-700'>{item.name}</span>
                                        </Badge>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )
            }
        },
        {
            accessor: 'destination.state',
            Header: 'Destination',
        },
        { 
            accessor: 'selectedOption.shipmentCost',
            Header: 'Selected Option',
            Cell: (rowData) => {
                const { selectedOption, requestedShipping, collectedRates, orderId } = rowData.row.original;
                const shipmentCost = selectedOption?.shipmentCost || '0';
                const serviceName = selectedOption?.serviceName || 'Awaiting Label';
                const userOverride = selectedOption?.userOverride || false;

                const requestedCarrier = requestedShipping?.carrierCode || '';
                const selectedCarrier = selectedOption?.carrierCode || '';

                let overrideBadgeBg = 'light';
                if (requestedCarrier !== selectedCarrier) overrideBadgeBg = 'danger';
                
                let triangleColor = "text-danger";
                if (userOverride) triangleColor = "text-success";
                if (!userOverride && requestedCarrier === selectedCarrier) triangleColor = "text-warning";

                return (
                    <div>
                        <SoftBadge
                            bg='secondary'
                            className='me-2'
                        >
                        ${shipmentCost}
                        </SoftBadge>
                        {requestedCarrier === selectedCarrier && !userOverride &&
                            <IconButton
                                variant=""
                                className={triangleColor}
                                size="sm"
                                icon="exclamation-triangle"
                                transform="shrink-3"
                                onClick={() => {
                                    setOverrideData({
                                        requested: requestedShipping,
                                        selected: selectedOption,
                                        collected: collectedRates,
                                        financials: rowData.row.original.financials,
                                        orderId: orderId,
                                        timestamp: {
                                            updated: serverTimestamp(),
                                        }
                                    });
                                    setShowOverrideModal(true);
                                }}
                            />
                        }
                       
                        {requestedCarrier !== selectedCarrier &&
                            <IconButton
                                variant=""
                                className={triangleColor}
                                size="sm"
                                icon="exclamation-triangle"
                                transform="shrink-3"
                                onClick={() => {
                                    setOverrideData({
                                        requested: requestedShipping,
                                        selected: selectedOption,
                                        collected: collectedRates,
                                        financials: rowData.row.original.financials,
                                        orderId: orderId
                                    });
                                    setShowOverrideModal(true);
                                }}
                            />
                        }
                        {requestedCarrier === selectedCarrier && userOverride &&
                            <IconButton
                                variant=""
                                className={triangleColor}
                                size="sm"
                                icon="check"
                                transform="shrink-3"
                                onClick={() => {
                                    setOverrideData({
                                        requested: requestedShipping,
                                        selected: selectedOption,
                                        collected: collectedRates,
                                        financials: rowData.row.original.financials,
                                        orderId: orderId
                                    });
                                    setShowOverrideModal(true);
                                }}
                            />
                        }
                        <Badge
                            bg='light'
                        >
                            <span className='text-700'>
                            {serviceName} 
                            </span>
                        </Badge>
                    </div>
                )
            }
                
        },
        { 
            accessor: 'financials',
            Header: 'Shipping Profitability',
            Cell: (rowData) => {
                const { financials } = rowData.row.original;
                const orderPrice = financials?.orderPrice || 0;
                const orderCost = financials?.orderCost || 0;
                const budget = financials?.budget || 0;
                const costTotal = Number(orderCost) + Number(budget);
                const excess = Number(orderPrice) - Number(costTotal);
                const margin = (excess / Number(orderPrice)) * 100;
                let marginBadgeBg = 'primary';
                if (margin < 0) marginBadgeBg = 'dark';
                if (margin > 0 && margin < 20) marginBadgeBg = 'danger';
                if (margin > 20 && margin < 30) marginBadgeBg = 'warning';
                if (margin > 30) marginBadgeBg = 'success';
                return (
                    <SoftBadge bg={marginBadgeBg}>
                        ${excess.toFixed(2)} ({margin.toFixed(2)}%)
                    </SoftBadge>
                )
            }
                
        },
    ];

    function LabelTableActions(table){
        const { selectedRowIds, page, pageCount } = table;
        // get all rows
    
        let display = ' order'
        if (Object.keys(selectedRowIds).length > 1) display = ' orders'
        return (
          <Row className="flex-between-center mb-3">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                {
                  Object.keys(selectedRowIds).length > 0 ?
                  'You have selected ' + Object.keys(selectedRowIds).length +  display
                  :
                  ''
                }
              </h5>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
              {Object.keys(selectedRowIds).length > 0 ? (
                <div className="d-flex">
                  <Form.Select size="sm" aria-label="Bulk actions">
                    <option>Bulk Actions</option>
                    <option value="refund">Hold Order</option>
                  </Form.Select>
                  <Button
                    type="button"
                    variant="falcon-default"
                    size="sm"
                    className="ms-2"
                  >
                    Apply
                  </Button>
                </div>
                ) : (
                  <div id="orders-actions">
                    <IconButton
                        variant="falcon-default"
                        size="sm"
                        icon="external-link-alt"
                        transform="shrink-3"
                        className="me-2"
                        onClick={recalcLabels}
                        >
                      <span className="d-none d-sm-inline-block ms-1">Recalculate</span>
                    </IconButton>
                    <IconButton
                      variant="falcon-default"
                      size="sm"
                      icon="external-link-alt"
                      transform="shrink-3"
                      className="me-2"
                        onClick={() => setShowPrintModal(true)}
                    >
                      <span className="d-none d-sm-inline-block ms-1">Print Labels</span>
                    </IconButton>
                    <IconButton
                      variant="falcon-default"
                      size="sm"
                      icon="external-link-alt"
                      transform="shrink-3"
                      onClick={() => setShowExclusionsModal(true)}
                    >
                      <span className="d-none d-sm-inline-block ms-1">Manage Exclusions</span>
                    </IconButton>
                  </div>
                )}
            </Col>
          </Row>
        );
      };
    
    async function recalcLabels() {
        // get all orders that are in the labelPool
        const labelQuery = query(
            collection(db, "labelPool"),
            orderBy("timestamp.created", "desc")
        );
        const labelSnap = await getDocs(labelQuery);
        const labelRefs = labelSnap.docs.map((doc) => doc.ref);
        for (const labelRef of labelRefs) {
            updateDoc(labelRef, { status: 'OPEN'});
        }
    }

    return (
        !labelsLoading && labels && labels.length > 0 
        ?
            <>
                <AdvanceTableWrapper
                    columns={colDef}
                    data={labels}
                    sortable
                    pagination
                    perPage={50}
                    selection={true}
                    selectionColumnWidth={30}
                >
                    <LabelTableActions table/>
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                    <div className="mt-3">
                        <AdvanceTableFooter
                        rowCount={labels.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                        />
                    </div>
                </AdvanceTableWrapper>
            </>
        :
            <Lottie animationData={dotsAnimation} style={{width: 100, height: 100}} />
    )
}

const OverrideDetailModal = ({show, setShow, requested, selected, collected, financials, orderId}) => {

    const handleOverrideClick = async (rate) => {
        const payload = {
            ...rate,
            userOverride: true,
        }
        // find the order in labelPool
        const labelQuery = query(
            collection(db, "labelPool"),
            where("orderId", "==", orderId),
            limit(1)
        );
        const labelSnap = await getDocs(labelQuery);
        const labelRef = labelSnap.docs[0].ref;
        // update the labelPool record
        await updateDoc(labelRef, {
            status: 'READY FOR LABEL',
            selectedOption: payload,
        });
        // close the modal
        toast.success('Override Successful!', {theme: 'colored'});
        setShow(false);
    }

    // sort collected.shipmentCost from least to most expensive
    collected?.sort((a, b) => {
        return a.shipmentCost - b.shipmentCost;
    });

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Override Shipping 
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <p className='text-700 fs-1'
                >Budget: ${financials?.budget}</p>
                <div className='mb-2'>
                    <h6 className=''>Selected</h6>
                    <SoftBadge
                        bg='secondary'
                        className='me-2'
                    >
                    {selected?.carrierCode}
                    </SoftBadge>
                    <Badge
                        bg='light'
                    >
                        <span className='text-700'>
                        {selected?.serviceName}
                        </span>
                    </Badge>
                </div>
                <h6>Requested</h6>
                <div className='mb-2'>
                    <SoftBadge
                        bg='secondary'
                        className='me-2'
                    >
                    {requested?.carrierCode}
                    </SoftBadge>
                    <Badge
                        bg='light'
                    >
                        <span className='text-700'>
                        {requested?.requestedShippingService}
                        </span>
                    </Badge>
                </div>
                
                {collected?.length > 0 &&
                    <>
                        <h6 className='mt-3'>Collected</h6>
                        <div>
                            {collected?.map((rate, index) => {
                                let rateBadgeBg = 'success';
                                if (rate.shipmentCost > financials.budget) rateBadgeBg = 'danger';
                                return (
                                    <div key={`collected-${index}`} className='mb-3'>
                                        <IconButton
                                            size="sm"
                                            variant="falcon-default"
                                            className='me-2'
                                            onClick={() => handleOverrideClick(rate)}
                                            icon='check'
                                        >
                                        {rate?.carrierCode}
                                        </IconButton>
                                        <Badge
                                            bg={rateBadgeBg}
                                        >
                                            ${rate.shipmentCost} 
                                        </Badge>
                                        <Badge
                                            bg='light'
                                        >
                                            <span className='text-700'>
                                            {rate?.serviceName}
                                            </span>
                                        </Badge>
                                    </div>
                                )
                            })}
                        </div>
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="outline-secondary"
                    onClick={() => setShow(false)}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const ExclusionsModal = ({show, setShow}) => {
    const [exclusions, setExclusions] = useState([]);
    const [exclusionsLoading, setExclusionsLoading] = useState(false);
    const [exclusionsError, setExclusionsError] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        async function getExclusions() {
            setExclusionsLoading(true);
            const exclusionsRef = doc(db, 'shipStationMeta', 'shippingExclusions');
            const exclusionsSnap = await getDoc(exclusionsRef);
            const exclusionsData = exclusionsSnap.data();
            setExclusions(exclusionsData?.serviceCodes || []);
            setExclusionsLoading(false);
        }
        let isSubbed = true;
        if (exclusionsError) {
            console.log(exclusionsError);
            return
        }
        if (isSubbed) getExclusions();
        return () => isSubbed = false;
    }, []);

    async function saveExclusions(newExclusions) {
        setExclusionsLoading(true);
        const exclusionsRef = doc(db, 'shipStationMeta', 'shippingExclusions');
        await updateDoc(exclusionsRef, {
            serviceCodes: newExclusions
        });
        setExclusions(newExclusions);
        setExclusionsLoading(false);
    }

    async function handleNewExclusion() {
        const inputValue = inputRef.current.value;
        const newExclusions = [...exclusions, inputValue];
        saveExclusions(newExclusions);
    }

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Manage Exclusions
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!exclusionsLoading ?
                    <>
                        <h5 className='mb-3'>Current Exclusions</h5>
                        <div className='mb-3'>
                            {exclusions?.map((exclusion, index) => {
                                return (
                                    <div key={`exclusion-${index}`}>
                                        <SoftBadge
                                            key={index}
                                            bg='light'
                                            className=''
                                        >
                                            <span className='text-900'>
                                                {exclusion}
                                            </span>
                                        </SoftBadge>
                                        <IconButton
                                            variant=""
                                            className="text-danger"
                                            size="sm"
                                            icon="trash"
                                            transform="shrink-3"
                                            onClick={() => {
                                                const newExclusions = exclusions.filter((e) => e !== exclusion);
                                                saveExclusions(newExclusions);
                                            }}
                                        >
                                        </IconButton>
                                    </div>
                                )
                            })}
                        </div>
                        <h5 className='mb-3'>Add Exclusions</h5>
                        
                            <InputGroup className="">
                                <FormControl
                                    type="text"
                                    placeholder="Enter Service Code"
                                    className="flex-grow-1 me-2"
                                    ref={inputRef}
                                />
                                <IconButton
                                    variant="falcon-default"
                                    className="text-success"
                                    size="sm"
                                    icon="plus"
                                    transform="shrink-3"
                                    onClick={handleNewExclusion}
                                />
                            </InputGroup>
                        
                    </>

                :
                    <Lottie animationData={dotsAnimation} style={{width: 100, height: 100}} />
                }
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="outline-secondary"
                    onClick={() => setShow(false)}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )

}

const PrintLabelsModal = ({show, setShow, labels}) => {
    const [batches, setBatches] = useState([]);
    const [batchesLoading, setBatchesLoading] = useState(false);
    const [batchesError, setBatchesError] = useState(false);
    const labelsWithIssues = labels.filter((label) => label.status === 'REVIEW' || label.status === 'HOLD FOR NEW PRODUCT');
    const labelsReadyForPrint = labels.filter((label) => label.status === 'LABEL ASSIGNED' || label.status === 'LABEL OVERIDE USED');
    const [tabKey, setTabKey] = useState('print');

    useEffect(() => {
        async function getBatches() {
            setBatchesLoading(true);
            const batchIds = labelsReadyForPrint.map((label) => label.batchId);
            const uniqueBatchIds = [...new Set(batchIds)];
            const batchRefs = uniqueBatchIds.map((batchId) => doc(db, 'customBatches', batchId));
            const batchSnaps = await Promise.all(batchRefs.map((batchRef) => getDoc(batchRef)));
            const batchData = batchSnaps.map((batchSnap) => {
                let temp = batchSnap.data();
                temp.id = batchSnap.id;
                temp.ref = batchSnap.ref;
                return temp;
            });
            // remove duplicates
            const uniqueBatches = [...new Set(batchData)];
            // for each unique batch, get the labels and add to batch data
            for (const batch of uniqueBatches) {
                const id = batch.id;
                const labelsforBatch = labelsReadyForPrint.filter((label) => label.batchId === id);
                batch.labels = labelsforBatch;
            }
            // remove batches that are status = DELETED
            let filteredBatches = uniqueBatches;
            filteredBatches = filteredBatches.filter((batch) => batch.status !== 'DELETED');
            filteredBatches = filteredBatches.filter((batch) => batch.status !== 'CLOSED');
            setBatches(filteredBatches);
        }
        let isSubbed = true;
        if (batchesError) {
            console.log(batchesError);
            return
        }
        if (isSubbed) getBatches()
        .catch((err) => console.log(err))
        .finally(() => {
            setBatchesLoading(false)
            return () => isSubbed = false;
        });
    }, [labels]);

    const tabPillDisplay = (title, num, variant) => {
        return (
            <div className='d-flex align-items-center'>
                <span className='text-700'>
                    {title}
                </span>{" "}
                <SoftBadge
                    bg={variant || 'primary'}
                    className='ms-2 me-2'
                >
                    {num}
                </SoftBadge>
            </div>
        )
    }
      
    function ReadyToPrintTable({ batches }) {

        const columns = [
            {
                Header: 'Batch',
                accessor: 'batchName',
                Cell: (rowData) => {
                    const { batchName } = rowData.row.original;
                    return (
                        <div>
                            <SoftBadge
                                bg='secondary'
                                className='me-2'
                            >
                            {batchName}
                            </SoftBadge>
                        </div>
                    )
                }
            },
            {
                Header: 'User',
                accessor: 'assignedUser',
                Cell: (rowData) => {
                    const { assignedUser } = rowData.row.original;
                    return (
                        <div>
                            {assignedUser}
                        </div>
                    )
                }
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: (rowData) => {
                    const { status } = rowData.row.original;
                    return (
                        <div>
                            <SoftBadge
                                bg='secondary'
                                className='me-2'
                            >
                            {status}
                            </SoftBadge>
                        </div>
                    )
                }
            },
            {
                Header: 'Labels / Orders',
                accessor: 'labels.length',
                Cell: (rowData) => {
                    const { labels, orders } = rowData.row.original;
                    return (
                        <div>
                            {labels.length} / {orders.length}
                        </div>
                    )
                }
            },
            {
                Header: 'Actions',
                Cell: (rowData) => {
                    const batch = rowData.row.original;

                    return (
                        <div>
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="external-link-alt"
                                transform="shrink-3"
                                className="me-2"
                                onClick={() => {
                                    printLabels(batch);
                                }}
                            >
                                <span className="d-none d-sm-inline-block ms-1">Print</span>
                            </IconButton>
                        </div>
                    )
                }
            },
        ];

        async function prependPage(existingPdfBytes, appData) {
            // Load the existing PDF document
            const existingPdfDoc = await PDFDocument.load(existingPdfBytes);
          
            // Create a new PDF document
            const pdfDoc = await PDFDocument.create();
          
            // Embed the Helvetica font
            const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
          
            // Add a blank page to the new PDF
            const page = pdfDoc.addPage([288, 432]);
            const { height } = page.getSize();

            appData.forEach(item => {
                try {
                    if (item.type === 'text') {
                        // remove the first two characters
                        page.drawText(item.content, {
                          x: item.x,
                          y: height - item.y, // Adjusted by page height
                          font,
                          size: 24,
                          color: rgb(0, 0, 0),
                        });
                    }
                } catch (error) {
                    console.log(error, item.content);
                }
                // Handle other item types if needed
            });
          
            // Copy over pages from existing PDF
            const existingPages = await pdfDoc.copyPages(existingPdfDoc, existingPdfDoc.getPageIndices());
          
            // Add existing pages to the new PDF
            for (const existingPage of existingPages) {
              pdfDoc.addPage(existingPage);
            }
          
            // Serialize to bytes
            const mergedPdfBytes = await pdfDoc.save();
          
            return mergedPdfBytes;
          }
        
        async function convertBase64ToBytes(base64) {
            const response = await fetch(`data:application/pdf;base64,${base64}`);
            const buffer = await response.arrayBuffer();
            return new Uint8Array(buffer);
          }

        async function combinePDFs(base64PDFArray, batch) {
            console.log(base64PDFArray.length)
            const mergedPdf = await PDFDocument.create();
            for (const base64 of base64PDFArray) {
                // const pdfBytes = Buffer.from(base64, 'base64');
                // const pdfBytes = Uint8Array.from(atob(base64), c => c.charCodeAt(0));
                // const pdfBytes = new TextEncoder().encode(atob(base64));
                const pdfBytes = await convertBase64ToBytes(base64);
                const pdf = await PDFDocument.load(pdfBytes);
                const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
                copiedPages.forEach((page) => {
                    mergedPdf.addPage(page);
                });
            }
            const pdfBytes = await mergedPdf.save();
            const printTimeString = new Date().toLocaleString('en-US', { timeZone: 'America/New_York' });
            const batchNameNoEmoji = batch.batchName.replace(/[\uD800-\uDFFF]./g, '');
            batchNameNoEmoji.replace('-', "");
            return prependPage(pdfBytes, [
                {
                  type: 'text',
                  content: `Batch: ${batchNameNoEmoji}`,
                  x: 30,
                  y: 120
                },
                {
                  type: 'text',
                  content: `User: ${batch.assignedUser === undefined ? 'Unassigned' : batch.assignedUser}`,
                  x: 30,
                  y: 80
                },
                {
                    type: 'text',
                    content: `${printTimeString}`,
                    x: 30,
                    y: 200
                },
                {
                    type: 'text',
                    content: `Total Orders: ${batch.orders.length}`,
                    x: 30,
                    y: 240
                },
                {
                    type: 'text',
                    content: `Total Labels: ${batch.labels.length}`,
                    x: 30,
                    y: 280
                },

              ]);
        }

        async function printLabels(batch) {
            try {
                const base64PDFArray = [];
                
                const labels = batch.labels;
                for (const label of labels) {
                    if (label.label !== "NONE") {
                        base64PDFArray.push(label.label.labelData);
                    }
                }
                
                const pdfBytes = await combinePDFs(base64PDFArray, batch);
                const blob = new Blob([pdfBytes], { type: 'application/pdf' });
                const blobUrl = URL.createObjectURL(blob);
                window.open(blobUrl);
            } catch (error) {
                console.log(error);
            }
            
        }

        
        return (
          <AdvanceTableWrapper
            columns={columns}
            data={batches}
            sortable
            pagination
            perPage={10}
          >
            <Row className="flex-end-center mb-3">
              <Col lg={12}>
                <AdvanceTableSearchBox table/>
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
            <div className="mt-3">
              <AdvanceTableFooter
                rowCount={batches.length}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
        );
    }

    function LabelsWithIssuesTable({ labels }) {
        const columns = [
            {
                Header: 'Order',
                accessor: 'orderNumber',
                Cell: (rowData) => {
                    const { orderNumber, orderId } = rowData.row.original;
                    return (
                        <div>
                            <Link
                                to={`/orders/${orderId}`}
                            >
                                {orderNumber}
                            </Link>
                        </div>
                    )
                }
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: (rowData) => {
                    const { status } = rowData.row.original;
                    return (
                        <div>
                            {status}
                        </div>
                    )
                }
            },
            {
                Header: 'Requested',
                accessor: 'requestedShipping.requestedShippingService',
                Cell: (rowData) => {
                    const { requestedShipping } = rowData.row.original;
                    return (
                        <div>
                            {requestedShipping.requestedShippingService}
                        </div>
                    )
                }
            },
            {
                Header: 'Selected',
                accessor: 'selectedOption.serviceName',
                Cell: (rowData) => {
                    const { selectedOption } = rowData.row.original;
                    if (!selectedOption) return (`Awaiting Label`);
                    return (
                        <div>
                            {selectedOption?.serviceName} - ${selectedOption.shipmentCost}
                        </div>
                    )
                }
            },
            {
                Header: 'Budget',
                accessor: 'financials.budget',
                Cell: (rowData) => {
                    const { financials } = rowData.row.original;
                    if (!financials) return (`Awaiting Label`);
                    let budgetMessage = 
                        <FontAwesomeIcon
                            variant="falcon-default"
                            size="sm"
                            icon="exclamation-triangle"
                            transform="shrink-3"
                            className="me-2"
                        />;
                    if (financials.budget > 0) budgetMessage = 'Available';
                    const analysis = financials.orderPrice - financials.orderCost;
                    if (isNaN(analysis)) budgetMessage = 'No Cost Information';
                    return (
                        <div>
                            {budgetMessage} 
                            {!isNaN(analysis) && <SoftBadge
                                bg='secondary'
                                className='ms-2'
                            > Excess: ${analysis.toFixed(2)} </SoftBadge> }
                        </div>
                    )
                }
            },
            {
                Header: 'Actions',
                Cell: (rowData) => {
                    const { financials } = rowData.row.original;
                    
                    return (
                        <div>
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="external-link-alt"
                                transform="shrink-3"
                                className="me-2"
                                onClick={() => {
                                    console.log('something')
                                }}
                            >
                                <span className="d-none d-sm-inline-block ms-1">Select Rate</span>
                            </IconButton>
                        </div>
                    )
                }
            },
        ];
        

        return (
          <AdvanceTableWrapper
            columns={columns}
            data={labels}
            sortable
            pagination
            perPage={10}
          >
            <Row className="flex-end-center mb-3">
              <Col lg={12}>
                <AdvanceTableSearchBox table/>
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
            <div className="mt-3">
              <AdvanceTableFooter
                rowCount={labels.length}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
        );
    }

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            size="fullscreen"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Print Labels
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!batchesLoading ?
                    <Tabs
                        id="label-printing-interface"
                        className="mb-3 border-bottom-0 fs--1 font-weight-semi-bold border-top-0 border-start-0 border-end-0"
                        activeKey={tabKey}
                        onSelect={(k) => setTabKey(k)}
                    >   
                        <Tab 
                            eventKey="print" 
                            title={tabPillDisplay("Ready to Print", labelsReadyForPrint.length, "success")} 
                            className='p-0'>
                            {labelsReadyForPrint.length > 0 ?
                                <div>
                                    {batches.length > 0 && <ReadyToPrintTable batches={batches} /> }
                                </div>
                            :
                                <div className='text-center'>
                                    No labels ready to print!
                                </div>
                            }
                        </Tab>
                        <Tab 
                            eventKey="issues" 
                            title={tabPillDisplay("Issues", labelsWithIssues.length, "danger")}
                            className='p-0'>
                            {labelsWithIssues.length > 0 ?
                                <div>
                                    {labelsWithIssues.length && <LabelsWithIssuesTable labels={labelsWithIssues} /> }
                                </div>
                            :
                                <div className='text-center'>
                                    No labels with issues
                                </div>
                            }
                        </Tab>
                    </Tabs>
                :
                    <Lottie animationData={dotsAnimation} style={{width: 100, height: 100}} />
                }
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="outline-secondary"
                    onClick={() => setShow(false)}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )

}

const ShippingLabels = ({ children }) => {

    const { user, authLoading }= useContext(FirebaseAuthContext);
    const navigate = useNavigate();

    
    const [showExclusionsModal, setShowExclusionsModal] = useState(false);
    const [showOverrideModal, setShowOverrideModal] = useState(false);
    const [showPrintModal, setShowPrintModal] = useState(false);
    const [overrideData, setOverrideData] = useState({});
    
    // Get all batched orders without shipping labels!
    const [masterLoading, setMasterLoading] = useState(false);
    const [labelsRaw, labelsLoading, labelsError] = useCollection(
        query(
            collection(db, "labelPool"),
            orderBy("timestamp.updated", "desc")
        )
    );
    const [labels, setLabels] = useState([]);
    useEffect(() => {
        if (labelsLoading) return;
        setMasterLoading(true);
        if (labelsError) {
            console.log("ERR", labelsError);
            setMasterLoading(false)
            return;
        }
        let isSubbed = true;
        if (labelsRaw.size > 0) {
            const newLabels = [];
            labelsRaw.docs.forEach((label) => {
                const temp = label.data();
                temp.id = label.id;
                temp.ref = label.ref;
                newLabels.push(temp);
            });
            if (isSubbed) setLabels(newLabels);
        }
        setMasterLoading(false);
        return () => isSubbed = false;
    }, [labelsRaw]);


    // Labeling Analysis
    const [labelFilter, setLabelFilter] = useState('ALL');
    const [filteredLabels, setFilteredLabels] = useState();
    const initialAnalysisState = () => ({
        OPEN: 0,
        RETRY: 0,
        REVIEW: 0,
        "READY FOR LABEL": 0,
        "DISCOVERING OPTIONS": 0,
        "LABELING IN PROCESS": 0,
        "LABEL ASSIGNED": 0,
        "LABEL OVERRIDE USED": 0,
        "ERROR": 0,
        "HOLD NEW PRODUCT": 0,
    })
    const labelStateMachine = (state, action) => {
        let newState;
        switch (action.type) {
            case "RESET":
                return initialAnalysisState();
            case "OPEN":
                newState = {
                    ...state,
                    OPEN: state.OPEN + 1,
                }
                return newState;
            case 'RETRY':
                newState = {
                    ...state,
                    RETRY: state.RETRY + 1,
                }
                return newState;
            case 'REVIEW':
                newState = {
                    ...state,
                    REVIEW: state.REVIEW + 1,
                }
                return newState;
            case 'READY FOR LABEL':
                newState = {
                    ...state,
                    "READY FOR LABEL": state["READY FOR LABEL"] + 1,
                }
                return newState;
            case 'DISCOVERING OPTIONS': 
                newState = {
                    ...state,
                    "DISCOVERING OPTIONS": state["DISCOVERING OPTIONS"] + 1,
                }
                return newState;
            case 'LABELING IN PROCESS':
                newState = {
                    ...state,
                    "LABELING IN PROCESS": state["LABELING IN PROCESS"] + 1,
                }
                return newState;
            case 'LABEL ASSIGNED':
                newState = {
                    ...state,
                    "LABEL ASSIGNED": state["LABEL ASSIGNED"] + 1,
                }
                return newState;
            case 'LABEL OVERRIDE USED':
                newState = {
                    ...state,
                    "LABEL OVERRIDE USED": state["LABEL OVERRIDE USED"] + 1,
                }
                return newState;
            case 'HOLD NEW PRODUCT':
                newState = {
                    ...state,
                    "HOLD NEW PRODUCT": state["HOLD NEW PRODUCT"] + 1,
                }
                return newState;
            case 'ERROR':
                newState = {
                    ...state,
                    "ERROR": state["ERROR"] + 1,
                }
                return newState;
            default:
                return state;
        }
    }
    const [labelAnalysis, labelStateDispatch] = useReducer(labelStateMachine, initialAnalysisState());
    // below counts the labels in various states
    useEffect(() => {
        if (labelsLoading) return;
        let isSubbed = true;
        labelStateDispatch({type: 'RESET'});
        if (labels.length > 0) {
            labels.forEach((label) => {
                const temp = label.status;
                if (isSubbed) labelStateDispatch({type: temp});
            });
        }
        if (isSubbed) setFilteredLabels(labels);
        return () => isSubbed = false;
    }, [labels]);
    const chooseBgColor = (status) => {
        switch (status) {
            case 'RETRY': return 'warning';
            case 'REVIEW': return 'danger';
            case 'READY FOR LABEL': return 'info';
            case 'DISCOVERING OPTIONS': return 'info';
            case 'LABELING IN PROCESS': return 'info';
            case 'LABEL ASSIGNED': return 'success';
            case 'LABEL OVERRIDE USED': return 'success';
            case 'HOLD NEW PRODUCT': return 'warning';
            case 'ERROR': return 'danger';
            default: return 'secondary';
        }
    }
    const applyFilter = (filter) => {
        setLabelFilter(filter);
        viewStateDispatch({type: 'FILTER', payload: filter});
        if (filter === 'ALL') return setFilteredLabels(labels);
        const newFilteredLabels = labels.filter((label) => label.status === filter);
        setFilteredLabels(newFilteredLabels);
    }
    const initialViewSettings = () => ({
        filter: "ALL",
    });
    const viewStateMachine = (state, action) => {
        let newState;
        switch (action.type) {
            case "RESET":
                return initialViewSettings();
            case "FILTER":
                newState = {
                    ...state,
                    filter: action.payload,
                }
                return newState;
            default:
                return state;
        }
    }
    const [viewSettings, viewStateDispatch] = useReducer(viewStateMachine, initialViewSettings());
    useEffect(() => {
        if (labelsLoading) return;
        let isSubbed = true;
        if (isSubbed) applyFilter(viewSettings.filter);
        return () => isSubbed = false;
    }, [labels]);
    
    return (
        !labelsLoading ?
        <>
            <PageHeader title={`Shipping Labels`} className="mb-3">
                    <div className='text-700 fw-semi-bold mt-3 mb-2'>
                        Viewing <strong>{labelFilter}</strong> ({filteredLabels?.length} of {labels?.length} orders)
                    </div>
                    <div key={`label-analysis-all-items`} className="mt-3 d-inline" onClick={() => applyFilter('ALL')}>
                        <SoftBadge
                            bg={chooseBgColor('ALL')}
                            className='me-2'
                        >
                            {labels.length} - ALL 
                        </SoftBadge>
                    </div>
                    {Object.keys(labelAnalysis).map((key, index) => {
                        if (labelAnalysis[key] > 0) {
                            return (
                                <div key={`label-analysis-${index}`} className="mt-3 d-inline" onClick={() => applyFilter(key)}>
                                    <SoftBadge
                                        
                                        bg={chooseBgColor(key)}
                                        className='me-2'
                                        
                                    >
                                        {labelAnalysis[key]} - {key} 
                                    </SoftBadge>
                                </div>
                            )
                        } 
                    })}
                
            </PageHeader>
            <Card className='p-3 mb-3'>
                { !labelsLoading ? 
                  <NeedsLabelTable 
                    labels={filteredLabels}
                    printModal={[showPrintModal, setShowPrintModal]}
                    exclusionsModal={[showExclusionsModal, setShowExclusionsModal]}
                    overrideModal={[showOverrideModal, setShowOverrideModal]}
                    overrideData={[overrideData, setOverrideData]}
                    navigate={navigate}
                    labelsLoading={masterLoading}
                  />
                : <Lottie animationData={dotsAnimation} style={{width: 100, height: 100}} /> }
            </Card>
            <PrintLabelsModal show={showPrintModal} setShow={setShowPrintModal} labels={labels}/>
            <ExclusionsModal show={showExclusionsModal} setShow={setShowExclusionsModal} />
            <OverrideDetailModal 
                show={showOverrideModal}
                setShow={setShowOverrideModal}
                requested={overrideData.requested}
                selected={overrideData.selected}
                collected={overrideData.collected}
                financials={overrideData.financials}
                orderId={overrideData.orderId}
            />
        </>
        :
        <Lottie animationData={dotsAnimation} style={{width: 100, height: 100}} />
                
    );
}

export default ShippingLabels;
