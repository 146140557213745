import { db } from "config/firebase"
import AdvanceTable from "components/merlin/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/merlin/advance-table/AdvanceTableWrapper";
import { useEffect, useState } from "react";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import DatePicker, { registerLocale } from 'react-datepicker';
import { arrayRemove, arrayUnion, updateDoc } from "firebase/firestore";
import { toast } from 'react-toastify';
import SoftBadge from "components/common/SoftBadge";

export default function Discounts({ product, docRef }){

    const [modalShow, setModalShow] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [discountName, setDiscountName] = useState("");
    const [discountValue, setDiscountValue] = useState("");

    const discounts = product?.discounts || [];

    const columns = [
        {
            accessor: 'active',
            Header: 'Status',
            Cell: rowData => {
                const { startDate, endDate } = rowData.row.original;
                const now = new Date();
                const active = startDate?.toDate() < now && endDate?.toDate() > now;
                let status;
                if (active) { 
                    status = 'Active'
                } else {
                    status = 'Suspended'
                }

                return (
                    <SoftBadge bg={active ? "success" : "danger"}>{status}</SoftBadge>
                )
            }
        },
        {
          accessor: 'name',
          Header: 'Name'
        },
        
        {
            accessor: 'value',
            Header: 'Amount',
            Cell: rowData => {
                const { value } = rowData.row.original;
                const formmattedValue = Number(value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                return (
                    <span>{formmattedValue}</span>
                )
            }

        },
        {
            accessor: 'startDate',
            Header: 'Start Date',
            Cell: rowData => {
                const { startDate } = rowData.row.original;
                const startDateString = startDate?.toDate().toLocaleDateString();
                return (
                    <span>{startDateString}</span>
                )
            }
        },
        {
            accessor: 'endDate',
            Header: 'End Date',
            Cell: rowData => {
                const { endDate } = rowData.row.original;
                const endDateString = endDate?.toDate().toLocaleDateString();
                return (
                    <span>{endDateString}</span>
                )
            }
        },
      ];
    
    
    
    function BulAction(all){

        const { selectedRowIds, page } = all;
        const rows = page.map((row) => {
            let temp = row.original;
            temp.rowId = row.id;
            return temp
        });

        async function handleApply(e) {
            e.preventDefault();
            const el = document.getElementById('discountActionOptions');
            const action = el.value;
            switch (action) {
                case 'Suspend':
                    await handleSuspend(selectedRowIds, rows)
                    break;
                case 'Delete':
                    await handleDelete(selectedRowIds, rows)
                    break
                default:
                    break;
            }
        }  
    
        async function handleDelete(selectedRowIds, rows) {
            const selectedRows = rows.filter((row) => selectedRowIds[row.rowId]);
            for (let i = 0; i < selectedRows.length; i++) {
                const data = selectedRows[i];
                const arrayMatch = {
                    name: data.name,
                    startDate: data.startDate,
                    endDate: data.endDate, 
                    value: data.value
                }
                try {
                   await updateDoc(docRef.ref, { discounts: arrayRemove(arrayMatch)})
                   toast.success(`Discount ${discountName} - Removed`, {
                    position: 'top-center',
                    theme: 'colored'
                  });
                } catch (error) {
                    console.log(error)
                }
            }
        }


      return (
        <Row className="flex-between-center mb-3">
        <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
            {
                Object.keys(selectedRowIds).length > 0 ?
                'Selected ' + Object.keys(selectedRowIds).length + ' rows' 
                :
                ''
            }
            </h5>
        </Col>
        <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
            {Object.keys(selectedRowIds).length > 0 ? (
            <div className="d-flex">
                <Form.Select size="sm" aria-label="Actions" id="discountActionOptions">
                    <option>Bulk Actions</option>
                    <option value="Suspend">Suspend</option>
                    <option value="Delete">Delete</option>
                </Form.Select>
                <Button
                type="button"
                variant="falcon-default"
                size="sm"
                className="ms-2"
                onClick={handleApply}
                >
                    Apply
                </Button>
            </div>
            ) : (
                <div id="orders-actions">
                <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="plus"
                    transform="shrink-3"
                    className='me-2'
                    onClick={() => setModalShow(true)}
                >
                    <span className="d-none d-sm-inline-block ms-1">New</span>
                </IconButton>
                </div>
            )}
        </Col>
        </Row>
      );
    };
    
    function DiscountsTable() {
    
    return(
        <AdvanceTableWrapper
        columns={columns}
        data={discounts}
        sortable
        pagination
        perPage={5}
        selection
        selectionColumnWidth={30}
        >
        <BulAction table/>
        <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
            }}
        />
        </AdvanceTableWrapper>
    )
    }
    

    async function saveDiscount(productRef, discountName, discountValue, startDate, endDate){
        try {
            const newDiscount = {
                name: discountName,
                value: discountValue,
                startDate: startDate,
                endDate: endDate
            }
            const update = updateDoc(productRef.ref, {
                discounts: arrayUnion(newDiscount)
            })
            toast.success(`Discount ${discountName} - Addded`, {
                position: 'top-center',
                theme: 'colored'
              });
        } catch (error) {
            console.log(error)
        }
    
    }
      

    return (
        <div className="px-0 mt-2 mb-2 p-1">
            <DiscountsTable />
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Add New Discount or Promotion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="discountName">
                        <Form.Label>Discount Name</Form.Label>
                        <Form.Control placeholder="Allowance" onChange={(e) => setDiscountName(e.target.value)}/>
                    </Form.Group>
                    <DatePicker
                        selected={startDate}
                        onChange={(date)=>setStartDate(date)}
                        formatWeekDay={day => day.slice(0, 3)}
                        className='form-control mb-2'
                        placeholderText="Select Start Date"
                    />
                    <DatePicker
                        selected={endDate}
                        onChange={(date)=>setEndDate(date)}
                        formatWeekDay={day => day.slice(0, 3)}
                        className='form-control mb-2'
                        placeholderText="Select End Date"
                    />

                    <Form.Group className="mb-3" controlId="inlineRadio">
                        <Form.Label
                            className="me-2"
                        >Select the basis for the discount</Form.Label>
                        <Form.Check
                            inline
                            type='radio'
                            id='costBasisRadio'
                            label='Cost Basis'
                            name='inline-radio'
                        />
                        <Form.Check
                            inline 
                            type='radio'
                            id='marginBasisRadio'
                            label='Margin Basis'
                            disabled
                            name='inline-radio'
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="inlineRadio">
                        <Form.Label
                            className="me-2"
                        >Enter Discount</Form.Label>
                        <Form.Check
                            inline
                            type='radio'
                            id='fixedAmountRadio'
                            label='Fixed Amount'
                            name='inline-cost-radio'
                            
                        />
                        <Form.Check
                            inline 
                            type='radio'
                            id='percentAmountRadio'
                            label='Percent Amount'
                            disabled
                            name='inline-margin-radio'
                            
                        />
                        <Form.Control placeholder="" onChange={(e) => setDiscountValue(Number(e.target.value))} />
                        <Form.Label>
                            <h1 className="display-6 mt-3 mb-2 fs-1 text-primary">
                                New Core Cost: ${Number(product.cost).toFixed(2) - Number(discountValue).toFixed(2)}
                            </h1>
                        </Form.Label>
                    </Form.Group>


                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setModalShow(false)}>Cancel</Button>
                    <Button 
                        variant="success"
                      onClick={() => {
                        saveDiscount(docRef, discountName, discountValue, startDate, endDate)
                        setModalShow(false)}
                    }>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
