import React, { useState, useEffect, useContext } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { auth, db } from 'config/firebase';
import {
  addDoc,
  where,
  query,
  collection,
  getDocs,
  limit,
  getDoc
} from 'firebase/firestore';
import {
  Spinner,
  Card,
  Row,
  Col,
  Button,
  ButtonToolbar, 
  ButtonGroup,
  Modal, 
  Form} from 'react-bootstrap';
import FalconCloseButton from 'components/common/FalconCloseButton';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import PageHeader from 'components/common/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import { toast } from 'react-toastify';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import axios from 'axios';

const TradeHubProducts = () => {
  //const [user, loading] = useAuthState(auth);
  const { user, authLoading }= useContext(FirebaseAuthContext)
  const navigate = useNavigate();
  const [addVendorButtonActive, setAddVendorButtonActive] = useState(false);
  //const [vendors, setVendors] = useState([{name: 'vendor', vendorId: 'loading'}]);
  const [showAddVendorModal, setShowAddVendorModal] = useState(false);
  const [newVendorName, setNewVendorName] = useState();
  const [newContactName, setNewContactName] = useState();
  const [newContactEmail, setNewContactEmail] = useState();

  /** Collect and process Vendor collection data */
  const [vendors, vendorsLoading, vendorsError] = useCollectionData(query(collection(db, 'tradeHubProducts')))
  //const [vendors, setVendors] = useState([]);
  // use a useEffect to set the Vendors state after collection is loaded
  // useEffect(() => {
  //   let isSubscribed = true;
  //   if (vendorsLoading) return;
  //   if (vendorsError) {
  //     console.log("VendorCollectionRetrievalError", vendorsError)
  //     return;
  //   }
  //   if (!vendorsRaw) return;
  //   let vendorArray = [];
  //   vendorsRaw.forEach((doc) => {
  //     let temp = doc.data();
  //     temp.vendorId = doc.id;
  //     vendorArray.push(temp)
  //   })
  //   if (isSubscribed) setVendors(() => vendorArray)
  //   return () => isSubscribed = false;
  // }, [vendorsRaw, vendorsLoading, vendorsError])

  const columns = [
    {
      accessor: 'name',
      Header: 'Product Name',
      Cell: rowData => {
        const { name, email  } = rowData.row.original;
        
        return (
          <div>
            <a href={`mailto:${email}`}>{name}</a>
          </div>
        )
      }
    },
    {
      accessor: 'orgId',
      Header: 'Organization',
      Cell: rowData => {
        let { orgId } = rowData.row.original;

        return (
          <div>
            {orgId}
          </div> 
        );
      }
    },
    {
      accessor: 'category',
      Header: 'Actions',
      Cell: rowData => {

        return (
          <div>
           {false ?
              <SoftBadge bg="danger" size="sm" >Reject Product</SoftBadge>
            : <Button variant="outline-success" size="sm">Approve Product</Button>
            }
          </div> 
        );
      }
    },

  ];

  useEffect(() => {
    if (authLoading) return;
    if (!user) return navigate('/');
    
  }, [user, authLoading, navigate]);


  async function updateUserStatus(uid) {
    try {

      const reqConfig = {
        data: {
          apiKey: "R3kqZ7lG0w4bV8y2",
          userId: String(uid),
          state: "COMPLETE"
        },
        method: 'POST',
        url: 'https://updateonboardingstate-2y7un444sa-uc.a.run.app',
        headers: {
          'Content-Type': 'application/json'
        }
      }
      const response = await axios(reqConfig)
      
    } catch (error) {
      console.log(error)
    }
  }


  return (
    <>
    <PageHeader

    title="TradeHub User Management" titleTag="h5" className="mb-3">

    </PageHeader>
    
    
        {!vendorsLoading ? (
          <Card className="p-3">
          {!vendorsLoading && vendors.length !== 0 ? (
              <AdvanceTableWrapper
              columns={columns}
              data={vendors}
              sortable
              pagination
              perPage={10}
            >
              <Row className="flex-end-center mb-3">
                <Col xs="auto" sm={6} lg={4}>
                  <AdvanceTableSearchBox table/>
                </Col>
              </Row>
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
              <div className="mt-3">
                <AdvanceTableFooter
                  rowCount={vendors.length}
                  table
                  rowInfo
                  navButtons
                  rowsPerPageSelection
                />
              </div>
            </AdvanceTableWrapper>
          ) : (
            <Row className="align-items-center">
              <Col>
                <Spinner animation="border" variant="primary">
                  <span className='visually-hidden'>Loading...</span>
                </Spinner>
              </Col>
            </Row>
          )
          }
          </Card>
        ) : 
          <Row className="align-items-center">

            <Col>
              <Spinner animation="border" variant="primary">
                <span className='visually-hidden'>Loading...</span>
              </Spinner>
            </Col>
          
          </Row>
          }
      
    </>
  );
};

export default TradeHubProducts;
