import React, { useState, useEffect, useContext } from 'react';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import { Accordion, Card, Col, Container, Form, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { db } from 'config/firebase.js'
import { collection, where, limit, query, getDocs} from 'firebase/firestore';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
const NotificationSettings = () => {

  const { user, authLoading }= useContext(FirebaseAuthContext)
  const [userSettings, setUserSettings] = useState(null);
  useEffect(() => {
    if (authLoading) return;
    if (!user) return;
    let isSubbed = true;
    const getUserSettings = async () => {
      const userSettingsQuery = query(
        collection(db, "notificationMeta"),
        where("uid", "==", user.uid),
        limit(1)
      );
      const userSettingsSnapshot = await getDocs(userSettingsQuery);
      const userSettings = [];
      userSettingsSnapshot.forEach((doc) => {
        let temp = doc.data();
        temp.docRef = doc.ref;
        userSettings.push(temp);
      });
      return userSettings[0];
    }
    getUserSettings().then((userSettings) => {
      if (isSubbed) setUserSettings(userSettings);
    });
    return () => isSubbed = false;
  }, [user, authLoading]);

  const NotificationSettingsWidget = ({ userSettings }) => {

    const SubscribedWidget = ({ subscribed }) => {
      return (
        <div className='d-flex align-items-center'>
          <div className={classNames('badge', 'rounded-pill', 'me-2', {
            'bg-success': subscribed,
            'bg-danger': !subscribed
          })} />
          <span className={classNames('text-nowrap', {
            'text-success': subscribed,
            'text-danger': !subscribed
          })}>
            {subscribed ? 'Subscribed' : 'Not Subscribed'}
          </span>
        </div>
      )
    }

    const ChannelWidget = ({ topic, channel, disabled }) => {
      return (
        <Form className='m-1'>
          <Form.Check
            type='switch'
            id={channel}
            label={channel}
            checked={userSettings?.activeTopics?.includes(topic)}
            disabled={disabled}
            onChange={(e) => {
              let temp = userSettings;
              if (e.target.checked) {
                temp.activeChannels.push(channel);
              } else {
                temp.activeChannels = temp.activeChannels.filter((item) => item !== channel);
              }
              setUserSettings(temp);
            }}
          />
        </Form>
      )
    }

    const ChannelOptions = ({ topic }) => {
      return (
        <Flex>
          <ChannelWidget topic={topic} channel='App' />
          <ChannelWidget topic={topic} channel='Email' disabled />
          <ChannelWidget topic={topic} channel='SMS' disabled />
        </Flex>
      )
    }

    return (
      <Container>
        <Row className='fw-bold text-900 mb-4'>
          <Col>Topic</Col>
          <Col>Subscribed</Col>
          <Col>Channels</Col>
        </Row>
        <Row>
          <Col>Orders</Col>
          <Col>
            <SubscribedWidget subscribed={userSettings?.activeTopics?.includes('ORDERS')} />
          </Col>
          <Col>
            <ChannelOptions topic={'ORDERS'}/>
          </Col>
        </Row>
        <Row>
          <Col>SellerActive</Col>
          <Col>
            <SubscribedWidget subscribed={userSettings?.activeTopics?.includes('SELLERACTIVE')} />
          </Col>
          <Col>
            <ChannelOptions topic={'SELLERACTIVE'}/>
          </Col>
        </Row>
        <Row>
          <Col>General Notifications</Col>
          <Col>
            <SubscribedWidget subscribed={userSettings?.activeTopics?.includes('GENERAL')} />
          </Col>
          <Col>
            <ChannelOptions topic={'GENERAL'}/>
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <Card>
      <FalconCardHeader title="Notification Settings" />
      <Card.Body className="bg-light">
        
        <NotificationSettingsWidget userSettings={userSettings} />
        
      </Card.Body>
    </Card>
  );
};

export default NotificationSettings;
