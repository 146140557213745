import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import team3 from 'assets/img/team/avatar.png';
import Avatar from 'components/common/Avatar';
import { logout } from 'config/firebase';
import { Button } from 'react-bootstrap';

const ProfileDropdown = () => {
  

  return (
    <Dropdown navbar={true} as="li" key="profileDropDownNavItemThing">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={team3} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item as={Link} to="/user/profile">
            Account Profile
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to="/user/settings">
            User Settings
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item>
            <Button onClick={logout}> Logout</Button>
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
