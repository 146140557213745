// grid components
// value getters
import OrderAgeGetter from '../components/valueGetters/OrderAgeGetter';
// value formatters
import OrderDateFormatter from '../components/valueFormatters/OrderDateFormatter';
import Currency from 'components/pages/newOrders/components/valueFormatters/Currency';
import TagIdValueFormatter from 'components/pages/newOrders/components/valueFormatters/TagIdValueFormatter';
// cell renderers
import OrderItemsRenderer from '../components/cellRenderers/OrderItemsRenderer';
// import InStock from 'components/pages/newOrders/components/cellRenderers/InStock';
import OrderStatusRenderer from '../components/cellRenderers/OrderStatusRenderer';
import TagRenderer from 'components/pages/newOrders/components/cellRenderers/TagRenderer';
import OrderSourceRenderer from 'components/pages/newOrders/components/cellRenderers/OrderSourceRenderer';

export const columnDefinition = [
    {
        headerCheckboxSelection: true,
        checkboxSelection: true,
        maxWidth: 40,
        suppressSizeToFit: false,
        suppressMenu: false,
        resizable: false,
        sortable: true,
    },
    {
        field: "orderDateString",
        filter: true,
        headerName: "Age",
        maxWidth: 50,
        valueGetter: OrderAgeGetter,
    },
    {
        field: "orderDateString",
        filter: true,
        headerName: "Date",
        valueFormatter: OrderDateFormatter,
    },
    { 
        field: "orderNumber",
        filter: true,
        floatingFilter: true,
    },
    {
        field: "orderStatus",
        filter: true,
        cellRenderer: OrderStatusRenderer,
    },
    {
        field: "orderSource",
        filter: true,
        cellRenderer: OrderSourceRenderer,
    },
    {
        field: "items",
        filter: true,
        headerName: "Items",
        cellRenderer: OrderItemsRenderer,
        minWidth: 300,
    },
    {
        field: "merlinBatchName",
        headerName: "Merlin Batch",
        filter: true,
        sortable: true,
    },
    {
        field: "batchName",
        headerName: "SS Batch",
        filter: true,
        sortable: true,
    },
    {
        field: "warehouseLocation",
        headerName: "Location",
        filter: true,
        sortable: true,
    },
    {
        field: "itemNames",
        filter: true,
        headerName: "Item Names",
        hide: true,
    },
    {
        field: "itemSkus",
        filter: true,
        headerName: "Item Skus",
        hide: true,
    },
    {
        field: "orderTotal",
        headerName: "Total",
        filter: true,
        maxWidth: 100,
        valueFormatter: Currency,
        cellClass: 'text-end',
    },
    {
        field: "shipToZone",
        headerName: "Zone",
        maxWidth: 50,
        filter: true,
    },
    {
        field: "tagIds",
        headerName: "Tags",
        filter: 'agSetColumnFilter',
        filterParams: {
            valueFormatter: TagIdValueFormatter,
        },
        cellRenderer: TagRenderer,
    },
    {
        field: "shipToCountry",
        headerName: "Country",
        filter: true,
    },
    {
        field: "shipToState",
        headerName: "State",
        filter: true,
    },
    {
        field: "shipToName",
        headerName: "Recipient",
        filter: true,
    },


]