import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from 'config/firebase';
import {
  query,
  collection,
  where,
  getDocs,
  addDoc,
  updateDoc
} from 'firebase/firestore';
import {
  Spinner,
  Card,
  Row,
  Col,
  Button
} from 'react-bootstrap';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import PageHeader from 'components/common/PageHeader';
import SoftBadge from 'components/common/SoftBadge';
import { toast } from 'react-toastify';
import { useCollection } from 'react-firebase-hooks/firestore';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import axios from 'axios';
import { QRCodeCanvas } from 'qrcode.react';

const CreateCustomQr = () => {
  const { user, authLoading }= useContext(FirebaseAuthContext)
  const navigate = useNavigate();
  useEffect(() => {
    if (authLoading) return;
    if (!user) return navigate('/');    
  }, [user, authLoading, navigate]);

  const [qrCodeDestination, setQrCodeDestination] = useState(null);

  async function addRecipient() {
    const recipientName = document.getElementById('recipientName').value;
    const recipientAddress = document.getElementById('recipientAddress').value;
    const recipientCity = document.getElementById('recipientCity').value;
    const recipientState = document.getElementById('recipientState').value;
    const recipientZip = document.getElementById('recipientZip').value;
    const recipientNotes = document.getElementById('recipientNotes').value;
    const recipient = {
      name: recipientName,
      address: recipientAddress,
      city: recipientCity,
      state: recipientState,
      zip: recipientZip,
      notes: recipientNotes
    }
    const payload = {
      sentTo: recipient,
      status: 'open',
      created: new Date(),
      campaignId: 'Vdj8IZHYXuPvZZ3lp5r9',
    }
    try {
      const res = await addDoc(collection(db, 'postcardRecipients'), payload);
      return res;
    } catch (error) {
      console.log(error)
    }
  }

  async function generateQrCode(ref) {
    const id = ref.id;
    setQrCodeDestination("https://us-central1-stk-merlin.cloudfunctions.net/postcardRedirect?id=" + id);
    const canvas = document.getElementById('qrCode').getElementsByTagName('canvas')[0];
    const imgData = canvas.toDataURL("image/png");
    await updateDoc(ref, { qrCode: imgData })
    toast.success("QR Code generated successfully", { theme: "colored" })
  }


  async function handleAddRecipient() {
    try {
      const ref = await addRecipient();
      generateQrCode(ref);
      toast.success("Recipient added successfully", { theme: "colored" })
    } catch (error) {
      console.log(error)
      toast.error("Error adding recipient", { theme: "colored" })
    }
  }

  async function handleQrDownload() {
    const canvas = document.getElementById('qrCode').getElementsByTagName('canvas')[0];
    const imgData = canvas.toDataURL("image/png");
    const link = document.createElement('a');
    link.download = 'qrCode.png';
    link.href = imgData;
    link.click();
  }
  
  return (
    <>
      <PageHeader
        title="Add Recipient" titleTag="h5" className="mb-3">
      </PageHeader>
      <Card className="p-3">
        <Row>
          <Col>
            <h5>Recipient Information</h5>
            <p>Enter the recipient's information below.</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <label htmlFor="recipientName">Name</label>
            <input type="text" id="recipientName" className="form-control" /> 
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <Col>
                <label htmlFor="recipientAddress">Address Line 1</label>
                <input type="text" id="recipientAddress" className="form-control" />
              </Col>
            </Row>
            <Row>
              <Col>
                <label htmlFor="recipientAddress">Address Line 2</label>
                <input type="text" id="recipientAddress" className="form-control" />
              </Col>
            </Row>
          </Col>
          <Col>
          <Col>
              <label htmlFor="recipientCity">City</label>
              <input type="text" id="recipientCity" className="form-control" />
            </Col>
            <Row>
              <Col>
                <label htmlFor="recipientState">State</label>
                <input type="text" id="recipientState" className="form-control" />
              </Col>
              <Col>
                <label htmlFor="recipientZip">Postal Code</label>
                <input type="text" id="recipientZip" className="form-control" />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <label htmlFor="recipientNotes">Notes</label>
            <textarea id="recipientNotes" className="form-control" />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button variant="primary" className="mt-3" onClick={() => handleAddRecipient()}>Add Recipient</Button>
          </Col>
        </Row>
      </Card>
      {qrCodeDestination !== null ?
        <Card className="p-3 mt-3">
          <div id="qrCode" className="text-center">
            <QRCodeCanvas 
              value={qrCodeDestination}
              fgColor={"#F21980"}
              level={'Q'}
              size={256}
              renderAs={'canvas'}
            />
          </div>
          <Button
            variant="primary"
            className="mt-3"
            onClick={() => handleQrDownload()}
          >
            Download QR Code
          </Button>
        </Card>
      : null}
    </>
  );
};

export default CreateCustomQr;
