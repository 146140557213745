/**
 * This file contains all the actions that are related to the active pricer service.
 */
import { db } from 'config/firebase';
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { toast } from 'react-toastify';

export const createActivePricerJobs = async (uid, selectedRows) => {
    try {
        for (const row of selectedRows) {
            const {sku, cost, productFactor, pricingTemplate, shippingTemplate, weightOz } = row;
            if (!pricingTemplate) {
                toast.error('Missing Pricing Template! Please assign a pricing template before continuing', { theme: 'colored' });
                return { success: false, message: 'Missing Pricing Template data' };
            }
            if (!shippingTemplate) {
                toast.info('Missing Shipping Template! If a custom shipping template is needed, please assign one and rerun the repricer!', { theme: 'colored' });
                // return { success: false, message: 'Missing Shipping Template data' };
            }
            if (!weightOz) {
                toast.error('Missing Weight! Please assign a weight before continuing', { theme: 'colored' });
                return { success: false, message: 'Missing Weight data' };
            }
            const variantCost = Number(cost * productFactor).toFixed(2);
            await createRepricerJob(sku, variantCost, weightOz, pricingTemplate, shippingTemplate, uid);
        }
        toast.success('Multiple repricer jobs created', { theme: 'colored' });
        return { success: true, message: 'Multiple repricer jobs created' };
    } catch (error) {
        console.log(error);
        return error;
    }
}

export async function createRepricerJob(sku, variantCost, weightOz, pricingTemplateId, shippingTemplateId, uid) {
    const formattedCost = Number(variantCost).toFixed(2);
    /**
     * newPricer.js uses a payload object to send data to the activePricer service.
     * The payload object contains the following properties:
     * sku,
     * variantCost,
     * weightOz,
     * pricingTemplate,
     * shippingTemplate,
     */
    if (!pricingTemplateId) pricingTemplateId = 'qGSV0t5QBo31KxBXiL1C';
    if (!shippingTemplateId) shippingTemplateId = 'ATq0bsoxh469y1xxHDAP';
    try {
        await addDoc(collection(db, 'activePricerJobs'), {
            status: "pending",
            handler: "activePricingService",
            type: "newPricer",
            jobData: {
                contentType: "application/json",
                httpMethod: "POST",
                inSeconds: 10,
                payload: {
                    uid: uid,
                    sku,
                    pt: pricingTemplateId,
                    st: shippingTemplateId,
                    variantCost: formattedCost,
                    weightOz,
                },
                queueName: "active-pricing-queue",
            },
            sku,
            jobHash: uid,
            timestamp: {
                created: serverTimestamp(),
                updated: serverTimestamp(),
            }
        });
        return { success: true, message: 'Job created' };
    } catch (error) {
        console.log(error);
        return error;
    }
}
