import { useState, useContext, useEffect, useReducer, useRef, useCallback } from 'react';
import { db } from "config/firebase";
import { useNavigate, useParams } from 'react-router-dom';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import PageHeader from 'components/common/PageHeader';
import { Card, Row, Col, Button, Form, Tabs, Tab, Image, ProgressBar, Alert, Modal } from 'react-bootstrap';
import Lottie from 'lottie-react';
import dotsAnimation from 'assets/lottie/dots.json';
import celebrationAnimation from 'assets/img/animated-icons/check-primary-light.json';
import { useDocument } from 'react-firebase-hooks/firestore';
import { doc, updateDoc, serverTimestamp, arrayUnion, addDoc, getDoc, getDocs, collection, query, where, orderBy, collectionGroup} from 'firebase/firestore';
import SoftBadge from 'components/common/SoftBadge';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { toast } from 'react-toastify';
import Webcam from 'react-webcam';
import celebrationAnimation2 from 'assets/img/animated-icons/check-primary-light.json';
import Flex from 'components/common/Flex';
import { set } from 'lodash';

const PackDetail = ({ children }) => {

    const { user, authLoading }= useContext(FirebaseAuthContext)
    const navigate = useNavigate();
    const { packId } = useParams();

    const [packDataProcessing, setpackDataProcessing] = useState(false);
    const [packJobRaw, packJobLoading, packJobError] = useDocument(
        doc(db, "packWizardJobs", packId)
    );
    const [packJob, setpackJob] = useState(null);
    const [orders, setOrders] = useState(null);
    useEffect(() => {
        let isSubscribed = true;
        setpackDataProcessing(true);
        if (packJobLoading) return;
        if (packJobError) return;
        if (!packJobRaw) return;
        let packJob = packJobRaw.data();
        packJob.ref = packJobRaw.ref;
        getOrders(packJob.orders).then((orders) => {
            if (isSubscribed) {
                setOrders(orders);
                setpackJob(packJob);
                setpackDataProcessing(false);
            }
        }).catch((error) => {
            console.log(error);
            if (isSubscribed) setpackDataProcessing(false);
        });
        return () => isSubscribed = false;
    }, [packJobLoading, packJobError, packJobRaw]);

    async function getOrders(orderIds) {
        let orderDataArray = [];
        for (let i = 0; i < orderIds.length; i++) {
            const orderId = orderIds[i];
            const orderQuery = query (
                collection(db, "orders"),
                where("orderId", "==", orderId),
                orderBy("modifyTimestamp", "desc")
            );
            const orderQuerySnapshot = await getDocs(orderQuery);
            const orderData = orderQuerySnapshot.docs[0].data();
            orderData.ref = orderQuerySnapshot.docs[0].ref;
            orderDataArray.push(orderData);
        }
        return orderDataArray;
    }

    // Redirect to home if not logged in
    useEffect(() => {
        if (authLoading) return;
        if (!user) return navigate('/');
      }, [authLoading, user])

    const [packState, updatepackState] = useState('NOT STARTED');
    useEffect(() => {
        if (packJobLoading) return;
        if (packJobError) return;
        if (!packJobRaw) return;
        if (!packJob) return;
        if (packJob.status !== 'NOT STARTED') {
            updatepackState(packJob.status);
        }
    }, [packJobLoading, packJobError, packJobRaw, packJob])


    async function progresspackStatus(toBeStatus) {
        if (!toBeStatus) return;
        if (toBeStatus === 'IN PROGRESS') {
            updatepackState('IN PROGRESS');
            await updateDoc(packJob.ref, {
                status: 'IN PROGRESS',
                startedAt: new Date(),
                startedBy: user.uid
            });
            await saveCurrentpackIndex(0);
            return;
        }
        if (toBeStatus === 'COMPLETED') {
            updatepackState('COMPLETED');
            return;
        }
    }

    async function saveCurrentpackIndex(index) {
        if (!index) return;
        try {
            await updateDoc(packJob.ref, {
                currentpackIndex: index
            });
        } catch (error) {
            console.log(error);
        }
        
    }

    const ActivepackCard = ({ orders }) => {

        const initialIndex = packJob?.currentpackIndex || 0;
        const [packIndex, setpackIndex] = useState(initialIndex);
        const [order, setOrder] = useState(orders[packIndex]);
        const [progress, setProgress] = useState(0);
        const [startTime, setStartTime] = useState(null);
        const [showIssueModal, setShowIssueModal] = useState(false);
        const [finishState, setFinishState] = useState(false);

        useEffect(() => {
            let isSubscribed = true;
            if (isSubscribed) setStartTime(new Date());
            return () => isSubscribed = false;
        }, [])

        const getProgress = () => {
            let total = orders.length;
            let completed = packIndex + 1;
            return (completed / total) * 100;
        }

        useEffect(() => {
            let isSubscribed = true;
            if (isSubscribed) {
                setOrder(orders[packIndex]);
                setProgress(getProgress());
            }
            return () => isSubscribed = false;
        }, [packIndex, orders])

        async function handleCollected(e) {
            e.preventDefault();
            const endTime = new Date();
            await savepackSplitTime(startTime, endTime);
            await saveCurrentpackIndex(packIndex + 1);
            setpackIndex(packIndex + 1);
        }

        async function handlePreviousClick(e) {
            e.preventDefault();
            const endTime = new Date();
            await savepackSplitTime(startTime, endTime);
            await saveCurrentpackIndex(packIndex - 1);
            setpackIndex(packIndex - 1);
        }

        async function savepackSplitTime(startTime, endTime) {
            try {
                await updateDoc(packJob.ref, {
                    splitTimes: arrayUnion({
                        startTime: startTime,
                        endTime: endTime
                    })
                });
            } catch (error) {
                console.log(error);
            }
        }

        function handleIssue(e) {
            e.preventDefault();
            setShowIssueModal(true);
            // saveCurrentpackIndex(packIndex - 1);
            // setpackIndex(packIndex - 1);
        }

        async function handlePackFinish(e) {
            try {
                e.preventDefault();
                await updateDoc(packJob.ref, {
                    status: 'COMPLETE',
                    currentpackIndex: 0,
                    completedAt: new Date(),
                    completedBy: user.uid
                });
                const batchDataDocRef = doc(db, "customBatches", packJob.batchId);
                await updateDoc(batchDataDocRef, {
                    status: 'COMPLETE',
                    'timestamp.modified': serverTimestamp(),
                });
                toast.success('pack Job Completed', { theme: 'colored' });
            } catch (error) {
                console.log(error);
            }
        }

        const IssueModal = ({ show, setShow }) => {

            const handleClose = () => setShow(false);
            const [showOtherForm, setShowOtherForm] = useState(false);

            async function recordIssue(issue, data) {
                if (!issue || !data) return;
                try {
                    await updateDoc(packJob.ref, {
                        issues: arrayUnion({
                            issue: issue,
                            data: data
                        })
                    });
                } catch (error) {
                    console.log(error);
                }
            }

            async function handleNamedIssue(issue) {
                if (!issue) return;
                switch (issue) {
                    case 'Custom':
                        let customIssueText = document.getElementById('customIssueText').value;
                        if (!customIssueText) return;
                        await recordIssue(customIssueText, item);
                        setShow(false);
                        toast.success('Custom Issue Recorded', { theme: 'colored' });
                        break;
                    default:
                        await recordIssue(issue, item);
                        setShow(false);
                        toast.success('Issue Recorded', { theme: 'colored' });
                        break;
                }
                const endTime = new Date();
                await savepackSplitTime(startTime, endTime);
                await saveCurrentpackIndex(packIndex + 1);
                setpackIndex(packIndex + 1);
                toast.info('Next pack Ready', { theme: 'colored' });
            }

            return (
                <Modal
                    size="sm"
                    show={show}
                    onHide={() => handleClose()}
                >
                    <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Pack Issue
                    </Modal.Title>
                    <FalconCloseButton onClick={() => handleClose()}/>
                    </Modal.Header>
                    <Modal.Body>
                    <div className="d-grid gap-2">
                        {!showOtherForm &&
                            <Button variant="danger" onClick={() => handleNamedIssue('Not In Slot')}>
                                Shipping Issue
                            </Button>
                        }
                        {!showOtherForm &&
                            <Button variant="warning" onClick={() => handleNamedIssue('Not Enough Stock')}>
                                Product Issue
                            </Button>
                        }
                        {!showOtherForm &&
                            <Button variant="secondary" onClick={() => setShowOtherForm(true)}>
                            Other Issue
                            </Button>
                        }
                        {showOtherForm &&
                            <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Issue Description</Form.Label>
                                    <Form.Control as="textarea" rows={3} id="customIssueText" />
                                </Form.Group>
                                <Button variant="danger" onClick={() => handleNamedIssue('Custom')}>
                                    Submit
                                </Button>
                            </Form>
                        }
                        
                    </div>
                    </Modal.Body>
                </Modal>
            )
        }

        async function getProductData(sku) {
            if (!sku) throw new Error('No SKU Provided');
            try {
                const productQuery = query (
                    collectionGroup(db, "variants"),
                    where("sku", "==", sku)
                    );
                const productQuerySnapshot = await getDocs(productQuery);
                if (productQuerySnapshot.empty) {
                    console.log("Product Not Found", sku)
                    toast.error('Product Not Found', { theme: 'colored' });
                    return {error: true, message: 'Product Not Found for SKU'};
                } 
                const variantData = productQuerySnapshot.docs[0].data();
                const productData = await getDoc(variantData.parentRef);
                const product = productData.data();
                const item = {
                    ...variantData,
                    ...product
                };
                return item;
            } catch (error) {
                console.log(error);
            }
        }

        const QualityWrapperTable = ({ items, setFinish, orderId, order }) => {
            
            const [enhancedItems, setEnhancedItems] = useState();
            const [enahncementLoading, setEnhancementLoading] = useState(true);
            useEffect(() => {
                let isSubscribed = true;
                if (isSubscribed) {
                    let itemsWithProductData = [];
                    const promises = [];
                    for (let i = 0; i < items?.length; i++) {
                        const item = items[i];
                        if (!item.sku) continue;
                        promises.push(getProductData(item.sku));
                    };
                    Promise.all(promises).then((values) => {
                        for (let i = 0; i < values.length; i++) {
                            const item = values[i];
                            if (item.error) continue;
                            // find item in items array using sku
                            const itemIndex = items.findIndex((item) => item.sku === item.sku);
                            const orderItem = items[itemIndex];
                            const enhancedItem = {
                                ...item,
                                ...orderItem,
                                orderQuantity: items[i].quantity,
                                itemQuantity: items[i]?.quantity * item?.productFactor
                            }
                            itemsWithProductData.push(enhancedItem);
                        }
                        if (isSubscribed) setEnhancedItems(itemsWithProductData);
                        if (isSubscribed) setEnhancementLoading(false);
                    }).catch((error) => {
                        console.log(error);
                        setEnhancementLoading(false);
                    });
                }
                return () => isSubscribed = false;
            }, [items])

            const [qualityStep, setQualityStep] = useState(0);

            useEffect(() => {
                let isSubscribed = true;
                if (isSubscribed) {
                    const currentQualityStep = order?.currentQualityStep || 0;
                    setQualityStep(currentQualityStep);
                }
                return () => isSubscribed = false;
            }, [order])

            const progressQualityStep = (order, step) => {
                try {
                    updateDoc(order.ref, {
                        currentQualityStep: step
                    });
                    setQualityStep(step);
                } catch (error) {
                    console.log(error);
                }
            }

            const InitialTable = ({ enhancedItems }) => {
                return (
                    <div className='mb-3'>
                        <h5 className='fw-bold'>Items</h5>
                        <table className="table table-sm table-nowrap card-table">
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>SKU</th>
                                    <th>Item</th>
                                    <th>Order Quantity</th>
                                    <th>Item Quantity</th>
                                    <th>Weight</th>
                                </tr>
                            </thead>
                            <tbody>
                                {enhancedItems?.map((item, index) => {return(
                                    <tr key={index}>
                                        <td>{item.imageUrl ? <Image src={item.imageUrl} width={50} /> : null}</td>
                                        <td>{item.sku}</td>
                                        <td>{item.name}</td>
                                        <td>{item.orderQuantity}</td>
                                        <td>{item.itemQuantity}</td>
                                        <td>{item.weight?.value} {item.weight?.units}</td>
                                    </tr>
                                )})}
                            </tbody>
                        </table>
                        <div className='float-end mb-3'>
                            <Button 
                                variant='primary'
                                onClick={() => progressQualityStep(order, 1)}>
                                Start Checkout
                            </Button>
                        </div>
                    </div>
                    
                )
            }

            const ScanCheckTable = ({ enhancedItems, order }) => {
            
                // display a table of individual items in the order so that the fulfillment team
                // can scan each item into the pack

                const itemsToBeScanned = [];
                for (let i = 0; i < enhancedItems.length; i++) {
                    const item = enhancedItems[i];
                    const numItems = item.itemQuantity;
                    for (let j = 0; j < numItems; j++) {
                        itemsToBeScanned.push({
                            ...item,
                            scanned: false
                        });
                    }
                }

                const [scanCount, setScanCount] = useState(0);
                
                function handleScanCountIncrement(e) {
                    e.preventDefault();
                    setScanCount((c) => Number(c+1));
                }

                return (
                    <>
                        <h5 className='fw-bold'>Scan Check</h5>
                        {itemsToBeScanned.length > 0 ?
                            itemsToBeScanned.map((item, index) => {
                                return (
                                    <div key={index} className='mb-2'>
                                        <Row>
                                            <Col>
                                                <span className='fw-bold'>{item?.ibmi?.name}</span>
                                            </Col>
                                            <Col>
                                                <span className='fw-bold'>SKU: </span>{item.parentSku}
                                            </Col>
                                            <Col>
                                                <Form.Control type="text" placeholder={`${item.barcodes?.unit}`} />
                                            </Col>
                                            <Col>
                                                <Button
                                                    variant='outline-secondary'
                                                    onClick={(e) => handleScanCountIncrement(e)}
                                                >
                                                    Scan
                                                </Button>
                                            </Col>

                                        </Row>
                                    </div>
                                )
                            })
                        : null}
                        <div className='float-end mb-3'>
                            <Button 
                                variant='primary'
                                onClick={() => progressQualityStep(order, 2)}
                                disabled={scanCount !== itemsToBeScanned.length}
                            >
                                Finish Pack & Labeling
                                
                            </Button>
                        </div>
                    </>
                )
            
            }

            const PackPostageTable = ({ enhancedItems, order }) => {

                const orderId = order.orderId || '';
                if (!orderId) {
                    toast.error('Order ID not found', { theme: 'colored' });
                    return null;
                }

                // lookup the label data
                const [labelData, setLabelData] = useState(null);
                const [labelDataLoading, setLabelDataLoading] = useState(true);
                useEffect(() => {
                    let isSubscribed = true;
                    if (isSubscribed) {
                        const labelDataQuery = query (
                            collection(db, "labelPool"),
                            where("orderId", "==", orderId)
                        );
                        getDocs(labelDataQuery).then((snapshot) => {
                            if (snapshot.empty) {
                                setLabelDataLoading(false);
                                return;
                            }
                            const labelData = snapshot.docs[0].data();
                            setLabelData(labelData);
                            setLabelDataLoading(false);
                        }).catch((error) => {
                            toast.error(error.message, { theme: 'colored' });
                            console.log(error);
                            setLabelDataLoading(false);
                        });
                    }
                    return () => isSubscribed = false;
                }, [orderId])

                const openPdf = (base64String) => {
                    fetch(`data:application/pdf;base64,${base64String}`)
                      .then((response) => response.blob())
                      .then((blob) => {
                        const url = URL.createObjectURL(blob);
                        window.open(url, '_blank');
                      })
                      .catch((error) => {
                        console.error("There was an error with opening the PDF: ", error);
                      });
                  };
                  
                return (
                    <div className='mb-3'>
                        <h6>Pack & Labeling</h6>
                        <div>Requested Service Level: <strong>{order.requestedShippingService}</strong></div>
                        {!labelDataLoading && labelData ?
                            <div>Selected Service Level: <strong>{labelData?.selectedOption?.serviceName}</strong></div>
                        :   <div>Label Data Currently Not Available, Check Back Soon</div>
                        }
                        <div className='text-start mt-3 mb-3'>
                            <Button onClick={() => openPdf(labelData.label.labelData)}>View Label</Button>
                        </div>
                        <div className='float-end mb-3'>
                            <Button 
                                variant='primary'
                                onClick={() => progressQualityStep(order, 3)}>
                                Finalize Order
                            </Button>
                        </div>
                    </div>
                )
            }

            const FinalizeOrder = ({ enhancedItems, setFinish, orderId}) => {

                const [capturedImageData, setCapturedImageData] = useState(null);

                const webcamRef = useRef(null);

                const videoConstraints = {
                    width: 640,
                    height: 360,
                    facingMode: "environment"
                  };
                
                async function savePackageImage(orderId, imageData) {
                    try {
                        updateDoc(packJob.ref, {
                            packageImages: arrayUnion({
                                orderId: orderId,
                                imageData: imageData,
                                timestamp: new Date()
                            })
                        });
                        const orderRef = doc(db, "orders", orderId);
                        updateDoc(orderRef, {
                            packageImage: imageData,
                            packageImageTimestamp: new Date()
                        });
                        toast.success('Package Image Saved', { theme: 'colored' });
                    } catch (error) {
                        console.log(error);
                    }
                }

                const WebcamCapture = ({}) => {
                    return (
                        <>
                            <Webcam
                                audio={false}
                                height={360}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                width={640}
                                videoConstraints={videoConstraints}
                            />                        
                        </>
                    );
                };

                const handleCapture = (e) => {
                    e.preventDefault();
                    const imageSrc = webcamRef.current.getScreenshot();
                    setCapturedImageData(imageSrc);
                    savePackageImage(orderId, imageSrc);
                    // wait a second and then move to the next step
                    progressQualityStep(order, 4)
                };

                return (
                    <div className='mb-3'>
                    <h6>Finalize</h6>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px', width: '100%' }}>
                        <div style={{ textAlign: 'center' }}>
                        <WebcamCapture />
                        </div>
                    </div>
                    <div className='float-end mb-3'>
                        <Button variant='primary' onClick={handleCapture}>
                        Complete Order
                        </Button>
                    </div>
                    </div>

                )
            }

            const OrderCompleteScreen = ({ order }) => {
                return (
                    <div className='mb-3 text-center'>
                        <h3>Order Packing Complete!</h3>
                        
                            <Lottie 
                            className='align-middle'
                            animationData={celebrationAnimation2} 
                            style={{
                                display: 'block',
                                margin: "0 auto",
                                width: 200 }} />
                        
                    </div>
                )
            }

            return (
                !enahncementLoading ?
                    qualityStep === 0 && <InitialTable enhancedItems={enhancedItems} />
                    ||
                    qualityStep === 1 && <ScanCheckTable enhancedItems={enhancedItems} order={order}/>
                    ||
                    qualityStep === 2 && <PackPostageTable enhancedItems={enhancedItems} order={order} />
                    ||
                    qualityStep === 3 && <FinalizeOrder enhancedItems={enhancedItems} setFinish={setFinishState} orderId={orderId}/>
                    ||
                    qualityStep === 4 && <OrderCompleteScreen order={order} />
                : <Lottie animationData={dotsAnimation} style={{width: 100, height: 100}} />
            )
        }

        return (
            <>
            <IssueModal show={showIssueModal} setShow={setShowIssueModal} />
            <Card
                bg='primary'
                text='white'
                className="mb-2"
            >
                <Card.Body>
                    <Row className='mb-3'>
                        <Col>
                            <ProgressBar now={progress} variant='success'/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h5 className='fw-bold text-white'>Order {packIndex + 1} of {orders.length}</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h5 className='fw-bold text-white'>Order: {order?.orderNumber} </h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h5 className='fw-bold text-white'>Name: {order?.shipTo?.name} </h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h5 className='fw-bold text-white'>Destination: {order?.shipTo?.city}, {order?.shipTo?.state}</h5>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card>
                <Card.Body>
                    <Row>
                        <Col className=''>
                            <QualityWrapperTable items={order?.items} setFinish={setFinishState} orderId={order?.orderId} order={order}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='float-start'>
                                <Button variant="outline-danger" className="me-2" size='sm'
                                    onClick={(e) => handleIssue(e)}
                                >Issue</Button>
                            </div>
                            <div className='float-end'>
                                {packIndex !== (orders.length - 1) ?
                                    <>
                                        <Button variant="falcon-default" className="me-2" size='sm'
                                        onClick={(e) => handlePreviousClick(e)}
                                        >Previous Order</Button>

                                        <Button variant="falcon-default" className="me-2" size='sm'
                                        onClick={(e) => handleCollected(e)}
                                        >Next Order</Button>
                                    </>

                                :   <Button variant="success" className="me-2" size='sm'
                                    onClick={(e) => handlePackFinish(e)}
                                    >End Pack Job</Button>
                                }
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            </>
        )
    }

    return (
        !packDataProcessing ?
        <>
            {packState !== 'IN PROGRESS' ?
                <PageHeader title={packJob?.batchName || "Loading"} className={'mb-3'}>
                <Row className='mt-3'>
                    <Col>
                        <SoftBadge variant={
                            packJob?.status === 'NOT STARTED' ? 'primary'
                            : packJob?.status === 'IN PROGRESS' ? 'success'
                            : 'secondary' } className='me-2'>{packJob?.status}</SoftBadge>
                    </Col>
                    {packState === 'COMPLETE' ?
                    <Col>
                        <div className='float-end'>
                            <Lottie animationData={celebrationAnimation} 
                                style={{
                                    display: 'block',
                                    margin: "0 auto",
                                    width: 100 
                            }} />
                        </div>
                    </Col>
                    : null
                    }
                </Row>
                <Row className='mt-2'>
                    <Col className=''>
                        <span className='fw-bold text-muted'>
                            Assigned To: {packJob?.assignedUser}, {orders?.length} orders
                        </span>
                    </Col>
                   
                </Row>
                
                </PageHeader>
            : null}
            {packState === 'NOT STARTED' ?
                <Card>
                    <Card.Body>
                        <Row>
                            <Col>
                                {packState === 'NOT STARTED' ?
                                    <Button variant="success" className="me-2" size='lg'
                                        onClick={() => progresspackStatus('IN PROGRESS')}
                                    >Start Pack Job</Button>
                                : packState === 'IN PROGRESS' ?
                                    <Button variant="success" className="me-2" size='lg'
                                        onClick={() => progresspackStatus('IN PROGRESS')}
                                    >Continue Pack Job</Button>
                                : packState === 'COMPLETED' ?
                                    <Button variant="primary" className="me-2" size='lg'

                                    >View Pack Job</Button>
                                : null
                                }
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            : null}
            
            {packState === 'IN PROGRESS' ?
                <ActivepackCard orders={orders} />
            : null}
            {packState === 'COMPLETE' ?
                <Card>
                    <Card.Body>
                        <Row>
                            <Col>
                                <h5 className='fw-bold'>Pack Job Complete!</h5>
                            </Col>
                            <Col>
                                <div className='float-start'>
                                    <Button variant="outline-primary" className="me-2" size='sm'
                                        onClick={() => navigate('/fulfillment/pack/')}
                                    >Back to Pack Jobs</Button>
                                </div>
                            </Col>
                        </Row>
                        <Row className='mt-3 mb-3'>
                            <Col>
                                <span className='fw-bold'>Pack Job Summary</span>
                            </Col>
                        </Row>
                        <Row className='fw-semi-bold'>
                            <Col>Split</Col>
                            <Col>Start</Col>
                            <Col>End</Col>
                            <Col>Duration</Col>
                        </Row>
                        <Row>
                            <Col>
                                <div>
                                    {packJob?.splitTimes?.map((splitTime, index) => {
                                        const startTime = splitTime.startTime.toDate().toLocaleTimeString();
                                        const endTime = splitTime.endTime.toDate().toLocaleTimeString();
                                        const difference = splitTime.endTime.toDate().getTime() - splitTime.startTime.toDate().getTime();
                                        return (
                                            <Row>
                                                <Col>Split {index+1}</Col>
                                                <Col>{startTime}</Col>
                                                <Col>{endTime}</Col>
                                                <Col>{Number(difference / 1000).toFixed(0)} seconds</Col>
                                            </Row>
                                        )
                                    })}
                                </div>
                            </Col>
                        </Row>
                        <Row className='mt-3 mb-3 fw-bold'>
                            <Col>Package Gallery</Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className='d-flex flex-wrap'>
                                    {packJob?.packageImages?.map((image, index) => {
                                        return (
                                            <div key={index} className='me-2 mb-2 d-block'>
                                                <Image src={image.imageData} width={200} />
                                                <div className='text-muted fs--1 text-center'>Order: {image.orderId}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            : null
            }
        </>
        :
        <Lottie animationData={dotsAnimation} style={{width: 100, height: 100}} />
    );
}

export default PackDetail;