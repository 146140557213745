import PageHeader from "components/common/PageHeader"
import Flex from "components/common/Flex"
import { Row, Col, Card, Button } from "react-bootstrap"
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { auth, db } from 'config/firebase';
import { addDoc, collection } from 'firebase/firestore';
import { toast } from 'react-toastify';

function InventoryReport() {

    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();

    async function createInventoryReport() {
        try {
            await addDoc(collection(db, "reportEngine"), {
                // ...allData
                requestedReport: 'IVR',
                state: 'open',
                timestamp: new Date(),
            });
            toast.success('Inventory Report Created. The report will be emailed to you in less than 5 minutes.', {
                theme: 'colored'
            });
        } catch {
            toast.error('Error creating inventory report', {
                theme: 'colored'
            });
        }
    }

    return (
        <>
            <PageHeader
                title={'Inventory Reports'}
                className="mb-3"
            ></PageHeader>
           
                <Card>
                    <Card.Body>
                        <Row className="flex-between-center mb-3">
                            <Col>
                                <h4>Value Report</h4>
                            </Col>
                            <Col>
                                <Button
                                    size="sm"
                                    variant="falcon-primary"
                                    onClick={() => createInventoryReport()}
                                >
                                    Run Report
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
           
        </>
    )
}

export default InventoryReport