import { Button, Modal} from 'react-bootstrap';

const HelpModal = ({ show, handleClose, context }) => {
    return (
        <>
          <Modal
            show={show}
            onHide={() => handleClose()}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Get Help</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => handleClose()}>Close</Button>
            </Modal.Footer>
          </Modal>
        </>
      );
}

export default HelpModal;



  
    
  
  