import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useNavigate } from 'react-router-dom';
import { auth, db } from 'config/firebase';
import { addDoc,collection, setDoc } from 'firebase/firestore';
import {
  Spinner,
  Card,
  Row,
  Col,
  Button,
  Form,
  Modal } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import PageHeader from 'components/common/PageHeader';
import SoftBadge from 'components/common/SoftBadge';
import { toast } from 'react-toastify';

const Locations = () => {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const [firebaseLoading, setFirebaseLoading] = useState(true);
  const [locations, collectionLoading, collectionError] = useCollectionData(
    collection(db, 'locations'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const columns = [
    {
      accessor: 'type',
      Header: 'Type',
      Cell: rowData => {
        const { type } = rowData.row.original;

        let Display;
        if (type === 'automatic') {
          Display = <SoftBadge pill bg="success" className="fs--2">
                              {'Automatic'}
                    </SoftBadge>
        } else {
          Display = <SoftBadge pill bg="secondary" className="fs--2">
                              {'Manual'}
                    </SoftBadge>
        }

        return (
          <span>
            {Display}
          </span>
        );
      }
    },
    {
      accessor: 'name',
      Header: 'Location Name',
      Cell: rowData => {
        const { name, id } = rowData.row.original;
        
        return (
          <span>
            <a href={`/inventory/locations/${id}`}>{name}</a>
          </span>
        );
      }
    },
    {
      accessor: 'address',
      Header: 'Address',
      Cell: rowData => {
        const { address } = rowData.row.original;
        
        return (
          <span>
            {address.street1}, {address.street2}, {address.city}, {address.state}, {address.postalCode}
          </span>
        );
      }
    },
    {
      accessor: 'notes',
      Header: 'Notes',
      Cell: rowData => {
        const { notes } = rowData.row.original;
        
        return (
          <span>
            {notes}
          </span>
        );
      }
    },
    {
      accessor: 'status',
      Header: 'Status',
      Cell: rowData => {
        const { active } = rowData.row.original;
        let defaultChecked = active;

        return (
          <span>
            <Form.Check
              type="switch"
              id="statusSwitch"
              label="Active"
              defaultChecked={defaultChecked}
              onChange={() => {}}
              ></Form.Check>
          </span>
        );
      }
    },
  ];

  if (collectionError) console.log(collectionError)


  useEffect(() => {
    if (loading) return;
    if (!user) return navigate('/');
    
    setFirebaseLoading(false);
  }, [user, loading, navigate]);

  //console.dir(currentProducts);
  return (
    <>
    <PageHeader

    title="Inventory Locations" titleTag="h5" className="mb-3">

    
    <div className='mt-4'>

      <NewLocation />

     
    </div>
    </PageHeader>
    
    
        {!firebaseLoading && !collectionLoading ? (
          <Card className="">
          <AdvanceTableWrapper
            columns={columns}
            data={locations}
            sortable
            pagination
            setPageSize
            >
            <div className="m-3">
            <Row className="flex-end-center mb-3">
              <Col>
                  <AdvanceTableSearchBox table/>
              </Col>
            </Row>
              <AdvanceTableFooter
                rowCount={2}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            
            </div>
            
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: false,
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
            <div className="m-3"><AdvanceTablePagination table /></div>
            
          </AdvanceTableWrapper>
          </Card>
        ) : 
          <Row className="align-items-center">

            <Col>
              <Spinner animation="border" variant="primary">
                <span className='visually-hidden'>Loading...</span>
              </Spinner>
            </Col>
          
          </Row>
          }
      
    </>
  );
};

function NewLocation() {
  const [modalShow, setModalShow] = useState(false);

  async function createNewLocation(e) {
    e.preventDefault();
    const formData = new FormData(document.getElementById("addressForm"));
    const formDataObj = Object.fromEntries(formData.entries());
    console.log(formDataObj)
    const address = {
      city: formDataObj.city || 'Default',
      street1: formDataObj.street1 || 'Default',
      street2: formDataObj.street2 || 'Default',
      state: formDataObj.state || 'Default',
      country: formDataObj.country || 'Default',
      postalCode:formDataObj.postalCode || 'Default',
    }
    const docRef = await addDoc(collection(db, "locations"), {
      active: true,
      address: address,
      name: formDataObj.locationName  || 'Default Name',
      notes: formDataObj.locationNotes  || 'Default Notes',
      type: formDataObj.locationType || 'manual'
    });
    
    if (docRef.id) {
      setModalShow(false);
      setDoc(docRef, { id: docRef.id }, { merge: true });
      toast.success(`New Location Added`, {
        theme: 'colored'
      });
    }
  }

  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Add New Location
      </Button>


      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
      
      
      <Form onSubmit={createNewLocation} id="addressForm">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Add Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row className="align-items-center g-3">
              <Col>
                  <Form.Group className="mb-3" controlId="formLocationType">
                    <Form.Label>Choose Location Type</Form.Label>
                    <Form.Select aria-label="Choose location type, manual or automatic" name="locationType">
                      <option value="manual">Manual</option>
                      <option value="automatic">Automatic</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formLocationName">
                    <Form.Label>Enter Location Name</Form.Label>
                    <Form.Control type="text" placeholder="Location Name" name="locationName"/>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formLocationAddress">
                    <Form.Label>Address</Form.Label>
                    <Form.Control type="text" placeholder="Street " name="street1"/>
                    <Form.Control type="text" placeholder="" name="street2" />
                    <Form.Control type="text" placeholder="City" name="city" />
                    <Form.Control type="text" placeholder="State"  name="state"/>
                    <Form.Control type="text" placeholder="Postal Code" name="postalCode" />
                    <Form.Control type="text" placeholder="Country" name="country" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formLocationNotes">
                    <Form.Label>Notes</Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder="Notes" name="locationNotes"/>
                  </Form.Group>
              </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalShow(false)}>Close</Button>
          <Button variant="success" type="submit">Create</Button>
        </Modal.Footer>
        
        </Form>
      </Modal>
    </>
  );
}

export default Locations;
