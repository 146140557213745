import { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import { Card, Row, Col, Button, Form, Modal, Image, InputGroup, Badge } from 'react-bootstrap';
import Lottie from 'lottie-react';
import dotsAnimation from 'assets/lottie/dots.json';
import { useCollectionData, useDocument } from 'react-firebase-hooks/firestore';
import { db } from "config/firebase";
import { 
    query, collection, orderBy, startAt, limit, startAfter, endAt,
    getDocs, endBefore, addDoc, collectionGroup, where, getDoc, doc, updateDoc,
    getCountFromServer
} from 'firebase/firestore';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import IconButton from 'components/common/IconButton';
import FalconCloseButton from 'components/common/FalconCloseButton';
import PageHeader from 'components/common/PageHeader';
import { toast } from 'react-toastify';
import words from './words.json';
import { v4 as uuidv4 } from 'uuid';


function BatchTable({ batch }) {
    
    const [orders, setOrders] = useState([]);
    const [ordersLoading, setOrdersLoading] = useState(false);
    const [enhancedOrders, setEnhancedOrders] = useState([]);
    const [tagsMeta, tagsMetaLoading, error] = useCollectionData(
        collection(db, "shipStationTags"),
    );

    useEffect(() => {
        // batch.orders is an array of orderIds that can 
        // be found in the orders collection
        const orderIds = batch?.orders;
        if (!orderIds) return;
        let isSubscribed = true;
        setOrdersLoading(true);
        getOrders(orderIds).then((orders) => {
            if (isSubscribed) {
                setOrders(orders);
                setOrdersLoading(false);
            }
        }).catch((error) => {
            console.log(error)
            setOrdersLoading(false);
        });
        return () => isSubscribed = false;
    }, [batch]);

    useEffect(() => {
        let isSubscribed = true;
        const enhancePromises = [];
        setOrdersLoading(true);
        for (let i = 0; i < orders.length; i++) {
            const order = orders[i];
            const items = order.items;
            for (let j = 0; j < items.length; j++) {
                const item = items[j];
                const sku = item.sku;
                enhancePromises.push(checkInventoryLevels(sku));
            }
        }
        Promise.all(enhancePromises).then((enhancedItems) => {
            if (isSubscribed) {
                setEnhancedOrders(enhancedItems);
                setOrdersLoading(false);
            }
        }).catch((error) => {
            console.log(error)
            setOrdersLoading(false);
        });
        return () => isSubscribed = false;
    }, [orders]);
    
    async function getOrders(orderIds) {
        const orders = [];
        for (let i=0; i<orderIds.length; i++) {
                const orderId = orderIds[i];
                const orderQuery = query(
                    collection(db, "orders"),
                    where("orderId", "==", orderId),
                    orderBy("modifyTimestamp", "desc"),
                    limit(1)
                );
                const orderSnapshot = await getDocs(orderQuery);
                const order = orderSnapshot.docs[0];
                let temp = order.data();
                // destructure doc reference so we can make quick edits
                temp.id = order.id;
                temp.ref = order.ref;

                // get label data too
                const labelQuery = query(
                    collection(db, "labelPool"),
                    where("orderId", "==", Number(orderId)),
                    limit(1)
                );
                const labelSnapshot = await getDocs(labelQuery);
                const labelDoc = labelSnapshot.docs[0];
                if (labelDoc) {
                    const labelPoolDoc = labelDoc.data();
                    temp.labelData = labelPoolDoc.label.labelData;
                }
                
                orders.push(temp);
        }
        return orders;
    }

    async function checkInventoryLevels(sku) {
        try {
            let returnObject = {
                sku: sku,
                count: 0,
                message: ''
            }
            if (!sku) return;
            const q = query(
                collectionGroup(db, "variants"),
                where("sku", "==", sku),
                limit(1)
            );
            const querySnapshot = await getDocs(q);
            const variants = querySnapshot.docs.map(doc => doc.data());
            const variant = variants[0];
            if (variant) {
                const parentRef = variant.parentRef;
                const parentDoc = await getDoc(parentRef);
                const parent = parentDoc.data();
                // to get the count in the variant's unit of measure
                let count;
                if (variant.productFactor < 1) {
                    // if the variant is a break-box then we need to build up how many boxes are in stock
                    count = parent.ecomm_onhand * (1 + variant.productFactor);
                } else {
                    // if the variant is a multiple of the parent unit we divide the parent's onhand by the variant's productFactor
                    // to get the count in the variant's unit of measure
                    count = parent.ecomm_onhand / variant.productFactor;
                }
                if (count < 1) {
                    // check other inventory locations
                    const q2 = query(
                        collection(db, "locationCountData"),
                        where("sku", "==", parent.sku),
                    );
                    const querySnapshot2 = await getDocs(q2);
                    const locationCountData = querySnapshot2.docs.map(doc => doc.data());
                    const locationCount = locationCountData[0];

                    if (locationCount) {
                        count = locationCount.count;
                        if (count < 1) {
                            returnObject.message = 'Out of stock';
                        } else {
                            returnObject.count = count;
                            returnObject.message = 'Warehouse Out of Stock, In-stock in Inventory Room';
                        }
                    } else {
                        returnObject.message = 'Out of stock';
                    }
                } else {
                    returnObject.count = count;
                    returnObject.message = 'In stock';
                }
            } else {
                returnObject.message = 'Not found';
            }
            return returnObject;
        } catch (error) {
            console.log(error);
        }

    }

    console.log(orders[0])

    const colDef = [
        {
          accessor: 'orderNumber',
          Header: 'Order Number',
          Cell: rowData => {
            const { orderId, orderNumber } = rowData.row.original
            return(
                <span>
                    <a href={`/orders/${orderId}`}>{orderNumber}</a>
                </span>   
            )
          }
        },
        {
          accessor: 'orderTotal',
          Header: 'Total',
          cellProps:{
            className:'fw-medium'
          }
        },
        {
            Header: 'Items',
            Cell: rowData => {
                const { items } = rowData.row.original
                // iterate through items and consolidate any items that are repeats
                // and add a count to the item
                const consolidatedItems = [];
                items.forEach(item => {
                    const index = consolidatedItems.findIndex(consolidatedItem => consolidatedItem.name === item.name);
                    if (index === -1) {
                        consolidatedItems.push({...item, count: 1});
                    } else {
                        consolidatedItems[index].count++;
                    }
                })
                return(
                    consolidatedItems.length > 0 &&
                    consolidatedItems.map((item, index) => {
                        // create a random key using the index + a random word from words.json
                        const key = words.adjectives[Math.floor(Math.random() * words.adjectives.length)];
                        return (
                            <div key={`cnt-${key}-${index}`} className="d-flex align-items-center">
                                <Image key={`image-${key}-${index}-${item.sku}`} src={item.imageUrl} className="rounded-2 me-2" width="30" height="30" />
                                <span 
                                    style={{display: 'inline-block', maxWidth: '350px'}}
                                    className="text-truncate"
                                    key={`span-${key}-${index}`}
                                >{item.count} x {item.name}</span>
                            </div>
                        )
                    })
                )
            }
        },
        {
            Header: 'Inventory',
            Cell: rowData => {
                const { items } = rowData.row.original
                const consolidatedItems = [];
                items.forEach(item => {
                    const index = consolidatedItems.findIndex(consolidatedItem => consolidatedItem.name === item.name);
                    if (index === -1) {
                        consolidatedItems.push({...item, count: 1});
                    } else {
                        consolidatedItems[index].count++;
                    }
                })               
                // find each item in the enhanced orders array
                // and display the inventory status
                const inventoryStatus = [];
                consolidatedItems.forEach(item => {
                    if (item.sku) {
                        const index = enhancedOrders.findIndex(enhancedItem => enhancedItem?.sku === item?.sku);
                        if (index !== -1) {
                            inventoryStatus.push(enhancedOrders[index]);
                        } else {
                            inventoryStatus.push({message: 'Not found'});
                        }
                    } else {
                        inventoryStatus.push({message: 'No SKU'});
                    }
                    
                })

                const invDisplay = inventoryStatus.map((item, index) => {
                    let display;
                    let random = uuidv4();
                    switch (item.message) {
                        case 'In stock':
                            // display an In Stock pill badge
                            display = <Badge bg="success" key={`${random}-${index}`} className='mb-1'>In Stock</Badge>
                            break;
                        case 'Out of stock':
                            // display an Out of Stock pill badge
                            display = <Badge bg="danger" key={`${random}-${index}`} className='mb-1'>Out of Stock</Badge>
                            break;
                        case 'Warehouse Out of Stock, In-stock in Inventory Room':
                            // display an Out of Stock pill badge
                            display = <Badge bg="warning" key={`${random}-${index}`} className='mb-1'>Inventory Room Only</Badge>
                            break;
                        default:
                            // display an Out of Stock pill badge
                            display = <Badge bg="danger" key={`${random}-${index}`} className='mb-1'>Not Found</Badge>
                            break;
                    }
                    return (
                        <div key={`container-${random}-${index}`}>
                            <div key={`${uuidv4()}-${index}`} className="d-flex align-items-center">
                                {display}
                            </div>
                            {item.message === 'Not found' && <div key={`${uuidv4()}-${index}`} className="text-muted mt-2">Missing {item.sku}</div>}
                        </div>
                    )
                })

                return(
                    invDisplay.length > 0 &&
                    invDisplay.map((item, index) => {
                        return (
                            <div key={`cnt-${index}`} className="d-flex align-items-center">
                                {item}
                            </div>
                        )
                    })
                )
            }
        },
        {
            accessor: 'tagIds',
            Header: 'Tags',
            cellProps:{
              className:'fw-medium'
            },
            Cell: rowData => {
                const { tagIds } = rowData.row.original

                const tags = [];
                if (tagIds?.length) {
                    tagIds.forEach(tagId => {
                       if (tagsMeta?.length) {
                            const tagData = tagsMeta.find(tag => tag.tagId === tagId);
                            if (tagData) {
                                tags.push(tagData);
                            }
                       }
                    })
                }

                const TagDisplay = ({ tag }) => {
                    const { name, color, text } = tag;
                    // create a rounded pill with the color of the tag
                    const key = words.adjectives[Math.floor(Math.random() * words.adjectives.length)];
                    return (
                        <span key={`tag-span${key}`} className="badge rounded-pill me-1" style={{backgroundColor: color, color: text}}>{name}</span>
                    )
                }

                return(
                    tags?.length > 0 &&
                    tags.map((tag, index) => {
                        return (
                            <TagDisplay key={index} tag={tag} />
                        )
                    })
                )
            }
        },
        {
            accessor: 'orderStatus',
            Header: 'Label Data',
            cellProps: {
              className: 'fw-medium'
            },
            Cell: rowData => {
              const { orderId, labelData } = rowData.row.original;

              const base64_pdf = labelData;
          
              const open_pdf = () => {
                const pdf_window = window.open();
                pdf_window.document.write(
                  `<iframe width='100%' height='100%' src='data:application/pdf;base64,${base64_pdf}'></iframe>`
                );
              };
          
              return (
                <Button
                  variant="falcon-default"
                  size="sm"
                  onClick={base64_pdf ? open_pdf : null}
                  disabled={!base64_pdf}
                >
                  View PDF
                </Button>
              );
            }
          }
    ];

    function BulAction(table){
        
        const { selectedRowIds, page} = table;
        const [showBulkModal, setShowBulkModal] = useState(false);
        const [bulkActionOption, setBulkActionOption] = useState('');
        const handleClose = () => setShowBulkModal(false);
        const selectedRows = Object.keys(selectedRowIds).map(id => page.find(row => row.id === id).original);

        const RemoveOrderFromBatch = ({ orders }) => {
            
            async function removeOrder(order) {
                /** This function removes the order from the current batch by resetting the following:
                 *  - assignedToBatch = false
                 *  - batchId = ''
                 *  - batchName = ''
                 *  
                */
                const orderRef = order.ref;
                if (!orderRef) return;
                try {
                    await updateDoc(orderRef, {
                        assignedToBatch: false,
                        batchId: '',
                        batchName: ''
                    });
                    await removeOrderIdFromBatchOrders(order.orderId);
                    //toast.success(`Order ${order.orderNumber} removed from the batch`);
                    return;
                } catch (error) {
                    console.log(error);
                    //toast.error(`Error removing order ${order.orderNumber} from the batch`);
                }
            } 

            async function removeOrders(orders) {
                const promises = [];
                orders.forEach(order => {
                    promises.push(removeOrder(order));
                })
                Promise.all(promises).then(() => {
                    toast.success(`${orders.length} Orders removed from the batch`, { theme: 'colored' });
                }).catch((error) => {
                    console.log(error);
                    toast.error('Error removing orders from the batch', { theme: 'colored' });
                })
            }

            async function removeOrderIdFromBatchOrders(orderId) {
                try {
                    const oldOrders = batch.orders;
                    const index = oldOrders.findIndex(id => id === orderId);
                    if (index !== -1) {
                        oldOrders.splice(index, 1);
                    } else {
                        return;
                    }
                    await updateDoc(batch.ref, {
                        orders: oldOrders
                    });
                    return;
                } catch (error) {
                    console.log(error);
                }
            }

            let errorState = false;
            let errorMsg = 'There are no errors'
            if (!orders) {
                errorState = true;
                errorMsg = 'No orders were selected';
            }

            return (
                !errorState ?
                <div>
                    <p>Are you sure you want to remove {orders.length} orders from this batch?</p>
                    <Button variant="danger" onClick={() => removeOrders(orders)}>Remove Orders</Button>
                </div>
                :
                <div>
                    <p>{errorMsg}</p>
                </div>
            )
        }

        const ReassignOrdersToAnotherBatch = ({ orders }) => {

            const [batches, setBatches] = useState([]);
            const [selectedBatch, setSelectedBatch] = useState(null);
            const [activeBatch, setActiveBatch] = useState(null);

            useEffect(() => {
                let isSubscribed = true;
                gatherOpenBatchesForReassignment().then((batches) => {
                    if (isSubscribed) setBatches(batches);
                });
                return () => isSubscribed = false;
            }, []);

            useEffect(() => {
                if (!selectedBatch) return;
                const raw = selectedBatch.split(':');
                const batchName = raw[0];
                setActiveBatch(batchName);
            }, [selectedBatch]);

            console.log(activeBatch);

            async function gatherOpenBatchesForReassignment() {
                try {
                    const batchQuery = query(
                        collection(db, "customBatches"),
                        where("status", "==", "OPEN"),
                    );
                    const batchSnapshot = await getDocs(batchQuery);
                    const batchData = batchSnapshot.docs.map(doc => doc.data());
                    // remove the current batch from the list
                    const curentBatch = batch.batchName;
                    const index = batchData.findIndex(batch => batch.batchName === curentBatch);
                    if (index !== -1) {
                        batchData.splice(index, 1);
                    }
                    return batchData;
                } catch (error) {
                    console.log(error);
                }
            }

            async function handleReassignment(batchName, orders) {
                try {
                    // TODO - need to use something else or add a batchId field to the batch
                    // because batchName is not guaranteed to be unique
                    const batchQuery = query(
                        collection(db, "customBatches"),
                        where("batchName", "==", batchName),
                    );
                    const batchSnapshot = await getDocs(batchQuery);
                    const batchData = batchSnapshot.docs.map(doc => {
                        let temp = doc.data();
                        temp.id = doc.id;
                        temp.ref = doc.ref;
                        return temp;
                    });
                    if (batchData.length !== 1) {
                        toast.warning(`There are multiple batches with name ${batchName} `, { theme: 'colored' });
                    }
                    const newBatch = batchData[0];
                    // get the current orders in batch
                    const oldOrderIds = newBatch?.orders || [];
                    const newOrderIds = orders.map(order => order.orderId);
                    // add the new orders to the batch
                    const newOrders = [...oldOrderIds, ...newOrderIds];
                    // update the batch with the new orders
                    await updateDoc(newBatch.ref, {
                        orders: newOrders
                    });
                    // remove the current orderId from existing batch
                    const currentBatchesOrderIds = batch.orders || [];
                    const updatedOrderIdsForCurrentBatch = currentBatchesOrderIds.filter(orderId => !newOrderIds.includes(orderId));
                    await updateDoc(batch.ref, {
                        orders: updatedOrderIdsForCurrentBatch
                    });
                    // update the orders with the new batchId and batchName
                    const promises = [];
                    orders.forEach(order => {
                        promises.push(updateDoc(order.ref, {
                            batchId: newBatch.id,
                            batchName: newBatch.batchName,
                            assignedToBatch: true
                        }));
                    })
                    Promise.all(promises).then(() => {
                        toast.success(`${orders.length} Orders reassigned to ${newBatch.batchName}`, { theme: 'colored' });
                        handleClose();
                    }).catch((error) => {
                        console.log(error);
                        toast.error('Error reassigning orders to batch', { theme: 'colored' });
                    })

                } catch (error) {
                    console.log("batch", batch);
                    console.log("orders", orders)
                    console.log(error);
                }
            }
            
            return (
                <div>
                    <p>Select batch below that the orders will be reassigned to:</p>
                    <Form.Select size="sm" aria-label="Bulk actions" onChange={(e) => setSelectedBatch(e.target.value)}>
                        <option>Select Batch</option>
                        {
                            batches.map((batch, index) => {
                                return (
                                    <option key={index} value={batch.id}>{batch.batchName}: ({batch.assignedUser} - {batch?.orders?.length} orders) </option>
                                )
                            })
                        }
                    </Form.Select>
                    { activeBatch && 
                        <div className='mt-3'>
                            <Button
                                variant="success"
                                onClick={() => handleReassignment(activeBatch, orders)}

                            >Reassign Orders to {activeBatch}</Button>
                        </div>
                    }
                </div>
            )
        }

        const ReassignUser = ({ batch }) => {
            const assignedUser = batch.assignedUser || 'None';
            const [users, setUsers] = useState([]);
            const [newAssignedUser, setNewAssignedUser] = useState('');

            async function gatherAssignableUsers() {
                const users = [];
                const querySnapshot = await getDocs(collection(db, "users"));
                querySnapshot.forEach((doc) => {
                    users.push(doc.data());
                });
                // remove the current assignedUser from the list of options
                const index = users.findIndex(user => user.name === assignedUser);
                if (index !== -1) {
                    users.splice(index, 1);
                }
                return users;
            }

            useEffect(() => {
                if (!batch) return;
                let isSubscribed = true;
                gatherAssignableUsers().then((users) => {
                    if (isSubscribed) setUsers(users);
                });
                return () => isSubscribed = false;
            }, [batch]);

            async function handleReassignment(newAssignedUser) {
                try {
                    await updateDoc(batch.ref, {
                        assignedUser: newAssignedUser
                        
                    });
                    toast.success(`Batch reassigned to ${newAssignedUser}`, { theme: 'colored' });
                    handleClose();
                } catch (error) {
                    console.log(error);
                    toast.error('Error reassigning batch to user', { theme: 'colored' });
                }
            }

            const UserNameDropDown = ({ users }) => {
                return (
                    <>
                        <Form.Group className="mb-3" controlId="formBasicUserSelect">
                        <Form.Label>Assign To User (Optional)</Form.Label>
                        <Form.Select 
                            aria-label="Select User" 
                            onChange={(e) => setNewAssignedUser(e.target.value)}
                        >
                            <option>Select User</option>
                            {users.map((user, index) => (
                                <option key={`${index}-${user.id}`} value={user.id}>{user.name}</option>
                            ))}
                        </Form.Select>
                        </Form.Group>
                        { newAssignedUser !== '' &&
                            <div className='mt-3'>
                                <Button
                                    variant="success"
                                    onClick={() => handleReassignment(newAssignedUser)}
                                >Reassign Batch to {newAssignedUser}</Button>
                            </div>
                        }
                    </>
                )
            }
 
            return (
                <> 
                    {assignedUser === 'None' ?
                        <div>
                            <h6>Whoa this batch has no assigned user!</h6>
                            <p>Select user below that the batch will be assigned to:</p>
                        </div>
                    :   <div>
                            <h6>This batch is currently assigned to {assignedUser}</h6>
                            <p>Select user below that the batch will be reassigned to:</p>
                        </div>
                    }
                    <UserNameDropDown users={users} />
                </>
                
            )
        }

        return (
            <>
                <Row className="flex mb-3">
                <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                    <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                    {
                        Object.keys(selectedRowIds).length > 0 ?
                        'You have selected ' + Object.keys(selectedRowIds).length + ' batches' 
                        :
                        null
                    }
                    </h5>
                </Col>
                <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                    {Object.keys(selectedRowIds).length > 0 ? (
                    <div className="d-flex">
                        <Form.Select size="sm" aria-label="Bulk actions" onChange={(e) => setBulkActionOption(e.target.value)}>
                        <option>Bulk Actions</option>
                        <option value="remove">Remove From Batch</option>
                        <option value="reassign">Reassign Orders to Another Batch</option>
                        </Form.Select>
                        <Button
                        type="button"
                        variant="falcon-default"
                        size="sm"
                        className="ms-2"
                        onClick={() => setShowBulkModal(true)}
                        >
                        Apply
                        </Button>
                    </div>
                    ) : (
                        <div id="orders-actions">
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="plus"
                            transform="shrink-3"
                            className='me-2'
                            onClick={() => { setShowBulkModal(true); setBulkActionOption('newTeammateAssignment'); }}
                        >
                            <span className="d-none d-sm-inline-block ms-1">Reassign Batch</span>
                        </IconButton>
                        </div>
                    )}
                </Col>
                </Row>
                <Modal show={showBulkModal} onHide={handleClose} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <Modal.Title>
                            {bulkActionOption === 'remove' && 'Remove Orders from Batch'}
                            {bulkActionOption === 'reassign' && 'Reassign Orders to Batch'}
                            {bulkActionOption === 'newTeammateAssignment' && 'Reassign Batch to Another User'}
                        </Modal.Title>
                        <FalconCloseButton onClick={handleClose}/>
                    </Modal.Header>
                    <Modal.Body>
                        {bulkActionOption === 'remove' && <RemoveOrderFromBatch orders={selectedRows}/>}
                        {bulkActionOption === 'reassign' && <ReassignOrdersToAnotherBatch orders={selectedRows} />}
                        {bulkActionOption === 'newTeammateAssignment' && <ReassignUser batch={batch} />}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                        Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    };

    return (
        orders.length > 0 
        ?
        <AdvanceTableWrapper
            columns={colDef}
            data={orders}
            sortable
            pagination
            perPage={50}
            selection
            selectionColumnWidth={30}
        >
            <div className="mt-3">
                <BulAction table currentBatch={batch}/>
            </div>
            
            <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
                }}
            />
        </AdvanceTableWrapper>
        :
        ordersLoading ?
        <div className="text-center">
            <h3>No Orders</h3>
        </div>
            :
            <Lottie animationData={dotsAnimation} style={{width: 100, height: 100}} />
    )
}

const ViewBatchDetail = ({ children }) => {

    const { user, authLoading }= useContext(FirebaseAuthContext)
    const { batchId } = useParams();
    const navigate = useNavigate();
    
    const [batchRaw, batchLoading, batchError] = useDocument(doc(db, "customBatches", batchId));
    const [batch, setBatch] = useState(null);
    if (batchError) console.log(batchError);

    useEffect(() => {
        if (!batchRaw) return;
        const batch = batchRaw.data();
        batch.id = batchRaw.id;
        batch.ref = batchRaw.ref;
        setBatch(batch);
    }, [batchRaw])

    // Redirect to home if not logged in
    useEffect(() => {   
        if (authLoading) return;
        if (!user) return navigate('/');
      }, [authLoading, user])

    return (
        !batchLoading ?
        <>
            <PageHeader title={`Batch ${batch?.batchName}`} className={'mb-3'}>
                <div className="d-flex mt-2 float-start">
                    Assigned to: {batch?.assignedUser}
                </div>
                <div className='d-flex mt-2 float-end'>
                    <Button
                        variant="falcon-default"
                        size="sm"
                        className="ms-2"
                        onClick={() => navigate('/fulfillment/batches')}
                    >
                        Back to Batches
                    </Button>
                    <Button
                        variant="falcon-default"
                        size="sm"
                        className="ms-2"
                        onClick={() => navigate('/fulfillment/pick')}
                    >
                        Back to Pick Dashboard
                    </Button>
                </div>
            </PageHeader>
            <Card>
                <Card.Body>
                    <BatchTable batch={batch} />
                </Card.Body>
            </Card>
        </>
        :
        <Lottie animationData={dotsAnimation} style={{width: 100, height: 100}} />
    );
}

export default ViewBatchDetail;
