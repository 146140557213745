import { useEffect, useState, useRef } from "react";
import { db } from "config/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import ReactToPrint from "react-to-print";
import JsBarcode from "jsbarcode";
import { Modal, Button } from "react-bootstrap";
import FalconCloseButton from "components/common/FalconCloseButton";

async function collectSlotInformation(locationId) {

    if (!locationId) return;
    const slotQuery = query(collection(db, "locationCountData"), where("locationId", "==", locationId));
    const slotSnapshot = await getDocs(slotQuery);
    if (slotSnapshot.empty) return console.log("No matching documents.");
    const slotData = slotSnapshot.docs.map((doc) => doc.data());
    return slotData;
    
}

async function buildLabels(slotData) {
    if (!slotData || slotData.length <= 1) return;
    const labelData = [];
    for (let i = 0; i < slotData.length; i++) {

        // for each slot we're going to look up the corresponding 
        // product from the catalog and return the unit UPC
        const slot = slotData[i];

        const q = query(collection(db, "productCatalog/topicz/products"), where("sku", "==", slot.parentSku));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
            // console.log("No matching documents, checking other catalogs: ", slot.parentSku);
        } else {
            const productData = querySnapshot.docs.map((doc) => doc.data());
            const product = productData[0];
            const label = {
                slotId: slot.slot,
                sku: slot.sku,
                factor: slot.factor,
                name: product.name,
                gps: `${slot.row}-${slot.section}-${slot.shelf}`,
                upc: product.barcodes?.unit || product.barcodes?.stock || product.barcodes?.other || "N/A",
            }
            labelData.push(label);
        }
    }
    return labelData;
}

async function buildLabelImages(labelData) {
    // label = {
    //     slotId: slot.slot,
    //     sku: slot.sku,
    //     factor: slot.factor,
    //     name: product.name,
    //     gps: `${slot.row}-${slot.section}-${slot.shelf}`,
    //     upc: product.barcodes?.unit || product.barcodes?.stock || product.barcodes?.other || "N/A",
    // }

    if (!labelData || labelData.length <= 1) return;
    const labels = [];
    for (let i = 0; i < labelData.length; i++) {
        const label = labelData[i];
        const labelImage = (
            <div className="mb-2" key={`${label.slotId}-${i}`}>
                <div className="label__name">{label.sku} - {label.name}</div>
                <svg className="barcode"
                    jsbarcode-format="auto"
                    jsbarcode-value={label.upc}
                    jsbarcode-textmargin="0"
                    jsbarcode-fontoptions="bold"
                    jsbarcode-fontsize="12"
                    jsbarcode-height="20"
                    jsbarcode-width="3"
                >
                </svg>
            </div>
        );
        labels.push(labelImage);
    }
    return <>
        {labels}
    </>
}

export default function PrintLabels({ location}) {

    const [sd, setSlotData] = useState([]);
    const [ld, setLabelData] = useState([]);
    const [labelImages, setLabelImages] = useState([]);
    const [labelsLoading, setLabelsLoading] = useState(true);
    const [showDetailModal, setShowDetailModal] = useState(false);
    const componentRef = useRef(null);

    useEffect(() => {
        if (!location) return;
        return collectSlotInformation(location.id).then((data) => {
            setSlotData(data);
            return buildLabels(data).then((labels) => {
                setLabelData(labels);
                return buildLabelImages(labels).then((images) => {
                    setLabelImages(images);
                    setLabelsLoading(false);
                })
            })
        })
    }, []);

    return (
        <>
            <Button 
                disabled={labelsLoading} 
                variant="falcon-primary"
                onClick={() => setShowDetailModal(true)}
            >Print Labels</Button>
            <Modal
            size="lg"
            show={showDetailModal}
            onHide={() => setShowDetailModal(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            >
            <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-lg">
                Slot Labels
                </Modal.Title>
                <FalconCloseButton onClick={() => setShowDetailModal(false)}/>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-between">
                    <div className="d-flex">
                        <Button className='me-2' variant="falcon-primary" onClick={() => JsBarcode(".barcode").init()}>Generate</Button>
                        <ReactToPrint
                            trigger={() => <Button>Print</Button>}
                            content={() => componentRef.current}
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    <div id="listOfLabels" className="m-3" ref={componentRef}>
                        {labelImages}
                    </div>
                </div>
            </Modal.Body>
            </Modal>
        </>
        
    )
}

