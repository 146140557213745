
import React, { useRef, useState, useEffect } from 'react';
import { db } from 'config/firebase';
import { addDoc, collection, query, where, getDoc, getDocs, deleteDoc, doc, collectionGroup } from 'firebase/firestore';
import { Modal, Button, Tabs, Tab, Form, ListGroup, Row, Col, InputGroup, Table, Image} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import { debounce } from 'lodash';

export const ManagePricingTemplatesModal = ({ show, onClose }) => {

    const [templates, setTemplates] = useState([]);
    useEffect(() => {
        let isSubbed = true;
        const getTemplates = async () => {
            const q = query(
                collection(db, 'systemTemplates'),
                where('type', '==', 'pricing')
            );
            const querySnapshot = await getDocs(q);
            const templateData = [];
            querySnapshot.forEach((doc) => {
                templateData.push({ id: doc.id, ref: doc.ref, ...doc.data() });
            });
            return templateData;
        }
        getTemplates().then((data) => {
            if (isSubbed) {
                setTemplates(data);
            }
        });
        return () => isSubbed = false;
    }, []);

    return (
        <Modal show={show} onHide={onClose} size="fullscreen">
            <Modal.Header>
                <Modal.Title>Manage Pricing Templates</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs defaultActiveKey="createNew" id="pricingTemplateTabs" className='m-1'>
                    <Tab eventKey="createNew" title="Create New Template" className='border-bottom border-x p-3'>
                        <NewPricingTemplateForm />
                    </Tab>
                    <Tab eventKey="manage" title="Manage Existing" className='border-bottom border-x p-3'>
                        <ManageExistingTemplatesForm templates={templates}/>
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
    
}

const NewPricingTemplateForm = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [simSku, setSimSku] = useState(null);
    const simSkuInput = useRef();
    const [simSkuSearchResults, setSearchResults] = useState([]);
    const [simSkuData, setSimSkuData] = useState(null);
    const [errorState, setErrorState] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [simulationRunning, setSimulationRunning] = useState(false);
    const [productImageUrl, setProductImageUrl] = useState(null);

    const additionalFulfillmentCostsRef = useRef();
    const [afc, setAfc] = useState(0);
    const additionalChannelCostsRef = useRef();
    const [acc, setAcc] = useState(0);
    const additionalInboundCostsRef = useRef();
    const [aic, setAic] = useState(0);
    const templateNameRef = useRef();

    const minMarginRef = useRef();
    const [minMarginValue, setMinMarginValue] = useState(0);
    const targetMarginRef = useRef();
    const [targetMarginValue, setTargetMarginValue] = useState(0);
    const b2bMarginRef = useRef();
    const [b2bMarginValue, setB2bMarginValue] = useState(0);
    const maxMarginRef = useRef();
    const [maxMarginValue, setMaxMarginValue] = useState(0);

    const debounceFunction = (func, delay) => {
        return debounce(func, delay);
    };

    const handleNewTemplateFormSubmission = async (e) => {
        e.preventDefault();
        // Convert percentage input to decimal and store in variables

        const newName = templateNameRef.current.value;
        if (!newName.trim()) {
            toast.error('Template name is required', { theme: 'colored' });
            return;
        }

        const newTemplate = {
            name: newName,
            settings: {
                minMargin: minMarginValue,
                maxMargin: maxMarginValue,
                prefMargin: targetMarginValue,
                bizMargin: b2bMarginValue,
                additionalFulfillmentCost: afc,
                additionalChannelCost: acc,
                additionalInboundCost: aic,
            },
            type: 'pricing',
            timestamp: {
                created: new Date(),
                updated: new Date(),
            }
        }


        try {
            await addDoc(collection(db, 'systemTemplates'), newTemplate)
            toast.success('New template created', { theme: 'colored' });
            // wait a half sec then close modal
            setTimeout(() => {
                onClose();
            }, 500);
        } catch (error) {
            console.log(error);
            toast.error(error.message, { theme: 'colored' });
        }

    }

    const lookupSkuForSimulation = async (e) => {
        e.preventDefault();
        const lookupSku = simSkuInput.current.value;
        if (!lookupSku.trim()) {
            toast.error('SKU is required', { theme: 'colored' });
            return;
        } else {
            const results = await getProductData(lookupSku);
            setSearchResults(results);
            return;
        }
    }

    const getProductData = async (sku) => {
        // build a less than variable that starts with the sku and appends high value ascii characters
        // this will allow us to query for all skus that start with the sku
        // then we can filter the results to find the exact match
        const skuEnd = sku + '~~~~~~~~~~~';
        try {
            const q = query(
                collectionGroup(db, 'variants'),
                where('sku', '>=', sku),
                where('sku', '<=', skuEnd),
            );
            const querySnapshot = await getDocs(q);
            const productData = [];
            querySnapshot.forEach((doc) => {
                productData.push({ id: doc.id, ref: doc.ref, ...doc.data() });
            });
            return productData;
        } catch (error) {
            console.log(error)
        }
    }

    const runSimulation = async (skuData) => {
        setSimulationRunning(true);
        const parentRef = skuData.parentRef;
        if (parentRef) {
            const parentDoc = await getDoc(parentRef);
            const parentData = parentDoc.data();
            const cost = parentData.cost;
            if (cost && skuData.productFactor) {
                skuData.cost = cost * skuData.productFactor;
            }
            if (parentData.primaryImage) {
                setProductImageUrl(parentData.primaryImage);
            }
        }
        if (skuData.weightOz) {
            const shippingCost = await lookupShippingCost(skuData.weightOz);
            if (shippingCost) {
                skuData.quickShippingCost = shippingCost
            } else {
                skuData.quickShippingCost = 0.00;
            }
            // add additional costs to base cost
            let baseCost = skuData.cost;
            // if there are additional costs, add them to the base cost
            if (afc) { // fullfillment cost
                baseCost += Number(afc);
            }
            if (acc) { // channel cost
                baseCost += Number(acc);
            }
            if (aic) { // inbound cost
                baseCost += Number(aic);
            }
            let marketplaceFees = 0.15;
            const interim = Number(baseCost) + Number(skuData.quickShippingCost) + Number(baseCost * marketplaceFees);
            if (interim < 10) {
                marketplaceFees = 0.08;
            }
            const calculatePriceWithFees = (baseCost, margin, shippingCost, feeRate) => {
                const sc = Number(shippingCost);
                const bc = Number(baseCost);
                let priceBeforeFees;
                if (margin <= 1) {
                    priceBeforeFees = bc / (1 - margin);
                } else {
                    priceBeforeFees = bc + (bc * margin);
                }
                return priceBeforeFees + sc + (priceBeforeFees * feeRate);
            };
            const bc = Number(baseCost).toFixed(2);
            const sc = Number(shippingCost).toFixed(2);
            const minPrice = calculatePriceWithFees(bc, minMarginValue, sc, marketplaceFees);
            const maxPrice = calculatePriceWithFees(bc, maxMarginValue, sc, marketplaceFees);
            const targetPrice = calculatePriceWithFees(bc, targetMarginValue, sc, marketplaceFees);
            const businessPrice = calculatePriceWithFees(bc, b2bMarginValue, sc, marketplaceFees);
            skuData.minPrice = Number(minPrice).toFixed(2);
            skuData.maxPrice = Number(maxPrice).toFixed(2);
            skuData.targetPrice = Number(targetPrice).toFixed(2);
            skuData.businessPrice = Number(businessPrice).toFixed(2);
            skuData.marketplaceFees = marketplaceFees;
            skuData.baseCost = baseCost;
        }
        setSimulationRunning(false);
        return skuData;
    }

    const clearSimulation = (e) => {
        e.preventDefault();
        setSimSku(null);
        setSimSkuData(null);
        setSearchResults([]);
        setErrorState(false);
        setErrorMessage(null);
    }

    const selectSkuForSimulation = async (sku) => {
        setSimSku(sku);
        // find the sku in the search results and set the data
        const skuData = simSkuSearchResults.find((result) => result.id === sku);
        const simulatedSkuData = await runSimulation(skuData);
        setSimSkuData(simulatedSkuData);
    }

    const lookupShippingCost = async (weightOz) => {
        if (!weightOz) throw new Error('Weight is required');
        const weightRange = weightOz * 0.1;
        const floorWeight = weightOz - weightRange;
        const ceilWeight = weightOz + weightRange;
        const floorWeightOz = Math.floor(floorWeight);
        const ceilWeightOz = Math.ceil(ceilWeight);
        console.log(floorWeightOz, ceilWeightOz)
        try {
            const q = query(
                collection(db, 'qscLookup'),
                where('weight', '>=', Number(floorWeightOz)),
                where('weight', '<=', Number(ceilWeightOz)),
            );
            const querySnapshot = await getDocs(q);
            const shippingData = [];
            querySnapshot.forEach((doc) => {
                shippingData.push({ id: doc.id, ref: doc.ref, ...doc.data() });
            });
            const medianShippingCost = shippingData.reduce((acc, cur) => {
                return acc + Number(cur.weightedAverageCost);
            }, 0) / shippingData.length;
            console.log(medianShippingCost)
            return Number(medianShippingCost).toFixed(2);
        } catch (error) {
            console.log(error)
        }
    }

    const handleInputChange = debounceFunction((e, ref, stateSetter) => {
        e.preventDefault();
        const value = ref.current.value ? parseFloat(ref.current.value) / 100 : 0;
        stateSetter(value);
    }, 500); // 500 ms debounce time
    
    const handleAddCostChange = debounceFunction((e, ref, stateSetter) => {
        e.preventDefault();
        const value = ref.current.value ? parseFloat(ref.current.value) : 0;
        stateSetter(value);
    }, 500); // 500 ms debounce time

    useEffect(() => {
        if (simSku) {
            const skuData = simSkuSearchResults.find((result) => result.id === simSku);
            runSimulation(skuData).then((data) => {
                setSimSkuData(data);
            });
        }
    }, [minMarginValue, targetMarginValue, b2bMarginValue, maxMarginValue, afc, acc, aic])

    return (
        <Row>
            <Col>
                <Form onSubmit={(e) => handleNewTemplateFormSubmission(e)}>
                    <Form.Group className="mb-3" controlId="templateName">
                        <Form.Label>Template Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter Template Name" ref={templateNameRef}/>
                    </Form.Group>
                        <Form.Label style={{width:'15rem'}}>Applicability Period</Form.Label>
                        <Row className="mb-3"> 
                            <Col>
                                <InputGroup.Text>Start</InputGroup.Text>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date)=>setStartDate(date)}
                                    formatWeekDay={day => day.slice(0, 3)}
                                    className='form-control'
                                    placeholderText="Select Date"
                                />
                            </Col>
                            <Col>
                                <InputGroup.Text>End</InputGroup.Text>
                                <DatePicker
                                    selected={endDate}
                                    onChange={(date)=>setEndDate(date)}
                                    formatWeekDay={day => day.slice(0, 3)}
                                    className='form-control'
                                    placeholderText="Select Date"
                                />
                            </Col>
                        </Row>
                    <InputGroup className="mb-3">
                        <Form.Label style={{width:'15rem'}}>Minimum Margin</Form.Label>
                        <Form.Control type="text" placeholder="Enter the lowest acceptable profit margin" ref={minMarginRef} 
                            onChange={(e) => handleInputChange(e, minMarginRef, setMinMarginValue)}
                        />
                        <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <Form.Label style={{width:'15rem'}}>Target Margin</Form.Label>
                        <Form.Control type="text" placeholder="Enter the preferred margin" ref={targetMarginRef}
                            onChange={(e) => handleInputChange(e, targetMarginRef, setTargetMarginValue)}
                        />
                        <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <Form.Label style={{width:'15rem'}}>B2B Margin</Form.Label>
                        <Form.Control type="text" placeholder="Enter the desired B2B margin" ref={b2bMarginRef}
                            onChange={(e) => handleInputChange(e, b2bMarginRef, setB2bMarginValue)}
                        />
                        <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <Form.Label style={{width:'15rem'}}>Maximum Margin</Form.Label>
                        <Form.Control type="text" placeholder="Enter the maximum acceptable profit margin" ref={maxMarginRef}
                            onChange={(e) => handleInputChange(e, maxMarginRef, setMaxMarginValue)}
                        />
                        <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <Form.Label style={{width:'15rem'}}>Assigned Channel</Form.Label>
                        <Form.Select aria-label="Default select example">
                            <option>Select a designated channel</option>
                            <option value="1">Amazon</option>
                            <option value="2" disabled>Shopify</option>
                            <option value="3" disabled>Headless</option>
                        </Form.Select>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <Form.Label style={{width:'15rem'}}>Fulfillment Type</Form.Label>
                        <Form.Select aria-label="Default select example">
                            <option>Select a fulfillment method</option>
                            <option value="1">Self/Merchant</option>
                            <option value="2" disabled>Marketplace</option>
                            <option value="3" disabled>Third Party Provider</option>
                        </Form.Select>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <Form.Label style={{width:'15rem'}}>Additional Fulfillment Costs</Form.Label>
                        <InputGroup.Text>$</InputGroup.Text>
                        <Form.Control type="text" placeholder="Enter additional fulfillment costs" ref={additionalFulfillmentCostsRef}
                            onChange={(e) => handleAddCostChange(e, additionalFulfillmentCostsRef, setAfc)}
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <Form.Label style={{width:'15rem'}}>Additional Channel Costs</Form.Label>
                        <InputGroup.Text>$</InputGroup.Text>
                        <Form.Control type="text" placeholder="Enter additional channel costs" ref={additionalChannelCostsRef}
                            onChange={(e) => handleAddCostChange(e, additionalChannelCostsRef, setAcc)}
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <Form.Label style={{width:'15rem'}}>Additional Inbound Costs</Form.Label>
                        <InputGroup.Text>$</InputGroup.Text>
                        <Form.Control type="text" placeholder="Enter additional inbound costs" ref={additionalInboundCostsRef}
                            onChange={(e) => handleAddCostChange(e, additionalInboundCostsRef, setAic)}
                        />
                    </InputGroup>
                    <div className='text-end'>
                        <Button variant="primary" type="submit">
                            Create New Pricing Template
                        </Button>
                    </div>
                </Form>
            </Col>
            <Col>
                <Row>
                    <Col>
                        Pricing Simulator (Beta) {simulationRunning ? '⏳' : null}
                    </Col>
                    <Col>
                        <Form onSubmit={(e) => lookupSkuForSimulation(e)}>
                            <Form.Group className="mb-3" controlId="skuSim" >
                            <InputGroup className="mb-3">
                                <Form.Control type="text" placeholder="Enter SKU" ref={simSkuInput}/>
                                <Button variant="primary" type="submit" onClick={(e) => lookupSkuForSimulation(e)}>
                                    🔎
                                </Button>
                                <Button variant="danger" type="submit" onClick={(e) => clearSimulation(e)}>
                                    🗑️
                                </Button>
                            </InputGroup>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {!simSku ? (
                            <ListGroup>
                                {simSkuSearchResults.map((result) => {
                                    return (
                                        <ListGroup.Item key={result.id} onClick={() => selectSkuForSimulation(result.id)}>
                                            {result.sku}
                                        </ListGroup.Item>
                                    )
                                })}
                            </ListGroup>
                        )
                        : (
                            !errorState ? (
                                <div>
                                    <Row>
                                        <Col>
                                            <h6>
                                                {simSkuData?.title}
                                            </h6>
                                            <Table size='sm'>
                                    <tbody>
                                        <tr>
                                            <td>Cost</td>
                                            <td>${Number(simSkuData?.cost).toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Modified Cost</td>
                                            <td>${Number(simSkuData?.baseCost).toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Weight</td>
                                            <td>{Number(simSkuData?.weightOz).toFixed(2)} oz</td>
                                        </tr>
                                        <tr>
                                            <td>Shipping Cost</td>
                                            <td>${Number(simSkuData?.quickShippingCost).toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Final Cost</td>
                                            <td>${Number(simSkuData?.baseCost) + Number(simSkuData?.quickShippingCost)}</td>
                                        </tr>
                                        <tr>
                                            <td>Marketplace Fees</td>
                                            <td>{simSkuData?.marketplaceFees * 100}%</td>
                                        </tr>
                                        <tr>
                                            <td>Min Price</td>
                                            <td>${Number(simSkuData?.minPrice).toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Target Price</td>
                                            <td>${Number(simSkuData?.targetPrice).toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <td>B2B Price</td>
                                            <td>${Number(simSkuData?.businessPrice).toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Max Price</td>
                                            <td>${Number(simSkuData?.maxPrice).toFixed(2)}</td>
                                        </tr>
                                        
                                    </tbody>
                                    </Table>
                                        </Col>
                                        <Col>
                                            <Image src={productImageUrl} alt={simSkuData?.title} style={{maxWidth:'50%'}}/>
                                        </Col>
                                    </Row>
                                    
                                    
                                </div>
                            )
                            : (
                                <div>
                                    {errorMessage}
                                </div>
                            )
                            
                        )}
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}


const ManageExistingTemplatesForm = ({templates}) => {
    return (
        <ListGroup>
            {templates.map((template) => {
                return (
                    <ListGroup.Item key={template.id}>
                        <Row>
                            <Col>
                                {template.name}
                            </Col>
                            {/* button should flow to the right side **/}
                            <Col className='text-end'>
                                <Button variant="danger" size="sm" onClick={(e) => deleteTemplate(e, template.id)}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                )
            })}
        </ListGroup>
    )
}

const deleteTemplate = async (e, templateId) => {
    e.preventDefault();
    try {
        await deleteDoc(doc(db, 'systemTemplates', templateId));
        toast.success('Template deleted', { theme: 'colored' });
    } catch (error) {
        console.log(error);
        toast.error(error.message, { theme: 'colored' });
    }
}
