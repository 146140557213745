import { shipStationTags } from "components/pages/newOrders/context/shipStationTags";

const TagIdValueFormatter = ({ value }) => {
    const tag = shipStationTags.data[value];
    let name = '';
    if (tag && tag.name) {
        name = tag.name;
    } else {
        name = value;
    }
    return `${name}`;  
};

export default TagIdValueFormatter;