import React, { useState, useEffect } from 'react';
import { Nav, Modal, CloseButton, Button, Form, InputGroup, Spinner, Row, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart as cart} from '@fortawesome/free-solid-svg-icons'
import {addDoc, collection, query, setDoc, where, doc, getDocs} from 'firebase/firestore';
import { db } from 'config/firebase';
import { useCollectionData } from 'react-firebase-hooks/firestore';

// nav link class names
// notification-indicator notification-indicator-warning notification-indicator-fill

const styleObject = {
  position: 'relative',
  top: '-2px',
  backgroundColor: '#bed0f1',
  borderRadius: '50%',
  color: '#eef1f9',
  width: '32px',
  height: '32px'
}

async function getOpenOrders(uid) {
  try {
    const openOrders = []
    const q = query(
      query(
        collection(db, "openOrders"),
        where("uid", "==", uid), 
        where("status", "==", 'Open')
      )
    )
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      openOrders.push({docId: doc.id, ...doc.data()})
    })
    
    return openOrders
  } catch (error) {
    console.log("getOpenOrders", error);
  }
}

//<span className="notification-indicator-number">1</span>
const OpenOrders = ({ user }) => {
  
  const [showModal, setShowModal] = useState(false);
  const [openOrders, setOpenOrders] = useState([]);
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [nOpenOrders, nOpenOrdersLoading, nopeError] = useCollectionData(query(
    collection(db, "openOrders"),
    where("uid", "==", user.uid), 
    where("status", "==", 'Open')
  ));
  const navigate = useNavigate();

  async function createNewManualOrder(batchName) {
    try {
      const docRef = await addDoc(collection(db, "openOrders"), {
        orderBatchName: batchName,
        uid: user.uid,
        status: "Open"
      });
      const again = await setDoc(doc(db, "openOrders", docRef.id), {
        docId: docRef.id
      }, {merge: true});
      navigate(`cart/${docRef.id}`);

    } catch (error) {
      console.log(error);
    }
     
  }

  function handleNewOrder(e){
    e.preventDefault();
    setShowModal(false);
    const input = document.getElementById('newBatchName').value;
    createNewManualOrder(input)
  }

  useEffect(() => {
    getOpenOrders(user.uid).then((orders) => {
      setOpenOrders(orders)
      setOrdersLoading(false)
    })
    
  }, [user, getOpenOrders]);

  return (
    <>
      { user !== null 
      ?
        <Nav.Item as="li" className="rounded" key={"openOrdersNavItem"}>
        
          <Nav.Link
            as={Link}
            onClick={() => setShowModal(!showModal)}
            className="pe-0 ps-2 nav-link"
            key="openOrdersNavItemLink"
          >
            
            <div style={styleObject} key={"openOrdersNavStyleObject"}>
              
              <FontAwesomeIcon
                key={"openOrdersNavStyleObjectIcon"}
                icon={cart}
                transform="shrink-7"
                className="fs-4"
                style={{marginLeft: '-3px'}}
                variant={"falcon-danger"}
              />
              
            </div>
          </Nav.Link>
        </Nav.Item>
      :
        null
      }
      
      <Modal
        show={showModal}
        onHide={() => setShowModal(!showModal)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        key={"openOrdersNavItemModal"}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Open Manual Orders</Modal.Title>
          <CloseButton
            className="btn btn-circle btn-sm transition-base p-0"
            onClick={() => setShowModal(!showModal)}
          />
        </Modal.Header>
        <Modal.Body>

          <h5>Create New Order</h5>
          <Form onSubmit={(e) => handleNewOrder(e)} key={"newOrderFormKey"}>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Order Or Batch Name"
                aria-label="Order Or Batch Name"
                aria-describedby="basic-addon2"
                id="newBatchName"
                key={"newBatchNameInputKey"}
              />
              <Button type={"submit"} variant="falcon-success" id="button-addon2">
                Start New Order
              </Button>
            </InputGroup>
          </Form>

          <h5>Continue an Existing Order</h5>
          
          {!nOpenOrdersLoading ?
              nOpenOrders.map((order, idx) => {
                if(order){
                  if (order.status !== 'Submitted') {
                    return (
                    <Row key={`row_${idx}`}>
                      <Col key={`col_${idx}`}>
                        <Link to={`cart/${order.docId}`} key={idx} onClick={() => setShowModal(!showModal)}>
                          {order.orderBatchName}
                        </Link>
                      </Col>
                    </Row>
                    )
                  }
                }
                
                
              })  
              :
              <Spinner />
            }

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowModal(!showModal)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
    
  );
};

export default OpenOrders;
