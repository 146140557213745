import { db } from 'config/firebase.js';
import { useEffect, useState } from 'react';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const useRoleData = (uid) => {
    
    const [roleData, setUserData] = useState({});
    const [orgData, setOrgData] = useState({});
    const [userLoading, setUserLoading] = useState(true);
    const [userError, setUserError] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        
        const populateUserData = async () => {
            try {
                
                const userQuery = query(collection(db, "users"), where("uid", "==", uid));
                const userSnapshot = await getDocs(userQuery);
                const userRoleData = [];
                userSnapshot.forEach((doc) => {
                    userRoleData.push({docId: doc.id, docRef: doc.ref, ...doc.data()})
                })
                if (userRoleData.length === 0) {
                    setUserError(new Error('No user data found'));
                    setUserLoading(false);
                    return;
                }
                if (userRoleData.length > 1) {
                    setUserError(new Error('Multiple users found'));
                    setUserLoading(false);
                    return;
                }
                setUserData(userRoleData[0]);
                setOrgData(userRoleData[0].Organization || 'None');
                setUserLoading(false);
            } catch (error) {
                if (error.code === 'permission-denied') {
                    navigate('/error');
                    setUserError(new Error('Permission denied'));
                    setUserLoading(false);
                    return;
                }
                setUserLoading(false);
                setUserError(true);
            }
        }

        if (uid) {
            populateUserData();
        } 

    }, [uid]);
    
    return [roleData, orgData, userLoading, userError];

}

export default useRoleData;