import { Badge } from 'react-bootstrap';

const BatchType = ({ value }) => {

    return (
        <Badge pill bg="light" className="fs--2 text-primary">
            {value ? "Self Pick" : "Team Pick"}
        </Badge>
    )
}

export default BatchType;