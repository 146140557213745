import { useState } from "react";
import { Carousel, Image } from "react-bootstrap";
import Flex from "components/common/Flex";

export default function ProductCarousel  ({images})  {

    const [currentIndex, setCurrentIndex] = useState();

    return (
      <div style={{maxHeight: '50rem'}}>
        <Carousel variant='dark' className={'text-primary'} indicators={false} activeIndex={currentIndex}>
        
            {images.map((img, index) => {

                return (
                    <Carousel.Item key={`caro-prod-img-${index}`}>
                        <div className="d-flex justify-content-center">
                        <img
                            className="align-middle"
                            style={{width: 'auto', height: '255px', maxHeight: '255px'}}
                            src={img.src}
                            alt="Primary Product Images"
                            key={`prod-img-${index}`}

                        />
                        </div>
                    </Carousel.Item>
            )})}
        
        </Carousel>
        <Flex direction={'row'} alignContent="start" justifyContent="center" className={'bg-100 p-3'}>
            {images.map((img, index) => (
              <Image thumbnail className="w-25 me-2" style={{maxHeight: '5rem', maxWidth: '5rem'}} src={img.src} key={`imgThumb-${index}`} onClick={() => setCurrentIndex(index)} />
            ))}
        </Flex>
      </div>
    );
};