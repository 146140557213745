import React, { useEffect, useState } from 'react';
import { Modal, Button, Row, Col, ListGroup, Alert } from 'react-bootstrap';
import { db } from 'config/firebase';
import { collection, query, where, getDocs, writeBatch } from 'firebase/firestore';
import { toast } from 'react-toastify';

export const AssignTemplateModal = ({ type, show, setShow, data, setRefetch }) => {

    const [savedTemplates, setSavedTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    let templateType = '';
    switch (type) {
        case 'pricing':
            templateType = 'pricingTemplate';
            break;
        case 'shipping':
            templateType = 'shippingTemplate';
            break;
        default:
            break;
    }

    useEffect(() => {
        if (show && type) {
            getSavedTemplates(type).then((savedTemplates) => {
                setSavedTemplates(savedTemplates);
            });
        }
    }, [show, type]);

    const onClose = () => {
        setShowAlert(false);
        setAlertMessage('');
        setSelectedTemplate(null);
        setSavedTemplates([]);
        setShow(false);
    }

    const assignTemplateToProducts = async (e, templateId, selectedProducts) => {
        e.preventDefault();
        if (!templateId) {
            setAlertMessage('Please select a template');
            setShowAlert(true);
            return;
        }
        try {
            const batch = writeBatch(db);
            for (const variant of selectedProducts) {
                const docRef = variant.ref;
                batch.update(docRef, {
                    [templateType]: templateId
                });
            }
            await batch.commit();
            toast.success('Template assigned to products', {theme: 'colored'});
            // trigger a source data refetch & pause a sec and then close the modal
            setRefetch((prev) => !prev);
            setTimeout(() => {
                onClose();
            }, 1000);
        } catch (error) {
            toast.error(error.message, {theme: 'colored'});
        }   
    }
    
    return (    
        <Modal
            show={show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton>
                <Row>
                    <Col>
                        <span>Assign <span className='text-capitalize'>{type}</span> Template to {data.length} Products</span>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Alert show={showAlert} variant={'danger'}>
                            {alertMessage}
                        </Alert>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ListGroup>
                            {savedTemplates.map((template) => (
                                <ListGroup.Item 
                                    active={selectedTemplate === template.id}
                                    key={template.id}
                                    onClick={() => setSelectedTemplate(template.id)}
                                    className="py-1 my-1"
                                >
                                    {template.name}
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={(e) => assignTemplateToProducts(e, selectedTemplate, data)}>Assign</Button>
            </Modal.Footer>
        </Modal>
    )
}

const getSavedTemplates = async (type) => {
    console.log('gst', type)
    const q = query(collection(db, 'systemTemplates'), where('type', '==', type));
    const querySnapshot = await getDocs(q);
    const templates = [];
    querySnapshot.forEach((doc) => {
        templates.push({ id: doc.id, ref: doc.ref, ...doc.data() });
    });
    return templates;
}