import { useNavigate, useParams } from 'react-router-dom';
import { db } from 'config/firebase';
import { doc, getDoc } from 'firebase/firestore';
import PageHeader from 'components/common/PageHeader';
import WizardForms from 'components/pages/vendorMgmt/WizardForms';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { Button } from 'react-bootstrap';

const VendorSignup = () => {
  //const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const params = useParams();
  // const { user, authLoading }= useContext(FirebaseAuthContext)
  //const [vendorDetails, setVendorDetails] = useState();
  const [vendorDetails, loading, error, vendorSnap] = useDocumentData(
    doc(db, "vendors", params.vendorId)
  );

  return (
    <>
    {!loading
      ?
        <>
          <PageHeader
            title={`Welcome ${vendorDetails.name}`} titleTag="h5" className="mb-3">
            <div className='mt-4'>
              Dear {vendorDetails.primaryContact},
              <br />
              We would like to welcome you as a new supplier to our company! In order to facilitate a smooth transition,
              we ask that you please complete the following steps to set up your account.
              <br />
              <br />
              <strong>Step 1:</strong> Complete the Vendor Information Wizard.
              <br />
              <strong>Step 2:</strong> Complete the W-9 Request for Taxpayer Indentification Number and Certification.
              <br />
              <strong>Step 3:</strong> Add New Products via the New Product Wizard or Bulk Upload.
              <br />
              <br />
              If you have any questions, please contact us at 1-800-555-5555.
              <br />
              <br />
              Thank you,
              <br />
              <br />
              <strong>Vendor Management Team</strong>
            </div>
          </PageHeader>
          {vendorDetails.status === 'Pending' ?
            <WizardForms vendorDetails={vendorDetails} vendorSnap={vendorSnap}/>
          : 
            <>
              <div className="alert alert-success" role="alert">
                <h4 className="alert-heading">Your primary account and supplier information has been created!</h4>
                <p>
                  Thank you! Please visit the dashboard to complete your profile and add your products.
                </p>
                <hr />
                <p className="mb-0">
                  <Button variant='falcon-success' className="me-3" onClick={() => navigate('/dashboard')}>Go to Dashboard</Button>
                  <Button variant='falcon-success' className="me-3" onClick={() => navigate('/bulk-upload')}>Upload Products</Button>
                </p>
              </div>
            </>
          }
          
        </>
      :
        <div>Loading...</div>  
    }
    </>
  );
};
// 
export default VendorSignup;
