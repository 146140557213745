import { Badge } from 'react-bootstrap';

const BatchState = ({ value }) => {
    let DisplayComponent = null;
    switch (value) {
        case "Initialized":
            DisplayComponent = <Badge pill bg="light" className="fs--2 text-primary">Initialized</Badge>;
            break;
        case "Ready":
            DisplayComponent = <Badge pill bg="light" className="fs--2 text-success">Ready</Badge>;
            break;
        case "Submitted":
            DisplayComponent = <Badge pill bg="light" className="fs--2 text-success">Submitted</Badge>;
            break;
        case "InProcess":
            DisplayComponent = <Badge pill bg="light" className="fs--2 text-secondary">Processing</Badge>;
            break;
        case "Completed":
            DisplayComponent = <Badge pill bg="light" className="fs--2 text-success">Completed</Badge>;
            break;
        default:
            DisplayComponent = <Badge pill bg="light" className="fs--2 text-danger">Unknown</Badge>;
            break;
    }
    return DisplayComponent;
}

export default BatchState;