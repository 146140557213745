import React from 'react';
import ProfileBanner from './Banner';
import ProfileIntro from './ProfileIntro';
import { Col, Row } from 'react-bootstrap';

import Photos from './Photos';

const Profile = () => {
  return (
    <>
      <ProfileBanner />
      <Row className="g-3 mb-3">
        <Col lg={8}>
          <ProfileIntro />
          <Photos />
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar">
           
            
          </div>
        </Col>
      </Row>
      
    </>
  );
};

export default Profile;
