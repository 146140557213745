import PageHeader from "components/common/PageHeader";
import { Card, Modal, Button, Form, Row, Col, Image, Alert} from "react-bootstrap";
import { useState, useRef, useEffect } from "react";
import { db } from "config/firebase";
import { query, collection, getDocs, where, updateDoc } from "firebase/firestore";
import NewSlot from "components/inventoryPages/newSlot";

async function searchWithUPC(barcodeResult) {
    
    if (!barcodeResult) return;
    //let processedBarcode = String(parseInt(barcodeResult));
    let barcodeCharArray = [...barcodeResult];
    let processedCharArray = [];
    barcodeCharArray.forEach((char, idx) => {
        processedCharArray.push(char)
        if (idx != 0){
            
        }
    })
    let processedBarcode = processedCharArray.join('');

    if (processedBarcode !== undefined) {
        console.log(processedBarcode)
        const q1 = query(
            collection(db, 'productCatalog/topicz/products'),
            where('barcodes.unit', "==", processedBarcode)
        );
        const q2 = query(
            collection(db, 'productCatalog/topicz/products'),
            where('barcodes.stock', "==", processedBarcode)
        )
        const q3 = query(
            collection(db, 'products'),
            where('barcodes.other', "==", processedBarcode)
        )
        let resultsSnap;
        resultsSnap = await getDocs(q1);
        if (resultsSnap.empty) resultsSnap = await getDocs(q2);
        if (resultsSnap.empty) resultsSnap = await getDocs(q3);
        if (resultsSnap.empty) return "Barcode Not Found";
        let result;
        resultsSnap.forEach((doc) => {
            result = doc.data();
        })

    
        return result;
    } else {
        return 'Not Found'
    }
    
    
}

async function searchIR(parentSku) {
    try {
        const q = query(
            collection(db, "locationCountData"),
            where("parentSku", "==", parentSku)
        );
        const snap = await getDocs(q);
        let result;
        if (snap.empty) return "Not Found"
        snap.forEach((doc) => {
            result = doc.data();
            result.id = doc.id;
            result.ref = doc.ref
        })
        return result;
    } catch (error) {
        console.log(error)
    }
}

const SearchByUPC = () => {

    const [scanningState, setScanningState] = useState(false);
    const [scannedProduct, setScannedProduct] = useState();
    const [locationData, setLocationData] = useState();
    const [socketScanResults, setSocketScanResults] = useState();
    const [newCount, setNewCount] = useState([]);
    const [newCountDisplay, setNewCountDisplay] = useState();
    const [successMsg, setSuccessMsg] = useState();

    async function handleCountInput(e, figure) {
        e.preventDefault();
        setNewCount((existing) => [...existing, figure])
    }

    useEffect(() => {
      if(newCount.length !== null && newCount.length > 0) {
        let temp = newCount.join('');
        setNewCountDisplay(temp);
      }
    }, [newCount])

    async function handleCountBack(e) {
        e.preventDefault()
        let temp = newCount;
        temp.pop();
        setNewCount(() => temp);
        let tempString = newCount.join('');
        setNewCountDisplay(tempString);
    }

    async function handleSocketScan(e) {
        e.preventDefault();
        setScanningState(true);
        setSuccessMsg();
        setScannedProduct();
        setLocationData();
        const el = document.getElementById('socketScanInput');
        setSocketScanResults(el.value);
        
    }

    async function updateLocationDataCount(locationDocRef, newCount) {
        try {
            await updateDoc(locationDocRef, { count: String(newCount), lastUpdate: new Date()})
            setSuccessMsg('Item Count Updated');
            setLocationData()
            setNewCount([]);
            setNewCountDisplay('');
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        
        searchWithUPC(socketScanResults).then((results) => {
            if (results != undefined) {
                searchIR(results?.sku).then((inventory) => {
                    setScannedProduct(results);
                    setLocationData(inventory);
                    setScanningState(false);
                    const el = document.getElementById('socketScanInput');
                    el.value = '';
                    el.focus();
                });
            }
        });

    }, [socketScanResults])
    

    return (
        <>
            <PageHeader title="Stocktake Scanner" titleTag="h5" className="mb-3">
                <div className="mt-3">
                    <NewSlot />
                </div>
            </PageHeader>
            <Card className="p-3 mb-3">
                <Row className="mb-3">
                    <Col>
                        <Form onSubmit={(e) => handleSocketScan(e)}>
                            <Row>
                                <Col className="">
                                    <Form.Group>
                                        <Form.Control type="text" id='socketScanInput' autoFocus/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Button onClick={() => {
                                        const el = document.getElementById('socketScanInput');
                                        el.value = '';
                                        el.focus();
                                    }}>Reset</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        { successMsg ? 
                            <Alert variant="success"> 
                                Count Updated 
                            </Alert> 
                        : null }
                    </Col>
                </Row>
            </Card>
            <Card className="p-3 mb-3">
            {scannedProduct && !scanningState ?
              <>
                <Row className="mb-3">
                    <Col><Image src={scannedProduct?.primaryImage} width="100vw"/></Col>
                    <Col><a href={`${scannedProduct?.productUrl}`}>{scannedProduct?.name}</a></Col>
                </Row>
                

                {locationData  ?
                    <>  
                        {locationData != 'Not Found' ?
                        <div id="inputDisplay">
                            
                                <div className="display-4 border border-2 d-block mb-3" style={{width: '100%', height: '5vh', textAlign: 'right'}}>
                                    {newCountDisplay}
                                </div>
                            
                            <div className="d-grid gap-1" fluid="true">
                                <Row xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} className={'mb-2'}>
                                    <Col><Button onClick={(e) => handleCountInput(e, '1')} style={{display: 'block', width: '100%'}} size="lg" variant="falcon-primary">1</Button></Col>
                                    <Col><Button onClick={(e) => handleCountInput(e, '2')} style={{display: 'block', width: '100%'}} size="lg" variant="falcon-primary">2</Button></Col>
                                    <Col><Button onClick={(e) => handleCountInput(e, '3')} style={{display: 'block', width: '100%'}} size="lg" variant="falcon-primary">3</Button></Col>
                                </Row>
                                <Row xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} className={'mb-2'}>
                                    <Col><Button onClick={(e) => handleCountInput(e, '4')} style={{display: 'block', width: '100%'}} size="lg" variant="falcon-primary">4</Button></Col>
                                    <Col><Button onClick={(e) => handleCountInput(e, '5')} style={{display: 'block', width: '100%'}} size="lg" variant="falcon-primary">5</Button></Col>
                                    <Col><Button onClick={(e) => handleCountInput(e, '6')} style={{display: 'block', width: '100%'}} size="lg" variant="falcon-primary">6</Button></Col>
                                </Row>
                                <Row xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} className={'mb-2'}>
                                    <Col><Button onClick={(e) => handleCountInput(e, '7')} style={{display: 'block', width: '100%'}} size="lg" variant="falcon-primary">7</Button></Col>
                                    <Col><Button onClick={(e) => handleCountInput(e, '8')} style={{display: 'block', width: '100%'}} size="lg" variant="falcon-primary">8</Button></Col>
                                    <Col><Button onClick={(e) => handleCountInput(e, '9')} style={{display: 'block', width: '100%'}} size="lg" variant="falcon-primary">9</Button></Col>
                                </Row>
                                <Row xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} className={'mb-2'}>
                                    <Col><Button onClick={(e) => handleCountBack(e)} style={{display: 'block', width: '100%'}} size="sm" variant="falcon-danger">Back</Button></Col>
                                    <Col><Button onClick={(e) => handleCountInput(e, '0')} style={{display: 'block', width: '100%'}} size="lg" variant="falcon-primary">0</Button></Col>
                                    <Col>
                                        <Button style={{display: 'block', width: '100%'}} size="sm" variant="falcon-primary" 
                                            onClick={async () => {
                                                await updateLocationDataCount(locationData.ref, newCountDisplay)
                                                const el = document.getElementById('socketScanInput');
                                                el.value = '';
                                                el.focus();
                                            }}
                                        >Send</Button>
                                    </Col>
                                </Row>
                            </div>
                            
                        </div>
                        : <p> Not In Inventory Room </p>
                        }
                    </>
                    
                : null
                }
              </>
            : 'No results'
            }
            </Card>
        </>
    )
}
   
export default SearchByUPC;