import React, { useState, useEffect, useContext } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { auth, db } from 'config/firebase';
import {
  where,
  query,
  collection,
  getDocs,
  orderBy,
  limit,
  updateDoc,
  doc,
  arrayUnion
} from 'firebase/firestore';
import {
  Spinner,
  Card,
  Image,
  Table,
  Row,
  Col,
  Button,
  Form } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import PageHeader from 'components/common/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import walmartIcon from 'assets/merlin/walmart.png';
import amazonIcon from 'assets/merlin/amazon.png';
import ebayIcon from 'assets/merlin/ebay.png';
import { useDocumentData, useCollectionData } from 'react-firebase-hooks/firestore';
import useRoleData from 'hooks/useRoleData.js';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import findSlugForSku from 'helpers/findSlugForSku';

const Orders = () => {
  //const [user, loading] = useAuthState(auth);
  const { user, authLoading }= useContext(FirebaseAuthContext)
  const navigate = useNavigate();
  const [nCurrentOrders, ordersLoading, ordersError] = useCollectionData( 
    query(
        collection(db, "orders"),
        where("orderStatus", "==", "awaiting_shipment"), 
        orderBy('modifyDate', 'desc'),
        limit(2500)
        ), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
  const [shipStationTags, ssTagsLoading] = useCollectionData(query(collection(db, "shipStationTags")));
  const [roleData, orgData, userLoading, userError] = useRoleData(user?.uid);

  // default state for page to load in
  const [filter, setFilter] = useState('AWAITING');
  const [openCarts, setOpenCarts] = useState();
  

  function getShipStationTag(tagId) {

      const SSTAGS = [
        {
            "tagId": 59532,
            "name": "*Cold Pack*",
            "color": "#1A3E94"
        },
        {
            "tagId": 59210,
            "name": "*Meltable*",
            "color": "#3366FF"
        },
        {
            "tagId": 77940,
            "name": "Aerosol",
            "color": "#CCFFCC"
        },
        {
            "tagId": 73379,
            "name": "Amazon Prime Order",
            "color": "#CCFFFF"
        },
        {
            "tagId": 72641,
            "name": "B2B Customer",
            "color": "#008000"
        },
        {
            "tagId": 60841,
            "name": "Billed Product",
            "color": "#FFFF00"
        },
        {
            "tagId": 74799,
            "name": "Buyer Waiting for ReStock. ",
            "color": "#993300"
        },
        {
            "tagId": 81049,
            "name": "Contacted",
            "color": "#808000"
        },
        {
            "tagId": 63136,
            "name": "Do Not Ship/Zonos",
            "color": "#FF00FF"
        },
        {
            "tagId": 79469,
            "name": "Explosive",
            "color": "#FF6600"
        },
        {
            "tagId": 76910,
            "name": "FROZEN/DRY ICE",
            "color": "#D6D6D6"
        },
        {
            "tagId": 59606,
            "name": "International",
            "color": "#FF0000"
        },
        {
            "tagId": 77747,
            "name": "Needs Fixed",
            "color": "#800080"
        },
        {
            "tagId": 66263,
            "name": "No Packing Slip",
            "color": "#000000"
        },
        {
            "tagId": 75969,
            "name": "OUT OF STOCK",
            "color": "#700303"
        },
        {
            "tagId": 81194,
            "name": "RTS",
            "color": "#808080"
        },
        {
            "tagId": 59252,
            "name": "Shipsurance Needed",
            "color": "#FF99CC"
        },
        {
            "tagId": 59250,
            "name": "Xtra Shipping Paid",
            "color": "#00FF00"
        }
      ];
      return SSTAGS.filter((tag) => tag.tagId === tagId);
  }

  const columns = [
    {
      accessor: 'orderNumber',
      Header: 'Order #',
      Cell: rowData => {
        const { orderId, orderNumber, orderStatus } = rowData.row.original;
        let DisplayText = '';
        switch (orderStatus) {
          case 'awaiting_shipment':
            DisplayText = <SoftBadge pill bg="primary" className="fs--2">
                              {'Awaiting Shipment'}
                              <FontAwesomeIcon
                                icon={['fa', 'bell']}
                                className="ms-1"
                                transform="shrink-2"
                              />
                          </SoftBadge>;
            break;
          case 'shipped':
            DisplayText = <SoftBadge pill bg="success" className="fs--2">
                              {'Shipped'}
                              <FontAwesomeIcon
                                icon={['fa', 'envelope']}
                                className="ms-1"
                                transform="shrink-2"
                              />
                          </SoftBadge>;
            break;
          default:
            DisplayText = <SoftBadge pill bg="warning" className="fs--2">
                              {orderStatus}
                              <FontAwesomeIcon
                                icon={['fa', 'exclamation']}
                                className="ms-1"
                                transform="shrink-2"
                              />
                          </SoftBadge>;
            break;
        }
        return (
          <div><div className='mb-2'>{DisplayText}</div><p><a href={`/orders/${orderId}`}>{orderNumber}</a></p></div> 
        );
      }
      
    },
    {
      accessor: 'shipByDate',
      Header: '⏰',
      Cell: rowData => {
        const { shipByDate } = rowData.row.original;
        const checkDate = new Date(shipByDate);
        const rightNow = new Date();
        const diffTime = Math.abs(rightNow - checkDate);
        // ms * seconds * minutes == hours
        const diffMinutes = Math.ceil(diffTime / (1000 * 60 * 60))
      
        let indicatorColor = '';
        if (diffMinutes <= 2) indicatorColor = '#FF5733';
        if (diffMinutes >= 3 && diffMinutes < 5) indicatorColor = '#DF4C2D';
        if (diffMinutes >= 6 && diffMinutes < 10) indicatorColor = '#BF4126';
        if (diffMinutes >= 11 && diffMinutes < 15) indicatorColor = '#9F3620';
        if (diffMinutes >= 16 && diffMinutes < 20) indicatorColor = '#802B1A3';
        if (diffMinutes >= 21 && diffMinutes < 34) indicatorColor = '#602113';
        if (diffMinutes >= 35 && diffMinutes < 100) indicatorColor = '#200B06';

        // 462212 is the number we get from SHOP THE KING
        return (
          <div style={{
            borderRadius: "50%",
            width: "28px",
            height: "28px",
            padding: "3px",
            background: "#fff",
            border: "0.5px solid",
            borderColor: indicatorColor,
            color: indicatorColor,
            textAlign: "center",
            font: "20px"
          }}><span>{diffMinutes !== 462212 ? diffMinutes : '?'}</span></div>
      
        );
      }
    },
    {
      accessor: 'advancedOptions',
      Header: '🌐',
      Cell: rowData => {
        const { advancedOptions } = rowData.row.original;
        const { storeId } = advancedOptions;
        // TODO = Need to make this a API lookup
        function showStoreName(storeId) {
          const storeNames = 
          [
            {
              id: '330261',
              name: 'Manual Order',
            },
            {
              id: '346169',
              name: 'Rate Browser',
            },
            {
              id: '453798',
              name: 'Alibaba',
            },
            {
              id: '454628',
              name: 'PayPal',
            },
            {
              id: '473569',
              name: 'Walmart US',
            },
            {
              id: '473848',
              name: 'eBay',
            },
            {
              id: '473849',
              name: 'Amazon US',
            },
            
            {
              id: '474015',
              name: 'Shop the King',
            }
          ];
          let name = '';
          storeNames.forEach((store) => {
            if (storeId.toString() === store.id) name = store.name;
          })
          return name;
        }
        let storeName = showStoreName(storeId);
        let DisplayText = '';
        switch (storeName) {
          case 'Amazon US':
            DisplayText = <SoftBadge pill bg="light" className="fs--2">
                            <img width={20} src={amazonIcon} alt="Amazon Logo"></img>
                          </SoftBadge>;
            break;
          case 'Walmart US':
            DisplayText = <SoftBadge pill bg="light" className="fs--2">
                            <img width={20} src={walmartIcon} alt="Amazon Logo"></img>
                          </SoftBadge>;
            break;
          case 'eBay':
            DisplayText = <SoftBadge pill bg="light" className="fs--2">
                            <img width={30} src={ebayIcon} alt="Amazon Logo"></img>
                          </SoftBadge>;
            break;   
          default: 
            DisplayText = <SoftBadge pill bg="light" className="fs--2">
                            {storeName}
                          </SoftBadge>;
            break;
        }
        return (
            
                <span>{DisplayText}</span>
            
        );
      }
    },
    {
      accessor: 'items',
      Header: 'Items',
      Cell: rowData => {
        const { items, tagIds } = rowData.row.original;

        const displayTags = [];

        if (tagIds?.length > 0 && !ssTagsLoading) {

          for (let i = 0; i < tagIds.length; i++) {
            let tagData;
              tagData = getShipStationTag(tagIds[i])
            displayTags.push(tagData);
          }
        } 

        return (
          <>
            
            <Table responsive borderless>
            <tbody>
            {
              items.map((item) => {
                let name = item.name;
                if (name.length > 90) name = name.slice(0, 85) + "....";
                
                return (
                    <tr key={item.lineItemKey}>
                     {/** TODO - add link */}
                      <td width={100}><strong><a href={``}>{item.sku}</a></strong><br />{item.warehouseLocation}</td>
                      <td width={60}><Image src={item.imageUrl} fluid width={50}/></td><td>{name}</td>
                    </tr>
                  )
                
              })
            }
            <tr>
              <td colSpan={3}>
                <span>
                  {displayTags.map((tag) => {
                    let thisTag = tag[0];
                    if (!thisTag) return null;
                    if (!thisTag.name) tag.name = 'Unknown';
                    return (
                      <Button size="sm" style={{backgroundColor: tag.color}} className='me-2'>
                        {tag[0]?.name}
                        </Button>
                      )
                  })}
              </span>
              </td>
            </tr>
            </tbody>
            </Table>
          </>
          
        );
      }
    },
    {
      accessor: '',
      Header: 'Add to Order',
      Cell: rowData => {
        const { items, orderKey, prePicked } = rowData.row.original;
        const processedItems = [];
        items.forEach((item) => {
          const firstLetter = item.sku.slice(0,1);
          if (firstLetter === 'B' || firstLetter === 'S' ) {
            /** TODO Need to manage bundles / kits */
          } else {
            processedItems.push({
              sku: item.sku,
              quantity: item.quantity
            })
          }
        })
        
        return (
          <span>
          {processedItems.length > 0 && prePicked !== true ?
            <PrePickSelectCartForm carts={openCarts} items={items} orderKey={orderKey} />
          : prePicked ? <span className='fs-1 text-primary'>Pre-Picked</span> : null
          }
          </span>
        );
      }
    },
    {
      accessor: 'orderTotal',
      Header: 'Order Total',
      Cell: rowData => {
        const { orderTotal } = rowData.row.original;
        
        return (
          <span>
            ${orderTotal}
          </span>
        );
      }
    },
  ];

  async function markOrderAsPrePicked(orderKey) {
    try {
      const ordersRef = collection(db, "orders"); 
      const q = query(ordersRef,
        where("orderKey", "==", String(orderKey)),
        limit(1)
      );
      const querySnap = await getDocs(q);
      let orderId;
      querySnap.forEach((doc) => {
        orderId = doc.id;
      })
      const docRef = doc(db, "orders", orderId);
      await updateDoc(docRef, {
        prePicked: true
      })
    
    } catch (error) {
      console.log(error);
    }
  }

  const PrePickSelectCartForm = (data) => {

    const carts = data?.carts;
    const items = data?.items;
    const key = data?.orderKey;

    async function addToCart(e, items, key) {
      e.preventDefault();
      const cartId = e.target.cartName.value;
      
      try {
        const docRef = doc(db, "openOrders", cartId);
        for (let i = 0; i < items.length; i++) {
          await updateDoc(docRef, {
            items: arrayUnion({
                picture: items[i]?.imageUrl,
                quantity: items[i]?.quantity,
                sku: items[i]?.sku,
                title: items[i]?.name,
                cost: "0.00",
              }),
            })
            markOrderAsPrePicked(key)
        }
        console.log({orderKey: key})
        
      } catch (error) {
        console.log(error);
      }
       
    }

    return (
      <Form onSubmit={(e) => addToCart(e, items, key)}>
        <Row>
          <Col>
            <Form.Select
              id='cartName'
            >
              {carts ?
                carts.map((cart) => {
                return (<option value={cart.docId}>{cart.orderBatchName}</option>)
              })
              : null
              }
            </Form.Select>
          </Col>
          </Row>
          <Row>
          <Col>
            <Button variant='falcon-primary' type="submit">Add</Button>
          </Col>
        </Row>
          
      </Form>
    )
  }

  useEffect(() => {
    if (authLoading) return;
    if (!user) return navigate('/');
    

    async function getOpenCarts(uid) {
      try {
        const openOrders = []
        const q = query(
          query(
            collection(db, "openOrders"),
            where("uid", "==", uid), 
            where("status", "==", "Open")
          )
        )
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          openOrders.push({docId: doc.id, ...doc.data()})
        })
        
        return openOrders
      } catch (error) {
        console.log("getOpenOrders", error);
      }
    }

    getOpenCarts(user.uid).then((carts) => {
      setOpenCarts(carts)
    });
    //fetchOrders();
  
  }, [user, authLoading, navigate ]);

  return (
    <>
    <PageHeader

    title="Open Orders" titleTag="h5" className="mb-3">

    </PageHeader>
    
    
        {!ordersLoading ? (
          <Card className="">
          <AdvanceTableWrapper
            columns={columns}
            data={nCurrentOrders}
            sortable
            pagination
            setPageSize
            >
            <div className="m-3">
            <Row className="flex-end-center mb-3">
              <Col>
                  <AdvanceTableSearchBox table/>
              </Col>
            </Row>
              <AdvanceTableFooter
                rowCount={nCurrentOrders.length}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            
            </div>
            
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: false,
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
            <div className="m-3"><AdvanceTablePagination table /></div>
            
          </AdvanceTableWrapper>
          </Card>
        ) : 
          <Row className="align-items-center">

            <Col>
              <Spinner animation="border" variant="primary">
                <span className='visually-hidden'>Loading...</span>
              </Spinner>
            </Col>
          
          </Row>
          }
      
    
    </>
  );
};

export default Orders;
