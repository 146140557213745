import { Link } from 'react-router-dom'


const BatchName = (props) => {
    const { value, data } = props;
    const { docId } = data;
    if (!value) return null;
    if (!docId) return null;
    return (
        <div>
            <Link to={`/newBatches/${docId}`}>{value}</Link>
        </div>
    )
}

export default BatchName;