import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import QuantityController from '../QuantityController';
import { auth, db } from 'config/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { doc, runTransaction } from 'firebase/firestore';
import findSlugForSku from 'helpers/findSlugForSku';
import { toast } from 'react-toastify';

const CartItem = ({ product, cartId, org }) => {

  const { quantity, sku } = product;
  const [user, authLoading] = useAuthState(auth);
  const navigate = useNavigate();
  const [slug, setSlug] = useState();
  
  async function handleRemove(itemSku) {
    try {
      await runTransaction(db, async (transaction) => {
        const cartRef = doc(db, "openOrders", cartId);
        const cart = await transaction.get(cartRef);
        if (!cart.exists()) throw "Cart does not exist!"
        const items = cart.data().items;
        for (let i = 0; i < items.length; i++) {
          if (items[i].sku === itemSku) items.splice(i, 1);
        }
        transaction.update(cartRef, {items: items})
      })
      toast.warning(`${itemSku} removed from cart`, {
        theme: "colored"
      });

    } catch (error) {
      console.log(error);
    }
  };

  async function handleAddToCart(itemSku, newQty) {
    try {
      await runTransaction(db, async (transaction) => {
        const cartRef = doc(db, "openOrders", cartId);
        const cart = await transaction.get(cartRef);
        if (!cart.exists()) throw "Cart does not exist!"
        const items = cart.data().items;
        for (let i = 0; i < items.length; i++) {
          if(items[i].sku === itemSku) {
            items[i].quantity = newQty;
          }
        }
        transaction.update(cartRef, {items: items})
      })
    } catch (error) {
      console.log(error);
    }
     
  }

  const handleIncrease = () => {
    handleAddToCart(sku, parseInt(quantity + 1));
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      handleAddToCart(sku, parseInt(quantity - 1));
    }
  };

  const handleChange = e => {
    handleAddToCart(sku, parseInt(e.target.value < 1 ? 1 : e.target.value));
  };

  useEffect(() => {
    if (authLoading) return;
    if (!user) return navigate('/');
    findSlugForSku(org, sku).then((slug) => setSlug(slug));

}, [user, authLoading, cartId]);

  return (
    <Row className="gx-card mx-0 align-items-center border-bottom border-200">
      <Col xs={8} className="py-3">
        <div className="d-flex align-items-center">
          <Link to={`/${org}/products/${slug}`}>
            <img
              src={product.picture}
              width="60"
              alt={product.title}
              className="img-fluid rounded-1 me-3 d-none d-md-block"
            />
          </Link>
          <div className="flex-1">
            <h5 className="fs-0">
              <Link
                to={`/${org}/products/${slug}`}
                className="text-900"
              >
                {product.title}
              </Link>
            </h5>
            <div className="fs--2 fs-md--1">
              <Button
                variant="link"
                size="sm"
                className="text-danger fs--2 fs-md--1 fw-normal p-0"
                onClick={() => handleRemove(sku)}
              >
                Remove
              </Button>
            </div>
          </div>
        </div>
      </Col>
      <Col xs={4} className="py-3">
        <Row className="align-items-center">
          <Col
            md={{ span: 8, order: 0 }}
            xs={{ order: 1 }}
            className="d-flex justify-content-end justify-content-md-center"
          >
            <div>
              <QuantityController
                quantity={quantity}
                handleChange={handleChange}
                handleIncrease={handleIncrease}
                handleDecrease={handleDecrease}
                btnClassName="px-2"
              />
            </div>
          </Col>
          <Col
            md={{ span: 4, order: 1 }}
            xs={{ order: 0 }}
            className="text-end ps-0 mb-2 mb-md-0 text-600"
          >
            <span className='fs-0 fw-semi-bold'>${Number(product.cost).toFixed(2)*quantity} </span><br />
            <span className='fs--2 text-700'>${Number(product.cost).toFixed(2)} per</span>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CartItem;
