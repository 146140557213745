import { useEffect, useState, useContext } from 'react'
import { Offcanvas, Tabs, Tab, Button } from 'react-bootstrap'
import { db } from 'config/firebase';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import { addDoc, collection } from 'firebase/firestore';

// steps to listing readiness
const addBarcodeLookupTask = async (db, payload) => {
    // const docRef = db.collection("barcodeLookupTasks").doc();
    // try {
    //   await docRef.set(payload);
    //   return docRef
    // } catch (error) {
    //   console.error(error);
    //   return false;
    // }
    try {
        const docRef = await addDoc(collection(db, "barcodeLookupTasks"), payload);
        return docRef
    } catch (error) {
        console.error(error);
        return false;
    }
};

const addOpenAiTask = async (db, payload) => {
    try {
        const docRef = await addDoc(collection(db, "openAiApiTasks"), payload);
        return docRef
    } catch (error) {
        console.error(error);
        return false;
    }
  };

// 1. Barcode Enhancements
const createBarcodeEnhancementJob = async (db, barcode, parentSku, execInSeconds) => {

    if (!execInSeconds) execInSeconds = 10
    
    const payload = {
        status: "new", // for task dispatch
        handler: "barcodeLookupRequestHandler",
        type: "lookupBarcode", // for request handler
        jobData: {
          contentType: "application/json",
          httpMethod: "POST",
          inSeconds: execInSeconds,
          payload: {
            barcode: barcode,
            parentSku: parentSku,
          },
          queueName: "barcode-lookup-queue",
        },
    }

    const jobRef = await addBarcodeLookupTask(db, payload)
    return jobRef
}

// 2. Tag Enrichment
const createTagEnrichmentJob = async (db, ibmiData, parentSku, execInSeconds, uid) => {
    const payload = {
        status: "new", // for task dispatch
        handler: "openAiTaskRequestHandler",
        type: "generateTags", // for request handler
        jobData: {
          contentType: "application/json",
          httpMethod: "POST",
          inSeconds: execInSeconds,
          payload: {
            productString: ibmiData,
            sku: parentSku,
            uid: uid,
          },
          queueName: "openai-tasks-queue",
        },
      }
    const jobRef = await addOpenAiTask(db, payload)
    return jobRef
}

// 3. Product Titles
const createProductTitleJob = async (db, ibmiData, parentSku, execInSeconds, uid) => {
    const payload = {
        status: "new", // for task dispatch
        handler: "openAiTaskRequestHandler",
        type: "generateTitles", // for request handler
        jobData: {
          contentType: "application/json",
          httpMethod: "POST",
          inSeconds: execInSeconds,
          payload: {
            productString: ibmiData,
            sku: parentSku,
            uid: uid,
          },
          queueName: "openai-tasks-queue",
        },
      }
    const jobRef = await addOpenAiTask(db, payload)
    return jobRef    
}

// 4. Product Descriptions & Bullets
const createProductDescriptionJob = async (db, fullProductData, parentSku, execInSeconds, uid) => {
    const payload = {
        status: "new", // for task dispatch
        handler: "openAiTaskRequestHandler",
        type: "generateDescriptions", // for request handler
        jobData: {
          contentType: "application/json",
          httpMethod: "POST",
          inSeconds: execInSeconds,
          payload: {
            productString: fullProductData,
            sku: parentSku,
            uid: uid,
          },
          queueName: "openai-tasks-queue",
        },
      }
    const jobRef = await addOpenAiTask(db, payload)
    return jobRef    
}

const buildJobList  = (data) => {
    if (!data) return console.log('no data')
    const jobList = []
    for (const row of data) {
        const { barcodes, ibmi, barcodeLookup, sku, parentSku } = row
        const fullRowString = JSON.stringify(row)
        let ibmiString = JSON.stringify(ibmi)
        if (barcodeLookup) {
            ibmiString = '\n\n';
            ibmiString = JSON.stringify(barcodeLookup)
        }
        jobList.push({
            sku,
            parentSku,
            variantRef: row.ref,
            parentRef: row.parentRef,
            barcodeStep: {
                status: 'pending',
                data: barcodes,
                fullRowString,
            },
            tagAndTitleStep: {
                status: {
                    tags: 'pending',
                    titles: 'pending',
                },
                data: ibmiString,
                fullRowString,
            },
            descriptionStep: {
                status: 'pending',
                data: fullRowString
            }
        })
    }
    return jobList
}

const processGroupedJob = async (db, parentSku, jobs, uid, execInSecondsOffset) => {

    const groupedJobReferences = [];
    for (const job of jobs) {
        let execInSeconds = 25 + execInSecondsOffset; 

        // 1. Barcode Enhancements
        const barcodeJobPromises = []
        const barcodeStep = job.barcodeStep
        const barcodes = barcodeStep.data
        const barcodeArray = Object.keys(barcodes).map((key) => barcodes[key])
        for (const barcode of barcodeArray) {
            barcodeJobPromises.push(createBarcodeEnhancementJob(db, barcode, parentSku, execInSeconds))

        }
        const barcodeJobRefs = await Promise.all(barcodeJobPromises)

        // 2. Tag and Title Enrichment
        const tagAndTitleStep = job.tagAndTitleStep
        const ibmiData = tagAndTitleStep.data
        const tagJobRef = [];
        tagJobRef[0] = await createTagEnrichmentJob(db, ibmiData, parentSku, execInSeconds, uid)
        tagJobRef[1] = await createProductTitleJob(db, ibmiData, parentSku, execInSeconds, uid)

        // 3. Product Descriptions & Bullets
        const descriptionStep = job.descriptionStep
        const fullProductData = descriptionStep.data
        const descriptionJobPromises = []
        for (const job of jobs) {
            descriptionJobPromises.push(createProductDescriptionJob(db, fullProductData, job.sku, execInSeconds, uid))
        }
        const descriptionJobRefs = await Promise.all(descriptionJobPromises)

        const jobReferences = {
            barcodes: barcodeJobRefs,
            tagsAndTitles: tagJobRef,
            descriptions: descriptionJobRefs,
        }
        groupedJobReferences.push(jobReferences)
    }
    return groupedJobReferences
    
}


export const ListingReadinessOffcanvas = ({ show, onHide, data }) => {

    const { user, authLoading }= useContext(FirebaseAuthContext)
    const [uid, setUid] = useState(null)
    const [jobReferences, setJobReferences] = useState(null)
    const [start, setStart] = useState(false)

    const handleHide = () => {
        setUid(null)
        setJobReferences(null)
        setStart(false)
        onHide()
    }

    useEffect(() => {
        if (authLoading) return
        if (!user) return
        setUid(user.uid)
    }, [user, authLoading])

    useEffect(() => {
        if (!uid) return
        if (!data) return
        if (!start) return
        let isSubbed = true;
        const execListingReadiness = async () => {
            const jobList = buildJobList(data)
            const uniqueJobs = jobList.filter((job, index, self) => index === self.findIndex((t) => (t.sku === job.sku)))
            // group unique jobs by parent sku
            const groupedJobs = uniqueJobs.reduce((acc, curr) => {
                const parentSku = curr.parentSku
                if (!acc[parentSku]) {
                    acc[parentSku] = []
                }
                acc[parentSku].push(curr)
                return acc
            }, {})
            const worklistJobReferences = [];
            let executionOffset = 5;
            for (const parentSku of Object.keys(groupedJobs)) {
                const jobs = uniqueJobs.filter(job => job.parentSku === parentSku)
                worklistJobReferences.push(processGroupedJob(db, parentSku, jobs, user.uid, executionOffset))
                executionOffset += 5;
            }
            return worklistJobReferences
        }
        if (start) {
            execListingReadiness().then((jobReferences) => {
                const refs = Promise.all(jobReferences);
                if (isSubbed) setJobReferences(refs)
            })
        } 
        return () => isSubbed = false
    }, [uid, data, start])

    const style={
        height: '20vh',
        maxHeight: '100vh'
    }

    return (
        <Offcanvas show={show} onHide={handleHide} placement='bottom' style={style}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Listing Readiness</Offcanvas.Title>
                <Button onClick={() => setStart(true)}>Start</Button>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <ListingReadiness jobs={jobReferences}/>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

const ListingReadiness = ({jobs}) => {
    const [key, setKey] = useState('Enhancements');
    const [refs, setRefs] = useState(null)

    console.log('jobs', jobs)
    const titles = [
        'Updates',
        'Issues',
    ]

    return (
        <div>
            <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            >
                <Tab eventKey={titles[0]} title={titles[0]} className='border-bottom border-x p-3'>
                    <p>
                    
                    </p>
                </Tab>
                <Tab eventKey={titles[1]} title={titles[1]} className='border-bottom border-x p-3'>
                    <p>
                    
                    </p>
                </Tab>
                <Tab eventKey={titles[2]} title={titles[2]} className='border-bottom border-x p-3'>
                    <p>
                    
                    </p>
                </Tab>
                <Tab eventKey={titles[3]} title={titles[3]} className='border-bottom border-x p-3'>
                    <p>
                    
                    </p>
                </Tab>
            </Tabs>
        </div>
    )
}