import { db } from '../config/firebase'
import { collection, query, where, getDocs } from "firebase/firestore";

export default async function findSlugForSku(vendor, sku) {
    const productQuery = query(
        collection(db, `productCatalog/${vendor}/products`),
        where("sku", "==", sku),
        );
    const productSnapshot = await getDocs(productQuery);
    const product = productSnapshot.docs.map((doc) => {
        let temp = doc.data();
        temp.id = doc.id;
        return temp
    });
    if (product.length !== 1) {
        return new Error (`There are ${product.length} products with sku ${sku} in the ${vendor} catalog.`)
    } else {
        // TODO - in the future this can be a friendly URL slug instead of the product document ID
        return product[0].id;
    }

}