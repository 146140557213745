import { AuthWizardContext } from 'context/Context';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';
import { Button, Col, Row, Form } from 'react-bootstrap';
import celebration from './lottie/celebration.json';
import { auth, registerSupplierWithEmailAndPassword } from 'config/firebase'
import { addDoc, collection, updateDoc } from 'firebase/firestore';
import { db, fileStorage } from 'config/firebase';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { getSize } from 'helpers/utils';
const BlankW9 = 'https://firebasestorage.googleapis.com/v0/b/stk-merlin.appspot.com/o/w9%2FtopiczW9.pdf?alt=media&token=ed0552b5-ae18-43bb-96f3-f62226c8dbaf';
import CardDropdown from 'components/common/CardDropdown';

const Success = ({ reset, getValues, vendorDetails, vendorSnap }) => {

  const [formValues, setFormValues] = useState(null);
  useEffect(() => {
    let isSubbed = true;
    if (isSubbed) setFormValues(getValues());
    return () => isSubbed = false;
  }, [getValues]);

  const navigate = useNavigate();
  const { setStep, setUser } = useContext(AuthWizardContext);
  const [success, setSuccess] = useState(false);
  const [w9Submited, setW9Submitted] = useState(false);

  const emptyData = () => {
    setStep(1);
    setUser({});
    reset();
  };

  const submitNewSupplier = async () => {
    const content = getValues();
    const name = content.name;
    const email = content.email;
    const password = content.password;
    const orgRaw = String(vendorDetails.name).toLowerCase();
    const org = orgRaw.replace(/\s+/g, '-');
    await auth.signOut();
    registerSupplierWithEmailAndPassword(name, email, password, org)
    .then((user) => {
      createWelcomeAlerts(user.uid)
      .then(() => {
        updateDoc(vendorSnap.ref, {
          status: 'Active',
        }).then(() => {
          setSuccess(true);
          toast.success('Account created successfully!', {
            theme: 'colored'
          });
        }).catch((error) => {
          console.log(error);
        })
      })
    }) 
  }

  
  async function createWelcomeAlerts(uid) {

    const alert1 = {
      heading: `Welcome to Merlin E-Commerce! We're glad you're here`,
      body: 'Welcome to the Supplier Portal! Please review your account information and complete your profile.',
      actioned: false,
      color: 'success',
      actionText: 'View',
      actionUrl: '/dashboard',
      uid: uid,
      timestamp: new Date(),
    }
    const alert2 = {
      heading: `Upload your first products!`,
      body: 'You can upload products one by one or view our bulk upload guide to upload multiple products at once.',
      actioned: false,
      color: 'success',
      actionText: 'Bulk Upload',
      actionUrl: '/bulk-upload',
      uid: uid,
      timestamp: new Date(),
    }

    await addDoc(collection(db, "keyActionsPanel"), alert1);
    await addDoc(collection(db, "keyActionsPanel"), alert2);

  }

  function W9Upload() {

    const [file, setFile] = useState(null);
    const [url, setURL] = useState("");
    const [downloadClick, setDownloadClick] = useState(false);

    const handleChange = (e) => {
      if (e.target.files[0]) {
        setFile(e.target.files[0]);
      }
    };

    const handleUpload = () => {
      const storageRef = ref(fileStorage, `w9/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on('state_changed',
        (snapshot) => {
          // progress function ...
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          console.log(progress);
        },
        (error) => {
          // Error function ...
          console.log(error);
        },
        async () => {
          // complete function ...
          await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {

            setURL(downloadURL);
            updateDoc(vendorSnap.ref, {
              w9: downloadURL,
            }).then(() => {
              setW9Submitted(true);
              toast.success('W9 Uploaded Successfully!', {
                theme: 'colored'
              });
            }).catch((error) => {
              console.log(error);
            })
          })
        },
      );
    };

    return (
      <div>
        <Row>
          <Col>
            <h6>Complete & Upload  W9</h6>
          </Col>
        </Row>
        {downloadClick == false ?
        <Row>
          <Col>
            <a href={BlankW9} target="_blank" rel="noreferrer">
              <Button variant='falcon-primary' onClick={() => setDownloadClick(true)}>Download Blank W9</Button>
            </a>
          </Col>
        </Row>
        : <Row>
            <Col width={2}>
            <Form.Group controlId="formFile" className="mb-3" onChange={handleChange}>
              <Form.Label>Select Completed W9</Form.Label>
              <Form.Control type="file" />
            </Form.Group>
            <Button variant='falcon-primary' onClick={handleUpload}>Upload W9</Button>
            </Col>
          </Row>
        }
      </div>
    );
  }


  return (
    <>
      <Row>
        <Col className="text-center">
          <div className="wizard-lottie-wrapper">
            <div className="wizard-lottie mx-auto">
              <Lottie animationData={celebration} loop={true} />
            </div>
          </div>
          <h4 className="mb-1">Thank you!</h4>
          {success ?
            <>
              <Button variant="falcon-danger" className="px-5 my-3 me-3" onClick={emptyData}>
                Start Over
              </Button>
              <Button variant="falcon-primary" className="px-5 my-3" onClick={submitNewSupplier}>
                Submit Supplier Application
              </Button>
            </>
          :
            w9Submited ?
              <Button 
                variant="falcon-success"
                onClick={() => setSuccess(true)}
              >
                Success! Please Continue!
              </Button>
            :
              <W9Upload />
          }
          
        </Col>
      </Row>
    </>
  );
};

Success.propTypes = {
  reset: PropTypes.func.isRequired
};

export default Success;
