import { db } from '../config/firebase'
import { collection, query, where, getDocs } from "firebase/firestore";
import axios from 'axios';

export default async function registerNotification(payload) {
    // sample payload
    // {
    //   topic: 'order',
    //   name: 'New Order',
    //   description: 'A new order has been recieved',
    //   keyAction: {
    //     type: 'link',
    //     name: 'View Order',
    //     url: 'https://www.example.com/orders/12345'
    //   },

    const notificationEnpoint = 'https://us-central1-stk-merlin.cloudfunctions.net/registerMerlinNotification';

    if (!payload.topic) throw new Error('Topic is required')
    if (!payload.name) throw new Error('Name is required')
    if (!payload.description) throw new Error('Description is required')
    if (!payload.keyAction) throw new Error('Key Action is required')
    
    try {
        const data = {
            token: 'merlin',
            payload: payload
        }
        const config = {
            method: 'post',
            url: notificationEnpoint,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }
        const response = await axios(config);
        return response.data;
    } catch (error) {
        throw new Error(error.message)
    }
}