import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router';
import { Card, Col, Row, Form, Button, InputGroup, Image, Modal, Spinner, DropdownButton, Dropdown } from 'react-bootstrap';
import ProductDetailsMedia from './ProductDetailsMedia';
import ProductDetailsMain from './ProductDetailsMain';
import ProductDetailsFooter from './ProductDetailsFooter';
import Flex from 'components/common/Flex';
import { db } from 'config/firebase';
import { doc, query, updateDoc, collection, deleteDoc, setDoc, getDoc, getDocs } from 'firebase/firestore';
import { useCollection, useDocumentData } from 'react-firebase-hooks/firestore';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import IconButton from 'components/common/IconButton';
import SoftBadge from 'components/common/SoftBadge';
import MissingProductImage from 'assets/merlin/missing_image.png';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify';
import useRoleData from 'hooks/useRoleData.js';
import Lottie from 'lottie-react';
import dotsAnimation from 'assets/lottie/dots.json';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import HeadsUpDisplay from './HeadsUpDisplay';

const MerlinProductDetails = () => {
  let paramData  = useParams();
  paramData.vendor = String(paramData.vendor).toLowerCase();
  const { vendor, productId } = paramData;
  const navigate = useNavigate();
  const { user, authLoading }= useContext(FirebaseAuthContext)
  const [roleData, orgData, userLoading, userError] = useRoleData(user?.uid);

  useEffect(() => {
    if (authLoading) return;
    if (!user) navigate('/login');
  }, [user])
  
  useEffect(() => {
    if (authLoading || userLoading) {
      return;
    } else {
      if (!user) return navigate('/login');
      if (!orgData || orgData === 'None') {
        toast.error('Error in user data. Organization not found.', {
          theme: 'colored'
        });
        return navigate('/');
      }
  
      if (vendor !== orgData && !authLoading && !userLoading) {
        toast.error('Warning: You do not have access to that page.', {
          theme: 'colored'
        });
        return navigate('/');
      }
    }
  
  }, [authLoading, userLoading, user])
  
  const [product, productLoading, productError, productDocRef] = useDocumentData(doc(db, `productCatalog/${String(vendor).toLowerCase()}/products`, productId));
  const [variantRefs, variantsLoading, variantsError, snap] = useCollection(query(
    collection(db, `productCatalog/${String(vendor).toLowerCase()}/products/${productId}`, 'variants')
    ));
  const [variants, setVariants] = useState();
  const [showVariantImageModal, setShowVariantImageModal] = useState(false);
  const [variantRefForImage, setVariantRefForImage] = useState();
  const [editModalShow, setEditModalShow] = useState(false);
  const [editSKUModalShow, setEditSKUModalShow] = useState(false);
  const [editTitle, setEditTitle] = useState(false);
  const [editSku, setEditSku] = useState(false);
  const [editVRef, setEditVRef] = useState();
  const [newVariantShow, setNewVariantShow] = useState(false);
  const [showVariantDetailModal, setShowVariantDetailModal] = useState(false);
  const [variantDetailData, setVariantDetailData] = useState();

  /** Once the variant refs load  
   * process them into the variants array
  */
  useEffect(() => {
    if (variantsLoading) return;
    const temp = [];
    variantRefs.docs?.map((doc) => {
      let v = doc.data();
      console.log("v", v)
      v.vId = doc.id;
      v.vRef = doc.ref;
      temp.push(v)
    })
    
    function variantCompareForSort(a, b) {
      if (Number(a.productFactor) < Number(b.productFactor)) {
        return -1;
      }

      if (Number(a.productFactor) > Number(b.productFactor)) {
        return 1;
      }
      return 0;
    }
    console.log("temp", temp)
    temp.sort(variantCompareForSort);
    gatherVariantSellerActiveStatusForProduct(temp)
      .then((updatedVariantArray) => {
        setVariants(updatedVariantArray);
        // compare the updatedVariantArray to the snap
      })
      .catch((error) => console.log(error));

  }, [variantRefs, variantsLoading, snap])


  async function gatherVariantSellerActiveStatusForProduct(variantArray) {
    console.log("variantArray", variantArray)
    try {
      const updatedVariantArray = [];
      for (let i = 0; i < variantArray.length; i++) {
        const variant = variantArray[i];
        const variantSku = variant.sku;
        if (!variantSku) {
          variantSku = variant.parentSku;
        }
        const docRef = doc(db, "productsMeta/sellerActive/active", String(variantSku) );
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          updatedVariantArray.push({...variant, sellerActiveStatus: true})
        } else {
          updatedVariantArray.push({...variant, sellerActiveStatus: false})
        }
      }
      return updatedVariantArray;
    } catch (error) { 
      console.log(error)
    }
  }

  function sellerActiveStateController(variantSku, state, vRef) {
    try {
      switch (state) {
        case 'active':
          const docRef = doc(db, "productsMeta/sellerActive/active", String(variantSku) );
          setDoc(docRef, {active: true});
          updateDoc(vRef, {sellerActiveStatus: true});
          toast.success(`Variant ${variantSku} is now active. Merlin will update SellerActive with Count & Cost Data.`, {
            position: 'top-center',
            theme: 'colored'
          });
          break;
        case 'inactive':
          const docRef2 = doc(db, "productsMeta/sellerActive/active", String(variantSku) );
          deleteDoc(docRef2);
          updateDoc(vRef, {sellerActiveStatus: false});
          toast.warning(`Variant ${variantSku} is now inactive. Merlin will no longer update SellerActive with this variant.`, {
            position: 'top-center',
            theme: 'colored'
          });
          break;
        default:
          break;
      }
    } catch (error) {
      console.log(error)
    }
  }

  const RefreshButton = ({state, vRef, sku}) => {
    let displayJsx;
    switch (state) {
      case 'active':
        displayJsx = (
          <Button variant="outline-success" size="sm" className="ms-2" style={{border: 'none'}} onClick={() => sellerActiveStateController(sku, 'active', vRef)}>
            <FontAwesomeIcon icon={faArrowAltCircleRight} />
          </Button>
        )
        break;
      case 'inactive':
        displayJsx = (
          <Button variant="outline-danger" size="sm" className="ms-2" style={{border: 'none'}} onClick={() => sellerActiveStateController(sku, 'inactive', vRef)}>
            <FontAwesomeIcon icon={faArrowAltCircleRight} />
          </Button>
        )
        break;
      default:
        displayJsx = (
          
            <FontAwesomeIcon icon={faArrowAltCircleRight} />
          
        )
        break;
    }
    return displayJsx;
  }

  function handleVariantImageClick(vRef) {
    setVariantRefForImage(vRef)
    setShowVariantImageModal(true);
    return;
  }

  async function handleVariantTitleUpdate(e, title, vRef) {
    e.preventDefault();
    setEditTitle(title);
    setEditModalShow(true);
    setEditVRef(vRef);
  }

  async function handleVariantSkuUpdate(e, sku, vRef) {
    e.preventDefault();
    setEditSku(sku);
    setEditSKUModalShow(true);
    setEditVRef(vRef);
  }


  async function handleNameUpdate(e) {
    e.preventDefault();
    const el = document.getElementById("editedVariantNameControl");
    const newName = String(el.value);
    try {
      await updateDoc(editVRef, {
        title: newName
      });
      setEditModalShow(false);
      toast.success(`Variant Name Updated`, {
        position: 'top-center',
        theme: 'colored'
      });

    } catch (error) {
      console.log(error)
    }
    
  }

  async function handleSkuUpdate(e) {
    e.preventDefault();
    const el = document.getElementById("editedVariantSkuControl");
    const newSku = String(el.value);
    try {
      // Update the active record in product meta as well
      await updateDoc(editVRef, {
        sku: newSku
      });
      setEditSKUModalShow(false);
      toast.success(`Variant SKU Updated`, {
        position: 'top-center',
        theme: 'colored'
      });

    } catch (error) {
      console.log(error)
    }
    
  }
  
  /** Variant Table Column Definitions */
  const columns = [
    {
      accessor: 'primaryImage',
      Header: 'Primary Image',
      Cell: rowData => {
        const { primaryImage, vRef } = rowData.row.original;
        if (!primaryImage) {
          return (
            <Image src={MissingProductImage} alt="Missing Product Image" className="img-fluid" width={75} onClick={() => handleVariantImageClick(vRef)} />
          )
        } else {
          return (
            <Image src={primaryImage} alt="Product" className="img-fluid"  width={75} onClick={() => handleVariantImageClick(vRef)} />
          )
        }
        
      }
    },
    {
      accessor: 'sellerActiveStatus',
      Header: 'Seller Active',
      Cell: rowData => {
        const { sellerActiveStatus, vRef, sku } = rowData.row.original;
        console.log("sellerActiveStatus", sellerActiveStatus) 
        return (
          <>
            {sellerActiveStatus ? 
              <>
                <SoftBadge bg="success">Active</SoftBadge>
                <RefreshButton state={'inactive'} vRef={vRef} sku={sku}/>
              </> 
            : <>
                <SoftBadge bg="danger">Inactive</SoftBadge>
                <RefreshButton state={'active'} vRef={vRef} sku={sku}/>
              </>
            }
          </>
        )
      }
    },
    {
      accessor: 'title',
      Header: 'Name',
      Cell: rowData => {
        const { title, defaultVariant, costOveride, vRef } = rowData.row.original
        return (
          <>
            {title} <FontAwesomeIcon className='ms-3 ' size='xs'  icon={faPen} onClick={(e) => handleVariantTitleUpdate(e, title, vRef)}/>
            {defaultVariant ? <SoftBadge className='ms-2'>Default</SoftBadge> : null }
            {costOveride ? <SoftBadge bg="success" className='ms-2'>Cost Override</SoftBadge> : null }
          </>
        )
      }
    },
    {
      accessor: 'sku',
      Header: 'SKU',
      cellProps:{
        className:'fw-medium'
      },
      Cell: rowData => {
        const { sku, vRef } = rowData.row.original;
        // <FontAwesomeIcon className='ms-3 ' size='xs'  icon={faPen} onClick={(e) => handleVariantSkuUpdate(e, sku, vRef)}/>
        return (
          <>
            {sku} 
          </>
        )
      }
    },
    {
      accessor: 'productFactor',
      Header: 'Factor',
      cellProps:{
        className:'fw-medium'
      }
    },
    {
      accessor: '',
      Header: 'Cost',
      cellProps:{
        className:'fw-medium'
      },
      Cell: rowData => {
        const { productFactor, costOveride, newCost, vRef, vId } = rowData.row.original;
        const discounts = product?.discounts || [];
        const discountFlag = discounts.length > 0 ? true : false;
        let endDateString = discounts[0]?.endDate.toString();
        const cost = productFactor * product.cost;
        let editCost = false;
        const now = new Date();

        let baseProductCost = product.cost;
        let tempCost = baseProductCost;
        if (discounts.length > 0) {
          discounts.forEach(discount => {
            
            // check to see if now is between the start and end date of the discount
            if (now >= discount.startDate.toDate() && now <= discount.endDate.toDate()) {
              if (!costOveride) {
                tempCost = Number( (baseProductCost - discount.value) * productFactor).toFixed(2);
              } else {
                tempCost = Number(newCost).toFixed(2);
              }
            } else {
              // no discounts
              tempCost = Number(baseProductCost * productFactor).toFixed(2);
            }
          })
        } else {
          // no discounts
          tempCost = Number(baseProductCost * productFactor).toFixed(2);
        }


        let placeholder;
        if (newCost) {
          placeholder = newCost;
        } else {
          placeholder = tempCost;
        }

        return (
          <>
            {!costOveride ?
              <>
                <p>${Number(placeholder).toFixed(2)}</p>
                {discountFlag ? 
                  <span className='text-muted'>${Number(cost).toFixed(2)} (-${Number(discounts[0].value * productFactor).toFixed(2)})</span> : null}
              </> 
              :
              <InputGroup>
                    <Form.Control
                      placeholder={placeholder}
                      aria-label="Cost Overide"
                      aria-describedby="button-addon2"
                      id={vId}
                      className='w-50'
                    />
                    <Button variant="outline-primary" id="button-addon2" onClick={() => handleNewCostSave(vId, vRef)}>
                      Save
                    </Button>
              </InputGroup>
                  
                
              
            }
          </>
        )
      }
    },
    {
      accessor: '',
      Header: 'Details',
      Cell: rowData => {
        return (
          <>
            <IconButton
              icon="chevron-right"
              variant="falcon-primary"
              size="sm"
              onClick={() => displayVariantModal(rowData.row.original)}
            />
          </>
          
        )
      }
    }
  ];

  function displayVariantModal(variantData) {
    setVariantDetailData(variantData);
    setShowVariantDetailModal(true);
  }

  const VariantDetailsModal = ({showModal, setShowModal}) => {
      // const [showModal, setShowModal] = useState(show);
      const [locations, setLocations] = useState();
      const [preferredLocationId, setPreferredLocationId] = useState();
      const [preferredName, setPreferredName] = useState();
      
      useEffect(() => {
        async function gatherLocations() {
          const locations = [];
          const q = query(
            collection(db, `locations`),
          );
          const snapshot = await getDocs(q);
          snapshot.forEach((doc) => {
            locations.push(doc.data());
          });
          return locations;
        }
        let isSubbed = true;
        gatherLocations().then((locations) => {
          if (isSubbed) {
            let tempPrefId = variantDetailData?.preferredFullfillmentLocation || '';
            let preferredLocation = locations.filter((location) => location.id === tempPrefId);
            if (preferredLocation) { 
              setPreferredLocationId(preferredLocation[0]?.id);
              const prefData = locations.filter((location) => location.id === preferredLocation[0]?.id);
              setPreferredName(prefData[0]?.name);
            }
            setLocations(locations);
          }
        })
        return () => isSubbed = false;
      }, [])

      const LocationDropdownMenu = ({locations}) => {
        return (
          <>
            {locations.map((location, idx) => {
              return (
                <option
                  value={location.id}
                  key={`opt-${idx}`}
                >{location.name}</option>
              )
            })}
          </>
        )
      }

      const manageSelection = async (e) => {
        const selectedValue = e.target.value;
        const filteredLocation = locations.filter((location) => location.id === selectedValue);
        const variantRef = variantDetailData.vRef;
        setShowModal(false);
        await updateDoc(variantRef, { preferredFullfillmentLocation: filteredLocation[0]?.id })
        toast.success(`Preferred Fulfillment Location Updated`, { theme: 'colored' });
      }

      return (
        <Modal
          show={showModal}
          onHide={() => setShowModal(!showModal)}
          aria-labelledby="example-modal-sizes-title-sm"
          centered
        >
          <Modal.Header>
            <Modal.Title id="example-modal-sizes-title-sm">
              Variant Details
            </Modal.Title>
            <FalconCloseButton onClick={() => setShowModal(!showModal)}/>
          </Modal.Header>
          <Modal.Body>
            {variantDetailData ?
              <>
                <Row xs='auto' sm='auto' md='auto' lg='auto' xl='auto' xxl='auto' className='mb-3'>
                  <Col className='me-2 w-25'>
                    <Form.Label className='fw-bold'>Title</Form.Label>
                  </Col>
                  <Col
                    className="text-start"
                    style={{ color: 'var(--bs-gray-500)' }}
                  >
                    {variantDetailData.title}
                  </Col>
                </Row>
                <Row xs='auto' sm='auto' md='auto' lg='auto' xl='auto' xxl='auto' className='mb-3'>
                  <Col className='me-2 w-25'>
                    <Form.Label className='fw-bold'>SKU</Form.Label>
                  </Col>
                  <Col
                    className="text-start"
                    style={{ color: 'var(--bs-gray-500)' }}
                  >
                    {variantDetailData.sku}
                  </Col>
                </Row>
                <Row xs='auto' sm='auto' md='auto' lg='auto' xl='auto' xxl='auto' className='mb-3'>
                  <Col className='me-2 w-25'>
                    <Form.Label className='fw-bold'>Preferred Fulfillment Location</Form.Label>
                  </Col>
                  <Col
                    className="text-start"
                    style={{ color: 'var(--bs-gray-500)' }}
                  >
                    {locations && locations?.length > 0 ?
                      <Form
                        onChange={(e) => manageSelection(e)}
                      >
                        <Form.Select aria-label="Default select example">
                          {preferredLocationId ?
                            <option key="default-select" value={preferredLocationId}>Selected: {preferredName}</option>
                          : <option key="default-select">Select Location</option>
                          }
                          
                          <LocationDropdownMenu locations={locations}/>
                        </Form.Select>
                      </Form>
                    : null}
                  </Col>
                </Row>
                <Row xs='auto' sm='auto' md='auto' lg='auto' xl='auto' xxl='auto' className='mb-3'>
                  <Col className='me-2 w-25'>
                    <Form.Label className='fw-bold'>SellerActive Data</Form.Label>
                  </Col>
                  <Col
                    className="text-start"
                    style={{ color: 'var(--bs-gray-500)' }}
                  >
                    {variantDetailData.sellerActiveStatus ?   
                      <SoftBadge bg="success">Active</SoftBadge>
                    : <SoftBadge bg="danger">Inactive</SoftBadge>
                    }
                    {variantDetailData.withRequestData ?
                      <>
                        <SoftBadge bg="success">With Request Data</SoftBadge>
                        <p>
                          Updated on {new Date(variantDetailData.sellerTimestamp.toDate()).toLocaleString()}
                        </p>
                        {variantDetailData.withRequestData.cost &&
                        <p>
                          With cost: ${variantDetailData.withRequestData.cost}
                        </p>}
                        {variantDetailData.withRequestData.onhand &&
                        <p>
                          With inventory: {variantDetailData.withRequestData.onhand} on hand
                        </p>}
                      </>
                      
                    :
                      <SoftBadge bg="danger">No Request Data</SoftBadge>
                    }
                  </Col>
                </Row>
              </>
            : null
            }
          </Modal.Body>
        </Modal>
      )
  };

  async function handleNewCostSave(variantId, variantRef){

      const el = document.getElementById(variantId);
      const newCost = el.value;
      // make sure newCost is a number
      const newCostNumber = newCost.replace(/[^0-9.-]+/g,"");


      try {
        const res = await updateDoc(variantRef, { newCost: `${Number(newCostNumber).toFixed(2)}` })
      } catch (error) {
        console.log(error)
      }
    
  }

  async function handleCostOveride(selectedRowIds, rows) {

    const selectedRows = rows.filter((row) => selectedRowIds[row.rowId]);
    for (let i = 0; i < selectedRows.length; i++) {
      const { vRef, costOveride } = selectedRows[i];
      try {
        await updateDoc(vRef, { costOveride: !costOveride})
      } catch (error) {
        console.log(error)
      }
    }

  }
  
  async function handleNewVariant(e) {
    e.preventDefault();
    setNewVariantShow(!newVariantShow);
  }

  function NewVariantModal({show, setShow, parent}) {

    //const [show, setShow] = useState(false);
    const [variantName, setVariantName] = useState();
    const [variantSku, setVariantSku] = useState();
    const [variantFactor, setVariantFactor] = useState();
    const [newVariantCost, setNewVariantCost] = useState();
    const [newVariantSRP, setNewVariantSRP] = useState();
    const [submissionExecuting, setSubmissionExecuting] = useState(false);
    
    async function handleNewVariantSubmission(name, sku, factor) {
      //const { name, sku, factor } = data;
      let newVariant = {};
      try {
        setSubmissionExecuting(true);
        const variantRef = doc(collection(db, `productCatalog/${String(vendor).toLowerCase()}/products/${productId}`, 'variants'));
  
        newVariant.id = product.sku;
        newVariant.parentRef = productDocRef.ref;
        newVariant.parentSku = product.sku;
        newVariant.productFactor = factor;
        newVariant.ref = variantRef;
        newVariant.sku = sku;
        newVariant.title = name;
  
        await setDoc(variantRef, newVariant);
        const activeRecordRef = doc(db, `productsMeta/sellerActive/active`, String(newVariant.id));
        const active = {
          refPath: productDocRef.ref,
          ref: variantRef,
        }
        await setDoc(activeRecordRef, active);
        setNewVariantShow(!newVariantShow);
        setSubmissionExecuting(false);
        return;
      } catch (error) {
        console.log(error)
      }
    }

    function handleClose(){
      setShow(false);
      setVariantName();
      setVariantSku();
      setVariantFactor();
      setNewVariantCost();
      setNewVariantSRP();
    }

    function handleFactorChange(e) {

      e.preventDefault();
      
      if (orgData === 'Topicz') {
        const newCost = (parent.ibmi.cost * e.target.value).toFixed(2);
        const newSrp = (((parent.ibmi.srp * e.target.value) * 0.4) + (parent.ibmi.srp * e.target.value)).toFixed(2);
        setVariantFactor(e.target.value);
        setNewVariantCost(Number(newCost).toFixed(2));
        setNewVariantSRP(Number(newSrp).toFixed(2));
      } else {
        setVariantFactor(e.target.value);
      }
      
    }

    return (
      <Modal
        
        show={show}
        onHide={() => handleClose()}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-sm">
            Create New Product Variant
          </Modal.Title>
          <FalconCloseButton onClick={() => handleClose()}/>
        </Modal.Header>
        <Modal.Body>
          {orgData === 'Topicz' ?
            <Form.Group className="mb-3" controlId="parentData">
              <Row xs='auto' sm='auto' md='auto' lg='auto' xl='auto' xxl='auto'>
                  <Col className='me-2 w-25'>
                    <Form.Label className='fw-bold'>Cost</Form.Label>
                  </Col>
                  <Col
                    className="text-end"
                    style={{ color: 'var(--bs-gray-500)' }}
                  >
                    <Form.Label className='fw-bold'>${parent.ibmi.cost}</Form.Label>
                  </Col>
              </Row>
              <Row xs='auto' sm='auto' md='auto' lg='auto' xl='auto' xxl='auto'>
                  <Col className='me-2 w-25'>
                    <Form.Label className='fw-bold'>Size</Form.Label>
                  </Col>
                  <Col
                    className="text-end"
                    style={{ color: 'var(--bs-gray-500)' }}
                  >
                    <Form.Label className='fw-bold'>{parent.ibmi?.size}</Form.Label>
                  </Col>
              </Row>
              <Row xs='auto' sm='auto' md='auto' lg='auto' xl='auto' xxl='auto'>
                  <Col className='me-2 w-25'>
                    <Form.Label className='fw-bold'>Pack</Form.Label>
                  </Col>
                  <Col
                    className="text-end"
                    style={{ color: 'var(--bs-gray-500)' }}
                  >
                    <Form.Label className='fw-bold'>{parent.ibmi?.pack}</Form.Label>
                  </Col>
              </Row>
              <Row xs='auto' sm='auto' md='auto' lg='auto' xl='auto' xxl='auto'>
                  <Col className='me-2 w-25'>
                    <Form.Label className='fw-bold'>Slot</Form.Label>
                  </Col>
                  <Col
                    className="text-end"
                    style={{ color: 'var(--bs-gray-500)' }}
                  >
                    <Form.Label className='fw-bold'>{parent.ibmi?.slot}</Form.Label>
                  </Col>
              </Row>
              <Row xs='auto' sm='auto' md='auto' lg='auto' xl='auto' xxl='auto'>
                  <Col className='me-2 w-25'>
                    <Form.Label className='fw-bold'>SRP</Form.Label>
                  </Col>
                  <Col
                    className="text-end"
                    style={{ color: 'var(--bs-gray-500)' }}
                  >
                    <Form.Label className='fw-bold'>${parent.ibmi?.srp}</Form.Label>
                  </Col>
              </Row>
              <Row xs='auto' sm='auto' md='auto' lg='auto' xl='auto' xxl='auto'>
                  <Col className='me-2 w-25'>
                    <Form.Label className='fw-bold'>SKU</Form.Label>
                  </Col>
                  <Col
                    className="text-end"
                    style={{ color: 'var(--bs-gray-500)' }}
                  >
                    <Form.Label className='fw-bold'>{parent.ibmi?.sku}</Form.Label>
                  </Col>
              </Row>
            </Form.Group>
          :
          null
          }


          <Form.Group className="mb-3" controlId="variantName">
            <Form.Label>Variant Name</Form.Label>
            <Form.Control type="text" placeholder="Enter Variant Name" value={variantName} onChange={(e) => setVariantName(e.target.value)} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="variantFactor">
            <Form.Label>Variant Factor</Form.Label>
            <Form.Control type="text" placeholder="Enter Variant Factor" value={variantFactor} onChange={(e) => handleFactorChange(e)}/>
            { newVariantCost ? <Form.Label className='mt-3'>New Cost/SRP: ${newVariantCost} / ${newVariantSRP} </Form.Label> : null }
          </Form.Group>

          <Form.Label>Variant SKU</Form.Label>
          <InputGroup className="mb-3">
            <Button variant="falcon-primary" id="autoSkuBtn" onClick={() => setVariantSku(`${parent.ibmi.sku}-${variantFactor}`)}>
              Auto
            </Button>
            <Form.Control
              type="text" placeholder="Enter Variant SKU" value={variantSku} 
              aria-label="Automatically Assign SKU using Core SKU and Product Factor"
              aria-describedby="autoSkuBtn"
              onChange={(e) => setVariantSku(e.target.value)} 
            />
          </InputGroup>
          <div className="float-end">
            <Button variant="falcon-danger" onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button variant="primary" disabled={submissionExecuting}
              onClick={() => handleNewVariantSubmission(
                variantName,
                variantSku,
                variantFactor
              )} className='ms-2'>
              Create Variant
            </Button>
          </div>
           
        </Modal.Body>
      </Modal>
    )
  }

  function DefaultFulfillmentDropdown({row}) {
    const [defaultFulfillment, setDefaultFulfillment] = useState(product.defaultFulfillmentLocation || "Main Warehouse");

    async function updateFulfillmentLocation(location) {
      try {
        await updateDoc(productDocRef.ref, {
          defaultFulfillmentLocation: location
        }) 
        toast.success(`Default fulfillment location updated to ${location}`, { theme: 'colored' })
      } catch (error) {
        toast.error("Error updating default fulfillment location", { theme: 'colored' })
        console.log(productDocRef)
        console.log(error)
      }
    }
        

    return (
      <DropdownButton
        variant="outline-secondary"
        title={`Default Fulfillment Location: ${defaultFulfillment}`}
        size="sm"
        className="text-nowrap"

      >
        <Dropdown.Item
          onClick={() => { 
            updateFulfillmentLocation("Main Warehouse");
          }}
        >
          Main Warehouse
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => { 
            updateFulfillmentLocation("Inventory Room");
          }}
        >
          Inventory Room
        </Dropdown.Item>
      </DropdownButton>
    )
  }
  
  function VariantAction(/**{ selectedRowIds }*/all){
    
    const { selectedRowIds, page } = all;
    const rows = page.map((row) => {
      let temp = row.original;
      temp.rowId = row.id;
      return temp
    });
    const [applyExecution, setApplyExecution] = useState(false);

    async function deleteVariant(selectedRowIds, rows) {

      const results = [];
      const errors = [];
      for(let i = 0; i < rows.length; i++) {
        if(selectedRowIds[rows[i].rowId]) {
          let temp = rows[i];
          let variantDocumentRef = temp.vRef;
          if (!variantDocumentRef) {
            console.log('No variant document reference found');
            continue;
          } else {
            try {
              await deleteDoc(variantDocumentRef);
              results.push(`Variant ${temp.title} Deleted`)
            } catch (error) {
              console.log(error)
              errors.push(`Error Deleting Variant ${temp.title}`)
            }
          }
        }
      }
      if (errors.length > 0) {
        toast.error(errors.join('\n'), {
          theme: 'colored'
        });
      }
      if (results.length > 0) {
        toast.success(results.join('\n'), {
          theme: 'colored'
        });
      }
      return Promise.resolve("Variants Deleted")
    }
  
    async function handleApply() {
      setApplyExecution(true);
      const el = document.getElementById('bulkSelectOptions');
      switch (el.value) {
        case 'fulfillmentLocation':
          handleFulfillmentLocationChange(selectedRowIds, rows);
          break;
        case 'costOverride':
          handleCostOveride(selectedRowIds, rows);
          break;
        case 'sellerActive':
          break;
        case 'editSRP':
          break;
        case 'delete':
          await deleteVariant(selectedRowIds, rows);
          break;
        default:
          break;
      }
      setApplyExecution(false);
    }
    
    return (
      <>
      <Row className="flex-between-center mt-3 mb-3">
        <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
          <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
            {
              Object.keys(selectedRowIds).length > 0 ?
              'You have selected ' + Object.keys(selectedRowIds).length + ' rows' 
              :
              'Product Variations'
            }
          </h5>
        </Col>
        <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
          {Object.keys(selectedRowIds).length > 0 ? (
            <div className="d-flex">
              <Form.Select size="sm" aria-label="Bulk actions" id="bulkSelectOptions">
                <option>Bulk Actions</option>
                <option value="fulfillmentLocation">Assign Fulfillment Location</option>
                <option value="costOverride">Enable/Disable Cost Override</option>
                <option value="sellerActive">Enable/Disable SellerActive Listing</option>
                <option value="editSRP">Edit Suggested Retail Price</option>
                <option value="delete">Delete Variant(s)</option>
              </Form.Select>
              <Button
                type="button"
                variant="falcon-default"
                size="sm"
                className="ms-2"
                onClick={() => handleApply()}
              > 
                {!applyExecution ?
                    <span className="d-none d-sm-inline-block ms-1">Apply</span>
                  :
                    <Spinner animation="border" variant="primary" size="sm" />
                  }
              </Button>
            </div>
            ) : (
              <div id="orders-actions">
                  <div style={{display: 'inline-block'}}>
                      <IconButton
                      variant="falcon-default"
                      size="sm"
                      icon="plus"
                      transform="shrink-3"
                      className='me-2'
                      onClick={(e) => handleNewVariant(e)}
                    >
                      <span className="d-none d-sm-inline-block ms-1">New</span>
                      
                    </IconButton>
                  </div>
              </div>
            )}
        </Col>
      </Row>
        <Modal
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Update Variant Title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => handleNameUpdate(e)}>
            <Form.Label>
              Variant Name
            </Form.Label>
            <Form.Control type="text" defaultValue={editTitle} id="editedVariantNameControl" />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="falcon-secondary" onClick={() => setEditModalShow(false)}>Close</Button>
          <Button onClick={(e) => handleNameUpdate(e)}>Save</Button>
        </Modal.Footer>
        </Modal>
        <Modal
        show={editSKUModalShow}
        onHide={() => setEditSKUModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Update Variant SKU</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => handleSkuUpdate(e)}>
            <Form.Label>
              Variant Sku
            </Form.Label>
            <Form.Control type="text" defaultValue={editSku} id="editedVariantSkuControl" />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="falcon-secondary" onClick={() => setEditSKUModalShow(false)}>Close</Button>
          <Button onClick={(e) => handleSkuUpdate(e)}>Save</Button>
        </Modal.Footer>
        </Modal>
      </>
      
    );
  };
  
  function VariantTable() {
    return (
      <>
        {!variantsLoading && variants && variants.length >= 0 ?
              <AdvanceTableWrapper
                columns={columns}
                data={variants}
                sortable
                pagination
                perPage={100}
                selection
                selectionColumnWidth={30}
              >
                <VariantAction table/>
                <VariantDetailsModal 
                  showModal={showVariantDetailModal}
                  setShowModal={setShowVariantDetailModal} 
                />
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
              </AdvanceTableWrapper>
              : null
              }
      </>
      
      
    )
  }

  async function handleVariantImageSelection(vRef, image) {
    try {
      await updateDoc(vRef, { primaryImage: image.src })
      setShowVariantImageModal(false)
    } catch (error) {
      console.log(error)
    }
  }

  function PrimaryImageModal({ images }) {
    return (
      <Modal
        
        show={showVariantImageModal}
        onHide={() => setShowVariantImageModal(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-sm">
            Select Primary Variant Image
          </Modal.Title>
          <FalconCloseButton onClick={() => setShowVariantImageModal(false)}/>
        </Modal.Header>
        <Modal.Body>

          {images?.map((img, i) => {
            return (
              <div key={i} className="mb-3">
                <Image src={img.src} className="img-fluid" width={150} onClick={() => handleVariantImageSelection(variantRefForImage, img)}/>
              </div>
            )
          })}
           
        </Modal.Body>
      </Modal>
    )
  }

  /**
   * 
   */
  return product && !productLoading && !authLoading ? (
    <>
      <PrimaryImageModal images={product.files}/>
      {!productLoading ? <NewVariantModal show={newVariantShow} setShow={setNewVariantShow} parent={product}/> : null}
      <Card className="mb-3">
        <Card.Body>
          <Row>
            <Col lg={6} as={Flex} justifyContent="between" direction="column">
              <ProductDetailsMain
                product={product}
                fbRef={productDocRef}
                uid={user.uid}
              />
            </Col>
            <Col lg={6} className="mb-4 mb-lg-0">
              <ProductDetailsMedia product={product} productDocRef={productDocRef}/>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <HeadsUpDisplay user={user} productDocRef={productDocRef} />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className='mt-2'>
              <VariantTable />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <ProductDetailsFooter
                  product={product}
                  parentSku={product?.sku}
                  fbRef={productDocRef}
                  uid={user.uid}
                  variants={variants}
                />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      
    </>
  ) : <div>
        <Row className="align-items-center">
          <Col>
              <Lottie animationData={dotsAnimation} style={{height: 300}} />
          </Col>
        </Row>
      </div>;
};


/**
 * <Navigate to={`/`} />
 *  
 */
export default MerlinProductDetails;
