import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Nav, Row, Col, Table, Form, Button, Accordion, Card, Alert } from 'react-bootstrap';
import { db } from "config/firebase.js";
import { query, collection, where, limit, getDocs, updateDoc, orderBy } from "firebase/firestore";
import SoftBadge from 'components/common/SoftBadge';

function ChannelIntel({product, variants, repricerData}) {
    return (
        repricerData?.length > 0 && <DisplayChannelPills product={product} variants={variants} repricerData={repricerData}/>
    )
}
export default ChannelIntel;

const DisplayChannelPills = ({ product, variants, repricerData}) => {


    return (
        <Tabs 
            variant='pills'
            defaultActiveKey="amazon"
            id="channelIntelTab"
            className='mb-3'
            mountOnEnter={true}
            >
        <Tab eventKey="amazon" title="Amazon" className=''>
            <AmazonIntel product={product} variants={variants} repricerData={repricerData[0]}/>
        </Tab>
        <Tab eventKey="walmart" title="Walmart" className='' disabled>
            <p>
                Coming Soon
            </p>
        </Tab>
        <Tab eventKey="Shopify" title="Shopify" className='' disabled>
            <p>
                Coming Soon
            </p>
        </Tab>
        </Tabs>
    )
}

const AmazonAsinDataCard = ({ asin, details, fees, pricing, top=false, colorVariant, score, akey}) => {

    return (
        <Card className='mb-3' key={akey}>
            <Card.Header className={`bg-${colorVariant}`}>
                <Row>
                    <Col>
                        <h5 className='mb-3 text-light'>{details?.title}</h5>
                    </Col>
                    {top 
                        ?
                        <Col xs="auto" className='text-end'>
                            <SoftBadge
                                bg='success'
                            >
                                Top ASIN {!isNaN(score) && <div>- {Number(score*100).toFixed(0)}%</div> }
                            </SoftBadge>
                        </Col>
                        :
                        score &&
                        <Col xs="auto" className='text-end'>
                            <SoftBadge
                                bg='secondary'
                            >
                                Score {Number(score*100).toFixed(0)}%
                            </SoftBadge>
                        </Col>
                        
                    }
                </Row>
                <Row>
                    <Col>
                        <SoftBadge
                            bg={`${colorVariant}`}
                        >
                            {asin}
                        </SoftBadge>
                    </Col>
                    <Col xs="auto" className='text-end'>
                        {pricing?.lowestPrice &&
                            <SoftBadge
                                bg='danger'
                                >
                                Lowest Price: ${pricing?.lowestPrice}
                            </SoftBadge>
                        }
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body className={`border border-${colorVariant}`}>
                <Row>
                    <Col>
                        <div>
                        <h6>Details</h6>
                        <Table size='sm'>
                            <tbody>
                                <tr>
                                    <td>Best Rank</td>
                                    <td>
                                        <a href={details?.bestRankUrl} target='_blank' rel="noreferrer">
                                            <SoftBadge bg="success">
                                                {details?.bestRank} - {details?.bestRankCategory}
                                            </SoftBadge>
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        {details?.allRanks?.length && 
                                            details?.allRanks?.map((rank, index) => {
                                                return (
                                                    <div className='m-2' key={index}>
                                                        <SoftBadge bg="secondary" >
                                                            {rank?.rank} - {rank?.category}
                                                        </SoftBadge>
                                                    </div>
                                                    
                                                )
                                            })
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Brand</td>
                                    <td>{details?.brand}</td>
                                </tr>
                                <tr>
                                    <td>Amazon Type</td>
                                    <td>
                                        <SoftBadge bg="secondary">
                                            {String(details?.amazonType).replace(/_/g, ' ')}
                                        </SoftBadge>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Size</td>
                                    <td>{details?.size}</td>
                                </tr>
                            </tbody>
                        </Table>
                        </div>
                        <div>
                            <h6>Fees</h6>
                            <Table striped bordered hover size='sm'>
                                <tbody>
                                    <tr>
                                        <td>Price Used In Estimate</td>
                                        <td>{fees?.priceUsedInEstimate}</td>

                                    </tr>
                                    <tr>
                                        <td>FBA</td>
                                        <td>{fees?.fba?.totalFeesPercent && Number(fees?.fba?.totalFeesPercent * 100)}%</td>
                                        <td>${fees?.fba?.totalFees && Number(fees?.fba?.totalFees).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td>Merchant</td>
                                        <td>{fees?.merchant?.totalFeesPercent}</td>
                                        <td>${fees?.merchant?.totalFees && Number(fees?.merchant?.totalFees).toFixed(2)}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                    <Col>
                        <h6>Pricing</h6>
                        <Table striped bordered hover size='sm'>
                            <tbody>
                                {pricing?.buyBoxOffers?.length > 0 &&
                                    <tr>
                                        <td>Buy Box Landed Price</td>
                                        <td>{pricing?.buyBoxOffers[0].landedPrice}</td>
                                    </tr>
                                }
                                {pricing?.offersDetail?.length > 0 &&

                                    
                                <tr>
                                    <td>Offers Detail</td>
                                    <td>{pricing?.offersDetail.map((offer, index) => {
                                        return (
                                            <div key={index}>
                                                <SoftBadge 
                                                    bg={offer.isBuyBoxWinner ? 'success' : 'secondary'}>
                                                    ${offer?.listingPrice} - {offer?.sellerId}
                                                    {offer?.isFeaturedMerchant && <span> - Featured Merchant</span>}
                                                    {offer?.sellerFeedbackRating && <span> - {offer.sellerFeedbackRating.SellerPositiveFeedbackRating}% ({offer.sellerFeedbackRating.FeedbackCount})</span>}
                                                    {offer?.isFulfilledByAmazon && <span> - FBA</span>}
                                                    
                                                </SoftBadge>
                                            </div>
                                        )
                                    })}
                                    </td>
                                </tr>
                                }
                                {pricing?.lowOffers?.length &&
                                <tr>
                                    <td>Low Offers</td>
                                    <td>
                                        {pricing?.lowOffers.map((offer, index) => {
                                            return (
                                                <div key={index}>
                                                    <SoftBadge
                                                        bg={"primary"}
                                                    >
                                                        ${offer?.landedPrice} - {offer?.seller}
                                                    </SoftBadge>
                                                </div>
                                            )})}
                                    </td>
                                </tr>
                                }
                                {pricing?.bbOffers?.length &&
                                <tr>
                                    <td>Buy Box Offer Count</td>
                                    <td>
                                        {pricing?.bbOffers.map((offer, index) => {
                                            return (
                                                <div key={index}>
                                                    <SoftBadge
                                                        bg={"primary"}
                                                    >
                                                        {offer?.numOffers} - {offer?.seller}
                                                    </SoftBadge>
                                                </div>
                                            )})}
                                    </td>
                                </tr>
                                }
                                
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Card.Body>
            {details?.images?.length > 0 &&
            <Card.Footer>
                
                    <Row>
                        <Col>
                            <h6>Images</h6>
                            <Row>
                                {details?.images?.map((image, index) => {
                                    return (
                                        <Col xs={6} md={4} lg={3} key={index}>
                                            <img src={image} alt={details?.title} className='img-fluid' />
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Col>
                    </Row>
            </Card.Footer>
            }
        </Card>
    )
    }

const AmazonIntel = ({product, variants, repricerData}) => {

    const topAsinScore = repricerData?.topAsin;
    const topAsinFees = repricerData?.topAsinFees;
    const otherAsins = repricerData?.amazonData
    let opportunityDetails = repricerData?.opportunityDetails
    
    
    // go through each property in opportunityDetails.fba to see if a price is present (not false)
    // if a price is present, then add it to the opportunityDetails.fba object
    // if a price is not present, then delete the property from the opportunityDetails.fba object
    // this will allow us to only show the FBA price if it is present
    useEffect(() => {
        if (opportunityDetails?.fba) {
            Object.keys(opportunityDetails.fba).forEach(key => {
                if (!opportunityDetails.fba[key]) {
                    delete opportunityDetails.fba[key]
                }
            })
        }
        if (opportunityDetails?.merchant) {
            Object.keys(opportunityDetails.merchant).forEach(key => {
                if (!opportunityDetails.merchant[key]) {
                    delete opportunityDetails.merchant[key]
                }
            })
        }
    }, [opportunityDetails])
    
    // remove topAsinScore.asin from otherAsins
    const otherAsinsFiltered = otherAsins?.filter(asin => asin.asin !== topAsinScore.asin)

    return (
        <>
            {opportunityDetails &&
                        <Alert variant='success'>
                            <Alert.Heading>Variant {opportunityDetails.sku} - Pricing Opportunity</Alert.Heading>
                            {opportunityDetails?.fba && Object.keys(opportunityDetails?.fba).length > 0 &&
                                <p>
                                    <strong>FBA</strong>
                                    <br />
                                    {opportunityDetails?.fba?.minimumPrice && <span>Minimum Price: ${opportunityDetails?.fba?.minimumPrice} 
                                    ({Number(opportunityDetails?.fba?.minimumPriceOpportunitySize/opportunityDetails?.fba?.minimumPrice).toFixed(2)})
                                    </span>}
                                    {opportunityDetails?.fba?.preferredPrice && <span>Minimum Price: ${opportunityDetails?.fba?.preferredPrice} 
                                    ({Number(opportunityDetails?.fba?.preferredPriceOpportunitySize/opportunityDetails?.fba?.preferredPrice).toFixed(2)})
                                    </span>}
                                    
                                </p>
                            }
                            {opportunityDetails?.merchant &&
                                <p>
                                    <strong>Merchant</strong>
                                    <br />
                                    <div>
                                    {opportunityDetails?.merchant?.minimumPrice && <span>Minimum Price: ${opportunityDetails?.merchant?.minimumPrice} 
                                    ({Number(opportunityDetails?.merchant?.minimumPriceOpportunitySize/opportunityDetails?.merchant?.minimumPrice).toFixed(2)})
                                    </span>}
                                    </div>
                                    {opportunityDetails?.merchant?.preferredPrice && <span>Preferred Price: ${opportunityDetails?.merchant?.preferredPrice} 
                                    ({Number(opportunityDetails?.merchant?.preferredPriceOpportunitySize/opportunityDetails?.merchant?.preferredPrice).toFixed(2)})
                                    </span>}
                                </p>
                            }
                            
                        </Alert>
            }
            <Row>
                <Col>
                    <AmazonAsinDataCard
                        asin={topAsinFees.asin}
                        details={topAsinFees?.details}
                        fees={topAsinFees?.fees}
                        pricing={topAsinFees?.pricing}
                        top={true}
                        colorVariant={'primary'}
                        score={topAsinScore.score}
                    />
                </Col>
            </Row>
            {otherAsinsFiltered?.length > 0 &&
                otherAsinsFiltered?.map((asin, index) => {
                    return (
                        <AmazonAsinDataCard
                        asin={asin.asin}
                        details={asin?.details}
                        fees={asin?.fees}
                        pricing={asin?.pricing}
                        colorVariant={'secondary'}
                        score={asin.score}
                        akey={index}
                    />
                )
            })}
        </>
    )
}
