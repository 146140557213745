import React, { useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';

import PageHeader from 'components/common/PageHeader';
import { Card, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import registerNotification from 'helpers/registerNotification';


const NotificationAdmin = () => {

    const navigate = useNavigate();
    const { user, authLoading }= useContext(FirebaseAuthContext);
    useEffect(() => {
        if (authLoading) return;
        if (!user) return navigate('/');
        if (user.email !== "greg@meetmarty.com") return navigate('/');
    }, [authLoading, user])

    const topicRef = useRef('General');
    const nameRef = useRef('Default Notification Name');
    const descriptionRef = useRef('Default Notification Description');
    const keyActionNameRef = useRef('Default Key Action Name');
    const keyActionUrlRef = useRef('Default Key Action URL');

    const handleNotificationRegistration = async (e) => {
        e.preventDefault();
        const payload = {
            topic: topicRef.current.value,
            name: nameRef.current.value,
            description: descriptionRef.current.value,
            keyAction: {
                type: 'link',
                name: keyActionNameRef.current.value,
                url: keyActionUrlRef.current.value
            }
        }
        try {
            const notificationId = await registerNotification(payload);
            toast.success("Notification registered successfully", {theme: "colored"});
        } catch (error) {
            toast.error("Error registering notification", {theme: "colored"});
        }
    }

    return (
        <>
            <PageHeader title="Notification Admin" className="mb-3" />
                <Card className="mb-3">
                    <Card.Body>
                        <Card.Title>Send a Notification</Card.Title>
                            <Form onSubmit={(e) => handleNotificationRegistration(e)}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Topic</Form.Label>
                                    <Form.Control type="text" placeholder="Enter topic" ref={topicRef}/>  
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail" >
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter name" ref={nameRef} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control type="text" placeholder="Enter description" ref={descriptionRef}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Key Action Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter key action" ref={keyActionNameRef}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Key Action URL</Form.Label>
                                    <Form.Control type="text" placeholder="Enter key action" ref={keyActionUrlRef}/>
                                </Form.Group>
                                <Button variant="primary" type="submit">Send</Button>
                            </Form>
                    </Card.Body>
                </Card>
        </>
    )  
}

export default NotificationAdmin;