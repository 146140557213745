import React, { useEffect, useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Card, Dropdown, ListGroup } from 'react-bootstrap';
import { isIterableArray } from 'helpers/utils';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Notification from 'components/notification/Notification';
import { db } from 'config/firebase';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import { collection, query, getDocs, where, limit, updateDoc, orderBy } from 'firebase/firestore';

const collectUserNotificationsSettings = async (userId) => {
  const userNotificationsRef = collection(db, "notificationMeta");
  const userNotificationsSnapshot = await getDocs(query(userNotificationsRef, where("uid", "==", userId)));
  const userNotifications = [];
  userNotificationsSnapshot.forEach((doc) => {
    userNotifications.push(doc.data());
  });
  return userNotifications[0];
}

const collectUserNotifications = async (userSettings) => {
  const activeTopics = userSettings?.activeTopics;
  // for each active topics make a request to the notifications collection
  if (!activeTopics) return ({unread: [], previous: []});
  if (userSettings.lastRead === null) userSettings.lastRead = new Date();
  const notificationsRef = collection(db, "notifications");
  const unreadNotificationsSnapshot = await getDocs(
    query(notificationsRef, 
      where("topic", "in", activeTopics),
      where("timestamp", ">", userSettings.lastRead),
      orderBy("timestamp", "desc"),
    )
  );
  const unreadNotifications = [];
  unreadNotificationsSnapshot.forEach((doc) => {
    let temp = doc.data();
    temp.id = doc.id;
    unreadNotifications.push(temp);
  });
  // sort by timestamp newest first
  unreadNotifications.sort((a, b) => (a.timestamp > b.timestamp) ? -1 : 1);

  const previousNotificationsSnapshot = await getDocs(
    query(notificationsRef,
      where("topic", "in", activeTopics),
      where("timestamp", "<=", userSettings.lastRead),
      limit(250)
    )
  );
  const previousNotifications = [];
  previousNotificationsSnapshot.forEach((doc) => {
    let temp = doc.data();
    temp.id = doc.id;
    previousNotifications.push(temp);
  });
  // sort by timestamp newest first
  previousNotifications.sort((a, b) => (a.timestamp > b.timestamp) ? -1 : 1);

  return { unread: unreadNotifications, previous: previousNotifications};
}

const NotificationDropdown = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [isAllRead, setIsAllRead] = useState(true);
  const [newNotifications, setNewNotifications] = useState([]);
  const [earlierNotifications, setEarlierNotifications] = useState([]);
  const { user, authLoading }= useContext(FirebaseAuthContext);
  useEffect(() => {
    if (authLoading) return;
    if (!user) return;
    let isSubbed = true;
    collectUserNotificationsSettings(user.uid).then((userSettings) => {
      collectUserNotifications(userSettings).then((notifications) => {
        const newNotificationData = notifications.unread;
        const earlierNotificationData = notifications.previous;
        if (isSubbed) {
          if (newNotificationData.length < 1) setIsAllRead(true);
          else setIsAllRead(false);
          setNewNotifications(() => newNotificationData.map((notification) => buildNotificationFormat(notification, true)));
          setEarlierNotifications(() => earlierNotificationData.map((notification) => buildNotificationFormat(notification, false)));
        }
      })
    })
    return () => isSubbed = false;
  }, [authLoading, user])


  const buildNotificationFormat = (notification, unread) => {
    try {
      const timestamp = notification?.timestamp.toDate();
      const time = timestamp.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
      const date = timestamp.toLocaleDateString();
      return {
        id: notification.id,
        avatar: {
          src: 'https://via.placeholder.com/500x500.png?text=Avatar',
          size: '2xl'
        },
        children:
          `<div><strong>${notification.name}</strong> ${notification.description}</div>`,
        time: `${date} ${time}`,
        emoji: '💬',
        className: 'rounded-0 border-x-0 border-300 border-bottom-0',
        to: notification.url,
        unread: unread,
        keyAction: notification.keyAction
      }
    } catch (error) {
      console.log(error)
    }
    
  }

  // Handler
  const handleToggle = () => {
    updateLastReadTime(user.uid);
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.innerWidth < 1200 && setIsOpen(false);
    });
  }, []);

  async function updateLastReadTime(uid) {
    try {
      const userNotificationsRef = collection(db, "notificationMeta");
      const userNotificationsSnapshot = await getDocs(query(userNotificationsRef, where("uid", "==", uid)));
      const userNotifications = [];
      userNotificationsSnapshot.forEach((doc) => {
        const temp = doc.data();
        temp.id = doc.id;
        temp.docRef = doc.ref;
        userNotifications.push(temp);
      });
      const userNotificationRef = userNotifications[0].docRef;
      await updateDoc(userNotificationRef, {
        lastRead: new Date()
      });
    } catch (error) {
      console.log(error)
    }
  }

  const markAsRead = e => {

    updateLastReadTime(user.uid);
    e.preventDefault();

    const updatedNewNotifications = newNotifications.map(notification =>
      Object.prototype.hasOwnProperty.call(notification, 'unread')
        ? { ...notification, unread: false }
        : notification
    );
    const updatedEarlierNotifications = earlierNotifications.map(notification =>
      Object.prototype.hasOwnProperty.call(notification, 'unread')
        ? { ...notification, unread: false }
        : notification
    );

    setIsAllRead(true);
    setNewNotifications(updatedNewNotifications);
    setEarlierNotifications(updatedEarlierNotifications);
  };

  return (
    <Dropdown navbar={true} as="li" show={isOpen} onToggle={handleToggle}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className={classNames('px-0 nav-link', {
          'notification-indicator notification-indicator-primary': !isAllRead
        })}
      >
        <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-4" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret-bg">
        <Card
          className="dropdown-menu-notification dropdown-menu-end shadow-none"
          style={{ maxWidth: '20rem' }}
        >
          <FalconCardHeader
            className="card-header"
            title="Notifications"
            titleTag="h6"
            light={false}
            endEl={
              <Link
                className="card-link fw-normal"
                to="#!"
                onClick={markAsRead}
              >
                Mark all as read
              </Link>
            }
          />
          <ListGroup
            variant="flush"
            className="fw-normal fs--1 scrollbar"
            style={{ maxHeight: '19rem' }}
          >
            <div className="list-group-title">NEW</div>{' '}
            {isIterableArray(newNotifications) &&
              newNotifications.map(notification => (
                <ListGroup.Item key={notification.id} onClick={handleToggle}>
                  <Notification {...notification} flush />
                </ListGroup.Item>
              ))}
            <div className="list-group-title">EARLIER</div>
            {isIterableArray(earlierNotifications) &&
              earlierNotifications.map(notification => (
                <ListGroup.Item key={notification.id} onClick={handleToggle}>
                  <Notification {...notification} flush />
                </ListGroup.Item>
              ))}
          </ListGroup>
          <div
            className="card-footer text-center border-top"
            onClick={handleToggle}
          >
            <Link className="card-link d-block" to="/user/notifications">
              View all
            </Link>
          </div>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationDropdown;
