import React, { useEffect } from 'react';

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useNavigate } from 'react-router-dom';
import { auth, db } from 'config/firebase';
import { query, collection, limit, orderBy } from 'firebase/firestore';
import {  Row, Col } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';

const AutomaticHistory = () => {

    const [user, authLoading] = useAuthState(auth);
    const navigate = useNavigate();
    const [values, collectionLoading, collectionError] = useCollectionData( 
        query(
            collection(db, "productChangeHistory"), 
            orderBy("timestamp", "desc"),
            limit(2500)
            ), {
        snapshotListenOptions: { includeMetadataChanges: true },
    });

    if (collectionError) console.log(collectionError)

     const columns = [
        {
            accessor: 'timestamp',
            Header: 'Date/Time',
            Cell: rowData => {
              const { timestamp } = rowData.row.original;
              const time = new Date(timestamp.toDate()).toLocaleString("en-us", {timeZone: "America/New_York"});
              return (
                <span>
                  {time}
                </span>
              );
            }
        },
        {
            accessor: 'sku',
            Header: 'Product Number',
            Cell: rowData => {
              const { sku } = rowData.row.original;
              
              return (
                <span>
                  <a href={`/products/${sku}`}>{sku}</a>
                </span>
              );
            }
        },
        {
          accessor: 'special',
          Header: 'Key Event',
          Cell: rowData => {
            const { special, sku } = rowData.row.original;
            console.log(special)
            return special.map((change) => <ul key={`${change}_${sku}`}>{change}</ul>)
          }
        },
        {
          accessor: 'changes',
          Header: 'Product Changes',
          Cell: rowData => {
            const { changes } = rowData.row.original;

            return changes.map((change) => <ul key={`${change.sku}_${change.new}_${change.field}`}>{change.field} New: {change.new} </ul>)
          }
        }
      ];


    useEffect(() => {
        if (authLoading) return;
        if (!user) return navigate('/');
        
      }, [user, authLoading, navigate]);


      return (
        <>
            {!collectionLoading ?

                <div className="m-3">

                    <AdvanceTableWrapper
                        columns={columns}
                        data={values}
                        sortable
                        pagination
                        setPageSize
                    >
                
                    <div className="m-3">

                        <Row className="flex-end-center mb-3">
                            <Col>
                                <AdvanceTableSearchBox table/>
                            </Col>
                        </Row>
                        <Row className="flex-end-center mb-3">
                            <Col>
                                <AdvanceTableFooter
                                    rowCount={values.length}
                                    table
                                    rowInfo
                                    navButtons
                                    rowsPerPageSelection
                                />
                            </Col>
                        </Row>
                        

                    </div>
                
                    <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                        bordered: false,
                        striped: true,
                        className: 'fs--1 mb-0 overflow-hidden'
                    }}
                    />

                    <div className="m-3">

                        <AdvanceTablePagination table />
                    
                    </div>
            
                    </AdvanceTableWrapper>
                </div>
            :
                <h1>Loading...</h1>
            }
        </>
      )
    

}

export default AutomaticHistory;