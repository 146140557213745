import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as fullHeart} from '@fortawesome/free-solid-svg-icons'

// nav link class names
// notification-indicator notification-indicator-warning notification-indicator-fill

const styleObject = {
  position: 'relative',
  top: '-2px',
  backgroundColor: '#bed0f1',
  borderRadius: '50%',
  color: '#eef1f9',
  width: '32px',
  height: '32px'
}

//<span className="notification-indicator-number">1</span>
const FavoritesNavLink = ({ user }) => {

  return (
    <Nav.Item as="li" className="rounded" key="favoritesNavItem">
      <Nav.Link
        as={Link}
        to="/favorites"
        className="pe-0 ps-2 nav-link"
      >
        <div style={styleObject}>
        <FontAwesomeIcon
          
          icon={fullHeart}
          transform="shrink-7"
          className="fs-4"
          style={{marginLeft: '-0.5px'}}
          variant={"falcon-danger"}
        /></div>
      </Nav.Link>
    </Nav.Item>
  );
};

export default FavoritesNavLink;
