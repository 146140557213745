import { db } from 'config/firebase.js';
import { useEffect, useState } from 'react';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import useLocalStorage, { clearLocalStorage, storageAvailable }  from './useLocalStorage';
import { set as idbSet, get as idbGet, setMany, values as getValues, clear as eraseDb } from 'idb-keyval';

/**
 * This Hook is used to get the fuzzy search data from firebase and store it in local storage
 * Then periodocially update the local data if the ttl has expired
 * @param {Bool} expired 
 * @param {String} org 
 * @returns 
 */
function useSearchData(expired) {

    /** TODO - change for production */
    const ttlKeyName = 'merlinSearchTtl13';
    const searchSkusName = 'mspid13';
    /** TODO - sync ttl with backend hydration */
    // const ttl = 1000 * 60 * 60 * 8; // 8 hours
    const ttl = 1000 * 60 * 60 * 24 * 7; // 1 week

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [state, setState] = useState(false);
    const [message, setMessage] = useState('useSearchData Hook Initialized');
    const [localDataExpiration, setLocalExpiration] = useLocalStorage(ttlKeyName, new Date().getTime());
    
    async function getFirebaseChoices(org) {
        try {
    
            const choicesQuery = query(
                collection(db, "fuzzySearch", org, "fuzzyChoices"),
                state ? where("disc", "!=", true) : null
              );
              
            const choicesSnapshot = await getDocs(choicesQuery);
            
            const snapSize = choicesSnapshot.size;
            setMessage(`Identified ${snapSize} products. Please wait.`);
            const choices = [];
            const skus = [];
            choicesSnapshot.forEach((doc) => {
                skus.push(doc.id)
                choices.push({...doc.data()})
            })
            //setMerlinSearchSkus(skus);
            setMessage(`Product Data Loaded. Please wait.`);
            return choices;
        } catch (error) {
            console.log(error);
            setError(error);
        }
    }

    function chunkArray(array, size) {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
            const chunk = array.slice(i, i + size);
            result.push(chunk);
        }
        return result;
    }

    async function saveManyLocalData(choices) {
        const chunks = chunkArray(choices, 100);
        return eraseDb().then(() => {
            const numChunks = chunks.length;
            let index = 1;
            for (const chunk of chunks) {
                const structured = chunk.map((choice) => {
                    return [choice.sku, choice]
                })
                setMany(structured).then(() => {
                    const percent = Math.round((index / numChunks) * 100);
                    setMessage(`Local application data loading ${percent}% complete. Please wait.`);
                }).catch((error) => {
                    console.log(error)
                    console.log([...structured])
                }).finally(() => {
                    index++;
                })
            }
            
        })
    }

    async function getManyLocalData() {
        try {
            // getValues returns an array of values for products
            let localChoices = await getValues();
            let incomplete = false;
            const undefinedList = localChoices.filter((choice) => choice === undefined);
            if (undefinedList?.length > 0) incomplete = true;
            if (localChoices?.length < 30000 || incomplete ) {
                // didnt get the full copy
                // need to get from firebase
                setMessage(`Missing product data, collecting up to date information. Please wait.`);
                const firebaseChoices = await getFirebaseChoices('topicz');
                setMessage('Local Application Data Loaded');
                return await saveManyLocalData(firebaseChoices);
            } else {
                setMessage('Local Application Data Loaded');
                return localChoices;
            }

        } catch (error) {
            console.log(error)

        }
    }

    // function creates an timer to delay setLoading(false) until the message has been displayed
    function delaySetLoading() {
        return setTimeout(() => {
            setLoading(false);
        }, 800)
    }

    useEffect(() => {
        setLoading(true)
        const populateData = async () => {
            try {
                const rightNow = new Date().getTime();
                if (rightNow - localDataExpiration > ttl || expired) {
                    // the data is stale
                    // update local storage
                    setMessage('Getting the latest data for you. Please wait.')
                    setLocalExpiration(new Date().getTime());
                    const choices = await getFirebaseChoices('topicz');
                    setData(choices);
                    await saveManyLocalData(choices);
                    delaySetLoading();
                } else {
                    // the data is fresh
                    // use local storage
                    setMessage('Attempting to load local data. Please wait.')
                    const choices = await getManyLocalData();
                    setData(choices);
                    //setLoading(false);
                    delaySetLoading();
                }
            } catch (error) {
                console.log(error);
                setError(error);
            }
        }
        return populateData();
    }, []);

    return [data, loading, error, setState, message]
}

export default useSearchData;