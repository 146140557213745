import { useState, useContext, useEffect } from 'react';
import { db } from "config/firebase";
import { useNavigate } from 'react-router-dom';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import PageHeader from 'components/common/PageHeader';
import { Card, Row, Col, Button, Form, Tabs, Tab, Image } from 'react-bootstrap';
import Lottie from 'lottie-react';
import dotsAnimation from 'assets/lottie/dots.json';
import { useCollection } from 'react-firebase-hooks/firestore';
import { collection, where, query } from "firebase/firestore";
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';


const PickWizard = ({ children }) => {

    const { user, authLoading }= useContext(FirebaseAuthContext)
    const navigate = useNavigate();
    const [loadingState, setLoadingState] = useState(false);

    // Get all Picks!
    const [openPicksRaw, openPicksLoading, openPicksError] = useCollection(
        query(
            collection(db, "pickWizardJobs"),
            where("status", "==", "NOT STARTED"),
        )
    );
    const [openPicks, setOpenPicks] = useState([]);
    useEffect(() => {
        if (openPicksLoading) return;
        if (openPicksError) {
            console.log("Error loading open picks: ", openPicksError);
            return;
        }
        if (!openPicksRaw) return;
        setOpenPicks(openPicksRaw.docs.map(doc => {
            let temp = doc.data();
            temp.pickId = doc.id;
            temp.pickRef = doc.ref;
            return temp;
        }));
    }, [openPicksRaw, openPicksLoading, openPicksError]);

    const [wipPicksRaw, wipPicksLoading, wipPicksError] = useCollection(
        query (
            collection(db, "pickWizardJobs"),
            where("status", "==", "IN PROGRESS")
        )
    );
    const [wipPicks, setWipPicks] = useState([]);
    useEffect(() => {
        if (wipPicksLoading) return;
        if (wipPicksError) {
            console.log("Error loading wip picks: ", wipPicksError);
            return;
        }
        if (!wipPicksRaw) return;
        setWipPicks(wipPicksRaw.docs.map(doc => {
            let temp = doc.data();
            temp.pickId = doc.id;
            temp.pickRef = doc.ref;
            return temp;
        }));
    }, [wipPicksRaw, wipPicksLoading, wipPicksError]);

    const OpenPicksTable = ({ openPicks }) => {
        const colDef = [
            { 
                accessor: 'batchName',
                Header: 'Batch Name',
            },
            {
                accessor: 'assignedUser',
                Header: 'Assigned User',
            },
            {
                accessor: 'items',
                Header: 'Item Count',
                Cell: ({ cell }) => {
                    const { items } = cell.row.original;
                    const itemCount = items.length;
                    return (
                        <div className="">
                            {itemCount}
                        </div>
                    )
                }
            },
            {
                accessor: 'outOfStockItems',
                Header: 'Alerts',
                Cell: ({ cell }) => {
                    const { outOfStockItems } = cell.row.original;
                    
                    let AlertDisplay;
                    if (outOfStockItems.length > 0) {
                        AlertDisplay = (
                            <div className="">
                                <Image src="https://img.icons8.com/ios/50/000000/high-priority.png" width="25" height="25" />
                                <span className='ms-2 fw-bold'>Out of Stock Items Detected</span>
                                {outOfStockItems.map((item, index) => {
                                    return (
                                        <div key={index} className="ms-4">
                                            <span className="ms-2">{item.quantity} x</span>
                                            <span className="ms-2">{item.orderedSku}</span>
                                            {item.bundleComponent ?
                                                <span className="ms-2">Bundle Component</span>
                                            : item.subItem !== "None" ? 
                                                <span className="ms-2">Sub Item: {item.subItem}</span>
                                            :   <span className="ms-2">No Sub Defined</span>
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    } else {
                        AlertDisplay = (
                            <div className="">
                                <Image src="https://img.icons8.com/ios/50/000000/ok--v1.png" width="25" height="25" />
                            </div>
                        )
                    }

                    return AlertDisplay;
                }
            },
            {
                accessor: 'timestamp.created',
                Header: 'Actions',
                Cell: ({ cell }) => {
                    const { batchId, pickId } = cell.row.original;
                    
                    // display three buttons which can view the batch, view the pick or pause pick
                    const ViewBatchButton = ({ batchId }) => {
                        return (
                            <Button variant="outline-primary" size="sm" className="me-2" 
                                onClick={() => navigate(`/fulfillment/batches/${batchId}`)}>
                                View Batch
                            </Button>
                        )
                    }

                    const SendPickLink = ({ pickId }) => {
                        return (
                            <Button variant="outline-primary" size="sm" className="me-2" 
                                onClick={() => navigate(`/fulfillment/pick/${pickId}`)}>
                                View Job
                            </Button>
                        )
                    }

                    return (
                        <div className="">
                            <ViewBatchButton batchId={batchId} />
                            <SendPickLink pickId={pickId} />
                        </div>
                    )
                }
            },
        ];
        return (
            openPicks?.length > 0 
            ?
                <>
                    <AdvanceTableWrapper
                        columns={colDef}
                        data={openPicks}
                        sortable
                        pagination
                        perPage={50}
                    >
                        <Row className='mb-3'>
                            <Col>
                                <h5>Open Picks</h5>
                            </Col>
                        </Row>
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </AdvanceTableWrapper>
                </>
            :
            <div className="text-center">
                <h3>No Open Picks</h3>
            </div>
        )
    }

    const WipPicksTable = ({ wipPicks }) => {
        const colDef = [
            { 
                accessor: 'batchName',
                Header: 'Batch Name',
                Cell: ({ cell }) => {
                    const { batchName, pickId } = cell.row.original;
                    return (
                        <a href={`/fulfillment/pick/${pickId}`}>{batchName}</a>
                    )
                }
            },
            {
                accessor: 'assignedUser',
                Header: 'Assigned User',
            },
            {
                accessor: 'items',
                Header: 'Item Progress',
                Cell: ({ cell }) => {
                    const { items, currentPickIndex } = cell.row.original;
                    const itemCount = items.length;
                    const percentComplete = Math.round(((currentPickIndex+1) / itemCount) * 100);
                    return (
                        <>
                            
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{width: `${percentComplete}%`}} aria-valuenow={percentComplete} aria-valuemin="0" aria-valuemax="100">{percentComplete}%</div>
                            </div>
                        </>

                    )
                }
            },
            {
                accessor: 'startedAt',
                Header: 'Performance',
                Cell: ({ cell }) => {
                    const { splitTimes } = cell.row.original;
                    // splits are an array of time between individual picks. each item in the array has a start and end
                    // i need the avaerage of all the splits
                    let splitAverage = 0;
                    if (splitTimes?.length > 0) {
                        let splitTotal = 0;
                        splitTimes.forEach(split => {
                            splitTotal += (split.endTime.toDate() - split.startTime.toDate()) / 1000;
                        });
                        splitAverage = Number(Math.round(splitTotal / splitTimes?.length));
                    }

                    return (
                        <div className="">
                            <span className="ms-2">{splitAverage} Seconds Avaerage Pick Time</span>
                        </div>
                    )
                }
            },
            {
                accessor: 'issues',
                Header: 'Issues',
                Cell: ({ cell }) => {
                    const { issues } = cell.row.original;
                    let issueDisplay;
                    if (issues?.length > 0) {
                        issueDisplay = (
                            <div className="">
                                <Image src="https://img.icons8.com/ios/50/000000/high-priority.png" width="25" height="25" />
                                <span className='ms-2 fw-bold'>Issues Detected</span>
                                {issues.map((issue, index) => {
                                    return (
                                        <div key={index} className="ms-4">
                                            <span className="ms-2">{issue.issue}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    } else {
                        issueDisplay = (
                            <div className="">
                                <Image src="https://img.icons8.com/ios/50/000000/ok--v1.png" width="25" height="25" />
                            </div>
                        )
                    }
                    return  issueDisplay;
                }
            },


        ];
        return (
            wipPicks?.length > 0 
            ?
                <>
                    <AdvanceTableWrapper
                        columns={colDef}
                        data={wipPicks}
                        sortable
                        pagination
                        perPage={50}
                    >
                        <Row className='mb-3'>
                            <Col>
                                <h5>In-Progress Picks</h5>
                            </Col>
                        </Row>
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </AdvanceTableWrapper>
                </>
            :
            <div className="text-start mt-2 ">
                <h5>No In-Progress Picks</h5>
            </div>
        )
    }
    
    // Redirect to home if not logged in
    useEffect(() => {
        if (authLoading) return;
        if (!user) return navigate('/');
      }, [authLoading, user])

    return (
        <>
            <PageHeader title="Pick Wizard Dashboard" className="mb-3"/>
            <Card className='p-3 mb-3'>
                { !openPicksLoading ? <OpenPicksTable openPicks={openPicks} />
                : <Lottie animationData={dotsAnimation} style={{width: 100, height: 100}} /> }
            </Card>
            <Card className='p-3 mb-3'>
                { !wipPicksLoading ? <WipPicksTable wipPicks={wipPicks} />
                : <Lottie animationData={dotsAnimation} style={{width: 100, height: 100}} /> }
            </Card>
        </>
        
        
    );
}

export default PickWizard;