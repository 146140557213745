import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import PageHeader from 'components/common/PageHeader';
import { Card, Col, Row, Form, Button, Image, Modal, Tabs, Tab, Spinner, InputGroup, FormControl } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/merlin/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/merlin/advance-table/AdvanceTableFooter';
import IconButton from 'components/common/IconButton';
import SoftBadge from 'components/common/SoftBadge';
import { toast } from 'react-toastify';

import { db } from 'config/firebase.js';
import { useCollection } from 'react-firebase-hooks/firestore';
import { 
    collection, query, orderBy, collectionGroup, getDocs, 
    where, getDoc, addDoc, deleteDoc, serverTimestamp, updateDoc, 
    onSnapshot, doc, writeBatch
} from 'firebase/firestore';
import Flex from 'components/common/Flex';
import Select from 'components/doc-components/Select';
import { use } from 'video.js';
import { LottiePlayer } from 'lottie-react';

const Launchpad = () => {

    const [showAcceptModal, setShowAcceptModal] = useState(false);
    const [productForAcceptModal, setProductForAcceptModal] = useState(null);
    const navigate = useNavigate();
    const { user, authLoading }= useContext(FirebaseAuthContext);
    useEffect(() => {
        if (authLoading) return;
        if (!user) return navigate('/');
    }, [authLoading, user])

    const [incomingProducts, setIncomingProducts] = useState([]);
    const [incomingProductsRaw, incomingProductsLoading, incomingProductsError] = useCollection(
        query(
            collection(db, "incomingProducts"),
            where("status", "==", "NEW")
        )
    );
    const getStoreName = async (storeId) => {
        try {
            if (storeId === "UNKNOWN") return ("UNKNOWN");
            const storeDoc = await getDoc(doc(db, "shipStationStores", storeId));
            const storeData = storeDoc.data();
            let name = storeData.storeName;
            if (storeData.marketplaceName) name += " - (" + storeData.marketplaceName + ")"; 
            return name
        } catch {
            return ("UNKNOWN");
        }
    }

    const enhanceIncomingProducts = async (incomingProducts) => {
        for (let i = 0; i < incomingProducts.length; i++) {
            incomingProducts[i].storeName = await getStoreName(incomingProducts[i].storeId);
        }
        return incomingProducts;
    }

    useEffect(() => {
        if (incomingProductsLoading) return;
        if (incomingProductsError) {
            toast.error("Error fetching incoming products");
            console.log(incomingProductsError);
            return;
        }
        if (!incomingProductsRaw) return;
        let isSubbed = true;
        const incomingProductsData = incomingProductsRaw.docs.map((doc) => {
            return {
                id: doc.id,
                ref: doc.ref,
                ...doc.data()
            }
        });
        enhanceIncomingProducts(incomingProductsData).then((enhancedIncomingProducts) => {
            if (isSubbed) setIncomingProducts(enhancedIncomingProducts);
        })
        .catch((error) => {
            toast.error("Error enhancing incoming products");
            console.log(error);
        })
        .finally(() => {
            return () => isSubbed = false;
        })
    }, [incomingProductsRaw, incomingProductsLoading, incomingProductsError]);
    

    const GenerateIncomingProductsCards = ({incomingProducts}) => {
        return (
            <>
                {incomingProducts.map((incomingProduct, index) => {
                    return (
                    
                    <div 
                        className='p-2 m-0 w-100-xs w-auto-sm w-auto-md w-lg-25 '
                        key={`incp-${index}`}
                    >
                    <Card>
                        <Card.Img src={incomingProduct.imageUrl} style={{
                            width: "100%",
                            height: "15vw",
                            objectFit: "cover",
                        }} variant='top'/>
                        <Card.Header>
                        <Row>
                            <Col>
                                <span
                                    className='text-800 fw-semi-bold d-block'
                                    style={{ maxWidth: 'calc(100%)' }}
                                >{incomingProduct.name}</span>
                            </Col>
                        </Row>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col xs={12} md={8}>
                                    <SoftBadge bg="light" className="me-2">SKU: {incomingProduct.sku}</SoftBadge>
                                    <SoftBadge bg="light" className="me-2">Supplier: {incomingProduct.supplierId}</SoftBadge>
                                </Col>
                                
                            </Row>
                            <Row>
                                <Col xs={12} md={8}>
                                    <SoftBadge bg="light" className="me-2">Detected In {incomingProduct.channel}</SoftBadge>
                                    <SoftBadge bg="light" className="me-2">Originally from {incomingProduct.storeName}</SoftBadge>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            <Row>
                                <Col>
                                    <Button 
                                        variant="falcon-success" 
                                        className="float-start"
                                        onClick={() => handleAccpetModal(incomingProduct)}
                                    >Accept</Button>
                                </Col>
                                <Col>
                                    <Button variant="falcon-danger" className="float-end" onClick={() => rejectIncomingProduct(incomingProduct)}>Reject</Button>
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>
                    </div>
                )})}
            </>
        )
    }

    const GenerateIncomingProductsGrid = ({incomingProducts}) => {

        const optionRef = React.useRef(null);

        const columns = [
            {
              accessor: 'sku',
              Header: 'SKU',
              Cell: (rowData) => {
                const { sku } = rowData.row.original;
                return (
                    <div className="d-flex align-items-center ">
                        <div className="flex-1 ms-2 ">{sku.length > 10 ? `${String(sku).substring(0,10)}...` : String(sku)}</div>
                    </div>
                )
              }
            },
            {
              accessor: 'name',
              Header: 'Product Name',
              Cell: (rowData) => {
                const { name, imageUrl } = rowData.row.original;
                return (
                    <div className="d-flex align-items-center d-inline-block">
                        {imageUrl && <Image src={imageUrl} className="rounded" width="32" alt={name} /> }
                        <div 
                            className="flex-1 ms-2 text-truncate"
                            style={{ maxWidth: '50vw' }}
                        >{name}</div>
                    </div>
                )
              }
            },
            {
                accessor: 'supplierId',
                Header: 'Supplier',
                Cell: (rowData) => {
                    const { supplierId } = rowData.row.original;
                    return (
                        <div className="d-flex align-items-center">
                            <div className="flex-1 ms-2">{supplierId}</div>
                        </div>
                    )
                    }
            },
            {
                accessor: 'channel',
                Header: 'Channel',
                Cell: (rowData) => {
                    const { channel } = rowData.row.original;
                    return (
                        <div className="d-flex align-items-center">
                            <div className="flex-1 ms-2">{channel}</div>
                        </div>
                    )
                    }
            },
            {
                accessor: 'storeName',
                Header: 'Store Name',
                Cell: (rowData) => {
                    const { channel } = rowData.row.original;
                    return (
                        <div className="d-flex align-items-center">
                            <div className="flex-1 ms-2">{channel}</div>
                        </div>
                    )
                    }
            },
        ];

        async function handleBulkAction(action, selectedRowIds, table) {
            const data = table.page;
            const selectedRows = data.filter((row) => selectedRowIds[row.id]);
            const records = selectedRows.map((row) => row.original);
            switch (action) {
                case 'accept':
                    //await bulkAcceptIncomingProducts(records);
                    break;
                case 'reject':
                    await bulkRejectIncomingProducts(records);
                    break;
                default:
                    break;
            }
        }

        function BulAction(table){
            const { selectedRowIds } = table;
        return (
            <Row className="flex-between-center mb-3">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                <h5 className="fs-0 ms-1 mb-0 text-nowrap py-2 py-xl-0">
                {
                    Object.keys(selectedRowIds).length > 0 ?
                    'You have selected ' + Object.keys(selectedRowIds).length + ' products' 
                    :
                    `Viewing ${incomingProducts.length} Products`
                }
                </h5>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                {Object.keys(selectedRowIds).length > 0 ? (
                <div className="d-flex">
                    <Form.Select size="sm" aria-label="Bulk actions" ref={optionRef}>
                    <option>Bulk Actions</option>
                    <option value="accept">Accept</option>
                    <option value="reject">Reject</option>
                    </Form.Select>
                    <Button
                    type="button"
                    variant="falcon-default"
                    size="sm"
                    className="ms-2"
                    onClick={() => handleBulkAction(optionRef.current.value, selectedRowIds, table)}
                    >
                    Apply
                    </Button>
                </div>
                ) :
                    null // add bulk action buttons here
                }
            </Col>
            </Row>
        );
        };
          
        return  (
            <div 
                className='p-0 m-0 w-100'
                key={`data-grid}`}
            >
            <Card className='p-2'>
            <AdvanceTableWrapper
                columns={columns}
                data={incomingProducts}
                sortable
                pagination
                perPage={10}
                selection
                selectionColumnWidth={5}
            >
                <Row className="flex-end-center mb-3">
                <Col xs="auto" sm={12} lg={12}>
                    <AdvanceTableSearchBox table/>
                </Col>
                </Row>
                <BulAction table/>
                <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                    bordered: true,
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                }}
                />
                <div className="mt-3">
                <AdvanceTableFooter
                    rowCount={incomingProducts.length}
                    table
                    rowInfo
                    navButtons
                    rowsPerPageSelection
                />
                </div>
            </AdvanceTableWrapper>
            </Card>
            </div>
        )
    }

    const [currentView, setView] = useState('cards'); // ['cards', 'grid'
    const ViewCardsOrGrid = ({ currentView, setView }) => {
        return (
            <div className="d-flex justify-content-end">
                <IconButton
                    className="mx-1"
                    icon={currentView === 'cards' ? 'image' : 'th'}
                    onClick={() => setView(currentView === 'cards' ? 'grid' : 'cards')}
                    variant="falcon-default"
                    size="sm"
                />
            </div>
        )
    }

    const rejectIncomingProduct = async (incomingProduct) => {
        try {
            await updateDoc(incomingProduct.ref, {
                status: "REJECTED",
                timestamp: {
                    updated: serverTimestamp()
                }
            });
            toast.success("Product Rejected");
        } catch (error) {
            toast.error("Error Rejecting Product");
            console.log(error);
        }
    }

    const bulkRejectIncomingProducts = async (incomingProducts) => {
        try {
            const batch = writeBatch(db);
            incomingProducts.forEach((incomingProduct) => {
                batch.update(incomingProduct.ref, {
                    status: "REJECTED",
                    timestamp: {
                        updated: serverTimestamp()
                    }
                });
            });
            await batch.commit();
            toast.success("Products Rejected");
        } catch (error) {
            toast.error("Error Rejecting Products");
            console.log(error);
        }
    }

    const restoreProducts = async () => {
        try {
            const incomingProductsQuery =
            query (
                collection(db, "incomingProducts"),
                where("status", "==", "REJECTED"),
            );
            const incomingProductsRaw = await getDocs(incomingProductsQuery);
            const incomingProducts = incomingProductsRaw.docs.map((doc) => {
                let temp = doc.data();
                temp.ref = doc.ref;
                return temp;
            });
            const batch = writeBatch(db);
            incomingProducts.forEach((incomingProduct) => {
                batch.update(incomingProduct.ref, {
                    status: "NEW",
                    timestamp: {
                        updated: serverTimestamp()
                    }
                });
            });
            batch.commit();
            toast.success("Products Restored");
        } catch (error) {
            toast.error("Error Restoring Products");
            console.log(error);
        }
    }

    const AcceptModal = ({incomingProduct, show, setShow}) => {

        const [newProductType, setNewProductType] = useState('core'); // ['core', 'variant', 'bundle'
        const brandRef = React.useRef(null);
        const costRef = React.useRef(null);
        const shortDescriptionRef = React.useRef(null);
        const additionalBarcodesRef = React.useRef(null);
        const vendorRef = React.useRef(null);

        const [fulfillmentLocations, setFulfillmentLocations] = useState([]);
        const [selectedLocation, setSelectedLocation] = useState(null);

        const [productAcceptedState, setProductAcceptedState] = useState('idle'); // ['idle', 'loading', 'success', 'error']
        const [productAcceptedUrl, setProductAcceptedUrl] = useState(null);

        useEffect(() => {
            if (show) {
                const getFulfillmentLocations = async () => {
                    try {
                        const fulfillmentLocationsQuery = query(collection(db, "locations"));
                        const fulfillmentLocationsRaw = await getDocs(fulfillmentLocationsQuery);
                        const fulfillmentLocations = fulfillmentLocationsRaw.docs.map((doc) => {
                            let temp = doc.data();
                            if (temp.type !== 'automatic') {
                                // if the location is not automatic, we can use it
                                temp.id = doc.id;
                                temp.ref = doc.ref;

                            console.log(temp)
                                return temp;
                            }
                            return null;
                        });
                        // remove empty locations
                        const filteredFulfillmentLocations = fulfillmentLocations.filter((location) => location !== null);
                        setFulfillmentLocations(fulfillmentLocations);
                    } catch (error) {
                        console.log(error);
                    }
                }
                getFulfillmentLocations();
            }
        }, [show]);


        const acceptIncomingProduct = async (incomingProduct) => {
            switch (newProductType) {
                case 'core': return handleCoreProductAccept(incomingProduct);
                case 'variant': return // handleVariantProductAccept(incomingProduct);
                case 'bundle': return // handleBundleProductAccept(incomingProduct);
                default: return;
            }
        }

        const handleCoreProductAccept = async (incomingProduct) => {
            try {
                let newProduct = {};
                
                // barcodes
                const upc = incomingProduct.upc || '';
                if (upc) newProduct.barcodes.unit = upc;

                // inventory
                newProduct.ecomm_onhand = 0;
                newProduct.isInStock = false;

                // images
                const primaryImage = incomingProduct.imageUrl || '';
                const files = [];
                if (primaryImage) {
                    files.push({
                        alt: incomingProduct.name,
                        id: incomingProduct.sku,
                        src: primaryImage
                    });
                }
                newProduct.files = files;

                // name, id & sku
                const id = incomingProduct.sku;
                const sku = incomingProduct.sku;
                newProduct.id = id;
                newProduct.sku = sku;
                newProduct.name = incomingProduct.name;

                // default values
                newProduct.enhanced = false;
                newProduct.expectedDate = false;
                newProduct.ibmi = false;
                newProduct.sellerConfig = {
                    updateCost: false,
                    updateCount: false,
                };
                newProduct.shippingCost = 0;
                newProduct.tags = ['ImportedFromLaunchpad'];
                newProduct.timestamp = {
                    created: serverTimestamp(),
                    modified: serverTimestamp(),
                };

                // build default variant
                let defaultVariant = {};
                defaultVariant.id = incomingProduct.sku;
                defaultVariant.productFactor = 1;
                defaultVariant.sellerActiveStatus = false;
                defaultVariant.sku = incomingProduct.sku;
                defaultVariant.title = incomingProduct.name;

                const brandInput = brandRef.current.value || '';
                const costInput = costRef.current.value || '';
                const shortDescriptionInput = shortDescriptionRef.current.value || '';
                const additionalBarcodesInput = additionalBarcodesRef.current.value || '';
                const vendorInput = vendorRef.current.value || '';

                if (brandInput) newProduct.brand = brandInput;
                if (costInput) newProduct.cost = costInput;
                if (shortDescriptionInput) newProduct.shortDescription = shortDescriptionInput;
                if (additionalBarcodesInput) newProduct.barcodes.other = additionalBarcodesInput;
                if (vendorInput) newProduct.vendor = vendorInput;

                if (selectedLocation) defaultVariant.preferredFullfillmentLocation = selectedLocation; // id of the fulfillment location

                const { newProductRef, defaultVariantRef, productUrl } = await createNewCoreProduct(newProduct, defaultVariant);
                if (!newProductRef || !defaultVariantRef) {
                    toast.error("Error Creating New Core Product", {theme: "colored"});
                    return;
                }
                const finalUpdate = {
                    ref: defaultVariantRef,
                    parentRef: newProductRef,
                    parentSku: newProduct.sku,
                }
                await updateDoc(defaultVariantRef, finalUpdate);
                await updateDoc(incomingProduct.ref, {
                    status: "ACCEPTED",
                    timestamp: {
                        updated: serverTimestamp()
                    },
                    productUrl: productUrl
                });
                toast.success("Product Accepted", {theme: "colored"});
                setProductAcceptedUrl(productUrl);
                setProductAcceptedState('success');
                setShow(false); // close the modal?
            } catch (error) {
                console.log(error);
            }
        }

        const createNewCoreProduct = async (newProduct, defaultVariant) => {

            const sku = newProduct.sku;
            // check if the sku exists
            const skuExistsQuery = query(
                collectionGroup(db, "variants"),
                where("sku", "==", sku)
            );
            const skuExistsRaw = await getDocs(skuExistsQuery);
            if (!skuExistsRaw.empty) {
                toast.error("Product already exists!", {theme: "colored"});
                return;
            }
            try {
                // products Collection
                const productsCollection = collection(db, "productCatalog/topicz/products");
                const newProductRef = await addDoc(productsCollection, newProduct);
                // variants Collection
                const variantsCollection = collection(db, "productCatalog/topicz/products", newProductRef.id, "variants");
                const defaultVariantRef = await addDoc(variantsCollection, defaultVariant);
                const productUrl = `https://merlin-staging.meetmarty.com/topicz/products/${newProductRef.id}`;
                await updateDoc(newProductRef, {
                    productUrl: productUrl
                });
                return { newProductRef, defaultVariantRef, productUrl };
            } catch (error) {
                toast.error("Error Creating New Core Product", {theme: "colored"});
                console.log(error);
            }
        }

        const handleTypeChange = (e) => {
            console.log(e.target.value)
            e.preventDefault();
            if (e.target.value === 'select') return;
            setNewProductType(e.target.value);
        }

        const ProductTypeForm = () => {
            return (
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Product Type</Form.Label>
                        <Form.Select aria-label="Default select example"
                            onChange={(e) => handleTypeChange(e)}
                        >
                            <option value="select">Select type</option>
                            <option value="core">Core Product</option>
                            <option value="variant">Product Variant</option>
                            <option value="bundle">Bundle / Kit</option>
                        </Form.Select>
                    </Form.Group>
                </Form>
            )
        }

        const CoreProductForm = () => {
            // generate a form for brand, cost, shortDescription, additionalBarcodes, vendor
            return (
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Brand</Form.Label>
                        <Form.Control type="text" placeholder="Enter Brand" ref={brandRef}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Cost</Form.Label>
                        <Form.Control type="text" placeholder="Enter Cost" ref={costRef} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Short Description</Form.Label>
                        <Form.Control type="text" placeholder="Enter Short Description" ref={shortDescriptionRef} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Additional Barcodes</Form.Label>
                        <Form.Control type="text" placeholder="Enter Additional Barcodes" ref={additionalBarcodesRef} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Vendor</Form.Label>
                        <Form.Control type="text" placeholder="Enter Vendor" ref={vendorRef} />
                    </Form.Group>
                </Form>
            )
        }

        const VariantProductForm = () => {
            return (
                <div>Coming Soon</div>
            )
        }

        const BundleProductForm = () => {
            return (
                <div>Coming Soon</div>
            )
        }

        const DefaultFulfillmentLocationForm = ({ locations, setPrefLocation }) => {

            const handleLocationChange = (e) => {
                e.preventDefault();
                console.log(e.target.value)
                if (e.target.value === 'select') return;
                setPrefLocation(e.target.value);
            }

            return (
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Default Fulfillment Location</Form.Label>
                        <Form.Select aria-label="Default select example" onChange={(e) => handleLocationChange(e)}>
                            <option value="select">Select Location</option>
                            {locations.map((location, index) => {
                                if (location?.id) {
                                    return (
                                        <option key={`loc-${index}`} value={location?.id}>{location?.name}</option>
                                    )
                                }
                            })}
                        </Form.Select>
                    </Form.Group>
                </Form>
            )
        }

        console.log(productAcceptedState)
        return (
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Accept Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        productAcceptedState === 'idle' &&
                            <>
                            <Row>
                                <Col>
                                    <ProductTypeForm type={newProductType} setType={setNewProductType}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <DefaultFulfillmentLocationForm locations={fulfillmentLocations} setPrefLocation={setSelectedLocation}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {newProductType === 'core' && <CoreProductForm />}
                                    {newProductType === 'variant' && <VariantProductForm />}
                                    {newProductType === 'bundle' && <BundleProductForm />}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {productAcceptedUrl && <div><div>
                                    <Link
                                        to={productAcceptedUrl}
                                        className="btn btn-falcon-primary btn-sm"
                                    >
                                        View Product
                                    </Link>
                                </div></div>}
                                </Col>
                            </Row>
                            </>
                    }
                    {
                        productAcceptedState === 'success' &&
                            <>
                                <div>Product Accepted</div>
                                
                            </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="falcon-default" onClick={() => setShow(false)}>
                        Cancel
                    </Button>
                    <Button variant="falcon-success" onClick={() => acceptIncomingProduct(incomingProduct)}>
                        Accept
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
    const handleAccpetModal = (incomingProduct) => {
        setProductForAcceptModal(incomingProduct);
        setShowAcceptModal(true);
    }


    return (
        <>
            <AcceptModal incomingProduct={productForAcceptModal} show={showAcceptModal} setShow={setShowAcceptModal} />
            <PageHeader title="Product Launchpad" className="mb-3" >
                <ViewCardsOrGrid currentView={currentView} setView={setView} />
            </PageHeader>
            <Flex 
                wrap='wrap' 
                className="mb-1 bg-200 border border-300"
                direction='row'
            >
                {currentView === 'cards' && <GenerateIncomingProductsCards incomingProducts={incomingProducts} />}
                {currentView === 'grid' && <GenerateIncomingProductsGrid incomingProducts={incomingProducts} />}
                
            </Flex>
        </>
    )  
}

export default Launchpad;