import { db } from 'config/firebase';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import celebration from './lottie/celebration.json';
import { toast } from 'react-toastify';

const Success = ({ reset, setStep, getValues }) => {
  //const { setStep, setUser } = useContext(AuthWizardContext);
  const allData = getValues();
  
  async function addToLaunchPad(allData) {
    try {
      const docRef = await addDoc(collection(db, "launchPad"), {
        ...allData
      });

    } catch {
      toast.error('Error adding product to database', {
        theme: 'colored'
      });
    }
  }

  const emptyData = () => {
    setStep(1);
    reset();
  };

  return (
    <>
      <Row>
        <Col className="text-center">
          <div className="wizard-lottie-wrapper">
            <div className="wizard-lottie mx-auto">
              <Lottie animationData={celebration} loop={true} />
            </div>
          </div>
          <h4 className="mb-1">Your New Product has been Created</h4>
          <p className="fs-0">You can now access your new product from your product dashboard!</p>
          <Button color="primary" className="px-5 my-3" onClick={emptyData}>
            Create Another Product
          </Button>
        </Col>
      </Row>
    </>
  );
};

Success.propTypes = {
  reset: PropTypes.func.isRequired
};

export default Success;
