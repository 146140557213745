import { db } from 'config/firebase';
import { query, collection, where } from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useState, useEffect } from 'react';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/merlin/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';

export default function Competition({ product }) {

    const [enhancedProducts, enhancedLoading] = useCollectionData(
        query(
          collection(db, "enhancedProductData"),
          where("parentSku", "==", product.sku),
        ),
        { snapshotListenOptions: { includeMetadataChanges: true } }
    );

    const [stores, setStores ] = useState([]);

    useEffect(() => {
        if (enhancedLoading) return;
        if (enhancedProducts[0]?.stores) {
            setStores(enhancedProducts[0].stores)
        }
        
    }, [enhancedLoading])

    const storeColumns = [
        {
            accessor: 'name',
            Header: 'Name'
        },
        {
            accessor: 'price',
            Header: 'Price',
            Cell: (rowData) => {
                const { price, sale_price, currency_symbol } = rowData.row.original;
                return (
                    !sale_price ? <span>{currency_symbol} {price}</span> : <span><del>{currency_symbol} {price}</del> <span className='text-success'>{currency_symbol} {sale_price}</span></span>
                )
            }
        },
        {
            accessor: 'last_update',
            Header: 'Last Checked',
            Cell: (rowData) => {
                const { last_update } = rowData.row.original;
                const dateString = new Date(last_update).toLocaleDateString();
                return (
                    <span>{dateString}</span>
                )
            }

        },
        {
            accessor: 'country',
            Header: 'Country'
        },
        {
            accessor: 'link',
            Header: 'Visit',
            Cell: (rowData) => {
                const { link } = rowData.row.original;
                return (
                    <a href={link} target="_blank" rel="noreferrer">Visit</a>
                )
            }
        },
    ];
      
    return (
        <>
            {enhancedLoading ? <p>Loading...</p> : 
              enhancedProducts[0]?.stores ?
                <AdvanceTableWrapper
                columns={storeColumns}
                data={stores}
                sortable
                pagination
                perPage={15}
            >
                <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                    bordered: true,
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                }}
                />
                <div className="mt-3">
                <AdvanceTableFooter
                    rowCount={stores.length}
                    table
                    rowInfo
                    navButtons
                    rowsPerPageSelection
                />
                </div>
            </AdvanceTableWrapper>
            : <p>No competition data found</p>
        }
        </>
    )
      

}