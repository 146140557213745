import React, { useState, useEffect } from "react";
import { db, auth, functions } from "config/firebase";
import { Card, Tabs, Tab, Alert, Button } from "react-bootstrap";
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import AdvanceTableSearchBox from "../advance-table/AdvanceTableSearchBox";
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import PageHeader from 'components/common/PageHeader';
import { collection, query, orderBy, where, limit, Timestamp } from "firebase/firestore";
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTruckMoving, faEye } from '@fortawesome/free-solid-svg-icons'
import { faStopCircle as faHands} from '@fortawesome/free-regular-svg-icons'
import { toast } from 'react-toastify';

const OrderReconciliation = () => {
    
    
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [resultsPerPage, setResultsPerPage] = useState(10);
    const [user, authLoading] = useAuthState(auth);
    const navigate = useNavigate();
    const [noHoldOrders, setNoHoldOrders] = useState();
  
    const [putOrderOnHold, holdLoading, holdError] = useHttpsCallable(functions, "placeOrderOnFulfillmentHold");
    
    const [orders, ordersLoading, ordersError] = useCollectionData(
        query(
            collection(db, "fulfillments"),
            where("createTimestamp", ">=", new Timestamp.fromMillis(fromDate.getTime())),
            where("createTimestamp", "<=", new Timestamp.fromMillis(toDate.getTime())),
            orderBy("createTimestamp", "desc")
        ), {
            snapshotListenOptions: { includeMetadataChanges: false },
        }
    )

    const [settlements, settlementsLoading, settlementsError] = useCollectionData(
        query(
            collection(db, "reconciliationReports")
        ), {
            snapshotListenOptions: { includeMetadataChanges: false },
        }
    )

    const [ordersOnHold, ordersOnHoldLoading, ordersOnHoldError] = useCollectionData(
        query(
            collection(db, "fulfillments"),
            where("onFulfillmentHold", "==", true)
        ), {
            snapshotListenOptions: { includeMetadataChanges: false },
        }
    )

    useEffect(() => {
        if (authLoading) return;
        if (!user) return navigate('/');

        // if its before 2:00 PM then it shows today's fulfilments
        // after 2:00PM it's tomorrows. 

        const rightNow = new Date();
        const searchFromDate = new Date();
        const searchToDate = new Date();
        if(rightNow.getHours() < 14) {
            searchFromDate.setDate(rightNow.getDate() - 1);
            searchToDate.setHours(14, 0, 0, 0);
        } else {
            searchFromDate.setDate(rightNow.getDate());
            searchFromDate.setHours(14, 0, 0, 0);
            searchToDate.setDate(rightNow.getDate() + 1);
            searchToDate.setHours(14, 0, 0, 0);
        }
        
        //filter out held orders
        if(!ordersLoading) {
            let temp = orders.filter(order => order.onFulfillmentHold != true);
            setNoHoldOrders(temp)
        }

        setFromDate(searchFromDate);
        setToDate(searchToDate);
    
    }, [authLoading, user])

    async function handleHoldClick(e, shipmentId) {
        e.preventDefault();
        try {
         await putOrderOnHold({shipmentId: shipmentId});
         
         toast.warn(`Shipment ${shipmentId} placed on hold`, {theme: "colored"});
        } catch (error) {
          toast.error(error.message, {theme: "colored"} )
        }
    }

    async function handleResumeClick(e, shipmentId, resume=true) {
        e.preventDefault();
        try {
         await putOrderOnHold({shipmentId: shipmentId, resume: resume});
         toast.success(`Shipment ${shipmentId} resumed`, {theme: "colored"});
        } catch (error) {
          toast.error(error.message, {theme: "colored"} )
        }
    }

    const openOrderColumns = [
        {
          accessor: 'orderNumber',
          Header: 'Order Number',
          Cell: (data) => {
            const {
                orderId,
                orderNumber
            } = data.row.original

            return <a href={`orders/${orderId}`}>{orderNumber}</a>
          }
        },
        {
            accessor: 'shipTo.name',
            Header: 'Name',
            Cell: (data) => {
              const {
                  shipTo,
              
              } = data.row.original
  
              return <span>{shipTo.name}</span>
            }
          },
          {
            accessor: 'shipTo.city',
            Header: 'City',
            Cell: (data) => {
              const {
                  shipTo,
                  
              } = data.row.original
  
              return <span>{shipTo.city}</span>
            }
          },
          {
            accessor: 'shipTo.state',
            Header: 'State',
            Cell: (data) => {
              const {
                  shipTo,
              
              } = data.row.original
  
              return <span>{shipTo.state}</span>
            }
          },
          {
            accessor: 'shipTo.country',
            Header: 'Country',
            Cell: (data) => {
              const {
                  shipTo,
              
              } = data.row.original
  
              return <span>{shipTo.country}</span>
            }
          },
          {
            accessor: '',
            Header: 'Quick Action',
            Cell: (data) => {
              const {
                  orderKey,
                  shipmentId,
                  onFulfillmentHold
              } = data.row.original
                return (
                <>
                    {!onFulfillmentHold ? 
                        <Button key={orderKey} variant='falcon-danger'
                            onClick={async (e) => {

                                await handleHoldClick(e, shipmentId);
                                
                                
                            }}
                            disabled={holdLoading}
                        >
                            <FontAwesomeIcon icon={faHands} /> Hold Order
                        </Button>
                    : <Button variant="falcon-primary"
                        onClick={async (e) => {

                            await handleResumeClick(e, shipmentId);
                        

                        }}
                        disabled={holdLoading}
                        ><FontAwesomeIcon icon={faTruckFast} /> Resume Order</Button> }
                </>)
              
            }
          },
    ];

    const settlementsColumns = [
        {
          accessor: 'reportTime.seconds',
          Header: 'Date & Time',
          Cell: (data) => {
            const { reportTime } = data.row.original;
            return <span>{reportTime.toDate().toLocaleString()}</span>
          }
        },
        {
            accessor: 'uploadStatus',
            Header: 'IBMi Sync Status',
            
        },
        {
            accessor: 'totalRawOrders',
            Header: 'Total Orders in Batch',
            
        },
        {
            accessor: 'Condensed Raw Item Orders',
            Header: 'Total Items',
            
        },
        {
            accessor: 'Post-initial Processing',
            Header: 'After Processing',
            
        },
        {
            accessor: 'Total Bundle Items',
            Header: '# Bundles',
            
        },
        {
            accessor: 'IBMi Order Items',
            Header: 'IBMi Order Items',
            
        },
        {
            accessor: 'finalOrder.length',
            Header: 'Final Order',
            Cell: (data) => {
                const {finalOrder} = data.row.original;
                return <Button size='sm'><FontAwesomeIcon icon={faEye} />{" "} View Order</Button>

            }
            
        },
        
    ];


    const onHoldColumns = [
        {
          accessor: 'orderNumber',
          Header: 'Order Number',
          Cell: (data) => {
            const {
                orderId,
                orderNumber
            } = data.row.original

            return <a href={`orders/${orderId}`}>{orderNumber}</a>
          }
        },
        {
            accessor: 'shipTo.name',
            Header: 'Name',
            Cell: (data) => {
              const {
                  shipTo,
              
              } = data.row.original
  
              return <span>{shipTo.name}</span>
            }
          },
          {
            accessor: 'shipTo.city',
            Header: 'City',
            Cell: (data) => {
              const {
                  shipTo,
                  
              } = data.row.original
  
              return <span>{shipTo.city}</span>
            }
          },
          {
            accessor: 'shipTo.state',
            Header: 'State',
            Cell: (data) => {
              const {
                  shipTo,
              
              } = data.row.original
  
              return <span>{shipTo.state}</span>
            }
          },
          {
            accessor: 'shipTo.country',
            Header: 'Country',
            Cell: (data) => {
              const {
                  shipTo,
              
              } = data.row.original
  
              return <span>{shipTo.country}</span>
            }
          },
          {
            accessor: '',
            Header: 'Quick Action',
            Cell: (data) => {
              const {
                  orderKey,
                  shipmentId,
                  onFulfillmentHold
              } = data.row.original
                return (
                <>
                    {!onFulfillmentHold ? 
                        <Button key={orderKey} variant='falcon-danger'
                            onClick={async (e) => {

                                await handleHoldClick(e, shipmentId);
                                
                                
                            }}
                            disabled={holdLoading}
                        >
                            <FontAwesomeIcon icon={faHand} /> Hold Order
                        </Button>
                    : <Button variant="falcon-primary"
                        onClick={async (e) => {

                            await handleResumeClick(e, shipmentId);
                        

                        }}
                        disabled={holdLoading}
                        ><FontAwesomeIcon icon={faTruckFast} /> Resume Order</Button> }
                </>)
              
            }
          },
    ];

    return !ordersLoading && !settlementsLoading ? (
      <>
        <PageHeader
            title="IBMi Order Reconciliation" titleTag="h5" className="mb-3">
            <div className='mt-4'>

            Orders with shipping labels assigned in ShipStation will populate here daily. 
            Fulfilments are tracked daily from yesterday's shipping cut-off until today's shipping cut-off. 
            The current cut-off is 14:00 ET. On Mondays fulfillments are pulled from 14:00 ET of the preceeding Friday (3 Days).
            If for any reason an order should not be settled/reconciled with Topicz-IBMi it should be held by pressing the 'Hold' button.
            Held orders will accumulate under the "All On Hold Orders" tab.
            View previously submitted settlement/reconciliation orders under the "Submitted IBMi Orders" tab.
            
            </div>
        </PageHeader>
        <Card className="mb-3 p-3">
        <Tabs variant='pills' defaultActiveKey="openOrder" id="uncontrolled-tab-example" className='mb-3'>

            <Tab eventKey="openOrder" title="Today's Open Orders" className='p-3'>
               {orders.length > 0 ? 
                <AdvanceTableWrapper
                columns={openOrderColumns}
                data={orders}
                sortable
                pagination
                perPage={10}
                >   
                    <div className="mb-3">
                    <AdvanceTableSearchBox className="mb-3" table/>
                    <p></p>
                        <AdvanceTableFooter
                        rowCount={orders.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                        />
                    </div>
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                        bordered: true,
                        striped: true,
                        className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                    <div className="mt-3">
                        <AdvanceTableFooter
                        rowCount={orders.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                        />
                    </div>
                </AdvanceTableWrapper>
                : 
                <Alert variant="danger">
                    <p>The current time is {new Date().toTimeString()}. The shipping cutoff is 14:00:00 GMT-5:00</p>
                    <p>Searching from {fromDate.toLocaleString()}  to {toDate.toLocaleString()} there were {orders.length} fulfilment orders found in the Fulfillments collection.  </p>
                    
                   
                </Alert>}
            </Tab>

            <Tab eventKey="onHold" title="All On Hold Orders" className='p-3'>
                {ordersOnHold.length > 0 ? 
                    <AdvanceTableWrapper
                    columns={onHoldColumns}
                    data={ordersOnHold}
                    sortable
                    pagination
                    perPage={resultsPerPage}
                    >
                        <div className="">
                            <AdvanceTableSearchBox className="mb-3" table/>
                            <p></p>
                            <AdvanceTableFooter
                            rowCount={ordersOnHold.length}
                            table
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                            />
                        </div>
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                            bordered: true,
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                        <div className="mt-3">
                            <AdvanceTableFooter
                            rowCount={ordersOnHold.length}
                            table
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                            />
                        </div>
                    </AdvanceTableWrapper>
                    :
                    <Alert variant="secondary">
                            <h6>No Orders On Hold</h6>
                    </Alert>} 
            </Tab>

            <Tab eventKey="previous" title="Submitted IBMi Orders" className='p-3'>
                <AdvanceTableWrapper
                columns={settlementsColumns}
                data={settlements}
                sortable
                pagination
                perPage={resultsPerPage}
                >
                    <div className="">
                        <AdvanceTableSearchBox className="mb-3" table/>
                        <p></p>
                        <AdvanceTableFooter
                        rowCount={settlements.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                        />
                    </div>
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                        bordered: true,
                        striped: true,
                        className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                    <div className="mt-3">
                        <AdvanceTableFooter
                        rowCount={settlements.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                        />
                    </div>
                </AdvanceTableWrapper>
            </Tab>


        </Tabs>
        </Card>
      </>
    ) : (
        <h1>loading...</h1>
    );
};

export default OrderReconciliation;