import React, { useContext, useEffect, useState } from 'react';
import { Dropdown } from "react-bootstrap"
import Flex from 'components/common/Flex';
import { ActionsContext } from 'context/MenuBarActionsContext';

export const MenuBar = ({ gridRef }) => {


    const CreateMenu = ({ name, actions, bold }) => {
        return (
            <div className='ms-0 me-3'>
            <Dropdown >
                <Dropdown.Toggle as={MenuToggle} id={`${String(name).substring(0,3)}-DropdownToggle`} >
                    <span className={bold ? 'fw-bold text-white' : 'text-white'}>{String(name)}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu as={Menu}>
                    {Object.entries(actions).map(([label, {actionType, payload}]) => (
                        <Dropdown.Item key={actionType} onClick={createHandler(actionType, payload)}>
                            {label}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
            </div>
            
        )
    }
    const { handleAction } = useContext(ActionsContext);
    const createHandler = (actionType, payload) => () => {
        handleAction(actionType, payload);   
    }
    const productsActions = {
        'New Workspace': {
            actionType: 'NEW_WORKSPACE', 
            payload: 'whoa'
        },
        'Open Workspace': {
            actionType: 'OPEN_WORKSPACE', 
            payload: 'whoa'
        },
        'Manage Workspaces': {
            actionType: 'OPEN_WORKSPACE',
            payload: 'whoa2'
        },
    };

    let copyLabel = 'Copy';
    let pasteLabel = 'Paste';
    const editActions = {
        [copyLabel]: {
            actionType: 'COPY', 
            payload: 'whoa'
        },
        [pasteLabel]: {
            actionType: 'PASTE', 
            payload: 'whoa'
        },
        'Add or Remove Tags': { 
            actionType: 'TAGS',
            payload: 'whoa3'
        },
        'Recalculate Pricing': {
            actionType: 'REPRICE',
            payload: 'whoa2'
        },
    }

    const templatesActions = {
        'Assign Pricing Template': {
            actionType: 'ASSIGN__PRICING_TEMPLATE', 
            payload: 'whoa'
        },
        'Manage Pricing Templates': {
            actionType: 'MANAGE_PRICING_TEMPLATES', // TODO
            payload: 'whoa2'
        },
        'Assign Shipping Template': {
            actionType: 'ASSIGN_SHIPPING_TEMPLATE', 
            payload: 'whoa'
        },
        'Manage Shipping Template': {
            actionType: 'MANAGE_SHIPPING_TEMPLATES', // TODO
            payload: 'whoa2'
        },
    }

    const intelActions = {
        'ASIN Discovery': {
            actionType: 'DISCOVER_ASINS', 
        },
        'Estimate Amazon Fees': {
            actionType: 'ESTIMATE_AMAZON_FEES',
        },
    }

    const listingsActions = {
        'Check Readiness': {
            actionType: 'LISTING_READINESS_CHECK',
        }
    };

    return (
        <Flex >
                <CreateMenu name={"Products"} actions={productsActions} bold/>
                <CreateMenu name={"Edit"} actions={editActions} />
                <CreateMenu name={"Templates"} actions={templatesActions} />
                <CreateMenu name={"Listings"} actions={listingsActions} />
                <CreateMenu name={"Admin"} actions={intelActions} />
        </Flex>
    )
}

const MenuToggle = React.forwardRef(( { children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
        className='fs--1 fw-semi-bold text-800 ms-2'

    >
        {children}
    </a>
))

const Menu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');
        return (
            <div
                ref={ref}
                className={className}
                aria-labelledby={labeledBy}
                style={{ ...style }}
            >
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        child =>
                            !value || child.props.children.toLowerCase().startsWith(value),
                    )}
                </ul>
            </div>
        )
    }
)
