import { useState, useContext, useEffect, useRef } from 'react';
import { db } from "config/firebase";
import { useNavigate } from 'react-router-dom';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import PageHeader from 'components/common/PageHeader';
import { Card, Row, Col, Button, Badge, Form } from 'react-bootstrap';
import Lottie from 'lottie-react';
import dotsAnimation from 'assets/lottie/dots.json';
import { useCollection } from 'react-firebase-hooks/firestore';
import { collection, query, where, getDocs, doc, getDoc, updateDoc } from 'firebase/firestore';
import AdvanceTableFooter from 'components/merlin/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/merlin/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import { toast } from 'react-toastify';

const FullfillmentSettings = ({ children }) => {

    const loading = false

    const CarrierSpecificMarkup = () => {
        return (
            <Card className="mb-3 p-3">
                <Card.Header>Carrier Specific Surchages & Markup</Card.Header>    
            
            </Card>
            
        )
    }

    const StandardPackageTypes = () => {

        const [packageTypes, setPackageTypes] = useState([]);
        const [currentDefaultPackage, setCurrentDefaultPackage] = useState(null);
        
        async function getPackageTypes() {
            try {
                const q = query(collection(db, "packageTypes"));
                const snapshot = await getDocs(q);
                if (snapshot.empty) {
                    console.log('No matching documents.');
                    return;
                }
                const packageTypes = [];
                snapshot.forEach(doc => {
                    packageTypes.push(doc.data());
                });
                setPackageTypes(packageTypes);
            } catch (error) {
                console.error(error);
            }
        }

        async function getDefaultPackageType() {
            try {
                const docRef = doc(db, "repricerSettings", "defaultPackageType");
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setCurrentDefaultPackage(docSnap.data().value);
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error(error);
            }
        }

        async function saveDefaultPackageType(e) {
            e.preventDefault();
            try {
                const packageTypeSelection = document.getElementById('packageTypeSelection');
                const packageTypeName = packageTypeSelection.value;
                if (packageTypeName === 'default') {
                    toast.error('Please select a package type', {theme: 'colored'});
                    return;
                } else {
                    const packageType = packageTypes.find(packageType => packageType.name === packageTypeName);
                    const docRef = doc(db, "repricerSettings", "defaultPackageType");
                    await updateDoc(docRef, {
                        value: packageType,
                        timestamp: new Date(),
                    });
                    toast.success('Default Package Type Updated', {theme: 'colored'});
                }
            } catch (error) {
                console.error(error);
            }
        }

        useEffect(() => {
            let isSubbed = true;
            if (isSubbed) {
                getPackageTypes();
                getDefaultPackageType();
            }
            return () => isSubbed = false;
        }, []);

        return (
            <Card className="mb-3 p-3">
                <Card.Header>Default Package</Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            <Form.Control
                                as="select"
                                id={"packageTypeSelection"}
                                onChange={(e) => saveDefaultPackageType(e)}
                            >
                                <option value={"default"}>{currentDefaultPackage ? currentDefaultPackage.name : "Choose..."}</option>
                                {packageTypes.length > 0 && packageTypes.map((packageType, index) => (
                                    <option key={index} value={packageType.name}>{packageType.name} - ${packageType.cost}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        )
    }

    const DimensionalAdjustmentForEstimates = () => {
        return (
            <Card className="mb-3 p-3">
                <Card.Header>Dimensional Adjustments for Estimation</Card.Header>    
            </Card>
        )
    }

    return (
        !loading ?
        <>
            <PageHeader title="Fulfillment Settings" className="mb-3">
            </PageHeader>
            <StandardPackageTypes />
            <DimensionalAdjustmentForEstimates />
            <CarrierSpecificMarkup />
        </>
        :
        <Lottie animationData={dotsAnimation} style={{width: 100, height: 100}} />
    );
}

export default FullfillmentSettings;