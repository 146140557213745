import PageHeader from "components/common/PageHeader";
import { Card, Container, Row, Col, Form, Button, Image, Modal, Table } from "react-bootstrap";
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from 'components/merlin/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from "components/merlin/advance-table/AdvanceTableSearchBox";
import IconButton from 'components/common/IconButton';
import SoftBadge from "components/common/SoftBadge";
import Flex from "components/common/Flex";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { db } from "config/firebase";
import { addDoc, collection, doc, setDoc, where, query, getDocs, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import Lottie from "lottie-react";
import dotsAnimation from 'assets/lottie/dots.json';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

export default function LaunchPad() {

  const navigate = useNavigate();
  const [products, loading, error] = useCollectionData(query(
    collection(db, 'launchPad'),
    where('launchStatus', '!=', 'launched')
  ));
  
  const [productsForPromotion, setProductsForPromotion] = useState([]);
  const [showPromotionModal, setShowPromotionModal] = useState(false);
  
  function LaunchPadProductTable({ products }) {

    const [columns, setColumns] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);

    function handleBulkAction(e, selectedRowIds, rows) {
      e.preventDefault();
      const option = document.getElementById('bulkActionOptionSelect').value;
      const selectedRows = rows.filter((row) => selectedRowIds[row.rowId]);

      switch (option) {
        case 'promote':
          setProductsForPromotion(selectedRows);
          setShowPromotionModal(true);
          break;
        case 'delete':
          console.log('delete');
          break;
        case 'contact':
          console.log('contact');
          break;
        default:
          break;
      }

    }

    function BulAction(table){
      const { selectedRowIds, page } = table;
      const rows = page.map((row) => {
        let temp = row.original;
        temp.rowId = row.id;
        return temp
      });

      return (
        <Row className="flex-between-center mb-3">
          <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              {
                Object.keys(selectedRowIds).length > 0 ?
                'You have selected ' + Object.keys(selectedRowIds).length + ' products' 
                :
                ''
              }
            </h5>
          </Col>
          <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
            {Object.keys(selectedRowIds).length > 0 ? (
              <div className="d-flex">
                <Form.Select size="sm" aria-label="Bulk actions" id={'bulkActionOptionSelect'}>
                  <option>Bulk Actions</option>
                  <option value="promote">Promote To Catalog</option>
                  <option value="contact">Contact Vendor</option>
                  <option value="delete">Archive Product</option>
                </Form.Select>
                <Button
                  type="button"
                  variant="falcon-default"
                  size="sm"
                  className="ms-2"
                  onClick={(e) => handleBulkAction(e, selectedRowIds, rows)}
                >
                  Apply
                </Button>
              </div>
              ) : (
                <div id="orders-actions">
          
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="external-link-alt"
                    transform="shrink-3"
                    className="float-end"
                  >
                    <span className="d-none d-sm-inline-block ms-1">Export</span>
                  </IconButton>
                </div>
              )}
          </Col>
        </Row>
      );
    };

    // loop over products and grab each product's attributes/properties
    // then create a collated & unique list of attributes/properties
    function grabColumnPropertiesFromProducts(products) {
      const properties = [];
      products.forEach(product => {
        const productProperties = Object.keys(product);
        productProperties.forEach(property => {
          if (!properties.includes(property)) {
            properties.push(property);
          }
        })
      })
      // ensure the properties are globally unique
      const uniqueProperties = [...new Set(properties)];
      return uniqueProperties;
    }

    function buildLaunchPadColumns(colName) {
      const columns = [];
      colName.forEach(name => {
        switch (name) {
          case 'image':
            columns.push({
              accessor: 'image',
              Header: 'Image',
              Cell: rowData => {
                const { image } = rowData.row.original;
                return (
                  <Image src={image} width={50} />
                )
              }
            })
            break;
          default:
            columns.push({
              accessor: name,
              Header: name,
            })
            break;
        }
      })

      // reorder columns so that merlinVendor is first, then Image, then the resdt
      /*
      const merlinVendorIndex = columns.findIndex(column => column.accessor === 'merlinVendor');
      const imageIndex = columns.findIndex(column => column.accessor === 'image');
      const merlinVendor = columns.splice(merlinVendorIndex, 1);
      const image = columns.splice(imageIndex, 1);
      columns.unshift(merlinVendor[0]);
      columns.unshift(image[0]);
      */

      return columns;
    }

    useEffect(() => {
      if (loading) return;
      if (products.length > 0) {
        const colProp = grabColumnPropertiesFromProducts(products);
        const columns = buildLaunchPadColumns(colProp);
        setColumns(columns);
        const f = products.filter(product => product !== undefined);
        setFilteredProducts(f);
        return products;

        // return grabColumnPropertiesFromProducts(products).then(colNames => {
        //   const columns = buildLaunchPadColumns(colNames);
        //   setColumns(columns)
        //   const f = products.filter(product => product !== undefined);
        //   setFilteredProducts(f);
        //   return products;
        // });
      }
      
    }, [products]);



    return (
      <AdvanceTableWrapper
        columns={columns}
        data={filteredProducts}
        sortable
        pagination
        perPage={10}
        selection
        selectionColumnWidth={30}
      >
        <div className="mt-3">
            <BulAction table/>
        </div>
        <Row className="flex-end-center mb-3">
          <Col xs="auto" sm={12} lg={12}>
            <AdvanceTableSearchBox table/>
          </Col>
        </Row>
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3">
        <AdvanceTableFooter
          rowCount={products.length}
          table
          rowInfo
          navButtons
          rowsPerPageSelection
        />
      </div>
      </AdvanceTableWrapper>
    )
  }

  function PromoteProductModal({ selectedProducts, show, setShow }) {

    const [promoteState, setPromoteState] = useState('initial');
    const [promotedProducts, setPromotedProducts] = useState([]);

    function generateMerlinSku(vendorCode){
      // todo - needs to be globally unique and not just random
      const sku = Math.floor(Math.random() * 1000000000);
      const finalSku = `${vendorCode}-${sku}`;
      return finalSku;
    }

    const ProductPromotionTable = ({ products }) => {

      const processedProducts = products.map(product => {
        let temp = product;
        const vendor = product.merlinVendor;
        // take the first three letters of vendor
        const vendorCode = vendor.substring(0, 3).toUpperCase();
        temp.newSku = generateMerlinSku(vendorCode);
        return temp;
      })

      return (
        <Table responsive className="mb-0">
          <thead className="bg-200 text-900">
            <tr>
              <th className="align-middle white-space-nowrap">Product Name</th>
              <th className="align-middle white-space-nowrap">Supplier SKU</th>
              <th className="align-middle white-space-nowrap">Merlin SKU</th>
            </tr>
          </thead>
          <tbody>
            {processedProducts.map(product => {
              const { name, merlinVendor, cost, sku, newSku } = product;
              return (
                <tr key={sku}>
                  <td className="align-middle white-space-nowrap">{name}</td>
                  <td className="align-middle white-space-nowrap">{sku}</td>
                  <td className="align-middle white-space-nowrap">{newSku}</td>
                </tr>
              )
            }
            )}
          </tbody>
        </Table>
      )
    }

    async function updateLaunchStatus(vendor, vendorSku) {
      const q = query(
        collection(db, "launchPad"),
        where("merlinVendor", "==", vendor),
        where("sku", "==", vendorSku),
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const launchPadRef = doc.ref;
        updateDoc(launchPadRef, {
          launchStatus: 'launched'
        })
      });
    }

    async function promoteProductsToMerlin(e, products) {
      e.preventDefault();
      try {
        const promoted = [];
        setPromoteState(() => 'processing');
        for (let i = 0; i < products.length; i++) {
          const product = products[i];
          const newProductRef = doc(collection(db, 'productCatalog/topicz/products'));
          const tags = product.tags;
          const tagArray = tags.split(',').map(tag => tag.trim().toLowerCase());

          const initialVariantRef = doc(collection(db, 'productCatalog/topicz/products', newProductRef.id, 'variants'));

          const files = [];
          files.push({
            alt: product.name,
            src: product.image,
            id: product.newSku
          })

          const newProduct = {
            cost: product.cost,
            ecomm_onhand: 0,
            primaryImage: product.image,
            launchPad: true,
            vendor: product.merlinVendor,
            timestamp: {
              created: new Date(),
              modified: new Date(),
            },
            files: files,
            name: product.name,
            sku: product.newSku,
            launchPadData: {
              vendorSku: product.sku,
              brand: product.brand,
              description: product.description,
            },
            tags: tagArray,
            productUrl: `/topicz/products/${newProductRef.id}`,
            isInStock: false,
            id: newProductRef.id,
          }

          const newVariant = {
            sku: product.newSku,
            productFactor: 1,
            parentRef: newProductRef,
            ref: initialVariantRef,
            parentSku: product.newSku,
            title: product.name,
            id: product.newSku
          }

          await setDoc(newProductRef, newProduct);
          await setDoc(initialVariantRef, newVariant);
          // await updateLaunchStatus(product.merlinVendor, product.sku);
          promoted.push({ name: newProduct.name, sku: newProduct.sku, url: newProduct.productUrl, image: newProduct.primaryImage});

        }
        setPromotedProducts(() => promoted);
        setPromoteState(() => 'success');
        return 'Promoted to Merlin'
      } catch (error) {
        console.log(error);
      }
    }

  
    return (
      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
        fullscreen
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">Promote Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {promoteState === 'initial' && <ProductPromotionTable products={selectedProducts} />}
          {promoteState === 'processing' && <div>Processing...</div>}
          {promoteState === 'success' && (
            <div className="">
              <div className="mb-3">
                The following products have been promoted:
              </div>
              <div>
                {promotedProducts.map(product => {
                  return (
                    <ul key={`ul-${product.newSku}`}><a onClick={() => navigate(product.url)}>{product.sku} - {product.name}</a></ul>
                  )
                })}
              </div>
            </div>  

          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => promoteProductsToMerlin(e, selectedProducts )}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  return (
    <>
        <PageHeader
                title="Product Launch Pad" titleTag="h5" className="mb-3"
        >

        </PageHeader>
        {!loading ?

          <Card className="p-3">
            <LaunchPadProductTable  products={products}/>
          </Card>
        :
          <div><Lottie animationData={dotsAnimation} style={{height: 300}} /></div>
        }
        <PromoteProductModal selectedProducts={productsForPromotion} show={showPromotionModal} setShow={setShowPromotionModal}/>
    </>
  )
}