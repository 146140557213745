import React from 'react';
import ReactDOM from 'react-dom';
// import { createRoot } from 'react-dom/client';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import ErrorBoundary from './boundaries/ErrorBoundary';


ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
    <Main>
      <App />
    </Main>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('main')
);


/*
const container = document.getElementById('main');
const root = createRoot(container);
root.render(
    <React.StrictMode>
      <Main>
        <App />
      </Main>
    </React.StrictMode>
);
*/