import { useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { db } from 'config/firebase';
import { toast } from 'react-toastify';
import { updateDoc, getDocs, collection, where } from 'firebase/firestore';

const RemoveFromBatch = ({ show, handleClose, data }) => {
    const [removing, setRemoving] = useState(false);
    const [modifiedBatches, setModifiedBatches] = useState([]);

    async function removeOrderFromBatch(order) {
        if (!order) {
            toast.error('No order provided');
        }
        if (!order.assignedToBatch) {
            return { error: true, message: 'Order not assigned to a batch' };
        }
        const mbn = order.merlinBatchName;
        if (mbn) {
            setModifiedBatches(prev => [...prev, mbn]);
        }
        const orderRef = order.ref;
        const orderData = order;
        orderData.assignedToBatch = false;
        orderData.merlinBatchName = '';
        await updateDoc(orderRef, orderData);
        return true;
    }

    async function reduceBatchOrderCounts(batchesToUpdate) {
        for (let i = 0; i < batchesToUpdate.length; i++) {
            const batch = batchesToUpdate[i];
            const batchSnapshot = await getDocs(
                collection(db, 'batches'),
                where('orderBatchName', '==', batch.batch)
            );
            console.log("rboc", batch)
            batchSnapshot.forEach(doc => {
                const batchData = doc.data();
                const batchRef = doc.ref;
                batchData.numOrders -= batch.count;
                updateDoc(batchRef, batchData);
            });
        }
    }

    function consolidateBatches() {
        // count the number of times each batch appears in the modifiedBatches array
        const counts = {};
        for (let i = 0; i < modifiedBatches.length; i++) {
            const batch = modifiedBatches[i];
            counts[batch] = counts[batch] ? counts[batch] + 1 : 1;
        }
        // remove duplicates
        const uniqueBatches = [...new Set(modifiedBatches)];
        // create a new array of objects with the batch name and the count
        const finalBatches = uniqueBatches.map(batch => {
            return { batch, count: counts[batch] };
        });
        return finalBatches;
    }

    async function handleRemove() {
        setRemoving(true);
        const removePromises = [];
        for (let i = 0; i < data.length; i++) {
            const order = data[i];
            removePromises.push(removeOrderFromBatch(order));
        }
        const results = await Promise.all(removePromises);
        const errorCheck = results.filter(result => result.error);
        if (errorCheck.length > 0) {
            for (let i = 0; i < errorCheck.length; i++) {
                toast.error(`Error removing order from batch: ${errorCheck[i].message}`);
            }
            return;
        }
        const batchesToUpdate = consolidateBatches();
        await reduceBatchOrderCounts(batchesToUpdate);
        setRemoving(false);
        toast.success('Orders removed from batch');
        handleClose();
    }

    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Remove from Batch</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <p>Are you sure you want to remove the following orders from the batch?</p>
                    <ul>
                        {data.map((order, index) => {
                            return (
                                <li key={index}>{order.orderNumber}</li>
                            )
                        })}
                    </ul>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="danger" onClick={handleRemove} disabled={removing}>
                    {removing ? <Spinner animation="border" size="sm" /> : 'Remove'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default RemoveFromBatch;