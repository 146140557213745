import { useState, useContext, useEffect, useReducer, useRef } from 'react';
import { db } from "config/firebase";
import { useCollection } from 'react-firebase-hooks/firestore';
import { query, collection, where, orderBy, updateDoc, addDoc, deleteDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import PageHeader from 'components/common/PageHeader';
import { Card, Row, Col, Button, Form, Tabs, Tab, Image, Modal } from 'react-bootstrap';
import Lottie from 'lottie-react';
import dotsAnimation from 'assets/lottie/dots.json';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import SoftBadge from 'components/common/SoftBadge';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { toast } from 'react-toastify';
import IconButton from 'components/common/IconButton';

const BatchAutomations = ({ children }) => {

    const { user, authLoading }= useContext(FirebaseAuthContext);
    const navigate = useNavigate();
    const [loadingState, setLoadingState] = useState(false);
    const [orderRange, setOrderRange] = useState(100);
    const [showAddRuleModal, setShowAddRuleModal] = useState(false);
    const [deleteExecuting, setDeleteExecuting] = useState(false);

    const [batchRulesRaw, batchRulesLoading, batchRulesError] = useCollection(
        query(
            collection(db, "batchAutomationRules"),
            orderBy("priority", "asc")
        )
    );
    const [batchRules, setBatchRules] = useState([]);
    useEffect(() => {
        if (batchRulesLoading) return;
        if (batchRulesError) {
            console.log(batchRulesError);
            return;
        }
        if (!batchRulesRaw) return;
        let rules = [];
        batchRulesRaw.forEach((doc) => {
            let temp = doc.data();
            temp.id = doc.id;
            temp.ref = doc.ref;
            rules.push(temp);
        });
        setBatchRules(rules);
    }, [batchRulesRaw, batchRulesLoading, batchRulesError]);
        
    // Redirect to home if not logged in
    useEffect(() => {
        if (authLoading) return;
        if (!user) return navigate('/');
      }, [authLoading, user])

    const colDef = [
        {
            accessor: 'customRuleName',
            Header: 'Rule Name',
            Cell: ({ cell }) => {
                const { 
                    customRuleName,
                    active,
                    ref
                } = cell.row.original;
                let loading = false;
                async function toggleActive() {
                    loading = true;
                    await updateDoc(ref, {
                        active: !active
                    });
                    loading = false;
                }
                return (
                    <Row>
                        <Col xs={3}>
                            <Form.Check 
                                type='switch'
                                id='checkedSwitch'
                                label={active ? 'Active' : 'Inactive'}
                                defaultChecked={active}
                                onChange={toggleActive}
                                disabled={loading}
                            />
                        </Col>
                        <Col>
                            <span className='text-monospace fw-bold'
                            >{customRuleName}
                            </span>
                        </Col>
                    </Row>
                )
            }
        },
        {
            accessor: 'subjectValue',
            Header: 'Rule Definition',
            Cell: ({ cell }) => {
                const {
                    subject,
                    subjectType,
                    condition,
                    subjectValue,
                } = cell.row.original;

                const SubjectTypeMap = ({ subject, subjectType }) => {

                    let SubjectDisplay;
                    switch (subject) {
                        case 'orderItem':
                            SubjectDisplay = <SoftBadge bg='primary' className='me-1'>Order Item</SoftBadge>;
                            break;
                        case 'Order':
                            SubjectDisplay = <SoftBadge bg='primary' className='me-1'>Order</SoftBadge>;
                            break;
                        default:
                            SubjectDisplay = <SoftBadge bg='danger' className='me-1'>None</SoftBadge>;
                            break;
                    }

                    let SubjectTypeDisplay;
                    switch (subjectType) {
                        case 'sku':
                            SubjectTypeDisplay = <SoftBadge bg='info' className='me-1'>SKU</SoftBadge>;
                            break;
                        case 'tagged':
                            SubjectTypeDisplay = <SoftBadge bg='info' className='me-1'>Tag</SoftBadge>;
                            break;
                    }

                    return (
                        <>
                            {SubjectDisplay} {SubjectTypeDisplay}
                        </>
                    )

                }

                const ConditionSubjectValueMap = ({ condition, subjectValue }) => {

                    let ConditionDisplay;
                    switch (condition) {
                        case 'equals':
                            ConditionDisplay = <SoftBadge bg='success' className='me-1'> Equals </SoftBadge>;
                            break;
                        default:
                            ConditionDisplay = <SoftBadge bg='danger' className='me-1'> None </SoftBadge>;
                            break;
                    }

                    let SubjectValueDisplay = <SoftBadge variant='info' className='me-1'>{subjectValue}</SoftBadge>;

                    return (
                        <>
                            {ConditionDisplay} {SubjectValueDisplay}
                        </>
                    )
                   
                }

                return (
                    <Row>
                        <Col>
                            <SubjectTypeMap subject={subject[0]} subjectType={subjectType[0]} />
                            <ConditionSubjectValueMap condition={condition[0]} subjectValue={subjectValue} />
                        </Col>
                    </Row>
                )
            }
        },
        {
            accessor: 'action',
            Header: 'Action',
            Cell: ({ cell }) => {
                const { action, subjectValue } = cell.row.original;
                let ActionDisplay;
                switch (action.execFunction) {
                    case 'assignToTaggedBatch':
                        ActionDisplay = <SoftBadge bg='info' className='me-1'>Assign to  ANY {subjectValue} Batch</SoftBadge>;
                        break;
                    case 'assignToNamedBatch':
                        ActionDisplay = <SoftBadge bg='info' className='me-1'>Assign to  THE {subjectValue} Batch</SoftBadge>;
                        break;
                    default:
                        break;
                }
                return (
                    <>
                        {ActionDisplay}
                    </>
                )
            }
        },
        {
            accessor: 'priority',
            Header: '',
            Cell: ({ cell }) => {
                return (
                    <Row>
                        <Col>
                            <IconButton
                                icon="trash-alt"
                                variant="falcon-default"
                                size="sm"
                                onClick={() => handleDelete(cell.row.original)}
                                className="m-1"
                            />
                        </Col>  
                    </Row>
                    
                )
            }
        }
    ];

    async function handleDelete(rule) {
        try {
            setDeleteExecuting(true);
            await deleteDoc(rule.ref);
            toast.success('Rule Deleted Successfully', { theme: 'colored' });
            setDeleteExecuting(false);
        } catch (error) {
            console.log(error);
            setDeleteExecuting(false);
            toast.error('Error Deleting Rule', { theme: 'colored' });
        }
    }

    function AddRuleModal({ show, setShow }) {

        const [saveExecuting, setSaveExecuting] = useState(false);
    
        const customRuleNameRef = useRef(null);
        const actionRef = useRef(null);

        const initializeState = () => ({
            customRuleName: '',
            subject: ['orderItem'],
            subjectType: ['tagged'],
            condition: ['equals'],
            subjectValue: '',
            action: {
                execFunction: 'assignToTaggedBatch',
                withValues: {
                    tag: '',
                    name: ''
                }
            },
            priority: 0,
            active: true,
            notes: ''
        })

        const newRuleReducer = (state, action) => {
            switch (action.type) {
                case 'HANDLE INPUT TEXT CHANGE':
                    return {
                        ...state,
                        [action.field]: action.payload
                    }
                case 'HANDLE SELECT CHANGE':
                    return {
                        ...state,
                        [action.field]: action.payload
                    }
                case "TOGGLE ACTIVE":
                    return {
                        ...state,
                        active: !state.active
                    }
                default:
                    return state;
            }
        }

        const [newRuleState, newRuleDispatch] = useReducer(newRuleReducer, {
        }, initializeState);

        const handleFormControlChange = (e) => {
            newRuleDispatch({
                type: "HANDLE INPUT TEXT CHANGE",
                field: e.target.name,
                payload: e.target.value
            })
        }

        const handleFormSelectChange = (e) => {
            newRuleDispatch({
                type: "HANDLE SELECT CHANGE",
                field: e.target.name,
                payload: [e.target.value]
            })
        }

        const handleActionValueChange = (e) => {
            switch (newRuleState.subjectType) {
                case 'tagged':
                    newRuleDispatch({
                        type: "HANDLE INPUT TEXT CHANGE",
                        field: 'action',
                        payload: {
                            execFunction: newRuleState.action.execFunction,
                            withValues:  {
                                tag: e.target.value
                            }
                        }
                    })
                    break;
                case 'sku':
                    newRuleDispatch({
                        type: "HANDLE INPUT TEXT CHANGE",
                        field: 'action',
                        payload: {
                            execFunction: newRuleState.action.execFunction,
                            withValues:  {
                                sku: e.target.value
                            }
                        }
                    })
                    break;
            }
        }

        const handleActionChange = (e) => {

            const test = actionRef.current.value;
            switch (test) {
                case 'Assign to Tagged Batch':
                    newRuleDispatch({
                        type: "HANDLE INPUT TEXT CHANGE",
                        field: 'action',
                        payload: {
                            execFunction: 'assignToTaggedBatch',
                            withValues: newRuleState.action.withValues
                        }
                    })
                    break;
                case 'Assign to Named Batch':
                    newRuleDispatch({
                        type: "HANDLE INPUT TEXT CHANGE",
                        field: 'action',
                        payload: {
                            execFunction: 'assignToNamedBatch',
                            withValues: newRuleState.action.withValues
                        }
                    })
                    break;
            }
        }

        const handleSave = async (e) => {
            e.preventDefault();
            setSaveExecuting(true);
            const newRule = {
                ...newRuleState,
                priority: parseInt(newRuleState.priority)
            }
            try {
                await addDoc(collection(db, "batchAutomationRules"), newRule);
                setSaveExecuting(false);
                // delay the closing of the modal by 1.4 sec
                setShow(false);
                toast.success('Rule Added Successfully', { theme: 'colored' });
            } catch (error) {
                console.log(error);
                setSaveExecuting(false);
            }
        }

        const handleClose = () => {
            setShow(false);
        }

      return (
        <>
          <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} fullscreen>
            <Modal.Header>
              <Modal.Title>Add New Batch Automation Rule</Modal.Title>
              <FalconCloseButton onClick={handleClose}/>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>Rule Name</Form.Label>
                    <Form.Control 
                        ref={customRuleNameRef}
                        type='text'
                        name='customRuleName'
                        placeholder='Enter Rule Name' 
                        onChange={(e) => handleFormControlChange(e)}
                        />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Rule Definition</Form.Label>
                    <Row>
                        <Col>
                            <Form.Select name='subject' onChange={(e) => handleFormSelectChange(e)}>
                                <option>Order Item</option>
                                <option>Order</option>
                            </Form.Select>
                        </Col>
                        <Col>
                            <Form.Select name='subjectType' onChange={(e) => handleFormSelectChange(e)}> 
                                <option>Tag</option>
                                <option>SKU</option>
                            </Form.Select>
                        </Col>
                        <Col>
                            <Form.Select name='condition' onChange={(e) => handleFormSelectChange(e)}>
                                <option>Equals</option>
                            </Form.Select>
                        </Col>
                        <Col>
                            <Form.Control type='text' placeholder='Enter Value'  name='subjectValue' onChange={(e) => handleFormControlChange(e)}/>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Action</Form.Label>
                    <Row>
                        <Col>
                            <Form.Select ref={actionRef} name='action' onChange={(e) => handleActionChange(e)}>
                                <option>Assign to Tagged Batch</option>
                                <option>Assign to Named Batch</option>
                            </Form.Select>
                        </Col>
                        <Col>
                            <Form.Control type='text' placeholder='Enter Value' name='actionValue' onChange={(e) => handleActionValueChange(e)}/>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Priority</Form.Label>
                    <Form.Control type='number' placeholder='Enter Priority' name='priority' onChange={(e) => handleFormControlChange(e)}/>
                </Form.Group>
                <Form.Group>
                    <Form.Check
                        type='switch'
                        id='checkedSwitch'
                        label='Active'
                        defaultChecked
                        name='active'
                        onChange={(e) => newRuleDispatch({ type: "TOGGLE ACTIVE"})}                    

                        />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Notes</Form.Label>
                    <Form.Control as='textarea' rows={3} placeholder='Enter Notes' name='notes' onChange={(e) => handleFormControlChange(e)}/>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
                <Button variant="falcon-primary" onClick={(e) => handleSave(e)} disabled={saveExecuting}>
                    Save Changes
                </Button>

            </Modal.Footer>
          </Modal>
        </>
      );
    }

    return (
        !loadingState ?
        <>
            <AddRuleModal show={showAddRuleModal} setShow={setShowAddRuleModal} />
            <Card className='mb-3'>
                <Card.Header>
                    <Row>
                        <Col>
                            <h5 className="m-0">Batch Automation Strategy</h5>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            <Form.Check 
                                type='switch'
                                id='checkedSwitch'
                                label='Simple Round Robin'
                                defaultChecked
                            />
                            <Form.Label className="text-muted">
                                Assigns orders as they are processed. Assignment occurs to any open batch with 'auto-assignment' enabled.
                            </Form.Label></Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label>Maximum Orders Per Batch: {orderRange}</Form.Label>
                            <Row><Col>
                                <div className='float-start'>10</div>
                                <div className='float-end'>500</div>
                            </Col></Row>
                            <Form.Range
                                min={10}
                                max={500}
                                step={10}
                                defaultValue={orderRange}
                                onChange={(e) => setOrderRange(e.target.value)}
                            />
                            <Form.Label className='text-muted'>
                                The maximum number of orders to assign to a batch. After this number is reached, a new batch will be created.
                            </Form.Label>
                        </Col>
                    </Row>
                    
                </Card.Body>
            </Card>
            <Card>
                <Card.Header>
                    <Row>
                        <Col>
                            <h5 className="m-0">Automation Rules</h5>
                        </Col>
                        <Col>
                            <div className='float-end'>
                                <Button variant='falcon-default' className='me-2' onClick={() => setShowAddRuleModal(true)}>Add Rule</Button>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    
                            <AdvanceTableWrapper
                                columns={colDef}
                                data={batchRules}
                                sortable
                                pagination
                                perPage={50}
                            >
                                <AdvanceTable
                                    table
                                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                                    rowClassName="align-middle white-space-nowrap"
                                    tableProps={{
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                    }}
                                />
                            </AdvanceTableWrapper>
                       
                </Card.Body>
            </Card>
        </>
        :
        <Lottie animationData={dotsAnimation} style={{width: 100, height: 100}} />
    );
}

export default BatchAutomations;