import React, { useState, useEffect } from 'react';
import { Card, Form, ListGroup, Image } from 'react-bootstrap';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { db } from 'config/firebase';
import MissingImage from '../../../../assets/merlin/missing_image.png';
import * as fuzz from 'fuzzball'
import { collection } from 'firebase/firestore';

const MicroSearchBox = ({ setSelectedProduct }) => {

  const [fuzzyChoices, loading, error] = useCollectionData(collection(db, 'fuzzySearch/topicz/fuzzyChoices'));
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  
  function addToCurrentCart(product) {
    setSelectedProduct(product);
  }

  useEffect(() => {
    if (loading) return;
    if (!searchTerm) return;
    async function searchProducts(searchTerm) {
      try {
          const options = {
              scorer: fuzz.partial_ratio,
              processor: (choice) => choice.proc_sorted,
              limit: 50,
              cutoff: 90,
              unsorted: false,
              full_process: false,
          }
          const results = fuzz.extract(searchTerm, fuzzyChoices, options)
  
          setSearchResults(results);
          
          return results;
      } catch (error) {
          console.log(error);
      }
    }
    searchProducts(searchTerm).then((results) => {
      setSearchResults(results);
    })
  }, [searchTerm])

  return (
  <Card className='mb-3'>
    <Card.Body>
    <Form className='mb-1'>
        <Form.Control
            type="search"
            placeholder="Search for products..."
            aria-label="Search"
            className="rounded-pill search-input"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}  
        />
    </Form>
            {searchTerm.length > 0 ?
                    <div className='mb-4'>
                        <ListGroup>
                            {searchResults.map((hit, index) => {
                                const product = hit[0];
                                return (
                                    <ListGroup.Item
                                        key={index}
                                        className='d-flex justify-content-between align-items-center m-0'
                                        onClick={() => addToCurrentCart(product)}
                                    >
                                        <div className='d-flex align-items-center'>
                                            <Image 
                                                src={product?.image || MissingImage} 
                                                alt={product?.name} 
                                                height={50}
                                                />
                                            <div className='ms-2'>
                                                <p className='mb-0'>{product?.name}</p>
                                                <p className='mb-0'>SKU: {product?.sku}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <p className='mb-0'>${hit.ibmi_cost}</p>
                                        </div>
                                    </ListGroup.Item>
                                )
                            })}
                        </ListGroup>
                    </div>
                :
                    null
            }
    </Card.Body>
    </Card>
  )
}

export default MicroSearchBox;