import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { auth, db, fileStorage, functions } from 'config/firebase';
import { doc, arrayUnion, query, updateDoc, collection, where, limit, FieldValue, arrayRemove, getDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useHttpsCallable } from 'react-firebase-hooks/functions';
import {
  Card,
  Row,
  Col,
  Table,
  Tab,
  Tabs,
  Image,
  Alert,
  Form,
  InputGroup,
  Button,
  Accordion,
  Modal,
  Carousel
} from 'react-bootstrap';
import ProductGenius from 'components/merlin/custom/ProductGenius';
import CompIntel from 'components/merlin/custom/CompIntel';
import VariantList from 'components/merlin/custom/variantList';
import MissingImage from '../../assets/merlin/missing_image.png';
import Tags from 'components/merlin/custom/tags';
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagic, faCameraRetro, faPencilAlt, faCartPlus } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';
import ProductHistory from 'components/merlin/custom/ProductHistory';
import FavoriteHeart from 'components/merlin/custom/FavoriteHeart';
import PageHeader from 'components/common/PageHeader';
import Flex from 'components/common/Flex';
/**New Imports for page */ 

import SoftBadge from 'components/common/SoftBadge';
import InventoryHUD from './productPageComponents/InventoryHUD';
import CostHUD from './productPageComponents/CostHUD';
import ProductDetails from '../pages/productPageComponents/ProductDetails.js';
import ProductChannels from '../pages/productPageComponents/ProductChannels.js';
import VariantsDisplay from '../pages/productPageComponents/VariantsDisplay.js';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ProductCarousel from './productPageComponents/ProductCarousel';
import Lottie from "lottie-react";
import dotsAnimation from 'assets/lottie/dots.json';
/** End new imports for page */

function NewProducts(){
  
  let { sku, vendor } = useParams();
  let externalProduct = false;
  if (vendor) externalProduct = true;
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const [bestImg, setBestImg] = useState(MissingImage);
  const [bestTitle, setBestTitle] = useState('Loading...');
  const [editActive, setEditActive] = useState(false);
  const [barcodes, setBarcodes] = useState([]);
  const [newImageFile, setNewImageFile] = useState("");
  const [pictureUploadProgress, setPercent] = useState(0)
  const [srp, setSRP] = useState();
  const [retailSRP, setRetailSRP] = useState();
  const [transferAmount, setTransferAmount]  = useState(0);
  const [pictureModalState, showPictureModal] = useState(false);
  const [enhanceModalState, showEnhanceModal] = useState(false);
  const [enhancedId, setEnhancedId] = useState();
  const [downloadURL, setDownloadURL] = useState();
  const [lastUpdate, setLastUpdate] = useState();
  const [chatGptResult, setChatGptResult] = useState();

  /** START New Declarations */

  /** product "BPF" = Product Burn Rate Plus Fulfillment Cost
   *  This callable returns two figures, the number of days in inventory remaining plus avg item fulfillment cost
   */
  const [productBPF, bpfExecuting, bpfError] = useHttpsCallable(functions, 'getProductBurnRatePlusFulfillmentCost');
  const [productStats, setProductStats] = useState();
  const [merlinAiModal, showMerlinAiModal] = useState(false);
  const handleMerlinAiModalDisplay = () => showMerlinAiModal(!merlinAiModal);
  /** END New Declarations */

  /**
   * Enhance Product Data Handlers
   */
  const [enhanceLifecycleMessage, setEnhanceLifecycleMessage] = useState("");
  const [enhancementDisabledState, setEnhancementDisabledState] = useState(false);
  const handleEnhanceClose = () => showEnhanceModal(false);
  const handleEnhanceShow = () => showEnhanceModal(true);
  const EnhanceIcon = <FontAwesomeIcon icon={faMagic} />
  const CameraIcon = <FontAwesomeIcon icon={faCameraRetro} />
  const PencilIcon = <FontAwesomeIcon className={"fa-lg text-primary"} icon={faPencilAlt}/>
  const CartPlusIcon = <FontAwesomeIcon className={"fa-lg text-white"} icon={faCartPlus}/>

  let product, docLoading, docError, docSnapshot;
  if (externalProduct) {

    
    [product, docLoading, docError, docSnapshot ] = useDocumentData(doc(db, 'externalProducts', String(vendor), 'products', sku), {
      snapshotListenOptions: { includeMetadataChanges: true }
    });
    
    /*
    const docRef = doc(db, `externalProducts`, 'calm-caterpillar');
    getDoc(docRef).then((doc) => {
      if (doc.exists()) {
        console.log("inside", doc)
      } else {
        console.log("no doc")
      }

    }).catch((error) => {

      console.log("err", error)
    });
    */
    
  } else {
    [product, docLoading, docError, docSnapshot ] = useDocumentData(doc(db, `products`, sku), {
      snapshotListenOptions: { includeMetadataChanges: true }
    });
  }

  const [enhancedProducts, enhancedLoading] = useCollectionData(
    query(
      collection(db, "enhancedProductData"),
      where("parentSku", "==", sku),
      limit(1)
    ),
    { snapshotListenOptions: { includeMetadataChanges: true } }
  );

  const [enhanceProductData, enhanceExecuting, enhanceError] = useHttpsCallable(functions, 'enhanceProductData');
  const [getProductBarcodes, barcodesExecuting, barcodesError] = useHttpsCallable(functions, 'getProductBarcodes');
  const [checkDataExpiry, expiryExecuting, expiryError] = useHttpsCallable(functions, 'checkEnhancedDataExpiration');
  const [downloadRemoteImage, downloadExecuting, downloadError] = useHttpsCallable(functions, 'downloadRemoteImage');
  const [chatGpt, chatExecuting, chatError] = useHttpsCallable(functions, 'GptTitleAndDescription');
  

  async function processEnhancedData(sku){
    try {
      
      const expiryCheck = await checkDataExpiry({sku: sku});

      if (expiryCheck !== undefined) {
        if (expiryCheck.data === 'REFRESH DATA') {
          let barcodes = await getProductBarcodes({sku: sku});
          if(!barcodes) {
            setEnhanceLifecycleMessage("No barcode available")
          } else {
            let result = await enhanceProductData({sku: sku, barcodes: barcodes.data});
            setEnhanceLifecycleMessage(result.data);
          }
          
        }
  
        if (expiryCheck.data === 'NO REFRESH') {
          setEnhanceLifecycleMessage("No refresh needed...");
        }
        setEnhancementDisabledState(false);
      }
      
     
    } catch (error) {
      setEnhanceLifecycleMessage("There was an error")
      setEnhancementDisabledState(false);
      console.log(error, expiryError, enhanceError);
    }
  }

  async function handleEnhancementClick(e, sku) {
    e.preventDefault();
    setEnhancementDisabledState(true);
    processEnhancedData(sku)
  }

  function copyToClipboard(contentToCopy) {
    try {
      const userInput = String(contentToCopy);
      navigator.clipboard.writeText(String(userInput));
      toast.info(`Copied ${userInput} to Clipboard`, {
        position: 'top-center',
        theme: 'colored'
      });
      
    } catch (error) {
      console.log(error);
    }
     
  }

  /**
   * Primary Picture Handlers
   */
  const handlePictureClose = () => {
    showPictureModal(false);
    setNewImageFile("");
    setPercent(0);
  };
  const handlePictureShow = () => showPictureModal(true);
  const handleFileSelect = (e) => setNewImageFile(e.target.files[0]) 
  const handleURLSelect = (e) => setDownloadURL(e.target.value);

  function handleFileUpload() {
    if (!newImageFile) alert('Choose a file first')

    const filename = newImageFile.name.split(".");
    // create a unique name and save with original file/filename suffix
    const storageRef = ref(fileStorage, `/images/products/${uuidv4()}.${filename[filename.length - 1]}`)
    const uploadTask = uploadBytesResumable(storageRef, newImageFile);

    uploadTask.on("state_changed", 
      (snapshot) => {
        const percent = Math.round( (snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setPercent(`${Number(percent)}%`);
      }, 
      (err) => console.log(err), 
      (success) => {
        // download url, the success object isn't real
        getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
          setPercent("Success")
          //add url to images array in product collection
          const prodRef = doc(db, "products", sku);
          await updateDoc(prodRef, {
            images: arrayUnion(url)
          });
          // sku is set in component useState
          changePrimaryImg(sku, url);
          handlePictureClose();
         });
      }
    );

  }

  async function handleURLFileUpload(e) {
    e.preventDefault();
    console.log(downloadURL);
    await fetch(downloadURL)
    .then(res => res.blob()) // Gets the response and returns it as a blob
    .then(blob => {
      // Here's where you get access to the blob
      // And you can use it for whatever you want
      // Like calling ref().put(blob)
      console.log(blob);
  });

  }

  async function changePrimaryImg(sku, url) {
    try {
      console.log(url);
      if (!sku) return Promise.reject("No SKU")
      if (!url) return Promise.reject("Must include picture URL")
      const productRef = doc(db, "products", sku)
      return await updateDoc(productRef, {
        primaryImg: url
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function removeImageFromGallery(sku, url) {
    try {
      const filename = url.split("?");
      console.log('remove', filename[0]);
      const docRef = doc(db, "products", sku);
      const myArrayRemove = arrayRemove(url);
      await updateDoc(docRef, {
        images: myArrayRemove
      })

    } catch (error) {
      console.log(error);
    }
  }

  function ThumbNailImage(img) {
    const url = img.img;
    const sku = img.sku
    return (
      <Card className='mt-5 m-3' style={{width: '10rem'}}>
        <p></p>
        <Image src={url} fluid="true"/> 
        <p></p>
        <Button
          size='sm' 
          className='mb-2'
          onClick={async () => {
            try {
              await changePrimaryImg(sku, url)
              handlePictureClose();
            } catch (error) {
              console.log(error)
            } 
          }}
          disabled={docLoading}
        >Make Primary</Button>
        
        <Button
          className='mb-3'
          size='sm'
          variant='secondary' 
          onClick={async () => {
            try {
              await removeImageFromGallery(sku, url)
            } catch (error) {
              console.log(error)
            }
          }}
          disabled={docLoading}
        >Remove </Button>

      </Card>
    )
  }

  function ImageGallery(images) {
    const allImages = images.images;
    const imgDisplay = [];
    if (typeof allImages !== 'undefined') {
      allImages.forEach((img) => {
        imgDisplay.push(<ThumbNailImage img={`${img}`} sku={sku} />)
      })  
    }

    return (
      <>
        <Row xs={1} md={2} className="g-4">
          {imgDisplay.length > 0 ? imgDisplay : <Col>No images available please upload or import from Product Genius</Col>}
        </Row>
      </>
    )
  }

  function handleAddToCart() {
    try {
     /** If there is no active cart show list of carts to add to order */
      const openOrderIds = []; 
      const openOrdersRef = collection(db, "openOrders");
      const q = query(openOrdersRef, where("status", "!=", "Processed"));
      const snapshot = getDocs(q);
      snapshot.forEach((doc) => openOrderIds.push(doc.id));
      console.log(openOrderIds);
    } catch (error) {
      console.log(error);
    }
     
  }  

  useEffect(() => {

    productBPF({sku: sku}).then((res) => {
      setProductStats(res.data.stats)
    })
    //console.log(vendor, sku)
    // if (sku) processEnhancedData(sku);
    
  }, [/** Just run on initial load */])
  
  useEffect(() => {
    if (!product) return
    if (product.ibmi_name) {
      chatGpt({ibmi_name: product.ibmi_name, ibmi_unit_barcode: product.ibmi_unit_barcode}).then((res) => {
        setChatGptResult(res.data)
      })
    }
  }, [product]);

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate('/');
    if (!sku) return navigate('/products');

    if(!docLoading) {
      // if (!product) return navigate('/products');

      if (!externalProduct) {
        setBestTitle(product?.sell_Title || product?.ibmi_name)
        setBestImg(product?.primaryImg || MissingImage)
        let date = new Date(product?.lastUpdate.toDate());
        setLastUpdate(date.toLocaleString("en-us"));
        let size = product?.ibmi_size;
        let numInCaseRaw = size.split('/');
        let numInCase = Number(numInCaseRaw[0]);
        if (isNaN(numInCase)) numInCase = 1;
        if (product.ibmi_srp === 'None') {
          let newMarkup = Number(product.ibmi_cost) * Number(0.40);
          let srp = Math.round(((Number(product.ibmi_cost) + newMarkup) + Number.EPSILON) * 100) / 100;
          setSRP(srp);
          let eachPrice = srp / numInCase;
          eachPrice = Number(Math.ceil(eachPrice * 100) / 100);
          setRetailSRP(eachPrice);
        } else {
          setSRP( Number((product.ibmi_srp / 100) * numInCase))
          //console.log(srp, numInCase)
          let tempEachPrice = Number(Math.round( ((srp / numInCase) + Number.EPSILON)));
          setRetailSRP(tempEachPrice);
        }
      }
      
    }
    
  }, [user, loading, product, navigate, sku, srp, retailSRP]);

  return product && !docLoading ? (
    <>
      <PageHeader title={''} className="mb-3">
      <Row>
            {/** Header */}
            <Col style={{minHeight: '100px'}}>
              <Row>
                {product.ibmi_size === 'DISC' 
                  ?
                    <Col className='d-flex justify-content-center'>
                      <SoftBadge pill 
                        bg="danger" 
                        text="dark" 
                        className='h-25 h-25 d-inline-flex align-items-center justify-content-start'
                      >
                        <h5><strong>Discontinued</strong></h5>
                      </SoftBadge>
                    </Col>
                  :
                    <div style={{minWidth: '0px'}}></div>
                }
                <Col>
                  <Flex>
                    <div>
                      
                        <div style={{ display: 'inline-block'}}>
                          <span className="d-inline-block fs-4 text-truncate" style={{ maxWidth: '70vw' }}>
                            {bestTitle} {" "}
                          </span>
                        </div>
                        
                      
                      <div className="">
                        <div className='d-inline-block fs-1 mb-3 text-dark'>

                          <OverlayTrigger
                            overlay={
                              <Tooltip id="overlayClickToCopySku">
                                Click to copy SKU
                              </Tooltip>
                            }
                          >
                            <div 
                              className='d-inline-block' 
                              onClick={(e => copyToClipboard(product.ibmi_sku))}
                            >
                              SKU: {product.ibmi_sku}
                            </div>

                          </OverlayTrigger>
                          
                          <span className='ms-3'>Last IBMi Update: {lastUpdate}</span>
                        </div>
                        <Flex alignItems='end'>
                            
                            <div className=''>
                              <Button variant="primary" size="sm" onClick={() => handleAddToCart}>
                                {CartPlusIcon}
                              </Button>
                            </div>
                            <div className='ms-2'>
                              <FavoriteHeart sku={sku} name={bestTitle} picture={bestImg}/>
                            </div>
                            <div className='ms-2'>
                              <Button variant="falcon-primary" onClick={handleEnhanceShow} size="sm">
                                {EnhanceIcon}
                              </Button>
                            </div>
                            <div className='ms-2'>
                              <Button variant="falcon-primary" onClick={handlePictureShow} size="sm">
                                {CameraIcon}
                              </Button>
                            </div>
                            
                            
                          </Flex>
                      </div>
                    </div> 

                  </Flex>
                  
                </Col>
                
              </Row>
            </Col>
            
          </Row>
      </PageHeader>
      <Card className="mb-3">
        <Card.Body>

          
          <Row>
          <Col>
                {!externalProduct ? <CostHUD product={product} /> : null }
                {!bpfExecuting ? <InventoryHUD product={product} stats={productStats}/> : 'Loading...'}
                
          </Col>
              <Col
                className="mb-4 mb-lg-0"
              >
                <div className="">
                  {/**<Image className={""} src={bestImg} fluid/> **/}
                  {product.images &&
                    <ProductCarousel images={product.images}/>
                  }
                </div>
              
              </Col>
            
          </Row>
          
        </Card.Body>
      </Card>
      <Card className="mb-3">
        <Card.Body>
          {!externalProduct ? <Tags key={`${product.ibmi_sku}_tags`} sku={product.ibmi_sku} tags={product.tags} /> : null }
        </Card.Body>
      </Card>
      <Card className="mb-3">

      <Tabs
        defaultActiveKey="variants"
        id="product_tabs"
        className="mb-3"
        fill
      >
        <Tab eventKey="variants" title="Variants">
          <VariantsDisplay product={product} />
        </Tab>
        <Tab eventKey="details" title="Product Details">
          {! externalProduct ? <ProductDetails product={product} /> : null  }
        </Tab>
        <Tab eventKey="channels" title="Channel Settings">
          <ProductChannels product={product} />
        </Tab>
        <Tab eventKey="genius" title="Product Genius">
          {!enhancedLoading ? <ProductGenius products={enhancedProducts} /> : "Loading..."}
        </Tab>
        <Tab eventKey="notes" title="Merlin AI" >
            {!chatExecuting && chatGptResult ?
              <div className="m-3">

                <Row className='mb-3' >
                  <Col xs={2} md={2} lg={2}>
                    <span className='text-dark fw-semi-bold'>Given Data: </span>
                  </Col>
                  <Col>
                    {chatGptResult.original}
                  </Col>
                </Row>
                <Row className='mb-3' >
                  <Col xs={2} md={2} lg={2}>
                  <span className='text-dark fw-semi-bold'>Generated Title: </span>
                    
                  </Col>
                  <Col>
                    {chatGptResult.result}
                  </Col>
                </Row>
                <Row className='mb-3' >
                  <Col xs={2} md={2} lg={2}>
                  <span className='text-dark fw-semi-bold'>Description:</span>
                    
                  </Col>
                  <Col >
                    {chatGptResult.description}
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col xs={2} md={2} lg={2}>
                  <span className='text-dark fw-semi-bold'>Generated Tags:</span>
                  </Col>
                  <Col>
                    {chatGptResult.tags.map((tag, index) => {
                      return (
                        <SoftBadge key={index} bg="primary" className="me-1">
                          {tag}
                        </SoftBadge>
                      );
                    })}
                    {chatGptResult.moreTags.map((tag, index) => {
                      return (
                        <SoftBadge key={index} bg="secondary" className="me-1">
                          {tag}
                        </SoftBadge>
                      );
                    })}
                  </Col>
                </Row>
                <Row>
                  <Col><Button onClick={handleMerlinAiModalDisplay}>Train</Button></Col>
                </Row>
              </div>
            : <div><Lottie animationData={dotsAnimation} style={{height: 300}} /></div>
            }
        </Tab>
        
      </Tabs>

      </Card>
      


      {/**Enhanced Product Data Modal */}
      <Modal show={enhanceModalState} onHide={handleEnhanceClose}>
        <Modal.Header closeButton>
          <Modal.Title>Enhance Product Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-3'>
            Product data is enhanced from third-party data sources. Select below source to begin enhancement. After enhancement new data will be available under the 'Product Genius' tab.
          </div>
          <div className='mb-3'>
            <h5>Select Enhancement Source</h5>
          </div>
          <div className='mb-3'>
            <Button 
              onClick={ (e) => handleEnhancementClick(e, sku)}
              disabled={enhancementDisabledState}
            >{enhancementDisabledState ? 'Loading' : 'barcodelookup.com' }</Button>
          </div>
          {enhanceLifecycleMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEnhanceClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/** Picture Gallery Modal */}
      <Modal size="lg" show={pictureModalState} onHide={handlePictureClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Product Photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-3'>
            <h5>Upload New Photo from File</h5>
            <input onChange={handleFileSelect} type={'file'} accept="image/*" />
            
            <button onClick={handleFileUpload}>
              {pictureUploadProgress != 0 ? 
              `${pictureUploadProgress}`  : "Upload"}
            </button>
          </div>
          <div className='mb-3'>
            <h5>Upload New Photo from URL</h5>
            <input onChange={handleURLSelect} id="downloadByURLInput" type={'text'} />
            
            <Button 
            onClick={async () => {
              try {
                let download = document.getElementById("downloadByURLInput").value;
                //console.log(download);
                let url = await downloadRemoteImage({imgSrc: download, sku: sku})
                console.log(url);
              } catch (error) {
                console.log(error)
              }
            }}
            disabled={downloadExecuting}
          >Grab Image</Button>
          </div>
          <p></p>
          <div className='mb-3'>
            <h5>Or select below</h5>
            <ImageGallery images={product.images} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlePictureClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={merlinAiModal} onHide={handleEnhanceClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Fine-Tune Merlin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {!chatExecuting && chatGptResult ?
              <div>
                <Row className='border-bottom mb-3'>
                <Col>Original</Col>
                <Col>{chatGptResult.original}</Col>
              </Row>
              <Row>
                <Col>Given</Col>
                <Col>Should Be</Col>
              </Row>
              <Row className='mb-3'>
                <Col>{chatGptResult.result}</Col>
                <Col><Form.Control></Form.Control></Col>
              </Row>
              <Row>
                <Col>{chatGptResult.description}</Col>
                <Col><Form.Control as="textarea" rows={3}></Form.Control></Col>
              </Row>
              </div>
            : "Still Loading"
            }
            
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleMerlinAiModalDisplay}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => handleTrainingClick(e, product.ibmi_sku)}>
            Train
          </Button>
        </Modal.Footer>
      </Modal>

    </>

  ) : (
    <h3>loading...</h3>
  );
};
//<ProductGenius barcodes={barcodes} />

async function handleTrainingClick(e, sku) {
  try {
    
    console.log(result);
  } catch (error) {
    console.log(error);
  }
}

export default NewProducts;

