import React, {useState, useEffect, useRef} from "react";
import { ListGroup, Modal, Button, Row, Col, Form, InputGroup, Spinner } from "react-bootstrap";
import { updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";

export const AsinDetailModal = ({ show, onClose, data}) => {
    
    const amazonData = data?.amazon;
    
    async function saveNewAsinToDb(newAsin) {
        try {
            await updateDoc(data.ref, {
                ['amazon.asin']: newAsin,
            })
            toast.success('ASIN Override Successful', { theme: 'colored' });
            // wait a half sec then close modal
            setTimeout(() => {
                onClose();
            }, 500);
        } catch (error) {
            toast.error(error.message, { theme: 'colored' });
        }
    }

    const topAsinComponent = (topAsin) => {
        return (
            <>
                <Row>
                    <Col>
                        <h6>{topAsin.title}</h6>
                        <span>{topAsin.asin}</span>
                    </Col>
                </Row>
                <Row className="fs--1">
                    <Col>
                        {topAsin.brandName}
                    </Col>
                    <Col>
                        Pkg Qty: {topAsin.packageQuantity}
                    </Col>
                    <Col>
                        {topAsin.size}
                    </Col>
                </Row>
            </>
        )
    }

    const otherAsinsComponent = (otherAsins) => {

        const [working, setWorking] = useState(false);
        const [selectedAsin, setSelectedAsin] = useState(null);

        const selectListItem = async (asinData) => {
            setWorking(true);
            setSelectedAsin(asinData.asin);
            await saveNewAsinToDb(asinData.asin);
            setWorking(false);
            toast.success('ASIN Override Successful', { theme: 'colored' });
            // wait a half sec then close modal
            setTimeout(() => {
                onClose();
            }, 500);
        }

        return (
            <>
                <ListGroup>
                    {otherAsins.map((asin, index) => {
                        return (
                            <ListGroup.Item key={index} action onClick={() => selectListItem(asin)} disabled={working}>
                                <Row>
                                    <Col>
                                        <h6>{asin.title}</h6>
                                        <span>{asin.asin}</span>
                                    </Col>
                                    <Col xs='auto'>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="me-2"
                                            style={{ visibility: selectedAsin === asin.asin ? 'visible' : 'hidden' }}
                                        />
                                    </Col>
                                </Row>
                                <Row className="fs--1">
                                    <Col>
                                        {asin.brandName}
                                    </Col>
                                    <Col>
                                        Pkg Qty: {asin.packageQuantity}
                                    </Col>
                                    <Col>
                                        {asin.size}
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        )
                    })}
                </ListGroup>
            </>
        )
    }

    const givenProductComponent = (product) => {
        return (
            <>
                <Row>
                    <Col>
                        <h6>{product.title}</h6>
                    </Col>
                </Row>
                <Row className="fs--1">
                    <Col>
                    </Col>
                </Row>
            </>
        )
    }

    const AsinOverrideComponent = () => {

        const [overrideAsinWorking, setOverrideAsinWorking] = useState(null);
        const overRideControl = useRef(null);
        const handleOverride = async (e) => {
            e.preventDefault();
            setOverrideAsinWorking(true);
            const newAsin = overRideControl.current.value;
            await saveNewAsinToDb(newAsin);
            setOverrideAsinWorking(false);
        }

        return (
            <>
                <Row>
                    <Col>
                        <Form onSubmit={handleOverride}>
                            <InputGroup
                                className="input-group-merge"
                                size="sm"
                            >
                            <Form.Control 
                                type="text"
                                placeholder="Enter ASIN" 
                                ref={overRideControl}
                            />
                                <Button
                                    variant="outline-secondary"
                                    size="sm"
                                    type="submit"
                                    disabled={overrideAsinWorking}
                                >
                                    Override
                                </Button>
                            </InputGroup>
                        </Form>
                    </Col>
                </Row>
            </>
        )
    }

    return (
        data ?
        <Modal show={show} onHide={onClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>ASIN Detail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="mb-2">
                    <Col>
                        <h5>Given Product</h5>
                        {givenProductComponent(data)}
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <h5>Top ASIN</h5>
                        {topAsinComponent(amazonData?.topAsinData)}
                    </Col>
                </Row>
                {amazonData?.otherAsinData?.length > 0 ?
                <Row className="mb-2">
                    <Col>
                        <h5>Other ASINs</h5>
                        {otherAsinsComponent(amazonData?.otherAsinData)}
                    </Col>
                </Row>
                : null}
                <Row className="mb-2">
                    <Col>
                        <h5>ASIN Override</h5>
                        {AsinOverrideComponent()}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        : null
    )
};