import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import createMarkup from 'helpers/createMarkup';
import Avatar from 'components/common/Avatar';
import IconButton from 'components/common/IconButton';
import SoftBadge from 'components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Notification = ({
  avatar,
  time,
  className,
  unread,
  flush,
  emoji,
  children,
  keyAction
}) => {
  const note = createMarkup(children);
  console.log(keyAction.url)
  return (
  <div
    className={classNames(
      'notification',
      { 'notification-unread': unread, 'notification-flush': flush },
      className
    )}
  >
    {avatar && (
      <div className="notification-avatar">
        <Avatar {...avatar} className="me-3" />
      </div>
    )}
    <div className="notification-body">
      <p className="mb-1" dangerouslySetInnerHTML={note} />
      {keyAction && keyAction.type === 'link' && (
        <p>
          <SoftBadge
            bg='secondary'
          > 
            <FontAwesomeIcon
              icon={['fas', 'link']}
              className="me-2"
            />
            <a href={keyAction.url} className="notification-link text-800" target='_blank'>
              {keyAction.name}
            </a>
          </SoftBadge>
        </p>
      )}
      <span className="notification-time">
        {emoji && (
          <span className="me-2" role="img" aria-label="Emoji">
            {emoji}
          </span>
        )}
        {time}
      </span>
    </div>
  </div>
)};


Notification.propTypes = {
  avatar: PropTypes.shape(Avatar.propTypes),
  time: PropTypes.string.isRequired,
  className: PropTypes.string,
  unread: PropTypes.bool,
  flush: PropTypes.bool,
  emoji: PropTypes.string,
  children: PropTypes.node
};

Notification.defaultProps = { unread: false, flush: false };

export default Notification;
