import { DateTime } from 'luxon';

const OrderAgeGetter = ({ data }) => {
    let modifyDateString = null;
    if (data?.modifyDateString) modifyDateString = data.modifyDateString;
    if (!modifyDateString) modifyDateString = data?.orderDateString;
    const today = DateTime.local(); // Current date and time in local time zone
    // America/Los_Angeles is the time zone for the Cloud Function which is processing the orders
    const orderDate = DateTime.fromISO(modifyDateString, { zone: 'America/Los_Angeles' }).toLocal();
    const diffTime = Math.abs(today - orderDate); // Difference in milliseconds
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); 
    const diffHours = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const diffMinutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
    let returnLiteral = ``;
    if (diffMinutes > 10 && diffMinutes < 60 && diffHours === 0 && diffDays === 0) {
        returnLiteral = `${diffMinutes}m`;
        return returnLiteral;
    }
    if (diffHours > 0 && diffHours < 24 && diffDays === 0) {
        returnLiteral = `${diffHours}h`;
        return returnLiteral;
    }
    if (diffDays > 0) {
        returnLiteral = `${diffDays}d`;
        return returnLiteral;
    }
    return `⏰`;
}

export default OrderAgeGetter;