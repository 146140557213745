import React, { useEffect, useState, useRef } from 'react';
import { Tabs, Tab, Nav, Row, Col, Table, Form, Button, Accordion, Container, InputGroup} from 'react-bootstrap';
import { db } from "config/firebase.js";
import { query, collection, where, limit, getDocs, updateDoc, orderBy, addDoc } from "firebase/firestore";
import { Pie, Bar } from 'react-chartjs-2';
import { getColor, rgbaColor } from 'helpers/utils';
import { chartJsDefaultTooltip } from 'helpers/chartjs-utils';
import Flex from 'components/common/Flex';
import checkPrimaryLight from 'assets/img/animated-icons/check-primary-light.json';
import Lottie from 'lottie-react';
import dotsAnimation from 'assets/lottie/dots.json';
import { toast } from 'react-toastify';
import { useCol } from 'react-bootstrap/esm/Col';
import { useCollection, useCollectionData } from 'react-firebase-hooks/firestore';


const ShippingMaster = ({product, variants, repricerData}) => {

    
    const [shippingData, setShippingData] = useState(null);
    const [weightedAvgCost, setWeightedAvgCost] = useState(null);
    const [loading, setLoading] = useState(true);

    const [packageTypes, packageTypesLoading, packageTypeErrors] = useCollectionData(collection(db, 'packageTypes'));

    useEffect(() => {
        if (!repricerData) return;
        const latestRepricingData = repricerData[0];
        const shippingDataFromRepricer = latestRepricingData.shippingData || {};
        setShippingData(shippingDataFromRepricer);
        setWeightedAvgCost(shippingDataFromRepricer.weightedAvgCost);
        setLoading(false);
    }, [repricerData]);

    async function createPackageType({ name, cost }) {
        if (!name || !cost) throw new Error('Missing required fields');
        const packageType = {
            name,
            cost,
            createdAt: new Date(),
        }
        const packageTypesRef = collection(db, 'packageTypes');
        const packageTypeDoc = await addDoc(packageTypesRef, packageType);
        return packageTypeDoc;
    }

    const PackageTypeForm = ({packageTypes, packageTypesLoading, vRef, currentPackageType}) => {

        if (!currentPackageType) currentPackageType = 'Default Package';

        async function assignPackageType(e) {
            e.preventDefault();
             const packageTypeName = e.target.value;
            try {
                await updateDoc(vRef, {packageType: packageTypeName});
                toast.success('Package Type Assigned', {theme: 'colored'});
            } catch (error) {
                toast.error(error.message, {theme: 'colored'});
                console.log(error)
            }
        }


        return (
            !packageTypesLoading ?
            <div className='d-block w-100' >
            <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Select aria-label="Select a package type" onChange={(e) => assignPackageType(e)} >
                        <option>{currentPackageType}</option>
                        {packageTypes.map((option, index) => {
                            return (
                                <option key={index}>{option.name}</option>
                            )
                        })}
                    </Form.Select>
                </Form.Group>
            </Form>
            </div>
            : <Lottie animationData={dotsAnimation} style={{width: 100, height: 100}} />
        )
    }

    const AddNewPackageType = () => {

        const newPackageNameRef = useRef(null);
        const newPackageCostRef = useRef(null);

        async function handleAdd(e) {
            e.preventDefault();
            const newPackageName = newPackageNameRef.current.value;
            const newPackageCost = newPackageCostRef.current.value;
            try {
                await createPackageType({ name: newPackageName, cost: newPackageCost });
                toast.success('Package Type Added', {theme: 'colored'});
            } catch (error) {
                toast.error(error.message, {theme: 'colored'});
                console.log(error)
            }
        }

        return (
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Add New</Accordion.Header>
                    <Accordion.Body>
                    <Form>
                    <Form.Group className="mb-3" controlId="newPackageType">
                        <Form.Control type="text" placeholder="Package Type Name" ref={newPackageNameRef}/>
                        <InputGroup>
                            <InputGroup.Text>$</InputGroup.Text>
                            <Form.Control type="number" placeholder="Cost" ref={newPackageCostRef}/>
                        
                            <Button variant="outline-secondary"  type={"submit"} onClick={(e) => handleAdd(e)}>Add</Button>
                        </InputGroup>
                    </Form.Group>
                    </Form>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        )
    }

    return (
        !loading ?
        <>
            <Row className='mt-3 p-3'>
                <Col className='w-75'>
                    <Row>
                        <Col lg="auto" className="d-flex justify-content-center align-top">
                            <Lottie animationData={checkPrimaryLight} loop={true} style={{height: '80px', width: '80px'}} />
                        </Col>
                        <Col  className="align-top">
                            <div className='text-sans-serif display-3 mt-2'>
                                ${weightedAvgCost}
                                
                            </div>
                            <span className='d-block text-muted'>Weighted Average Cost</span>
                           
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="auto" className="d-flex justify-content-center align-top mt-3">
                            <Table striped bordered hover size="lg" className='text-sans-serif'>
                                <thead>
                                    <tr>
                                        <th>Zone</th>
                                        <th>Average</th>
                                        <th>Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {shippingData.zones?.map((zone, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{zone.zone}</td>
                                                <td>${zone.average}</td>
                                                <td>{zone.count}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="auto" className="d-flex justify-content-center align-top mt-3">
                            {variants && variants.length > 0 ?
                               variants.map((variant, index) => {
                                      return (
                                        <div key={index} className='text-sans-serif p-2'>
                                            <div className='text-700'>{variant.title}</div>
                                            <div className='text-muted'>{variant.sku}</div>
                                            <PackageTypeForm 
                                                packageTypes={packageTypes}
                                                packageTypesLoading={packageTypesLoading}
                                                vRef={variant.vRef}
                                                currentPackageType={variant.packageType}
                                            />
                                        </div>
                                      )
                                 })
                            :
                            <div className='text-sans-serif'>
                                <div className='text-muted'>{product.title}</div>
                                <div className='text-muted'>{product.sku}</div>
                                
                            </div>
                            }
                            
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <AddNewPackageType />
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <div className='text-center p-5'>
                        <div>Estimate Data Sources</div>
                        <EstimateSourcePieChart shippingData={shippingData}/>
                    </div>
                </Col>
            </Row>
        </>
        :
        <Lottie animationData={dotsAnimation} style={{width: 100, height: 100}} />
    )
}

const EstimateSourcePieChart = ({shippingData}) => {

    const freshPercent = Number(shippingData?.freshPercent)*100 || 0;
    const fromOrderPercent = Number(shippingData?.fromOrderPercent)*100 || 0;
    const reusePercent = 100-Number(shippingData?.reusePercent)*100 || 0;

    const data = {

        labels: ['ShipStation Estimate', 'Live Order Data', 'Saved Data'],
        datasets:[
        {
            data: [freshPercent, fromOrderPercent, reusePercent],
            borderWidth: 0,
            backgroundColor: [getColor('primary'), getColor('danger'), getColor('secondary')],
            borderColor: getColor('gray-100')
        }
        ],
    };
    
    const options = {
        plugins: {
            tooltip: chartJsDefaultTooltip()
        },
        maintainAspectRatio: true
    };
    
    return (
        <Pie data={data} options={options} width={300} height={300}/>
    );
}

const ZonesBarChart = ({shippingData}) => {

    
 
        const data = {
          labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
          datasets: [
            {
              label: '# of Votes',
              data: [12, 19, 3, 5, 2, 3],
              backgroundColor: [
                rgbaColor(getColor('secondary'), 0.2),
                rgbaColor(getColor('warning'), 0.2),
                rgbaColor(getColor('info'), 0.2),
                rgbaColor(getColor('success'), 0.2),
                rgbaColor(getColor('info'), 0.2),
                rgbaColor(getColor('primary'), 0.2)
              ],
              borderColor: [
                getColor('secondary'),
                getColor('warning'),
                getColor('info'),
                getColor('success'),
                getColor('info'),
                getColor('primary')
              ],
              borderWidth: 1
            }
          ]
        };
      
        const options = {
          plugins: {
            tooltip: chartJsDefaultTooltip()
          },
          scales: {
            x: {
              grid: {
                color: rgbaColor(getColor('black'), 0.1)
              }
            },
            y: {
              grid: {
                color: rgbaColor(getColor('black'), 0.1),
                drawBorder: true
              }
            }
          }
        };
      
          return (
            <Bar 
              data={data} 
              options={options}
            />
          );
        
      
}
      
      

export default ShippingMaster;