import { db } from 'config/firebase';
import { collection, query, where, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import { toast } from 'react-toastify';

export async function createRepricerJob(variant) {

    async function checkForActiveRepricerJobs(sku) {
        const repricerJobsRef = collection(db, 'repricerJobs');
        const q1 = query(repricerJobsRef, 
            where('sku', '==', sku), 
            where('status', '==', 'pending')
        );
        const q2 = query(repricerJobsRef, 
            where('sku', '==', sku), 
            where('status', '==', 'processing')
        );
        const [pendingJobs, processingJobs] = await Promise.all([
            getDocs(q1),
            getDocs(q2)
        ])
        if (pendingJobs.size > 0 || processingJobs.size > 0) {
            setNumOpenJobs(pendingJobs.size + processingJobs.size)
            return { status: 'true', openJobs: pendingJobs.size + processingJobs.size };
        } else {
            return { status: 'false', openJobs: 0 };
        }
    }

    async function addNewRepricerJob(variant, targetPrice) {
        try {
            await addDoc(collection(db, 'repricerJobs'), {
                sku: variant.sku,
                parentSku: variant.parentSku,
                targetPrice: Number(targetPrice),
                barcodes: variant.barcodes,
                variant,
                createdAt: serverTimestamp(),
                status: 'pending',
                newUi: true
            })
        } catch (error) {
            throw new Error(error.message)
        }
        
    }

    async function checkForSettings(variant) {
        if (!variant) throw new Error('No variant provided')
        if (!variant.repricerSettings) {
            variant.repricerSettings = {
                default: true,
                markupFactor: 1.15,
                targetMargin: 0.15,
                discountRate: 0.05,
                businessRate: 0.05
            }
        }        
        return variant
    }

    function calculateTargetPrice({ productCost, variantProductFactor, targetMargin }) {
        const targetPrice = Number(
            (productCost * variantProductFactor) + ((productCost * variantProductFactor) * targetMargin)
        ).toFixed(2);
        return targetPrice;
    }

    const { status, openJobs } = await checkForActiveRepricerJobs(variant.sku);
    if (openJobs > 0) {
        //** There is an active repricer job executing */
        return { status: status, openJobs };
    }
    const { repricerSettings } = await checkForSettings(variant);
    const { targetMargin } = repricerSettings;
    const productCost = Number(variant.cost);
    const variantProductFactor = Number(variant.productFactor);
    const targetPrice = calculateTargetPrice({ productCost, variantProductFactor, targetMargin });
    try {
        await addNewRepricerJob(variant, targetPrice);
        return { success: true, openJobs: 1 };
    } catch (error) {
        return { success: false, message: error.message};
    }

}

export async function pinAllProducts(skus, tabController) {
    if (!skus) return;
    if (!tabController) return;
    const simpleList = skus.map(sku => sku.sku)
    try {
        const { activeTab, addProduct } = tabController;
        for (const sku of simpleList) {
            const newPin = {
                sku,
                score: 102,
                source: 'pinned',
            };
            addProduct(activeTab, newPin)
        }    
        toast.success(`${skus.length} products pinned`, {theme: 'colored'});
        return true;
    } catch (error) {
        console.log(error.message)
        toast.error(error.message, {theme: 'colored'});
    }
    

}
