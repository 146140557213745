import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from 'config/firebase';
import { collection, addDoc, updateDoc, doc } from "firebase/firestore"; 
import CartItem from './CartItem';
import CountUp from 'react-countup';
import { useDocumentData } from 'react-firebase-hooks/firestore';

const ShoppingCart = (props) => {
  const [user, authLoading] = useAuthState(auth);
  const org = props?.org;
  /** reverse the items in the cart as per Trae's request 2-Mar-23 */
  /*
  const rawCartItems = props.items || [];
  const processedCartItems = [];
  for (let i = rawCartItems.length; i >= 0; i--) {
    if (rawCartItems[i] != undefined) {
      processedCartItems.push(rawCartItems[i]);
    }
  }
  const [cartItems, setCartItems] = useState(processedCartItems);
  */
  const [cartItems, setCartItems] = useState([]);
  const [batchName, setBatchName] = useState(props.batchName)
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [cartId, setCartId] = useState(props.cartId);
  const [subTotal, setSubTotal] = useState(0);
  const [oldSub, setOldSub] = useState(0);
  const [orderSubmittedState, setOrderSubmittedState] = useState(false);
  const [orderItems, orderItemsLoading, orderItemsError, orderSnap] = useDocumentData(doc(db, "openOrders", props.cartId));
  
  useEffect(() => {
    if (orderItemsLoading || orderItemsError) return;
    const tempItems = orderItems.items;
    if (tempItems?.length > 0) {
      const processedItems = [];
      for (let i = tempItems.length; i >= 0; i--) {
        if (tempItems[i] != undefined) {
          processedItems.push(tempItems[i]);
        }
      }
      setCartItems(processedItems);
    }
    

  }, [orderSnap])

  function getSubtotal(acc, currentValue) {
    const temp = Number(currentValue.cost) * Number(currentValue.quantity);
    setOldSub(temp);
    return acc + temp;

  }

  function getItemQty(acc, cv) {
    const temp = Number(cv.quantity);
    return acc + temp;
  }

  useEffect(() => {
    setSubTotal(cartItems.reduce(getSubtotal, 0))
    setTotalItemCount(cartItems.reduce(getItemQty, 0))
  }, [cartItems]);


  /**
   * orderObject = {
   *  uid,
   *  timestamp,
   *  status (processed or not),
   *  items (the stuff you're ordering),
   *  batchName (what to put on label)
   * }
   * @param {Object} orderObject 
   */
  async function submitManualOrder(orderObject) {
    try {
      /*
      const orderRef = await addDoc(collection(db, "manualOrders"), {
        uid: orderObject.uid,
        timestamp: orderObject.timestamp,
        status: 'Submitted',
        batchName: orderObject.batchName,
        items: cartItems
      })
      */
      const cartRef = await updateDoc(doc(db, "openOrders", cartId), {
        status: "Submitted",
        lastUpdate: new Date(),
        muid: orderObject.uid,
        mtimestamp: orderObject.timestamp,
        mbatchName: orderObject.batchName,
        mitems: cartItems
      })
      return cartRef.id  
    } catch (error) {
      console.log(error);
    }
  }

  async function handleSubmitButton() {
    // build the orderObject
    let temp = {
      uid: user.uid,
      timestamp: new Date(),
      status: 'Submitted',
      batchName: batchName,
      items: cartItems
    }
    setOrderSubmittedState(true);
    submitManualOrder(temp);
  }

  return (
    <>
      <Card>
        <Card.Header>
          <Row className="justify-content-between">
            <Col md="auto">
              {orderItems &&
                <span>Viewing {cartItems.length} Products ({totalItemCount} Total Items)</span>
              }
            </Col>
            <Col md="auto">
              <Button
                style={{width: '8rem'}}
                type='submit'
                variant="primary"
                size="sm"
                onClick={handleSubmitButton}
                disabled={orderSubmittedState}
              >
                {orderSubmittedState ? "Thank You" : "Submit Order" }
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          {totalItemCount > 0 ? (
            <>
              <Row className="gx-card mx-0 bg-200 text-900 fs--1 fw-semi-bold">
                <Col xs={9} md={8} className="py-2">
                  Name
                </Col>
                <Col xs={3} md={4}>
                  <Row>
                    <Col md={8} className="py-2 d-none d-md-block text-center">
                      Quantity
                    </Col>
                    <Col xs={12} md={4} className="text-end py-2">
                      Cost
                    </Col>
                  </Row>
                </Col>
              </Row>
              {cartItems.map((product, idx) => (
                !orderSubmittedState ? <CartItem product={product} key={idx} cartId={cartId} org={org}/> : null
               
              ))}
              {!orderSubmittedState ? null : <div className='m-4'><h5>Thank you! The order has been submitted for processing</h5></div> }
              <Row className="fw-bold gx-card mx-0">
                <Col xs={9} md={8} className="py-2 text-end text-900">
                  Total
                </Col>
                <Col className="px-0">
                  <Row className="gx-card mx-0">
                    <Col md={7} className="py-2 d-none d-md-block text-center">
                      {totalItemCount} (items)
                    </Col>
                    <Col
                      xs={12}
                      md={5}
                      className="text-end py-2 text-nowrap px-card"
                    >
                    <CountUp 
                      start={oldSub} 
                      end={subTotal} 
                      delay={0} 
                      duration={0.4}
                      separator=","
                      decimals={2}
                      decimal="."
                    >
                      <span id="subTotalDisp">${subTotal}</span>
                    </CountUp>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          ) : (
            <p className="p-card mb-0 bg-light">
              You have no items in your shopping cart. Add items using the search bar above.
            </p>
          )}
        </Card.Body>

        {cartItems.length > 0 && (
          <Card.Footer className="bg-light d-flex justify-content-end">
            
          <Button
                style={{width: '8rem'}}
                type='submit'
                variant="primary"
                size="sm"
                onClick={handleSubmitButton}
                disabled={orderSubmittedState}
              >
                {orderSubmittedState ? "Thank You" : "Submit Order" }
              </Button>
          </Card.Footer>
        )}
      </Card>
    </>
  );
  
};

export default ShoppingCart;
