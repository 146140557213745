import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';

/**
 * TODO 
 * 

    - We need to be able to save the tabs and their data to the database

    1) Create a custom hook to load, save, delete and update the tabs
    2) update calcBestSellers.js to add a 'Best Sellers' tab to the tabs collection

    tabModel = {
        id: 'fjf9430inf3qinf',
        name: 'Tab 1',
        productSkus: ['123456', '123457', '123458']
        access: 'private' || 'public'
        users: ['user1', 'user2', 'user3']
        timestamp: {
            created: 'timestamp',
            updated: 'timestamp'
        }
    }

 * @param {*} param0 
 * @returns 
 */
import { useSkuList } from 'components/pages/newProducts/context/SkuListContext';

export const TabContainer = ({ tabs, loading, tabController, uid, gridRef}) => {

    // if (!setSkuList) throw new Error('setSkuList is required');

    const {skuList, setSkuList} = useSkuList();
    const [tabData, setTabData] = useState();
    const [hoveredTab, setHoveredTab] = useState(null);  // Track which tab is being hovered
    const [editingTab, setEditingTab] = useState(null);  // Index of the tab being edited, or null if no tab is being edited
    const [newTabName, setNewTabName] = useState('');  // New name being typed for the tab being edited
    const [visibleTabs, setVisibleTabs] = useState([0]);  // Array of tab indexes that are visible
    
    useEffect(() => {
        if (loading) return;
        if (tabs.length === 0) return;
        let isSubbed = true;
        const tabData = [];
        for (const tab of tabs) {
            tabData.push(tab);
        }
        if (isSubbed) { 
            if (!tabController.activeTab) {
                console.log("No active tab")
                tabController.onLoad();
            } else {
                // check for the search tab, ensure its visible and loaded first
                initializeSearchTab(tabData);
                setTabData(tabData);
                tabController.assignActiveTab(tabController.activeTab);
                const filteredTabs = tabData.filter((tab) => tab.open);
                setVisibleTabs(filteredTabs);
                const pinnedSkus = filteredTabs[tabController.activeTabIndex]?.skus;
                updateSkusForActiveTab(pinnedSkus, skuList);
                // filter thte tabs that only have the open property as true
            }
        }
        return () => { 
            isSubbed = false
            updateSkusForActiveTab.cancel();
        }
    }, [tabs, loading, tabController.activeTab])

    const initializeSearchTab = (tabs) => {
        const searchTab = tabs.find((tab) => tab.name === "Search Results");
        if (!searchTab) {
            // create one
            tabController.createSearchTab();
        }
    }

    // merge both sets of skus to return one list to the skuList (ag-grid)
    const updateSkusForActiveTab = debounce((pinnedSkus, skuList) => {

        const mergedSkus = [];
        if (pinnedSkus?.length > 0) {
            for (const sku of pinnedSkus) {
                mergedSkus.push(sku);
            }
        }
        mergedSkus.reverse();
        // Only update the SKU list if it has changed
        if (!arraysAreEqual(mergedSkus, skuList)) {
            setSkuList(mergedSkus);
        }
    }, 500);

    const arraysAreEqual = (array1, array2) => {
        if (array1?.length !== array2?.length) return false;
        for (let i = 0; i < array1?.length; i++) {
            if (array1[i] !== array2[i]) return false;
        }
        return true;
    }

    async function manageAddTab(e) {
        e.preventDefault();
        const newTab = {
            name: 'Untitled',
            open: true,
            skus: []
        }
        // setVisibleTabs((prev) => {
        //     if (prev) return [...prev, newTab]
        //     return [newTab]
        // });
        try {
            await tabController.createTab(newTab);
        } catch (error) {
            console.log(error);
        }
    }

    const handleTabClick = (index) => {
        if (loading) return;
        if (gridRef.current) {
            gridRef.current.api.showLoadingOverlay();
            tabController.assignActiveTab(visibleTabs[index]?.id);
            gridRef.current.api.setRowData([]);
            updateSkusForActiveTab(visibleTabs[index]?.skus, []);
            gridRef.current.api.hideOverlay();
        }
    };

    const handleTabClose = (index) => {
        
        // setVisibleTabs(prev => {
        //     const newVisibleTabs = prev.filter((_, i) => i !== index);
        //     return newVisibleTabs;
        // });
        tabController.assignActiveTab(visibleTabs[index === 0 ? 0 : index - 1]?.id);
        updateSkusForActiveTab(visibleTabs[index === 0 ? 0 : index - 1]);
        try {
            // tabController.deleteTab(tabData[index].id);
            tabController.closeTab(visibleTabs[index].id);
            handleTabClick(index === 0 ? 0 : index - 1)
        } catch (error) {
            console.log(error);
        }
    };

    const handleTabTitleClick = (index) => {
        setEditingTab(index);
        setNewTabName(visibleTabs[index].name);
    };

    const handleTabTitleChange = (event) => {
        setNewTabName(event.target.value);
    };

    const handleTabTitleSave = async (index) => {
        // find id of index
        // save the new name in the local copy
        // setVisibleTabs(prev => prev.map((tab, i) => i === index ? { ...tab, name: newTabName } : tab));
        // save the new name in the database
        try {
            await tabController.updateName(visibleTabs[index].id, newTabName);
        } catch (error) {
            console.log(error);
        }
        setEditingTab('');
        setNewTabName('');
    };

    const styles = {
        container: {
          display: 'flex',
          alignItems: 'flex-end',
          flexDirection: 'row',
          backgroundColor: '#fff',
          padding: '5px 10px',
          position: 'relative',
          width: '100%',
          // borderBottom: '1px solid #ccc',
        },
        tab: (isActive) => ({
            position: 'relative',
            padding: '5px 10px',
            backgroundColor: isActive ? '#fff' : '#f9f9f9',
            border: '1px solid #ccc',
            borderBottom: isActive ? '3px solid #f00' : 'none',  
            borderRadius: '5px 5px 0 0',
            marginLeft: '5px',
            cursor: 'pointer',
            marginBottom: '-5px',
            FontFace: 'Roboto',
            fontSize: '11px',
            fontWeight: 'bold',
        }),
        addTab: {
            padding: '5px',
            backgroundColor: '#fff', // '#f9f9f9',
            // border: '1px solid #ccc',
            // borderBottom: 'none',
            // borderRadius: '5px 5px 0 0',
            marginLeft: '5px',
            cursor: 'pointer',
            fontSize: '20px',
            lineHeight: '1',
            height: '25px',
          },
        closeButton: {
            position: 'absolute',
            top: '0',  // Adjusted to align to the top
            right: '0px',
            transform: 'translateY(-50%)',  
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
            display: 'none',  // Hide the close button by default
            color: 'red',  // Added to make the 'x' red
            padding: '5px',  // Added to increase clickable area and spacing
        },
        closeButtonVisible: {
            display: 'block',  // Show the close button
        },
        tabTitleInput: {
            border: 'none',
            outline: 'none',
            backgroundColor: 'transparent',
            fontSize: 'inherit',
            width: '100%',
        },
    };

    return (
        <div style={styles.container}>
            {tabData && visibleTabs && visibleTabs.length > 0 && visibleTabs.map((tab, index) => {
                return (
                    tab.open && (
                    <div 
                        key={index} 
                        style={styles.tab(index === tabController.activeTabIndex)}
                        onClick={() => handleTabClick(index)}
                        onMouseEnter={() => setHoveredTab(index)}
                        onMouseLeave={() => setHoveredTab(null)}
                    >
                        {editingTab === index && tab.editable === true ? (
                            <input
                                type="text"
                                value={newTabName}
                                onChange={handleTabTitleChange}
                                onBlur={() => handleTabTitleSave(index)}  // Save the new title when the input field loses focus
                                onKeyDown={(event) => event.key === 'Enter' && handleTabTitleSave(index)}  // Save the new title when the Enter key is pressed
                                style={styles.tabTitleInput}
                                autoFocus  // Automatically focus the input field when it's rendered
                            />
                        ) : (
                            <span onDoubleClick={() => handleTabTitleClick(index)}>{tab.name}</span>
                        )}
                        {tab.editable === true &&
                            <button
                            style={{
                                ...styles.closeButton, 
                                ...(hoveredTab === index && styles.closeButtonVisible)
                            }} 
                            onClick={(e) => {
                                e.stopPropagation();  // Prevent the tab click handler from firing
                                handleTabClose(index);
                            }}
                            >
                                ×
                            </button>
                        }
                    </div>
                    )
                )
            })}
            <div style={styles.addTab} onClick={(e) => manageAddTab(e)}>+</div>
        </div>
    )
}