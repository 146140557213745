import { useState, useContext, useEffect, useRef } from 'react';
import { db } from "config/firebase";
import { useNavigate } from 'react-router-dom';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import PageHeader from 'components/common/PageHeader';
import { Card, Row, Col, Button, Badge, Form, InputGroup } from 'react-bootstrap';
import Lottie from 'lottie-react';
import dotsAnimation from 'assets/lottie/dots.json';
import { useCollection } from 'react-firebase-hooks/firestore';
import { collection, query, updateDoc, where, getDoc, doc } from 'firebase/firestore';
import AdvanceTableFooter from 'components/merlin/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/merlin/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import { toast } from 'react-toastify';

const GeneralRepricerSettings = ({ children }) => {

    const loading = false

    const DefaultShippingTable = () => {

        const defaultShippingControlRef = useRef(null);

        async function getCurrentValue() {
            try {
                //const doc = await db.collection("repricerSettings").doc('fallbackShippingAmount').get();
                const docRef = doc(db, "repricerSettings", "fallbackShippingAmount");
                const currentValue = await getDoc(docRef);
                if (defaultShippingControlRef.current) {
                    if (currentValue.data().value) {
                        defaultShippingControlRef.current.value = currentValue.data()?.value;
                    } else {
                        defaultShippingControlRef.current.value = '0.00';
                    }
                }
                
            } catch (error) {
                console.error(error);
            }
        }

        useEffect(() => {
            getCurrentValue();
        }, []);

        async function handleSubmit(e) {
            e.preventDefault();
            try {
                // await db.collection("repricerSettings").doc('fallbackShippingAmount').update({
                //     value: defaultShippingControlRef.current.value,
                //     timestamp: new Date(),
                // });
                const valueToUpdate = defaultShippingControlRef.current.value;
                const docRef = doc(db, "repricerSettings", "fallbackShippingAmount");
                await updateDoc(docRef, {
                    value: valueToUpdate,
                    timestamp: new Date(),
                });
                toast.success('Default Shipping Amount Updated', {theme: 'colored'});
                return true;
            } catch (error) {
                console.error(error);
            }
        }

        return (
            <Card className="mb-3 p-3">
                <Card.Header><strong>Default Shipping Table</strong></Card.Header>    
                <Card.Body>
                    <Row className='align-middle'>
                        <Col xs="auto">
                            <p>Default Shipping Amount</p>
                        </Col>
                        <Col>
                            <Form>
                                <InputGroup onSubmit={(e) => handleSubmit(e)}>
                                    <InputGroup.Text>$</InputGroup.Text>
                                    <Form.Control type="text" placeholder="0.00" ref={defaultShippingControlRef} />
                                    <Button onClick={(e) => handleSubmit(e)} type="submit" variant="falcon-secondary">Save</Button>
                                </InputGroup>
                            </Form>
                        </Col>
                    </Row>
                    </Card.Body>
            </Card>
        )
    }

    const GeneralSettings = () => {

        const defaultTargetMarginControlRef = useRef(null);
        const competitiveMarginControlRef = useRef(null);

        async function getCurrentSettings() {
            try {
                //const doc = await db.collection("repricerSettings").doc('fallbackShippingAmount').get();
                const docRef = doc(db, "repricerSettings", "defaultTargetMargin");
                const currentValue = await getDoc(docRef);
                console.log(currentValue.data());
                if (defaultTargetMarginControlRef.current) {
                    if (currentValue.data().value) {
                        defaultTargetMarginControlRef.current.value = currentValue.data()?.value;
                    } else {
                        defaultTargetMarginControlRef.current.value = '0.25';
                    }
                }
                const nextSettingDocRef = doc(db, "repricerSettings", "competitiveMargin");
                const nextSettingValue = await getDoc(nextSettingDocRef);
                if (competitiveMarginControlRef.current) {
                    if (nextSettingValue.data().value) {
                        competitiveMarginControlRef.current.value = nextSettingValue.data()?.value;
                    } else {
                        competitiveMarginControlRef.current.value = '0.25';
                    }
                }
            } catch (error) {
                console.error(error);
            }
        }

        useEffect(() => {
            getCurrentSettings();
        }, []);

        async function handleTargetMarginSubmit(e) {
            e.preventDefault();
            try {
                // await db.collection("repricerSettings").doc('fallbackShippingAmount').update({
                //     value: defaultShippingControlRef.current.value,
                //     timestamp: new Date(),
                // });
                const valueToUpdate = defaultTargetMarginControlRef.current.value;
                const docRef = doc(db, "repricerSettings", "defaultTargetMargin");
                await updateDoc(docRef, {
                    value: valueToUpdate,
                    timestamp: new Date(),
                });
                toast.success('Default Target Margin Saved', {theme: 'colored'});
                return true;
            } catch (error) {
                console.error(error);
            }
        }

        async function handleCompetitiveMarginSubmit(e) {
            e.preventDefault();
            try {
                const valueToUpdate = competitiveMarginControlRef.current.value;
                const docRef = doc(db, "repricerSettings", "competitiveMargin");
                await updateDoc(docRef, {
                    value: valueToUpdate,
                    timestamp: new Date(),
                });
                toast.success('Default Target Margin Saved', {theme: 'colored'});
                return true;
            } catch (error) {
                console.error(error);
            }
        }

        return (
            <Card className="mb-3 p-3">
                <Card.Header><strong>General Settings</strong></Card.Header>    
                <Card.Body>
                    <Row className='align-middle'>
                        <Col md={2}>
                            <p>Default Target Margin</p>
                        </Col>
                        <Col>
                            <Form>
                                <InputGroup onSubmit={(e) => handleTargetMarginSubmit(e)}>
                                    <InputGroup.Text>%</InputGroup.Text>
                                    <Form.Control type="text" placeholder="0.00" ref={defaultTargetMarginControlRef} />
                                    <Button onClick={(e) => handleTargetMarginSubmit(e)} type="submit" variant="falcon-secondary">Save</Button>
                                </InputGroup>
                            </Form>
                        </Col>
                    </Row>
                    <Row className='align-middle'>
                        <Col md={2}>
                            <p>Competitive Margin</p>
                        </Col>
                        <Col>
                            <Form>
                                <InputGroup onSubmit={(e) => handleCompetitiveMarginSubmit(e)}>
                                    <InputGroup.Text>%</InputGroup.Text>
                                    <Form.Control type="text" placeholder="0.00" ref={competitiveMarginControlRef} />
                                    <Button onClick={(e) => handleCompetitiveMarginSubmit(e)} type="submit" variant="falcon-secondary">Save</Button>
                                </InputGroup>
                            </Form>
                        </Col>
                    </Row>
                    </Card.Body>
            </Card>
        )
    }


    return (
        !loading ?
        <>
            <PageHeader title="General Repricer Settings" className="mb-3">
            </PageHeader>
            <DefaultShippingTable />
            <GeneralSettings />
        </>
        :
        <Lottie animationData={dotsAnimation} style={{width: 100, height: 100}} />
    );
}

export default GeneralRepricerSettings;