import React, { useState } from "react";
import { Modal, Button, Badge, Form } from "react-bootstrap";
import { writeBatch } from "firebase/firestore";
import { db } from 'config/firebase';

export const BulkTagsModal = ({ show, setShow, data, gridRef }) => {

    const currentTags = data.map(product => product.tags).flat();
    const uniqueTags = [...new Set(currentTags)];
    const sortedTags = uniqueTags.sort();
    const [working, setWorking] = useState(false);

    function handleModalClose() {
        setShow(false);
        const cellRefreshParams = {
            rowNodes: gridRef.current.api.getSelectedNodes(),
            force: true,
            columns: ['tags']
        }
        gridRef.current.api.refreshCells(cellRefreshParams);
    }

    async function handleRemoveTag (data, tagName) {
        // for each row in data remove the tag from the tags array
        // update the document with the new tags array
        const rows = data.map(product => {
            const tags = product.tags;
            const index = tags.indexOf(tagName);
            if (index > -1) {
                tags.splice(index, 1);
            }
            return {
                tags: tags,
                ref: product.ref
            }
        });
        if (rows.length > 0) {
            setWorking(true);
            // chunk the refs into 500 document chunks
            const chunks = chunkArray(rows, 500);
            setWorking(true);
            for (const chunk of chunks) {
                const batch = writeBatch(db);
                for (const row of chunk) {
                    batch.update(row.ref, { tags: row.tags });
                }
                try {
                    await batch.commit();
                } catch (error) {
                    console.error(error);
                }
            }
            setWorking(false);
        }
    
    }
    
    async function handleAddTag (data, tagName) {
        // for each row in data add the tag to the tags array
        // update the document with the new tags array
        const rows = data.map(product => {
            const tags = product.tags;
            tags.push(tagName);
            return {
                tags: tags,
                ref: product.ref
            }
        });
        if (rows.length > 0) {
            setWorking(true);
            // chunk the refs into 500 document chunks
            const chunks = chunkArray(rows, 500);
            setWorking(true);
            for (const chunk of chunks) {
                const batch = writeBatch(db);
                for (const row of chunk) {
                    batch.update(row.ref, { tags: row.tags });
                }
                try {
                    await batch.commit();
                    console.log('Successfully added tag to ', chunk.length, ' variants');
                } catch (error) {
                    console.error('Error adding tag to ', chunk.length, ' variants');
                    console.error(error);
                }
            }
            setWorking(false);
        }
    }

    const DisplayTags = ({ tags, data }) => {

        const handleTagRemoval = (e, tagName) => {
            e.preventDefault();
            handleRemoveTag(data, tagName);
        }

        return (
            <>
                <div className="justify-content-start">
                    <div className="fw-semi-bold fs--1 d-block">Remove Tags</div>
                    <div className="border-primary border-top pt-1" >
                        {tags.map(tag => (
                            <Badge pill bg="primary" className="me-1 mt-1" key={tag}>
                            {tag}
                            <button
                                type="button"
                                className="btn-close text-white"
                                aria-label="Remove"
                                onClick={ (e) => handleTagRemoval(e, tag)}
                            />
                        </Badge>
                        ))}
                    </div>
                </div>
            </>
            
        );
    };

    const AddTags = ({ data }) => {

        const [tagName, setTagName] = useState('');

        const handleChange = (e) => {
            setTagName(e.target.value);
        }

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                handleAddTag(data, tagName);
            }
        }

        return (
            <div className="justify-content-start">
                <div className="fw-semi-bold fs--1 d-block">Add Tags</div>
                <div className="border-primary border-top pt-1" >
                    <Form.Control
                        type="text"
                        placeholder="Enter Tag Name"
                        value={tagName}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                    />
                </div>
            </div>
        )
    }

    return (
        <Modal show={show} onHide={() => handleModalClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>Add or Remove Tags</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Your modal content based on selected action */}
                    <div className="mb-3">
                        <DisplayTags tags={sortedTags} data={data}/>
                    </div>
                    <div>
                        <AddTags data={data}/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleModalClose()}>
                        Close
                    </Button>
                </Modal.Footer>
        </Modal>
    )
}

function chunkArray(myArray, chunk_size){
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];
    
    for (index = 0; index < arrayLength; index += chunk_size) {
        var myChunk = myArray.slice(index, index+chunk_size);
        // Do something if you want with the group
        tempArray.push(myChunk);
    }
    return tempArray;
}