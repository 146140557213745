import React, { createContext, useState, useContext } from 'react';

const WorkspaceCacheContext = createContext();

export const useWorkspaceCache = () => useContext(WorkspaceCacheContext);

export const WorkspaceCacheProvider = ({ children }) => {
    const [workspaceCache, setWorkspaceCache] = useState({});

    const setCache = (key, value) => {
        const cacheKey = generateCacheKey(key);
        setWorkspaceCache(prev => ({ ...prev, [cacheKey]: value }));
    };

    const getCache = (key) => {
        const cacheKey = generateCacheKey(key);
        return workspaceCache[cacheKey];
    };

    const deleteCache = (key) => {
        const cacheKey = generateCacheKey(key);
        setWorkspaceCache(prev => {
            const newCache = { ...prev };
            delete newCache[cacheKey];
            return newCache;
        });
    };

    return (
        <WorkspaceCacheContext.Provider value={{ workspaceCache, setCache, getCache, deleteCache }}>
            {children}
        </WorkspaceCacheContext.Provider>
    )
}

const hashCode = (s) => s.split('').reduce((a, b) => {
    a = ((a << 5) - a) + b.charCodeAt(0);
    return a & a;
}, 0);

const generateCacheKey = (complexObjectArray) => {
    // Use JSON.stringify to convert the array to a string
    // and then hash it. This is a simplistic approach; 
    // you might need a more sophisticated method for large or complex objects.
    return hashCode(JSON.stringify(complexObjectArray));
};

