import React, {useState, useEffect} from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { db } from 'config/firebase';
import { collection, query, where, orderBy, limit } from "firebase/firestore";
import { Col, Row, Table, Button } from 'react-bootstrap';

function convertIbmiDate(ibmiDate) {
    //converts from YYYYMMDD into valid date object
    if (ibmiDate.toLowerCase() !== 'none') {
        let year = ibmiDate.substring(0,4);
        let month = ibmiDate.substring(4,6)
        let day = ibmiDate.substring(6,8)
        let dateTime = new Date(`${year}/${month}/${day}`);
        return dateTime.toDateString();
    } else {
        return "None"
    }
    
}

const ProductHistory = (props) => {
    const sku = props?.sku;
    const [filter, setFilter] = useState(false);

    const q = query(
        collection(db, 'productChangeHistory'),
        where("sku", "==", sku),
        orderBy("timestamp", "desc"),
        limit(1000)
        );
    
    
    const [historyItems, loading, error] = useCollectionData(
        q,
        {
          snapshotListenOptions: { includeMetadataChanges: true },
        }
      );

    const reportRows = [];
    if (!loading) {
        historyItems.forEach(item => {
            let changes = item.changes;
            let timestamp = item.timestamp.toDate();
            let dateTime = new Date(timestamp).toLocaleString();
            
            changes.forEach((change) => {

                    let newItem = change.new;
                    let oldItem = change.old;
                    if (newItem === '') newItem = 0;
                    if (oldItem === '') oldItem = 0;

                    if (change.field === 'Next Receipt Date') {
                        newItem = convertIbmiDate(change.new);
                        oldItem = convertIbmiDate(change.old);
                    }
                    
                    if(filter && change.field === 'Quantity On Hand') {
                        reportRows.push(
                            <tr>
                                <td>{dateTime}</td>
                                <td>{change.field}</td>
                                <td>
                                    <Row>
                                        <Col>Current: {newItem}</Col>
                                        <Col>Previous Value: {oldItem}</Col>
                                    </Row> 
                                </td>
                            </tr>
                        )
                    } else {
                        reportRows.push(
                            <tr>
                                <td>{dateTime}</td>
                                <td>{change.field}</td>
                                <td>
                                    <Row>
                                        <Col>Current: {newItem}</Col>
                                        <Col>Previous Value: {oldItem}</Col>
                                    </Row> 
                                </td>
                            </tr>
                        )
                    }
                    

                
            })

        })

    }
   

    return (
        <>
            <h4>IBMi Product History</h4>
            <p></p>
            <Table bordered hover>
                <tbody>
                    {reportRows}
                </tbody>
            </Table>
        </>
    )
} 

export default ProductHistory;