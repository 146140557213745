import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { auth, db } from 'config/firebase';
import {
  doc,
  query,
  collection,
  getDocs,
  orderBy,
  limit,
  getDoc,
  where,
  addDoc
} from 'firebase/firestore';
import { Card, Image, Table, Col, Row } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import axios from 'axios';

const Orders = () => {
  const params = useParams();
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const [orderId, setOrderId] = useState('');
  const [orderData, setOrderData] = useState();
  const [orderTitle, setOrderTitle] = useState("Loading...");
  const [statusDisplay, setStatusDisplay] = useState('Loading...');
  const [firebaseLoading, setFirebaseLoading] = useState(true);
  const [slot, setSlot] = useState([]);

  async function getShipStationOrder(orderId) {
    try {
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://ssapi.shipstation.com/orders/${orderId}`,
        headers: { 
          'Authorization': 'Basic NjU5NTAyYjk1NmIzNDJiMjk1Njg1NzRiOGViZmM3NmY6ZDdiODY2MmUwMmMxNDBkN2JmNTA2OGE4ZjEwNmFiMzI='
        }
      };
      const order = await axios(config);
      const res = await addDoc(collection(db, "orders"), order.data);
      return order;
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate('/');

    const fetchOrder = async (orderIdNumber) => {
      try {
        let q = query(collection(db, "orders"), where("orderId", "==", parseInt(orderIdNumber)));
        let querySnapshot = await getDocs(q);
        let chooseOrderData;
        if (querySnapshot.empty) {
          /** get order from shipstation */
          chooseOrderData = await getShipStationOrder(orderIdNumber);
        } else {
          querySnapshot.forEach((doc) => {
            chooseOrderData = doc.data();
          })
        }
      
        const tempData = chooseOrderData;
        const subtotal = tempData?.items.reduce((acc, curr) => curr.quantity * curr.unitPrice + acc, 0)
        const taxPaid = tempData?.items.reduce((acc, curr) => curr.taxAmount + acc, 0);
        const shippingPaid = tempData?.items.reduce((acc, curr) => curr.shippingAmount + acc, 0);
        
        const storeQuery = query(collection(db, "shipStationStores"), where("storeId", "==", parseInt(tempData.advancedOptions.storeId)));
        const storeSnap = await getDocs(storeQuery);
        let tempStoreName = '';
        storeSnap.forEach((store) => {
          let data = store.data();
          tempStoreName = data.storeName;
          setOrderTitle(`${data.storeName}   :  ${tempData.orderNumber}`);
        })
        setStatusDisplay(returnStatusDisplayText(tempData.orderStatus));
        setOrderData({...chooseOrderData, storeName: tempStoreName, st: subtotal, tp: taxPaid, shP: shippingPaid});
        
      } catch (error) {
        console.log(error);
      }
    };
    
    
    setOrderId(params.orderId);
    fetchOrder(params.orderId);
    //setOrderTitle(`Order : ${orderData.orderNumber}`);
    setFirebaseLoading(false);
    
  
  }, [user, loading, navigate, orderId]);

  function returnStatusDisplayText(orderStatus) {
    let DisplayText;
    switch (orderStatus) {
      case 'awaiting_shipment':
        DisplayText = <SoftBadge pill bg="primary" className="fs--2">
                          {'Awaiting Shipment'}
                          <FontAwesomeIcon
                            icon={['fa', 'bell']}
                            className="ms-1"
                            transform="shrink-2"
                          />
                      </SoftBadge>;
        break;
      case 'shipped':
        DisplayText = <SoftBadge pill bg="success" className="fs--2">
                          {'Shipped'}
                          <FontAwesomeIcon
                            icon={['fa', 'envelope']}
                            className="ms-1"
                            transform="shrink-2"
                          />
                      </SoftBadge>;
        break;
      case 'old':
        DisplayText = <SoftBadge pill bg="success" className="fs--2">
                          {'Note: Order modified prior to Staging Deployment - Old Data'}
                          <FontAwesomeIcon
                            icon={['fa', 'envelope']}
                            className="ms-1"
                            transform="shrink-2"
                          />
                      </SoftBadge>;
        break;
      default:
        DisplayText = <SoftBadge pill bg="warning" className="fs--2">
                          {orderStatus}
                          <FontAwesomeIcon
                            icon={['fa', 'exclamation']}
                            className="ms-1"
                            transform="shrink-2"
                          />
                      </SoftBadge>;
        break;
    }
    return DisplayText;
  }

 
  return orderData && !firebaseLoading ? (
    <>
      <PageHeader

        title={orderTitle} titleTag="h5" className="mb-3">
        <p className="fs--1 mt-1">{Date(orderData.modifyDate).toLocaleString('en-US', {timeZone: 'EST'})}</p>
          <div className='mb-3'>
            <strong className="me-2">Status: </strong>
            {statusDisplay}
          </div>
          {orderData.prePicked ?
            <SoftBadge pill bg="warning" className="fs-1">Pre-Picked</SoftBadge>
          : null
          }

      </PageHeader>
      <Card className="mb-3">
      <Card.Body>
        <Row>
          <Col md={6} lg={4} className="mb-4 mb-lg-0">
            <h5 className="mb-3 fs-0">Billing Address</h5>
            <h6 className="mb-2">{orderData.billTo.name}</h6>
            <p className="mb-1 fs--1">
            
              {orderData.billTo.street1 ? null : <span>Same as Shipping</span>}
              {orderData.billTo.street1} <br /> {orderData.billTo.street2} <br /> {orderData.billTo.street3} <br /> 
              {orderData.billTo.city}{orderData.billTo.city ? ',' : null} {orderData.billTo.state}{" "}{orderData.billTo.postalCode}
             
            </p>
            <p className="mb-0 fs--1">
              <strong>
                Email: {orderData.customerEmail ? orderData.customerEmail : 'Unavailable'}
              </strong>
            </p>
            <p className="mb-0 fs--1">
              <strong>
                Phone: {orderData.billTo.phone ? orderData.billTo.phone : 'Unavailable'}
              </strong>
            </p>
          </Col>
          <Col md={6} lg={4} className="mb-4 mb-lg-0">
            <h5 className="mb-3 fs-0">Shipping Address</h5>
            <h6 className="mb-2">{orderData.shipTo.name}</h6>
            <p className="mb-0 fs--1">
              {orderData.shipTo.street1 ? null : <span>Same as Shipping</span>}
              {orderData.shipTo.street1} <br /> {orderData.shipTo.street2} <br /> {orderData.shipTo.street3} <br /> 
              {orderData.shipTo.city}{orderData.shipTo.city ? ',' : null} {orderData.shipTo.state}{" "}{orderData.shipTo.postalCode}
             
            </p>
            <div className="text-500 fs--1">{orderData.requestedShippingService}{" "}Shipping</div>
          </Col>
          <Col md={6} lg={4}>
            <h5 className="mb-3 fs-0">Additional Details</h5>
            <div className="d-flex">
              <div className="flex-1">
                <h6 className="mb-0">Payment Date</h6>
                <p className="mb-3 fs--1">{Date(orderData.paymentDate).toLocaleString('en-US', {timeZone: 'EST'})}</p>
                <h6 className="mb-0">User ID</h6>
                <p className="mb-3 fs--1">{orderData.userId ? orderData.userId : "Not Available"}</p>
                <h6 className="mb-0">Address Status</h6>
                <p className="mb-3 fs--1">{orderData.shipTo.addressVerified}</p>
              </div>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
    <Card className="mb-3">
      <Card.Body>
        <div className="table-responsive fs--1">
          <Table striped className="border-bottom">
            <thead className="bg-200 text-900">
              <tr>
                <th className="border-0">SKU</th>
                <th className="border-0">Name</th>
                <th className="border-0 text-center">Quantity</th>
                <th className="border-0 text-end">Unit Price</th>
                <th className="border-0 text-end">Amount</th>
              </tr>
            </thead>
            <tbody>
            {orderData.items.map((item, index) => {
              
              let baseSku = item.sku.split("-");
              
              if (baseSku[0].length < 6 || baseSku[0].length > 6) {
                baseSku = '';
              } else {
                baseSku = `/products/${baseSku[0]}`;
              }
              
              return (
                <tr key={item.orderItemId} className="border-200">
                  <td className="align-middle">
                    <h6 className="mb-2 text-nowrap">
                      <a href={baseSku}>{item.sku}</a></h6>
                    <p className="mb-0"><Image width={50} src={item.imageUrl} fluid /></p>
                  </td>
                  <td className="align-middle">
                    <h6 className="mb-0 text-nowrap">{item.name}</h6>
                    <p className="mb-0">{item.warehouseLocation} - {item.upc}</p>
                  </td>
                
                  <td className="align-middle text-center">
                    {item.quantity}
                  </td>
                  <td className="align-middle text-end">
                    $
                    {parseFloat(item.unitPrice).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })}
                  </td>
                  <td className="align-middle text-end">
                    $
                    {parseFloat(item.quantity * item.unitPrice).toLocaleString(
                      undefined,
                      { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                    )}
                  </td>
                </tr>
              )
              
            })}
            </tbody>
          </Table>
          <Row className="g-0 justify-content-end">
            <Col xs="auto">
              <Table borderless size="sm" className="fs--1 text-end">
                <tbody>
                  <tr>
                    <th className="text-900">Subtotal:</th>
                    <td className="fw-semi-bold">
                      
                      $
                      {parseFloat(orderData.st).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </td>
                  </tr>
                  <tr>
                    <th className="text-900">Tax</th>
                    <td className="fw-semi-bold">
                      $
                      {parseFloat(orderData.tp).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </td>
                  </tr>
                  <tr>
                    <th className="text-900">Shipping</th>
                    <td className="fw-semi-bold">
                      $
                      {parseFloat(orderData.shP).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </td>
                  </tr>
                  <tr className="border-top">
                    <th className="text-900">Total:</th>
                    <td className="fw-semi-bold">
                      $
                      {parseFloat(orderData.st + orderData.tp).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </td>
                  </tr>
                  <tr>
                    <th className="fw-lighter">Amount Paid:</th>
                    <td className="fw-lighter">
                      $
                      {parseFloat(orderData.amountPaid).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
    </>
  ) : <h3>loading...</h3>;
};

export default Orders;
