import React, { useEffect, useState } from 'react';
import { Tab, Nav, Row, Col, Table, Form, Button, Accordion } from 'react-bootstrap';
import InventoryWidget from './components/InventoryWidget';
import InventoryChart from './components/InventoryChart';
import Discounts from './components/Discounts';
import MerlinAi from './components/MerlinAi';
import MerlinGpt from 'components/pages/productDetails/components/MerlinGpt';
import Competition from './components/Competition';
import FulfillmentCosts from './components/FulfillmentCosts';
import { db } from "config/firebase.js";
import { query, collection, where, limit, getDocs, updateDoc, orderBy } from "firebase/firestore";
import ChannelIntel from 'components/pages/productDetails/components/repricer/ChannelIntel';
import VariantLevelRepricer from 'components/pages/productDetails/components/repricer/VariantLevelRepricer';
import ShippingMaster from 'components/pages/productDetails/components/repricer/ShippingMaster';
import { useCollection } from 'react-firebase-hooks/firestore';

const ProductDetailsFooter = ({ product, fbRef, uid, variants, parentSku,
  product: { shortDescription, specification }
}) => {

  async function addSpecToHUD(spec) {
    try {
      console.log(`elements.ibmi.${spec}`)
      const q = query(collection(db, "userHeadsUpDisplay"), where("uid", "==", uid), limit(1));
      const querySnapshot = await getDocs(q);
      let docRef;
      querySnapshot.forEach((doc) => docRef = doc.ref);
      
      const elementToUpdate = `ibmi.${spec}`;
      await updateDoc(docRef, {
        [`elements.${elementToUpdate}`]: true
      })

    } catch (error) {
      console.log(error)
    }
  }

  const [repricerDataRaw, repricerDataLoading, repricerDataError] = useCollection(
    query(collection(db, "repricerData"), where("parentSku", "==", parentSku), orderBy("timestamp", "desc"), limit(1000))
  );
  const [repricerData, setRepricerData] = useState(null);
  useEffect(() => {
    if (repricerDataLoading) return;
    if (repricerDataError) {
      console.log(repricerDataError);
      return;
    };
    if (!repricerDataRaw) return;
    if (repricerDataRaw.empty) return;
    if (repricerDataRaw.docs.length >= 1) {
      const data = repricerDataRaw.docs.map(doc => {
        const repricerDataEntry = doc.data()
        repricerDataEntry.timestamp = repricerDataEntry.timestamp.toDate();
        repricerDataEntry.id = doc.id;
        repricerDataEntry.docRef = doc.ref;
        return repricerDataEntry;
      });
      setRepricerData(data);
    };
  }, [repricerDataRaw, repricerDataLoading, repricerDataError]);

  return (
    <div className="overflow-hidden mt-4">
      <Tab.Container defaultActiveKey="inventory">
        <Nav variant="tabs">

          <Nav.Item>
            <Nav.Link
              eventKey="inventory"
              className="ps-0 cursor-pointer outline-none"
            >
              Inventory
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              eventKey="specifications"
              className="px-2 px-md-3 cursor-pointer outline-none"
            >
              Product Specs
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              eventKey="channelIntel"
              className="px-2 px-md-3 cursor-pointer outline-none"
            >
              Channel Intelligence
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              eventKey="repricer"
              className="px-2 px-md-3 cursor-pointer outline-none"
            >
              Repricer
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              eventKey="shipping"
              className="px-2 px-md-3 cursor-pointer outline-none"
            >
              Shipping
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              eventKey="discounts"
              className="px-2 px-md-3 cursor-pointer outline-none"
            >
              Discounts & Promotions
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              eventKey="merlin_ai"
              className="px-2 px-md-3 cursor-pointer outline-none"
            >
              MerlinGPT
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              eventKey="competition"
              className="px-2 px-md-3 cursor-pointer outline-none"
            >
              Competition Analysis
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>

          {/** Show Inventory Locations, warehouse slots, and maybe a graph? */}
          <Tab.Pane eventKey="inventory">
            <div
              className="mt-3"
            >
              <InventoryWidget product={product} />
              <InventoryChart product={product} />
            </div>
          </Tab.Pane>

          {/** Show Specifications */}
          <Tab.Pane eventKey="specifications">


            <Accordion className='mt-4'>

              {product?.barcodes ?
              
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Product Barcodes</Accordion.Header>
                  <Accordion.Body>
                    <Table className="fs--1 mt-3">
                      <tbody>
                        {Object.keys(product.barcodes).map((spec) => (
                          <tr key={spec}>
                            <td className="bg-100" style={{ width: '30%' }}>
                              <div className='text-capitalize'>
                                {spec}
                              </div>
                            </td>
                            <td>{product.barcodes[spec]}</td>
                          </tr>
                          
                        ))}
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>

              : null
              }

              {product.ibmi ?
                <Accordion.Item eventKey="0">
                  <Accordion.Header>IBMi Product Data</Accordion.Header>
                  <Accordion.Body>
                    <Table className="fs--1 mt-3">
                      <tbody>
                        {Object.keys(product.ibmi).map((spec) => (

                          spec === 'incoming_date_object' ? null :
                          <tr key={spec}>
                            <td className="bg-100" style={{ width: '30%' }}>
                              <div className='text-capitalize'>{spec}
                                <Button
                                  size='sm'
                                  variant='link'
                                  onClick={() => {
                                    addSpecToHUD(spec, uid)
                                  }}
                                >+</Button>
                              </div>
                              
                            </td>
                            <td>{product.ibmi[spec]}</td>
                          </tr>
                          
                        ))}
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
              : null }

              {specification ?
                <Accordion.Item eventKey="1">
                  <Accordion.Header>E-Commerce Specifications</Accordion.Header>
                  <Accordion.Body>
                    <Table className="fs--1 mt-3">
                      <tbody>
                        {Object.keys(specification).map((spec) => (
                          
                          <tr key={spec}>
                            <td className="bg-100" style={{ width: '30%' }}>
                              <div className='text-capitalize'>{spec}</div>
                            </td>
                            <td>{specification[spec]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
              : null}
              
            </Accordion>

            
          </Tab.Pane>

          <Tab.Pane eventKey="channelIntel">
            <div
              className="mt-3"
            >
              <ChannelIntel product={product} variants={variants} repricerData={repricerData}/>
            </div>
          </Tab.Pane>

          <Tab.Pane eventKey="repricer">
            
          </Tab.Pane>

          <Tab.Pane eventKey="shipping">
            <div
              className="mt-3"
            ><ShippingMaster product={product} variants={variants} repricerData={repricerData}/> 
            </div>
          </Tab.Pane>


          {/** Merlin AI & Enhanced Product Data */}
          <Tab.Pane eventKey="discounts">
            <div
              className="mt-3"
            >
              <Discounts product={product} docRef={fbRef} />
            </div>
          </Tab.Pane>

          {/** Merlin AI & Enhanced Product Data   <MerlinAi product={product} docRef={fbRef.ref} /> */}
          <Tab.Pane eventKey="merlin_ai">
            <div
              className="mt-3"
            >
             <MerlinGpt product={product} />
            </div>
          </Tab.Pane>

          {/** Competition*/}
          <Tab.Pane eventKey="competition">
            <div
              className="mt-3"
            >
              <Competition product={product}/>
            </div>
          </Tab.Pane>

          {/** Show Listings */}
          <Tab.Pane eventKey="listings">
            <div
              className="mt-3"
            >
              
            </div>
          </Tab.Pane>

        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

/**
 * <StarRating
    className="fs--1"
    readonly
    rating={review.rating}
  /> 
<StarRating className="d-block fs-3" rating={0} />
*/

/**
 * <Nav.Item>
            <Nav.Link
              eventKey="fulfillmentCosts"
              className="px-2 px-md-3 cursor-pointer outline-none"
            >
              Fulfillment Costs
            </Nav.Link>
          </Nav.Item>
          <Tab.Pane eventKey="fulfillmentCosts">
            <div
              className="mt-3"
            >
              <FulfillmentCosts product={product} variants={variants}/>
            </div>
          </Tab.Pane>
 */


export default ProductDetailsFooter;
