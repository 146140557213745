import React, { useState, useRef, useEffect } from 'react'
import { Button, Form, InputGroup, Offcanvas, Spinner } from 'react-bootstrap'
import { useMerlinAssistant } from 'components/pages/newProducts/ai/useMerlinAssistant';
import { useSkuList } from 'components/pages/newProducts/context/SkuListContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';
import Markdown from 'react-markdown';

const MerlinAiChatbox = ({ setWaiting, activeWorkspaceSkus }) => {

    if (!activeWorkspaceSkus) return null;

    const filteredSkus = activeWorkspaceSkus?.map(info => info.sku);

    const {sendMessage, createNewThread, toggleChatRefresh, awaitingResponse, messages, isLoading, error} = useMerlinAssistant(filteredSkus);

    useEffect(() => {
        setWaiting(awaitingResponse);
        if (awaitingResponse === false) scrollToBottom();
    }, [awaitingResponse]);

    useEffect(() => {
        setWaiting(isLoading);
    }, [isLoading]);

    const [inputText, setInputText] = useState('');
    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        if (isLoading) return;
        if (messages?.length > 0) scrollToBottom();
    }, [isLoading]);

    useEffect(() => {
        scrollToBottom()
    }, [messages]);

    const handleSend = async () => {
        if (awaitingResponse) return;
        if (inputText.trim()) {
            try {
                await sendMessage(inputText);
            } catch (error) {
                console.log(error);
            }
        }
        setInputText('');
    }

    const AiInteractionContainer = ({ messages }) => {
        return (
            <div 
                style={{ 
                    flexGrow: 1,
                    overflowY: 'auto',
                    msOverflowStyle: 'none', /* IE and Edge */
                    scrollbarWidth: 'none' /* Firefox */
                }}>
                {messages?.length > 0  && messages.map((message, index) => {
                    return (
                    <>
                    {message.role !== 'status' &&
                        <div key={index} style={{ textAlign: message.role === 'user' ? 'right' : 'left'}}>
                            <div className='mb-3'>
                                {message.role === 'user' ? <div className='fs--2 fw-bold text-success'>You</div> : <div className='fs--2 fw-bold text-primary'>Merlin</div>}
                                {message.content?.map((content, index) => {
                                    switch (content.type) {
                                        case 'text':
                                            return <div className='fs--2' key={index}><Markdown>{content.text.value}</Markdown></div>
                                        default:
                                            break;
                                    }
                                })}
                            </div>
                        </div>
                    }
                    {message.role === 'status' &&
                        <span className='fs--2 text-muted'>{message.content}</span>
                    }
                    </>
                    
                    )
                })}
                <div ref={messagesEndRef}>
                </div>
            </div>
        )
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <AiInteractionContainer messages={messages} />
            <MessageInputComponent inputText={inputText} handleSend={handleSend} setInputText={setInputText} createNewThread={createNewThread} toggleChatRefresh={toggleChatRefresh}/>
        </div>
    )
}

const MessageInputComponent = ({inputText, handleSend, setInputText, createNewThread, toggleChatRefresh}) => {

    const handleNewThread = () => {
        
        createNewThread();
        setTimeout(() => {
            toggleChatRefresh();
        }, 1000);
    }

    return (
        <>
            <InputGroup className="mb-3">
                <Button variant="outline-secondary" onClick={handleNewThread}>New</Button>
                <Form.Control
                    placeholder="Type your message here..."
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handleSend();
                        }
                    }}
                />
                <Button variant="outline-secondary" onClick={handleSend}>Send</Button>
            </InputGroup>
            <span className='fs--2 text-muted'>merlin-alpha-001 - training data current as of 11/18/23</span>
        </>
        
    )
}

export const OffCanvasChatbox = () => {
    const [show, setShow] = useState(false)
    const [waiting, setWaiting] = useState(false)
    const { skuList } = useSkuList();
    
    const handleClose = () => setShow(false)
    const toggleShow = () => setShow((s) => !s)

    return (
        <>
            <FontAwesomeIcon
                icon={faCommentDots}
                onClick={toggleShow}
                style={{
                    position: 'fixed', 
                    bottom: '20px',
                    left: '20px',
                    zIndex: '99999',
                    fontSize: '2rem',
                    color: 'primary',
                    cursor: 'pointer'
                }}
            >
                Chat
            </FontAwesomeIcon>
            <Offcanvas show={show} onHide={handleClose} placement='end' autofocus={true} restoreFocus={true}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{waiting && <Spinner animation="border" variant="primary" /> } Merlin Assistant </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body
                    style={{
                        height: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <MerlinAiChatbox setWaiting={setWaiting} activeWorkspaceSkus={skuList}/>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}