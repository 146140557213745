import { db } from "config/firebase.js";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { collection, query, where } from "firebase/firestore";
import { useState, useEffect } from "react";

const HeadsUpDisplay = ({ user, productDocRef }) => {

    const [headsUpData, headsUpLoading, headsUpError] = useCollectionData(query(
        collection(db, "userHeadsUpDisplay"),
        where("uid", "==", user.uid)
      ));
    
    const [dataForDisplay, setDataForDisplay] = useState(false);

    useEffect(() => {
      if (headsUpLoading || !user || !productDocRef) return;
      const userDisplaySettings = headsUpData[0];
      if (!userDisplaySettings) return;
      extractHeadsUpValues(userDisplaySettings.elements).then((data) => {
        setDataForDisplay(data);
      });
      
    }, [headsUpData])
    

    async function extractHeadsUpValues(elements) {
        const returnObject = {};
      try {
        if (elements?.ibmi) {
          Object.keys(elements?.ibmi).forEach((key) => {
                
            const data = productDocRef.data();
            const intermediateValue = data?.ibmi[key];
            if (intermediateValue) {
                returnObject[key] = intermediateValue;
            }
        
          });
        }
        return returnObject;
      } catch (error) {
        console.log(error)
      }
    }

    return (
        <div className="heads-up-display">
            <h6>Heads Up Display</h6>
            {dataForDisplay && Object.keys(dataForDisplay).map((key) => {
                const keyString = key.split("_").join(" ");
                switch (key) {

                  case "incoming_date":
                    // incomingDate is in the format YYYYMMDD
                    const today = new Date();
                    const incomingDate = dataForDisplay[key];
                    let formattedDate = incomingDate;
                    let daysDifference
                    if (formattedDate !== 'None') {
                      const year = incomingDate.slice(0, 4);
                      const month = incomingDate.slice(4, 6);
                      const day = incomingDate.slice(6, 8);
                      const incomingDateFormatted = `${month}/${day}/${year}`;
                      const fd = new Date(incomingDateFormatted);
                      formattedDate = fd.toDateString();
                      // calculate the number of days between today and the incoming date
                      const timeDifference = fd.getTime() - today.getTime();
                      daysDifference = timeDifference / (1000 * 3600 * 24);
                      
                    }
                    
                    return (
                        <table key={key}>
                            <tr>
                                <td><span className="me-3 text-capitalize">{keyString}</span></td>
                                <td><span>{formattedDate} {daysDifference > 0 && formattedDate != 'None' ? `(${Number(daysDifference).toFixed(0)} Days)` : null}</span></td>
                            </tr>
                            
                        </table>
                    )
                    
                  default:
                    return (
                        <table key={key}>
                            <tr>
                                <td><span className="me-3 text-capitalize">{keyString}</span></td>
                                <td><span>{dataForDisplay[key]}</span></td>
                            </tr>
                            
                        </table>
                    )
                }
                
                
            })}
        </div>
    )
};

export default HeadsUpDisplay;