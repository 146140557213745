import { useState, useContext, useEffect, useCallback, useRef } from 'react';
import { db, fileStorage } from "config/firebase";
import { useNavigate } from 'react-router-dom';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import PageHeader from 'components/common/PageHeader';
import { Card, Row, Col, Button, Form, Tabs, Tab, Image, Badge, Fade, Overlay, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Lottie from 'lottie-react';
import dotsAnimation from 'assets/lottie/dots.json';
import { read, utils } from 'xlsx';
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { ref, uploadBytes } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/merlin/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/merlin/advance-table/AdvanceTableFooter';
import IconButton from 'components/common/IconButton';
import { doc, collection, writeBatch } from "firebase/firestore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';


async function processFileUpload() {
    try {
        const filePath = `bulkUploads/${uuidv4()}.${filename[filename.length - 1]}`
        const bulkUploadReference = ref(fileStorage, filePath);
    } catch (error) {
        console.error(error);
    }
}

const BulkUpload = ({ children }) => {

    const { user, authLoading }= useContext(FirebaseAuthContext)
    const navigate = useNavigate();
    const [loadingState, setLoadingState] = useState(false);
    const [uploadedWorkbook, setUploadedWorkbook] = useState(null);
    const [givenHeaders, setGivenHeaders] = useState([]);
    const [givenData, setGivenData] = useState([]);
    const [wizardStep, setWizardStep] = useState(1);
    const [finalHeaders, setFinalHeaders] = useState([]);
    const [finalData, setFinalData] = useState([]);
    const [finalMapping, setFinalMapping] = useState([]);
    const [workSheets, setWorkSheets] = useState([]);
    
    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
            
                // Do whatever you want with the file contents
                const binaryStr = reader.result
                const workbook = read(binaryStr, {type: 'binary'});
                setUploadedWorkbook(workbook);
                setWorkSheets(workbook.SheetNames);
                const first_ws = workbook.Sheets[workbook.SheetNames[0]];
                const data = utils.sheet_to_json(first_ws, {header:1});
                setGivenHeaders(data[0]);
                const headers = data[0];
                const dataArray = data.slice(1);
                // for each row in the data array add the given headers as property name
                const finalData = dataArray.map((row) => {
                    const obj = {};
                    for (let i = 0; i < headers.length; i++) {
                        obj[headers[i]] = row[i];
                    }
                    return obj;
                })
                setGivenData(/**data.slice(1)*/ finalData);
            }     
            reader.readAsArrayBuffer(file)
        })
    })

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop });
    
    // Redirect to home if not logged in
    useEffect(() => {
        if (authLoading) return;
        if (!user) return navigate('/');
      }, [authLoading, user])

    const HeaderDisplay = ({headers}) => {

        const [selectedHeaders, setSelectedHeaders] = useState([]);

        async function handleClick(e, key) {
            e.preventDefault();
            const element = document.getElementById(key);
            element.classList.toggle('bg-primary');
            element.classList.toggle('bg-danger');
            manageHeaderClick(element.innerText);
        }

        function manageHeaderClick(header) {
            if (selectedHeaders.includes(header)) {
                setSelectedHeaders(selectedHeaders.filter(item => item !== header));
                
            } else {
                setSelectedHeaders([...selectedHeaders, header]);
            }
        }

        function changeHeaders(sheetName) {
            const newSheet = uploadedWorkbook.Sheets[sheetName];
            const data = utils.sheet_to_json(newSheet, {header:1});
            setGivenHeaders(data[0]);
            const headers = data[0];
            const dataArray = data.slice(1);
            // for each row in the data array add the given headers as property name
            const finalData = dataArray.map((row) => {
                const obj = {};
                if (row.length > 0) {
                    for (let i = 0; i < headers.length; i++) {
                        if (row[i] !== undefined) {
                            obj[headers[i]] = row[i];
                        }
                    }
                }
                return obj;
            })
            setGivenData(/**data.slice(1)*/ finalData); 
        }

        function handleNextClick(e) {
            e.preventDefault();
            setSelectedHeaders([...selectedHeaders, 'actions']);
            setFinalHeaders(selectedHeaders);
            setWizardStep(2);
        }

        return (
            <>  <Row>
                    <Col>
                        <h6>Available Worksheets</h6>
                        <div className='mb-3'>
                            {workSheets.map((sheet, index) => {
                                return (
                                    <Badge key={index} 
                                        className='bg-secondary me-2'
                                        onClick={() => {changeHeaders(sheet)}}
                                    >{sheet}</Badge>
                                )
                            })}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h6>Select Headers to Import</h6>
                            {headers.map((header, index) => {
                                const key = `header-${index}`;
                                return (
                                    <Badge 
                                        className="me-2" 
                                        variant="primary"
                                        onClick={(e) => {handleClick(e, key)}}
                                        key={index}
                                        id={key}
                                    >
                                        {header}
                                    </Badge>
                                )
                            })}
                        or
                        <Badge
                            className="ms-2 bg-secondary"
                        >
                            Select All
                        </Badge>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button
                            size='sm'
                            variant='primary'
                            className='mt-3 float-end'
                            disabled={selectedHeaders.length < 1}
                            onClick={(e) => handleNextClick(e)}
                        >Next</Button>
                    </Col>
                </Row>
                
            </>
            
        )
    }

    const HeaderMappingDisplay = ({ headers }) => {
        /**
         * Minimum Required:
         * - Name
         * - Cost
         * - Inventory Count
         * - SKU
         * 
         * Recommended:
         * - Description
         * - Image
         * - Brand
         * - Category
         * - Tags
         * - Specification (Weight)
         * - Specification (Dimensions) 
         */

        const [data, setData] = useState();

        const [mapping, setMapping] = useState({
            name: '',
            cost: '',
            "inventory count": '',
            sku: '',
            description: '',
            image: '',
            brand: '',
            category: '',
            tags: '',
        })

        function removeRow(e, merlinHeader) {
            e.preventDefault();
            const index = data.findIndex((row) => row.merlinHeader === merlinHeader);
            setData(() => data.filter((row, i) => i !== index));
            let temp = mapping;
            delete temp[merlinHeader];
            setMapping(temp);
        }

        function updateMapping(e, merlinHeader) {
            e.preventDefault();
            const value = e.target.value;
            setMapping({
                ...mapping,
                [merlinHeader]: value
            })
        }

        function buildColumnsForMappingDisplay() {
            const columns = [];
            columns.push({
                Header: 'Product Data',
                accessor: 'merlinHeader',
                Cell: (rowData) => {
                    const { merlinHeader } = rowData.row.original;
                    // return the header with a small help icon to the right
                    return (
                        <>
                            <span className='text-capitalize'>{merlinHeader}</span>
                            <OverlayTrigger
                                overlay={
                                    <Tooltip 
                                        id={`${merlinHeader}-tooltip`}
                                        style={{position: 'fixed'}}
                                    >
                                        This is the helpful tooltip
                                    </Tooltip>
                                }
                            >
                                <IconButton
                                    icon={faQuestionCircle}
                                    size='sm'
                                    className='ms-2 text-400'
                                    variant={"link"}
                               />
                            </OverlayTrigger>
                            
                        </>
                        
                    )
                }
            })
            columns.push({
                Header: 'Uploaded Header',
                accessor: 'uploadedHeader',
                Cell: (rowData) => {
                    const { merlinHeader } = rowData.row.original;
                    const value = mapping[merlinHeader];

                    return (
                        !value ? 
                            <Form.Select
                            onChange={(e) => updateMapping(e, merlinHeader)}
                            >   
                                <option>{" "}</option>
                                {finalHeaders.map((header, index) => {
                                    return (
                                        <option key={index}>{header}</option>
                                    )
                                })}
                            </Form.Select>
                        :
                            <span className='text-capitalize'>{value}</span>
                    )
                }
            })
            columns.push({
                Header: 'Actions',
                accessor: 'actions',
                Cell: (rowData) => {
                    const { merlinHeader } = rowData.row.original;

                    return (
                        <Button
                            size='sm'
                            variant='danger'
                            onClick={(e) => removeRow(e, merlinHeader)}
                        >Remove</Button>
                    )
                }
            })
            return columns;
        }

        function buildDataForMappingDisplay() {
            const rows = [];
            for (let i = 0; i < Object.keys(mapping).length; i++) {
                rows.push({
                    merlinHeader: Object.keys(mapping)[i],
                    uploadedHeader: Object.values(mapping)[i],
                    actions: '',
                })
            }
            return rows;
        }

        function BulAction({ selectedRowIds }){
            return (
              <Row className="flex-between-center mb-3">
                <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                  <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                    {
                      /** Object.keys(selectedRowIds).length > 0 */ false ?
                      'You have selected ' + Object.keys(selectedRowIds).length + ' rows' 
                      :
                      ''
                    }
                  </h5>
                </Col>
                <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                  {/** Object.keys(selectedRowIds).length > 0 */ false ? (
                    <div className="d-flex">
                      <Form.Select size="sm" aria-label="Bulk actions">
                        <option>Bulk Actions</option>
                        <option value="refund">Refund</option>
                        <option value="delete">Delete</option>
                        <option value="archive">Archive</option>
                      </Form.Select>
                      <Button
                        type="button"
                        variant="falcon-default"
                        size="sm"
                        className="ms-2"
                      >
                        Apply
                      </Button>
                    </div>
                    ) : (
                      <div id="orders-actions">
                        <IconButton
                          variant="falcon-default"
                          size="sm"
                          icon="plus"
                          transform="shrink-3"
                          className='me-2'
                        >
                          <span className="d-none d-sm-inline-block ms-1">New</span>
                        </IconButton>
                      </div>
                    )}
                </Col>
              </Row>
            );
        };
        
        const columns = buildColumnsForMappingDisplay();
        
        function MappingTable({ columns, data }) {

            return (
                <AdvanceTableWrapper
                    columns={columns}
                    data={data}
                    sortable
                    selection
                    selectionColumnWidth={30}
                    pagination
                    perPage={100}
                >
                    <BulAction table/>
                    <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                        striped: true,
                        className: 'fs--1 mb-0 overflow-hidden'
                    }}
                    />
                </AdvanceTableWrapper>      
            )
        }

        function handleNextClick() {
            setWizardStep(3);
            setFinalMapping(mapping);
        }

        useEffect(() => {
           
            const d = buildDataForMappingDisplay();
            setData(() =>  d);
            
        }, [mapping])

        return (
            <>
                <Row>
                    <Col>
                        <h6>Map Headers to Database Fields</h6>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {data ? 
                            <MappingTable columns={columns} data={data} />
                        :
                         null
                        }

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button
                            onClick={handleNextClick}
                            size='sm'
                            variant='primary'
                            className='float-end mt-3'
                        >Next</Button>
                    </Col>
                </Row>
            </>
        )
    }

    const DataDisplay = ({ mapping }) => {

        const [productUploadInProgress, setProductUploadStatus] = useState(false);
        const [dataColumns, setDataColumns] = useState([]);
        const [data, setData] = useState([]);

        async function buildDisplayColumns(finalMapping) {
            const columns = [];
            let index = 0;
            for (const header in finalMapping) {
                if (finalMapping[header]) {
                    switch (header) {
                        case 'image':
                            columns.push({
                                id: index,
                                accessor: header,
                                Header: `${header} (${finalMapping[header]})`,
                                size: 50,
                                Cell: (rowData) => {
                                    const { image } = rowData.row.original;
                                    return (
                                        <Image src={image} width={40} />
                                    )
                                }
                             })
                            break;
                        default:
                            columns.push({
                                id: index,
                                accessor: header,
                                Header: `${header} (${finalMapping[header]})`,
                             })
                    }
                }
                index++;
            } 

            // reorder the columns array so that the image is first
            /*
            const imageIndex = columns.findIndex((column) => column.accessor === 'image');
            const imageColumn = columns[imageIndex];
            columns.splice(imageIndex, 1);
            columns.unshift(imageColumn);
            */
            return columns;
        }

        //const columns = buildDisplayColumns(finalMapping);
          
        async function buildDataDisplay(rawData) {
            const data = [];
            rawData.forEach((row, index) => {
                if (row) {
                    const rowObject = {};

                    /** Loop through the given data to identify 
                     *  properties that match the final mapping
                     *  copy the data using the mapping into the
                     *  map
                     */
                    for (const property in row) {
                        for (const mapItem in finalMapping) {
                            if (finalMapping[mapItem] === property) {
                                rowObject[mapItem] = row[property];
                            }
                        }
                    }
                    
                    /** This cleans out any rows that don't have full
                     *  information for each of the chosen headers
                     */
                    let fullItem = true;
                    for (const property in rowObject) {
                        if (rowObject[property] === undefined) fullItem = false;
                    }
                    if (fullItem) {
                        data.push(rowObject);
                    }
                }
            })
            const processed = data.filter((row) => Object.keys(row).length > 0);
            return processed;
        }

        //const data = buildDataDisplay(givenData);

        function chunk(toBeChunkedArray, chunkSize){
            const chunkedArray = [];
            for (let i = 0; i < toBeChunkedArray.length; i += chunkSize) {
                const chunk = toBeChunkedArray.slice(i, i + chunkSize);
                chunkedArray.push(chunk);
            }
            return chunkedArray;
        }

        async function storeUploadedProductsToLaunchPad(productsToUpload, org) {
            try {
                setProductUploadStatus(true);
                // chunck productsToUpload into 500 product chunks

                /** TODO need to check if a similar product is already in the launchpad */
                const chunkedProducts = chunk(productsToUpload, 500);
                for (let i = 0; i < chunkedProducts.length; i++) {
                    const chunk = chunkedProducts[i];
                    const batch = writeBatch(db);
                    for (let j = 0; j < chunk.length; j++) {
                        const product = chunk[j];
                        product.merlinVendor = org;
                        product.launchStatus = 'pending';
                        const newProductRef = doc(collection(db, 'launchPad'));
                        batch.set(newProductRef, product);
                    }
                    await batch.commit();
                }
                setProductUploadStatus(false);
            } catch (error) {
                console.log(error)
            }
        }

        async function handleFinalImportClick(e, dataForImport) {
            e.preventDefault();
            /** TODO change org name */
            await storeUploadedProductsToLaunchPad(dataForImport, 'Testing');
            setWizardStep(4);
        }

        useEffect(() => {
          buildDisplayColumns(finalMapping).then((columns) => {
            setDataColumns(() => columns);
            buildDataDisplay(givenData).then((data) => {
                setData(() => data);
                return () => {
            
                }
            });    
          });

        }, [])
        


        return (
            <>
                <AdvanceTableWrapper
                    columns={dataColumns}
                    data={data}
                    sortable
                    pagination
                    perPage={10}
                >
                    <Row className="flex-end-center mb-3">
                    <Col>
                        <span className='fw-semi-bold fs-1'>Data Import Preview</span>
                    </Col>
                    <Col xs="auto" sm={6} lg={4}>
                        <AdvanceTableSearchBox table/>
                    </Col>
                    <Col>
                    <div className="mt-3 float-end">
                        <Button
                            onClick={(e) => handleFinalImportClick(e, data)}
                            disabled={productUploadInProgress}
                        >Import Products</Button>
                    </div>
                    </Col>
                    </Row>
                    <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                        bordered: true,
                        striped: true,
                        className: 'fs--1 mb-0 overflow-hidden'
                    }}
                    />
                    
                    <div className="mt-3">
                        <AdvanceTableFooter
                        rowCount={data.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                        />
                    </div>
              </AdvanceTableWrapper>
            </>
        )
    }


    return (
        !loadingState ?
        <>
            <PageHeader 
                title="Bulk Upload"
                className='mb-3'
            />
            {wizardStep === 1 ?
                <Card className=''>
                    <Card.Body>
                        <Row>
                            <Col>
                                {acceptedFiles.length < 1 && (
                                    <div transition={Fade}>
                                        <div {...getRootProps({ className: 'dropzone-area py-6' })}>
                                            <input {...getInputProps({multiple:false})} />
                                            <Flex justifyContent="center">
                                            <img src={cloudUpload} alt="" width={25} className="me-2" />
                                            <p className="fs-0 mb-0 text-700">Drop your spreadsheet here or click to select file</p>
                                            </Flex>
                                        </div>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer>
                        <div className="mt-3 fs--1">
                            {acceptedFiles.length > 0 && (
                            <>
                                <h6>File: {acceptedFiles[0].path} ({acceptedFiles[0].size} bytes)</h6>
                            </>
                            )}
                        </div>
                    </Card.Footer>
                </Card>
            :
                null
            }
            
            {givenHeaders.length > 0 && wizardStep === 1 ?
                <Card className='mt-3'>
                    <Card.Body>
                        <HeaderDisplay headers={givenHeaders} />
                    </Card.Body>
                </Card>
            :
                null
            }
            {wizardStep === 2 ?
                <Card className='mt-3'>
                    <Card.Body>
                        <HeaderMappingDisplay />
                    </Card.Body>
                </Card>
            :
                null
            }
            {wizardStep === 3 ?
                <Card className='mt-3'>
                    <Card.Body>
                        <DataDisplay />
                    </Card.Body>
                </Card>
            :
                null
            }
            {wizardStep === 4 ?
                <Card className='mt-3'>
                    <Card.Body>
                        <Button 
                            onClick={() => setWizardStep(1)}
                            className='me-3'
                        >Upload Another File</Button>
                        <Button
                            onClick={() => navigate('/vendors/launchpad')}
                            className='me-3'
                        >Go to Launchpad</Button>
                    </Card.Body>
                </Card>
            :
                null
            }
        </>
        :
        <Lottie animationData={dotsAnimation} style={{width: 100, height: 100}} />
    );
}

export default BulkUpload;