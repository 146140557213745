/** Inventory Heads Up Display for Product Page */
import { Spinner, Table, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import { db } from 'config/firebase';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { Line } from "react-chartjs-2";
import { getColor, rgbaColor } from "helpers/utils";
import { chartJsDefaultTooltip } from 'helpers/chartjs-utils';
import DatePicker from "react-datepicker";

export default function InventoryChart({ product }) {


    const [historyLoading, setHistoryLoading] = useState(true);
    const [quantityChangeHistory, setQuantityChangeHistory] = useState();
    const [historyBackup, setHistoryBackup] = useState();
    const [quantityOnOrder, setQuantityOnOrder] = useState();
    const date = new Date();
    const [endDate, setEndDate] = useState(new Date());
    const [startDate, setStartDate] = useState(date.setDate(date.getDate() - 7));
    const onChange = dates => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    };

    function filterDates(start, changes) {
      const startDate = new Date(start);
      const newData = [];
      
      for (let i  = 0; i < changes.length; i++) {
        const item = changes[i];
        const itemTimestamp = item.timestamp.toDate().getTime();
        const startTimestamp = startDate.getTime();
        
        const newData = []
        if (itemTimestamp >= startTimestamp) {
          newData.push(item);
        } 
      }
      return newData;
    }

    useEffect(() => {

       getInventoryHistory(product.sku).then((history) => {

            extractHistory("Quantity On Hand", history).then((quantityChanges) => {

                //const filtered = filterDates(startDate, quantityChanges);  
                //console.log(filtered)              
                setQuantityChangeHistory(quantityChanges);
                setHistoryBackup(quantityChanges);
                setHistoryLoading(false);
                
            });

       })

    }, [startDate])
    

    return (
        <>
            {true ?
                <>
                <DatePicker
                  selected={startDate}
                  onChange={onChange}
                  startDate={startDate}
                  formatWeekDay={day => day.slice(0, 3)}
                  endDate={endDate}
                  selectsRange
                  dateFormat="MMM dd"
                  className='form-control'
                />
                    {!historyLoading ? <ChartOptions chartData={quantityChangeHistory} c2={quantityOnOrder} /> : <Spinner />}
                    
                </>
              : <Spinner />
            }
            
        </>
        
    )    
    
}

async function extractHistory(field, history) {
    const historyItems = [];
    for (let i = 0; i < history.length; i++) {
       const historyItem = history[i];
       for (let j = 0; j < historyItem.changes?.length; j++) {
          const change = historyItem.changes[j];
          if (change.field === field) {
            const date = historyItem.timestamp.toDate();
            const dateString = date.toLocaleString();
            historyItems.push({date: dateString, quantity: Number(change.new), timestamp: historyItem.timestamp})
          }
       }
    }
    return historyItems;
}

async function getInventoryHistory(sku){

    const history = [];

    try {
        const q = query (
            collection(db, "productChangeHistory"),
            where("sku", "==", sku),
            orderBy("timestamp", "asc")
        )
        const snap = await getDocs(q);
        snap.forEach((doc) => {
            history.push({docId: doc.id, ...doc.data()})
        })
        return history;
    } catch (
        error
    ) {
        console.log(error);
    }
}

function ChartOptions(chartData) {
 
    const labels = []
    const cdata = []
    for (let i = 0; i < chartData.chartData.length; i++) {
        const item = chartData.chartData[i];
        labels.push(item.date)
        cdata.push(item.quantity)
    }


    const data = {
      labels: labels,
      datasets: [
        {
          type: 'line',
          label: 'Inventory Quantity Changes',
          borderColor: getColor('primary'),
          borderWidth: 2,
          fill: false,
          data: cdata,
          tension: 0.3
        }
      ]
    };
  
    const options = {
      plugins: {
        tooltip: chartJsDefaultTooltip()
      },
      scales: {
        x: {
          grid: {
            color: rgbaColor(getColor('black'), 0.1)
          }
        },
        y: {
          grid: {
            color: rgbaColor(getColor('black'), 0.1),
            drawBorder: true
          }
        }
      }
    };
    
  
      return (
        <Line data={data} options={options}  height={1000} width={1618} />
      );  

      
}
  