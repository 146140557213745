import { useState } from 'react';
import { Modal, Button, Form, Row, Col, } from 'react-bootstrap';
import { db } from 'config/firebase';
import { collection, serverTimestamp, updateDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useCollectionData } from 'react-firebase-hooks/firestore';

const SubmitBatches = ({ show, handleClose, data }) => {

    // get inventory locations
    const locationsRef = collection(db, 'locations');
    const [locations, loading, error] = useCollectionData(locationsRef, { idField: 'id' });
    const [selectedLocation, setSelectedLocation] = useState(null);

    async function handleSubmit() {
        if (!selectedLocation) {
            toast.error('Please select a location');
            return;
        }
        const submitPromises = [];
        for (let i = 0; i < data.length; i++) {
            console.log(data[i]);
            submitPromises.push(updateDoc(data[i].ref, {
                status: 'Submitted',
                timestamp: serverTimestamp()
            }));
        }
        await Promise.all(submitPromises);
        toast.success(`Batch${data.length > 1 ? 'es' : ''} submitted`);
        handleClose();
    }

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Submit Batches</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!loading && (
                    <>
                        <div>You are about to submit {data.length} {data.length > 1 ? 'batches' : 'batch'}. Are you sure you want to proceed?</div>
                        <FulfillmentLocationSelection locations={locations} setSelectedLocation={setSelectedLocation}/>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Submit {data.length} {data.length > 1 ? 'Batches' : 'Batch'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const FulfillmentLocationSelection = ({ locations, setSelectedLocation }) => {

    const handleSelection = (e) => {
        setSelectedLocation(e.target.value);
    }

    return (
        <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3" className="">
                Fulfillment Location
            </Form.Label>
            <Col sm="9">
                <Form.Select aria-label="Fulfillment Location" onChange={(e) => handleSelection(e)}>
                    <option>Select a location</option>
                    {locations.map((location, i) => {
                        return <option key={i} value={location.id}>{location.name}</option>
                    })}
                </Form.Select>
            </Col>
        </Form.Group>
    )
}

export default SubmitBatches;