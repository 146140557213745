// grid components
// value getters
// value formatters
// cell renderers
import BatchType from 'components/pages/newBatches/components/cellRenderers/BatchType';
import BatchState from 'components/pages/newBatches/components/cellRenderers/BatchState';
import BatchName from 'components/pages/newBatches/components/cellRenderers/BatchName';
import Items from 'components/pages/newBatches/components/cellRenderers/Items';

export const columnDefinition = [
    {
        headerCheckboxSelection: true,
        checkboxSelection: true,
        maxWidth: 40,
        suppressSizeToFit: false,
        suppressMenu: false,
        resizable: false,
        sortable: true,
    },
    {
        field: "doNotPick",
        headerName: "Type",
        filter: true,
        sortable: true,
        cellRenderer: BatchType,
    },
    {
        field: "status",
        headerName: "Status",
        filter: true,
        cellRenderer: BatchState,
    },
    {
        field: "orderBatchName",
        headerName: "Batch",
        filter: true,
        cellRenderer: BatchName,
    },
    {
        field: "items",
        headerName: "Items",
        cellRenderer: Items,
        editable: false,
    },
    {
        field: "numOrders",
        headerName: "Total Orders",
        filter: true,
    },
    {
        field: "timestamp",
        headerName: "Date",
        filter: true,
        valueFormatter: (params) => {
            if (!params.value) return null;
            const firebaseTimestamp = params.value;
            const d = firebaseTimestamp.toDate();
            return d.toLocaleDateString();
        },
    }
]