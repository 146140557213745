const rowClass = "fs--2 m-0 p-0";
const headerClass = "fs--2 fw-bold";
const cellClass = "fs--2 m-0 p-0";

export const gridOptions = {
    animateRows: true,
    enableAdvancedFilter: true,
    checkboxSelection: true,
    getRowId: params => params.data?.docId,
    pagination: true,
    paginationPageSize: 100,
    paginationPageSizeSelector: [100, 200, 300, 400, 500, 1000, 2000, 5000],
    rowSelection: 'multiple',
    rowClass: rowClass,
    headerClass: headerClass,
    cellClass: cellClass,
    sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: false,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressSideButtons: false,
              suppressColumnFilter: false,
              suppressColumnSelectAll: false,
              suppressColumnExpandAll: false,
            },
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
      },
    suppressModelUpdateAfterUpdateTransaction: true,
};