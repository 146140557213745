import React, { useState, useEffect } from 'react';
import { Table, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from 'config/firebase';
import { doc, setDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import {
  Container,
  Button,
  Link,
  lightColors,
  darkColors
} from 'react-floating-action-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLock,
  faPencilAlt,
  faMagic
} from '@fortawesome/free-solid-svg-icons';

function ProductDetails(product) {
  const sku = product.product.ibmi_sku;
  const wandEdit = product.wandEdit;
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const incomingDateRaw = product.product.ibmi_incoming_date;
  const incomingDate = incomingDateRaw.replace(
    /(\d{4})(\d{2})(\d{2})/g,
    '$1-$2-$3'
  );
  const displayDate = new Date(incomingDate).toDateString();
  const [editActive, setEditActive] = useState(wandEdit);

  const [formEcommTitle, setFormEcommTitle] = useState(
    product.product.sell_Title
  );
  const [formASIN, setFormASIN] = useState(product.product.sell_ASIN);

  function handleWandEvent(wandEvent) {
    switch (wandEvent) {
      case 'edit':
        setEditActive(true);
        break;
      case 'save':
        setEditActive(false);
        processChanges();
        break;
      default:
    }
  }

  function processChanges() {
    const ecommTitle = document.getElementById('ecommTitle');
    if (ecommTitle.defaultValue !== ecommTitle.value) {
      setFormEcommTitle(ecommTitle.value);
      updateProductData(sku, 'sell_Title', ecommTitle.value);
    }
    const formASIN = document.getElementById('formAsinId');
    if (formASIN.defaultValue !== formASIN.value) {
      setFormASIN(formASIN.value);
      updateProductData(sku, 'sell_ASIN', formASIN.value);
    }
  }

  async function updateProductData(sku, field, newValue) {
    try {
      const productRef = doc(db, 'products', sku);
      const updateObject = {};
      updateObject[field] = newValue;
      console.log(setDoc(productRef, updateObject, { merge: true }));
      toast.success(`Product Data Saved`, {
        theme: 'colored'
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate('/');
    //processChanges();
  }, [loading, user, editActive, navigate]);

  return (
    <>
      {!editActive ? (
        <Table>
          <tbody>
            <tr>
              <td>Friendly Title</td>
              <td>{formEcommTitle}</td>
            </tr>
            <tr>
              <td>Incoming Date</td>
              <td>{displayDate}</td>
            </tr>
            <tr>
              <td>Incoming Quantity</td>
              <td>{product.product.ibmi_incoming_quantity}</td>
            </tr>
            <tr>
              <td>Maximum Order Quantity</td>
              <td>{product.product.ibmi_max_order_qty}</td>
            </tr>
            <tr>
              <td>Topicz IBMi Name</td>
              <td>{product.product.ibmi_name}</td>
            </tr>
            <tr>
              <td>Topicz IBMi Size</td>
              <td>{product.product.ibmi_size}</td>
            </tr>
            <tr>
              <td>ASIN</td>
              <td>{formASIN}</td>
            </tr>
            <tr>
              <td>Retail Barcode</td>
              <td>{product.product.ibmi_unit_barcode}</td>
            </tr>
            <tr>
              <td>Stock Barcode</td>
              <td>{product.product.ibmi_stock_barcode}</td>
            </tr>
            <tr>
              <td>B2B/ZiiZii Barcode</td>
              <td>{product.product.ibmi_pull_barcode}</td>
            </tr>
          </tbody>
        </Table>
      ) : (
        <Table>
          <tbody>
            <tr>
              <td>Friendly Title</td>
              <td>
                <Form.Control
                  type="text"
                  defaultValue={formEcommTitle}
                  id="ecommTitle"
                ></Form.Control>
              </td>
            </tr>
            <tr>
              <td>Incoming Date</td>
              <td>{displayDate}</td>
            </tr>
            <tr>
              <td>Incoming Quantity</td>
              <td>{product.product.ibmi_incoming_quantity}</td>
            </tr>
            <tr>
              <td>Maximum Order Quantity</td>
              <td>{product.product.ibmi_max_order_qty}</td>
            </tr>
            <tr>
              <td>Topicz IBMi Name</td>
              <td>{product.product.ibmi_name}</td>
            </tr>
            <tr>
              <td>Topicz IBMi Size</td>
              <td>{product.product.ibmi_size}</td>
            </tr>
            <tr>
              <td>ASIN</td>
              <td>
                <Form.Group>
                  <Form.Control
                    type="text"
                    defaultValue={formASIN}
                    id="formAsinId"
                  ></Form.Control>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td>Unit Barcode</td>
              <td>{product.product.ibmi_unit_barcode}</td>
            </tr>
            <tr>
              <td>Stock Barcode</td>
              <td>{product.product.ibmi_stock_barcode}</td>
            </tr>
            <tr>
              <td>Pull Barcode</td>
              <td>{product.product.ibmi_pull_barcode}</td>
            </tr>
          </tbody>
        </Table>
      )}
      <Container>
        {editActive ? (
          <Link
            tooltip="Save Product Data"
            display={'none'}
            styles={{
              backgroundColor: lightColors.green,
              color: lightColors.black
            }}
          >
            <FontAwesomeIcon
              icon={faLock}
              onClick={() => handleWandEvent('save')}
            />
          </Link>
        ) : (
          <Link tooltip="Edit Product Data" display={'none'}>
            <FontAwesomeIcon
              icon={faPencilAlt}
              onClick={() => handleWandEvent('edit')}
            />
          </Link>
        )}

        <Button
          rotate={true}
          styles={{
            backgroundColor: darkColors.blue,
            color: lightColors.white
          }}
        >
          <FontAwesomeIcon icon={faMagic} />
        </Button>
      </Container>
    </>
  );
}

ProductDetails.propTypes = {
  product: PropTypes.any,
  wandEdit: PropTypes.bool
};

export default ProductDetails;
