// System Imports
import { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

// AG-Grid
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'assets/css/ag-theme-merlin.css';
import { LicenseManager } from  'ag-grid-enterprise'    
LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-049307 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( ARC Limited )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Merlin )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Merlin )_need_to_be_licensed___( Merlin )_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 16 October 2024 )____[v2]_MTcyOTAzMzIwMDAwMA==aadeefbfe111df847d24eaed4ef57023")

import { useCollection } from 'react-firebase-hooks/firestore';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import { db, realtime } from 'config/firebase';
import {
    collection,
    query,
    orderBy,
    getDocs,
    where,
    doc,
    updateDoc,
    collectionGroup,
    addDoc,
    serverTimestamp,
    onSnapshot,
    getDoc,
    limit,
} from 'firebase/firestore';
import { Card } from 'react-bootstrap';

// grid settings
import { columnDefinition } from 'components/pages/newOrders/config/columnDefinition';
import { gridOptions } from 'components/pages/newOrders/config/gridOptions';

// menu bar
import { MenuBar } from 'components/pages/newOrders/components/OrdersMenuBar';
import { ActionsContext } from 'context/OrdersMenuBarActionsContext';

// modals
import HelpModal from 'components/pages/newOrders/components/modals/HelpModal';
import CreateNewBatchModal from 'components/pages/newOrders/components/modals/CreateNewBatch';
import AddToBatch from 'components/pages/newOrders/components/modals/AddToBatch';
import RemoveFromBatch from 'components/pages/newOrders/components/modals/RemoveFromBatch';

const AdvancedOrdersGrid = () => {

    const { user, authLoading }= useContext(FirebaseAuthContext)
    const navigate = useNavigate();
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [rowData, setRowData] = useState([]);
    const [orders, setOrders] = useState([]);
    const [quickFilterValue, setQuickFilterValue] = useState(null);
    const quickFilterInputRef = useRef(null);
    const [selectedData, setSelectedData] = useState([]);

    // modal state
    const [showHelpModal, setShowHelpModal] = useState(false);
    const [showCreateNewBatchModal, setShowCreateNewBatchModal] = useState(false);
    const [showAddToBatchModal, setShowAddToBatchModal] = useState(false);
    const [showRemoveFromBatchModal, setShowRemoveFromBatchModal] = useState(false);

    useEffect(() => {
        if (authLoading) return;
        if (!user) return navigate('/login');
    }, [authLoading, user]);

    useEffect(() => {
        if (quickFilterInputRef.current) {
            quickFilterInputRef.current.addEventListener('input', () => {
                setQuickFilterValue(quickFilterInputRef.current.value);
            });
            return () => {
                quickFilterInputRef.current.removeEventListener('input', () => {
                    setQuickFilterValue(quickFilterInputRef.current.value);
                });
            };
        }
        return () => {}
    }, [quickFilterInputRef]);

    const [ordersCollection, ordersLoading, ordersError] = useCollection(
        query(
            collection(db, 'processedOrders'),
            orderBy('createDate', 'desc'),
            limit(1000)
        ),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    useEffect(() => {
        if (ordersLoading) return;
        if (ordersError) {
            console.log(ordersError);
            return;
        }
        if (ordersCollection) {
            setOrders(ordersCollection.docs.map(doc => {
                let temp = doc.data()
                // get the first item warehouse location
                if (temp.items && temp.items.length > 0) {
                    temp['warehouseLocation'] = temp.items[0].warehouseLocation;
                }
                temp['docId'] = doc.id
                temp['ref'] = doc.ref
                return temp
            }));
        }
        return () => {}
    }, [ordersCollection, ordersLoading, ordersError]);

    useEffect(() => {
        if (orders.length > 0) {
            setRowData(orders);
        }
        return () => {}
    }, [orders]);

    const defaultColDef = useMemo(() => {
        return {
          editable: true,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
          filter: true,
          flex: 1,
          minWidth: 100,
          resizable: true,
          sortable: true,
        };
      }, []);

    const [colDefs, setColDefs] = useState(columnDefinition);

    const additionalOptions = {
        onGridReady: (params) => {
            setGridApi(params.api);
            setGridColumnApi(params.columnApi);
        },
        columnDefs: colDefs,
        rowData: rowData,
        defaultColDef: defaultColDef,
        quickFilterText: quickFilterValue,
    }
    const combinedOptions = { ...gridOptions, ...additionalOptions };

    // menu bar
    async function handleAction(actionType, payload) {
        const selectedNodes = gridApi.getSelectedNodes();
        const selectedDataRaw = selectedNodes.map(node => node.data);
        const selectedData = selectedDataRaw.map(node => {
            let temp = node;
            temp['ref'] = doc(db, 'processedOrders', node.docId);
            return temp;
        });
        setSelectedData(selectedData);
        switch (actionType) {
            case 'GET_HELP':
                setShowHelpModal(!showHelpModal);
                break;
            case 'ADD_TO_BATCH':
                setShowAddToBatchModal(!showAddToBatchModal);
                break;
            case 'CREATE_NEW_BATCH':
                setShowCreateNewBatchModal(!showCreateNewBatchModal);
                break;
            case 'REMOVE_FROM_BATCH':
                setShowRemoveFromBatchModal(!showRemoveFromBatchModal);
                break;
            default:
                console.log('default');
                break;
        }
        gridApi.refreshCells();
    }

    return (
        <div>
            <ActionsContext.Provider value={{handleAction}}>
            <Card>
                <div className='ps-2 pe-2 pb-2 pt-1 rounded-top bg-primary align-middle'>
                    <div className='d-inline-block fw-semi-bold fs-1 text-white'>
                        <MenuBar />
                    </div>
                    <div className='d-inline-block float-end  fs--1 rounded mt-1 text-white w-50 pe-2' style={{ position: 'relative' }} >
                    
                        <input
                            className='form-control form-control-sm rounded-pill bg-white border-0 text-dark'
                            type='text'
                            placeholder='Search'
                            aria-label='Search'
                            ref={quickFilterInputRef}
                        />
                        {quickFilterValue && (
                            <button
                                onClick={() => {
                                    setQuickFilterValue(null)
                                    quickFilterInputRef.current.value = '';
                                }}
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    right: '10px',
                                    transform: 'translateY(-50%)',
                                    border: 'none',
                                    background: 'none',
                                    cursor: 'pointer'
                                }}
                            >
                                Clear
                            </button>
                        )}
                    </div>
                </div>
                <div
                    className="ag-theme-alpine ag-theme-merlin"
                    style={{ width: '100%', height: '84vh', maxHeight: '85vh' }}
                >
                    <AgGridReact className="border-0" {...combinedOptions} />
                </div>
            </Card>
            <HelpModal show={showHelpModal} handleClose={() => setShowHelpModal(!showHelpModal)} context={{type: 'orders'}} />
            <AddToBatch show={showAddToBatchModal} handleClose={() => setShowAddToBatchModal(!showAddToBatchModal)} data={selectedData} />
            <RemoveFromBatch show={showRemoveFromBatchModal} handleClose={() => setShowRemoveFromBatchModal(!showRemoveFromBatchModal)} data={selectedData} />
            {user && (
            <CreateNewBatchModal show={showCreateNewBatchModal} handleClose={() => setShowCreateNewBatchModal(!showCreateNewBatchModal)} uid={user.uid}/>
            )}
            </ActionsContext.Provider>
        </div>
    );
}

export default AdvancedOrdersGrid;