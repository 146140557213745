import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { Card, Row, Col, Image } from 'react-bootstrap';
import { auth, db } from 'config/firebase';
import { collection, getDocs, orderBy, query, where} from 'firebase/firestore';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import PageHeader from 'components/common/PageHeader';
import Lottie from 'lottie-react';
import dotsAnimation from 'assets/lottie/dots.json';


const Favorites = () => {
    const [user, authLoading] = useAuthState(auth);
    const navigate = useNavigate();
    const [favoritesList, setFavoritesList] = useState(["loading"]);
    const [favoritesLoading, setFavoritesLoading] = useState(true);

    async function getUserFavorites(uid) {
        try {
            const userFaves = [];
            const favoritesQuery = query(
                collection(db, "favorites"),
                where("uid", "==", uid),
                where("status", "==", true),
                orderBy("lastUpdate", "desc")
            )
            const snapshot = await getDocs(favoritesQuery);
            snapshot.forEach(doc => {
                let temp = doc.data();
                temp.id = doc.id;
                userFaves.push(temp);
            })
            setFavoritesList(userFaves)
            return userFaves;
        } catch (error) {
          console.log(error);
        }    
    }
    
    useEffect(() => {
        if (authLoading) return;
        if (!user) return navigate('/');
        
        getUserFavorites(user.uid);
        setFavoritesLoading(false);

      }, [user, authLoading, navigate]);

    const columns = [
        {
          accessor: 'lastUpdate',
          Header: 'Last Update',
          Cell: data => {
            const { lastUpdate } = data.row.original;
            const dateString = lastUpdate?.toDate().toLocaleString();
            
            return (
                <span>{dateString}</span>
            )
          }
        },
        {
            accessor: 'name',
            Header: 'Product',
            Cell: data => {
                const { 
                    sku, 
                    link,
                    name,
                    img 
                } = data.row.original;

                return (
                    <div>
                        <Row xs={6} md={6} lg={6}>
                            <Col>
                                <Image src={img} style={{width: '4rem'}}/>
                            </Col>
                            <Col style={{"alignItems": "center"}}>
                                <a href={link}>
                                <Row><Col>{sku}</Col></Row>
                                <Row><Col>{name}</Col></Row>
                                </a>
                            </Col>
                        </Row>
                    </div>
                )
            }
        },
        
    ];

    return (
        <>
        <PageHeader
            title="Favorites" titleTag="h5" className="mb-3">
            <div className='mt-4'>

            </div>
        </PageHeader>
        {!authLoading && !favoritesLoading ? 
                <Card className="">

                    <AdvanceTableWrapper
                        columns={columns}
                        data={favoritesList}
                        sortable
                        pagination
                        setPageSize
                        >
                        <div className="m-3">
                            <Row className="flex-end-center mb-3">
                            <Col>
                                <AdvanceTableSearchBox table/>
                            </Col>
                            </Row>
                            <AdvanceTableFooter
                                rowCount={favoritesList.length}
                                table
                                rowInfo
                                navButtons
                                rowsPerPageSelection
                            />
                        </div>
                        
                        <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            bordered: false,
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                        />
                        <div className="m-3"><AdvanceTablePagination table /></div>
                        
                    </AdvanceTableWrapper>
                </Card>
                :
                <div>
                        <Row className="align-items-center">

                        <Col>
                            <Lottie animationData={dotsAnimation} style={{height: 300}} />
                        </Col>

                        </Row>
                    </div>
            }
        </>
    )
        
    
}

export default Favorites;