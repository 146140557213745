import Notification from 'components/notification/Notification';
// import { notifications } from 'data/notification/notification';
import React, { useState, useEffect, useContext } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { db } from 'config/firebase';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import { collection, query, getDocs, where, limit, updateDoc, orderBy } from 'firebase/firestore';


const collectUserNotificationsSettings = async (userId) => {
  const userNotificationsRef = collection(db, "notificationMeta");
  const userNotificationsSnapshot = await getDocs(query(userNotificationsRef, where("uid", "==", userId)));
  const userNotifications = [];
  userNotificationsSnapshot.forEach((doc) => {
    userNotifications.push(doc.data());
  });
  return userNotifications[0];
}

const collectUserNotifications = async (userSettings) => {
  const activeTopics = userSettings?.activeTopics;
  // for each active topics make a request to the notifications collection
  const notificationsRef = collection(db, "notifications");
  const unreadNotificationsSnapshot = await getDocs(
    query(notificationsRef, 
      where("topic", "in", activeTopics),
      where("timestamp", ">", userSettings.lastRead),
      orderBy("timestamp", "desc"),
    )
  );
  const unreadNotifications = [];
  unreadNotificationsSnapshot.forEach((doc) => {
    let temp = doc.data();
    temp.id = doc.id;
    unreadNotifications.push(temp);
  });
  // sort by timestamp newest first
  unreadNotifications.sort((a, b) => (a.timestamp > b.timestamp) ? -1 : 1);

  const previousNotificationsSnapshot = await getDocs(
    query(notificationsRef,
      where("topic", "in", activeTopics),
      where("timestamp", "<=", userSettings.lastRead),
      limit(250)
    )
  );
  const previousNotifications = [];
  previousNotificationsSnapshot.forEach((doc) => {
    let temp = doc.data();
    temp.id = doc.id;
    previousNotifications.push(temp);
  });
  // sort by timestamp newest first
  previousNotifications.sort((a, b) => (a.timestamp > b.timestamp) ? -1 : 1);

  return { unread: unreadNotifications, previous: previousNotifications};
}

const buildNotificationFormat = (notification, unread) => {
  try {
    const timestamp = notification?.timestamp.toDate();
    const time = timestamp.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
    const date = timestamp.toLocaleDateString();
    return {
      id: notification.id,
      avatar: {
        src: 'https://via.placeholder.com/500x500.png?text=Avatar',
        size: '2xl'
      },
      children:
        `<div><strong>${notification.name}</strong> ${notification.description}</div>`,
      time: `${date} ${time}`,
      emoji: '💬',
      className: 'rounded-0 border-x-0 border-300 border-bottom-0',
      to: notification.url,
      unread: unread,
      keyAction: notification.keyAction
    }
  } catch (error) {
    console.log(error)
  }
  
}

const Notifications = () => {

  const [newNotifications, setNewNotifications] = useState([]);
  const [earlierNotifications, setEarlierNotifications] = useState([]);
  const { user, authLoading }= useContext(FirebaseAuthContext);
  const [isAllRead, setIsAllRead] = useState(true);

  useEffect(() => {
    if (authLoading) return;
    if (!user) return;
    let isSubbed = true;
    collectUserNotificationsSettings(user.uid).then((userSettings) => {
      collectUserNotifications(userSettings).then((notifications) => {
        const newNotificationData = notifications.unread;
        const earlierNotificationData = notifications.previous;
        if (isSubbed) {
          if (newNotificationData.length < 1) setIsAllRead(true);
          else setIsAllRead(false);
          setNewNotifications(() => newNotificationData.map((notification) => buildNotificationFormat(notification, true)));
          setEarlierNotifications(() => earlierNotificationData.map((notification) => buildNotificationFormat(notification, false)));
        }
      })
    })
    return () => isSubbed = false;
  }, [authLoading, user])

  return (
    <>
      {newNotifications && newNotifications.length > 0 &&
        <Card>
          <Card.Header className="bg-light">
            <Row className="flex-between-center">
              <Col sm="auto">
                <h5 className="mb-1 mb-md-0">New Notifications</h5>
              </Col>
              <Col sm="auto" className="fs--1">
                <Link to="#!" className="font-sans-serif ms-2 ms-sm-3">
                  Mark all as read
                </Link>
                <div className="font-sans-serif ms-2 ms-sm-3"></div>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-0">
            {newNotifications.map(notification => (
              <Notification {...notification} key={notification.id} />
            ))}
          </Card.Body>
        </Card>
      }
      {earlierNotifications && earlierNotifications.length > 0 &&
        <Card>
          <Card.Header className="bg-light">
            <Row className="flex-between-center">
              <Col sm="auto">
                <h5 className="mb-1 mb-md-0">Earlier Notifications</h5>
              </Col>
              <Col sm="auto" className="fs--1">
                <div className="font-sans-serif ms-2 ms-sm-3"></div>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-0">
            {newNotifications.map(notification => (
              <Notification {...notification} key={notification.id} />
            ))}
          </Card.Body>
          <Card.Body className="p-0">
            {earlierNotifications.map(notification => (
              <Notification {...notification} key={notification.id} />
            ))}
          </Card.Body>
        </Card>
      }
    </>
    
  );
};

export default Notifications;
