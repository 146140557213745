import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Button, Form, Modal, Alert, Image, Card, Row, Col } from 'react-bootstrap';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { auth, db } from 'config/firebase';
import { doc, where, query, getDoc, collectionGroup, getDocs, addDoc, collection, limit, setDoc } from 'firebase/firestore';
import { QRCodeSVG } from 'qrcode.react';
import JsBarcode from 'jsbarcode';

const NewSlot = (props) => {
 
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const [newSlotStep, setNewSlotStep] = useState(1);
    const [feedbackMessage, updateFeedback] = useState("");
    const [showFeedback, setShowFeedback] = useState(false);
    const [variants, setVariants] = useState([]);
    const [productName, setProductName] = useState("");
    const [productImage, setImage] = useState("");
    const [productFactor, setProductFactor] = useState(1);
    const [parentSku, setParentSku] = useState('');
    const [variantSku, setVariantSku] = useState('');
    const [currentSlots, setCurrentSlots] = useState('');
    const [alertStyle, setAlertStyle] = useState('primary');
    const [slotLocation, setSlotLocation] = useState({
      row: '',
      section: '',
      shelf: '',
      slot: ''
    })
    
    //topicz only feature
    const [mainWarehouseSlot, setMainSlot] = useState('');

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate('/');
        
    }, [user, loading, navigate]);


    function progressSwitcher(stageNumber, showFeedback, userMessage, style) {
      setNewSlotStep(stageNumber);
      updateFeedback(userMessage);
      setShowFeedback(showFeedback);
      if (typeof style != 'undefined') setAlertStyle(style);
      if (typeof style == 'undefined') setAlertStyle('primary');
    }

    async function lookupProduct(e){
        e.preventDefault();
        const sku = document.getElementById("productForSlot").value;
        // const reference = doc(db, "products", sku);
        // const snapshot = await getDoc(reference);
        const q = query(
          collection(db, `productCatalog/topicz/products`),
          where('sku', '==', sku) 
        );
        const snapshot = await getDocs(q);
        if (snapshot.empty) {
          progressSwitcher(1, true, "No product found with that SKU", "danger");
          
        } else {
          const productData = [];
          snapshot.forEach((doc) => {
            // projects/stk-merlin/databases/(default)/documents/[productCatalog]/Topicz/products/123456
            if (doc.ref._path.segments[5] === 'productCatalog') {
              // product belongs to migrated product catalog
              productData.push({...doc.data(), ref: doc.ref, docId: doc.id});
            }
          })
          if (productData.length > 1){
            // multiple variants found
            console.log("Multiple products found", productData)
          }
          let temp = productData[0];
          setImage(temp.primaryImage);
          setProductName(temp.name);
          setMainSlot(temp?.ibmi?.slot);
          setParentSku(temp?.sku);
          const currentSlots = query(
            collection(db, "locationCountData"),
            where('parentSku', '==', temp?.sku)
          );
          const slots = await getDocs(currentSlots);
          const slotList = [];
          slots.forEach((slot) => slotList.push(slot.data()));
          setCurrentSlots(slotList);
          const variants = query(
            collectionGroup(db, "variants"),
            where('parentSku', '==', temp?.sku)
          )
          const variantList = await getDocs(variants);
          const tempList = [];
          variantList.forEach((variant) => {
              if (variant.ref._path.segments[5] === 'productCatalog') {
                tempList.push(variant.data());
              }
          })
          setVariants(tempList);
          progressSwitcher(2, true, "Product found. Select Variant for New Slot");
        }
    }

    async function processVariantForSlot(e) {
      e.preventDefault();
      const variantValue = document.getElementById("selectedVariant").value;
      const selectedVariant = variants.find((variant) => variant.sku === variantValue);
      setProductFactor(selectedVariant.productFactor);
      setVariantSku(selectedVariant.sku);
      progressSwitcher(3, true, `Selected ${variantValue}. Enter location information.`)
     
    }

    async function setLocationInfo(e) {
      e.preventDefault();
      const row = document.getElementById("rowLocation").value;
      const section = document.getElementById("sectionLocation").value;
      const shelf = document.getElementById("shelfLocation").value;
      const slot = document.getElementById("slotIdReference").value;
      const temp = {
        row: row,
        section: section,
        shelf: shelf,
        slot: slot
      }
      setSlotLocation(temp);
      progressSwitcher(4, true, `Slot location data saved... Please confirm below`)
    }

    async function pickSlotName(e) {
      e.preventDefault();
     
        const shortCode = props.location.shortCode;
        const slotId = `${shortCode}-${mainWarehouseSlot}`;
        const elem = document.getElementById("slotIdReference");
        elem.value = slotId;
      
      
    }

    async function uploadNewSlot(slotData) {
      try {
        const docRef = await addDoc(
          collection(db, "locationCountData"), slotData);
        return docRef.id
      } catch (error) {
        console.log(error);
      }
    }

    async function checkForExistingSlot(sku) {
      let result;
      try {
        const q = query(
          collection(db, "locationCountData"),
          where("sku", "==", sku)
        )
        const snapshot = await getDocs(q);
        if (!snapshot.empty) {
          snapshot.forEach(doc => {
            console.log(doc)
            result = doc.id;
          })
        } else {
          result = "";
        }
      } catch (error) {
        console.log(error);
      }
      if(result) {
        console.log(result)
        return result;
      }
    }

    function downloadStringAsFile(data, filename) {
      let a = document.createElement('a');
      a.download = filename;
      a.href = data;
      a.click();
    }

    async function storeQRCode(variantSku, fileURI) {
      let slotId;
      try {
        const q = query(
          collection(db, "locationCountData"),
          where("sku", "==", variantSku),
          limit(1)
        )
        const snapshot = await getDocs(q);
        snapshot.forEach(doc => slotId = doc.id)
        const docRef = doc(db, "locationCountData", slotId);
        await setDoc(docRef, {qrCode: fileURI}, {merge: true})
      } catch (error) {
        console.log(error)
      }
    }

    async function saveQRCode(e) {
      e.preventDefault();
      const node = document.getElementById("generatedQrCode");
      const serializer = new XMLSerializer();
      const fileURI =
      'data:image/svg+xml;charset=utf-8,' +
      encodeURIComponent(
        '<?xml version="1.0" standalone="no"?>' +
          serializer.serializeToString(node)
      );
      storeQRCode(variantSku, fileURI);
      downloadStringAsFile(fileURI, `${variantSku}-svg-qr.svg`)
    }

    async function saveNewSlot(e) {
      e.preventDefault();
      let initialCount = document.getElementById("initialCount").value;
      if (isNaN(initialCount)) {
        progressSwitcher(newSlotStep, true, 'Please enter a number for the Initial Count', 'danger')
        return;
      }
      const slotNotes = document.getElementById("slotNotesControl").value;
      const finalSlotData = {
        name: productName,
        row: slotLocation.row,
        section: slotLocation.section,
        shelf: slotLocation.shelf,
        slot: slotLocation.slot,
        parentSku: parentSku,
        sku: variantSku,
        factor: productFactor,
        count: initialCount,
        note: slotNotes,
        locationId: props.location.id,
        lastUpdated: new Date()
      };
      const replicaCheck = await checkForExistingSlot(finalSlotData.sku);
      if (replicaCheck) {
        progressSwitcher(6, true, 'Error - there is already a slot with this sku at this location', 'danger')
        
      } else { 
        
        const res = await uploadNewSlot(finalSlotData);
        if (res) {
          progressSwitcher(5, true, `Slot saved with ID ${res}`)

          JsBarcode("#barcode")
          .options({font: "OCR-B"}) // Will affect all barcodes
          .CODE128(res, {fontSize: 18, textMargin: 0})
          .blank(20) // Create space between the barcodes
          .CODE128(String(finalSlotData.sku), {height: 85, textPosition: "top", fontSize: 16, marginTop: 15})
          .render();
          
          console.log(finalSlotData)
        } else {
          progressSwitcher(6, true, `Something went wrong...`)
        }
      }
      
    }

    function cancelOrClose(e) {
      setMainSlot('');
      setSlotLocation('');
      setModalShow(false);
      setNewSlotStep(1);
      updateFeedback("");
      setShowFeedback(false);
      setVariants([]);
      setProductName('');
      setImage('');
      setProductFactor('');
      setParentSku('');
      setVariantSku('');
      setSlotLocation('');
      
    }

  return (
    <>
      <Button variant="success" disabled={props.disabled} onClick={() => setModalShow(true)}>
        New Slot
      </Button>

      <Modal
        show={modalShow}
        onHide={() => cancelOrClose()}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Add New Slot</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            { showFeedback ? <Alert variant={alertStyle}>{feedbackMessage}</Alert> : null }
            {
                newSlotStep === 1 ?
                    <Form onSubmit={(e) => lookupProduct(e)} id="newSlotForm">
                        <Form.Group className="mb-3" controlId="productForSlot">
                            <Form.Label>Enter Product Number</Form.Label>
                            <Form.Control type="text" placeholder="Enter SKU / Product Number" name="productNumber"/>
                        </Form.Group>
                        <Button variant="success" type="submit">Look Up</Button>
                    </Form>
                :
                null
            }
            {
                newSlotStep === 2 ?
                  <>
                    <Card>
                    <Form onSubmit={(e) => processVariantForSlot(e)} >
                        <Row>
                            <Col md="auto" className='m-4'>
                              <Image src={productImage} rounded height={'100px'}></Image>
                            </Col>
                            <Col md="auto" className='m-4'>
                                <span className='fw-semi-bold'>{productName}</span>
                            </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className='m-3'>{currentSlots[0] ? <h4>Current Slot Locations</h4> : null}</div>
                            {currentSlots.map((slot) => {
                              return <ul key={`${slot.slot}-${slot.sku}`}>{slot.sku} Qty: {slot.count} @ {slot.row}-{slot.section}-{slot.shelf} in Slot {slot.slot}</ul>
                            })}
                          </Col>
                        </Row>
                        <Row>
                            <Col style={{margin: '20px'}}>
                                    <Form.Select aria-label="Select product variant for slot" id="selectedVariant">
                                        {variants.map((variant, index) => {
                                            let shortname = variant.title.split(' ').slice(0, 4).join(' ');
                                            return <option key={`${variant.parentSku}_${variant.sku}_${index}`} value={variant.sku}>{variant.sku} {shortname} (Factor {variant.productFactor})</option>
                                        })}
                                    </Form.Select>
                            </Col>
                        </Row>
                        <div className='m-3'>
                          <Row>
                            <Col xs={6}>
                              <Button onClick={() => {progressSwitcher(newSlotStep-1, false, '')}}
                                        className={'mt-4'} variant="falcon-primary">Back</Button>
                            </Col>
                            <Col>
                              <Button className="mt-4 float-end" variant="primary" type="submit">Next</Button>
                            </Col>
                          </Row>
                        </div>
                        
                      </Form>
                    </Card>
                    
                  </>
                    
                :
                null
            }
            {
                newSlotStep === 3 ?
                  <>
                  <Form onSubmit={(e) => setLocationInfo(e)} id="slotLocationData">
                    <Card className="m-3">
                        <Row>
                            <Col md="auto" className='m-4'>
                                
                                <p>
                                The slot configuration wizard allows you to set custom Row, Section, Shelf & Slot data for each product stocked in this inventory location
                                </p>
                                <strong>Variant Product Factor: {productFactor}</strong>
                            </Col>
                        </Row>
                        <Row className="m-1">
                          <Col md="auto" className='m-1'>
                            
                            <Form.Group>
                              <Form.Label>Row</Form.Label>
                              <Form.Control type="text" placeholder="A, B, C, etc" id={'rowLocation'}/>
                            </Form.Group>
                            
                          </Col>
                          <Col md="auto" className='m-1'>
                           
                            <Form.Group>
                              <Form.Label>Section</Form.Label>
                              <Form.Control type="text" placeholder="A, B, C, etc" id={'sectionLocation'}/>
                            </Form.Group>
                           
                          </Col>
                          <Col md="auto" className='m-1'>
                            
                            <Form.Group >
                              <Form.Label>Shelf</Form.Label>
                              <Form.Control type="text" placeholder="A, B, C, etc" id={'shelfLocation'}/>
                            </Form.Group>
                           
                          </Col>
                          <Col md="auto" className='m-1'>
                            
                            <Form.Group >
                              <Form.Label>Slot ID</Form.Label>
                              <Form.Control type="text" placeholder="A, B, C, etc" id="slotIdReference"/>
                              <Button 
                                size="sm"
                                onClick={(e) => pickSlotName(e)}
                              >Auto</Button>
                            </Form.Group>
                            
                            
                            
                          </Col>
                        </Row>
                        <div className='m-3'>
                          <Row>
                            <Col xs={6}>
                              <Button onClick={() => {progressSwitcher(newSlotStep-1, false, '')}}
                                        className={'mt-4'} variant="falcon-primary">Back</Button>
                            </Col>
                            <Col>
                              <Button className="mt-4 float-end" variant="primary" type="submit">Next</Button>
                            </Col>
                          </Row>
                        </div>
                    </Card>
                    </Form>
                  </>
                    
                :
                null
            }
            {
                newSlotStep === 4 ?
                 <>
                 <Form onSubmit={(e) => saveNewSlot(e)}>
                    <Card>
                      <Card.Body>
                      <Card.Title>Confirm Slot Details</Card.Title>
                        <Row>
                            <Col md="auto" className='m-4'>
                                <Image src={productImage} rounded height={'100px'}></Image>
                            </Col>
                            <Col md="auto" className='m-4'>
                                <h4>{productName}</h4>
                            </Col>

                        </Row>
                        <Row>
                            <Col style={{margin: '20px'}}>
                              <Row><Col>Row</Col><Col>{slotLocation.row}</Col></Row>
                              <Row><Col>Section</Col><Col>{slotLocation.section}</Col></Row>
                              <Row><Col>Shelf</Col><Col>{slotLocation.shelf}</Col></Row>
                              <Row><Col>Slot</Col><Col>{slotLocation.slot}</Col></Row>
                                
                            </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group className="mb-3">
                              <p><strong>Current Core Product Factor is {productFactor}:1 </strong></p>
                              <Form.Label>Set Initial Slot Count</Form.Label>
                              <Form.Control type="text" placeholder="Enter number of units in slot" id="initialCount"/>
                            </Form.Group>
                            <Form.Group className="mb-3" id="slotNotes">
                              <Form.Label>Notes</Form.Label>
                              <Form.Control as="textarea" rows={3} id="slotNotesControl"/>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Button className={'mt-4'} variant="success" type="submit">Save New Slot Location</Button>
                          </Col>
                        </Row>
                      </Card.Body>
                        
                    </Card>
                    </Form>
                  </>
                    
                :
                null
            }
            {
                newSlotStep === 5 ?
                 <>
                 <Form onSubmit={(e) => saveNewSlot(e)}>
                    <Card>
                      <Card.Body>
                      <Card.Title>Print Label?</Card.Title>
                        <Row>
                            <Col md="auto" className='m-4'>
                                <Image src={productImage} rounded height={'100px'}></Image>
                            </Col>
                            <Col md="auto" className='m-4'>
                                <h4>{productName}</h4>
                            </Col>

                        </Row>
                        <Row>
                            <Col md={"auto"} style={{margin: '20px'}}>
                                <div id="generatedQrCode"></div>
                                <svg id="barcode"></svg>
                            </Col>
                            <Col>
                              <p><Button variant="secondary">Print Label</Button>{' '}</p>
                              <Button onClick={(e) => saveQRCode(e)}>Save Label</Button></Col>
                        </Row>
                      </Card.Body>
                        
                    </Card>
                    </Form>
                  </>
                    
                :
                null
            }
            {
                newSlotStep === 6 ?
                <h3>Oops! Theres already a slot with this SKU</h3>
                :
                null
            }
            

          
        </Modal.Body>
        <Modal.Footer>
          <Button variant={'falcon-danger'} onClick={() => cancelOrClose()}>{newSlotStep !== 5 ? 'Cancel' : 'Close'}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );

}

export default NewSlot;