import { db } from "config/firebase";
import { toast } from "react-toastify";
import { addDoc, collection, serverTimestamp, doc, getDoc } from "firebase/firestore";

export const createAsinDiscoveryJobs = async (selectedRows) => {
    try {
        for (const row of selectedRows) {
            const { sku, title, barcodes } = row;
            await createAsinDiscoveryJob(sku, title, barcodes);
        }
        toast.success('Multiple ASIN discovery jobs created', { theme: 'colored' });
        return { success: true, message: 'Multiple ASIN discovery jobs created' };
    } catch (error) {
        console.log(error);
        toast.error(error.message, { theme: 'colored' });
        return { success: false, message: error.message };
    }
    async function createAsinDiscoveryJob(variantSku, variantTitle, barcodes) {
        try {
            await addDoc(collection(db, 'amazonApiTasks'), {
                status: "New",
                handler: "amazonApiRequestHandler",
                type: "gatherAsinIntel",
                jobData: {
                contentType: "application/json",
                httpMethod: "POST",
                inSeconds: 10,
                payload: { // sku, listPrice, shippingCost, asin
                    sku: variantSku,
                    title: variantTitle,
                    type: "UPC",
                    values: [
                        {size: "unit", value: barcodes.unit}, 
                        {size: "stock", value: barcodes.stock}, 
                        {size: "other", value: barcodes.other}
                    ],
                },
                queueName: "amazon-api-queue",
                },
                timestamp: {
                    created: serverTimestamp(),
                    updated: serverTimestamp(),
                }
            });
            return { success: true, message: 'Job created' };
        } catch (error) {
            console.log
            toast.error(error.message, { theme: 'colored' });
        }
    }
};

export const createAmazonFeeDiscoveryJobs = async (selectedRows) => {
    try {
        for (const row of selectedRows) {
            const { sku, pricingTemplate, amazon, shippingMaster, cost, productFactor, estShippingCost } = row;
            const variantCost = Number(cost * productFactor).toFixed(2);
            if (!pricingTemplate) throw new Error('No pricing template found');
            // if (!shippingMaster) throw new Error('No shipping data found. Get shipping estimates first.');
            const priceSettings = await getPricingTemplate(pricingTemplate);
            if (!priceSettings) throw new Error('No pricing template found');
            const asin = amazon.asin;
            let targetMargin = Number(priceSettings.settings.targetMargin).toFixed(2);
            // if (!targetMargin) targetMargin = 0.15;
            let formattedCost = Number(variantCost).toFixed(2);
            if (!formattedCost) throw new Error('No variant cost found');
            const price = Number(formattedCost) + ( Number(formattedCost) * Number(targetMargin) );
            const shippingCost = Number(estShippingCost).toFixed(2);
            await createAmazonFeeDiscoveryJob(sku, price, shippingCost, asin);
        }
        toast.success('Multiple Amazon fee discovery jobs created', { theme: 'colored' });
        return { success: true, message: 'Multiple Amazon fee discovery jobs created' };
    } catch (error) {
        console.log(error);
        toast.error(error.message, { theme: 'colored' });
        return { success: false, message: error.message };
    }
}

async function getPricingTemplate(templateId) {
    const pricingTemplateRef = doc(db, 'systemTemplates', templateId);
    const pricingTemplateDoc = await getDoc(pricingTemplateRef);
    if (pricingTemplateDoc.exists()) {
        return pricingTemplateDoc.data();
    } else {
        return null;
    }
}

async function createAmazonFeeDiscoveryJob(sku, price, shippingCost, asin) {
    if (!sku) {
        toast.error('No SKU provided', { theme: 'colored' });
        return { success: false, message: 'No SKU provided' };
    }

    const request = 
    {
        status: "New", // for task dispatch
        handler: "amazonApiRequestHandler",
        type: "gatherFeesForAsin", // for request handler
        jobData: {
          contentType: "application/json",
          httpMethod: "POST",
          inSeconds: 10,
          payload: {
            sku,
            listPrice: price,
            shippingCost,
            asin,
          },
          queueName: "amazon-api-queue",
        },
        timestamp: {
            created: serverTimestamp(),
            updated: serverTimestamp(),
        },
    }
    try {
        await addDoc(collection(db, 'amazonApiTasks'), request);
        return { success: true, message: 'Amazon Fee Discovery Job created' };
    } catch (error) {
        console.log
        toast.error(error.message, { theme: 'colored' });
    }
}