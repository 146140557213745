import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import { db } from 'config/firebase.js'
import { query, collection, addDoc, updateDoc, limit, where, getDocs, doc, orderBy, onSnapshot, collectionGroup, getDoc } from 'firebase/firestore';
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';
import PageHeader from 'components/common/PageHeader';
import { Card, Row, Col, Button, Form, Tabs, Tab, Image, Overlay, Tooltip } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/merlin/advance-table/AdvanceTableSearchBox';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/merlin/advance-table/AdvanceTableFooter';
import Lottie from 'lottie-react';
import dotsAnimation from 'assets/lottie/dots.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import useRoleData from 'hooks/useRoleData.js';

const NewBatchDetail = () => {

    const { user, authLoading }= useContext(FirebaseAuthContext)
    const { batchId } = useParams();
    const [batch, batchLoading, batchError] = useDocumentData(doc(db, 'batches', batchId));
    const [ordersLoading, setOrdersLoading] = useState(true);
    const [enrichmentLoading, setEnrichmentLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [enrichedOrders, setEnrichedOrders] = useState([]);
    const [roleData, orgData, userLoading, userError] = useRoleData(user?.uid);
    const navigate = useNavigate();
  
    useEffect(() => {
      if (authLoading) return;
      if (!user) return navigate('/');
    }, [authLoading, user])

    async function gatherOrderItems(orderNumber) {
        try {
            const q = query(collection(db, 'processedOrders'), where('orderNumber', '==', orderNumber))
            const orderSnapshot = await getDocs(q);
            const orders = [];
            orderSnapshot.forEach((doc) => {
                let temp = doc.data();
                temp.ref = doc.ref;
                orders.push(temp);
            });
            return orders;
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (batchLoading) return;
        if (!batch) return;
        // collect all orderIds
        const items = batch.items;
        const manualItems = items.filter(item => item.orderId === 'Manual');
        const manualOrders = [];
        if (manualItems.length > 0) {
            manualItems.forEach(item => {
                const orderItem = {
                    imageUrl: item.picture,
                    name: item.title,
                    quantity: item.quantity,
                    sku: item.sku,
                    warehouseLocation: "Manual",
                }
                manualOrders.push({
                    orderNumber: item.orderNumber,
                    orderId: item.orderId,
                    items: [orderItem]
                });
            });
        }
        const orderIds = items.map(item => item.orderId);
        const chunk = (arr, size) =>
            Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
                arr.slice(i * size, i * size + size)
            );

        const orderChunks = chunk(orderIds, 10);
        const ordersPromises = orderChunks.map(chunk => {
            const q = query(collection(db, 'processedOrders'), where('orderId', 'in', chunk));
            return new Promise(resolve => {
                const unsubscribe = onSnapshot(q, snapshot => {
                    const orders = snapshot.docs.map(doc => ({
                        ...doc.data(),
                        ref: doc.ref,
                    }));
                    resolve(orders);
                    return unsubscribe;
                });
            });
        });

        Promise.all(ordersPromises).then(chunks => {
            const orders = chunks.flat();
            const enrichedOrders = [...orders, ...manualOrders];
            setEnrichedOrders(enrichedOrders);
            setEnrichmentLoading(false);
        });

        // return unsubscribe;
    }, [batchLoading, batch])
    
    const batchColumns = [
        {
          accessor: 'orderNumber',
          Header: 'Order Number',
        },
        {
            accessor: 'items[0].sku',
            Header: 'SKU',
            Cell: rowData => {
                const { items } = rowData.row.original;
                const uniqueSkus = [...new Set(items.map(item => item.sku))]
                const uniqueImages = [...new Set(items.map(item => item.imageUrl))]
                return (
                    <>
                        {uniqueSkus.map((sku, index) => {
                            
                                return (
                                    <div key={`${index}sku`}>
                                        <Row>
                                            <Col>
                                                 
                                                <span className='fw-bold' > {sku} </span>
                                                
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            })
                        }
                        {uniqueImages.map((image, index) => {
                                return (
                                    <div key={`${index}img`}>
                                        <Row>
                                            <Col>
                                                <Image src={image} style={{width: 50}} className="mr-2" />
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            })
                        }
                            
                    </>
                )
            }
        },
        {
            accessor: 'items[0].name',
            Header: 'Name & Quantity',
            Cell: rowData => {
                const { items } = rowData.row.original;
                const itemsClone = [...items];
                const processUniqueItems = (items) => {
                    const uniqueNames = [...new Set(items.map(item => item.name))]
                    const uniqueItemsWithQuantity = [];
                    for (let i = 0; i < items.length; i++) {
                        for (let j = 0; j < uniqueNames.length; j++) {
                            let uniqueName = uniqueNames[j];
                            const uniqueSet = items.filter(item => item.name === uniqueName);
                            const count = uniqueSet.length;
                            let uniqueQuantity = 0;
                            for (let k = 0; k < uniqueSet.length; k++) {
                                uniqueQuantity += uniqueSet[k].quantity;
                            }
                            uniqueItemsWithQuantity.push({uniqueName, count, uniqueQuantity})
                        }
                    }
                    return uniqueItemsWithQuantity;
                }
                const finalItems = processUniqueItems(itemsClone);
                return (
                    <>
                        {finalItems?.map((item, index) => {
                           if (index > 0) return;
                           return (
                                <div key={index}>
                                    <Row>
                                        <Col>
                                            <span className='text-700 fs--1'>
                                                {item.uniqueName}
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className='mt-4 text-700 fw-bold fs-0 p-0'>
                                                x {item.uniqueQuantity}
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        })}
                            
                    </>
                    
                )
            }
        },
        {
            accessor: 'items[0].warehouseLocation',
            Header: 'Slot',
            Cell: rowData => {
                const { items } = rowData.row.original;
                const unique = [...new Set(items.map(item => item.warehouseLocation))]
                return (
                    <>
                        {unique.map((item, index) => {
                           return (
                                <div key={index}>
                                    <Row>
                                        <Col>
                                            <span className='text-700 fs--1'>
                                                {item}
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        })}
                            
                    </>
                    
                )
            }
        },
        {
            Header: 'Actions',
            Cell: rowData => {
                const { orderNumber, accountingExclusion, items } = rowData.row.original;
                return (
                    <>
                        {orderNumber !== "Manual Order" ?
                            !accountingExclusion ?
                                <Button
                                    size="sm"
                                    variant='falcon-danger'
                                    className="btn-icon btn-icon-only"
                                    onClick={() => {
                                        processOrderExclusion(orderNumber)
                                    }}
                                >
                                    <FontAwesomeIcon icon={['fas', 'times']} /> Remove
                                </Button>
                            :
                                <Button
                                    size="sm"
                                    variant='falcon-success'
                                    className="btn-icon btn-icon-only"
                                    onClick={() => {
                                        processOrderExclusion(orderNumber)
                                    }}
                                >
                                    <FontAwesomeIcon icon={['fas', 'plus']} /> Add
                                </Button>

                        :
                            <Button
                                    size="sm"
                                    variant='falcon-primary'
                                    className="btn-icon btn-icon-only"
                                    onClick={() => {
                                        removeManualItems(items)
                                    }}
                                >
                                    Remove Manual
                            </Button>        
                        }
                    </>   
                )
            }
        },
        
        
    ];

    async function removeManualItems (items) {
        const skusToRemove = items.map(item => item.sku);
        try {
            const batchRef = doc(db, 'batches', batchId);
            const batchDoc = await getDoc(batchRef);
            const batchData = batchDoc.data();
            const batchItems = batchData.items;
            const newItems = batchItems.filter(item => !skusToRemove.includes(item.sku));
            await updateDoc(batchRef, { items: newItems });
            toast.success('Manual items removed from batch', {
                theme: 'colored'
            })
        } catch (error) {
            console.log(error)
        }
    }


    async function processOrderExclusion(orderNumber) {
        try {
            console.log(orderNumber)
            const q = query(collection(db, 'processedOrders'), where('orderNumber', '==', orderNumber))
            const orderSnapshot = await getDocs(q);
            const orders = [];
            orderSnapshot.forEach((doc) => {
                let temp = doc.data();
                temp.ref = doc.ref;
                orders.push(temp);
            });
            orders.forEach((order) => {
                const { accountingExclusion } = order;
                let lineItem = enrichedOrders.filter((item) => item.orderNumber === orderNumber)[0];
                lineItem.accountingExclusion = !accountingExclusion;
                const lineItemIndex = enrichedOrders.findIndex((item) => item.orderNumber === orderNumber);
                // replace the item at lineItemIndex in the enrichedOrders array with the new lineItem
                setEnrichedOrders((prev) => [...prev.slice(0, lineItemIndex), lineItem, ...prev.slice(lineItemIndex + 1)])
                if (!accountingExclusion) {
                    updateDoc(order.ref, { accountingExclusion: true })
                } else {
                    updateDoc(order.ref, { accountingExclusion: false })
                }
                toast.success(!accountingExclusion ? "Order Removed": "Order Added to Batch" , {
                    theme: 'colored'
                })
            })
            
            return;
        } catch (error) {
            console.log(error)
            return error.message
        }
    }

    return (
            
        <>
            {!batchLoading ?
            <PageHeader
                title={batch.orderBatchName} titleTag="h5" className="mb-3">
                <Button size='sm' className='mt-3' variant='falcon-primary'
                    onClick={() => {navigate(`/newBatches`)}}>    
                    <FontAwesomeIcon icon={['fas', 'arrow-left']} className="mr-2"/> 
                {"   "} Return to Batches</Button>
            </PageHeader>
            : null}
        
            <Card className="p-3 mb-3">
            {!enrichmentLoading ?
                <AdvanceTableWrapper
                    columns={batchColumns}
                    data={enrichedOrders}
                    sortable
                    pagination
                    perPage={10}
                    >
                        <Row className="flex-end-center mb-3">
                        <Col xs="auto" sm={12} lg={12}>
                            <AdvanceTableSearchBox table/>
                        </Col>
                        </Row>
                        
                <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                    bordered: true,
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                }}
                />
                    <div className="mt-3">
                    <AdvanceTableFooter
                        rowCount={enrichedOrders?.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                    />
                    </div>
                </AdvanceTableWrapper>
                :
                    <div>
                        <Row className="align-items-center">
                        <Col>
                            <Lottie animationData={dotsAnimation} style={{height: 300}} />
                        </Col>
                        </Row>
                    </div>
                }
            </Card>
        </>
                 
            
    )
}

export default NewBatchDetail;



