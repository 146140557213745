import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from 'config/firebase';
import { Card, Image, Row, Col, Container, Badge, InputGroup, Button, Form, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import MissingImage from '../../assets/merlin/missing_image.png';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import Lottie from 'lottie-react';
import dotsAnimation from 'assets/lottie/dots.json';
import useRoleData from 'hooks/useRoleData.js';
import { collection, query, where, getDocs, limit, orderBy, doc, collectionGroup, addDoc } from 'firebase/firestore';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from 'components/merlin/advance-table/AdvanceTableFooter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';
import SoftBadge from 'components/common/SoftBadge';
import useFuzzyProducts from 'hooks/useFuzzyProducts.js';

import Fuse from 'fuse.js';
import * as FuseIndex from 'assets/json/merlin-index.json';

function Search() {
  const navigate = useNavigate();
  const { user, authLoading }= useContext(FirebaseAuthContext)
  const [results, setResults] = useState([]);
  const [roleData, orgData, userLoading, userError] = useRoleData(user?.uid);
  const [searchTerm, setSearchTerm] = useState();
  const [resultsLoading, setResultsLoading] = useState(false);
  const [fuzzySearch, fuzzyLoading, fuzzyError] = useFuzzyProducts();
  const merlinIndex = Fuse.parseIndex(FuseIndex);
  /*
  const [productChanges, productChangesLoading, productChangesError] = useCollectionData(
    query(
      collection(db, 'productChangeHistory'),
      orderBy('timestamp', 'desc'),
      limit(5000)
    )
  );
  */

  const [fuseProducts, fuseLoading, fuseProductsError] = useCollectionData(
    query(
      collection(db, 'fuzzySearch', 'topicz', 'fuseProducts')
    )
  );

  useEffect(() => {
    if (fuseLoading) return;
    const shuffledProducts = fuseProducts.sort(() => 0.5 - Math.random());
    let options = {
      ignoreLocation: true,
      includeScore: true,
      shouldSort: true,
      //sortFn: (a, b) => a.score < b.score,
      //keys: [/**'sku', 'brand', **/'tags', 'name', 'category'],
      keys:['tags', 'name'],
      threshold: 0.1,
      
    
    };
    const fuse = new Fuse(shuffledProducts, options, merlinIndex);  
    let searchTerm = 'pizza'
    const result = fuse.search(searchTerm);
    console.log('fuse', searchTerm, result)
  }, [fuseLoading])
  


  
  useEffect(() => {
    if (authLoading) return;
    if (!user) return navigate('/login');
 
  }, [user, authLoading, navigate]);

  async function searchProducts(searchTerm) {
    try {
      if (!searchTerm) return;
      if (fuzzyLoading) return;

      setResultsLoading(true);

      /*
      const query = full_process(searchTerm);

      const options = {
        scorer: searchScorer,
        full_process: false,
        wildcards: '*',
        unsorted: false,
        cutoff: 30,
        //limit: 100
      };
      console.log(fuzzySearch)
      const results = extract(query, fuzzySearch, options);
      console.log(results)
      
      setResultsLoading(false);

      function searchScorer(query, choice, options) {
        console.log(choice)
        const score = ratio(query, choice, options);
        return score;
      }
      */
      const hackResults = [];
      fuzzySearch.forEach((doc) => {
        const { sku, name, brand, tags, category } = doc;
        if (sku == searchTerm) {
          hackResults.push(doc);
        }
      })
      
      return hackResults;
    } catch (error) {
      console.log(error);
    }

  }

  async function handleSearchProducts(e) {
    e.preventDefault();
    const currentSearchTerm = document.getElementById('searchInput').value;
    if (String(currentSearchTerm.length) < 4) return;
    setSearchTerm(currentSearchTerm);

    const results = await searchProducts(currentSearchTerm);
    
    /*
    console.log(results)

    const searchResults = [];
    if (results?.length > 0) {
      results?.forEach((result) => {
        searchResults.push(result[0]);
      });
    }
    */
    
    setResults(results);
  }

  const columns = [
    {
      accessor: 'image',
      Header: 'Image',
      Cell: rowData => {
        const { image } = rowData.row.original;
        return (
          <Image
            src={image ? image : MissingImage}
            alt="Product Image"
            className="img-fluid"
            style={{maxHeight: 100}}
          />
        );
      }
    },
    {
      accessor: 'score',
      Header: 'Relevance',
      Cell: rowData => {
        const { score } = rowData.row.original;
        let color;
        if (score > 90) {
          color = 'success';
        } else if (score > 80) {
          color = 'warning';
        } else {
          color = 'danger';
        }
        return (
          <div className="mb-1">
            <span className="fw-bold fs--1">
              <SoftBadge bg={color} pill className="me-1">
                {score}
              </SoftBadge>
            </span>
          </div>
        );
      }
    },
    {
      accessor: 'sku',
      Header: 'SKU',
      Cell: rowData => {
        const { sku, url } = rowData.row.original;
        return (
          <div className="mb-1">
              
              <Link
                to={url}
                className="fw-bold fs--1 link-secondary text-decoration-none me-1"
              >
                {sku}
              </Link>
            </div>
        );
      }
    },
    {
      accessor: 'name',
      Header: 'Name',
      Cell: rowData => {
        const { name, url } = rowData.row.original;
        return (
          <>
            <div className="mb-1">
              
              <Link
                to={url}
                className="fw-bold fs--1 link-secondary text-decoration-none me-1"
              >
                {name}
              </Link>
            </div>
          </>
        )
      }
    },
    
  ];
  
  async function getProductDetails(sku){
    const query = await getDocs(collection(db, `productCatalog/${orgData}/products`), where('sku', '==', sku));
    const results = [];
    query.forEach((doc) => {
      let temp = doc.data();
      temp.ref = doc.ref;
      results.push(temp);
    });
    return results;
  }

  const ProductHighlights = () => {

    const productHighlightColumns = [
      {
        accessor: 'special',
        Header: 'Event',
        Cell: rowData => {
          const { special, current, old } = rowData.row.original;
          const diff = Number(current-old);
          const direction = diff > 0 ? 'up' : 'down';
          return (
            <>
              {special.map((event, index) => {
                let display;
                switch (event) {

                  case 'Large Quantity Change':
                    const percentChange = Number((current-old)/old).toFixed(2)*100;
                    display = <div key={`${event}-${index}`}>
                        <SoftBadge bg={percentChange < 0 ? "danger" : "success"} pill className="me-1">
                          {event}
                        </SoftBadge>
                        <span className='text-secondary fw-semi-bold'>
                          {percentChange < 0 ? 
                            <>  
                              <span className='text-danger'>{percentChange}%</span>
                            </>
                          : <>
                              <span className='text-success'>{percentChange}%</span>
                            </>
                          }
                        </span>
                      </div>
                    break;

                  case 'Out of Stock':
                    display = <div key={`${event}-${index}`}>
                        <SoftBadge bg="danger" pill className="me-1">
                          {event}
                        </SoftBadge>
                      </div>
                    break;

                  case 'NEXT DATE CHANGE':
                    let day, month, year;
                    //20230505
                    day = current.slice(6,8);
                    month = current.slice(4,6);
                    year = current.slice(0,4);
                    display = <div key={`${event}-${index}`}>
                        <SoftBadge bg="warning" pill className="me-1 text-lowercase">
                          <span className='text-capitalize'>{event}</span>
                        </SoftBadge>
                        <span className='text-secondary fw-semi-bold'>
                          {month && day && year ? `${month}-${day}-${year}` : "None" } 
                        </span>
                      </div>
                    break;

                  default:
                    display = <div key={`${event}-${index}`}>
                          <SoftBadge bg="primary" pill className="me-1 text-capitalize">
                            {event} 
                          </SoftBadge>
                          {direction === 'up' ?
                            <span className="text-success fw-bold">
                              <FontAwesomeIcon icon={faArrowUp} className="me-1" />
                              {diff}
                            </span>
                            :
                            <span className="text-danger">
                              <FontAwesomeIcon icon={faArrowDown} className="me-1" />
                              {diff}
                            </span>
                          }
                        </div>
                    break;
                }
                return display;
              })}
            </>
          )
        }
      },
      {
        Header: 'Product',
        Cell: rowData => {
          const { sku, name, url } = rowData.row.original;
          if (!name) return <span>{sku}</span>
          
          return (
            <>
              <div className="mb-1">
                <Link
                  to={url}
                  className="fw-semi-bold fs--1 link-secondary text-decoration-none me-1"
                >
                  {name}
                </Link>
              </div>
            </>
          )
          
        }

      },
      {
        accessor: 'timestamp.seconds',
        Header: 'Time',
        Cell: rowData => {
          const { timestamp } = rowData.row.original;
          const date = new Date(timestamp.seconds * 1000);
          return (
            <>
              <span className='fs--3'>{date.toLocaleDateString()} {date.toLocaleTimeString()}</span>
            </>
          )
        }
      }
    ];

    return (
      <>
        <Row className="align-items-center mt-3 mb-3">
          <Col>
            <span className='mb-3 fw-semi-bold'>Product Highlights</span>
          </Col>
        </Row>
        <Row>
          <Col>
          <AdvanceTableWrapper
                    columns={productHighlightColumns}
                    data={productChanges}
                    sortable
                    pagination
                    perPage={10}
                  >
                    <div className="mt-3 mb-2">
                      <AdvanceTableFooter
                        rowCount={productChanges.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                        
                      />
                    </div>
                    <AdvanceTable
                      table
                      headerClassName="bg-200 text-900 text-nowrap align-middle"
                      rowClassName="align-middle white-space-nowrap"
                      tableProps={{
                        bordered: true,
                        striped: true,
                        className: 'fs--1 mb-0 overflow-hidden'
                      }}
                    />
                    <div className="mt-3">
                      <AdvanceTableFooter
                        rowCount={productChanges.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                      />
                    </div>
                  </AdvanceTableWrapper>
          </Col>
        </Row>
      </>
    )
  }
  
  return !authLoading ? (
    <>
      <Container fluid>
        {!fuzzyLoading ?
          <Card className="p-3">
          <Row>
            <Col>
              <Form onSubmit={(e) => handleSearchProducts(e)}>
                <InputGroup size="lg">
                  <InputGroup.Text id="searchInputGroup">
                    <FontAwesomeIcon
                    icon={faSearch}
                    className="mr-2 me-2"
                    ></FontAwesomeIcon>
                    {" "}
                  </InputGroup.Text>
                  <Form.Control id="searchInput" aria-label="Search Input" aria-describedby="searchInputGroup" 
                    placeholder="Search by SKU or Tag"
                    disabled={fuzzyLoading}
                    onChange={(e) => handleSearchProducts(e)}
                  />
                </InputGroup>
              </Form>
              
            </Col>
          </Row>
          <Row>
            <Col>
                {results && results.length > 0 ?
                
                  <AdvanceTableWrapper
                    columns={columns}
                    data={results}
                    sortable
                    pagination
                    perPage={10}
                  >
                    <div className="mt-3 mb-2">
                      <AdvanceTableFooter
                        rowCount={results.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                        
                      />
                    </div>
                    <AdvanceTable
                      table
                      headerClassName="bg-200 text-900 text-nowrap align-middle"
                      rowClassName="align-middle white-space-nowrap"
                      tableProps={{
                        bordered: true,
                        striped: true,
                        className: 'fs--1 mb-0 overflow-hidden'
                      }}
                    />
                    <div className="mt-3">
                      <AdvanceTableFooter
                        rowCount={results.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                      />
                    </div>
                  </AdvanceTableWrapper>
                :
                // Default Display before search
                <div>
                  {searchTerm ?
                    <Row className="align-items-center mt-3">
                      <Col>
                        <h5>No Results Found</h5>
                        {searchTerm} - {results.length}
                      </Col>
                    </Row>
                  :
                    <>
                      {/**
                      {!productChangesLoading ? 
                        <>
                          <ProductHighlights /> 
                        </>
                        :
                        <div>
                          <Row className="align-items-center">
                            <Col>
                                <Lottie animationData={dotsAnimation} style={{height: 300}} />
                            </Col>
                          </Row>
                        </div>
                      }
                      */}
                    </>
                  }
                </div>
                }
            </Col>
          </Row>
        </Card>
        :
        <div>
          <Row className="align-items-center">
            <Col>
                <Lottie animationData={dotsAnimation} style={{height: 300}} />
            </Col>
          </Row>
        </div>
        }
        
      </Container>
    </>
  ) : (
    <div>
      <Row className="align-items-center">
        <Col>
            <Lottie animationData={dotsAnimation} style={{height: 300}} />
        </Col>
      </Row>
    </div>
  );
};

Search.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  categories: PropTypes.array,
  price: PropTypes.node
};

export default Search;
