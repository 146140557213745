import { useEffect } from 'react';

// Custom hook to handle keystrokes
const useKeystroke = (keyMap) => {
  const handleKeyPress = (event) => {
    // Detect if the user is on a Mac
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

    // Iterate over each key combination
    for (const [keyCombo, handler] of Object.entries(keyMap)) {
      const keys = keyCombo.split('+').map(k => k.trim().toLowerCase());
      const modKey = isMac ? 'cmd' : 'ctrl';
      const modKeyPressed = (isMac && event.metaKey) || (!isMac && event.ctrlKey);
      const shiftKey = keys.includes('shift') && event.shiftKey;
      const key = keys.find(k => k !== 'ctrl' && k !== 'cmd' && k !== 'shift');

      if ((keys.includes(modKey) === modKeyPressed) && 
          (shiftKey || !keys.includes('shift')) && 
          event.key.toLowerCase() === key) {
        handler();
        break;
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyMap]);
};

export default useKeystroke;
