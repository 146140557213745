import { db } from 'config/firebase';
import { addDoc, collection } from 'firebase/firestore';
import { toast } from 'react-toastify';

export const createShippingEstimateJobs = async (selectedRows) => {
    try {
        for (const row of selectedRows) {
            const { sku } = row;
            await createShippingEstimateJob(sku);
        }
        toast.success('Multiple shipping estimate jobs created', { theme: 'colored' });
        return { success: true, message: 'Multiple shipping estimate jobs created' };
    } catch (error) {
        console.log(error);
        toast.error(error.message, { theme: 'colored' });
        return { success: false, message: error.message };
    }
}

async function createShippingEstimateJob(variantSku) {
    if (!variantSku) {
        toast.error('No SKU provided', { theme: 'colored' });
        return { success: false, message: 'No SKU provided' };
    }
    const request = 
    {
        status: "new", // for task dispatch
        handler: "shipStationApiRequestHandler",
        type: "getVariantShippingEstimate", // for request handler
        jobData: {
          contentType: "application/json",
          httpMethod: "POST",
          inSeconds: 10,
          payload: {
            sku: variantSku,
          },
          queueName: "shipstation-api-queue",
        },
    }
    try {
        await addDoc(collection(db, 'shipStationApiTasks'), request);
        return { success: true, message: 'Job created' };
    } catch (error) {
        console.log
        toast.error(error.message, { theme: 'colored' });
    }
}
