import { shipStationTags } from "components/pages/newOrders/context/shipStationTags";

const checkIfDark = (hexcolor) => {
    // this function checks if the hex color is dark or light
    // if it is light it returns true, if it is dark it returns false
    // https://stackoverflow.com/questions/12043187/how-to-check-if-hex-color-is-too-black
    let r, g, b, hsp;
    hexcolor = hexcolor.replace("#", "");
    r = parseInt(hexcolor.substring(0, 2), 16);
    g = parseInt(hexcolor.substring(2, 4), 16);
    b = parseInt(hexcolor.substring(4, 6), 16);
    hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
    );
    if (hsp > 127.5) {
        return true;
    } else {
        return false;
    }
}

const TagRenderer = (params) => {
    const tagIds = params.value;
    const shipStationTagsData = shipStationTags.data;
    let tags = [];
    if (tagIds && tagIds.length > 0) {
        for (let i = 0; i < tagIds.length; i++) {
            const tagId = tagIds[i];
            const tag = shipStationTagsData[tagId];
            const style = {
                backgroundColor: `${tag.color}`
            }
            // if the background is dark, make the text light
            let textColor = 'dark';
            if (!checkIfDark(tag.color)) {
                textColor = 'light';
            }
            tags.push(<div bg="light" key={tag.tagId} style={style} className={`badge pill text-${textColor} me-1`}>{tag.name}</div>);
        }
    }
    return (
        <div>
            {tags}
        </div>
    )
};

export default TagRenderer;