import React, { useState, useEffect, useContext } from 'react';
import { Button, Card, Col, Row, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import KeyActionsPanel from './dashboardComponents/KeyActionsPanel';
import GreetingCard from 'components/dashboards/e-commerce/greetings/GreetingCardOriginal.js';
import { db } from "config/firebase";
import { collection, query, where, getDocs, limit, Timestamp } from 'firebase/firestore';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/merlin/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/merlin/advance-table/AdvanceTableSearchBox';

const Starter = () => {
  const { user, authLoading }= useContext(FirebaseAuthContext)
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(null)
  const [newProductsSinceDate, setNewProductsSinceDate] = useState(null)
  const [numberOfNewProducts, setNumberOfNewProducts] = useState(null)
  const [newProducts, setNewProducts] = useState(null)
  
  async function gatherUserData(uid) {
    try {
      const q = query(
        collection(db, "users"),
        where("uid", "==", uid),
        limit(1)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setUserDetails(doc.data())
      });

    } catch (error) {
      console.log(error)

    }
  }

  const NewProductDisplayTable = ({ products }) => {

    if (!products) {
      products = [{ name: 'Loading...'}]
    }
    const columns = [
      {
        accessor: 'name',
        Header: 'Product Name',
        Cell: ({ cell }) => {
          const { name, productUrl } = cell.row.original;
          return (
            <a href={`${productUrl}`}>{name}</a>
          )
        }
      },
      {
        accessor: 'cost',
        Header: 'Cost'
      },
      {
        accessor: 'ecomm_onhand',
        Header: 'Inventory'
      },
      {
        accessor: 'ibmi.incoming_date',
        Header: 'Expected Date',
        Cell: ({ cell }) => {
          const { ibmi } = cell.row.original;
          let displayDate = '';
          if (ibmi) {
            const { incoming_date } = ibmi;
            if (incoming_date !== 'None') {
              // incoming_date is formatted YYYYMMDD
              const year = incoming_date.slice(0,4);
              const month = incoming_date.slice(4,6);
              const day = incoming_date.slice(6,8);
              displayDate = new Date(`${year}-${month}-${day}`).toDateString();
            } else {
              displayDate = 'None'
            }
          }
          return (
            <span>{displayDate}</span>
          )
        }
      },
    ];
    
    return (

      <AdvanceTableWrapper
        columns={columns}
        data={products}
        sortable
        pagination
        perPage={10}
      >
        <Row className="flex-end-center mb-3">
          <Col xs="auto" sm={6} lg={4}>
            <AdvanceTableSearchBox table/>
          </Col>
        </Row>
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3">
          <AdvanceTableFooter
            rowCount={products.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </div>
      </AdvanceTableWrapper>

    );
  }

  async function gatherNewProducts() {
    // create a date object for the previous Sunday
    // ie if today is thursday, this will be the previous sunday
    const today = new Date();
    const previousSunday = new Date(today.setDate(today.getDate() - today.getDay()));
    previousSunday.setHours(0,0,0,0);
    const fbPreviousSunday = Timestamp.fromDate(previousSunday);
    const q = query(
      collection(db, "productCatalog/topicz/products"),
      where("timestamp.created", ">=", fbPreviousSunday),
    );
    const querySnapshot = await getDocs(q);
    const numberOfNewProducts = querySnapshot.size;
    const newProducts = [];
    querySnapshot.forEach((doc) => {
      newProducts.push(doc.data())
    });
    // sort newProducts by timestamp.created
    newProducts.sort((a, b) => {
      const aDate = a.timestamp.created.toDate();
      const bDate = b.timestamp.created.toDate();
      return bDate - aDate;
    })
    return [previousSunday, numberOfNewProducts, newProducts]
  }

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      gatherNewProducts().then((data) => {
        const [previousSunday, numberOfNewProducts, newProducts] = data;
        setNewProductsSinceDate(previousSunday)
        setNumberOfNewProducts(numberOfNewProducts)
        setNewProducts(newProducts)
      })
    }
    return () => isSubscribed = false;
  }, [])


  useEffect(() => {
    if (authLoading) return;
    if (!user) navigate('/login')
    let isSubscribed = true;
    if (isSubscribed) {
      gatherUserData(user.uid)
    }
    return () => {
      isSubscribed = false;
    }
  }, [authLoading, user, navigate])

  return (
    <>
      <Row>
        <Col xs={12} className="">
          {!authLoading && <KeyActionsPanel uid={user.uid}/> }
        </Col>
      </Row>

      {userDetails && <GreetingCard
        user={userDetails}
      /> }

      <Card className='mt-3'>
        <Card.Body className="mt-3">
          <Row className="align-items-center justify-content-between">
            
            <Col className="">
              <h4 className="text-primary">
                <strong>{numberOfNewProducts}</strong> New Products <span className='text-muted fs-1'>Since Sunday</span>
              </h4>
              <NewProductDisplayTable products={newProducts} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
    
  );
};

export default Starter;
