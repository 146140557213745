import { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import { Card, Row, Col, Button, Form, Modal, Image, InputGroup, Badge } from 'react-bootstrap';
import Lottie from 'lottie-react';
import dotsAnimation from 'assets/lottie/dots.json';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { db } from "config/firebase";
import { 
    query, collection, orderBy, startAt, limit, startAfter, endAt,
    getDocs, endBefore, addDoc, collectionGroup, where, getDoc, doc, updateDoc,
    getCountFromServer, serverTimestamp,
} from 'firebase/firestore';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import IconButton from 'components/common/IconButton';
import FalconCloseButton from 'components/common/FalconCloseButton';
import DatePicker from 'react-datepicker';
import words from './words.json';
import { toast } from 'react-toastify';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import AdvanceTableSearchBox from 'components/merlin/advance-table/AdvanceTableSearchBox';
// import getInventoryData from 'hooks/productHooks/getInventoryData';

async function initialLoadCollectOrders(filter, limitAmt) {

    let storeFilterWhere = null;
    if (filter !== 'all') {
        storeFilterWhere = where("advancedOptions.storeId", "==", Number(filter));
    } 
    let first, count;
    if (storeFilterWhere) {
        count = query(
            collection(db, "orders"),
            where("orderStatus", "==", "awaiting_shipment"),
            where("assignedToBatch", "==", false),
            storeFilterWhere,
        );
        first = query(
            collection(db, "orders"),
            where("orderStatus", "==", "awaiting_shipment"),
            where("assignedToBatch", "==", false),
            storeFilterWhere,
            orderBy("orderTimestamp", "desc"),
            limit(limitAmt)
        );
    } else {
        count = query(
            collection(db, "orders"),
            where("orderStatus", "==", "awaiting_shipment"),
            where("assignedToBatch", "==", false),
        );
        first = query(
            collection(db, "orders"),
            where("orderStatus", "==", "awaiting_shipment"),
            where("assignedToBatch", "==", false),
            orderBy("orderTimestamp", "desc"),
            limit(limitAmt)
        );
    }
    // get count
    const countSnapshot = await getCountFromServer(count);
    const totalOrders = countSnapshot.data().count;

    const documentSnapshots = await getDocs(first);
    const orders = documentSnapshots.docs.map(doc => {
        let temp = doc.data();
        temp.id = doc.id;
        temp.ref = doc.ref;
        return temp;
    });
    // sort orders by modifyTimestamp
    orders.sort((a, b) => b.modifyTimestamp - a.modifyTimestamp);
    const firstVisible = documentSnapshots.docs[0];
    const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
    return { orders, firstVisible, lastVisible, totalOrders };
}

async function subsequentLoadCollectOrders(filter, cursor, limitAmt) {
    const { start, end }   = cursor;
    let storeFilterWhere = null;
    if (filter !== 'all') {
        storeFilterWhere = where("advancedOptions.storeId", "==", Number(filter));
    }

    console.log("subload", cursor)
    let next;
    if (storeFilterWhere) {
        next = query(
            collection(db, "orders"),
            where("orderStatus", "==", "awaiting_shipment"),
            where("assignedToBatch", "==", false),
            storeFilterWhere,
            orderBy("orderTimestamp", "desc"),
            startAt(start),
            endAt(end),
            limit(limitAmt)
        );
    } else {
        next = query(
            collection(db, "orders"),
            where("orderStatus", "==", "awaiting_shipment"),
            where("assignedToBatch", "==", false),
            orderBy("orderTimestamp", "desc"),
            startAt(start),
            endAt(end),
            limit(limitAmt)
        );
    }
    const documentSnapshots = await getDocs(next);
    const orders = documentSnapshots.docs.map(doc => {
        let temp = doc.data();
        temp.id = doc.id;
        temp.ref = doc.ref;
        return temp;
    });
    // sort orders by modifyTimestamp
    orders.sort((a, b) => b.orderTimestamp - a.orderTimestamp);
    const firstVisible = documentSnapshots.docs[0];
    const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
    return { orders, firstVisible, lastVisible };
}

const DisplayStoreFilter = ({ storeFilter, setStoreFilter, user }) => {

    const [availableStores, avaiableStoresLoading, availableStoresError] = useCollectionData(query(
        collection(db, "shipStationStores"),
        where("orderFilterable", "==", true)
    ));

    // save the current storeFilter to local storage
    useEffect(() => {
        localStorage.setItem('storeFilter', storeFilter);
    }, [storeFilter])

    //check if there is a local filter saved
    useEffect(() => {
        const localStoreFilter = localStorage.getItem('storeFilter');
        if (localStoreFilter) {
            setStoreFilter(localStoreFilter);
        }
    }, [])

    async function saveCurrentStoreFilter(storeFilter) {
        // save the current storeFilter to firebase users doc
        const userQ = query(
            collection(db, "users"),
            where("uid", "==", user.uid),
            limit(1)
        );
        getDocs(userQ).then((querySnapshot) => {
            if (querySnapshot.empty) return;
            const docRef = querySnapshot.docs[0].ref;
            updateDoc(docRef, {
                currentStoreFilter: storeFilter
            });
            console.log('saved store filter to user profile');
        }).catch((error) => {
            console.log("Error getting documents: ", error);
        }).finally(() => {
            // setLoadingState(false);
        });
    }

    function handleStoreFilter(e) {
        e.preventDefault();
        setStoreFilter(e.target.value);
        saveCurrentStoreFilter(e.target.value);
    }

    // find the store name from the storeFilter (storeId)
    const store = availableStores?.find(store => store.storeId === Number(storeFilter));

    return (
        !avaiableStoresLoading ?
        <Form.Select aria-label="Default select example" onChange={(e) => handleStoreFilter(e)}>
            <option value="">{store ? store.storeName : 'Select a store'}</option>
            {
                availableStores?.length > 0 &&
                availableStores.map((store, index) => {
                    return (
                        <option key={index} value={store.storeId}>
                        {store.storeName}</option>
                    )
                })
            }
        </Form.Select>
        : null
    )
    
}

function NewBatchModal({ show, setShow }) {

    //const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);

    const [batchName, setBatchName] = useState('');
    const [batchMobile, setBatchMobile] = useState('');
    const [assignableUsers, setAssignableUsers] = useState([]);
    const [assignedUser, setAssignedUser] = useState('');
    const [autoAssignableBatch, setAutoAssignableBatch] = useState(true);
    const labelPreviewRaw = batchName ? batchName : 'Batch Name';

    const [testMode, setTestMode] = useState(true);

    useEffect(() => {
        let isSubscribed = true;
        gatherAssignableUsers().then((users) =>
            isSubscribed ? setAssignableUsers(users) : null
        ).catch((error) => {
            console.log(error);
        });
        return () => isSubscribed = false;
    }, []);

    // strip out any spaces and special characters
    let labelPreview = labelPreviewRaw.replace(/[^a-zA-Z0-9]/g, '');
    labelPreview = labelPreview.substring(0, 10);

    const previewStyle = {
        display: 'inline-block',
        border: '1px solid #ccc',
        padding: '5px',
        borderRadius: '5px',
        backgroundColor: '#fff',
        color: '#000',
        fontSize: '14px',
        fontWeight: 'bold',
        margin: '0 5px',
        fontFamily: 'monospace'
    }

    function handleAutoClick(e) {
        e.preventDefault();
        const randomWords = [];
        // select a random words.adjectives and a noun from words.nouns
        randomWords.push(words.adjectives[Math.floor(Math.random() * words.adjectives.length)]);
        randomWords.push(words.nouns[Math.floor(Math.random() * words.nouns.length)]);
        setBatchName(randomWords.join(''));
    }

    async function handleSubmit(e) {
        e.preventDefault();
        try {
            await addDoc(collection(db, "customBatches"), {
                status: 'OPEN',
                batchName: batchName,
                autoAssign: autoAssignableBatch,
                mobileNumber: batchMobile,
                assignedUser: assignedUser,
                timestamp: {
                    created: serverTimestamp(),
                    modified: serverTimestamp()
                },
                testMode: testMode
            })
            handleClose();
            toast.success('Batch Created Successfully', { theme: 'colored' });
        } catch (error) {
            console.log(error);
        }
    }

    async function gatherAssignableUsers() {
        const users = [];
        const q = query(
            collection(db, "users"),
            where("fulfillmentAssignable", "==", true)
        );
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
                users.push(doc.data());
            });
        } else {
            console.log('No users found');
        }
        return users;
    }

    return (
      <>
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
          <Modal.Header>
            <Modal.Title>Create New Batch</Modal.Title>
            <FalconCloseButton onClick={handleClose}/>
          </Modal.Header>
          <Modal.Body>
            <Form>
                <Form.Label>Batch Name</Form.Label>
                <InputGroup className="mb-3">
                    <Form.Control
                        placeholder="Enter Batch Name"
                        aria-label="Name For Batch"
                        aria-describedby="basic-addon2"
                        defaultValue={batchName}
                        onChange={(e) => setBatchName(e.target.value)}
                    />
                    <Button variant="outline-secondary" id="button-addon2" onClick={(e) => handleAutoClick(e)}>
                    Auto
                    </Button>
                </InputGroup>
                <Form.Label>Label Preview</Form.Label>
                <InputGroup className="mb-3">
                <span
                    style={previewStyle}
                    className="text-uppercase"
                >
                    {labelPreview}
                </span>

                </InputGroup>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Auto Assignment Eligible" defaultChecked onChange={(e) => setAutoAssignableBatch(e.target.checked)}/>
                </Form.Group>
                {/** Add input for mobile phone number with validation */}
                <Form.Group className="mb-3" controlId="formBasicPhoneNumberInput">
                    <Form.Label>Mobile Phone Number (Optional)</Form.Label>
                    <Form.Control type="text" placeholder="Enter mobile phone number" />
                    <Form.Text className="text-muted">This is to send a link to this batches Pick Wizard for Non-Users</Form.Text>
                </Form.Group>
                {/** Create a drop down selection of available users */}
                <Form.Group className="mb-3" controlId="formBasicUserSelect">
                    <Form.Label>Assign To User (Optional)</Form.Label>
                    <Form.Select aria-label="Select User" onChange={(e) => setAssignedUser(e.target.value)}>
                        <option>Select User</option>
                        {assignableUsers.map((user, index) => (
                            <option key={`${index}-${user.id}`} value={user.id}>{user.name}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
                {/** Create a switch for test mode */}
                <Form.Group className="mb-3" controlId="formBasicTestMode">
                    <Form.Check type="switch" label="Test Mode" checked={testMode} onChange={(e) => setTestMode(e.target.checked)}/>
                    <Form.Text className="text-muted">Test Mode will prevent the purchase of shippping labels</Form.Text>
                </Form.Group>

            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={(e) => handleSubmit(e)}
            >Create</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  
}

async function checkInventoryLevels(sku, locations) {
    
    let returnObject = {
        sku: sku,
        count: 0,
        message: '',
        location: ''
    }

    try {
        if (!sku || sku === undefined || sku === null) return;
        const q = query(
            collectionGroup(db, "variants"),
            where("sku", "==", sku),
            limit(1)
        );
        
        const querySnapshot = await getDocs(q);

       
        const variants = querySnapshot.docs.map(doc => doc.data());
        
        const variant = variants[0];

        if (variant) {
            const locationData = await getInventoryLocation(variant, locations);
            if(!variant.isBundle) {
                // need to assign preference to item's fulfillment location
                // then report availability based on that location

                returnObject.count = locationData.count;
                returnObject.message = locationData.msg;
                returnObject.location = locationData.slot;

            } else  {
                // product is a bundle
                // get the bundle's components
                const bundleParent = variant.parentRef;
                const bundleParentDoc = await getDoc(bundleParent);
                const bundleParentData = bundleParentDoc.data();
                const componentProducts = bundleParentData.includedVariants;

                // get the count of each component
                const componentCounts = [];
                for (let i = 0; i < componentProducts.length; i++) {
                    let componentCount;
                    const componentProduct = componentProducts[i];
                    const componentParent = await getDoc(componentProduct.parentRef)
                    const componentParentData = componentParent.data();
                    if (componentProduct.productFactor < 1) {
                        // if the variant is a break-box then we need to build up how many boxes are in stock
                        componentCount = componentParentData.ecomm_onhand * (1 + componentProduct.productFactor);
                    } else {
                        // if the variant is a multiple of the parent unit we divide the parent's onhand by the variant's productFactor
                        // to get the count in the variant's unit of measure
                        componentCount = componentParentData.ecomm_onhand / componentProduct.productFactor;
                    }
                    componentCounts.push({sku: componentProduct.sku, count: componentCount});
                }
                // find the lowest count
                const lowestCount = Math.min(...componentCounts.map(component => component.count));
                if (lowestCount < 1) {
                    returnObject.message = 'Bundle Component Out of Stock';
                    const missingBundleComponentsDetail = [];
                    componentCounts.forEach(component => {
                        if (component.count < 1) {
                            missingBundleComponentsDetail.push(`${component.sku} is Out of Stock`);
                        }
                    })
                    returnObject.missingBundleComponentsDetail = missingBundleComponentsDetail;
                } else {
                    returnObject.count = lowestCount;
                    returnObject.message = 'Bundle In Stock';
                }
            }
        } else {
            returnObject.message = 'Not found';
            //console.log("Not Found?", sku, variants)
        }
        return returnObject;
    } catch (error) {
    }
}

const lookupMainWarehouse = async (variant) => {
    const { parentRef } = variant;
    const parentDoc = await getDoc(parentRef);
    const parent = parentDoc.data();
    const slot = parent?.ibmi?.slot;
    let count, msg;
    if (variant.productFactor < 1) {
        // if the variant is a break-box then we need to build up how many boxes are in stock
        count = parent.ecomm_onhand * (1 + variant.productFactor);
        msg = "In Stock";
    } else {
        // if the variant is a multiple of the parent unit we divide the parent's onhand by the variant's productFactor
        // to get the count in the variant's unit of measure
        count = parent.ecomm_onhand / variant.productFactor;
        msg = "In Stock";
    }
    return { slot, count, locationId: '0', msg};
}

const lookupInventoryLocation = async (variant) => {
    const q = query(
        collectionGroup(db, "locationCountData"),
        where("locationId", "==", variant.preferredFullfillmentLocation),
        where("sku", "==", variant.sku),
    );
    const querySnapshot = await getDocs(q);
    const locationCountData = querySnapshot.docs.map(doc => doc.data());
    let msg = `Error ${variant.sku}`;
    if (locationCountData.length > 0) {
        const locationCount = locationCountData[0];
        const slot = locationCount.slot;
        let count = locationCount.count;
        msg = "In Stock";
        if (count < 1) {
            count = await lookupMainWarehouse(variant);
            msg = "Preferred Location Out of Stock, In-stock in Default Warehouse";
        }
        return { slot, count, locationId: variant.preferredFulfillmentLocation, msg};
    } else {
        return {slot: 'Unknown', count: "Unknown", locationId: variant.preferredFulfillmentLocation, msg};
    }
}   

const getInventoryLocation = async (variant, locations) => {
    const { preferredFullfillmentLocation } = variant;
    if (!preferredFullfillmentLocation) return {slot: 'Unknown', count: "Unknown", locationId: 'Unknown', msg: 'No Pref Inv Loc'}
    const selectedLocation = locations.find(location => location?.id === preferredFullfillmentLocation);
    let lookupFunction;
    let lookupLocation = selectedLocation?.name || "Name Unknown";
    switch (selectedLocation?.id) {
        case '0':
            lookupFunction = lookupMainWarehouse;
            break;
        default:
            lookupFunction = lookupInventoryLocation;
            break;
    }
    const { slot, count, msg } = await lookupFunction(variant);
    return {location: lookupLocation, slot, count, msg};
}

function AutoAssignmentModal({ show, setShow }) {

    const [orders, setOrders] = useState([]);
    const [locations, setLocations] = useState([]);
    const staffingLevel = useRef(null);
    const handleClose = () => setShow(false);
    useEffect(() => {
        const getActiveLocations = async () => {
            const q = query(
                collection(db, "locations"),
                where("active", "==", true)
            );
            const querySnapshot = await getDocs(q);
            const locations = [];
            querySnapshot.forEach((doc) => {
                let temp = doc.data();
                temp.id = doc.id;
                locations.push(temp);
            });
            return locations;
        }
        const getAllOrders = async () => {
            const q = query(
                collection(db, "orders"),
                where("orderStatus", "==", "awaiting_shipment"),
                where("assignedToBatch", "==", false),
                orderBy("orderTimestamp", "desc"),
            );
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                let temp = doc.data();
                temp.id = doc.id;
                temp.ref = doc.ref;
                orders.push(temp);
            });
            return orders;
        }
        let isSubbed = true;
        getActiveLocations().then((locations) => {
            setLocations(locations);
            getAllOrders().then((orders) => {
                const enhancedPromises = [];
                for (const order of orders) {
                    const items = order.items;
                    for (const item of items) {
                        const sku = item.sku;
                        if (!sku) {
                            continue;
                        } else {
                            enhancedPromises.push(checkInventoryLevels(sku, locations))
                        }
                    }
                }
                Promise.all(enhancedPromises).then((enhancedOrders) => {
                    if (isSubbed) {
                        const merged = orders.map((order, index) => {
                            const items = order.items;
                            const skus = items.map(item => item.sku);
                            const enhancedData = enhancedOrders.filter(enhancedOrder => skus.includes(enhancedOrder?.sku));
                            
                            return {...order, enhancedData: enhancedData}
                        })
                        setOrders(merged);
                    }
                })  
            })
        })
        return () => isSubbed = false;
    }, [])


    const getRandomBatchName = () => {
        const randomWords = [];
        // select a random words.adjectives and a noun from words.nouns
        randomWords.push(words.adjectives[Math.floor(Math.random() * words.adjectives.length)]);
        randomWords.push(words.nouns[Math.floor(Math.random() * words.nouns.length)]);
        return randomWords.join('');
    }
    
    const assignOrdersToBatches = async (staffingLevel) => {

        const batchMax = 25;
        const ordersPerStaffMember = Math.ceil(orders.length / staffingLevel);
        const batchesPerStaffMember = Math.ceil(ordersPerStaffMember / batchMax);
        const totalBatchesNeeded = batchesPerStaffMember * staffingLevel;
        const batches = [];
        for (let i = 0; i < totalBatchesNeeded; i++) {
            const batchName = getRandomBatchName();
            batches.push(batchName);
        }
        // sort orders by enhancedData.location 
        const sortedOrders = orders.sort((a, b) => {
            const aLocation = a.enhancedData[0]?.location;
            const bLocation = b.enhancedData[0]?.location;
            if (aLocation < bLocation) {
                return -1;
            }
            if (aLocation > bLocation) {
                return 1;
            }
            return 0;
        })

        const batchesWithOrders = [];
        for (let i = 0; i < batches.length; i++) {
            const batch = batches[i];
            const batchOrders = [];
            for (let j = 0; j < batchMax; j++) {
                const order = sortedOrders.shift();
                if (order) {
                    batchOrders.push(order);
                }
            }
            batchesWithOrders.push({batchName: batch, orders: batchOrders});
        }
        // remove any batches that have no orders
        const batchesWithOrdersFiltered = batchesWithOrders.filter(batch => batch.orders.length > 0);
        // create the batches
        for (let i = 0; i < batchesWithOrdersFiltered.length; i++) {
            const batch = batchesWithOrdersFiltered[i];
            try {
                // get date in MM/DD format
                const date = new Date();
                const month = date.getMonth() + 1;
                const day = date.getDate();
                const formattedDate = `${month}/${day}`;
                const docRef = await addDoc(collection(db, "customBatches"), {
                    status: 'OPEN',
                    batchName: `🤖-${batch.batchName}_${formattedDate}`,
                    autoAssign: false,
                    timestamp: {
                        created: serverTimestamp(),
                        modified: serverTimestamp()
                    }
                })
                const orderIds = batch.orders.map(order => order.orderId);
                await updateDoc(docRef, {orders: orderIds});
                for (let j = 0; j < batch.orders.length; j++) {
                    const order = batch.orders[j];
                    await updateDoc(order.ref, {
                        assignedToBatch: true,
                        batchId: docRef.id,
                        batchName: batch.batchName
                    })
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handleStaffingLevelAssignment = async (e) => {
        e.preventDefault();
        const staffingLevelValue = staffingLevel.current.value;
        if (staffingLevelValue <= 0) {
            toast.error('Staffing Level must be greater than 0', { theme: 'colored' });
            return;
        }
        try {
            const result = await assignOrdersToBatches(staffingLevelValue);
        } catch (error) {
            console.log(error);
        }

    }

    return (
      <>
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
          <Modal.Header>
            <Modal.Title>Quick Assign Orders to Batches</Modal.Title>
            <FalconCloseButton onClick={handleClose}/>
          </Modal.Header>
          <Modal.Body>
            <Form>
                <Form.Label>Staffing Level</Form.Label>
                <InputGroup className="mb-3">
                    <Form.Control
                        placeholder="Enter Staffing Level"
                        aria-label="Staffing Level"
                        aria-describedby="basic-addon2"
                        ref={staffingLevel}
                    />
                </InputGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleStaffingLevelAssignment}>Assign</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  
}

const OpenOrders = ({ children }) => {

    const { user, authLoading }= useContext(FirebaseAuthContext)
    const navigate = useNavigate();
    const [loadingState, setLoadingState] = useState(false);
    const [orders, setOrders] = useState([]);
    // const cursor = useRef(null);
    const [newOrderDisplay, setNewOrderDisplay] = useState();
    const [perPage, setPerPage] = useState(25);
    const [showNewBatchModal, setShowNewBatchModal] = useState(false);
    const [showAutoAssignmentModal, setShowAutoAssignmentModal] = useState(false);

    const [prevButtonDisabled, setPrevButtonDisabled] = useState(true);
    const [nextButtonDisabled, setNextButtonDisabled] = useState(false);

    const [currentPage, setCurrentPage] = useState({start: null, end: null});
    const [pageHistory, setPageHistory] = useState([]);
    const [activeCursor, setActiveCursor] = useState(null);
    const [totalOrders, setTotalOrders] = useState(0);
    const [storeFilter, setStoreFilter] = useState('all');
    const [orderRefresh, setOrderRefresh] = useState(false);

    // Redirect to home if not logged in
    useEffect(() => {   
        if (authLoading) return;
        if (!user) return navigate('/');
      }, [authLoading, user])

    // check if a storeFilter is saved to the user's profile
    useEffect(() => {
        if (!user) return;
        let isSubbed = true;
        const userQ = query(
            collection(db, "users"),
            where("uid", "==", user.uid),
            limit(1)
        );
        getDocs(userQ).then((querySnapshot) => {
            if (querySnapshot.empty) return;
            const doc = querySnapshot.docs[0].data();
            if (doc.currentStoreFilter) {
                if (isSubbed) setStoreFilter(doc.currentStoreFilter);
            }
        }
        ).catch((error) => {
            console.log("Error getting documents: ", error);
        }).finally(() => {
            // setLoadingState(false);
        });
        return () => isSubbed = false;
    }, [user])

    useEffect(() => {
        let isSubscribed = true;
        setLoadingState(true);
        if (!activeCursor) {
            initialLoadCollectOrders(/**"496330"*/storeFilter, perPage)
            .then(({ orders, firstVisible, lastVisible, totalOrders }) => {
                if (isSubscribed) {
                    // set the overall total count
                    setTotalOrders(totalOrders);
                    // set the first page's start and end cursors
                    if (orders.length < perPage) {
                        setNextButtonDisabled(() => true);
                    } else {
                        setNextButtonDisabled(() => false);
                    }
                    setCurrentPage(() => ({start: firstVisible, end: lastVisible}));
                    setPageHistory(() => [{start: firstVisible, end: lastVisible}]);
                    setOrders(orders);
                    setLoadingState(false);
                }
            })
        } else {
            subsequentLoadCollectOrders(/**"496330"*/storeFilter, activeCursor, perPage)
            .then(({ orders, firstVisible, lastVisible }) => {
                if (isSubscribed) {
                    // add this page to the list of pages
                    setCurrentPage(() => ({start: firstVisible, end: lastVisible}));
                    setOrders(orders);

                    if (orders.length < perPage) {
                        setNextButtonDisabled(() => true);
                    } else {
                        setNextButtonDisabled(() => false);
                    }

                    setLoadingState(false);
                }
            }).catch((error) => {
                console.log("SUBLOADERR", error);
            })
        }
        return () => isSubscribed = false;
    }, [storeFilter, activeCursor, orderRefresh])

    function OrdersTable({ 
        orders, newOrderDisplay, perPage, pages,
        setModalShow, setAutoShow, showAuto, storeFilter, 
        setStoreFilter, totalOrders, setOrderRefresh
    }) {
    
        const [enhancedOrders, setEnhancedOrders] = useState([]);
        const [tagsMeta, tagsMetaLoading, error] = useCollectionData(
            collection(db, "shipStationTags"),
        );
        const [showNewProductModal, setShowNewProductModal] = useState(false);
        const [selectedItem, setSelectedItem] = useState();
        const [selectedOrder, setSelectedOrder] = useState();
    
        useEffect(() => {
            let isSubscribed = true;
            const enhancePromises = [];
            const getActiveLocations = async () => {
                const q = query(
                    collection(db, "locations"),
                    where("active", "==", true)
                );
                const querySnapshot = await getDocs(q);
                const locations = [];
                querySnapshot.forEach((doc) => {
                    let temp = doc.data();
                    temp.id = doc.id;
                    locations.push(temp);
                });
                return locations;
            }
            getActiveLocations().then((locations) => {
                for (let i = 0; i < orders.length; i++) {
                    const order = orders[i];
                    const items = order.items;
                    for (let j = 0; j < items.length; j++) {
                        const item = items[j];
                        const sku = item.sku;
                        enhancePromises.push(checkInventoryLevels(sku, locations));
                    }
                }
                Promise.all(enhancePromises).then((enhancedItems) => {
                    
                    // remove duplicates from enhancedItems
                    const uniqueEnhancedItems = [];
                    enhancedItems.forEach(item => {
                        const index = uniqueEnhancedItems.findIndex(uniqueItem => uniqueItem?.sku === item?.sku);
                        if (index === -1) {
                            uniqueEnhancedItems.push(item);
                        }
                    })
                    // remove any undefined items
                    const filteredUniqueEnhancedItems = uniqueEnhancedItems.filter(item => item !== undefined);
                    
                    // iterate over orders looking for the sku in filteredUniqueEnhancedItems
                    // if found add the inventory status to the order
                    const enhancedOrders = [];
                    orders.forEach(order => {
                        const items = order.items;
                        let primaryLocation = 'Unknown';
                        const enhancedItems = [];
                        items.forEach(item => {
                            const index = filteredUniqueEnhancedItems.findIndex(filteredItem => filteredItem?.sku === item?.sku);
                            if (index !== -1) {
                                enhancedItems.push(filteredUniqueEnhancedItems[index]);
                                if (filteredUniqueEnhancedItems[index].location) {
                                    primaryLocation = filteredUniqueEnhancedItems[index].location;
                                }
                            }
                        })
                        order.enhancedItems = enhancedItems;
                        order.primaryLocation = primaryLocation;
                        enhancedOrders.push(order);
                    })
                    if (isSubscribed) setEnhancedOrders(enhancedItems);
    
    
                });
            })
            return () => isSubscribed = false;
        }, [orders]);

        const colDef = [
            {
              accessor: 'orderNumber',
              Header: 'Order Number',
              Cell: rowData => {
                const { orderId, orderNumber } = rowData.row.original
                return(
                    <span>
                        <a href={`/orders/${orderId}`}>{orderNumber}</a>
                    </span>   
                )
              }
            },
            {
              accessor: 'orderTotal',
              Header: 'Total',
              cellProps:{
                className:'fw-medium'
              }
            },
            {
                Header: 'Items',
                Cell: rowData => {
                    const { items } = rowData.row.original
                    // iterate through items and consolidate any items that are repeats
                    // and add a count to the item
                    const consolidatedItems = [];
                    items.forEach(item => {
                        const index = consolidatedItems.findIndex(consolidatedItem => consolidatedItem.name === item.name);
                        if (index === -1) {
                            consolidatedItems.push({...item, count: 1});
                        } else {
                            consolidatedItems[index].count++;
                        }
                    })
                    return(
                        consolidatedItems.length > 0 &&
                        consolidatedItems.map((item, index) => {
                            // create a random key using the index + a random word from words.json
                            const key = words.adjectives[Math.floor(Math.random() * words.adjectives.length)];
                            return (
                                <div key={`cnt-${key}-${index}`} className="d-flex align-items-center">
                                    <Image key={`image-${key}-${index}-${item.sku}`} src={item.imageUrl} className="rounded-2 me-2" width="30" height="30" />
                                    <span 
                                        style={{display: 'inline-block', maxWidth: '350px'}}
                                        className="text-truncate"
                                        key={`span-${key}-${index}`}
                                    >{item.count} x {item.name}</span>
                                </div>
                            )
                        })
                    )
                }
            },
            {
                Header: 'Inventory',
                Cell: rowData => {
                    const { items } = rowData.row.original
                    const consolidatedItems = [];
                    items.forEach(item => {
                        const index = consolidatedItems.findIndex(consolidatedItem => consolidatedItem.name === item.name);
                        if (index === -1) {
                            consolidatedItems.push({...item, count: 1});
                        } else {
                            consolidatedItems[index].count++;
                        }
                    })               
                    // find each item in the enhanced orders array
                    // and display the inventory status
                    const inventoryStatus = [];
    
                    consolidatedItems.forEach(item => {
                        if (item.sku) {
                            const index = enhancedOrders.findIndex(enhancedItem => enhancedItem?.sku === item?.sku);
                            if (index !== -1) {
                                inventoryStatus.push(enhancedOrders[index]);
                            } else {
                                inventoryStatus.push({message: 'Not found', data: item});
                            }
                        } else {
                            inventoryStatus.push({message: 'No SKU'});
                        }
                        
                    })
    
                    const handleNotFoundClick = (item) => {
    
                        //let thisItem = item;
                        
                        if (item.message === 'Not found') item = item.data
                        //console.log(thisItem)
    
                        // find the item by sku in items
                        const index = items.findIndex(i => i?.sku === item?.sku);
                        const itemData = items[index];
                        setSelectedItem(itemData);
                        setShowNewProductModal(true);
                        setSelectedOrder(rowData.row.original);
                    }
    
                    const invDisplay = inventoryStatus.map((item, index) => {
                        let display;
                        let random = uuidv4();
                        switch (item.message) {
                            case 'In Stock':
                                // display an In Stock pill badge
                                display = <Badge bg="success" key={`${random}-${index}`} className='mb-1'>In Stock</Badge>
                                break;
                            case 'Out of stock':
                                // display an Out of Stock pill badge
                                display = <Badge bg="danger" key={`${random}-${index}`} className='mb-1'>Out of Stock</Badge>
                                break;
                            case 'Preferred Location Out of Stock, In-stock in Default Warehouse':
                                // display an Out of Stock pill badge
                                display = <Badge bg="warning" key={`${random}-${index}`} className='mb-1'>Inventory Room Only</Badge>
                                break;
                            case 'Bundle Component Out of Stock':
                                // display an Out of Stock pill badge
                                display = <Badge bg="warning" key={`${random}-${index}`} className='mb-1'>Bundle Component Out of Stock</Badge>
                                break;
                            case 'Bundle In Stock':
                                // display an In Stock pill badge
                                display = <Badge bg="success" key={`${random}-${index}`} className='mb-1'>Bundle In Stock</Badge>
                                break;
                            case 'Not found':
                                // display an Out of Stock pill badge
                                display = 
                                        <Badge 
                                            bg="danger" 
                                            key={`${random}-${index}`} 
                                            className='mb-1' 
                                            onClick={() => {
    
                                                let lookupSku = item.sku;
                                                // find in consolidatedItems the item by lookupSku
                                                const index = consolidatedItems.findIndex(consolidatedItem => consolidatedItem?.sku === lookupSku);
                                                const itemData = consolidatedItems[index];
    
                                                handleNotFoundClick(itemData), 
                                                console.log("click", itemData)
                                            }}
                                        >Not found</Badge>
                                break;
                            default:
                                // display an Out of Stock pill badge
                                display = <Badge bg="danger" key={`${random}-${index}`} className='mb-1'>{item.message}</Badge>
                                break;
                        }
                        return (
                            <div key={`container-${random}-${index}`}>
                                <div key={`${uuidv4()}-${index}`} className="d-flex align-items-center">
                                    {display} <Badge bg="secondary" key={`${uuidv4()}-${index}-slot`} className='mb-1 ms-2'>{item.location}</Badge> 
                                </div>
                                {item.message === 'Not found' && <div key={`${uuidv4()}-${index}`} className="text-muted mt-1">Missing {item.sku}</div>}
                                {item.message === 'Bundle Component Out of Stock' &&
                                    item.missingBundleComponentsDetail.map((detail, index) => {
                                        return (
                                            <div key={`${uuidv4()}-${index}`} className="text-muted mt-2">{detail}</div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
    
                    return(
                        invDisplay.length > 0 &&
                        invDisplay.map((item, index) => {
                            return (
                                <div key={`cnt-${index}`} className="d-flex align-items-center">
                                    {item}
                                </div>
                            )
                        })
                    )
                }
            },
            {
                accessor: 'primaryLocation',
                Header: 'Primary Location',
                cellProps:{
                    className:'fw-medium'
                },
                Cell: rowData => {
                    const { primaryLocation } = rowData.row.original
                    return(
                        <span><Badge bg="secondary" key={`${uuidv4()}-slot`} className='mb-1 ms-2'>{primaryLocation}</Badge></span>
                    )
                }
            },
            {
                accessor: 'tagIds',
                Header: 'Tags',
                cellProps:{
                  className:'fw-medium'
                },
                Cell: rowData => {
                    const { tagIds } = rowData.row.original
    
                    const tags = [];
                    if (tagIds?.length) {
                        tagIds.forEach(tagId => {
                           if (tagsMeta?.length) {
                                const tagData = tagsMeta.find(tag => tag.tagId === tagId);
                                if (tagData) {
                                    tags.push(tagData);
                                }
                           }
                        })
                    }
    
                    const TagDisplay = ({ tag }) => {
                        const { name, color, text } = tag;
                        // create a rounded pill with the color of the tag
                        const key = words.adjectives[Math.floor(Math.random() * words.adjectives.length)];
                        return (
                            <span key={`tag-span${key}`} className="badge rounded-pill me-1" style={{backgroundColor: color, color: text}}>{name}</span>
                        )
                    }
    
                    return(
                        tags?.length > 0 &&
                        tags.map((tag, index) => {
                            return (
                                <TagDisplay key={index} tag={tag} />
                            )
                        })
                    )
                }
              },
        ];
    
        function BulAction(table){
            
            const { selectedRowIds, page } = table;
            const [showBulkModal, setShowBulkModal] = useState(false);
            const [bulkActionOption, setBulkActionOption] = useState('');
            const handleClose = () => setShowBulkModal(false);
            // get the data for the selected rows by id from page and return only the 'original' field in an array of objects
            const selectedRows = Object.keys(selectedRowIds).map(id => page.find(row => row.id === id).original);
            
            const AssignToBatch = ({ orders }) => {
                const [batches, setBatches] = useState([]);
                const [selectedBatch, setSelectedBatch] = useState('');
    
                useEffect(() => {
                    async function gatherOpenBatches() {
                        const q = query(
                            collection(db, 'customBatches'),
                            where("status", "==", "OPEN")
                        )
                        const querySnapshot = await getDocs(q);
                        const batches = [];
                        querySnapshot.forEach((doc) => {
                            let temp = doc.data();
                            temp.id = doc.id;
                            batches.push(temp);
                        });
                        return batches;
                    }
                    gatherOpenBatches().then((batches) => {
                        setBatches(batches);
                    })
                }, [])
    
                async function assignOrdersToBatch(batchName) {
                    const batch = batches.find(batch => batch.batchName === batchName);
                    const batchId = batch.id;
                    const batchRef = doc(db, 'customBatches', batchId);
                    const batchDoc = await getDoc(batchRef);
                    const batchData = batchDoc.data();
                    let orders = [];
                    if (batchData.orders) {
                        orders = batchData.orders;
                    }
                    let warning = false;
                    // go through selectedRows and only pull out orderIds
                    const orderIds = selectedRows.map(order => order.orderId);
                    // add the orderIds to the batch
                    orderIds.forEach(orderId => {
                        if (!orders.includes(orderId)) {
                            orders.push(orderId);
                        } else {
                            warning = true;
                        }
                    })
                    // update the batch
                    try {
                        await updateDoc(batchRef, {orders: orders});
                        for (let i = 0; i < selectedRows.length; i++) {
                            const order = selectedRows[i];
                            await updateDoc(order.ref, {
                                assignedToBatch: true,
                                batchId: batchId,
                                batchName: batchName
                            })
                        }
                        toast.success('Orders assigned to batch', { theme: 'colored'});
                        // force the useEffect to run again
                        setOrderRefresh(prev => !prev);
                        if (warning) {
                            toast.warning('Some orders were already assigned to this batch and were safely ignored', { theme: 'colored'});
                        }
                        handleClose();
                    } catch (error) {
                        console.log(error)
                        toast.error('Error assigning orders to batch', { theme: 'colored'});
                    }
    
                }
    
                function handleAssign(e) {
                    e.preventDefault();
                    try {
                        assignOrdersToBatch(selectedBatch);
                    } catch (error) {
                        console.log(error);
                    }
                }
    
                return (
                    <>
                        <span>You are assigning {selectedRows.length} orders to a batch</span>
                        <div className='border-bottom w100'></div>
                        <p> </p>
                        {selectedBatch.length <= 0 ?
                            <Form.Select aria-label="Default select example" onChange={(e) => setSelectedBatch(e.target.value)}>
                            <option value="">Select a batch</option>
                            {
                                batches.map((batch, index) => {
                                    return (
                                        <option key={index} value={batch.batchName}>
                                        {batch.batchName} {batch.assignedUser ? `(${batch.assignedUser})` : null }</option>
                                    )
                                })
                            }
                            </Form.Select>
                        :
                        <>
                            <span>You've selected <div className="inline-block text-800 mt-3 mb-3"><strong>{selectedBatch}</strong></div></span>
                            <a onClick={() => setSelectedBatch('')}><span className='link-primary'>Change</span></a> or 
                            <Button className='ms-2' size='sm' variant='primary' onClick={(e) => handleAssign(e)}>Assign</Button>
                        </>
                        }   
                    </>
    
                )
            }
    
            const HoldOrders = ({ orders }) => {
    
                const [date, setDate] = useState(null);
                const [shipStationFormat, setShipStationFormat] = useState(null);
                const orderIds = orders.map(order => order.orderId);
                const [additionalHoldDates, setAdditionalHoldDates] = useState([]);
                const [hold, setHold] = useState(false);
    
                async function getExpectedDate(sku) {
                    const q = query(
                        collectionGroup(db, 'variants'),
                        where('sku', '==', sku)
                    );
                    const querySnapshot = await getDocs(q);
                    const variantDocs = [];
                    querySnapshot.forEach(doc => {
                        variantDocs.push(doc.data());
                    })
                    const expectedDates = [];
                    for (let i = 0; i < variantDocs.length; i++) {
                        const variantDoc = variantDocs[i];
                        if (variantDoc.parentRef) {
                            const parentRef = variantDoc.parentRef;
                            const parentDoc = await getDoc(parentRef);
                            const parentData = parentDoc.data();
                            if (parentData.expectedDate) {
                                expectedDates.push(parentData.expectedDate);
                            }
                        }
                    }
                    return expectedDates;
                }
    
                async function collectHoldDates(items) {
                    // this function looks up if there is an expected date for the items
                    // get list of skus
                    const skus = items.map(item => item.sku);
                    const uniqueSkus = [...new Set(skus)];
                    // get the expected dates for the skus
                    const expectedDates = [];
                    for (let i = 0; i < uniqueSkus.length; i++) {
                        const sku = uniqueSkus[i];
                        const expectedDate = await getExpectedDate(sku);
                        expectedDates.push(expectedDate);
                    }
                    return expectedDates;
                }
    
                async function holdOrders(holdTillDate, orderId) {
                    const data = JSON.stringify({
                        orderId: orderId,
                        holdTillDate: String(holdTillDate)
                    })
                    let config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: 'https://ssapi.shipstation.com/orders/holduntil',
                        headers: { 
                          'Content-Type': 'application/json', 
                          'Authorization': 'Basic NjU5NTAyYjk1NmIzNDJiMjk1Njg1NzRiOGViZmM3NmY6ZDdiODY2MmUwMmMxNDBkN2JmNTA2OGE4ZjEwNmFiMzI='
                        },
                        data : data
                    };
                    const response = await axios.request(config);
                    const dataResponse = response.data;
                    if (dataResponse.success) {
                        return { orderId: orderId, success: true }
                    } else {
                        return { orderId: orderId, success: false }
                    }
                }
    
                useEffect(() => {
    
                    // when the date is selected format the date into shipstation format YYYY-MM-DD
                    if (date) {
                        const year = date.getFullYear();
                        const month = date.getMonth() + 1;
                        const day = date.getDate();
                        const formattedDate = `${year}-${month}-${day}`;
                        setShipStationFormat(formattedDate);
                    }
    
                }, [date])
    
                useEffect(() => {
                    if (!orders) return;
                    const orderItems = [];
                    orders.forEach(order => {
                        order.items.forEach(item => {
                            orderItems.push(item);
                        })
                    })
                    collectHoldDates(orderItems).then((expectedDates) => {
                        const exDates = [];
                        for (let i = 0; i < expectedDates.length; i++) {
                            const expectedDate = expectedDates[i];
                            if (expectedDate.length > 0) {
                                let temp = expectedDate[0].toDate();
                                // formate temp to YYYY-MM-DD
                                const year = temp.getFullYear();
                                const month = temp.getMonth() + 1;
                                const day = temp.getDate();
                                const formattedDate = `${year}-${month}-${day}`;
                                exDates.push(formattedDate) 
                            }
                        }
                        setAdditionalHoldDates(exDates);
                    })
    
                }, [orders])
    
                async function handleHold(date) {
                    // wonky format fix
                    const splitDate = date.split('-');
                    const year = splitDate[0];
                    let month = splitDate[1];
                    if (month < 10) {
                        month = `0${month}`;
                    }
                    const day = splitDate[2];
                    const formattedDate = `${year}-${month}-${day}`;
                    setHold(true);
                    for (let i = 0; i < orderIds.length; i++) {
                        const orderId = orderIds[i];
                        try {
                            const holdResponse = await holdOrders(formattedDate, orderId);
                            if (holdResponse.success) {
                                console.log(`Order ${orderId} was successfully held until ${date}`);
                            } else {
                                console.log(`Order ${orderId} was NOT successfully held until ${date}`);
                            }
                        } catch (error) {
                            console.log(formattedDate, error);
                        }
                    }
                    setHold(false);
                }   
    
                return (
                    <>
                        <span>You are holding {orderIds.length} orders</span>
                        <div className='border-bottom w100'></div>
                        <p> </p>
                        <span>Select a date to hold orders until or choose a date from the options below:</span>
                        <DatePicker
                        selected={date}
                        onChange={(date)=>setDate(date)}
                        formatWeekDay={day => day.slice(0, 3)}
                        className='form-control mt-3'
                        placeholderText="Select Date"
                        />
                        <p> </p>
                        {additionalHoldDates.length > 0 &&
                        <>
                            <span>Or select from the following dates of expected product arrival:</span>
                            <p> </p>
                            <div className='d-flex flex-wrap'>
                                {
                                    additionalHoldDates.map((date, index) => {
                                        return (
                                            <Button key={index} className='me-2 mb-2' size='sm' variant='outline-primary' onClick={() => setDate(date)}>{date}</Button>
                                        )
                                    })
                                }
                            </div>
                        </>
                        }
                        {date &&
                        <>
                            <p> </p>
                            <span className='text-primary'>Hold orders until <strong>{shipStationFormat}</strong>?</span>
                            <p> </p>
                            <Button className='me-2' size='sm' variant='primary' onClick={() => handleHold(shipStationFormat)}>Hold Order</Button>
                        </>
                        }
                    </>
                )
                
            }
    
            return (
                <>
                    <Row className="">
                    <Col xs={6} className="d-flex align-items-center pe-0">
    
                            {Object.keys(selectedRowIds).length > 0 
                            ? (
                                <div className="d-flex">
                                    <Form.Select size="sm" className="me-2" aria-label="Bulk actions" onChange={(e) => setBulkActionOption(e.target.value)}>
                                        <option>Bulk Actions</option>
                                        <option value="assign">Assign to Batch</option>
                                        <option value="onhold">Hold Order(s)</option>
                                    </Form.Select>
                                    <Button
                                    type="button"
                                    variant="falcon-default"
                                    size="sm"
                                    onClick={() => setShowBulkModal(true)}
                                    >
                                    Apply
                                    </Button>
                                </div>
                            ) : (
                                <div id="orders-actions" className="d-flex">
                                    <IconButton
                                        variant="falcon-default"
                                        size="sm"
                                        icon="plus"
                                        transform="shrink-3"
                                        className='me-2'
                                        onClick={() => setModalShow(true)}
                                    >
                                        <span className="d-none ms-1">New Batch</span>
                                    </IconButton>
                                    
                                    <IconButton
                                        variant="falcon-default"
                                        size="sm"
                                        icon={["fas", "bolt"]}
                                        transform="shrink-3"
                                        className=''
                                        onClick={() => setAutoShow(true)}
                                    >
                                        <span className="d-none ms-1">Quick Assignment</span>
                                    </IconButton>
                                </div>
                            )}
                        </Col>
                        <Col xs={6} sm="auto" className="ms-auto text-end d-flex justify-content-end align-middle">
                            <span className="fs-0 mb-0 text-nowrap py-1 py-xl-0">
                            {
                                Object.keys(selectedRowIds).length > 0 ?
                                    Object.keys(selectedRowIds).length == 1 ?
                                        'You have selected ' + Object.keys(selectedRowIds).length + ' order'
                                        : 'You have selected ' + Object.keys(selectedRowIds).length + ' orders'
                                    : `${totalOrders} orders`
                            }
                            </span>
                        </Col>
                    </Row>
                    <Modal show={showBulkModal} onHide={handleClose} backdrop="static" keyboard={false}>
                        <Modal.Header>
                            <Modal.Title>
                                {bulkActionOption === 'assign' && 'Assign to Batch'}
                                {bulkActionOption === 'onhold' && 'Hold Orders'}
                            </Modal.Title>
                            <FalconCloseButton onClick={handleClose}/>
                        </Modal.Header>
                        <Modal.Body>
                            {bulkActionOption === 'assign' && <AssignToBatch orders={selectedRows}/>}
                            {bulkActionOption === 'onhold' && <HoldOrders orders={selectedRows} />}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                            Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            );
        };
    
        const AddMissingProductModal = ({ show, setShow, item, order }) => {
    
            const items = order?.items;
    
            const storeId = order?.advancedOptions.storeId;
    
            const newProduct = {
                ecomm_onhand: 0,
                timestamp: {
                    created: serverTimestamp(),
                    modifed: serverTimestamp()
                },
                channelId: storeId,
                tradeHubId: storeId,
                nonTopicz: true,
            }
            if (item?.imageUrl) {
                newProduct.files = [
                    {
                        alt: `Image of ${item?.name}`,
                        id: uuidv4(),
                        src: item?.imageUrl,
                    }
                ];
                newProduct.primaryImage = item?.imageUrl;
            }
            if (item?.upc) {
                newProduct.barcodes = {
                    unit: item?.upc
                }
            }
            if (item?.sku) {
                newProduct.sku = item?.sku;
                newProduct.id = item?.sku;
            }
            if (item?.name) {
                newProduct.name = item?.name;
            }
    
            async function handleAddProduct() {
                try {
                    const ref = await addDoc(collection(db, "productCatalog/topicz/products"), newProduct);
                    const variant = {
                        createdTimestamp: serverTimestamp(),
                        parentRef: ref,
                        parentSku: item?.sku,
                        sku: item?.sku,
                        productFactor: 1,
                        channelId: storeId,
                        tradeHubId: storeId,
                        title: item?.name,
                    }
                    // add base variant
                    await addDoc(collection(db, `productCatalog/topicz/products/${ref.id}/variants`), variant);
                    toast.success('Product added successfully', { theme: 'colored' });
                    setSelectedItem();
                    setSelectedOrder();
                    setShow(false);
                } catch (error) {
                    console.log(error);
                    toast.error('Error adding product', { theme: 'colored' });
                }
            }
    
    
            return (
                <Modal show={show} onHide={() => setShow(false)} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <Modal.Title>Add Missing Product</Modal.Title>
                        <FalconCloseButton onClick={() => setShow(false)}/>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Would you like to add {item?.name} Sku: {item?.sku} to the database?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShow(false)}>
                        Close
                        </Button>
                        <Button variant="primary" onClick={() => handleAddProduct()}>
                        Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }
            
        return (
            orders.length > 0 
            ?
            <AdvanceTableWrapper
                columns={colDef}
                data={orders}
                sortable
                pagination
                perPage={perPage}
                selection
                selectionColumnWidth={30}
            >
                <div style={{ position: 'sticky', top: '0', zIndex: '100' }}>
  
                    <Row>
                        <Col>
                            <BulAction table/>  
                        </Col>
                        <Col>
                            <TableControls pages={pages} />
                        </Col>
                    </Row>
                    <Row className='mb-1'>
                        <Col>
                            <AdvanceTableSearchBox table/>    
                        </Col>
                    </Row>

                </div>
                <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                        striped: true,
                        className: 'fs--1 mb-0 overflow-hidden'
                    }}
                />
                <AutoAssignmentModal show={showAuto} setShow={setAutoShow} orders={enhancedOrders}/>
                <AddMissingProductModal show={showNewProductModal} setShow={setShowNewProductModal} item={selectedItem} order={selectedOrder}/>
            </AdvanceTableWrapper>
            :
            <div className="text-center">
                <span className='h2 mt-3'>No Orders</span>
            </div>
        )
    }

    function handlePrevOrNext(e, direction) {
        e.preventDefault();
        if (direction) {
            switch (direction) {
                case 'prev': 
                    
                    // remove the last element in the pagesHistory array
                    const temp = pageHistory.slice(0, pageHistory.length - 1);
                    setPageHistory(() => temp);

                    if (temp.length > 1) {
                        setPrevButtonDisabled(() => false);
                    } else {
                        setPrevButtonDisabled(() => true);
                    }

                    const prevStart = pageHistory[pageHistory.length - 1].start;
                    const prevEnd = pageHistory[pageHistory.length - 1].end;


                    if (prevStart && prevEnd) {
                        setActiveCursor(() => ({start: prevStart, end: prevEnd}));
                    }

                    break;
                case 'next':
                    // get the last element in the pages array
                    // const nextCursor = pages[pages.length - 1].end;
                    // setActiveCursor(() => nextCursor);
                    // get the next cursors to feed into the subsequentLoadCollectOrders function
                    
                    // add the current page to the pagesHistory array
                    const temp2 = pageHistory.slice();
                    temp2.push(currentPage);
                    setPageHistory(() => temp2);
                    
                    if (temp2.length > 1) {
                        setPrevButtonDisabled(() => false);
                    } else {
                        setPrevButtonDisabled(() => true);
                    }
                    
                    const nextQ = query (
                        collection(db, "orders"),
                        where("orderStatus", "==", "awaiting_shipment"),
                        where("assignedToBatch", "==", false),
                        orderBy("orderTimestamp", "desc"),
                        startAfter(currentPage.end),
                        limit(perPage)
                    );
                    const nextSnapshot = getDocs(nextQ);
                    nextSnapshot.then((snapshot) => {
                        const firstVisible = snapshot.docs[0];
                        const lastVisible = snapshot.docs[snapshot.docs.length - 1];
                        return { firstVisible, lastVisible };
                    }).then(({ firstVisible, lastVisible }) => {
                        let active = {
                            start: firstVisible,
                            end: lastVisible
                        }
                        setActiveCursor(() => active);
                    }).catch((error) => {
                        console.log(error);
                    })
                    break;
                default:
                    break;
            }
        }
    }

    function handlePerPageChange(e, storeFilter) {
        e.preventDefault();
        const newPerPage = parseInt(e.target.value);
        setPerPage(() => newPerPage);
        // setActiveCursor(() => pageHistory[0].start);
        const temp = pageHistory[0];
        let pageChangeQ;
        if (storeFilter === 'all') {
            pageChangeQ = query (
                collection(db, "orders"),
                where("orderStatus", "==", "awaiting_shipment"),
                where("assignedToBatch", "==", false),
                orderBy("orderTimestamp", "desc"),
                startAt(temp.start),
                limit(newPerPage)
            );
        } else {
            pageChangeQ = query (
                collection(db, "orders"),
                where("orderStatus", "==", "awaiting_shipment"),
                where("assignedToBatch", "==", false),
                // TODO - for some reason this is not working
                // when i include the storeFilter in the query it returns no results
                // which prevents subsequent data loads from working because there is no activeCursor
                //where("advancedOptions.storeId", "==", storeFilter),
                orderBy("orderTimestamp", "desc"),
                startAt(temp.start),
                limit(newPerPage)
            );
        }
        const nextSnapshot = getDocs(pageChangeQ);
        nextSnapshot.then((snapshot) => {
            const firstVisible = snapshot.docs[0];
            const lastVisible = snapshot.docs[snapshot.docs.length - 1];
            return { firstVisible, lastVisible };
        }).then(({ firstVisible, lastVisible }) => {

            let active = {
                start: firstVisible,
                end: lastVisible
            }
            
            setActiveCursor(() => active);
            setPageHistory(() => [active]);
        }).catch((error) => {
            console.log("nextSnapErr", error);
        })
    }

    const TableControls = ({pages}) => {
        return (
            <>
                <Row className='mb-2'>
                    <Col className='d-flex justify-content-start flex-grow-1'>
                        <Form.Select 
                        size="sm" 
                        aria-label="Results Per Page" 
                        onChange={(e) => handlePerPageChange(e, storeFilter)}
                        className='w-100'
                        >
                        <option>Viewing {perPage} orders</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                        </Form.Select>
                    </Col>
                    <Col xs='auto' className='d-flex justify-content-between flex-grow-1'>
                        <Button
                        variant="falcon-default"
                        size='sm'
                        onClick={(e) => handlePrevOrNext(e, 'prev', pages)}
                        disabled={prevButtonDisabled}
                        className='me-1 flex-grow-1'
                        >
                        Previous
                        </Button>
                        <Button 
                        variant="falcon-default" 
                        size='sm'
                        onClick={(e) => handlePrevOrNext(e, 'next', pages)}
                        disabled={nextButtonDisabled}
                        className='ms-1 flex-grow-1'
                        >
                        Next
                        </Button>
                    </Col>
                </Row>


            </>
            
        )
    }

    return (
        !loadingState ?
        <>
            <Card className='p-2 mb-2'>
                <DisplayStoreFilter storeFilter={storeFilter} setStoreFilter={setStoreFilter} user={user}/>
            </Card>
            <Card className='p-2 m-0'>
                    <OrdersTable 
                        orders={orders} 
                        newOrderDisplay={newOrderDisplay} 
                        perPage={perPage} 
                        pages={pageHistory} 
                        className="" 
                        setModalShow={setShowNewBatchModal}
                        setAutoShow={setShowAutoAssignmentModal}    
                        showAuto={showAutoAssignmentModal}
                        setStoreFilter={setStoreFilter}
                        storeFilter={storeFilter}
                        prevButtonDisabled={prevButtonDisabled}
                        setNextButtonDisabled={setNextButtonDisabled}
                        totalOrders={totalOrders}
                        orderRefresh={orderRefresh}
                        setOrderRefresh={setOrderRefresh}
                    />
                    <div className='mt-3'>
                        <TableControls pages={pageHistory} />
                    </div>
                
            </Card>
            <NewBatchModal show={showNewBatchModal} setShow={setShowNewBatchModal} />
        </>
        :
        <Lottie animationData={dotsAnimation} style={{width: 100, height: 100}} />
    );
}

export default OpenOrders;
