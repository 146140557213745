import { useState, useContext, useEffect, useRef } from 'react';
import { db } from "config/firebase";
import { useNavigate } from 'react-router-dom';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import PageHeader from 'components/common/PageHeader';
import { Card, Row, Col, Button, Badge } from 'react-bootstrap';
import Lottie from 'lottie-react';
import dotsAnimation from 'assets/lottie/dots.json';
import { useCollection } from 'react-firebase-hooks/firestore';
import { collection, query, where } from 'firebase/firestore';
import AdvanceTableFooter from 'components/merlin/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/merlin/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import { toast } from 'react-toastify';

const PackWizard = ({ children }) => {

    const queryBuilder = (filter) => {
        let whereClause = null;
        if (filter === 'last7') {
            whereClause = where("createdAt", ">=", new Date(new Date().setDate(new Date().getDate() - 7)));
        } else if (filter === 'last30') {
            whereClause = where("createdAt", ">=", new Date(new Date().setDate(new Date().getDate() - 30)));
        } else if (filter === 'last365') {
            whereClause = where("createdAt", ">=", new Date(new Date().setDate(new Date().getDate() - 365)));
        }
        const q = query(
            collection(db, "packJobs"),
            whereClause
        );
        return q;
    }

    const { user, authLoading }= useContext(FirebaseAuthContext)
    const navigate = useNavigate();
    const [activeQuery, setActiveQuery] = useState(collection(db, "packWizardJobs"));
    const [packJobsRaw, packJobsLoading, packJobsError] = useCollection(activeQuery);
    const [packJobs, setPackJobs] = useState(null)
    const filterRef = useRef(null);

    useEffect(() => {
        if (authLoading || packJobsLoading) return;
        if (packJobsError) toast.error(packJobsError.message, {theme: 'colored'});
        let isSubbed = true;
        const packJobData = packJobsRaw.docs.map(doc => {
            const data = doc.data();
            data.id = doc.id;
            return data;
        })
        if (isSubbed) setPackJobs(packJobData)
        return () => isSubbed = false;
    }, [authLoading, packJobsLoading, packJobsRaw, activeQuery])
    
    // Redirect to home if not logged in
    useEffect(() => {
        if (authLoading) return;
        if (!user) return navigate('/');
      }, [authLoading, user])

      
    const PackWizardTable = ({ packJobs }) => {

        const columns = [
            {
              accessor: 'batchName',
              Header: 'Batch Name'
            },
            {
                accessor: 'status',
                Header: 'Status',
                Cell: (rowData) => {
                    const { currentpackIndex, orders } = rowData.row.original;
                    const rationalizedCurrentPackIndex = currentpackIndex + 1;
                    const totalPacks = orders.length;
                    let percentComplete = Math.round((rationalizedCurrentPackIndex / totalPacks) * 100);
                    const status = rowData.cell.value;
                    if (status === 'COMPLETE') percentComplete = 100;
                    if (status === 'NOT STARTED') percentComplete = 0;
                    return (
                        <Badge bg={status === 'COMPLETE' ? 'success' : 'warning'}>{status} {percentComplete}%</Badge>
                    )
                }

              },
            {
              accessor: 'assignedUser',
              Header: 'Assigned To'
            },
            {
                accessor: 'id',
                Header: 'Actions',
                Cell: ({ cell }) => (
                    <Button variant="falcon-primary" size="sm" onClick={() => navigate(`/fulfillment/pack/${cell.value}`)}>View</Button>
                )
            }
        ];
          
        return (
          <AdvanceTableWrapper
            columns={columns}
            data={packJobs}
            sortable
            pagination
            perPage={5}
          >
            <Row className="flex-end-center mb-3">
              <Col lg={12}>
                <AdvanceTableSearchBox table/>
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
            <div className="mt-3">
              <AdvanceTableFooter
                rowCount={packJobs.length}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
        );
    }

    const handleFilterSelect = (e, filter) => {
        setActiveQuery(queryBuilder(filter));
    }

    

    const FilterSelection = () => {
        return (
            <Row className="mb-3">
                <Col lg={12}>
                    <div className="d-flex align-items-center">
                        <span className="fs-0">Viewing:</span>
                        <div className="ms-3">
                            <select className="form-select form-select-sm" ref={filterRef} onChange={(e) => handleFilterSelect(e, filterRef.current.value)}>
                                <option value="last7">Last 7 Days</option>
                                <option value="last30">Last 1 Month</option>
                                <option value="last365">Last Year</option>
                            </select>
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }

    return (
        !authLoading && !packJobsLoading ?
        <>
            <PageHeader title="Pack Wizard" className="mb-3">
                <FilterSelection />
            </PageHeader>
            <Card className="mb-3 p-3">
            {
                !packJobsLoading && packJobs && packJobs.length > 0 ?
                    <PackWizardTable packJobs={packJobs} />
                :   <Lottie animationData={dotsAnimation} style={{width: 100, height: 100}} />
            }
            </Card>
        </>
        :
        <Lottie animationData={dotsAnimation} style={{width: 100, height: 100}} />
    );
}

export default PackWizard;