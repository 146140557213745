import React from 'react';
import { Nav, Button } from 'react-bootstrap';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import NotificationDropdown from 'components/navbar/top/NotificationDropdown';
// import CartNotification from 'components/navbar/top/CartNotification';
import FavoritesNavLink from './FavoritesNavLink';
import OpenOrders from './OpenOrders';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from 'config/firebase';

// 
const TopNavRightSideNavItem = () => {
  
  const [user, loading] = useAuthState(auth);
  
  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
      key={"navBarkey"}
    >
      {user && !loading ?
        <>
            <NotificationDropdown />
            <OpenOrders user={user}/>
            <FavoritesNavLink user={user} />
            <ProfileDropdown user={user}/>
        </>
      :
        <Button>Sign In</Button>
     }
    </Nav>
  );
};

export default TopNavRightSideNavItem;
