import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Notification from './Notification';
import Background from 'components/common/Background';
import ecomBg from 'assets/img/illustrations/ecommerce-bg.png';
import { db } from "config/firebase";
import { collection, query, where, getDocs, limit, Timestamp } from 'firebase/firestore';

function chooseSalutation() {
  const date = new Date();
  const hour = date.getHours();
  if (hour < 12) {
    return 'Good Morning';
  } else if (hour < 18) {
    return 'Good Afternoon';
  } else {
    return 'Good Evening';
  }
}

async function gatherDailySalesData() {
  try {

    // create date object for 12:00am today
    const today = new Date();
    today.setHours(0,0,0,0);

    // create date object for 23:59 today
    const tomorrow = new Date();
    tomorrow.setHours(23,59,59,999);

    const fbToday = Timestamp.fromDate(today);
    const fbTomorrow = Timestamp.fromDate(tomorrow);

    const q = query(
      collection(db, "orders"),
      where("createTimestamp", ">=", fbToday),
    );
    const querySnapshot = await getDocs(q);
    const numOrders = querySnapshot.size;
    const amtPaid = [];
    querySnapshot.forEach((doc) => { 
      amtPaid.push(doc.data().amountPaid)
    });
    const totalSales = amtPaid.reduce((a, b) => a + b, 0);

    return { numOrders, totalSales }

  } catch (error) {
    console.log(error)
  }
}


const GreetingCard = ({ notifications, user }) => {

  const salutation = chooseSalutation();
  const [totalSales, setTotalSales] = useState(null)
  const [numOrders, setNumOrders] = useState(null)

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      gatherDailySalesData().then((data) => {
        const { numOrders, totalSales } = data;
        setTotalSales(totalSales)
        setNumOrders(numOrders)
      }).catch((error) => {
        console.log(error)
      })
    }
    return () => isSubscribed = false;
  }, [])

  return (
    <Card className="bg-transparent-50 overflow-hidden">
      <Card.Header className="position-relative">
        <Background
          image={ecomBg}
          className="d-none d-md-block bg-card z-index-1"
          style={{
            backgroundSize: '230px',
            backgroundPosition: 'right bottom',
            zIndex: '-1'
          }}
        />
        <div className="position-relative z-index-2">
          <div>
            <h3 className="text-primary mb-1">{salutation}, {user.name} </h3>
            <p>Here’s what happening with your operations today</p>
          </div>
          <Flex className="py-3">
            <div className="pe-3">
              <p className="text-600 fs--1 fw-medium">Number of Orders</p>
              <h4 className="text-800 mb-0">{ numOrders }</h4>
            </div>
            <div className="ps-3">
              <p className="text-600 fs--1">Today’s total sales</p>
              <h4 className="text-800 mb-0">${ Number(totalSales).toFixed(2)}</h4>
            </div>
          </Flex>
        </div>
      </Card.Header>
      <Card.Body className="p-0">
        <ul className="mb-0 list-unstyled">
          
        </ul>
      </Card.Body>
    </Card>
  );
};

GreetingCard.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object)
};

export default GreetingCard;
