import React, { useContext, useEffect, useState } from 'react';
import { db } from 'config/firebase';
import { query, collection, where, doc, getDocs, getDoc, deleteDoc, addDoc, updateDoc } from "firebase/firestore";
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import { Modal, Button, Table, Tabs, Tab, Row, Col } from "react-bootstrap";
import ActionButton from 'components/common/ActionButton';
import _ from 'lodash';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/merlin/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/merlin/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import { toast } from 'react-toastify';
import SoftBadge from 'components/common/SoftBadge';

export const OpenWorkspaceModal = ({ show, onClose, tabController }) => {

    const { user, authLoading }= useContext(FirebaseAuthContext);
    const [personalWorkspaces, setPersonalWorkspaces] = useState([]);
    const [systemWorkspaces, setSystemWorkspaces] = useState([]);
    const [showShareModal, setShowShareModal] = useState(false);
    const [tabToShare, setTabToShare] = useState(null);

    useEffect(() => {
        if (authLoading) return;
        if (!user) return;
        const workspacePromises = [];
        workspacePromises.push(getPrivateWorkspaces(user.uid));
        workspacePromises.push(getSystemWorkspaces(user.uid));
        Promise.all(workspacePromises).then((results) => {
            const privateWorkspacesRaw = results[0];
            // remove any workspace named "Search Results"
            const privateWorkspaces = privateWorkspacesRaw.filter((w) => w.name !== 'Search Results');
            const systemWorkspaces = results[1];
            setPersonalWorkspaces(privateWorkspaces);
            setSystemWorkspaces(systemWorkspaces);
        }).catch((error) => {
            console.log(error);
        })  
    }, [authLoading, user, deleteWorkspace, show]);

    const getPrivateWorkspaces = async (userId) => {
        const workspaces = [];
        const q = query(
            collection(db, "tabs"),
            where("users", "array-contains", userId)
        );
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
                let temp = doc.data();
                temp.id = doc.id;
                workspaces.push(temp);
            });    
        }
        return workspaces;
    }
    
    const getSystemWorkspaces = async (userId) => {
        const workspaces = [];
        const q = query(
            collection(db, "tabs"),
            where("access", "==", 'system')
        );
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
                let temp = doc.data();
                temp.id = doc.id;
                workspaces.push(temp);
            });    
        }
        return workspaces;
    }

    const WorkspaceItem = ({ workspace, actionType }) => {
        const workspaceData = {
            ...workspace,
            type: actionType
        }
        return (
            <tr>
                <td><div onClick={() => actionOnClick(workspaceData)}>{workspace.name}</div></td>
                <td className="text-end">
                    {actionType === 'private' ? 
                        <>
                            <ActionButton icon="share" title="Share" variant="action" className="p-0" onClick={() => console.log("share")}/>
                            <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-0" onClick={() => handleDelete(workspace)}/>
                        </>
                    :   <>
                            <ActionButton icon="share" title="Share" variant="action" className="p-0" onClick={() => console.log("share")}/>
                        </>
                    }
                    
                </td>
            </tr>
        )
    }

    const deleteWorkspace = async (workspace) => {
        const workspaceDoc = doc(db, 'tabs', workspace.id);
        await deleteDoc(workspaceDoc);
        // remove from personalWorkspaces
        const newPersonalWorkspaces = personalWorkspaces.filter((w) => w.id !== workspace.id);
        setPersonalWorkspaces(newPersonalWorkspaces);
    }
    const handleDelete = (workspace) => {
        deleteWorkspace(workspace);
        toast.success(`Workspace deleted successfully`, { theme: 'colored', autoClose: 700 });
    }

    async function actionOnClick(item) {
        if (!item) return;
        switch (item.type) {
            case 'system':
                const tab = await getTabData(item.id);
                tabController.createTab(tab);
                break;
            case 'private':
                tabController.openTab(item.id);
                break;
            default:
                break;
        }
    }

    async function getTabData(tabId) {
        const tabDoc = doc(db, 'tabs', tabId);
        const tabData = await getDoc(tabDoc);
        return tabData.data();
    }

    const WorkspaceTable = ({openShareModal, closeShareModal, setTabToShare, tabToShare}) => {
        return (
            <>
                <Tabs defaultActiveKey="home">
                    <Tab

                        eventKey="home" title="Personal" className='border-bottom border-x p-3'
                    >
                        <OpenWorkspaceAdvanceTable data={personalWorkspaces} closeShareModal={closeShareModal} openShareModal={openShareModal} setTabToShare={setTabToShare} tabToShare={tabToShare} />

                    </Tab>
                    <Tab
                        eventKey="system" title="System" className='border-bottom border-x p-3'
                    >
                        <OpenWorkspaceAdvanceTable data={systemWorkspaces} openShareModal={openShareModal} closeShareModal={closeShareModal} setTabToShare={setTabToShare} tabToShare={tabToShare} />
                    </Tab>
                </Tabs>
            </>
        )
    }

    const OpenWorkspaceAdvanceTable = ({ data, openShareModal, closeShareModal, setTabToShare, tabToShare }) => {
  
        const columns = [
            {
              accessor: 'name',
              Header: 'Name',
              headerProps: { className: 'text-900' },
              Cell: ({ row }) => {
                const { original } = row;
                const { name, id, open } = original;

                const handleClick  = async () => {
                    try {
                        await updateDoc(doc(db, "tabs", id), {
                            open: true
                        });
                        closeShareModal();
                    } catch (error) {
                        console.log(error);
                    }   
                }

                return (
                  <div className="d-flex justify-content-start">
                    <div onClick={handleClick}>
                        {open && <SoftBadge bg='success' className='me-2'>Open</SoftBadge>}
                        {name}
                    </div>
                  </div>
                );
              }
            },
            {
              accessor: 'timestamp.updated.seconds',
              Header: 'Date Modified',
              headerProps: { className: 'text-900' },
              Cell: ({ row }) => {
                const { original } = row;
                const { timestamp } = original;
                const dateString = timestamp?.updated.toDate().toLocaleDateString();
                return (
                  <div className="d-flex justify-content-end">
                    {dateString}
                  </div>
                );
              }
            },
            {
                Header: 'Actions',
                headerProps: { className: 'text-900' },
                Cell: ({ row }) => {
                  const { original } = row;
                  const { access } = original;
                
                  const handleShareClick = () => {
                    setTabToShare(original);
                    openShareModal();
                  }
    
                  return (
                    <div className="d-flex justify-content-end">
                        {access !== 'system' ? 
                            <>
                                <ActionButton icon="share" title="Share" variant="action" className="p-0" onClick={() => handleShareClick()}/>
                                <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-0" onClick={() => handleDelete(original)}/>
                            </>
                        :   <>
                                <ActionButton icon="share" title="Share" variant="action" className="p-0" onClick={() => handleShareClick()}/>
                            </>
                        }
                    </div>
                  );
                }
              },
          ];
          
        return (
          <AdvanceTableWrapper
            columns={columns}
            data={data}
            sortable
            pagination
            perPage={10}
            style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          >
            <Row className="flex-end-center mb-3">
              <Col lg={12}>
                <AdvanceTableSearchBox table/>
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-200 text-nowrap align-middle fs--1 font-weight-semi-bold"
              rowClassName="align-middle white-space-nowrap fs--2 font-weight-normal"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs-10 mb-0',
                style: { flexGrow: 1, overflow: 'true' } // Flex grow for the table
              }}
            />
            <div className="mt-3">
              <AdvanceTableFooter
                rowCount={data.length}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
        );
      }

    return (
        <>
            <Modal
                show={show}
                onHide={onClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ minHeight: '95vh' }}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Open Workspace
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body  style={{ display: 'flex', flexDirection: 'column', height: 'calc(95vh - 100px)' }}>
                    <WorkspaceTable
                        openShareModal={ () => setShowShareModal(true)}
                        closeShareModal={() => setShowShareModal(false)}
                        setTabToShare={setTabToShare}
                        tabToShare={tabToShare}/>
                </Modal.Body>
            </Modal>
            <ShareModal show={showShareModal} onClose={() => setShowShareModal(false)} tabToShare={tabToShare}/>
        </>
        
    )
}


const ShareModal = ({ show, onClose, tabToShare }) => {

    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [sharingInProgress, setSharingInProgress] = useState(false);

    useEffect(() => {
        const getUsers = async () => {
            const users = [];
            const q = query(
                collection(db, "users"),
                where("Organization", "==", 'topicz')
            );
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                    let temp = doc.data();
                    temp.id = doc.id;
                    users.push(temp);
                });    
            }
            return users;
        }
        getUsers().then((users) => {
            const u = users.map((user) => { 
                return {
                    name: user.name,
                    uid: user.uid
                }
            });
            setUsers(u);
        }).catch((error) => {
            console.log(error);
        })
    }, []);

    const handleSelectUser = (userId) => {
        if (selectedUsers.includes(userId)) {
            const newSelectedUsers = selectedUsers.filter((id) => id !== userId);
            setSelectedUsers(newSelectedUsers);
        } else {
            setSelectedUsers([...selectedUsers, userId]);
        }
    }

    const handleClose = () => {
        setSelectedUsers([]);
        onClose();
    }

    const handleShare = async () => {
        if (!tabToShare) return;
        try {
            setSharingInProgress(true);
            for (const userId of selectedUsers) {
                await createTab(tabToShare, userId);
            }
            setSharingInProgress(false);
            toast.success(`Workspace shared successfully with ${selectedUsers.length} users`, { theme: 'colored', autoClose: 700 });
            handleClose();
        } catch (error) {
            console.log(error);
            setSharingInProgress(false);
        }
        
    }

    const createTab = async (workspace, uid) => {

        if (workspace.users) delete workspace.users;
        if (workspace.id) delete workspace.id;
        if (workspace.timestamp) delete workspace.timestamp;
        const tab = {
            ...workspace,
            users: [uid],
            timestamp: {
                created: new Date(),
                updated: new Date()
            }
        }
        try {
            const docRef = await addDoc(collection(db, "tabs"), tab);
            return docRef.id;
        } catch (error) {
            console.log(error);
            return error;
        }
    }
    
    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Share Workspace
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-column">
                    {users.map((user, index) => (
                        <div key={`user${index}`} className="d-flex justify-content-between align-items-center">
                            <div>{user.name}</div>
                            <input type="checkbox" checked={selectedUsers.includes(user.uid)} onChange={() => handleSelectUser(user.uid)}/>
                        </div>
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleClose}>Close</Button>
                <Button onClick={handleShare}>Share</Button>
            </Modal.Footer>
        </Modal>
    )
}

  


