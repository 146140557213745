import React, { useEffect, useState, useRef } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { auth, db } from 'config/firebase';
import { useDocumentData, useCollectionData } from 'react-firebase-hooks/firestore';
import { doc, updateDoc, arrayUnion, deleteDoc, collection } from 'firebase/firestore';
import { 
    Alert, 
    Button, Spinner, Card, Container, 
    Row, Col, Badge, Image, Accordion, ListGroup, 
    InputGroup, Form, FormControl, Modal } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import MissingImage from './../../assets/merlin/missing_image.png';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ShoppingCart from 'components/app/e-commerce/cart/ShoppingCart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt as pencil, faTrash as trash} from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify';
import useRoleData from 'hooks/useRoleData.js';
import * as fuzz from 'fuzzball'

const Carts = () => {

    const { cartId } = useParams();
    const [viewingCartID, setViewingCartID] = useState("Loading...")
    const [editTitle, setEditTitle] = useState(false);
    const [deleteCartState, setDeleteCartState] = useState(false);
    const [user, authLoading] = useAuthState(auth);
    const navigate = useNavigate();
    const [manualOrder, orderLoading, orderError, orderSnapshot] = useDocumentData(
        doc(db, "openOrders", cartId)
    );
    const [roleData, orgData, userLoading, userError] = useRoleData(user?.uid);
    const [fuzzyChoices, loading, error] = useCollectionData(collection(db, 'fuzzySearch/topicz/fuzzyChoices'));
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    
    async function searchProducts(searchTerm) {
        try {

            const options = {
                scorer: fuzz.partial_ratio,
                processor: (choice) => choice.proc_sorted,
                limit: 50,
                cutoff: 90,
                unsorted: false,
                full_process: false,
            }

            const results = fuzz.extract(searchTerm, fuzzyChoices, options)

            setSearchResults(results);
            
            return results;
        } catch (error) {
            console.log(error);
        }

    }

    async function addToCurrentCart(hit) {
        try {
            await updateDoc(
                doc(db, "openOrders", viewingCartID),
                {
                    items: arrayUnion({
                        sku: hit.sku,
                        title: hit?.name,
                        cost: '0.00',
                        picture: hit?.image || MissingImage,
                        quantity: 1
                    })
                } 
            )
            setSearchTerm('');
            setSearchResults([]);
            toast.success(`${hit.name} added to cart!`, {
                theme: 'colored'
                })
            
        } catch (error) {
          console.log(error);
        }
         
        
    }

    async function handleTitleChange(e) {
        e.preventDefault();
        setEditTitle(false);
        let temp = e.target[0].value;
        try {
            
          await updateDoc(doc(db, "openOrders", viewingCartID), {
            orderBatchName: temp
          })
          
        } catch (error) {
          console.log(error);
        }
         
    }

    async function deleteCurrentOrder(e) {
        e.preventDefault();
        try {
            await deleteDoc(doc(db, "openOrders", cartId))
            navigate(-1);
            toast.warn("Cart has been deleted!", {
                theme: 'colored'
              })
        } catch (error) {
          console.log(error);
        }
         
    }

    const handleDeleteClose = () => setDeleteCartState(false);

    useEffect(() => {
      if (loading) return;
      if (!searchTerm) return;
      searchProducts(searchTerm).then((results) => {
        setSearchResults(results);
      })
    }, [searchTerm])
    
    const NoResult = () => {
        return <Alert variant='danger'>
            <p>This cart cannot be found or you do not have sufficient permissions to view it. </p>
            <Button onClick={() => {navigate(-1)}}>Return to Previous Page</Button>
        </Alert>
    }
    
    useEffect(() => {
        if (authLoading) return;
        if (!user) return navigate('/');
        if (!cartId) return navigate(-1);
        setViewingCartID(cartId);
        
    }, [user, authLoading, cartId]);

    

    return (
        <>
            {
                !orderLoading ?
                    manualOrder ? 
                    <Container fluid>
                        <PageHeader
                            title="" titleTag="h4" className="mb-3">
                            <div className="position-relative" style={{height: '2.75rem'}}>
                                
                                <div className="position-absolute top-50 start-0">
                                        <h4>{manualOrder.orderBatchName}</h4>
                                        <p>Order Status: {manualOrder.status}</p>
                                </div>
                                <div className="position-absolute top-50 end-0">
                                        <FontAwesomeIcon className='ms-2' icon={pencil} onClick={() => setEditTitle(!editTitle)}/>
                                        <FontAwesomeIcon className='ms-2' icon={trash} onClick={() => setDeleteCartState(!deleteCartState)}/>                    
                                </div>
                                
                            </div>
                            
                            {editTitle 
                                ?
                                <div className='mb-4 mt-4'>
                                <Form onSubmit={(e) => handleTitleChange(e)}>
                                    <InputGroup className="mb-3">
                                        <FormControl
                                            placeholder={manualOrder.orderBatchName}
                                            defaultValue={manualOrder.orderBatchName}
                                            aria-label="New Order/Batch Name"
                                            aria-describedby="basic-addon2"
                                        />
                                        <Button variant="outline-secondary" id="button-addon2" type="submit">
                                        Update
                                        </Button>
                                    </InputGroup>
                                </Form>
                            
                                </div>
                                :
                                null
                            }
                            
                        
                            <div className='mt-4'>
                                
                            </div>
                        </PageHeader>

                        <Modal show={deleteCartState} onHide={handleDeleteClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Delete Order?</Modal.Title>
                            </Modal.Header>
                                <Modal.Body>Please confirm you'd like to delete this order by pressing the delete button below.</Modal.Body>
                            <Modal.Footer>
                            <Button variant="secondary" onClick={handleDeleteClose}>
                                Close
                            </Button>
                            <Button 
                                variant="falcon-danger" 
                                onClick={(e) => deleteCurrentOrder(e)}>
                                Delete Order
                            </Button>
                            </Modal.Footer>
                        </Modal>

                        {!orderLoading && manualOrder.status !== "Submitted"
                            ?
                                <Card className='mb-3'>
                                <Card.Body>
                                
                                    <Form className='mb-1'>
                                        <Form.Control
                                            type="search"
                                            placeholder="Search for products..."
                                            aria-label="Search"
                                            className="rounded-pill search-input"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            
                                        />
                                    </Form>
                                        {searchTerm.length > 0 ?
                                                <div className='mb-4'>
                                                    <ListGroup>
                                                        {searchResults.map((hit, index) => {
                                                            const product = hit[0];
                                                            return (
                                                                <ListGroup.Item
                                                                    key={index}
                                                                    className='d-flex justify-content-between align-items-center m-0'
                                                                    onClick={() => addToCurrentCart(product)}
                                                                >
                                                                    <div className='d-flex align-items-center'>
                                                                        <Image 
                                                                            src={product?.image || MissingImage} 
                                                                            alt={product?.name} 
                                                                            height={50}
                                                                            />
                                                                        <div className='ms-2'>
                                                                            <p className='mb-0'>{product?.name}</p>
                                                                            <p className='mb-0'>SKU: {product?.sku}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <p className='mb-0'>${hit.ibmi_cost}</p>
                                                                    </div>
                                                                </ListGroup.Item>
                                                            )
                                                        })}
                                                    </ListGroup>
                                                </div>
                                            :
                                                null
                                        }
                                    

                                
                                
                                </Card.Body>
                                </Card>
                            :
                                null
                        }

                        {manualOrder.status !== "Submitted"
                        ?
                                !orderLoading
                                    ? <ShoppingCart 
                                        items={manualOrder.items} 
                                        cartId={manualOrder.docId} 
                                        batchName={manualOrder.orderBatchName}
                                        org={orgData}                        
                                    /> 
                                    : <Spinner />
                            :
                                <Card className="p-3">
                                    <Alert variant="success">
                                        Thank you! Your order has been submitted.
                                    </Alert>
                                </Card>

                        }
                    </Container>
                    : <NoResult /> 
                :
                    <Spinner />
            }
        </>
    )

}



export default Carts;