import React, { useEffect, useState } from 'react';
import { Badge, Image, Tooltip, OverlayTrigger, Modal, Button, Row, Col, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import IconButton from 'components/common/IconButton';
import ProductCarousel from './components/ProductCarousel';
import { useHttpsCallable } from 'react-firebase-hooks/functions';
import { db, functions, fileStorage } from 'config/firebase';
import { ref, uploadBytesResumable, getDownloadURL, uploadBytes } from "firebase/storage";
import { doc, arrayRemove, arrayUnion, updateDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

const ProductDetailsMedia = ({ product: { files, sku, vendor }, productDocRef}) => {

  const [pictureModalState, showPictureModal] = useState(false);
  const [newImageFile, setNewImageFile] = useState("");
  const [pictureUploadProgress, setPercent] = useState(0);
  const [downloadURL, setDownloadURL] = useState("");
  const [downloadRemoteImage, downloadExecuting, downloadError] = useHttpsCallable(functions, 'downloadRemoteImage');
  
  const handlePictureShow = () => showPictureModal(true);
  const handleFileSelect = (e) => setNewImageFile(e.target.files[0]) 
  const handleURLSelect = (e) => {
    setDownloadURL(e.target.value);
  }
  
  const handlePictureClose = () => {
    showPictureModal(false);
    setNewImageFile("");
    setPercent(0);
  };

  async function changePrimaryImg(url) {
    try {
      if (!url) return Promise.reject("Must include picture URL")
      return await updateDoc(productDocRef.ref, {
        primaryImg: url
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function handleFileUpload(e, docRef) {
    e.preventDefault();
    if (!newImageFile) return;

    const filename = newImageFile.name.split(".");
    const unique = uuidv4();
    // create a unique name and save with original file/filename suffix
    const storageRef = ref(fileStorage, `/images/products/${unique}.${filename[filename.length - 1]}`)

    const snapshot = await uploadBytes(storageRef, newImageFile);
    const url = await getDownloadURL(snapshot.ref);
    await updateDoc(docRef, {
      files: arrayUnion({
        alt: "product image",
        src: url,
        id: unique
      })
    });
    changePrimaryImg(url);
    // handlePictureClose();
    return url;
  }

  function ImageGallery({images, vendor}) {

    const allImages = images;
    const imgDisplay = [];
    if (typeof allImages !== 'undefined') {
      allImages.forEach((img, i) => {
        imgDisplay.push(<ThumbNailImage img={img} sku={sku} vendor={vendor} key={`thumb-${i}`}/>)
      })  
    }

    return (
      <>
        <Row xs={1} md={2} className="g-4">
          {imgDisplay.length > 0 ? imgDisplay : <Col>No images available please upload or import from Product Genius</Col>}
        </Row>
      </>
    )
  }

  function ThumbNailImage({ img, sku, vendor }) {
    
    return (
      <Card className='mt-5 m-3' style={{width: '8rem'}}>
        
        <Image src={img.src} fluid="true"/> 
        <Row>
          <Col className='mt-2 mb-2'>
            <OverlayTrigger
              key={'setPrimaryImageTooltip'}
              placement={'top'}
              overlay={
                <Tooltip>
                  Set as primary
                </Tooltip>
              }
            >
              <IconButton
                size='sm' 
                className='w-50'
                icon='star'
                onClick={async () => {
                  try {
                    await changePrimaryImg(img.src)
                    handlePictureClose();
                  } catch (error) {
                    console.log(error)
                  } 
                }}
              ></IconButton>  
            </OverlayTrigger>
            <OverlayTrigger
              key={'removeImageTooltip'}
              placement={'top'}
              overlay={
                <Tooltip>
                  Remove image
                </Tooltip>
              }
            >
              <IconButton
                className='w-50'
                icon='trash'
                size='sm'
                variant='danger' 
                onClick={async () => {
                  try {
                    await removeImageFromGallery(img, productDocRef)
                  } catch (error) {
                    console.log(error)
                  }
                }}
              ></IconButton>
            </OverlayTrigger>
            
          </Col>
        </Row>
        

      </Card>
    )
  }

  async function removeImageFromGallery(url, docRef) {
    try {
      const myArrayRemove = arrayRemove(url);
      await updateDoc(docRef.ref, {
        files: myArrayRemove
      })

    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="position-relative h-sm-100 overflow-hidden">
      {files?.length === 1 && (
        <Image
          fluid
          className="h-100"
          src={files[0].src}
          alt="product media"
          style={{width: 'auto', height: '255px', maxHeight: '255px'}}
        />
      )}
      {files?.length > 1 && (
        <>
          <div className="product-slider">
            <ProductCarousel images={files}/>
          </div>
        </>
      )}
      <OverlayTrigger
          key={'leftNewPicToolTip'}
          placement={'left'}
          overlay={
            <Tooltip>
              Click to add a new image
            </Tooltip>
          }
        >
          <IconButton
            icon={'plus'}
            variant={'falcon-primary'}
            className="position-absolute top-0 end-0 me-2 mt-2 fs--2 z-index-2"
            onClick={handlePictureShow}
          />
          
      </OverlayTrigger>
      <Modal size="lg" show={pictureModalState} onHide={handlePictureClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Product Photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-3'>
            <h5>Upload New Photo from File</h5>
            <input onChange={(e) => handleFileSelect(e)} type={'file'} accept="image/*" />
            
            <button onClick={(e) => handleFileUpload(e, productDocRef.ref)}>
              {pictureUploadProgress != 0 ? 
              `${pictureUploadProgress}`  : "Upload"}
            </button>
          </div>
          <div className='mb-3'>
            <h5>Upload New Photo from URL</h5>
            <input onChange={(e) => handleURLSelect(e)} id="downloadByURLInput" type={'text'} />
            
            <Button 
              onClick={() => {
                downloadRemote(downloadURL)
              }}
              disabled={downloadExecuting}
            >Grab Image</Button>

          </div>
          <p></p>
          <div className='mb-3'>
            <h5>Or select below</h5>
            <ImageGallery images={files} vendor={vendor}/>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlePictureClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );

  async function downloadRemote(src) {
    try {
      const res = await downloadRemoteImage({imgSrc: src})
      const merlinLocation = res.data;
      await updateDoc(productDocRef.ref, {
        files: arrayUnion({
          alt: "product image",
          src: merlinLocation,
          id: uuidv4()
        })
      });
      setDownloadURL("");
      return;
    } catch (error) {
      console.log('dr', error);
      console.log('drc', src, downloadError)
    }
  }

};


ProductDetailsMedia.propTypes = {
  product: PropTypes.shape({
    files: PropTypes.arrayOf(PropTypes.object),
    isNew: PropTypes.bool
  })
};

export default ProductDetailsMedia;
