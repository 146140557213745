import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from 'config/firebase';
import {
  query,
  collection,
  where,
  getDocs
} from 'firebase/firestore';
import {
  Spinner,
  Card,
  Row,
  Col,
  Button
} from 'react-bootstrap';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import PageHeader from 'components/common/PageHeader';
import SoftBadge from 'components/common/SoftBadge';
import { toast } from 'react-toastify';
import { useCollection } from 'react-firebase-hooks/firestore';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import axios from 'axios';

const CreatePostcardCampaign = () => {
  const { user, authLoading }= useContext(FirebaseAuthContext)
  const navigate = useNavigate();
  useEffect(() => {
    if (authLoading) return;
    if (!user) return navigate('/');    
  }, [user, authLoading, navigate]);
  
  /** Collect and process Roundtable collection data */
  const [postcardsRaw, postcardsLoading, postcardsError] = useCollection(query(collection(db, 'postcardCampaigns')));
  const [postcards, setPostcards] = useState([]);
  useEffect(() => {
    if (postcardsLoading) return;
    if (postcardsError) {
      toast.error("Error retrieving postcard campaigns", { theme: "colored"})
      return;
    }
    if (!postcardsRaw) return;
    let postcardsArray = [];
    postcardsRaw.forEach((doc) => {
      let temp = doc.data();
      temp.postcardId = doc.id;
      temp.docRef = doc.ref;
      postcardsArray.push(temp)
    })
    postcardsArray = postcardsArray.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    })
    return setPostcards(() => postcardsArray)
  }, [postcardsRaw, postcardsLoading, postcardsError])

  const ExistingPostcardDropdown = (postcards) => {
    return (
      <select className="form-control mt-3">
        <option value="0">Start from existing campaign</option>
        {postcards.map((postcard, idx) => {
          return (
            <option key={idx} value={postcard.postcardId}>{postcard.name}</option>
          )
        })}
      </select>
    )
  }

  return (
    <>
      <PageHeader
        title="Create New Postcard Campaign" titleTag="h5" className="mb-3">
        {postcards ? ExistingPostcardDropdown(postcards) : <Spinner animation="border" variant="primary" />}
      </PageHeader>
      <Card className="p-3">
        <Row>
          <Col>
            <h5 className="mb-3">Step 1: Select a Postcard Template</h5>
            <p>Choose a postcard template to use for this campaign. You can choose from one of our pre-made templates, or upload your own.</p>
            <Button
              variant="primary"
              size='sm'
              className="mt-3"
              onClick={() => navigate('/marketing/postcards/create/template')}
            >
              Create New Postcard Template
            </Button>
          </Col>
          <Col>
            <h5 className="mb-3">Step 2: Select a Mailing List</h5>
            <p>Choose a mailing list to send this postcard to. You can choose from one of our pre-made mailing lists, or upload your own.</p>
            <Button
              variant="primary"
              size='sm'
              className="mt-3"
              onClick={() => navigate('/marketing/postcards/create/mailing-list')}
            >
              Create New Mailing List
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5 className="mb-3">Step 3: Select a Send Date</h5>
            <p>Choose a date to send this postcard campaign. You can choose to send it immediately, or schedule it for a later date.</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5 className="mb-3">Step 4: Review and Send</h5>
            <p>Review the details of your postcard campaign and send it out to your mailing list.</p>
          </Col>
        </Row>``

      </Card>
    </>
  );
};

export default CreatePostcardCampaign;
