import { useState } from 'react';
import { Modal, Button, Form, Row, Col, } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { updateDoc } from 'firebase/firestore';

const ChangeType = ({show, handleClose, data}) => {

    const [selectedType, setSelectedType] = useState('');

    const TypeRadio = ({label, value, name}) => {
        return (
            <Form.Check
                type='radio'
                label={label}
                name={name}
                value={value}
                onChange={e => setSelectedType(e.target.value)}
            />
        )
    }

    const handleSubmit = () => {
        const changeTypePromises = [];
        for (let i = 0; i < data.length; i++) {
            changeTypePromises.push(updateDoc(data[i].ref, {
                doNotPick: selectedType === 'selfPick' ? true : false
            }));
        }
        Promise.all(changeTypePromises)
        .then(() => {
            toast.success(`Type of ${data.length} ${data.length > 1 ? 'batches' : 'batch'} changed`);
            handleClose();
        })
    }

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Change Type</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to change the type of {data.length} {data.length > 1 ? 'batches' : 'batch'}?
                <Form>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label as="legend" column sm={2}>
                            Select New Type
                        </Form.Label>
                        <Col sm={10}>
                            <TypeRadio label='Self Pick' value='selfPick' name='type' />
                            <TypeRadio label='Team Pick' value='teamPick' name='type' />
                        </Col>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Change Type
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ChangeType;
