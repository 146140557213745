/** Inventory Heads Up Display for Product Page */
import { Spinner, Table, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import { db, functions } from 'config/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useHttpsCallable } from 'react-firebase-hooks/functions';

export default function InventoryWidget({ product }) {

    const [productBPF, bpfExecuting, bpfError] = useHttpsCallable(functions, 'getProductBurnRatePlusFulfillmentCost');
    const [stats, setProductStats] = useState();

    let SKIP = false;
    if (!stats) SKIP = true;
    const [loading, setLoading] = useState(true);
    const [inventory, setInventory] = useState();

    useEffect(() => {

        productBPF({sku: product.sku}).then((res) => {
          setProductStats(res.data.stats)
        })
        
    }, [/** Just run on initial load */])
    

    useEffect(() => {
        if (bpfExecuting) return;

        async function getInventoryLocations(){
            const locations = [];
            try {
            const querySnapshot = await getDocs(collection(db, "locations"));
            querySnapshot.forEach((doc) => {
                locations.push({docId: doc.id, ...doc.data()})
            })
            return locations;
            } catch (error) {
            console.log(error);
            }
        }

        async function getProductCount(sku, id, name) {
            try {

            let collectionFilter;

            if (id === '0') { 
                collectionFilter = collection(db, "productCatalog/topicz/products")
            } else {
                collectionFilter = collection(db, "locationCountData")
            };

            let whereFilter;
            if (id === '0') {
                whereFilter = where("sku", "==", sku)
            } else {
                whereFilter = where("parentSku", "==", sku)
            }

            let locationFilter = '';
            if (id !== '0') {
                locationFilter = where("locationId", "==", id)
            }

            // const q = query(
            //     collectionFilter,
            //     whereFilter,
            //     locationFilter
            // );

            let q;
            if (id === '0') {
                q = query(
                    collectionFilter,
                    whereFilter,
                );
                } else {
                q = query(
                    collectionFilter,
                    whereFilter,
                    locationFilter
                );
            }

            let results = [];
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                results.push({name: name, count: '0', secondary: 'none'});
            }
            
            querySnapshot.forEach((doc) => {
                let count, warehouseCount, slot;
                let temp = doc.data();
                if (id === '0') {
                    count = temp?.ecomm_onhand;
                    warehouseCount = temp?.ibmi?.onhand;
                    slot = temp?.ibmi?.slot
                    results.push({name: name, count: count, secondary: warehouseCount, slot: slot})
                } else {
                    count = 'count'
                    warehouseCount = 'count'
                    slot = 'slot'
                    results.push({name: name, count: String(temp[count]), secondary: String(temp[warehouseCount]), slot: temp[slot]})
                }
            })
            return results;
            } catch (error) {
            console.log(error);
            }
        }

        async function gatherCounts(sku) {
            try {
            const locations = await getInventoryLocations();
            
            const countPromises = [];
            locations.forEach((location) => {
                countPromises.push(getProductCount(sku, location.docId, location.name))
            })
            return Promise.all(countPromises);
            } catch (error) {
            console.log(error);
            }
        }

        return gatherCounts(product.sku).then((results) => {
            setLoading(false);
            setInventory(results)
        })
        .catch(error => console.log(error))

    }, [bpfExecuting])
    
    return (
        <>
            {!loading ?
                <>
                    <Table hover size="sm" responsive>
                        <thead>
                            <tr>
                                <th><Form.Label>Location</Form.Label></th>
                                <th><Form.Label>Slot</Form.Label></th>
                                <th><Form.Label>Count</Form.Label></th>
                                <th><Form.Label>Days of Supply</Form.Label></th>
                            </tr>
                        </thead>
                        <tbody>
                                {!loading && inventory?.length > 0 ?
                                
                                    inventory.map((data) => {
                                        let daysRemaining;
                                        if (SKIP) {
                                            daysRemaining = 'None'
                                        } else {
                                            daysRemaining = Number(data[0].count/stats?.burnRate).toFixed(2);
                                        }
                                        let colorClass;
                                        daysRemaining < 90 ? colorClass = "text-primary" : colorClass = "text-black";
                                        daysRemaining < 90 ? colorClass = "text-primary" : null;
                                        daysRemaining < 15 ? colorClass = "text-warning" : null;
                                        daysRemaining < 5 ? colorClass =  "text-danger" : null;

                                        return (
                                            data[0].secondary != 'none' ? 
                                                <tr key={data[0]?.name}>
                                                <td>
                                                    <Form.Label>{data[0]?.name}</Form.Label>
                                                </td>
                                                <td>
                                                    {data[0]?.slot ? <Form.Label>{data[0]?.slot}</Form.Label> : "None"}
                                                </td>
                                                <td>
                                                    <Form.Label>{data[0]?.count}</Form.Label>
                                                </td>
                                                <td>
                                                    <Form.Label><span className={colorClass}>{daysRemaining}</span></Form.Label>
                                                </td>
                                            </tr> : null
                                            
                                            
                                        )
                                    })
                                        
                                    :
                                    <tr><td><Spinner /></td></tr>
                                }
                            
                        </tbody>
                    </Table>
                    {stats ?
                        <Table hover size="sm" responsive>
                        
                        <tbody>
                                <tr>
                                    <td><Form.Label>Average Selling Price</Form.Label></td>
                                    <td><Form.Label>${stats?.avg || 'None'}</Form.Label></td>
                                </tr>
                                <tr>
                                    <td><Form.Label>Minimum</Form.Label></td>
                                    <td><Form.Label>${stats?.min}</Form.Label></td>
                                </tr>
                                <tr>
                                    <td><Form.Label>Maximum</Form.Label></td>
                                    <td><Form.Label>${stats?.max}</Form.Label></td>
                                </tr>
                                <tr>
                                    <td><Form.Label>Inventory Burn Rate</Form.Label></td>
                                    <td><Form.Label>{Number(stats?.burnRate).toFixed(2)} units per day</Form.Label></td>
                                </tr>
                                <tr>
                                    <td><Form.Label><span className="text-muted">Data spanning previous {Number(stats?.daySpan).toFixed(2)} days</span></Form.Label></td>
                                </tr>
                        </tbody>
                    </Table>
                        : null
                    }
                    
                </>
              : <Spinner />
            }
            
        </>
        
    )    
    
}
