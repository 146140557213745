import { useState, useContext, useEffect } from 'react';
import { db } from "config/firebase";
import { useNavigate } from 'react-router-dom';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import { collection, query, where, getCountFromServer, doc, onSnapshot, updateDoc, limit, getDocs } from "firebase/firestore";
import PageHeader from 'components/common/PageHeader';
import { Card, Row, Col, Button, Form, Tabs, Tab, Image, Badge, Nav } from 'react-bootstrap';
import Lottie from 'lottie-react';
import dotsAnimation from 'assets/lottie/dots.json';
import OpenOrders from 'components/pages/BatchPickPack/subComponents/OpenOrders';
import OpenBatches from 'components/pages/BatchPickPack/subComponents/OpenBatches';
import BatchAutomations from 'components/pages/BatchPickPack/subComponents/BatchAutomations';

const BatchManagement = ({ children }) => {

    const { user, authLoading }= useContext(FirebaseAuthContext)
    const navigate = useNavigate();
    const [loadingState, setLoadingState] = useState(false);
    const [activeKey, setActiveKey] = useState('first'); // default is first tab
    
    // Redirect to home if not logged in
    useEffect(() => {
        if (authLoading) return;
        if (!user) return navigate('/');
      }, [authLoading, user])

    // Get active tab from user's profile
    useEffect(() => {
        if (authLoading) return;
        if (!user) return;
        setLoadingState(true);
        //const userRef = doc(db, "users", user.uid);
        const userQ = query(
            collection(db, "users"),
            where("uid", "==", user.uid),
            limit(1)
        );
        getDocs(userQ).then((querySnapshot) => {
            if (querySnapshot.empty) return;
            const doc = querySnapshot.docs[0];
            const data = doc.data();
            if (data.activeBatchTab) {
                setActiveKey(data.activeBatchTab);
            }
        }).catch((error) => {
            console.log("Error getting documents: ", error);
        }).finally(() => {
            setLoadingState(false);
        });

    }, [authLoading, user]);

    const saveActiveTabToUsersProfile = (key) => {
        // Save active tab to user's profile
        const userQ = query(
            collection(db, "users"),
            where("uid", "==", user.uid),
            limit(1)
        );
        getDocs(userQ).then((querySnapshot) => {

            if (querySnapshot.empty) return;
            const docRef = querySnapshot.docs[0].ref;
            updateDoc(docRef, {
                activeBatchTab: key
            });
        }).catch((error) => {
            console.log("Error getting documents: ", error);
        }).finally(() => {
            setLoadingState(false);
        });
    }

    const [unassignedOrdersCount, setUnassignedOrdersCount] = useState([]);
    const [openBatchCount, setOpenBatchCount] = useState([]);
    useEffect(() => {
        const unsubscribeOrders = onSnapshot(
            query(
                collection(db, "orders"), 
                where("orderStatus", "==", "awaiting_shipment"), 
                where("assignedToBatch", "==", false)
            ), 
            (snapshot) => {
                setUnassignedOrdersCount(snapshot.size);
            }
        );
        const unsubscribeBatches = onSnapshot(
            query(
                collection(db, "customBatches"),
                where("status", "==", "OPEN")
            ),
            (snapshot) => {
                setOpenBatchCount(snapshot.size);
            }
        );
        return () => {
            unsubscribeOrders();
            unsubscribeBatches();
        }
    }, []);

    const handleTabSelect = (key) => {
        setActiveKey(key);
        saveActiveTabToUsersProfile(key);
    }

    return (
        !loadingState ?
        <>
                <Row>
                    <Col>
                        <Tab.Container defaultActiveKey={activeKey} onSelect={handleTabSelect}>
                            <PageHeader title='Batch Management' className='mb-2'>
                                <Nav justify variant="pills" className="mt-2 justify-content-start">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">{unassignedOrdersCount} Unassigned Orders </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">{openBatchCount} Open Batches </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">Automations</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </PageHeader>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <OpenOrders />
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <OpenBatches />
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <BatchAutomations />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Col>
                </Row>
        </>
        :
        <Lottie animationData={dotsAnimation} style={{width: 100, height: 100}} />
    );
}

export default BatchManagement;