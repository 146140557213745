import { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import { Card, Row, Col, Button, Form, Modal, Image, InputGroup, Badge, Spinner } from 'react-bootstrap';
import Lottie from 'lottie-react';
import dotsAnimation from 'assets/lottie/dots.json';
import { useCollectionData, useCollection } from 'react-firebase-hooks/firestore';
import { db } from "config/firebase";
import { 
    query, collection, orderBy, startAt, limit, startAfter, endAt,
    getDocs, endBefore, addDoc, collectionGroup, where, getDoc, doc, updateDoc,
    getCountFromServer, writeBatch, deleteField
} from 'firebase/firestore';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import IconButton from 'components/common/IconButton';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { toast } from 'react-toastify';

function BatchesTable({ batches }) {

    const baseUrl = 'localhost:3000'

    const [enhancedBatches, setEnhancedBatches] = useState([]);
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [selectedBatchForAssignment, setSelectedBatchForAssignment] = useState(null);
    const [sendBatchToPickExecuting, setSendBatchToPickExecuting] = useState(false);
    const [deleteExec, setDeleteExec] = useState(false);

    async function deleteBatch(batch) {
        try {
            setDeleteExec(true);
            await updateDoc(batch.ref, {
                status: 'DELETED',
                "timestamp.modified": new Date(),
            });
            // need to delete the assignedToBatch field from each order in the batch
            const orders = batch.orders;
            // create firebase batch
            const batchDelete = writeBatch(db);
            for (let i = 0; i < orders.length; i++) {
                const orderId = orders[i]?.orderId;
                if (orderId) {
                    const q = query(
                        collection(db, "orders"),
                        where("orderId", "==", orderId),
                    );
                    const querySnapshot = await getDocs(q);
                    querySnapshot.forEach((doc) => {
                        batchDelete.update(doc.ref, {
                            assignedToBatch: deleteField(),
                            batchId: deleteField(),
                            batchName: deleteField(),
                        });
                    });
                } else {
                    console.log("oderId", orderId)
                }
            }
            await batchDelete.commit();
            toast.success(`Batch deleted. ${orders.length} orders returned to pool.`, { theme: 'colored' });
            setDeleteExec(false);
        } catch (error) {
            setDeleteExec(false);
            console.log(error);
            toast.error('Error deleting batch', { theme: 'colored' });
        }
    }

    async function getOrdersForBatch(batch) {
        const orders = [];
        for (let i = 0; i < batch.orders?.length; i++) {
            const orderId = batch.orders[i];
            const q = query(
                collection(db, "orders"),
                where("orderId", "==", orderId),
            );
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                let temp = doc.data();
                temp.id = doc.id;
                temp.ref = doc.ref;
                orders.push(temp);
            });
        }
        return orders;
    }

    async function getItemCountsForBatch(batch) {
        const orders = await getOrdersForBatch(batch);
        const items = [];
        orders.forEach(order => {
            order.items.forEach(item => {
                items.push(item);
            })
        })
        const skus = items.map(item => item.sku);
        const uniqueSkus = [...new Set(skus)];
        const itemCounts = [];
        for (let i = 0; i < uniqueSkus.length; i++) {
            const sku = uniqueSkus[i];
            const count = items.filter(item => item.sku === sku).length;
            itemCounts.push({ sku: sku, count: count });
        }
        return { skus: uniqueSkus, totalItemsCount: itemCounts, orders: orders };
    }
        
    useEffect(() => {
        // enhance the batches data with additional information
        // get the orders for each batch
        let isSubscribed = true;
        if (!batches) return;
        setEnhancedBatches([]);
        for (let i = 0; i < batches.length; i++) {
            const batch = batches[i];
            getItemCountsForBatch(batch).then((enhanced) => {
                const { skus, totalItemsCount, orders } = enhanced;
                const enhancedBatch = {
                    ...batch,
                    orders: orders,
                    skus: skus,
                    totalItemsCount: totalItemsCount
                }
                if (isSubscribed) setEnhancedBatches(prevState => [...prevState, enhancedBatch]);
            })
        }
        return () => isSubscribed = false;
    }, [batches])

    const ReassignUser = ({ batch }) => {
        const assignedUser = batch.assignedUser || 'None';
        const [users, setUsers] = useState([]);
        const [newAssignedUser, setNewAssignedUser] = useState('');
        const [assignmentExecuting, setAssignmentExecuting] = useState(false);
        const handleClose = () => setShowAssignModal(false);

        async function gatherAssignableUsers() {
            const users = [];
            const querySnapshot = await getDocs(query(collection(db, "users"), where("fulfillmentAssignable", "==", true)));
            querySnapshot.forEach((doc) => {
                users.push(doc.data());
            });
            // remove the current assignedUser from the list of options
            const index = users.findIndex(user => user.name === assignedUser);
            if (index !== -1) {
                users.splice(index, 1);
            }
            return users;
        }

        useEffect(() => {
            if (!batch) return;
            let isSubscribed = true;
            gatherAssignableUsers().then((users) => {
                if (isSubscribed) setUsers(users);
            });
            return () => isSubscribed = false;
        }, [batch]);

        async function handleReassignment(newAssignedUser) {
            try {
                setAssignmentExecuting(true);
                await updateDoc(batch.ref, {
                    assignedUser: newAssignedUser
                });
                toast.success(`Batch reassigned to ${newAssignedUser}`, { theme: 'colored' });
                setAssignmentExecuting(false);
                handleClose();
            } catch (error) {
                console.log(error);
                toast.error('Error reassigning batch to user', { theme: 'colored' });
            }
        }

        const UserNameDropDown = ({ users }) => {
            return (
                <>
                    <Form.Group className="mb-3" controlId="formBasicUserSelect">
                    <Form.Label>Assign To User (Optional)</Form.Label>
                    <Form.Select 
                        aria-label="Select User" 
                        onChange={(e) => setNewAssignedUser(e.target.value)}
                    >
                        <option>Select User</option>
                        {users.map((user, index) => (
                            <option key={`${index}-${user.id}`} value={user.id}>{user.name}</option>
                        ))}
                    </Form.Select>
                    </Form.Group>
                    { newAssignedUser !== '' &&
                        <div className='mt-3'>
                            <Button
                                variant="success"
                                onClick={() => handleReassignment(newAssignedUser)}
                                disabled={assignmentExecuting}
                            >Assign Batch to {newAssignedUser}</Button>
                        </div>
                    }
                </>
            )
        }

        return (
            <> 
                {assignedUser === 'None' ?
                    <div>
                        <h6>Whoa this batch has no assigned user!</h6>
                        <p>Select user below that the batch will be assigned to:</p>
                    </div>
                :   <div>
                        <h6>This batch is currently assigned to {assignedUser}</h6>
                        <p>Select user below that the batch will be reassigned to:</p>
                    </div>
                }
                <UserNameDropDown users={users} />
            </>
            
        )
    }

    function handleAssignmentClick(e, batch) {
        e.preventDefault();
        setShowAssignModal(true);
        setSelectedBatchForAssignment(batch);
    }

    const colDef = [
        { 
            accessor: 'batchName',
            Header: 'Batch Name',
            Cell: ({ row }) => {
                const { batchName, id } = row.original;
                return (
                    <a href={`/fulfillment/batches/${id}`}>{batchName}</a>
                )
            }
        },
        {
            Header: 'Created',
            accessor: 'timestamp.created.seconds',
        },
        {
            Header: 'Assigned User',
            Cell: ({ row }) => {
                const { assignedUser } = row.original;
                if (!assignedUser) {
                    return (
                        <Button
                            size='sm'
                            variant='outline-primary'
                            onClick={(e) => handleAssignmentClick(e, row.original)}
                            disabled={assignedUser}
                        >Assign User</Button>
                    )
                }
                return (
                    <span>{assignedUser}</span>
                )
            }
        },
        {
            Header: 'Orders in Batch',
            Cell: ({ row }) => {
                const { orders } = row.original;
                // reduce the count in totalItemsCount to get an aggregate number of items in batch
                const totalOrders = orders?.length;
                return (
                    <>
                        <div className='block text-700 fw-bold'>{totalOrders}</div>
                    </>   
                )
            }
        },
        {
            Header: 'Actions',
            Cell: ({ row }) => {
                // reduce the count in totalItemsCount to get an aggregate number of items in batch
                return (
                    <>
                        <IconButton 
                            size='sm'
                            variant='outline-default'
                            icon='user'
                            className='me-2'
                            onClick={() => sendBatchToPick(row.original)}
                            disabled={sendBatchToPickExecuting}
                        >
                            {!sendBatchToPickExecuting ? 'Send To Pick' : <Spinner animation="border" variant="primary" /> }
                        </IconButton>

                        <IconButton
                            variant="outline-default"
                            size="sm"
                            icon="trash-alt"
                            transform="shrink-3"
                            className='me-2 text-danger'
                            onClick={() => deleteBatch(row.original)}
                            disabled={deleteExec}
                        >
                            <span className="d-none d-sm-inline-block ms-1">Delete</span>
                        </IconButton>
                    </>   
                )
            }
        },
    ];

    console.log(enhancedBatches[0])

    function sendMultipleBatchesForPick(batches) {
        setSendBatchToPickExecuting(true);
        try {
            if (!batches) return;
            batches.forEach((batch) => {
                sendBatchToPick(batch, false);
            })
            toast.success(`${batches.length} Batches sent to pick`, { theme: 'colored' });
            setSendBatchToPickExecuting(false);
        } catch (error) {
            console.log(error);
            toast.error('Error sending batches to pick', { theme: 'colored' });
            setSendBatchToPickExecuting(false);
        }
    }

    const SendMultiBatchToPick = ({ batches }) => {
        return (
            <>
                <div className='block text-700 fw-bold'>Send {batches.length} Batches to Pick</div>
                <div className='mt-3'>
                    <Button
                        variant="success"
                        onClick={() => sendMultipleBatchesForPick(batches)}
                    >Send Batches to Pick</Button>
                </div>
            </>
        )
    }

    function BulAction(table){
        
        const { selectedRowIds, page } = table;
        const [showBulkModal, setShowBulkModal] = useState(false);
        const [bulkActionOption, setBulkActionOption] = useState('');
        const handleClose = () => setShowBulkModal(false);
        let selectedRows = [];
        if (selectedRowIds && page) {
            selectedRows = Object.keys(selectedRowIds).map(id => page.find(row => row.id === id)?.original);
        }

        return (
            <>
                <Row className="flex mb-3">
                <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                    <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                    {
                        Object.keys(selectedRowIds).length > 0 ?
                        'You have selected ' + Object.keys(selectedRowIds).length + ' batches' 
                        :
                        null
                    }
                    </h5>
                </Col>
                <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                    {Object.keys(selectedRowIds).length > 0 ? (
                    <div className="d-flex">
                        <Form.Select size="sm" aria-label="Bulk actions" onChange={(e) => setBulkActionOption(e.target.value)}>
                        <option>Bulk Actions</option>
                        <option value="sendToPick">Send To Pick</option>
                        </Form.Select>
                        <Button
                        type="button"
                        variant="falcon-default"
                        size="sm"
                        className="ms-2"
                        onClick={() => setShowBulkModal(true)}
                        >
                        Apply
                        </Button>
                    </div>
                    ) : (
                        <div id="orders-actions">
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="plus"
                            transform="shrink-3"
                            className='me-2'
                            onClick={() => setModalShow(true)}
                        >
                            <span className="d-none d-sm-inline-block ms-1">New Batch</span>
                        </IconButton>
                        </div>
                    )}
                </Col>
                </Row>
                <Modal show={showBulkModal} onHide={handleClose} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <Modal.Title>
                            {bulkActionOption === 'sendToPick' && 'Send Batches for Picking'}
                        </Modal.Title>
                        <FalconCloseButton onClick={handleClose}/>
                    </Modal.Header>
                    <Modal.Body>
                        {bulkActionOption === 'sendToPick' && <SendMultiBatchToPick batches={selectedRows}/>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                        Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    };

    function AssignUserModal({ show, setShow, batch }) {
    
      const handleClose = () => setShow(false);
    
      return (
        <>
          <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header>
              <Modal.Title>Assign User</Modal.Title>
              <FalconCloseButton onClick={handleClose}/>
            </Modal.Header>
            <Modal.Body>
                <ReassignUser batch={batch} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    }

    async function sendBatchToPick(batch, showToast = true) {
        // this function updates the batch status to IN_PICK
        // and sends an outgoingMail to the user assigned to the batch
        // with a link to the batch's pick wizard
        console.log(batch)
        setSendBatchToPickExecuting(true);
        try {
            await updateDoc(batch.ref, {
                status: 'IN_PICK',
                "timestamp.modified": new Date(),
            });
            const assignedUser = batch.assignedUser;
            const userQuery = query(
                collection(db, "users"),
                where("name", "==", assignedUser),
            );
            const userQuerySnapshot = await getDocs(userQuery);
            let usersRaw = [];
            userQuerySnapshot.forEach((doc) => {
                let temp = doc.data();
                temp.id = doc.id;
                temp.ref = doc.ref;
                usersRaw.push(temp);
            });
            const user = usersRaw[0];

            const orders = batch?.orders;

            // get the items in each order
            const itemsInOrders = [];
            for (let i = 0; i < orders?.length; i++) {
                const order = orders[i];
                const items = order.items;
                for (let j = 0; j < items?.length; j++) {
                    const item = items[j];
                    itemsInOrders.push({ 
                        sku: item.sku,
                        quantity: item.quantity,
                    });
                }
            }

            // combine like items together by increasing the quantity
            const items = [];
            itemsInOrders.forEach((item) => {
                const index = items.findIndex(i => i.sku === item.sku);
                if (index === -1) {
                    items.push(item);
                } else {
                    items[index].quantity += item.quantity;
                }
            });

            // look up each SKU to get the item name, current inventory, and slot location
            const itemsWithDetails = [];
            const itemsWithoutDetails = [];
            for (let i = 0; i < items?.length; i++) {
                const item = items[i];
                const q = query(
                    collectionGroup(db, "variants"),
                    where("sku", "==", item.sku),
                );
                const querySnapshot = await getDocs(q);
                if (querySnapshot.empty) {
                    // variant not found
                    itemsWithoutDetails.push(item);
                }
                for (let j = 0; j < querySnapshot.docs?.length; j++) {
                    const doc = querySnapshot.docs[j];
                    const variant = doc.data();
                    if (!variant.isBundle) {
                        
                        // look up the preferred fulfillment location for the variant
                        const fulfillmentLocation = variant.preferredFullfillmentLocation
                        const parent = await getDoc(variant.parentRef);
                        const parentData = parent.data();
                        let enhancedItem;
                        if (fulfillmentLocation === '0') {
                            // the product is a Topciz product and is not a bundle
                            enhancedItem = {
                                orderedSku: variant.sku,
                                parentSku: parentData.sku,
                                name: parentData.name,
                                slot: parentData.ibmi?.slot || 'None',
                                ibmiOnhand: parentData.ibmi?.onhand || 0,
                                subItem: parentData.ibmi?.subItem || 'None',
                                image: parentData?.primaryImage || parentData?.files[0] || 'None',
                            }
                        } else {
                            // the product is 3PL or alternate fulfillment
                            // look up location
                            const locationQuery = query(
                                collection(db, "locationCountData"),
                                where("sku", "==", variant.sku),
                                where("locationId", "==", fulfillmentLocation),
                            );
                            const locationQuerySnapshot = await getDocs(locationQuery);
                            let locationData = [];
                            locationQuerySnapshot.forEach((doc) => {
                                let temp = doc.data();
                                temp.id = doc.id;
                                temp.ref = doc.ref;
                                locationData.push(temp);
                            });
                            const slotData = locationData[0];
                            enhancedItem = {
                                orderedSku: variant.sku,
                                parentSku: parentData.sku,
                                name: parentData.name,
                                slot: slotData.slot || "Unknown",
                                ibmiOnhand: slotData.count || "0",
                                subItem: parentData?.ibmi?.subItem || 'None',
                                image: parentData?.primaryImage || parentData?.files[0] || 'None',
                                slotData: slotData,
                            }
                        }
                        // determine the quantity to collect
                        if (variant.productFactor < 1) {
                            // the product ordered is a "break-box" of the parent and we need to add up to the right quantity
                            // we take the ceiling of the quantity ordered divided by the productFactor
                            // example: Variant X has a productFactor of 0.5 and the customer orders 1
                            // we need to collect 2 of Variant X to fulfill the order
                            const newQuantity = Math.ceil(item.quantity / variant.productFactor);
                            enhancedItem.quantity = Number(newQuantity)
                        } else {
                            // if the product factor is 1 or greater, we just need to collect the quantity ordered multiplied by the productFactor
                            // example: Variant X has a productFactor of 1 and the customer orders 2
                            // we need to collect 2 of Variant X to fulfill the order
                            const newQuantity = item.quantity * variant.productFactor;
                            enhancedItem.quantity = Number(newQuantity);
                        }
                        itemsWithDetails.push(enhancedItem);
                    } else {
                        // the product is a bundle
                        // get the bundle items
                        const bundleDataRef = await getDoc(variant.parentRef);
                        const bundleData = bundleDataRef.data();
                        const variants = bundleData.includedVariants;
                        for (let k = 0; k < variants?.length; k++) {
                            const variant = variants[k];
                            const parentDataRef = await getDoc(variant.parentRef);
                            const parentData = parentDataRef.data();
                            let enhancedItem = {
                                orderedSku: variant.sku,
                                parentSku: parentData.sku,
                                slot: parentData.ibmi.slot,
                                name: parentData.name,
                                ibmiOnhand: parentData.ibmi.onhand,
                                subItem: parentData.ibmi.subItem || 'None',
                                bundleComponent: true,
                                image: parentData?.primaryImage || parentData?.files[0] || 'None',
                            }
                            // determine the quantity to collect
                            if (variant.productFactor < 1) {
                                // the variant ordered is a "break-box" and we need to add up to the right quantity
                                // we take the ceiling of the quantity ordered divided by the productFactor
                                // example: Variant X has a productFactor of 0.5 and the customer orders 1
                                // we need to collect 2 of Variant X to fulfill the order
                                enhancedItem.quantity = Math.ceil(item.quantity / variant.productFactor);
                            } else {
                                // if the product factor is 1 or greater, we just need to collect the quantity ordered multiplied by the productFactor
                                // example: Variant X has a productFactor of 1 and the customer orders 2
                                // we need to collect 2 of Variant X to fulfill the order
                                enhancedItem.quantity = item.quantity * variant.productFactor;
                            }
                            itemsWithDetails.push(enhancedItem);
                        }
                    }
                }
            }
            // sort itemsWithDetails by slot
            itemsWithDetails.sort((a, b) => (a.slot > b.slot) ? 1 : -1);

            // check for items that are out of stock
            const outOfStockItems = [];
            itemsWithDetails.forEach((item) => {
                if (item.quantity > item.ibmiOnhand) {
                    outOfStockItems.push(item);
                }
            });

            // check for items which are nearly out of stock
            const nearlyOutOfStockItems = [];
            itemsWithDetails.forEach((item) => {
                if (item.quantity > (item.ibmiOnhand / 2)) {
                    nearlyOutOfStockItems.push(item);
                }
            });

            // check for items which are low in stock
            const lowStockItems = [];
            itemsWithDetails.forEach((item) => {
                if (item.quantity > (item.ibmiOnhand / 4)) {
                    lowStockItems.push(item);
                }
            });

            const pickWizardJob = {
                batchId: batch.id,
                batchName: batch.batchName,
                assignedUser: batch.assignedUser,
                assignedUserUid: user.uid,
                assignedUserEmail: user.email || 'None',
                assignedUserPhone: user.phone || 'None',
                items: itemsWithDetails,
                outOfStockItems: outOfStockItems,
                nearlyOutOfStockItems: nearlyOutOfStockItems,
                lowStockItems: lowStockItems,
                timestamp: {
                    created: new Date(),
                    modified: new Date(),
                },
                status: "NOT STARTED",
            }


            // create a new pickWizardJob
            const pickWizardJobRef = await addDoc(collection(db, "pickWizardJobs"), pickWizardJob);
            const pickWizardJobId = pickWizardJobRef.id;
            const linkToPickWizard = `https://${baseUrl}/pick/${pickWizardJobId}`;

            const mail = {
                to: user.email,
                subject: 'Batch Ready To Pick',
                message: `Your batch ${batch.batchName} is ready to pick. Click the link below to begin picking:`,
                link: `${linkToPickWizard}`
            }
            await addDoc(collection(db, "outgoingMail"), {
                to: ['greg@meetmarty.com'],
                message: {
                    subject: mail.subject,
                    text: `${mail.message} \n\n\n ${mail.link}`,
                }
            });

            if (showToast) toast.success('Batch sent to pick', { theme: 'colored' });
            // only turn this off if showToast is true; showToast is set to true when it is a single batch being sent to pick
            // otherwise there are multiple batches being sent to pick which will have an inaccurate loading state if you
            // turn this false before they have a chance to finish
            if (showToast) setSendBatchToPickExecuting(false);
        } catch (error) {
            console.log(error);
            toast.error('Error sending batch to pick', { theme: 'colored' });
        }
    }

    return (
        enhancedBatches?.length > 0 
        ?
            <>
            <AdvanceTableWrapper
                columns={colDef}
                data={enhancedBatches}
                sortable
                pagination
                perPage={50}
                selection
                selectionColumnWidth={30}
            >
                <div className="mt-3">
                    <BulAction table/>
                </div>
                
                <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                    }}
                />
            </AdvanceTableWrapper>
            <AssignUserModal show={showAssignModal} setShow={setShowAssignModal} batch={selectedBatchForAssignment}/>
            </>
        :
        <div className="text-center">
            <h3>No Orders</h3>
        </div>
    )
}

const OpenBatches = ({ children }) => {

    // todo need to do batch data enhancement here instead of in the table component
    const { user, authLoading }= useContext(FirebaseAuthContext)
    const navigate = useNavigate();
    const [batchesRaw, batchesLoading, batchesError] = useCollection(
        query(
            collection(db, "customBatches"),
            where("status", "!=", "DELETED"),
            // orderBy("timestamp.created", "desc"),
        )
    );

    const [batches, setBatches] = useState([]);
    useEffect(() => {
        if (batchesLoading) return;
        if (batchesError) {
            console.log(batchesError);
            return;
        }
        if (!batchesRaw) return;
        const dataForState = [];
        batchesRaw.forEach((doc) => {
            let temp = doc.data();
            temp.id = doc.id;
            temp.ref = doc.ref;
            dataForState.push(temp)
        });
        setBatches(dataForState);
    }, [batchesRaw])

    // Redirect to home if not logged in
    useEffect(() => {   
        if (authLoading) return;
        if (!user) return navigate('/');
      }, [authLoading, user])

    return (
        !batchesLoading ?
        <>
            <Card>
                <Card.Body>
                    <BatchesTable 
                        batches={batches}
                        className="mt-2" 
                    />
                </Card.Body>
            </Card>
        </>
        :
        <Lottie animationData={dotsAnimation} style={{width: 100, height: 100}} />
    );
}

export default OpenBatches;
