import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import { db, fileStorage } from 'config/firebase.js'
import PageHeader from 'components/common/PageHeader';
import { Card, Row, Col, Button, Form, Tabs, Tab, Image, Nav, ProgressBar, Modal} from 'react-bootstrap';
import WizardInput from 'components/wizard/WizardInput';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WizardModal from 'components/wizard/WizardModal';
import IconButton from 'components/common/IconButton';
import { doc as firebaseDoc, getDoc } from "firebase/firestore";
import SoftBadge from 'components/common/SoftBadge';
import { useDropzone } from 'react-dropzone';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import CardDropdown from 'components/common/CardDropdown';
import Images from 'components/doc-components/Image';
import { getSize } from 'helpers/utils';
import { Dropdown } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import Success from 'components/wizard/NewProductSuccess';

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
    return (
      <Nav.Item>
        <Nav.Link
          className={classNames('fw-semi-bold', {
            done: step > index,
            active: step === index
          })}
          onClick={() => handleNavs(index)}
        >
          <Flex alignItems="center" justifyContent="center">
            <FontAwesomeIcon icon={icon} />
            <span className="d-none d-md-block mt-1 fs--1 ms-2">{label}</span>
          </Flex>
        </Nav.Link>
      </Nav.Item>
    );
  };

async function checkSku(sku) {
    try {
        if(sku) {
            const docRef = firebaseDoc(db, "products", String(sku));
            const doc = await getDoc(docRef);
            if (doc.exists()) return false; // SKU is already taken
            return true;
        } else return false
    } catch (error) {
        console.log(error)
        return false;
    }
    
}

const BasicProductDataForm = ({register, errors, watch}) => {

    const prodIdentOptions = ['ASIN', 'OTHER'];

    return (
        <>
            <WizardInput
                label="Product Name"
                disabled={false}
                name="name"
                errors={errors}
                formGroupProps={{ className: 'mb-3' }}
                formControlProps={{
                ...register('name', {
                    required: 'Product Name is required.',
                    minLength: {
                        value: 5,
                        message: 'Please select a longer product name'
                    }
                })
                }}
            />
            <WizardInput
                label="Core SKU Identifier"
                disabled={false}
                name="sku"
                errors={errors}
                formGroupProps={{ className: 'mb-3' }}
                formControlProps={{
                    ...register('sku', {
                        validate: {
                            checkUnique: async (v) => await checkSku(v) || 'SKU is already taken. Please seelect a different SKU.'
                            }
                        })
                }}

            />
            <Row className="g-2 mb-3">
                <Col>
                    <WizardInput
                        type="select"
                        label="Identifier Type"
                        disabled={false}
                        name="idType"
                        errors={errors}
                        formGroupProps={{ className: 'mb-3' }}
                        formControlProps={{
                        ...register('idType')
                        }}
                        placeholder={'UPC'}
                        options={prodIdentOptions}
                    />
                </Col>
                <Col>
                    <WizardInput
                        label="Identifier"
                        disabled={false}
                        name="productIdentifier"
                        errors={errors}
                        formGroupProps={{ className: 'mb-3' }}
                        formControlProps={{
                        ...register('productIdentifier')
                        }}
                    />
                </Col>
            </Row>
            <Row className="g-2 mb-3">
                <Col>
                    <WizardInput
                        label="Pack Information"
                        disabled={false}
                        name="name"
                        errors={errors}
                        formGroupProps={{ className: 'mb-3' }}
                        formControlProps={{
                        ...register('pack', {
                            required: 'Please enter Pack information.',
                        })
                        }}
                    />
                </Col>
                <Col>
                    <WizardInput
                        label="Size"
                        disabled={false}
                        name="size"
                        errors={errors}
                        formGroupProps={{ className: 'mb-3' }}
                        formControlProps={{
                        ...register('size', {
                            required: 'Please enter Size information.',
                        })
                        }}
                    />
                </Col>
            </Row>
            
            <WizardInput
                type='textarea'
                label="Description"
                disabled={false}
                name="description"
                errors={errors}
                formGroupProps={{ className: 'mb-3' }}
                formControlProps={{
                ...register('description')
                }}
            />
        </>
    )
}

const DefineInitialVariants = ({register, errors, watch, getValues, setPrimaryModal, primaryImgUrl}) => {

    const {name, sku, description, productIdentifier} = getValues();
    const variants = [];
    let img = '';
    if (primaryImgUrl) img = primaryImgUrl;
    variants.push({
        name: name,
        image: img,
        sku: sku,
        productFactor: 1,
        cost: '',
        core: true
    });

    const VariantHeader = () => {
        return (
            <Row className='border-bottom mb-3'>
                <Col lg="auto">
                    Status
                </Col>
                <Col lg={1}>
                    Image
                </Col>
                <Col>
                    Variant Name
                </Col>
                <Col lg={2}>
                    Product Factor
                </Col>
                <Col lg={2}>
                    Cost
                </Col>
                
            </Row>
        )
    }

    const VariantItem = ({variant}) => {
        return (
            <Row>
                <Col lg="auto">
                    {variant.core ?
                        <SoftBadge pill bg='primary' className='me-2'>Core</SoftBadge>
                    :   <SoftBadge pill bg='secondary' className='me-2'>Secondary</SoftBadge>
                    }
                </Col>
                <Col lg={1}>
                    {variant.image === '' ?
                        <>
                            <Button size='sm' variant='falcon-primary' onClick={() => setPrimaryModal(true)}>Choose</Button>
                        </>
                      : <>
                            <Image src={variant.image} width={'20vw'} />
                        </>
                    }
                </Col>
                <Col>
                    {variant.name}
                </Col>
                <Col lg={2}>
                    {variant.productFactor}
                </Col>    
                <Col lg={2}>
                    <WizardInput
                        label=""
                        disabled={false}
                        name="cost"
                        errors={errors}
                        formGroupProps={{ className: 'mb-3' }}
                        formControlProps={{
                        ...register('cost', {
                            required: 'Cost is required.',
                        })
                        }}
                    />
                </Col>  
                
            </Row>
        )
    }

    return (
        <>  
            <VariantHeader />
            {variants.map((v) => {
                return <VariantItem variant={v} key={v.name} />
            })}
        </>
        
    )
}

const PrimaryImageModal = ({modal, setModal, imageDataToWizard}) => {

    const [files, setFiles] = useState([]);
    const [downloadPercent, setPercent] = useState(0);
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            setFiles(
                acceptedFiles.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                )
            );
        }
    });

    const handleImageUpload = () => {
        const filename = files[0].name;
        // create a unique name and save with original file/filename suffix
        const storageRef = ref(fileStorage, `/images/products/${uuidv4()}.${filename[filename.length - 1]}`)
        const uploadTask = uploadBytesResumable(storageRef, files[0]);
        uploadTask.on("state_changed", 
            (snapshot) => {
                const percent = Math.round( (snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setPercent(`${Number(percent)}%`);
            }, 
            (err) => console.log(err), 
            (success) => {
                // download url, the success object isn't real
                getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                setPercent("Success")
                imageDataToWizard(url);
                setModal(false);
                });
            }
        );
    }

    const handleRemove = path => {
        setFiles(files.filter(file => file.path !== path));
    }

    return (

        <Modal
        show={modal}
        onHide={() => setModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Select Primary Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div {...getRootProps({ className: 'dropzone-area py-6'})}>
                <input {...getInputProps()} />
                <Flex justifyContent="center">
                    <img src={cloudUpload} alt="" width={25} className="me-2" />
                    <p className="fs-0 mb-0 text-700">Drop your images here</p>
                </Flex>
            </div>

            <div className="mt-3">
                {files.map(file => (
                    <Flex
                        alignItems="center"
                        className="py-3 border-bottom btm-reveal-trigger"
                        key={file.path}
                    >
                        <Image rounded width={40} height={40} src={file.preview} alt={file.path} />
                        <Flex justifyContent="between" alignItems="center" className="ms-3 flex-1">
                            <div>
                                <h6>{file.path}</h6>
                                <Flex className="position-relative" alignItems="center">
                                    <p className='mb-0 fs--1 text-400 line-height-1'>
                                        <strong>
                                            {getSize(file.size)}
                                        </strong>
                                    </p>
                                </Flex>
                            </div>
                        </Flex>
                        <CardDropdown>
                            <div className="py-2">
                                <Dropdown.Item className="text-danger" onClick={() => handleRemove(file.path)}>
                                    Remove
                                </Dropdown.Item>
                            </div>
                        </CardDropdown>
                    </Flex>
                ))}
            </div>

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModal(false)}>Close</Button>
          <Button onClick={() => handleImageUpload()}>Upload</Button>
          {downloadPercent}
        </Modal.Footer>
      </Modal>
    )
}

const PricingAndAvailability = ({register, errors}) => {
    return (
        <>
            <Row>
                <Col lg="auto">
                    <WizardInput
                        label="Minimum Margin"
                        type='checkbox'
                        name="minMargin"
                        errors={errors}
                        formGroupProps={{ className: 'mb-3' }}
                        formControlProps={{
                            ...register('minMargin')
                        }}
                    />
                </Col>
                <Col lg="auto">
                    <WizardInput
                        label="Minimum Percentage"
                        name="minMarginPercentage"
                        errors={errors}
                        formGroupProps={{ className: 'mb-3' }}
                        formControlProps={{
                            ...register('minMarginPercentage')
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <WizardInput
                        label="Sell on Marketplaces"
                        type="checkbox"
                        name="sellOnlineMarketplaces"
                        errors={errors}
                        formGroupProps={{ className: 'mb-3' }}
                        formControlProps={{
                        ...register('sellOnlineMarketplaces')
                        }}
                    />
                    <WizardInput
                        label="Sell on Web Channels"
                        type="checkbox"
                        name="sellOnlineWeb"
                        errors={errors}
                        formGroupProps={{ className: 'mb-3' }}
                        formControlProps={{
                        ...register('sellOnlineWeb')
                        }}
                    />
                    <WizardInput
                        label="Sell on Mobile Channels"
                        type="checkbox"
                        name="sellOnlineMobile"
                        errors={errors}
                        formGroupProps={{ className: 'mb-3' }}
                        formControlProps={{
                        ...register('sellOnlineMobile')
                        }}
                    />
                </Col>
                <Col>

                </Col>
            </Row>
            
        </>
    )
}

const NewProductWizard = ({validation = true}) => {
    
    const { user, authLoading }= useContext(FirebaseAuthContext)
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [modal, setModal] = useState(false);
    const [primaryModal, setPrimaryModal] = useState(false);
    const [primaryImgUrl, setPrimaryImgUrl] = useState();

    const imageDataToWizard = (url) => {
        setPrimaryImgUrl(url);
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
        reset,
        clearErrors,
        getValues
    } = useForm();
    
    useEffect(() => {
      if (authLoading) return;
      if (!user) return navigate('/');
    }, [authLoading, user])

    const onSubmitData = data => {
        // setUser({ ...user, ...data });
        setStep(step + 1);
    };

    const onError = () => {
        if (!validation) {
          clearErrors();
          setStep(step + 1);
        }
    };

    const handleNavs = targetStep => {
        if (step !== 4) {
          if (targetStep < step) {
            setStep(targetStep);
          } else {
            handleSubmit(onSubmitData, onError)();
          }
        } else {
          toggle();
        }
    };

    const toggle = () => setModal(!modal);

    const navItems = [
        {
          icon: 'lock',
          label: 'Basic Information'
        },
        {
          icon: 'user',
          label: 'Variant Definition'
        },
        {
          icon: 'dollar-sign',
          label: 'Pricing & Availability'
        },
        {
          icon: 'thumbs-up',
          label: 'Finalize'
        }
      ];

    return (
        <>
            <WizardModal modal={modal} setModal={setModal} />
            <PrimaryImageModal modal={primaryModal} setModal={setPrimaryModal} imageDataToWizard={imageDataToWizard} />
            <PageHeader
                title="New Product Wizard" titleTag="h5" className="mb-3">
            
            </PageHeader>
            <Card
                as={Form}
                noValidate
                onSubmit={handleSubmit(onSubmitData, onError)}
                className="theme-wizard mb-5"
            >
                <Card.Header
                className={'bg-light'}
                >
                    <Nav className="justify-content-center">
                    {navItems.map((item, index) => (
                        <NavItemPill
                        key={item.label}
                        index={index + 1}
                        step={step}
                        handleNavs={handleNavs}
                        icon={item.icon}
                        label={item.label}
                        />
                    
                    ))}
                    </Nav>
                </Card.Header>
                <ProgressBar now={step * 25} style={{ height: 2 }} />
                <Card.Body className="fw-normal px-md-6 py-4">
                    {step === 1 && (
                        <BasicProductDataForm register={register} errors={errors} watch={watch} />
                    )}
                    {step === 2 && (
                        <DefineInitialVariants register={register} errors={errors} watch={watch} getValues={getValues} setPrimaryModal={setPrimaryModal} primaryImgUrl={primaryImgUrl}/>
                    )}
                    {step === 3 && (
                        <PricingAndAvailability register={register} errors={errors} />
                    )}
                    {step === 4 && (
                        <Success reset={reset} setStep={setStep} getValues={getValues}
                        />
                    )}
                </Card.Body>
                <Card.Footer
          className={classNames('px-md-6 bg-light', {
            'd-none': step === 4,
            ' d-flex': step < 4
          })}
        >
          <IconButton
            variant="link"
            icon={'chevron-left'}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames('px-0 fw-semi-bold', {
              'd-none': step === 1
            })}
            onClick={() => {
              setStep(step - 1);
            }}
          >
            Prev
          </IconButton>

          <IconButton
            variant="primary"
            className="ms-auto px-5"
            type="submit"
            icon={'chevron-right'}
            iconAlign="right"
            transform="down-1 shrink-4"
          >
            Next
          </IconButton>
        </Card.Footer>
            </Card>
                
        </>
    )
}

export default NewProductWizard;



