import { useEffect, useState, useRef } from 'react';
import { realtime} from 'config/firebase';
import { useObjectVal } from 'react-firebase-hooks/database';
import { ref, update } from 'firebase/database';

const useRequestPubSub = () => {
    const [triggerRefetch, setTriggerRefetch] = useState(false);
    const [status, setStatus] = useState(null);
    const [userId, setUserId] = useState(null);
    const [userMsg, setUserMsg] = useState(null);
    const prevStatus = useRef();

    // create a message channel for the request
    const establishChannel = async (userId) => {
        if (!userId) return;
        const channelPath = `reqMessages/${userId}`;
        const channelRef = ref(realtime, channelPath);
        await update(channelRef, {
            userId,
            status: 'idle'
        });
        const newChannelId = channelRef.key;
        setUserId(userId);
        return newChannelId;
    }

    const updateStatus = async (userId, status) => {
        if (!userId) return;
        const channelPath = `reqMessages/${userId}`;
        await update(ref(realtime, channelPath), { status });
        setStatus(status); 
        prevStatus.current = status;
    }

    let channelPath;
    if (!userId) {
        channelPath = `reqMessages/default`;
    } else {
        channelPath = `reqMessages/${userId}`;
    }
    const [data, loading, error] = useObjectVal(ref(realtime, channelPath));

    useEffect(() => {
        if (loading || error) return;
        if (!data) return;
        if (data.status && data.status !== prevStatus.current) {
            updateStatus(userId, data.status);
            setUserMsg({ message: data.message, variant: data.variant });
            setTriggerRefetch(!triggerRefetch);
            return;
        }
    }, [data, loading, error]);

    return [status, userMsg, establishChannel, triggerRefetch];
}

export default useRequestPubSub;