import { useState } from 'react';
import { Modal, Row, Col, FormControl, Image, Button, InputGroup, Spinner } from 'react-bootstrap';
import MicroSearchBox from 'components/pages/newBatches/components/MicroSearchBox';
import { updateDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';

const AddItems = ({ show, setShow, data }) => {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [quantity, setQuantity] = useState(0);
    const [addingItems, setAddingItems] = useState(false);

    const handleAddItems = () => {
        setAddingItems(true);
        /**
        {
            "orderId": 694315638,
            "orderNumber": "200011582883309",
            "picture": "https://images.sellbrite.com/production/120550/478545/29e2ce64-7f47-54e9-9590-82e36d8c2c80.jpg",
            "quantity": 1,
            "sku": "478545",
            "title": "Airheads Candy Xtremes Belts Sour Candy, Rainbow Berry, Non Melting, Bulk Party Bag, 4.5 oz  bag"
        }
        */
       const newItem = {
              orderId: 'Manual',
              orderNumber: "Manual Order",
              picture: selectedProduct.image,
              quantity: quantity,
              sku: selectedProduct.sku,
              title: selectedProduct.name
       }
        const batches = data;
        const batchPromises = [];
        for (let i = 0; i < batches.length; i++) {
            const batch = batches[i];
            const items = batch.items;
            items.push(newItem);
            batch.items = items;
            batchPromises.push(updateBatch(batch));
        }
        Promise.all(batchPromises).then(() => {
            setAddingItems(false);
            setSelectedProduct(null);
            toast.success('Items added to batches');
        }).catch((error) => {
            console.log(error);
            setAddingItems(false);
        });
    }

    async function updateBatch(batch) {
        try {
            updateDoc(batch.ref, batch);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Modal show={show} onHide={() => setShow(!show)} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>Add Items</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!selectedProduct ? 
                    <MicroSearchBox setSelectedProduct={setSelectedProduct}/> : 
                    <>
                    <Row>
                        <Col sm={8}><Image src={selectedProduct.image} height={40}/> {selectedProduct.sku} {selectedProduct.name}</Col>
                        <Col sm={4}>
                            <InputGroup>
                                <FormControl type="number" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
                                <Button variant='primary' onClick={() => handleAddItems()} disabled={addingItems}>
                                    {addingItems ? <Spinner animation="border" size="sm" /> : 'Add'}
                                </Button>
                            </InputGroup>
                        </Col>
                            
                    </Row>
                    <Row>
                        <Col sm={12}>
                            
                        </Col>
                    </Row>
                    </>
                }
            </Modal.Body>
        </Modal>
    );
}

export default AddItems;

