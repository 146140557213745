import { useState } from 'react';

const Items = (props) => {
    const [showModal, setShowModal] = useState(false);
    const { value, data } = props;
    if (!value) return null;
    const items = value;
    let totalItemCount = 0;
    items.forEach(item => {
        totalItemCount += item.quantity;
    });

    const AddItem = (props) => {
        const { totalItemCount, data } = props;
        return (
            <div > 
                <span className='text-900 me-4'>{totalItemCount}</span>
            </div>
        )
      }
    return (
        <div>
            <AddItem totalItemCount={totalItemCount} data={data} />
        </div>
    )
}

export default Items;