import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import AppContext from 'context/Context';
import Footer from 'components/footer/Footer';
import { Row, Col } from 'react-bootstrap'

const DowntimeLayout = () => {
  // NOTE: MainLayout Structure changed for shorter version of Falcon react.
  // if you use kanban/ecommerce functionalities, plese check this file from full version of Falcon react.

  const { hash, pathname } = useLocation();

  const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, [hash]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      <div className="content">
        <Row className="flex-between-center mt-3 mb-3">
          <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <h5 className="mb-0">Merlin Scheduled Downtime</h5>
            <p></p>
            <span className='text-1100'>
              App functionalities will be unavailable during until 4/25/23 05:00 AM ET
            </span>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DowntimeLayout;
