export const shipStationStores = {
    "meta": {
        "format": "JSON",
        "version": "1.1.0",
        "projectId": "stk-merlin",
        "resourcePath": [
            "shipStationStores"
        ],
        "recursive": false,
        "creationTime": 1706644063,
        "app": "firefoo"
    },
    "data": {
        "330261": {
            "accountName": null,
            "active": true,
            "autoRefresh": false,
            "companyName": "CS Direct Supply",
            "createDate": "2020-06-29T20:43:02.98",
            "email": null,
            "integrationUrl": null,
            "lastRefreshAttempt": "2023-05-11T09:29:04.043",
            "marketplaceId": 0,
            "marketplaceName": "ShipStation",
            "modifyDate": "2021-05-18T08:21:39.287",
            "orderFilterable": false,
            "phone": "",
            "publicEmail": "",
            "refreshDate": "2023-05-11T09:28:13.007",
            "statusMappings": null,
            "storeId": 330261,
            "storeName": "Manual Orders",
            "website": "",
            "__collections__": {}
        },
        "346169": {
            "accountName": null,
            "active": true,
            "autoRefresh": false,
            "companyName": null,
            "createDate": "2020-07-10T08:55:38.25",
            "email": null,
            "integrationUrl": null,
            "lastRefreshAttempt": null,
            "marketplaceId": 117,
            "marketplaceName": "RateBrowser",
            "modifyDate": "2020-07-10T08:55:38.25",
            "orderFilterable": false,
            "phone": null,
            "publicEmail": null,
            "refreshDate": null,
            "statusMappings": null,
            "storeId": 346169,
            "storeName": "Rate Browser",
            "website": null,
            "__collections__": {}
        },
        "453798": {
            "accountName": null,
            "active": true,
            "autoRefresh": true,
            "companyName": "Shop The King",
            "createDate": "2021-05-18T08:18:20.32",
            "email": null,
            "integrationUrl": null,
            "lastRefreshAttempt": "2024-01-30T02:04:33.463",
            "marketplaceId": 140,
            "marketplaceName": "Alibaba",
            "modifyDate": "2023-10-31T09:31:37.523",
            "orderFilterable": false,
            "phone": "",
            "publicEmail": "csdirectsupply@gmail.com",
            "refreshDate": "2024-01-30T02:04:32.107",
            "statusMappings": null,
            "storeId": 453798,
            "storeName": "Alibaba Store",
            "website": "www.ShopTheKing.us",
            "__collections__": {}
        },
        "454628": {
            "accountName": "info@shoptheking.us",
            "active": true,
            "autoRefresh": true,
            "companyName": "Shop The King",
            "createDate": "2021-05-27T11:06:15.553",
            "email": null,
            "integrationUrl": null,
            "lastRefreshAttempt": "2024-01-30T02:04:32.847",
            "marketplaceId": 6,
            "marketplaceName": "PayPal",
            "modifyDate": "2022-04-13T10:51:40.79",
            "orderFilterable": false,
            "phone": "",
            "publicEmail": "",
            "refreshDate": "2024-01-30T02:04:32.07",
            "statusMappings": null,
            "storeId": 454628,
            "storeName": "(International Alibaba Orders)",
            "website": "",
            "__collections__": {}
        },
        "473569": {
            "accountName": "defe24e5-c0ac-4e6c-b4ef-629c0c0df140",
            "active": true,
            "autoRefresh": true,
            "companyName": "Shop The King",
            "createDate": "2022-01-14T11:49:12.52",
            "email": null,
            "integrationUrl": "https://marketplace.walmartapis.com",
            "lastRefreshAttempt": "2024-01-30T02:10:25.13",
            "marketplaceId": 93,
            "marketplaceName": "Walmart",
            "modifyDate": "2023-06-01T14:16:41.91",
            "orderFilterable": true,
            "phone": "",
            "publicEmail": "",
            "refreshDate": "2024-01-30T02:09:35.817",
            "statusMappings": null,
            "storeId": 473569,
            "storeName": "Walmart US",
            "website": "",
            "__collections__": {}
        },
        "473848": {
            "accountName": null,
            "active": true,
            "autoRefresh": true,
            "companyName": "Shop The King",
            "createDate": "2022-01-19T06:29:03",
            "email": null,
            "integrationUrl": null,
            "lastRefreshAttempt": "2024-01-30T02:05:54.077",
            "marketplaceId": 144,
            "marketplaceName": "eBay",
            "modifyDate": "2023-12-21T08:15:19.59",
            "orderFilterable": true,
            "phone": "",
            "publicEmail": "",
            "refreshDate": "2024-01-30T02:05:43.947",
            "statusMappings": null,
            "storeId": 473848,
            "storeName": "eBay",
            "website": "",
            "__collections__": {}
        },
        "473849": {
            "accountName": "ATVPDKIKX0DER",
            "active": true,
            "autoRefresh": true,
            "companyName": "Shop The King",
            "createDate": "2022-01-19T06:36:28.367",
            "email": null,
            "integrationUrl": null,
            "lastRefreshAttempt": "2024-01-30T02:07:06.363",
            "marketplaceId": 2,
            "marketplaceName": "Amazon",
            "modifyDate": "2023-06-01T14:17:51.87",
            "orderFilterable": true,
            "phone": "",
            "publicEmail": "",
            "refreshDate": "2024-01-30T02:06:46.643",
            "statusMappings": null,
            "storeId": 473849,
            "storeName": "Amazon US",
            "website": "www.ShopTheKing.us",
            "__collections__": {}
        },
        "474015": {
            "accountName": "concession-king.myshopify.com",
            "active": true,
            "autoRefresh": true,
            "companyName": "Shop The King",
            "createDate": "2022-01-20T14:04:35.017",
            "email": null,
            "integrationUrl": "concession-king.myshopify.com",
            "lastRefreshAttempt": "2024-01-30T02:04:52.16",
            "marketplaceId": 14,
            "marketplaceName": "Shopify",
            "modifyDate": "2023-08-29T12:19:29.463",
            "orderFilterable": true,
            "phone": "",
            "publicEmail": "",
            "refreshDate": "2024-01-30T02:04:49.063",
            "statusMappings": null,
            "storeId": 474015,
            "storeName": "Shop The King",
            "website": "shoptheking.us",
            "__collections__": {}
        },
        "496330": {
            "accountName": null,
            "active": true,
            "autoRefresh": true,
            "companyName": "The Calm Caterpillar ",
            "createDate": "2023-03-22T06:15:21.397",
            "email": null,
            "integrationUrl": null,
            "lastRefreshAttempt": "2024-01-30T02:05:36.317",
            "marketplaceId": 14,
            "marketplaceName": "Shopify",
            "modifyDate": "2023-06-26T12:45:21.237",
            "orderFilterable": true,
            "phone": "513-427-2437",
            "publicEmail": "contact@thecalmcaterpillar.com",
            "refreshDate": "2024-01-30T02:05:32.817",
            "statusMappings": null,
            "storeId": 496330,
            "storeName": "The Calm Caterpillar",
            "website": "www.thecalmcaterpillar.com",
            "__collections__": {}
        },
        "501026": {
            "accountName": null,
            "active": true,
            "autoRefresh": true,
            "companyName": "",
            "createDate": "2023-07-17T12:02:55.93",
            "email": null,
            "integrationUrl": null,
            "lastRefreshAttempt": "2024-01-30T02:05:35.477",
            "marketplaceId": 32,
            "marketplaceName": "Amazon CA",
            "modifyDate": "2023-07-17T12:05:39.477",
            "orderFilterable": true,
            "phone": "",
            "publicEmail": "",
            "refreshDate": "2024-01-30T02:05:32.83",
            "statusMappings": null,
            "storeId": 501026,
            "storeName": "Amazon CA",
            "website": "",
            "__collections__": {}
        },
        "501027": {
            "accountName": null,
            "active": true,
            "autoRefresh": false,
            "companyName": "",
            "createDate": "2023-07-17T12:04:15.73",
            "email": null,
            "integrationUrl": null,
            "lastRefreshAttempt": "2024-01-29T20:02:32.507",
            "marketplaceId": 87,
            "marketplaceName": "Amazon MX",
            "modifyDate": "2023-07-17T12:05:50.633",
            "orderFilterable": true,
            "phone": "",
            "publicEmail": "",
            "refreshDate": "2024-01-29T20:02:32.067",
            "statusMappings": null,
            "storeId": 501027,
            "storeName": "Amazon MX",
            "website": "",
            "__collections__": {}
        }
    }
}