import React from 'react';
import {Container, Card, Row, Col, Image} from 'react-bootstrap';
import MerlinLogo from '../assets/merlin/merlin.png';


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return ErrorPage(state);
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

const ErrorPage = (props) => {
    console.log(props)
    return (
        <Container className=''>
            <Card
                className='my-5 mx-auto'
            >
                <Row>
                    <Col xs='auto'>
                        <Image
                          src={MerlinLogo}
                          width={150}
                        />
                    </Col>
                    <Col>
                        <h1 className=''>Oops!</h1>
                        <h3 className=''>Something went wrong.</h3>
                        <p className=''>Please try again later.</p>
                    </Col>
                </Row>
            </Card>
        </Container>
    )
}