import React, { useState, useEffect, useRef } from 'react';
import { db } from 'config/firebase';
import { collection, query, getDocs, where, addDoc, updateDoc} from 'firebase/firestore';
import { Row, Col, Button, Modal, InputGroup, FormControl, Form, Spinner} from 'react-bootstrap';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/merlin/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/merlin/advance-table/AdvanceTableFooter';
import { toast } from 'react-toastify';

const CreateNewBatchModal = ({ show, handleClose, uid }) => {

    const [loading, setLoading] = useState(true);
    const [shipStationBatches, setShipStationBatches] = useState([]);
    
    useEffect(() => {
        // this loads the most recent batches configured in ShipStation
        setLoading(true);
        async function getBatchShipstationBatches() {
            // extract from processedOrders all of the unique batch numbers
            const oneWeekAgo = new Date();
            oneWeekAgo.setDate(oneWeekAgo.getDate() - 3);
            const querySnapshot = await getDocs(
                query(
                    collection(db, 'processedOrders'),
                    where('modifyDate', '>', oneWeekAgo)
                )
            );
            const batchNumbers = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data().batchName;
                if (data) {
                    batchNumbers.push(data);
                }
            });
            // return only the unique batch numbers
            // reverse the array so the most recent batches are at the top  
            const uniqueBatchNumbers = [...new Set(batchNumbers)];
            uniqueBatchNumbers.reverse();
            const returnObject = [];
            uniqueBatchNumbers.forEach((batch) => {
                returnObject.push({batchName: batch});
            });
            return returnObject;
            // return [...new Set(batchNumbers)];
        }
        getBatchShipstationBatches().then((batches) => {
            setShipStationBatches(batches);
            setLoading(false);
        }).catch((error) => {
            console.log(error)
        })
    }, []);

    return (
        <>
          <Modal
            show={show}
            onHide={() => handleClose()}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Row>
                <Col>
                  <Modal.Title id="contained-modal-title-vcenter">Create New Batch</Modal.Title>
                </Col>
              </Row>
              <Row>
                <Col>
                <div className='fs-1 mb-2'>
                    
                </div>
                </Col>
              </Row>
            </Modal.Header>
            <Modal.Body>
                <div>
                {
                    loading ? 
                    <div>Loading ShipStation Batches...</div> :
                    shipStationBatches && shipStationBatches.length > 0 ? 
                        <ShipStationBatchNameTable data={shipStationBatches} uid={uid}/>
                    :
                    <div>No ShipStation batches found</div>
                }
                </div>
                
            </Modal.Body>
            <Modal.Footer className=''>
              <Button variant="secondary" onClick={() => handleClose()}>Close</Button>
            </Modal.Footer>
          </Modal>
        </>
      );
}

export default CreateNewBatchModal;

const ShipStationBatchNameTable = ({data, uid}) => {
  const [selectedBatch, setSelectedBatch] = useState(null);
  const manualBatchNameRef = useRef(null);
  const [batchType, setBatchType] = useState(null);
  const [creatingBatch, setCreatingBatch] = useState(false);
  const [tableData, setTableData] = useState([]);
  
  useEffect(() => {
    async function checkIfBatchExists(batchName) {
      const querySnapshot = await getDocs(
        query(
          collection(db, 'batches'),
          where('orderBatchName', '==', batchName)
        )
      );

      return { exists: querySnapshot.size > 0, batchName };
    }
    const batchPromises = [];
    for (let i = 0; i < data.length; i++) {
      const batch = data[i];
      batchPromises.push(checkIfBatchExists(batch.batchName));
    }
    Promise.all(batchPromises).then((results) => {
      console.log(results)
      const filteredData = results.filter((result) => {
        return !result.exists;
      });
      setTableData(filteredData);
    });

  }, [data]);


  function createFromShipStation(value) {
    setSelectedBatch(value);
  }

  function createManualBatch() {
    const batchName = manualBatchNameRef.current.value;
    setSelectedBatch(batchName);
  }

  async function getOrdersWihBatchName(batchName) {
    const querySnapshot = await getDocs(
      query(
        collection(db, 'processedOrders'),
        where('batchName', '==', batchName)
      )
    );
    const orders = [];
    querySnapshot.forEach((doc) => {
      let temp = doc.data();
      temp['docId'] = doc.id;
      temp['ref'] = doc.ref;
      orders.push(temp);
    });
    return orders;
  }

  async function addOrderToBatch(order, batchName) {
    const orderRef = order.ref;
    const orderData = order;
    orderData.assignedToBatch = true;
    orderData.merlinBatchName = batchName;
    await updateDoc(orderRef, orderData);
    const items = orderData.items;
    
    const finalItems = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      console.log(item)
      finalItems.push({
        picture: item.imageUrl || '',
        sku: item.sku,
        title: item.name,
        quantity: item.quantity,
        orderNumber: orderData.orderNumber,
        orderId: orderData.orderId,
      });
    }
    return finalItems;
  }

  async function finalizeBatch() {
    if (!selectedBatch) {
      toast.error('No batch selected');
      return;
    }
    if (!batchType) {
      toast.error('No batch type selected');
      return;
    }
    setCreatingBatch(true);
    const rightNow = new Date();
    const newBatch = {
      items: [],
      orderBatchName: selectedBatch,
      status: 'Initialized',
      timestamp: rightNow,
      uid: uid
    }
    const orders = await getOrdersWihBatchName(selectedBatch);
    newBatch.numOrders = 0;
    if (orders.length > 0) {
      // assignedToBatch to true
      // merlinBatchName to selectedBatch
      const orderUpdatePromises = [];
      for (let i = 0; i < orders.length; i++) {
        const order = orders[i];
        orderUpdatePromises.push(addOrderToBatch(order, selectedBatch));
      }
      const updatedItems = await Promise.all(orderUpdatePromises);
      // flatten the array
      newBatch.items = updatedItems.flat();
      newBatch.status = "Ready";
      newBatch.numOrders = orders.length;
    }
    if (batchType === 'teamPick') {
      newBatch.doNotPick = false;
    } else {
      newBatch.doNotPick = true;
    }
    const docRef = await addDoc(collection(db, 'batches'), newBatch);
    toast.success('Batch created successfully');
    setSelectedBatch(null);
    setCreatingBatch(false);
  }

  const handleTypeChange = (e) => {
    setBatchType(e.target.value);
  }

  const SelectedBatch = ({ selectedBatch }) => {
    return (
      <div>
        <Row className='fs-1 mb-2'>
          <Col className='' lg={8}>
            Selected Batch: {selectedBatch}
          </Col>
          <Col className='float-end' lg={4}>
            <Button variant="secondary" onClick={() => setSelectedBatch(null)}>X</Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Check
              inline
              label="Self Pick"
              name="group1"
              type="radio"
              id={`inline--2`}
              value="selfPick"
              onChange={handleTypeChange}
            />
            <Form.Check
              inline
              label="Team Pick"
              type="radio"
              id={`inline--3`}
              value="teamPick"
              onChange={handleTypeChange}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <Button variant="success" disabled={creatingBatch} onClick={() => finalizeBatch()}>
                {
                  creatingBatch ?
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                  :
                  <span>
                    Create Batch
                  </span>
                }
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  const ManualBatchCreationForm = () => {
      return (
          <>
              <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">Batch Name</InputGroup.Text>
                  <FormControl
                      placeholder="Enter name for batch"
                      aria-label="Batch Name"
                      aria-describedby="basic-addon1"
                      ref={manualBatchNameRef}
                      onSubmit={() => createManualBatch()}
                  />
                  <Button variant="success" id="button-addon2" onClick={() => createManualBatch()}>
                      Create
                  </Button>
              </InputGroup>
          </>
      )
  }
  
  const columns = [
    {
      accessor: 'batchName',
      Header: 'Name',
      headerProps: { className: 'text-900' },
      Cell: (props) => {
        return (
          <div onClick={() => createFromShipStation(props.value)}>
            {props.value}
          </div>
        )
      }
    },
  ];
  
  return (
    <>
      {
        selectedBatch ? 
        <div>
          <SelectedBatch selectedBatch={selectedBatch} />
        </div>
        :
        <div>
          <AdvanceTableWrapper
            columns={columns}
            data={tableData}
            sortable
            pagination
            perPage={5}
          >
            <AdvanceTableSearchBox table/>
            <AdvanceTable
              table
              headerClassName="bg-200 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs-10 mb-0 overflow-hidden'
              }}
            />
            <div className="mt-3">
              <AdvanceTableFooter
                rowCount={data.length}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
          <div className='mt-3'>
            <ManualBatchCreationForm />          
          </div>
        </div>
      }

    </>
  )
}



