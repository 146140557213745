import { db } from 'config/firebase.js';
import { useEffect, useState } from 'react';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';

function useFuzzyProducts() {
    
    // check if a local item is there

    // if not get fresh

    // if so check if it's stale

    // if stale get fresh

    // if not stale use local

    const [fuzzyProducts, setFuzzyProducts] = useState([]);
    const [fuzzyLoading, setFuzzyLoading] = useState(true);
    const [fuzzyError, setFuzzyError] = useState(false);
    const fuzzyLocal = localStorage.getItem('fuzzyProducts');
    const fuzzyExpiry = localStorage.getItem('fuzzyExpiration');
    const rightNow = Date.now();

    useEffect(() => {
            
        const populateFuzzyProducts = async () => {
            try {

                const fuzzQuery = query(collection(db, "fuzzySearch", "topicz", "fuzzyChoices"));
                const fuzzySnapshot = await getDocs(fuzzQuery);
                const fuzzyProducts = [];
                fuzzySnapshot.forEach((doc) => {
                    fuzzyProducts.push({...doc.data()})
                })
                setFuzzyProducts(fuzzyProducts);
                localStorage.setItem('fuzzyProducts', JSON.stringify(fuzzyProducts));
                localStorage.setItem('fuzzyExpiration', Date.now() + 86400000);
                setFuzzyLoading(false);
            } catch (error) {
                console.log(error);
                setFuzzyError(true);
            }
        }
        
        if (!fuzzyLocal || !fuzzyExpiry || rightNow > fuzzyExpiry) {
            try {
                populateFuzzyProducts();
                setFuzzyLoading(false);
            } catch (error) {
                console.log(error);
                setFuzzyError(error);
            }
        } else {
            setFuzzyProducts(JSON.parse(fuzzyLocal));
            setFuzzyLoading(false);
        }
        
    }, []);

    return [fuzzyProducts, fuzzyLoading, fuzzyError];

}

export default useFuzzyProducts;