import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { auth, db } from 'config/firebase';
import {
  where,
  query,
  addDoc,
  collection,
  getDocs,
  doc,
  limit,
  setDoc,
} from 'firebase/firestore';
import {
  Spinner,
  Card,
  ButtonToolbar,
  ButtonGroup,
  InputGroup,
  FormControl,
  Row,
  Col,
  Button,
  Form,
  Modal } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import { toast } from 'react-toastify';
import AutomaticHistory from './AutomaticHistory';
import NewSlot from './newSlot';
import ManualSlots from './ManualSlots';
import PrintLabels from 'components/inventoryPages/PrintLabels';

const LocationsDetail = () => {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const params = useParams();
  const [firebaseLoading, setFirebaseLoading] = useState(true);
  const [locationId, setLocationId] = useState(params.locationId);
  const [locationData, docLoading, error, snapshot] = useDocumentData(doc(db, "locations", locationId), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
  const [disabled, setDisabled] = useState(false)
  
  function checkIfAutomatic(type) {
    if (type === 'automatic') return true;
    return false;
  }  

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate('/');
    
    if (!docLoading) setDisabled(checkIfAutomatic(locationData.type))
    setFirebaseLoading(false);
  }, [user, loading, navigate, docLoading]);

  //console.dir(currentProducts);
  return (
    <>
      {!firebaseLoading && !docLoading ?  (
        <PageHeader
          title={locationData.name} titleTag="h5" className="mb-3">
          <div className='mt-4'>
          {locationData.notes}
          </div>
          <div className='mt-4'>
            <ButtonToolbar
              className="justify-content-between"
              aria-label="Toolbar with Button groups"
            >
            <div>
              <NewSlot disabled={disabled} location={locationData} />
            </div>
            <div>
              <PrintLabels disabled={disabled} location={locationData} />
            </div>
            <div>
              <Button onClick={() => navigate('/inventory/scanner')}>Launch Scanner</Button>
            </div>
      
            </ButtonToolbar>
          </div>
          <div className='mt-4'>
          {disabled ? <span>Please contact your system administrator to modify this location</span> : null}
          </div>
          
      
        </PageHeader>
      ) : null }
    
    
        {!firebaseLoading && !docLoading ?  (
          <Card className="">

            {disabled ? <AutomaticHistory /> : <ManualSlots locationId={locationId} />}
          
          </Card>
        ) : 
          <Row className="align-items-center">

            <Col>
              <Spinner animation="border" variant="primary">
                <span className='visually-hidden'>Loading...</span>
              </Spinner>
            </Col>
          
          </Row>
          }
      
    </>
  );
};

function NewLocation() {
  const [modalShow, setModalShow] = useState(false);

  async function createNewLocation(e) {
    e.preventDefault();
    const formData = new FormData(document.getElementById("addressForm"));
    const formDataObj = Object.fromEntries(formData.entries());
    console.log(formDataObj)
    const address = {
      city: formDataObj.city || 'Default',
      street1: formDataObj.street1 || 'Default',
      street2: formDataObj.street2 || 'Default',
      state: formDataObj.state || 'Default',
      country: formDataObj.country || 'Default',
      postalCode:formDataObj.postalCode || 'Default',
    }
    const docRef = await addDoc(collection(db, "locations"), {
      active: true,
      address: address,
      name: formDataObj.locationName  || 'Default Name',
      notes: formDataObj.locationNotes  || 'Default Notes',
      type: formDataObj.locationType || 'manual'
    });
    
    if (docRef.id) {
      setModalShow(false);
      setDoc(docRef, { id: docRef.id }, { merge: true });
      toast.success(`New Location Added`, {
        theme: 'colored'
      });
    }
  }

  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Add New Location
      </Button>


      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
      
      
      <Form onSubmit={createNewLocation} id="addressForm">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Add Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row className="align-items-center g-3">
              <Col>
                  <Form.Group className="mb-3" controlId="formLocationType">
                    <Form.Label>Choose Location Type</Form.Label>
                    <Form.Select aria-label="Choose location type, manual or automatic" name="locationType">
                      <option value="manual">Manual</option>
                      <option value="automatic">Automatic</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formLocationName">
                    <Form.Label>Enter Location Name</Form.Label>
                    <Form.Control type="text" placeholder="Location Name" name="locationName"/>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formLocationAddress">
                    <Form.Label>Address</Form.Label>
                    <Form.Control type="text" placeholder="Street " name="street1"/>
                    <Form.Control type="text" placeholder="" name="street2" />
                    <Form.Control type="text" placeholder="City" name="city" />
                    <Form.Control type="text" placeholder="State"  name="state"/>
                    <Form.Control type="text" placeholder="Postal Code" name="postalCode" />
                    <Form.Control type="text" placeholder="Country" name="country" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formLocationNotes">
                    <Form.Label>Notes</Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder="Notes" name="locationNotes"/>
                  </Form.Group>
              </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalShow(false)}>Close</Button>
          <Button variant="success" type="submit">Create</Button>
        </Modal.Footer>
        
        </Form>
      </Modal>
    </>
  );
}

export default LocationsDetail;
