import { Modal, Button, Spinner, ListGroup, Badge } from "react-bootstrap";
import { db } from "config/firebase";
import { useEffect, useState } from "react";
import { getDocs, collection, where, updateDoc, query } from "firebase/firestore";
import Flex from "components/common/Flex";
import { toast } from 'react-toastify';

const AddToBatch = ({ show, handleClose, data}) => {

    const [openBatches, setOpenBatches] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedBatch, setSelectedBatch] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const querySnapshot = await getDocs(collection(db, 'batches'), where("status", "==", "Initialized"));
                let batchData = [];
                querySnapshot.forEach((doc) => {
                    batchData.push({ ...doc.data(), id: doc.id, ref: doc.ref});
                });
                setOpenBatches(batchData);
                setLoading(false);
            } catch (e) {
                console.error(e);
                setError(e);
                setLoading(false);
            }
        }
        fetchData();
    }, []);

    async function addOrderToBatch(order, batchName) {
        if (!order) {
            toast.error('No order provided');
        }
        if (order.assignedToBatch) {
            return {error: true, message: 'Order already assigned to a batch'};
        }
        const orderRef = order.ref;
        const orderData = order;
        orderData.assignedToBatch = true;
        orderData.merlinBatchName = batchName;
        await updateDoc(orderRef, orderData);
        const items = orderData.items;
        const finalItems = [];
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          finalItems.push({
            picture: item.imageUrl || '',
            sku: item.sku,
            title: item.name,
            quantity: item.quantity,
            orderNumber: orderData.orderNumber,
            orderId: orderData.orderId,
          });
        }
        return finalItems;
    }

    async function handleAddToBatchClick() {

        const addResults = [];
        for (let i = 0; i < data.length; i++) {
            const order = data[i];
            addResults.push(addOrderToBatch(order, selectedBatch));
        }
        const finalResults = await Promise.all(addResults);
        const items = finalResults.flat();
        const errorCheck = items.filter(item => item.error);
        if (errorCheck.length > 0) {
            for (let i = 0; i < errorCheck.length; i++) {
                toast.error(`Error adding order to batch: ${errorCheck[i].message}`, { colored: true });
            }
            return;
        }
        console.log("selectedBatch", selectedBatch)
        const batchQuerySnapshot = await getDocs(
            query (
                collection(db, 'batches'), 
                where("orderBatchName", "==", selectedBatch)
            )
        );
        let batchData = [];
        batchQuerySnapshot.forEach((doc) => {
            batchData.push({ ...doc.data(), id: doc.id, ref: doc.ref});
        });
        console.log(batchData)
        const batch = batchData[0];
        batch.numOrders += data.length;
        batch.items = items;
        await updateDoc(batch.ref, batch);
        toast.success(`Added ${data.length} orders to ${selectedBatch}`);
        handleClose();
    }

    const BatchListGroupItem = ({ batch }) => {
        return (
            <ListGroup.Item onClick={() => setSelectedBatch(batch.orderBatchName)}>
                    <Flex justifyContent="between" alignItems="center">
                    {batch.orderBatchName}
                    <Badge pill bg='primary' className='me-2'>{batch.numOrders}</Badge>
                    </Flex>
            </ListGroup.Item>
        )
    }

    const BatchListGroup = ({ batches }) => {
        return (
            <ListGroup>
                {batches.map((batch, index) => {
                    return <BatchListGroupItem key={index} batch={batch} />
                })}
            </ListGroup>
        )
    }


    return (
        <Modal show={show} onHide={handleClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>Add to Batch</Modal.Title>
            </Modal.Header>
            <Modal.Body>


                {error && <p>Error: {error.message}</p>}
                {loading ? <Spinner animation='border' />
                :
                    data.length > 0 ?
                    <div>
                        <p>Select a batch to add the following {data.length} orders to:</p>
                        <BatchListGroup batches={openBatches} />
                    </div>
                    :
                    <p>No orders to add to a batch</p>
                }
                
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={handleClose}>Close</Button>
                <Button variant='primary' disabled={selectedBatch === null} onClick={() => handleAddToBatchClick()}>Add to {selectedBatch ? selectedBatch : 'Batch'}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddToBatch;