import React, { createContext, useState, useContext } from 'react';

const SkuListContext = createContext();

export const useSkuList = () => useContext(SkuListContext);


export const SkuListProvider = ({ children }) => {
    const [skuList, setSkuList] = useState([]);

    return (
        <SkuListContext.Provider value={{ skuList, setSkuList }}>
            {children}
        </SkuListContext.Provider>
    )
}