import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from 'config/firebase';
import {
  query,
  collection,
  where,
  getDocs
} from 'firebase/firestore';
import {
  Spinner,
  Card,
  Row,
  Col,
  Button
} from 'react-bootstrap';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import PageHeader from 'components/common/PageHeader';
import SoftBadge from 'components/common/SoftBadge';
import { toast } from 'react-toastify';
import { useCollection } from 'react-firebase-hooks/firestore';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import axios from 'axios';

const RoundtableMgmt = () => {
  const { user, authLoading }= useContext(FirebaseAuthContext)
  const navigate = useNavigate();
  
  /** Collect and process Roundtable collection data */
  const [roundtableRaw, rtLoading, rtError] = useCollection(query(collection(db, 'roundtableSubmissions')));
  const [roundtable, setRoundtable] = useState([]);
  useEffect(() => {
    let isSubscribed = true;
    if (rtLoading) return;
    if (rtError) {
      console.log("RoundtableCollectionRetrievalError", rtError)
      return;
    }
    if (!roundtableRaw) return;
    let roundtableArray = [];
    roundtableRaw.forEach((doc) => {
      let temp = doc.data();
      temp.roundtableId = doc.id;
      temp.docRef = doc.ref;
      roundtableArray.push(temp)
    })
    roundtableArray = roundtableArray.sort((a, b) => {
      if (a.lastName < b.lastName) return -1;
      if (a.lastName > b.lastName) return 1;
      return 0;
    })
    return enrichWithLifetimeValue(roundtableArray).then((enrichedRoundtableArray) => {
      if (isSubscribed) setRoundtable(() => enrichedRoundtableArray)
      return () => isSubscribed = false;
    })
  }, [roundtableRaw, rtLoading, rtError])

  async function getLifetimeValue(forEmailAddress) {
    const q = query(
      collection(db, 'orders'), 
      where("advancedOptions.storeId", "==", 474015),
      where('customerEmail', '==', forEmailAddress)
    );
    try {
      const ordersnapshot = await getDocs(q);
      if (!ordersnapshot.empty) {
        let lifetimeValue = 0;
        ordersnapshot.forEach((doc) => {
          let order = doc.data();
          lifetimeValue += order.amountPaid;
        })
        return lifetimeValue;
      } else {
        return 0;
      }
    } catch (error) {
      console.log("Error getting lifetime value", error)
    }
  }

  async function enrichWithLifetimeValue(roundtableArray) {
    let enrichedRoundtableArray = [];
    for (let i = 0; i < roundtableArray.length; i++) {
      let roundtable = roundtableArray[i];
      let lifetimeValue = await getLifetimeValue(roundtable.email);
      roundtable.lifetimeValue = lifetimeValue;
      enrichedRoundtableArray.push(roundtable);
    }
    return enrichedRoundtableArray;
  }

  const columns = [
    {
      accessor: 'lastName',
      Header: 'Name',
      Cell: rowData => {
        const { firstName, lastName, email  } = rowData.row.original;
        
        return (
          <div>
            <a href={`mailto:${email}`}>{firstName}{" "}{lastName}</a>
          </div>
        )
      }
    },
    {
      accessor: 'appStatus',
      Header: 'Membership Details',
      Cell: rowData => {
        const { appStatus, b2bLevel } = rowData.row.original;
        const generateBadge = (level) => {
          switch (level) {
            case 'Gold':
              return <SoftBadge bg="warning">Gold</SoftBadge>
            case 'Silver':
              return <SoftBadge bg="secondary">Silver</SoftBadge>
            case 'Bronze':
              return <SoftBadge bg="dark">Bronze</SoftBadge>
            default:
              return <SoftBadge bg="danger">None</SoftBadge>
          }
        }

        return (
          <>

          <div className='text-capitalize'>
            <SoftBadge
              bg={appStatus === 'pending' ? 'warning' : appStatus === 'approved' ? 'success' : 'danger'}
            >{appStatus}</SoftBadge>
          </div> 

          <div className='text-capitalize'>
            {generateBadge(b2bLevel)}
          </div> 

          </>
        );
      }
    },
    {
      accessor: 'b2bLevel',
      Header: 'Company Name',
      Cell: rowData => {
        let { companyName } = rowData.row.original;
        if (!companyName) companyName = 'N/A';
        if (companyName.length > 20) {
          companyName = companyName.substring(0, 20) + '...';
        }


        return (
          <div className='text-capitalize'>
            {companyName}
          </div>
        );
      }
    },
    {
      accessor: 'lifetimeValue',
      Header: 'Lifetime Value',
      Cell: rowData => {
        let { lifetimeValue } = rowData.row.original;
        return (
          <div className='text-capitalize'>
            ${lifetimeValue}
          </div>
        );
      }
    },
    {
      accessor: '',
      Header: 'Location',
      Cell: rowData => {
        console.log(rowData.row.original)
        let { lifetimeValue } = rowData.row.original;
        return (
          <div className='text-capitalize'>
            ${lifetimeValue}
          </div>
        );
      }
    },
    {
      accessor: 'category',
      Header: 'Actions',
      Cell: rowData => {

        return (
          <div>
            <Button variant="danger" size="sm" >
              Suspend
            </Button>
            <Button variant="primary" size="sm" >
              Approve
            </Button>
          </div> 
        );
      }
    },

  ];

  useEffect(() => {
    if (authLoading) return;
    if (!user) return navigate('/');    
  }, [user, authLoading, navigate]);


  return (
    <>
    <PageHeader

    title="Roundtable Management" titleTag="h5" className="mb-3">

    </PageHeader>
    
    
        {!rtLoading? (
          <Card className="p-3">
          {!rtLoading && roundtable.length !== 0 ? (
              <AdvanceTableWrapper
              columns={columns}
              data={roundtable}
              sortable
              pagination
              perPage={10}
            >
              <Row className="flex-end-center mb-3">
                <Col xs="auto" sm={6} lg={4}>
                  <AdvanceTableSearchBox table/>
                </Col>
              </Row>
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
              <div className="mt-3">
                <AdvanceTableFooter
                  rowCount={roundtable.length}
                  table
                  rowInfo
                  navButtons
                  rowsPerPageSelection
                />
              </div>
            </AdvanceTableWrapper>
          ) : (
            <Row className="align-items-center">
              <Col>
                <Spinner animation="border" variant="primary">
                  <span className='visually-hidden'>Loading...</span>
                </Spinner>
              </Col>
            </Row>
          )
          }
          </Card>
        ) : 
          <Row className="align-items-center">

            <Col>
              <Spinner animation="border" variant="primary">
                <span className='visually-hidden'>Loading...</span>
              </Spinner>
            </Col>
          
          </Row>
          }
      
    </>
  );
};

export default RoundtableMgmt;
