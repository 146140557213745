import { useContext, useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { db } from 'config/firebase';
import { collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import { useNavigate } from 'react-router-dom';

/** Todo - need to filter by timestamp */
function KeyActionsPanel() {

    const { user, authLoading }= useContext(FirebaseAuthContext)
    const [actionAlerts, setActionAlerts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (authLoading) return;
        getKeyActions(user.uid).then((keyActions) => {
            setActionAlerts(keyActions);
        });

    }, [authLoading])

    async function getKeyActions(uid) {
        const q = query(
            collection(db, "keyActionsPanel"), 
            where("uid", "==", uid)
        );
        const querySnapshot = await getDocs(q);
        let keyActions = [];
        querySnapshot.forEach((doc) => {
            let temp = doc.data();
            temp.ref = doc.ref;
            keyActions.push(temp);
        });
        return keyActions;
    }

    async function updateKeyActionState(ref, state) {
        if (!state) state = false;
        try {
            await updateDoc(ref, {
                actioned: state
            });        
        } catch (error) {
            console.log(error)
        }
    }

    async function handleActionClick(ref, actioned, actionUrl) {
        await updateKeyActionState(ref, !actioned);
        navigate(actionUrl);
    }

    function AlertDismissible({ index, alert }) {
        const [show, setShow] = useState(true);
        return (
          <div className='justify-content-start'>
            <Alert show={show} variant={alert.color} key={index}>
              <Alert.Heading>{alert.heading}</Alert.Heading>
              <p>
                {alert.body}
              </p>
              <hr />
              <div className="d-flex justify-content-end">
                <Button onClick={() => setShow(false)} variant="falcon-danger" className="me-2 bg-light">
                  Dismiss
                </Button>
                <Button 
                    onClick={() => {!alert.actioned ? handleActionClick(alert.ref, alert.actioned, alert.actionUrl) : handleActionClick(alert.ref, !alert.actioned, alert.actionUrl)}}
                    variant={`falcon-${alert.color}`}
                >
                  {alert.actionText}
                </Button>
              </div>
            </Alert>
          </div>
        );
      }

    const ActionAlert = ({alerts}) => {

        const previousAlerts = alerts.filter((alert) => alert.actioned === true);
        const currentAlerts = alerts.filter((alert) => alert.actioned === false);
        const [viewPrevious, setViewPrevious] = useState(false);

        return (
            <>
                {currentAlerts.map((alert, index) => {
                    return (
                        <AlertDismissible key={index} alert={alert}/>
                    )
                })}
                {previousAlerts.length > 0 && viewPrevious ?
                    previousAlerts.map((alert, index) => {
                        return (
                            <AlertDismissible key={index} alert={alert}/>
                        )
                    })
                : null
                }
                <div className='float-end'>
                    {!viewPrevious ?
                        previousAlerts.length > 0 ?
                            <Button 
                                size='sm' 
                                variant={'falcon-secondary'} 
                                className='text-500' 
                                onClick={(e) => {e.preventDefault(); setViewPrevious(true)}}
                            >
                                View Previous Alerts
                            </Button>
                        : null
                     :
                        <Button 
                            size='sm' 
                            variant={'falcon-secondary'} 
                            className='text-500' 
                            onClick={(e) => {e.preventDefault(); setViewPrevious(false)}}
                        >
                            Hide Previous Alerts
                        </Button>    
                    }
                </div>
                
            </>

        )
    }

    return (
        <>
            {actionAlerts.length > 0 ? <ActionAlert alerts={actionAlerts}/> : null }
        </>
    )
}

export default KeyActionsPanel;