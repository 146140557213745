import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from 'config/firebase';
import {
  query,
  collection,
  where,
  getDocs
} from 'firebase/firestore';
import {
  Spinner,
  Card,
  Row,
  Col,
  Button
} from 'react-bootstrap';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import PageHeader from 'components/common/PageHeader';
import SoftBadge from 'components/common/SoftBadge';
import { toast } from 'react-toastify';
import { useCollection } from 'react-firebase-hooks/firestore';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import axios from 'axios';

const PostcardManagement = () => {
  const { user, authLoading }= useContext(FirebaseAuthContext)
  const navigate = useNavigate();
  useEffect(() => {
    if (authLoading) return;
    if (!user) return navigate('/');    
  }, [user, authLoading, navigate]);
  
  /** Collect and process Roundtable collection data */
  const [postcardsRaw, postcardsLoading, postcardsError] = useCollection(query(collection(db, 'postcardCampaigns')));
  const [postcards, setPostcards] = useState([]);
  useEffect(() => {
    if (postcardsLoading) return;
    if (postcardsError) {
      toast.error("Error retrieving postcard campaigns", { theme: "colored"})
      return;
    }
    if (!postcardsRaw) return;
    let postcardsArray = [];
    postcardsRaw.forEach((doc) => {
      let temp = doc.data();
      temp.postcardId = doc.id;
      temp.docRef = doc.ref;
      postcardsArray.push(temp)
    })
    postcardsArray = postcardsArray.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    })
    return setPostcards(() => postcardsArray)
  }, [postcardsRaw, postcardsLoading, postcardsError])

  const columns = [
    {
      accessor: 'name',
      Header: 'Campaign Name',
      Cell: rowData => {
        const { name  } = rowData.row.original;
        
        return (
          <div>
            {name}
          </div>
        )
      }
    },
  ];

  return (
    <>
      <PageHeader
        title="Roundtable Management" titleTag="h5" className="mb-3">
        <Button
          variant="primary"
          size='sm'
          className="mt-3 ml-auto"
          onClick={() => navigate('/marketing/postcards/create')}
        >
          Create New Postcard Campaign
        </Button>
      </PageHeader>
          <Card className="p-3">
          {!postcardsLoading && postcards.length !== 0 ? (
              <AdvanceTableWrapper
              columns={columns}
              data={postcards}
              sortable
              pagination
              perPage={10}
            >
              <Row className="flex-end-center mb-3">
                <Col lg={12}>
                  <AdvanceTableSearchBox table/>
                </Col>
              </Row>
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
              <div className="mt-3">
                <AdvanceTableFooter
                  rowCount={postcards.length}
                  table
                  rowInfo
                  navButtons
                  rowsPerPageSelection
                />
              </div>
            </AdvanceTableWrapper>
          ) : (
            <Row className="align-items-center">
              <Col>
                <Spinner animation="border" variant="primary">
                  <span className='visually-hidden'>Loading...</span>
                </Spinner>
              </Col>
            </Row>
          )
          }
          </Card>
    </>
  );
};

export default PostcardManagement;
