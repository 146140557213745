import { useState } from "react";
import { Badge, Modal } from "react-bootstrap";

const OrderItemDisplay = ({item}) => {
    return (
        <div>
            <Badge variant={"light"} className='me-2'>{item.quantity}</Badge>
            <Badge bg={"secondary"} className="me-2">{item.sku}</Badge>
            <span className="fs--2">{item.name}</span>
        </div>
    );
}

const OrderItemsList = ({items}) => {
    const [show, setShow] = useState(false);
    const itemDisplays = items.map((item, i) => {
        return <OrderItemDisplay item={item} key={i} />;
    });
    return (
        <>
            <div onClick={() => setShow(!show)}>
                ({items?.length} items)
            </div>
            <Modal show={show} onHide={() => setShow(!show)}>
                <Modal.Header closeButton>
                    <Modal.Title>Order Items</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {itemDisplays}
                </Modal.Body>
            </Modal>
        </>
    );
}

const OrderItemsRenderer = ({ value }) => {
    const items = value;
    const itemCount = items?.length;
    if (itemCount === 0) return <div>No Items</div>;
    if (itemCount === 1) return <OrderItemDisplay item={items[0]} />;
    if (itemCount > 1) return <OrderItemsList items={items} />;
    return <div>Unknown</div>;
};

export default OrderItemsRenderer;

