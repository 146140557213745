import React from 'react'
import { SkuListProvider } from 'components/pages/newProducts/context/SkuListContext';
import { WorkspaceCacheProvider } from 'components/pages/newProducts/context/WorkspaceCacheContext';
import AdvancedProductsGrid from 'components/pages/newProducts/AdvancedProductsGrid';
import { OffCanvasChatbox } from 'components/pages/newProducts/ai/MerlinAiChatbox';

const AdvancedProducts = () => {
    return (
        <WorkspaceCacheProvider>
            <SkuListProvider>
                <AdvancedProductsGrid />
                <OffCanvasChatbox />
            </SkuListProvider>
        </WorkspaceCacheProvider>
    )
}

export default AdvancedProducts
