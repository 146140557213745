import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import { db } from 'config/firebase.js'
import { query, collection, addDoc, updateDoc, limit, where, getDocs, doc, orderBy } from 'firebase/firestore';
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';
import PageHeader from 'components/common/PageHeader';
import { Card, Row, Col, Button, Form, Tabs, Tab, Image } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/merlin/advance-table/AdvanceTableSearchBox';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/merlin/advance-table/AdvanceTableFooter';
import Lottie from 'lottie-react';
import dotsAnimation from 'assets/lottie/dots.json';

const BatchToIBMi = () => {

    const [batches, batchNamesLoading, batchReadError] = useCollectionData(
        query(
            collection(db, "batchState"),
            orderBy('sortableTimestamp', 'desc')
        ), {
            snapshotListenOptions: { includeMetadataChanges: true },
    });
    const [orders, ordersLoading, ordersReadError] = useCollectionData(
        query(
            collection(db, "openOrders"),
            orderBy('lastUpdate', 'desc')
        ), {
            snapshotListenOptions: { includeMetadataChanges: true },
    });
    const [errors, errorsLoading, errorsReadError] = useCollectionData(
        query(
            collection(db, "batchToIBMiErrors"),
            orderBy('createTimestamp', 'desc')
        ), {
            snapshotListenOptions: { includeMetadataChanges: true },
    });
    const [accountingExclusions, accountingExclusionsLoading, accountingExclusionsReadError] = useCollectionData(
        query(
            collection(db, "orders"),
            where('accountingExclusion', '==', true),
            orderBy('modifyTimestamp', 'desc')
        ), {
            snapshotListenOptions: { includeMetadataChanges: true },
    });
    const [locations, locationsLoading, locationsReadError] = useCollectionData(
        query(
            collection(db, "locations"),
            orderBy('name', 'asc')
        ), {
            snapshotListenOptions: { includeMetadataChanges: true },
    });

    const [batchMetadata, metaLoading, metaError] = useDocumentData(doc(db, 'shipStationMeta', 'fulfillments'));
    const { user, authLoading }= useContext(FirebaseAuthContext)
    const navigate = useNavigate();

    const [openCarts, setOpenCarts] = useState();
    
    useEffect(() => {
      if (authLoading) return;
      if (!user) return navigate('/');

      async function getOpenCarts(uid) {
        try {
          const openOrders = []
          const q = query(
            query(
              collection(db, "openOrders"),
              where("uid", "==", uid), 
              where("status", "==", "Open")
            )
          )
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            openOrders.push({docId: doc.id, ...doc.data()})
          })
          
          return openOrders
        } catch (error) {
          console.log("getOpenOrders", error);
        }
      }
  
      getOpenCarts(user.uid).then((carts) => {
        setOpenCarts(carts)
      });

    }, [authLoading, user])
    
    const batchColumns = [
        {
          accessor: 'batchName',
          Header: 'Name'
        },
        {
            accessor: 'sortableTimestamp',
            Header: 'Timestamp',
            Cell: rowData => {
            const { timestamp } = rowData.row.original;
            const timestring = timestamp.toDate().toLocaleString();
            return (
                <span>
                    {timestring}
                </span>
            );

            },
            //sortType: "datetime", // only used with Date object
        },
        {
            accessor: 'timestamp',
            Header: 'Do Not Pick?',
            Cell: rowData => {
                const { ref, doNotPick, state } = rowData.row.original;
                let disabled;
                if (state === 'SENT') disabled = true;
                return (
                    <span>
                        {ref ?
                          <Form.Check 
                            type='switch'
                            id='defaultSwitch'
                            label=''
                            onChange={() => { updatePickStatus(ref, !doNotPick)}}
                            defaultChecked={doNotPick}
                            disabled={disabled}
                          />
                        :
                          'N/A'
                        }
                    </span>
                );

            },
            //sortType: "datetime", // only used with Date object
        },
        {
            //accessor: 'state',
            Header: 'Fulfillment Location',
            Cell: rowData => {
                // get Inventory locations from firebase
                
                return (
                    <>
                        <Form.Select
                            aria-label="Default select example"
                            onChange={(e) => { updateFulfillmentLocation(rowData.row.original.ref, e.target.value)}}
                            defaultValue={rowData.row.original.fulfillmentLocation}
                            disabled={rowData.row.original.state === 'SENT'}
                        >
                            <option key={'selOpt'} value=''>Select Location</option>
                            {locations && locations.map((location, i) => {
                                return (
                                    <option key={`${i}-opt`} value={location.name}>{location.name}</option>
                                )
                            })}
                            <option key={'nonOpt'} value={'None'}>None</option>
                        </Form.Select>
                    </>
                )
            }
        },
        {
            accessor: 'state',
            Header: 'State',
            Cell: rowData => {
                const batch = rowData.row.original;
                const state = batch.state;
                const hash = String(batchHashCode(batch.batchName));
                let disabled;
                if (state === 'SENT') disabled = true;

                return (
                    <> 
                        <Button
                            variant='falcon-primary'
                            className='mt-2'
                            onClick={(e) => handleBatchSubmit(e, batch)}
                            id={hash}
                            disabled={disabled}
                            style={{width: '5rem'}}
                            size="sm"
                        >
                            {!disabled ? 'Send' : 'Sent'}
                        </Button>
                        <Button
                            variant='falcon-success'
                            className='ms-2 mt-2'
                            onClick={() => navigate(`/batches/${batch.ref.id}`)}
                            size="sm"
                        >
                            View
                        </Button>
                    </>
                )
            }
        }
      ];

    const orderColumns = [
        {
          accessor: 'orderBatchName',
          Header: 'Name'
        },
        {
            accessor: 'lastUpdate',
            Header: 'Timestamp',
            Cell: rowData => {
            const { lastUpdate } = rowData.row.original;
            const timestring = lastUpdate.toDate().toLocaleString();
            return (
                <span>
                    {timestring}
                </span>
            );

            },
            //sortType: "datetime", // only used with Date object
        },
        {
            accessor: 'state',
            Header: 'State',
            Cell: rowData => {
                const order = rowData.row.original;
                const status = order.status;
                const hash = String(batchHashCode(order.orderBatchName));
                let disabled;
                if (status === 'Processed') disabled = true;

                return (
                    <> 
                        <Button
                            variant='falcon-primary'
                            className='mt-2'
                            onClick={(e) => {console.log('click')}}
                            id={hash}
                            disabled={true}
                            style={{width: '7rem'}}
                            size="sm"
                        >
                            {!disabled ? 'Send' : 'Processed'}
                        </Button>
                    </>
                )
            }
        }
      ];

    const errorColumns = [
        {
            accessor: 'orderData',
            Header: 'Items',
            Cell: rowData => {
            const { orderData } = rowData.row.original;
            return (
                <table>
                  <tbody>
                    <tr>
                    <td>

                    <Image src={orderData.picture} width={'20vw'} className='me-2'/>

                    </td>
                    <td>{orderData.title}</td>
                    </tr>
                  </tbody>
                </table>
            );

            },
            //sortType: "datetime", // only used with Date object
        },
        {
            accessor: '',
            Header: 'Variant SKU',
            Cell: rowData => {
            const { orderData } = rowData.row.original;
            return (
                <table>
                  <tbody>
                    <tr>
                        <td>{orderData.sku}</td>
                    </tr>
                  </tbody>
                </table>
            );

            },
            //sortType: "datetime", // only used with Date object
        },
        {
          accessor: 'batch',
          Header: 'Batch'
        },
        {
            accessor: 'createTimestamp',
            Header: 'Timestamp',
            Cell: rowData => {
            const { createTimestamp } = rowData.row.original;
            const date = new Date(createTimestamp);
            const timestring = date.toLocaleString();
            return (
                <span>
                    {timestring}
                </span>
            );

            },
            //sortType: "datetime", // only used with Date object
        },
        {
            accessor: 'status',
            Header: 'Status',
            Cell: rowData => {
                const {status} = rowData.row.original;

                return (
                    <> 
                        {status === 'OPEN' ?
                            <Button>Open</Button>
                          : <Button disabled>{status}</Button>
                        }
                    </>
                )
            }
        }
      ];

    async function updateFulfillmentLocation(reference, value) {
        if (!value || value === 'Select Location') return;
        try {
            await updateDoc(reference, {
                fulfillmentLocation: value
            });
        } catch (error) {
            console.log(error);
        }

    }
      
    const AddToOrderCartForm = (data) => {

        const carts = data?.carts;
        const items = data?.items;
        const key = data?.orderKey;
        const ssOrderId = data?.ssOrderId;

        async function addToCart(e, items, key) {
        e.preventDefault();
        const cartId = e.target.cartName.value;
        
        try {
            const docRef = doc(db, "openOrders", cartId);
            for (let i = 0; i < items.length; i++) {
            await updateDoc(docRef, {
                items: arrayUnion({
                    picture: items[i]?.imageUrl,
                    quantity: items[i]?.quantity,
                    sku: items[i]?.sku,
                    title: items[i]?.name,
                    cost: "0.00",
                }),
                })
                //markOrderAsPrePicked(key)
            }
            
        } catch (error) {
            console.log(error);
        }
        
        }

        return (
        <Form onSubmit={(e) => addToCart(e, items, key)}>
            <Row>
            <Col>
                <Form.Select
                id='cartName'
                >
                {carts ?
                    carts.map((cart, i) => {
                    return (<option key={`${i}-opt`} value={cart.docId}>{cart.orderBatchName}</option>)
                })
                : null
                }
                </Form.Select>
            </Col>
            </Row>
            <Row>
            <Col>
                <Button className='mt-2' variant='falcon-primary' type="submit">Add</Button>
            </Col>
            </Row>
            
        </Form>
        )
    }


    const accountingExclusionColumns = [
        {
            accessor: 'orderNumber',
            Header: 'Order Number',
            Cell: rowData => {
                const { orderNumber, orderId } = rowData.row.original;
                return (
                    <span>
                        <a href={`/orders/${orderId}`} target="_blank" rel="noreferrer">
                            {orderNumber}
                        </a>
                    </span>
                );
            }
        },
        {
            accessor: 'items',
            Header: 'Items',
            Cell: rowData => {
                const { items } = rowData.row.original;

               
                return (
                    <table>
                        <tbody>
                            {items.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                        {item.sku} - {item.name}
                                            <br/>
                                            
                                            
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                );
            }
        },
        {
            accessor: '',
            Header: 'Add to Order',
            Cell: rowData => {
              const { items, orderKey, prePicked, orderId } = rowData.row.original;
              const processedItems = [];
              items.forEach((item) => {
                const firstLetter = item.sku.slice(0,1);
                if (firstLetter === 'B' || firstLetter === 'S' ) {
                  /** TODO Need to manage bundles / kits */
                } else {
                  processedItems.push({
                    sku: item.sku,
                    quantity: item.quantity
                  })
                }
              })
              
              return (
                <span>
                {processedItems.length > 0 && prePicked !== true ?
                  <AddToOrderCartForm carts={openCarts} items={items} orderKey={orderKey} orderId={orderId} />
                : prePicked ? <span className='fs-1 text-primary'>Pre-Picked</span> : null
                }
                </span>
              );
            }
          },
    ];

    async function getProductDetails(sku) {
        try {
            const q = query(collection(db, "productCatalog/topicz/products"), where("sku", "==", sku));
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) return;
            const product = querySnapshot.docs[0].data();
            return product;
        } catch (error) {
            console.log(error)
        }
    }

    async function updatePickStatus(docRef, state) {
        try {
            await updateDoc(docRef, {
                doNotPick: state
            })
        } catch (error) {
            console.log(error)
        }
    }

    function batchHashCode(batchName) {
        let h;
        for ( let i = 0; i < batchName.length; i++ ) {
            h = Math.imul(31, h) + batchName.charCodeAt(i) | 0;
        } 

      return Math.abs(h);
    }

    async function handleBatchSubmit(e, batch) {
        e.preventDefault();
        const hash = batchHashCode(batch.batchName);
        const button = document.getElementById(String(hash));
        button.disabled = true;
        button.innerText = "Sending...";
        const newOrderRef = await addOrder(batch);
        await updateBatchStatus(batch.batchName, 'SENT', newOrderRef);
        button.innerText = "Sent"
        return 'SENT';
     }
    
    async function updateBatchStatus(name, status, orderRef) {
        try {
            const q = query(collection(db, "batchState"), where("batchName", "==", name), limit(1))
            const snap = await getDocs(q);
            if (!snap.empty) {

                const res = await updateDoc(snap.docs[0].ref, {
                    state: String(status),
                    orderRef: orderRef
                })
                return res;
            } else {
                console.log("no results")
            }
            
        } catch (error) {
            console.log(error)
        }
        
    }

    async function addOrder(batch) {
        try {
            const doNotPick = batch?.doNotPick || false;
            const orderRef = await addDoc( collection(db, "batchToIBMiOrders"), {
                timestamp: new Date(),
                orderBatchName: batch.batchName,
                status: 'Submitted',
                uid: user.uid,
                doNotPick: doNotPick,
            });
            return orderRef;
        } catch (error) {
            console.log(error)
        }
        
    }

    const lastSyncDisplay = batchMetadata?.lastSync ? batchMetadata.lastSync : 'now...';
    
    return (
        <>
            <PageHeader
                title="Order & Batch Processing" titleTag="h5" className="mb-3">
            {!metaLoading ? <span>Current as of {lastSyncDisplay}</span> : 'Loading...'}
            </PageHeader>
            {!batchNamesLoading && !ordersLoading && !errorsLoading ? 
                <Tabs variant='pills' defaultActiveKey="shipStationKey" id="" className='mb-3'>
                <Tab eventKey="shipStationKey" title="ShipStation Batches" className=''>
                    <Card className="p-3 mb-3">
                        {!batchNamesLoading ? 
                            <AdvanceTableWrapper
                            columns={batchColumns}
                            data={batches}
                            sortable
                            pagination
                            perPage={10}
                            >
                                <Row className="flex-end-center mb-3">
                                    <Col>
                                        <AdvanceTableSearchBox table/>
                                    </Col>
                                </Row>
                                <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    bordered: true,
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                                />
                                <div className="mt-3">
                                <AdvanceTableFooter
                                    rowCount={batches?.length}
                                    table
                                    rowInfo
                                    navButtons
                                    rowsPerPageSelection
                                />
                                </div>
                        </AdvanceTableWrapper>

                            : 'Loading...'
                        }
                    </Card>
                </Tab>
                <Tab eventKey="manualOrderKey" title="Manual Orders" className=''>
                    <Card className="p-3 mb-3">
                        {!ordersLoading ? 
                            <AdvanceTableWrapper
                            columns={orderColumns}
                            data={orders}
                            sortable
                            pagination
                            perPage={10}
                            >
                                <Row className="flex-end-center mb-3">
                                <Col xs="auto" sm={6} lg={4}>
                                    <AdvanceTableSearchBox table/>
                                </Col>
                                </Row>
                                <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    bordered: true,
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                                />
                                <div className="mt-3">
                                <AdvanceTableFooter
                                    rowCount={batches?.length}
                                    table
                                    rowInfo
                                    navButtons
                                    rowsPerPageSelection
                                />
                                </div>
                        </AdvanceTableWrapper>

                            : 'Loading...'
                        }
                    </Card>
                </Tab>
                <Tab eventKey="errorKey" title="Submission Errors" className=''>
                    <Card className="p-3 mb-3">
                        {!errorsLoading ? 
                            <AdvanceTableWrapper
                            columns={errorColumns}
                            data={errors}
                            sortable
                            pagination
                            perPage={10}
                            >
                                <Row className="flex-end-center mb-3">
                                <Col xs="auto" sm={6} lg={4}>
                                    <AdvanceTableSearchBox table/>
                                </Col>
                                </Row>
                                <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    bordered: true,
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                                />
                                <div className="mt-3">
                                <AdvanceTableFooter
                                    rowCount={errors?.length}
                                    table
                                    rowInfo
                                    navButtons
                                    rowsPerPageSelection
                                />
                                </div>
                        </AdvanceTableWrapper>

                            : 'Loading...'
                        }
                    </Card>
                </Tab>
                <Tab eventKey="accountingExclusionsKey" title="Accounting Exclusions" className=''>
                    <Card className="p-3 mb-3">
                        {!accountingExclusionsLoading ?
                            <AdvanceTableWrapper
                            columns={accountingExclusionColumns}
                            data={accountingExclusions}
                            sortable
                            pagination
                            perPage={10}
                            >
                                <Row className="flex-end-center mb-3">
                                <Col xs="auto" sm={6} lg={4}>
                                    <AdvanceTableSearchBox table/>
                                </Col>
                                </Row>
                                <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    bordered: true,
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden'
                                }}
                                />
                                <div className="mt-3">
                                <AdvanceTableFooter
                                    rowCount={accountingExclusions?.length}
                                    table
                                    rowInfo
                                    navButtons
                                    rowsPerPageSelection
                                />
                                </div>
                        </AdvanceTableWrapper>
                        : 'Loading...'
                    }
                    </Card>
                </Tab>
            </Tabs>
            :
            <div>
                <Row className="align-items-center">
                <Col>
                    <Lottie animationData={dotsAnimation} style={{height: 300}} />
                </Col>
                </Row>
            </div>
            }
            
                
        </>
    )
}

export default BatchToIBMi;



