import PageHeader from "components/common/PageHeader";
import { Card, Form, InputGroup, Button, Table, Alert } from "react-bootstrap";
import axios from "axios";
import { db } from "config/firebase";
import { query, collection, getDocs } from "firebase/firestore";
import { useState } from "react";

const ShippingCalculator = () => {
    const [ratesLoading, setRatesLoading] = useState(false);
    const [shippingRates, setShippingRates] = useState([]);
    const [shippingInfo, setShippingInfo] = useState();
    const [wt, setWeightType] = useState('pounds');

    async function handleSubmit(e) {
        e.preventDefault();
        setRatesLoading(true)
        const postalCode = document.getElementById('toPostalCode').value;
        const toCountry = document.getElementById('toCountry').value;
        const weight = document.getElementById('weight').value;
        const weightType = document.getElementById('weightType').name;
        const shippingInfo = {
            toPostalCode: postalCode,
            fromPostalCode: '45236',
            toCountry: toCountry,
            weight: {
                value: weight,
                units: wt,
                WeightUnits: '1'
            }
        }
        setShippingInfo(() => shippingInfo)
        const rates = await checkAllRates(shippingInfo);
        const processedRates = rates.filter((rate) => rate != 'no rate')
        setShippingRates(() => processedRates);
        setRatesLoading(false)
    }

    async function getRate(carrierCode, shippingInfo) {
        const data = JSON.stringify({
            carrierCode: carrierCode,
            fromPostalCode: shippingInfo.fromPostalCode,
            toCountry: shippingInfo.toCountry,
            toPostalCode: shippingInfo.toPostalCode,
            weight: shippingInfo.weight
        })

        const axiosConfig = {
            method: 'post',
            url: 'https://ssapi.shipstation.com/shipments/getrates',
            headers: { 
                'Authorization': 'Basic NjU5NTAyYjk1NmIzNDJiMjk1Njg1NzRiOGViZmM3NmY6ZDdiODY2MmUwMmMxNDBkN2JmNTA2OGE4ZjEwNmFiMzI=', 
                'Content-Type': 'application/json'
            },
            data : data
        }

        try {
            const response = await axios(axiosConfig);
           
            if (response.status !== 500) {
                return response.data;
            } else {
                return "no rate"
            }
        } catch (error) {
            return "no rate"
            
        }

    }

    async function getCarrierCodes() {
        try {
            const carriers = [];
            const q = query(collection(db, "shippingCarriers"));
            const snapshot = await getDocs(q);
            snapshot.forEach((doc) => carriers.push(doc.id))
            return carriers;
        } catch (error) {
            console.log(error)
        }
    }

    async function checkAllRates(shippingInfo) {
        const rates = [];
        const carriers = await getCarrierCodes();
        for (let i = 0; i < carriers.length; i++) {
            //console.log(carriers[i])
            const rateData = await getRate(carriers[i], shippingInfo);
            rates.push(rateData);
        }
        return rates;
    };

    return (
        <>
            <PageHeader title="Calculate Shipping Rates" titleTag="h5" className="mb-3">
                Shipping Carrier Account Details are Configured in ShipStation
            </PageHeader>
            <Card className="p-3 mb-3">
                <Form onSubmit={(e) => handleSubmit(e)}>
                    <div className="mb-3">
                      <InputGroup>
                        <InputGroup.Text> To Postal Code </InputGroup.Text>
                        <Form.Control
                            id="toPostalCode"
                        ></Form.Control>
                      </InputGroup>
                    </div>
                    <div className="mb-3">
                      <InputGroup>
                        <InputGroup.Text> To Country </InputGroup.Text>
                        <Form.Control
                            id="toCountry"
                        ></Form.Control>
                      </InputGroup>
                        
                    </div>
                    <div className="mb-1">
                      <InputGroup>
                        <InputGroup.Text> Weight </InputGroup.Text>
                        <Form.Control
                            id="weight"
                        ></Form.Control>
                      </InputGroup>
                        
                        <div className="mt-1">
                        <Form.Check 
                            inline
                            type='radio'
                            id='weightType'
                            label='Pounds'
                            name='wt'
                            defaultChecked
                            onChange={() => setWeightType('pounds')}
                        />
                        <Form.Check 
                            inline
                            type='radio'
                            id='weightType'
                            label='Ounces'
                            name='wt'
                            onChange={() => setWeightType('ounces')}
                        />
                        <Form.Check 
                            inline
                            type='radio'
                            id='weightType'
                            label='Grams'
                            name='wt'
                            onChange={() => setWeightType('grams')}
                        /></div>
                    </div>
                    <div className="float-end">
                        <Button type="submit" disabled={ratesLoading}>
                            {!ratesLoading ? 'Quote Rates' : 'Rates Loading' }
                        </Button>
                    </div>
                </Form>
            </Card>
            {!ratesLoading && shippingRates.length > 0 
                ?
                <Card className="p-3 mb-3">
                    <Alert>
                        Showing rates for a {shippingInfo.weight.value} {shippingInfo.weight.units} shipment to {shippingInfo.toPostalCode}
                    </Alert>
                    <Table striped responsive>
                    <thead>
                    
                    <tr>
                        <th>Total</th>
                        <th>Service Name</th>
                        <th>Details</th>
                    </tr></thead>
                    <tbody>
                        {shippingRates.map((carrierRates) => {
                            return carrierRates.map((rate) => {
                                const total = Number(rate.shipmentCost + rate.otherCost).toFixed(2);
                                if (rate.serviceName == undefined) rate.serviceName = 'Other Service Name'
                                if (rate) {
                                    return (
                                      <tr>
                                        <td>${total}</td>
                                        <td>{rate.serviceName}</td>
                                        <td>Shipping: ${rate.shipmentCost} <br /> Other: ${rate.otherCost}</td>
                                      </tr>
                                    )
                                }
                            })
                        })}
                    </tbody>
                    </Table>
                </Card>
                : null
            }
        </>
    )
}

export default ShippingCalculator;