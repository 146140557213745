import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import is from 'is_js';
import MainLayout from './MainLayout';
import DowntimeLayout from './DowntimeLayout';
import ErrorLayout from './ErrorLayout';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';
import Starter from 'components/pages/Starter';
import AppContext from 'context/Context';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

//import Login from 'components/pages/Login';
import Login from 'components/authentication/split/Login';
import Logout from 'components/authentication/split/Logout';
// import Registration from 'components/authentication/split/Registration';
import ForgetPassword from 'components/authentication/split/ForgetPassword';
import Products from 'components/pages/Products';
import Search from 'components/pages/Search';
import Orders from 'components/pages/Orders';
import OrderDetails from 'components/pages/OrderDetails';
import Fulfillments from 'components/pages/Fulfillments';
import Batches from 'components/garbage/Batches';
import Vendors from 'components/pages/vendors';
import VendorSignup from 'components/pages/vendorSignup';
import Locations from 'components/inventoryPages/Locations';
import LocationsDetail from 'components/inventoryPages/LocationsDetail';
import Scanner from 'components/inventoryPages/Scanner';
import StockTake from 'components/inventoryPages/StockTake';
import StockTakeDetail from 'components/inventoryPages/StockTakeDetail';
import ProductLists from 'components/pages/ProductLists';
import OrderReconciliation from 'components/merlin/custom/OrderReconciliation';
import Favorites from 'components/pages/user/Favorites';
import Carts from 'components/pages/Carts';
import NewProducts from 'components/pages/NewProducts';
import Profile from 'components/pages/user/profile/Profile';
import Settings from 'components/pages/user/settings/Settings';
import BatchToIBMi from 'components/pages/BatchToIBMi';
import ShippingCalculator from 'components/pages/ShippingCalculator';
import SearchByUPC from 'components/pages/SearchByUPC2';
import NewProductWizard from 'components/pages/NewProductWizard';
import BarcodeScanner from 'components/pages/BarcodeScanner';
import MerlinProductDetails from 'components/pages/productDetails/MerlinProductDetails';
import BatchDetail from 'components/pages/BatchDetail';
import useRoleData from 'hooks/useRoleData.js';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import BulkEditor from 'components/pages/BulkEditor';
import LaunchPad from 'components/pages/vendorMgmt/LaunchPad';
import InventoryReport from 'components/pages/InventoryReport'
import BulkUpload from 'components/pages/BulkUpload';
import TestingComponent from 'components/pages/TestingComponent';
import Bundles from 'components/pages/Bundles';
import BatchManagement from 'components/pages/BatchPickPack/BatchManagement';
import ViewBatchDetail from 'components/pages/BatchPickPack/subComponents/ViewBatchDetail';
import PackWizard from 'components/pages/BatchPickPack/PackWizard';
import PackDetail from 'components/pages/BatchPickPack/subComponents/PackDetail';
import PickWizard from 'components/pages/BatchPickPack/PickWizard';
import PickDetail from 'components/pages/BatchPickPack/subComponents/PickDetail';
import VendorStarter from 'components/pages/vendorMgmt/VendorStarter';
import TradeHubProducts from 'components/pages/TradeHubProducts';
import ShippingLabels from 'components/pages/BatchPickPack/ShippingLabels';
import RoundtableMgmt from 'components/pages/marketing/RoundtableMgmt';
import PostcardManagement from 'components/pages/marketing/postcards/PostcardManagement';
import CreatePostcardCampaign from 'components/pages/marketing/postcards/CreatePostcardCampaign';
import CreateCustomQr from 'components/pages/marketing/postcards/CreateCustomQr';
import Launchpad from 'components/pages/Launchpad';
import NotificationAdmin from 'components/pages/admin/NotificationAdmin';
import Notifications from 'components/pages/notifications/Notifications';
import GeneralRepricerSettings from 'components/pages/settings/GeneralRepricerSettings';
import FullfillmentSettings from 'components/pages/settings/FullfillmentSettings';
import AdvancedProducts from 'components/pages/newProducts/AdvancedProducts';
import AdvancedOrders from 'components/pages/newOrders/AdvancedOrders';
import AdvancedBatches from 'components/pages/newBatches/AdvancedBatches';
import NewBatchDetail from 'components/pages/newBatches/NewBatchDetail';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  useContext(AppContext);

  const { user, authLoading }= useContext(FirebaseAuthContext);
  const [roleData, orgData, userLoading, userError] = useRoleData(user?.uid);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);


  let ChosenLayout;
  let rn = new Date();
  let startDownTime = new Date('2023', '0', '24', '19', '0');
  let endDownTime = new Date('2023', '0', '25', '5', '0');
  if (rn > startDownTime && rn < endDownTime) {
    ChosenLayout = DowntimeLayout;
  } else {
    ChosenLayout = MainLayout;
  }

  if (userError) ChosenLayout = DowntimeLayout;

  /**
   * {
        user && roleData && orgData && !userLoading && !userError &&
        orgData === 'topicz' ?
        : <Route path="/dashboard" element={<VendorStarter />} />
      }    
  */
  
  return (
    <>
      <Routes>
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
        </Route>

        <Route element={<MainLayout />}>

            <Route path="/" element={<Login />} />
            <Route path="/error" element={<Error500 />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/forgot" element={<ForgetPassword />} />

            <Route path="/dashboard" element={<Starter />} />
            
            <Route path="/cart/:cartId" element={<Carts />} />
            
            {/** Old Order Routes */}
            <Route path="/orders" element={<Orders />} />
            
            <Route path="/orders/:orderId" element={<OrderDetails />} />
            <Route path="/settlements/" element={<OrderReconciliation />} />
            {/** Old Batching & Fulfillment Routes */}
            <Route path="/batches/" element={<BatchToIBMi />} />
            <Route path="/batches/:batchId" element={<BatchDetail />} />

            {/** New Order Routes */}
            <Route path="/newOrders" element={<AdvancedOrders />} />
            <Route path='/newBatches' element={<AdvancedBatches />} />
            <Route path='/newBatches/:batchId' element={<NewBatchDetail />} />

            {/** Batch Pick Pack Routes */}
            <Route path="/fulfillment/batches" element={<BatchManagement />} />
            <Route path="/fulfillment/batches/:batchId" element={<ViewBatchDetail />} />
            <Route path="/fulfillment/pick/" element={<PickWizard />} />
            <Route path="/fulfillment/pick/:pickId" element={<PickDetail />} />
            <Route path="/fulfillment/shipping-labels" element={<ShippingLabels />} />
            <Route path="/fulfillment/pack/" element={<PackWizard />} />
            <Route path="/fulfillment/pack/:packId" element={<PackDetail />} />
            <Route path="/fulfillment/shipping-calculator" element={<ShippingCalculator />} />

            {/** Vendor Management Routes */}
            <Route path="/suppliers" element={<Vendors />} />
            <Route path="/suppliers/products" element={<TradeHubProducts />} />
            <Route path="/vendors/:vendorId" element={<VendorSignup />} />
            <Route path="/vendors/launchpad" element={<LaunchPad />} />
            <Route path="/bulk-upload" element={<BulkUpload />} />
            <Route path="/:vendor/products/new" element={<NewProductWizard />} />

            {/** Inventory Routes */}
            <Route path="/inventory/locations" element={<Locations />} />
            <Route path="/inventory/locations/:locationId" element={<LocationsDetail />} />
            <Route path="/inventory/scanner" element={<SearchByUPC />} />
            <Route path="/inventory/report" element={<InventoryReport />} />

            {/** Marketing Routes */}
            <Route path="/marketing/roundtable/management" element={<RoundtableMgmt />} />

            <Route path="/marketing/postcards/management" element={<PostcardManagement />} />
            <Route path="/marketing/postcards/create" element={<CreatePostcardCampaign />} />

            <Route path="/marketing/generate/qr" element={<CreateCustomQr />} />
              
            {/** Product Routes */}
            <Route
              path="/:vendor/products/:productId"
              element={<MerlinProductDetails />}
            />
            <Route
              path="/bulk-editor"
              element={<BulkEditor />}
            /> 
            <Route path="/bundles" element={<Bundles />} />
            <Route path="/search" element={<Search />} />
            <Route path="/favorites" element={<Favorites />} />
            <Route path="/launchpad" element={<Launchpad />} />
            <Route path="/products" element={<AdvancedProducts />} />

            {/** User Routes */}
            <Route path="user/profile" element={<Profile />} />
            <Route path="user/settings" element={<Settings />} />
            <Route path="user/notifications" element={<Notifications />} />
          
            {/** Admin Routes */}
            <Route path="admin/notifications" element={<NotificationAdmin />} />
            <Route path="advanced/repricer/management" element={<GeneralRepricerSettings />} />
            <Route path="advanced/fulfillment/management" element={<FullfillmentSettings />} />
            
        </Route>

        <Route path="*" element={<Navigate to="/errors/404" replace />} />

      </Routes>

      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;

/*
<Route path="/inventory/stocktake" element={<StockTake />} />
<Route path="/inventory/stocktake/:stockTakeId" element={<StockTakeDetail />} />
*/