import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { isIterableArray } from 'helpers/utils';
import Flex from 'components/common/Flex';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { Image, Spinner, Form, Dropdown, Modal, Row, Col} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import Lottie from 'lottie-react';
import breatheAnimation from 'assets/lottie/breathe.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import * as fuzz from 'fuzzball'
import useSearchData from 'hooks/useSearchData';

const MediaSearchContent = ({ item, rowNum}) => {
  item.title = item.name;
  item.text = item.sku;
  let clss = "px-card py-2";
  if (rowNum === 0) clss += " bg-soft-success";
  if (item.disc === true) {
    clss = clss.replace("bg-soft-success", "");
    clss += " bg-soft-danger";
  }
  return (
    <Dropdown.Item className={clss} as={Link} to={item.url}>
      <Flex alignItems="center">
        {item.image && (
          <Image src={item.image} alt="" className="rounded" width={50}/>
        )}
        <div className="ms-2">
          <h6 className="mb-0">
            {item?.disc && <span>🛑</span>} {item.title}</h6>
          <p
            className="fs--2 mb-0"
            dangerouslySetInnerHTML={{ __html: item.text || item.time }}
          />
        </div>
      </Flex>
    </Dropdown.Item>
  );
};

const SearchBox = ({ autoCompleteItem }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [resultItem, setResultItem] = useState(autoCompleteItem);
  const { user, authLoading }= useContext(FirebaseAuthContext)
  const [results, setResults] = useState([]);
  const navigate = useNavigate();
  //const [roleData, orgData, userLoading, userError] = useRoleData(user?.uid);
  const [choiceData, choicesLoading, choicesError, excludeDisc, message] = useSearchData(/** Data Expired */ false);
  const [choiceLibrary, setChoiceLibrary] = useState([]);
  const [savedDisc, setSavedDisc] = useState([]);
  const [removeState, setRemoveState] = useState(false);

  // useEffect(() => {
  //   // navigate to login if no user
  //   if (!user && !authLoading) {
  //     navigate('/login', { replace: true });
  //   }
  // }, [user, authLoading, navigate]);
  
  function chunk(array, size) {
    const chunked_arr = [];
    let copied = [...array]; // ES6 destructuring
    const numOfChild = Math.ceil(copied.length / size); // Round up to the nearest integer
    for (let i = 0; i < numOfChild; i++) {
      chunked_arr.push(copied.splice(0, size));
    }
    return chunked_arr;
  }

  useEffect(() => {
    if (searchInputValue.length > 0) {
      setDropdownOpen(true);
    } else {
      setDropdownOpen(false);
    }
  }, [searchInputValue])

  useEffect(() => {
    if (choicesLoading) return;
    if (choicesError) return;
    if (choiceData?.length > 0) {
      setChoiceLibrary(choiceData);
    }
  }, [choiceData, choicesLoading, choicesError])

  
  async function searchProducts(searchTerm) {
    try {

      const options = {
        scorer: fuzz.partial_ratio,
        processor: (choice) => choice.proc_sorted,
        limit: 500,
        cutoff: 75,
        unsorted: false,
        full_process: false,
      }

      const lib = choiceLibrary.filter((item) => item !== undefined);
      const results = fuzz.extract(searchTerm, /** fuzzyChoices */ lib, options)

      return results;
    } catch (error) {
      console.log(error);
    }

  }

  const toggle = () => setDropdownOpen(!dropdownOpen);

  function removeDiscItems() {
    
    setRemoveState(true);
    let disc = choiceData.filter((item) => item.disc === true);
    setSavedDisc(disc);
    let filtered = choiceData.filter((item) => item.disc !== true);
    setChoiceLibrary(filtered);
    setSearchInputValue((current) => current)
    //initiateSearch();
  }

  function restoreDiscItems() {
    setRemoveState(false);
    setChoiceLibrary((choices) => [...choices, ...savedDisc]);
    initiateSearch();
  }

  function initiateSearch() {
    searchProducts(searchInputValue).then((results) => {

      setResultItem(() => results);
      setResults(() => results);
      isIterableArray([])
        ? setDropdownOpen(true)
        : setDropdownOpen(false);
      
    });
  }

  useEffect(() => {
    if (choicesLoading) return;
    if (!searchInputValue) return;
    if (searchInputValue.length > 2) {
      searchProducts(searchInputValue).then((results) => {
        setResultItem(() => results);
        setResults(() => results);
        isIterableArray(results)
          ? setDropdownOpen(true)
          : setDropdownOpen(false);
      });
    }

    // eslint-disable-next-line
  }, [searchInputValue]);

  function handleSearchClose() {
    setDropdownOpen(false);
    setSearchInputValue('');
    setResultItem(autoCompleteItem);
    setResults([]);
    const searchBar = document.getElementById('search-input') ;
    searchBar.focus();
    setDropdownOpen(true)
  }

  function handleSearchSubmit(e) {
    e.preventDefault();
    if (results.length > 0) {
      const result = results[0];
      const resultData = result[0];
      navigate(resultData.url);
    }
  }

    return (
    <>
      <Modal 
        show={choicesLoading} 
        fullscreen={true}
      >
          <Modal.Body
            className="bg-primary text-light d-flex align-items-center justify-content-center"
          >
            <Row>
              <Col className="text-center">
                <Lottie animationData={breatheAnimation} style={{height: 300}} /> <br />
                <h3 className="text-light">{message}</h3>
              </Col>
            </Row>
          </Modal.Body>
      </Modal>
      <Dropdown onToggle={toggle} className="search-box">
        <Dropdown.Toggle
          as="div"
          data-toggle="dropdown"
          aria-expanded={dropdownOpen}
          bsPrefix="toggle"
        >
          <Form className="position-relative" onSubmit={handleSearchSubmit}>
            <Form.Control
              type="search"
              placeholder="Search..."
              aria-label="Search"
              className="rounded-pill search-input"
              value={searchInputValue}
              onChange={({ target }) => setSearchInputValue(target.value)}
              disabled={choicesLoading}
              id='search-input'
            />
            <FontAwesomeIcon
              icon="search"
              className="position-absolute text-400 search-box-icon"
            />
            {searchInputValue && (
              <div
                className="search-box-close-btn-container"
              >
              {!choicesLoading ?
                <FalconCloseButton
                    size="sm"
                    noOutline
                    onClick={(e) => handleSearchClose(e)}
                  />
                : <Spinner animation="border" variant="primary" size='sm' />
              }

              </div>
            )}
          </Form>
          
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="scrollbar py-3" style={{ maxHeight: '24rem' }}>

            {isIterableArray(results) && (
              <>
                <Dropdown.Header as="h6" className="px-card pt-0 pb-2 fw-medium mb-3">
                  <div className='float-start'>
                    Showing {results.length} Results
                  </div>
                  <div className='float-end'>
                    {removeState ?
                      <a onClick={() => restoreDiscItems()}>Restore Disc</a>
                    :
                      <a onClick={() => removeDiscItems()}>Remove Disc</a>
                    }
                  </div>
                </Dropdown.Header>
                {results.map((item, index) => (
                  <MediaSearchContent item={item[0]} key={`item-${index}`} rowNum={index} />
                ))}
              </>
            )}
            {resultItem.length === 0 && (
              <p className="fs-1 fw-bold text-center mb-0">No Result Found.</p>
            )}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
    );
  
};

MediaSearchContent.propTypes = {
  item: PropTypes.shape({
    catagories: PropTypes.string,
    url: PropTypes.string.isRequired,
    icon: PropTypes.shape({
      img: PropTypes.string.isRequired,
      size: PropTypes.string,
      status: PropTypes.string
    }),
    title: PropTypes.string,
    text: PropTypes.string,
    img: PropTypes.string,
    time: PropTypes.string,
    file: PropTypes.bool,
    imgAttrs: PropTypes.shape({
      class: PropTypes.string
    })
  }).isRequired
};

SearchBox.propTypes = {
  autoCompleteItem: PropTypes.arrayOf(
    PropTypes.shape(MediaSearchContent.propTypes.item)
  )
};

export default SearchBox;
