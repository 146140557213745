import React, { useContext, useEffect, useState } from 'react';
import { Dropdown } from "react-bootstrap"
import Flex from 'components/common/Flex';
import { ActionsContext } from 'context/BatchesMenuBarActionsContext';

export const MenuBar = ({ gridRef }) => {

    const CreateMenu = ({ name, actions, bold }) => {
        return (
            <div className='ms-0 me-3'>
            <Dropdown >
                <Dropdown.Toggle as={MenuToggle} id={`${String(name).substring(0,3)}-DropdownToggle`} >
                    <span className={bold ? 'fw-bold text-white' : 'text-white'}>{String(name)}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu as={Menu}>
                    {Object.entries(actions).map(([label, {actionType, payload}]) => (
                        <Dropdown.Item key={actionType} onClick={createHandler(actionType, payload)}>
                            {label}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
            </div>
            
        )
    }
    const { handleAction } = useContext(ActionsContext);
    const createHandler = (actionType, payload) => () => {
        handleAction(actionType, payload);   
    }
    const batchesActions = {
        'Get Help': {
            actionType: 'GET_HELP',
        }
    };

    
    const editActions = {
        'Mark Ready': {
            actionType: 'MARK_READY',
        },
        'Submit': {
            actionType: 'SUBMIT_BATCH',
        },
        'Reopen': {
            actionType: 'REOPEN_BATCH',
        },
        'Change Type': {
            actionType: 'CHANGE_TYPE',
        }
    };

    const itemsActions = {
        'Add Item': {
            actionType: 'ADD_ITEM',
        },
        'Remove Item': {
            actionType: 'REMOVE_ITEM',
        }
    };

    return (
        <Flex >
                <CreateMenu name={"Batches"} actions={batchesActions} bold/>
                <CreateMenu name={"Actions"} actions={editActions} />
                <CreateMenu name={"Items"} actions={itemsActions} />
        </Flex>
    )
}

const MenuToggle = React.forwardRef(( { children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
        className='fs--1 fw-semi-bold text-800 ms-2'

    >
        {children}
    </a>
))

const Menu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');
        return (
            <div
                ref={ref}
                className={className}
                aria-labelledby={labeledBy}
                style={{ ...style }}
            >
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        child =>
                            !value || child.props.children.toLowerCase().startsWith(value),
                    )}
                </ul>
            </div>
        )
    }
)
