import React from 'react';
// import { Link } from 'react-router-dom';
import LoginForm from 'components/authentication/LoginForm';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import bgImg from 'assets/merlin/merlin.png';
import Flex from 'components/common/Flex';

// <span className="fw-semi-bold">New User? </span>
// <Link to="/register">Create account</Link>

const Login = () => {
  return (
    <AuthSplitLayout bgProps={{ image: bgImg, position: '50% 20%' }}>
      <Flex alignItems="center" justifyContent="between">
        <h3>Login</h3>
      </Flex>
      <LoginForm layout="split" hasLabel />
    </AuthSplitLayout>
  );
};

export default Login;
