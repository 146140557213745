import classNames from 'classnames';
import IconButton from 'components/common/IconButton';
import React, { useState } from 'react';
import { Col, Row, Modal, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// import { ProductContext } from 'context/Context';
// import useProductHook from '../useProductHook';
// import StarRating from 'components/common/StarRating';
import QuantityController from './components/QuantityController';
import FavoriteHeart from './components/FavoriteHeart';
import SoftBadge from 'components/common/SoftBadge';
import {query, collection, where, getDocs, getDoc, doc, updateDoc} from 'firebase/firestore';
import { db } from 'config/firebase';
import MissingImage from 'assets/merlin/missing_image.png';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'

const ProductDetailsMain = ({ product, fbRef, uid }) => {
  const {
    id,
    name,
    category,
    rating,
    totalReview,
    favorite,
    shortDescription,
    cost,
    discountCost,
    discount,
    shippingCost,
    isInStock,
    tags
  } = product;

  const [productCount, setProductCount] = useState(1);
  const [showAddToOrderModal, setShowAddToOrderModal] = useState(false);
  const [orderToAddTo, setOrderToAddTo] = useState();
  const [orderDisplayData, setOrderDisplayData] = useState([]);
  const categories = category?.split(' > ');
  const [editModalShow, setEditModalShow] = useState(false);

  const handleQuantityChange = e => {
    setProductCount(parseInt(e.target.value < 1 ? 1 : e.target.value));
  };

  const handleQuantityIncrease = () => {
    setProductCount(productCount + 1);
  };

  const handleQuantityDecrease = () => {
    productCount > 1 && setProductCount(productCount - 1);
  };

  async function AddItemToOrder(orderId, item) {
    /**
     * item = {
     *  picture
     *  quantity
     *  vendor
     *  sku
     *  title
     *  }
     */
    try {
      const orderRef = doc(db, "openOrders", orderId);
      const orderDoc = await getDoc(orderRef);
      const orderData = orderDoc.data();
      const orderItems = orderData.items || []; // If there are no items, create an empty array
      const itemExists = orderItems.filter((i) => i.sku === item.sku);
      if (itemExists.length > 0) {
        const itemIndex = orderItems.indexOf(itemExists[0]);
        orderItems[itemIndex].quantity = orderItems[itemIndex].quantity + item.quantity;
      } else {
        orderItems.push(item);
      }
      await updateDoc(orderRef, {
        items: orderItems,
      });
      toast.info(`Added ${item.title} to ${orderData.orderBatchName}`, {
        position: 'top-center',
        theme: 'colored'
      });
    } catch (error) {
      console.log(error)
    }
  }

  async function handleAddToCart(product) {

    const item = {
      picture: product.files[0]?.src || MissingImage,
      quantity: productCount,
      vendor: product.vendor,
      sku: product.sku,
      title: product.name,
    }
    
    /** Check to see how many open orders the User has */
    const openOrderIds = []; 
    const openOrdersRef = collection(db, "openOrders");
    const q = query(openOrdersRef, where("status", "!=", "Processed"), where("uid", "==", uid));
    const snapshot = await getDocs(q);
    const openOrders = []
    snapshot.forEach((doc) => {
      openOrderIds.push(doc.id)
      openOrders.push({
        name: doc.data().orderBatchName,
        id: doc.id,
        item: item
      })
    });
    const numberOfOpenOrders = openOrderIds.length;
    if (numberOfOpenOrders > 1) {
      /** If the User has more than 1 open order, ask them which order they want to add the product to via modal */
      setShowAddToOrderModal(true);
      setOrderDisplayData(openOrders);
    } else {
      /** If the User has only 1 open order, add the product to that order */
      await AddItemToOrder(openOrderIds[0], item)
    }

  
  }

  /**
   * <div className="fs--2 mb-3 d-inline-block">
        <StarRating readonly rating={rating} />
        <span className="ms-1 text-600">({totalReview})</span> 
     </div>
  */

  const AddToOrderModal = () => {
    return (
      <Modal
        show={showAddToOrderModal}
        onHide={() => {setShowAddToOrderModal(false)}}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add to Order
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {orderDisplayData.map((order, index) => {
            return(
              <div className="d-flex align-items-center mb-3" key={`${index}-order`}>
                <div className="flex-grow-1">
                  <h5>{order.name}</h5>
                </div>
                <div className="flex-shrink-0">
                  <IconButton
                    icon="cart-plus"
                    size="sm"
                    color="secondary"
                    onClick={() => {AddItemToOrder(order.id, order.item); setShowAddToOrderModal(false)}}
                  >
                    Add to Order
                  </IconButton>
                </div>
              </div>
            )
          })}
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            icon="cart-plus"
            size="sm"
            color="secondary"
            onClick={() => {setShowAddToOrderModal(false)}}
          >
            Close
          </IconButton>
        </Modal.Footer>
      </Modal>

    )
  };

  function handleEditClick(e){
    setEditModalShow(true);
  }

  async function handleNameUpdate(e) {
    e.preventDefault();
    const el = document.getElementById("editedProductNameControl");
    const name = String(el.value);
    try {
      await updateDoc(fbRef.ref, {
        name: name
      });
      setEditModalShow(false);
      toast.info(`Name updated to ${name}`, {
        position: 'top-center',
        theme: 'colored'
      });
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <AddToOrderModal />  
      <Row className="ms-1">
        <Col xs="auto" className="px-0 me-3 mb-2">
          <FavoriteHeart product={product} fbRef={fbRef.id}/>
        </Col>
        <Col xs="auto" className="px-0">
          <div>
            {categories?.map((category, index) => {
              return (
                
                  <Link to="#!" 
                    className="fs--1 mb-2 me-1 d-inline-block"
                    key={`${index}-link`}
                  > 
                    {category} {index !== (categories.length-1) ? '>' : null }
                  </Link> 
              )
            })}
          </div>
        </Col>
      </Row>
      
      <div className='ms-1 mb-2'>
        <h5>{name} <FontAwesomeIcon className='ms-2 fs-1' size='xs'  icon={faPen} onClick={(e) => handleEditClick(e)} /></h5>
      </div>

      
      {shortDescription && <p className="fs--1">{shortDescription}</p>}

      <Row>
        <Col xs={4}>
          <h4 className="d-flex align-items-center">
            <span className="text-warning me-2">
              {`$${discountCost ? discountCost : cost}`}
            </span>
            {discountCost && (
              <span className="me-1 fs--1 text-500">
                <del className="me-1">{`$${cost}`}</del>
                <strong>-{discount}%</strong>
              </span>
            )}
          </h4>
          <p className="fs--1">
            Stock:{' '}
            <strong
              className={classNames({
                'text-success': isInStock,
                'text-danger': !isInStock
              })}
            >
              {isInStock ? 'Available' : 'Stock-Out'}
            </strong>
            {product?.ibmi?.size === 'DISC' ?
              <strong
                className={classNames({
                  'text-danger': true
                })}
              >
                Product Discontinued
              </strong>
            : null }
          </p>
        </Col>
        <Col>
          <Row>
            <Col xs="auto" className="pe-0 mb-2">
              <div className="" style={{width: '150px'}}>
                <QuantityController
                  quantity={productCount}
                  handleChange={handleQuantityChange}
                  handleIncrease={handleQuantityIncrease}
                  handleDecrease={handleQuantityDecrease}
                />
              </div>
            </Col>
          </Row>
          <Row className="">
            <Col xs="auto" className="px-2 px-md-3">
              <IconButton
                iconClassName="me-sm-2"
                variant="primary"
                size="sm"
                icon="cart-plus"
                style={{width: '150px'}}
                onClick={() => handleAddToCart(product)}
              >
                <span className="d-none d-sm-inline-block">Add To Order</span>
              </IconButton>
            </Col>
          </Row>
        </Col>
      </Row>
      
      {tags && (
        <>
          <div className="fs--1 mt-3 mb-3">
            {tags?.map((tag, index) => (
              <SoftBadge key={`tag-${tag}-${index}`} className='me-2'> 
                {tag}
              </SoftBadge>
            ))}
          </div>
        </>
      )}

      <Modal
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Update Title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => handleNameUpdate(e)}>
            <Form.Label>
              Product Name
            </Form.Label>
            <Form.Control type="text" defaultValue={name} id="editedProductNameControl" />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="falcon-secondary" onClick={() => setEditModalShow(false)}>Close</Button>
          <Button onClick={(e) => handleNameUpdate(e)}>Save</Button>
        </Modal.Footer>
      </Modal>
    
      
      
    </>
  );
};


export default ProductDetailsMain;
