import { useContext, useEffect, createContext, useState } from "react";
import { db, auth } from "config/firebase";
import { useAuthState } from 'react-firebase-hooks/auth';

export const FirebaseAuthContext = createContext();

export const FirebaseAuthProvider = ({ children }) => {
    const [user, authLoading] = useAuthState(auth);
    const value = { user, authLoading };
    
    useEffect(() => {
      const unsubscribe = {};
      return unsubscribe
    }, []);
    
    return (
        <FirebaseAuthContext.Provider value={value}>
            { children }
        </FirebaseAuthContext.Provider>
    )
};

export function useFirebaseAuth() {
    const context = useContext(FirebaseAuthContext)
    if (context === undefined) throw new Error("Must be used within FirebaseAuthProvider")
    return [context.user, context.authLoading];
}

