import React, { useEffect, useState } from 'react';
import { Modal, Button, Form as bForm, Col, Row, Image, Badge} from 'react-bootstrap';
import { serverTimestamp, addDoc, doc, collection, onSnapshot, getDocs, updateDoc } from 'firebase/firestore';
import { db } from 'config/firebase';
import validator from '@rjsf/validator-ajv8';
import Form from '@rjsf/core';

export const NewListingModal = ({ show, setShow, data }) => {

    const { name, title, sku, primaryImage } = data;

    const handleClose = () => setShow(false);
    
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Create New Listing</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ScreenDisplayManager data={data}/>
            </Modal.Body>
        </Modal>
    )
}

const ScreenDisplayManager = ({data}) => {
    const [screen, setScreen] = useState("start");
    const [taskRef, setTaskRef] = useState(null);

    switch (screen) {
        case "start":
            return <StartScreen data={data} goToScreen={setScreen}/>;
        case "select":
            return <SelectScreen data={data} goToScreen={setScreen} />;
        case "amazon":
            return <AmazonIntroScreen data={data} goToScreen={setScreen} taskRef={taskRef} setTaskRef={setTaskRef}/>;
        case "amazon2":
            return <AmazonFormScreen data={data} goToScreen={setScreen} taskRef={taskRef}/>;
        case "amazon3":
            return <AmazonFormDetailsScreen data={data} goToScreen={setScreen} taskRef={taskRef}/>;
        default:
            return <DefaultScreen data={data} goToScreen={setScreen} />;
    }
}

const StartScreen = ({data, goToScreen}) => {

    const { name, title, sku, primaryImage } = data;

    return (
        <div className="screen-container">
            <Row className="align-items-center mb-4"> 
                <Col md={6}>
                    <Image src={primaryImage} fluid rounded />
                </Col>
                <Col md={6}>
                    <div className="info-text">
                        <p>You are creating a marketplace listing for <strong>{sku}: {title}</strong> ({name})</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-end"> 
                    <Button variant="primary" onClick={() => goToScreen("select")}>Next</Button>
                </Col>
            </Row>
        </div>
    );
}

const SelectScreen = ({data, goToScreen}) => {
    
    const { name, title, sku, primaryImage } = data;

    const options = [
        {
            name: "Amazon",
            screen: "amazon",
            active: true,
        },
        {
            name: "Shopify",
            screen: "shopify",
            active: false,
        },
        {
            name: "Walmart",
            screen: "walmart",
            active: false,
        },
        {
            name: "eBay",
            screen: "ebay",
            active: false,
        },
    ];

    const ChannelButtons = ({ options }) => {
        const allButtons = options?.map((option, index) => {
            return (
                <Button className="mt-1" key={index} disabled={!option.active} variant="falcon-default" onClick={() => goToScreen(option.screen)}>{option.name}</Button>
            );
        })

        return (
            <div className="d-flex flex-column">
                {allButtons.map((button) => button)}
            </div>
        );
    };

    return (
        <div className="screen-container">
            <Row className="align-items-center mb-4"> 
                <Col md={6}>
                    <Image src={primaryImage} fluid rounded />
                </Col>
                <Col md={6}>
                    <ChannelButtons options={options}/>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-start">
                    <Button variant="falcon-danger" onClick={() => goToScreen("start")}>Back</Button>
                </Col>
            </Row>
        </div>
    );
}
    
const DefaultScreen = ({data, goToScreen }) => {
    return (
        <div className="screen-container">
            <Row className="align-items-center mb-4"> 
                <Col md={6}>
                    <Image src={data.primaryImage} fluid rounded />
                </Col>
                <Col md={6}>
                    <div className="info-text">
                        Whoops! Something went wrong!
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-end"> 
                    <Button variant="falcon-danger" onClick={() => goToScreen("start")}>Start Over</Button>
                </Col>
            </Row>
        </div>
    );
}

const AmazonIntroScreen = ({data, goToScreen, taskRef, setTaskRef}) => {

    const [working, setWorking] = useState(false);

    useEffect(() => {
        if (!taskRef) return;
        let isSubbed = true;
        if (isSubbed) goToScreen("amazon2");
        return () => isSubbed = false;
    }, [taskRef]);

    async function handleCreateNewListing() {
        setWorking(true);
        try {
            const { success, taskRef, message } = await startNewAmazonListing(
                db,
                data.sku,
                "UPC",
                data.barcodes.unit,
                "new_new",
                "CANDY"
            );
            if (success) {
                setWorking(false);
                setTaskRef(taskRef);
            } else {
                console.log("New listing task failed");
                console.log(taskRef);
                console.log(message);
                setWorking(false);
            }
        } catch (error) {
            console.error(error);
            setWorking(false);
        }
        
    }

    return (
        <div className="screen-container">
            <Row className="align-items-center mb-4"> 
                <Col md={6}>
                    <Image src={data.primaryImage} fluid rounded />
                </Col>
                <Col md={6}>
                    <div className="info-text">
                        <p>You are creating an Amazon listing for <strong>{data.sku}: {data.title}</strong></p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-end"> 
                    <Button disabled={working} variant="primary" onClick={() => handleCreateNewListing()}>Create!</Button>
                </Col>
            </Row>
        </div>
    );
}

const AmazonFormScreen = ({data, goToScreen, taskRef}) => {

    const [taskData, setTaskData] = useState(null); // this is the data from the taskRef
    const [working, setWorking] = useState(false);
    const [selectedType, setSelectedType] = useState(null);

    useEffect(() => {
        if (!taskRef) return;
        let isSubbed = true;
        setWorking(true);
        const unsub = onSnapshot(doc(db, "amazonApiTasks", taskRef.id), (doc) => {
            if (isSubbed) { 
                setTaskData(doc.data());
                setWorking(false);
            }
        });
        return () => {
            isSubbed = false;
            unsub();
        }
    }, [taskRef]);

    useEffect(() => {
        if (!selectedType)  return;
        async function updateTask() {
            try {
                await updateDoc(taskRef, {
                    "jobData.payload.productType": selectedType,
                })
            } catch (error) {
                console.error(error);
            }
        }
        let isSubbed = true;
        if (isSubbed) updateTask();
        return () => isSubbed = false;
    }, [selectedType]);

    async function handleFinalSubmit() {
        setWorking(true);
        try {
            await updateDoc(taskRef, {
                "status": "New",
                "timestamp.updated": serverTimestamp(),
            });
            setWorking(false);
            goToScreen("amazon3");
        } catch (error) {
            console.error(error);
            setWorking(false);
        }
    }

    return (
        <div className="screen-container">
            {/** create a row that has a blueback ground for staus updates */}
            <Row className="bg-primary align-items-center mb-4">
                <Col md={12}>
                    {!working ? <Badge variant="primary">{taskData?.status}</Badge> : 'Loading...'}
                </Col>
            </Row>
            <Row className="align-items-center mb-4"> 
                <Col md={12}>
                    <ProductTypeSelector
                        selectedType={selectedType}
                        setSelectedType={setSelectedType}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-end"> 
                    <Button variant="success" onClick={() => handleFinalSubmit()}>Finalize</Button>
                </Col>
            </Row>
        </div>
    );
}

const AmazonFormDetailsScreen = ({data, goToScreen, taskRef}) => {

    const [taskData, setTaskData] = useState(null); // this is the data from the taskRef
    const [working, setWorking] = useState(false);
    const [def, setDef] = useState(null);
    const [formDisplayName, setFormDisplayName] = useState(null); // this is the data from the taskRef
    const [schema, setSchema] = useState(null);

    console.log(def?.schema?.link.resource)
    // schema 

    useEffect(() => {
        if (!taskRef) return;
        let isSubbed = true;
        setWorking(true);
        const unsub = onSnapshot(doc(db, "amazonApiTasks", taskRef.id), (doc) => {
            if (isSubbed) {
                let temp = doc.data();
                if (temp?.productTypeDefinition) {
                    setDef(temp.productTypeDefinition);
                    setFormDisplayName(`${temp?.productTypeDefinition?.displayName} - ${temp?.productTypeDefinition?.requirements}`);
                }
                setTaskData(temp);
                setWorking(false);
            }
        });
        return () => {
            isSubbed = false;
            unsub();
        }
    }, [taskRef]);

    useEffect(() => {
        if (!def) return;
        const url = def?.schema?.link.resource;
        async function loadSchema(url) {
            const schema = await fetchSchema(url);
            return schema;
        }
        loadSchema(url).then((schemaForForm) => {
            setSchema(schemaForForm);
        }).catch((error) => {
            console.error(error);
        });
    }, [def]);

    return (
        <div className="screen-container">
            {/** create a row that has a blueback ground for staus updates */}
            <Row className="bg-primary align-items-center mb-4">
                <Col md={12}>
                    {!working ? <Badge variant="primary">{taskData?.status}</Badge> : 'Loading...'}
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <h3>{formDisplayName}</h3>
                </Col>
            </Row>
            <Row className="align-items-center mb-4"> 
                <Col md={12}>
                    {schema && 
                        <Form
                            schema={schema}
                            validator={validator}
                            onSubmit={(e) => console.log(e)}
                        />
                    }
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-end"> 
                    <Button variant="secondary" onClick={() => {}}>Wait</Button>
                </Col>
            </Row>
        </div>
    );
}

async function fetchSchema(url) {
    const response = await fetch(url);
    const schema = await response.json();
    return schema;
}

const ProductTypeSelector = ({selectedType, setSelectedType}) => {

    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function getProductTypeOptions() {
            const snap = await getDocs(collection(db, "amazonApiProductTypes"));
            const productTypes = snap.docs.map((doc) => {
                return {
                    id: doc.id,
                    ...doc.data(),
                }
            });
            return productTypes;
        }
        getProductTypeOptions().then((productTypes) => {
            setOptions(productTypes);
            setLoading(false);
        }).catch((error) => {
            console.error(error);
            setLoading(false);
        });
    }, []);

    const handleSelect = (e) => {
        const selected = e.target.value;
        setSelectedType(selected);
    } 

    return (
        !loading && options.length > 0 ? 
        <bForm.Select value={selectedType} onChange={(e) => handleSelect(e)}>
            {options.map((option) => {
                return (
                    <option key={option.id} value={option.name}>{option.displayName}</option>
                );
            })}
        </bForm.Select>
        : <div>Loading...</div>
    );
}



async function startNewAmazonListing(
    db,
    sku, 
    searchType /** "UPC" */,
    searchPayload /** "859917004393" */,
    conditionType, /** "new_new" */
    productType /** "CANDY" */
) {
    const collectionName = "amazonApiTasks";
    const newListing = {
        status: "Draft",
        handler: "amazonApiRequestHandler",
        type: "putOfferOnlyListing",
        timestamp: {
            created: serverTimestamp(),
            updated: serverTimestamp(),
        },
        jobData: {
          contentType: "application/json",
          httpMethod: "POST",
          inSeconds: 0,
          payload: {
            sku,
            searchType,
            searchPayload,
            conditionType,
            productType,
          },
          queueName: "amazon-api-queue",
        },
    };
    try {
        const docRef = await addDoc(collection(db, collectionName), newListing);
        return { success: true, taskRef: docRef, message: "New listing task created"};
    } catch (error) {
        console.error(error);
        return { success: false, message: error.message};
    }

}