import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from 'config/firebase';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import useRoleData from 'hooks/useRoleData.js';
import {
    Accordion, 
    Container, 
    Card,
    Row,
    Col,
    Button,
    Form,
    InputGroup,
    FormControl,
    Modal,
    Image
    } from "react-bootstrap";
import { 
    query, 
    orderBy, 
    startAt, 
    limit, 
    collection,
    getDocs,
    endAt,
    where,
    getCountFromServer,
    updateDoc
    } from "firebase/firestore";
import PageHeader from "components/common/PageHeader";
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/merlin/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/merlin/advance-table/AdvanceTableFooter';
import AdvanceTablePagination from 'components/merlin/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import dotsAnimation from 'assets/lottie/dots.json';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { toast } from 'react-toastify';


function BulkEditor() {

    const navigate = useNavigate();
    const { user, authLoading }= useContext(FirebaseAuthContext)
    const [roleData, orgData, userLoading, userError] = useRoleData(user?.uid);
    const [tableData, setTableData] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [firstVisible, setFirstVisible] = useState(null);
    const [pageLimit, setPageLimit] = useState(20);
    const [nextQuery, setNextQuery] = useState(null);
    const [prevQuery, setPrevQuery] = useState(null);
    const [firebaseQueryExecuting, setFirebaseQueryExecuting] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [currentQuery, setCurrentQuery] = useState(null);
    const [queryResultCount, setQueryResultCount] = useState(0);
    const [showTagsModal, setShowTagsModal] = useState(false);
    const [modalSelections, setModalSelections] = useState([]);

    const bulkEditorColumns = [
        {
            accessor: "primaryImage",
            Header: "Image",
            Cell: rowData => {
                const { primaryImage, files } = rowData.row.original;
                if (files && files.length > 0) {
                    return (
                        <Image src={files[0].src} alt="product image" width={40}/>
                    )
                } else {
                    return (
                        <Image src={primaryImage} alt="product image" width={40}/>
                    )
                }
                
            }
        },
        {
            Header: "Product Name",
            accessor: "name",
            Cell: rowData => {
                const { name, productUrl } = rowData.row.original;
                return (
                    <a className='text-dark' href={productUrl} target="_blank">{name}</a>
                )
            }
        }
    ]
    const rowsPerPageOptions = [20, 200, 5000, 10000];

    /** Initial Query Load */
    useEffect(() => {
        if (!orgData) return;
        const firstQueryCount = query(
            collection(db, `productCatalog/${orgData}/products`),
            orderBy("timestamp.modified", "desc"),
        );
        const firstQuery = query(
            collection(db, `productCatalog/${orgData}/products`),
            orderBy("timestamp.modified", "desc"),
            limit(pageLimit)
        );
        setCurrentQuery(firstQuery);
        getCountFromServer(firstQueryCount).then((count) => {
            setQueryResultCount(count.data().count);
        });
        getDocs(firstQuery).then((snapshot) => {
            
            const lastV = snapshot.docs[snapshot.docs.length - 1];
            setLastVisible(lastV);
            
            if (lastV) {
                const next = query(
                    collection(db, `productCatalog/${orgData}/products`),
                    orderBy("timestamp.modified", "desc"),
                    startAt(lastV),
                    limit(pageLimit)
                );
                setNextQuery(next);
                
                // process table data
                const tableData = snapshot.docs.map((doc) => {
                    let temp = doc.data();
                    temp.ref = doc.ref;
                    return temp;
                });
                setTableData(() => tableData);
                setFirstLoad(false);
                return () => snapshot;
            }

        });

    }, [orgData/**  */])

    useEffect(() => {
      if (firstLoad) return;
      getDocs(currentQuery).then((snapshot) => {
        const lastV = snapshot.docs[snapshot.docs.length - 1];
        setLastVisible(lastV);

        const firstV = snapshot.docs[0];
        setFirstVisible(firstV);

        const next = query(
            collection(db, `productCatalog/${orgData}/products`),
            orderBy("timestamp.modified", "desc"),
            startAt(lastV),
            limit(pageLimit)
        );
        setNextQuery(next);

        /** TODO - prev query returns to the start of the results except for first press */
        const prev = query(
            collection(db, `productCatalog/${orgData}/products`),
            orderBy("timestamp.modified", "desc"),
            endAt(firstV),
            limit(pageLimit)
        );
        setPrevQuery(prev);

        // process table data
        const tableData = snapshot.docs.map((doc) => {
            let temp = doc.data();
            temp.ref = doc.ref;
            return temp;
        });
        setTableData(() => []);
        setTableData(() => tableData);
        
        return () => snapshot;
      })
        
    }, [pageLimit])
    
    async function searchForTag(e, tag) {
        e.preventDefault();
        try {
            const productsReference = collection(db, `productCatalog/${orgData}/products`);
            const c = query(
                productsReference,
                where("tags", "array-contains", tag),
            )
            const q = query(
                productsReference,
                where("tags", "array-contains", tag),
                limit(pageLimit)
            )
            const count = await getCountFromServer(c);
            setQueryResultCount(count.data().count);
            const resultsRaw = await getDocs(q);
            const results = resultsRaw.docs.map((doc) => {
                let temp = doc.data();
                temp.ref = doc.ref;
                return temp;
            });
            updateTableData(results);
        } catch (error) {
            console.log(error)
        }
    }

    function updateTableData(newTableData) {
        setTableData(() => newTableData);
    }

    async function executeFirebaseQuery(firebaseQuery, pageLimit) {
        setCurrentQuery(firebaseQuery);
        try {
            setFirebaseQueryExecuting(true);
            const snapshot = await getDocs(firebaseQuery);

            const lastV = snapshot.docs[snapshot.docs.length - 1];
            setLastVisible(lastV);

            const firstV = snapshot.docs[0];
            setFirstVisible(firstV);

            const next = query(
                collection(db, `productCatalog/${orgData}/products`),
                orderBy("timestamp.modified", "desc"),
                startAt(lastV),
                limit(pageLimit)
            );
            setNextQuery(next);

            /** TODO - prev query returns to the start of the results except for first press */
            const prev = query(
                collection(db, `productCatalog/${orgData}/products`),
                orderBy("timestamp.modified", "desc"),
                endAt(firstV),
                limit(pageLimit)
            );
            setPrevQuery(prev);
            
            // process table data
            const tableData = snapshot.docs.map((doc) => doc.data());
            setTableData(() => tableData);

            setFirebaseQueryExecuting(false);
        } catch (error) {
            console.log(error)
        }
    }
    
    const QueryBuilder = () => {
        
        return (
            <div>
                <Row className='mb-3'>
                    <Col>
                            
                            <Form onSubmit={(e) => {searchForTag(e, document.getElementById('tagSearch').value)}}>
                                <InputGroup >
                                    <InputGroup.Text> Search by Tag </InputGroup.Text>
                                    <FormControl
                                        placeholder="Search by Tag"
                                        aria-label="Search by Tag"
                                        aria-describedby="basic-addon2"
                                        id='tagSearch'
                                    />
                                </InputGroup>
                            </Form>
                        
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col>
                        <Button className="float-end" size='sm' variant='falcon-primary' onClick={(e) => {searchForTag(e, document.getElementById('tagSearch').value)}}>Update</Button>
                    </Col>
                </Row>
            </div>
        )
    }

    async function manageBulkAction(action, selections) {
        switch (action) {
            case 'changeTags':
                setModalSelections(selections);
                setShowTagsModal(true);
                break;
            default:
                break;
        }

    }

    function BulAction({ selectedRowIds, page }) {
        
        const selections = [];
        for (const [key, value] of Object.entries(selectedRowIds)) {
            //console.log(page[key].original);
            selections.push(page[key].original);
        }

        return (
          <Row className="flex-between-center mb-2">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                {
                  Object.keys(selectedRowIds).length > 0 ?
                  'You have selected ' + Object.keys(selectedRowIds).length + ' rows' 
                  :
                  'Search Results'
                }
              </h5>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
              {Object.keys(selectedRowIds).length > 0 ? (
                <div className="d-flex">
                  <Form.Select size="sm" aria-label="Bulk actions" id='bulkSelection'>
                    <option>Bulk Actions</option>
                    <option value="changeTags">Change Tags</option>
                  </Form.Select>
                  <Button
                    type="button"
                    variant="falcon-default"
                    size="sm"
                    className="ms-2"
                    onClick={() => {manageBulkAction(document.getElementById('bulkSelection').value, selections)}}
                  >
                    Apply
                  </Button>
                </div>
                ) : (
                  <>

                  </>
                )}
            </Col>
          </Row>
        );
      };

    const ChangeTagsModal = () => {

        const handleClose = () => {
            setShowTagsModal(false)
            setModalSelections([]);
        };
        const tagSelections = modalSelections.map((selection) => selection);
        const changingCoung = tagSelections.length;
        const tagListRaw = tagSelections.map((selection) => selection.tags);
        const uniqueTags = [...new Set(tagListRaw.flat())];
        
        return (
            <Modal show={showTagsModal} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header>
              <Modal.Title>Change Product Tags</Modal.Title>
              <FalconCloseButton onClick={handleClose}/>
            </Modal.Header>
            <Modal.Body>
                <div className="fw-semi-bold fs-0 mb-3">
                    You are modifing {changingCoung} products. Click the tags you wish to remove.
                </div>
                <p>
                    {uniqueTags.map((tag, index) => {
                        return (
                            <Button key={`btnTag${index}`} variant='falcon-default' className='m-2' onClick={() => handleTagChange('remove', tag, tagSelections)}>{tag}</Button>
                        )
                    })}
                </p>
                <div className="fw-semi-bold fs-0 mb-3">
                   Or enter a new tag for the selections below:
                </div>
                <div>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        handleTagChange('add', document.getElementById('newTag').value, tagSelections);
                        setShowTagsModal(false);
                        setModalSelections([]);
                    }}>
                        <InputGroup >
                            <InputGroup.Text> New Tag </InputGroup.Text>
                            <FormControl
                                placeholder="New Tag"
                                aria-label="New Tag"
                                aria-describedby="basic-addon2"
                                id='newTag'
                            />
                        </InputGroup>
                    </Form>
                </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="primary">Save Tag Changes</Button>
            </Modal.Footer>
          </Modal>
        )
    }
    
    function removeValueFromArray(array, value) {
        const index = array.indexOf(value);
        if (index > -1) {
            array.splice(index, 1);
        }
        return array;
    }

    function addValueToArray(array, value) {
        array.push(value);
        return array;
    }

    async function handleTagChange(state, tag, selections) {
        const selectionsCount = selections.length;
        switch (state) {
            case 'add':
                try {
                    for (const selection of selections) {
                        const oldTags = selection.tags;
                        const newTagArray = addValueToArray(oldTags, tag);
                        await updateDoc(selection.ref, {tags: newTagArray});
                    }
                    toast.success(`${tag} added to ${selectionsCount} products`, {
                        theme: 'colored'
                    });
                } catch (error) {
                    toast.error(`Error ${tag} not added to ${selectionsCount} products`, {
                        theme: 'colored'
                    });
                    console.log(error)
                }
            break;
            case 'remove':
                try {
                    for (const selection of selections) {
                        const oldTags = selection.tags;
                        const newTagArray = removeValueFromArray(oldTags, tag);
                        await updateDoc(selection.ref, {tags: newTagArray});
                    }
                    toast.success(`${tag} removed from ${selectionsCount} products`, {
                        theme: 'colored'
                    });
                } catch (error) {
                    toast.error(`Error ${tag} not removed from ${selectionsCount} products`, {
                        theme: 'colored'
                    });
                    console.log(error)
                }
            break;
            default:
            break;
        }
        setModalSelections([]);
        setShowTagsModal(false);
    }

    return (
        <>
        <Container fluid>
            <PageHeader
                title="Bulk Editor" titleTag="h5" className="mb-3">
              <div className='mt-3'>
                <Accordion flush>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header >Query Editor</Accordion.Header>
                        <Accordion.Body>
                            <QueryBuilder />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
              </div>
            </PageHeader>
            <Card className="p-3">
                <AdvanceTableWrapper
                    columns={bulkEditorColumns}
                    data={tableData}
                    sortable
                    pagination
                    perPage={1000}
                    selection
                    selectionColumnWidth={10}
                    >
                    <div className="">
                        <Row className="flex-end-center mb-3">
                            <Col>
                                <AdvanceTableSearchBox table newPlaceholder="Find in results" />
                            </Col>
                        </Row>
                        
                        <Row className="flex-end-center mt-1 mb-2">
                            <Col>
                                <p className='mb-0 fs--1'>
                                    Viewing {tableData.length} of {queryResultCount} results
                                </p>
                            </Col>
                            <Col>
                                <Flex>
                                    <p className="mb-0 mx-2 fs--1">Rows per page:</p>
                                    <Form.Select
                                    size="sm"
                                    className="w-auto"
                                    onChange={e => setPageLimit(e.target.value)}
                                    defaultValue={pageLimit}
                                    >
                                    {rowsPerPageOptions.map(value => (
                                        <option value={value} key={value}>
                                        {value}
                                        </option>
                                    ))}
                                    </Form.Select>
                                </Flex>
                            </Col>
                            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                                <Button size='sm' variant='falcon-primary'
                                    onClick={() => {executeFirebaseQuery(prevQuery, pageLimit)}}
                                    disabled={prevQuery === null || firebaseQueryExecuting}
                                >Previous</Button>
                                <Button size='sm' variant='falcon-primary' className='ms-3'
                                    onClick={() => {executeFirebaseQuery(nextQuery, pageLimit)}}
                                    disabled={firebaseQueryExecuting}
                                >Next</Button>
                            </Col>
                            
                        </Row>
                    </div>
                    <BulAction table/>
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            bordered: false,
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                    <Row className='mt-3'>
                        <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                            <Button size='sm' variant='falcon-primary'
                                onClick={() => {executeFirebaseQuery(prevQuery, pageLimit)}}
                                disabled={prevQuery === null || firebaseQueryExecuting}
                            >Previous</Button>
                            <Button size='sm' variant='falcon-primary' className='ms-3'
                                onClick={() => {executeFirebaseQuery(nextQuery, pageLimit)}}
                                disabled={firebaseQueryExecuting}
                            >Next</Button>
                        </Col>
                    </Row>
                    

                </AdvanceTableWrapper>
            </Card>
        </Container>
        <ChangeTagsModal />
        </>
        
    )
}


export default BulkEditor;

/**
 * 
 * <div>
          <Row className="align-items-center">
            <Col>
                <Lottie animationData={dotsAnimation} style={{height: 300}} />
            </Col>
          </Row>
    </div>
    <div className="m-3">
                        <AdvanceTablePagination table />
    </div>
    <div id="orders-actions">
                    <IconButton
                      variant="falcon-default"
                      size="sm"
                      icon="plus"
                      transform="shrink-3"
                      className='me-2'
                    >
                      <span className="d-none d-sm-inline-block ms-1">New</span>
                    </IconButton>
                    <IconButton
                      variant="falcon-default"
                      size="sm"
                      icon="external-link-alt"
                      transform="shrink-3"
                    >
                      <span className="d-none d-sm-inline-block ms-1">Export</span>
                    </IconButton>
    </div>
    <Row className="flex-center mt-3 mb-3">
                        <Col xs={12} sm={6} md={4} lg={3} xl={2}>
                            <AdvanceTablePagination table />
                        </Col>
                    </Row>
 */