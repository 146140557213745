import { useEffect } from "react";
import { useNavigate} from "react-router-dom";
import { db,  auth } from "config/firebase"
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { collection, query, where, limit, updateDoc, doc, addDoc } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as fullHeart} from '@fortawesome/free-solid-svg-icons'
import { faHeart as emptyHeart } from '@fortawesome/free-regular-svg-icons';
import { toast } from 'react-toastify';
import { Button } from "react-bootstrap";
import MissingImage from "assets/merlin/missing_image.png";

const FavoriteHeart = ({ product, fbRef }) => {
    const sku = product.sku;
    const name = product.name;
    const vendor = product.vendor;
    let img;
    if (product.files?.length > 0) {
      img = product?.files[0]?.src || MissingImage;
    } else {
      img = MissingImage;
    }

    const [user, authLoading] = useAuthState(auth);
    const navigate = useNavigate();
    const [favorite, loading, error, snapshot] = useCollectionData(
        query(
            collection(db, "favorites"),
            where("uid", "==", user.uid),
            where("sku", "==", sku),
            limit(1)
        ), {
            snapshotListenOptions: { includeMetadataChanges: false },
        }
    );
    // document id = snapshot.docs[0].id
    
    useEffect(() => {
      if (!authLoading) return;
      if (!user) return navigate("/");

      toast.error(error)
    }, [user, authLoading, error])

    /** TODO - must update Link below after migration */
    async function handleFavorite(favoriteId, status) {
        try {
          // If there is no favoriteId then create a new record
          if (!favoriteId) {
            return await addDoc(collection(db, "favorites"), {
                status: true,
                sku: sku,
                lastUpdate: new Date(),
                uid: user.uid,
                link: `new/products/${vendor}/${fbRef}`,
                name: name,
                img: img,
            })
          } else {
            const favReference = doc(db, "favorites", favoriteId);
            return await updateDoc(favReference, {
              status: !status,
              lastUpdate: new Date(),
              link: `new/products/${vendor}/${fbRef}`,
              name: name,
              img: img,
            });
          }
        } catch (error) {
          console.log(error);
        }    
    }

    return (
        <>
            {!loading && favorite[0]?.status

                ?   <Button 
                      onClick={() => handleFavorite(snapshot.docs[0]?.id, favorite[0]?.status)}
                      disabled={loading}
                      variant="falcon-danger"
                      size="sm"
                    >
                        <FontAwesomeIcon icon={fullHeart} />
                    </Button> 
                
                :   <Button 
                      onClick={() => handleFavorite(snapshot.docs[0]?.id)}
                      disabled={loading}
                      variant="falcon-danger"
                      size="sm"
                    >
                        <FontAwesomeIcon icon={emptyHeart} />
                    </Button>
            }  
        </>
    )
};

export default FavoriteHeart;