import { useState, useContext, useEffect, useReducer } from 'react';
import { db } from "config/firebase";
import { useNavigate, useParams } from 'react-router-dom';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import PageHeader from 'components/common/PageHeader';
import { Card, Row, Col, Button, Form, Tabs, Tab, Image, ProgressBar, Alert, Modal } from 'react-bootstrap';
import Lottie from 'lottie-react';
import dotsAnimation from 'assets/lottie/dots.json';
import celebrationAnimation from 'assets/img/animated-icons/check-primary-light.json';
import { useDocument } from 'react-firebase-hooks/firestore';
import { doc, updateDoc, serverTimestamp, arrayUnion, addDoc, getDoc, collection, runTransaction} from 'firebase/firestore';
import SoftBadge from 'components/common/SoftBadge';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { toast } from 'react-toastify';

const PickDetail = ({ children }) => {

    const { user, authLoading }= useContext(FirebaseAuthContext)
    const navigate = useNavigate();
    const { pickId } = useParams();

    const [pickDataProcessing, setPickDataProcessing] = useState(false);
    const [pickJobRaw, pickJobLoading, pickJobError] = useDocument(
        doc(db, "pickWizardJobs", pickId)
    );
    const [pickJob, setPickJob] = useState(null);
    useEffect(() => {
        setPickDataProcessing(true);
        if (pickJobLoading) return;
        if (pickJobError) return;
        if (!pickJobRaw) return;
        let pickJob = pickJobRaw.data();
        pickJob.ref = pickJobRaw.ref;
        setPickJob(pickJob);
        setPickDataProcessing(false);
    }, [pickJobLoading, pickJobError, pickJobRaw]);

    // Redirect to home if not logged in
    useEffect(() => {
        if (authLoading) return;
        if (!user) return navigate('/');
      }, [authLoading, user])

    const [pickState, updatePickState] = useState('NOT STARTED');
    useEffect(() => {
        if (pickJobLoading) return;
        if (pickJobError) return;
        if (!pickJobRaw) return;
        if (!pickJob) return;
        if (pickJob.status !== 'NOT STARTED') {
            updatePickState(pickJob.status);
        }
    }, [pickJobLoading, pickJobError, pickJobRaw, pickJob])


    async function progressPickStatus(toBeStatus) {
        if (!toBeStatus) return;
        if (toBeStatus === 'IN PROGRESS') {
            updatePickState('IN PROGRESS');
            await updateDoc(pickJob.ref, {
                status: 'IN PROGRESS',
                startedAt: new Date(),
                startedBy: user.uid
            });
            await saveCurrentPickIndex(0);
            return;
        }
        if (toBeStatus === 'COMPLETED') {
            updatePickState('COMPLETED');
            return;
        }
    }

    async function saveCurrentPickIndex(index) {
        if (!index) return;
        try {
            await updateDoc(pickJob.ref, {
                currentPickIndex: index
            });
        } catch (error) {
            console.log(error);
        }
        
    }

    const ActivePickCard = ({ items }) => {

        const initialIndex = pickJob?.currentPickIndex || 0;
        const [pickIndex, setPickIndex] = useState(initialIndex);
        const [item, setItem] = useState(items[pickIndex]);
        const [progress, setProgress] = useState(0);
        const [startTime, setStartTime] = useState(null);
        const [showIssueModal, setShowIssueModal] = useState(false);

        useEffect(() => {
            let isSubscribed = true;
            if (isSubscribed) setStartTime(new Date());
            return () => isSubscribed = false;
        }, [])

        const getProgress = () => {
            let total = items.length;
            let completed = pickIndex + 1;
            return (completed / total) * 100;
        }

        useEffect(() => {
            let isSubscribed = true;
            if (isSubscribed) {
                setItem(items[pickIndex]);
                setProgress(getProgress());
            }
            return () => isSubscribed = false;
        }, [pickIndex, items])

        async function handleCollected(e, quantity) {
            e.preventDefault();
            const endTime = new Date();
            if (item?.slotData) {
                
                // when item is collected from a non-automatic inventory location
                // reduce the quantity of the item in the slot
                const slotRef = item.slotData.ref;
                try {
                    await runTransaction(db, async (transaction) => {
                        const slotDoc = await transaction.get(slotRef);
                        const slotData = slotDoc.data();
                        const newQuantity = Number(slotData.count) - Number(quantity);
                        transaction.update(slotRef, {count: Number(newQuantity)});
                    });
                    toast.success('Slot Quantity Updated', { theme: 'colored' });
                } catch (error) {
                    console.log(error);
                }
                
            }
            await savePickSplitTime(startTime, endTime);
            await saveCurrentPickIndex(pickIndex + 1);
            setPickIndex(pickIndex + 1);
        }

        async function savePickSplitTime(startTime, endTime) {
            try {
                await updateDoc(pickJob.ref, {
                    splitTimes: arrayUnion({
                        startTime: startTime,
                        endTime: endTime
                    })
                });
            } catch (error) {
                console.log(error);
            }
        }

        function handleIssue(e) {
            e.preventDefault();
            setShowIssueModal(true);
            // saveCurrentPickIndex(pickIndex - 1);
            // setPickIndex(pickIndex - 1);
        }

        async function handlePickFinish(e) {
            try {
                e.preventDefault();
                await updateDoc(pickJob.ref, {
                    status: 'COMPLETED',
                    currentPickIndex: 0,
                    completedAt: new Date(),
                    completedBy: user.uid
                });
                const batchDataDocRef = doc(db, "customBatches", pickJob.batchId);
                const batchDataDoc = await getDoc(batchDataDocRef);
                const batchData = batchDataDoc.data();
                const packRef = await addDoc(collection(db, "packWizardJobs"), {
                    batchName: pickJob.batchName,
                    assignedUser: pickJob.assignedUser,
                    assignedUserUid: pickJob.assignedUserUid,
                    assignedUserEmail: pickJob.assignedUserEmail || 'None',
                    assignedUserPhone: pickJob.assignedUserPhone || 'None',
                    orders: batchData.orders,
                    status: 'NOT STARTED',
                    createdAt: serverTimestamp(),
                    createdBy: user.uid
                });
                await updateDoc(batchDataDocRef, {
                    status: 'PACKING',
                    'timestamp.modified': serverTimestamp(),
                });
                await addDoc(collection(db, "outgoingMail"), {
                    to: ['greg@meetmarty.com'],
                    message: {
                        subject: 'New Pack Job',
                        text: `A new pack job has been created for ${pickJob.batchName}. \n\n Assigned to ${pickJob.assignedUser} \n\n localhost:3000/pack/${packRef?.id}`,
                    }
                });
                toast.success('Pick Job Completed', { theme: 'colored' });
            } catch (error) {
                console.log(error);
            }
        }

        const IssueModal = ({ show, setShow }) => {

            const handleClose = () => setShow(false);
            const [showOtherForm, setShowOtherForm] = useState(false);

            async function recordIssue(issue, data) {
                if (!issue || !data) return;
                try {
                    await updateDoc(pickJob.ref, {
                        issues: arrayUnion({
                            issue: issue,
                            data: data
                        })
                    });
                } catch (error) {
                    console.log(error);
                }
            }

            async function handleNamedIssue(issue) {
                if (!issue) return;
                switch (issue) {
                    case 'Custom':
                        let customIssueText = document.getElementById('customIssueText').value;
                        if (!customIssueText) return;
                        await recordIssue(customIssueText, item);
                        setShow(false);
                        toast.success('Custom Issue Recorded', { theme: 'colored' });
                        break;
                    default:
                        await recordIssue(issue, item);
                        setShow(false);
                        toast.success('Issue Recorded', { theme: 'colored' });
                        break;
                }
                const endTime = new Date();
                await savePickSplitTime(startTime, endTime);
                await saveCurrentPickIndex(pickIndex + 1);
                setPickIndex(pickIndex + 1);
                toast.info('Next Pick Ready', { theme: 'colored' });
            }

            return (
                <Modal
                    size="sm"
                    show={show}
                    onHide={() => handleClose()}
                >
                    <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Pick Issue
                    </Modal.Title>
                    <FalconCloseButton onClick={() => handleClose()}/>
                    </Modal.Header>
                    <Modal.Body>
                    <div className="d-grid gap-2">
                        {!showOtherForm &&
                            <Button variant="danger" onClick={() => handleNamedIssue('Not In Slot')}>
                            Not In Slot
                            </Button>
                        }
                        {!showOtherForm &&
                            <Button variant="warning" onClick={() => handleNamedIssue('Not Enough Stock')}>
                            Not Enough Stock
                            </Button>
                        }
                        {!showOtherForm &&
                            <Button variant="secondary" onClick={() => setShowOtherForm(true)}>
                            Other Issue
                            </Button>
                        }
                        {showOtherForm &&
                            <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Issue Description</Form.Label>
                                    <Form.Control as="textarea" rows={3} id="customIssueText" />
                                </Form.Group>
                                <Button variant="danger" onClick={() => handleNamedIssue('Custom')}>
                                    Submit
                                </Button>
                            </Form>
                        }
                        
                    </div>
                    </Modal.Body>
                </Modal>
            )
        }

        return (
            <>
            <IssueModal show={showIssueModal} setShow={setShowIssueModal} />
            <Card
                bg='primary'
                text='white'
                className="mb-2"
            >
                <Card.Body>
                    <Row className='mb-3'>
                        <Col>
                            <ProgressBar now={progress} variant='success'/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h5 className='fw-bold text-white'>Item {pickIndex + 1} of {items.length}</h5>
                        </Col>
                        <Col>
                            <h5 className='fw-bold text-white'>{item?.parentSku}</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h5 className='fw-bold text-white'>Location</h5>
                        </Col>
                        <Col>
                            <h5 className='fw-bold text-white'>{item?.slot || 'None!'}</h5>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card>
                <Card.Body>
                    <Row>
                        <Col className='text-center'>
                            <h5 className='fw-bold'>{item?.name || 'Name Missing'}</h5>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col className='text-center'>
                            <Image src={item?.image} style={{maxHeight: '200px', maxWidth: '200px'}}/>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col className='text-center'>
                            {item?.bundleComponent &&
                                <SoftBadge pill bg='info'>Bundle Component</SoftBadge> 
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Alert
                                variant='primary'
                            >
                                <Row>
                                    <Col>
                                        <h5 className='fw-bold'>Collect</h5>
                                    </Col>
                                    <Col>
                                        <h5 className='fw-bold'>{item?.quantity}</h5>
                                    </Col>
                                </Row>
                            </Alert>
                            
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='float-start'>
                                <Button variant="outline-danger" className="me-2" size='lg'
                                    onClick={(e) => handleIssue(e)}
                                >Issue</Button>
                            </div>
                            <div className='float-end'>
                                {pickIndex !== (items.length - 1) ?
                                    <Button variant="success" className="me-2" size='lg'
                                    onClick={(e) => handleCollected(e, item?.quantity)}
                                    disabled={pickIndex === items.length - 1}
                                    >Collect</Button>
                                :   <Button variant="success" className="me-2" size='lg'
                                    onClick={(e) => handlePickFinish(e)}
                                    >Finish Pick</Button>
                                }
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            </>
        )
    }

    return (
        !pickDataProcessing ?
        <>
            
            {pickState !== 'IN PROGRESS' ?
                <PageHeader title={pickJob?.batchName || "Loading"} className={'mb-3'}>
                <Row className='mt-3'>
                    <Col>
                        <SoftBadge variant={
                            pickJob?.status === 'NOT STARTED' ? 'primary'
                            : pickJob?.status === 'IN PROGRESS' ? 'success'
                            : 'secondary' } className='me-2'>{pickJob?.status}</SoftBadge>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col className=''>
                        <span className='fw-bold text-muted'>
                            Assigned To: {pickJob?.assignedUser}, {pickJob?.items?.length} items
                        </span>
                    </Col>
                </Row>
                
                </PageHeader>
            : null}
            {pickState === 'NOT STARTED' ?
                <Card>
                    <Card.Body>
                        <Row>
                            <Col>
                                {pickState === 'NOT STARTED' ?
                                    <Button variant="success" className="me-2" size='lg'
                                        onClick={() => progressPickStatus('IN PROGRESS')}
                                    >Start Pick Job</Button>
                                : pickState === 'IN PROGRESS' ?
                                    <Button variant="success" className="me-2" size='lg'
                                        onClick={() => progressPickStatus('IN PROGRESS')}
                                    >Continue Pick Job</Button>
                                : pickState === 'COMPLETED' ?
                                    <Button variant="primary" className="me-2" size='lg'

                                    >View Pick Job</Button>
                                : null
                                }
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            : null}
            
            {pickState === 'IN PROGRESS' ?
                <ActivePickCard items={pickJob?.items} />
            : null}
            {pickState === 'COMPLETED' ?
                <Card>
                    <Card.Body>
                        <Row>
                            <Col>
                                <h5 className='fw-bold'>Pick Job Completed</h5>
                            </Col>
                        </Row>
                        <div className='text-center'>
                            <Lottie animationData={celebrationAnimation} style={{width: 200 }} />
                        </div>
                        <Row>
                            <Col>
                                <div className='float-start'>
                                    <Button variant="outline-primary" className="me-2" size='lg'
                                        onClick={() => navigate('/fulfillment/pick/')}
                                    >Back to Pick Jobs</Button>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            : null
            }
        </>
        :
        <Lottie animationData={dotsAnimation} style={{width: 100, height: 100}} />
    );
}

export default PickDetail;