import { shipStationStores } from "components/pages/newOrders/context/shipStationStores";
import AmazonLogo from "assets/img/logos/AmazonAlt.png";
import WalmartLogo from "assets/img/logos/walmart.png";
import EbayLogo from "assets/img/logos/ebay.png";
import StkLogo from "assets/img/logos/stk.png";
import ShopifyLogo from "assets/img/logos/shopify.png";
import { Image } from "react-bootstrap";
import { OverlayTrigger, Popover } from "react-bootstrap";

const ChannelIcon = ({ channel }) => {
    switch (channel) {
        case 'Amazon US':
            return  <>
                        <Image src={AmazonLogo} alt="Amazon US"  height={20} /> 🇺🇸
                    </>;
        case 'Amazon CA':
            return  <>
                        <Image src={AmazonLogo} alt="Amazon CA" height="20" /> 🇨🇦
                    </>;
        case 'Walmart US':
            return <>
                        <Image src={WalmartLogo} alt="Walmart US" height="20" /> 🇺🇸
                    </>;
        case 'Shop The King':
            return <>
                        <Image src={StkLogo} alt="Shop the King" height="30" />
                    </>;
        case 'The Calm Caterpillar':
            const randomKey = Math.random().toString(36).substring(7);
            return <>
                        <OverlayTrigger
                            trigger="click"
                            key={randomKey}
                            placement="top"
                            overlay={
                                <Popover id={`${randomKey}-pop-id`}>
                                    <Popover.Body>
                                        The Calm Caterpillar
                                    </Popover.Body>
                                </Popover>
                            }
                            >
                                <Image src={ShopifyLogo} alt="The Calm Caterpillar" height="20" />
                        </OverlayTrigger>
                    </>;
        case 'eBay':
            return <>
                        <Image src={EbayLogo} alt="eBay" height="20" />
                    </>;
        default:
            return <>{ channel }</>;
    }
}

const OrderSourceRenderer = (params) => {
    const { data } = params;
    const { fromStoreId } = data || {};
    if (fromStoreId) {
        const store = shipStationStores.data[fromStoreId];
        const storeName = store ? store?.storeName : 'Unknown';
        return (
            <div>
                <div className="fs--2"><ChannelIcon channel={storeName} /></div>
            </div>
        );
    } else {
        return (
            <div>
                <div className="fs--2">Unknown</div>
            </div>
        );
    }
    
    
};

export default OrderSourceRenderer;