import { Badge } from 'react-bootstrap';

const OrderStatusRenderer = ({ value }) => {
    // possible values awaiting_payment, awaiting_shipment, shipped, on_hold, cancelled, pending_fulfillment, fulfilled
    const status = value;
    let statusMessage = 'Unknown';
    let statusClass = 'fs--2 ';
    switch (status) {
        case 'awaiting_payment':
            statusClass = 'text-warning';
            statusMessage = 'Awaiting Payment';
            break;
        case 'awaiting_shipment':
            statusClass = 'text-warning';
            statusMessage = 'Awaiting Shipment';
            break;
        case 'shipped':
            statusClass = 'text-success';
            statusMessage = 'Shipped';
            break;
        case 'on_hold':
            statusClass = 'text-danger';
            statusMessage = 'On Hold';
            break;
        case 'cancelled':
            statusClass = 'text-danger';
            statusMessage = 'Cancelled';
            break;
        case 'pending_fulfillment':
            statusClass = 'text-warning';
            statusMessage = 'Pending Fulfillment';
            break;
        case 'fulfilled':
            statusClass = 'text-success';
            statusMessage = 'Fulfilled';
            break;
        default:
            statusClass = 'text-danger';
            statusMessage = 'Unknown';
            break;
    }
    return <Badge pill bg="light" className={statusClass}>{statusMessage}</Badge>
};

export default OrderStatusRenderer;