import { db } from 'config/firebase';
import { useEffect, useState, useRef} from 'react';
import { useHttpsCallable } from 'react-firebase-hooks/functions';
import { functions } from 'config/firebase';
import Lottie from 'lottie-react';
import dotsAnimation from 'assets/lottie/dots.json';
import { addDoc, updateDoc, collection, doc } from 'firebase/firestore';

export default function MerlinGpt({ product }) {
  const [chatGpt, chatExecuting, chatError] = useHttpsCallable(functions, 'merlinGpt');
  const additionalMessages = [{role: 'system', content: 'MerlinGPT is in beta and may be rate-limited in the future. To begin, please ask a question about your product.'}];
  
  if(chatError) console.log(chatError);


  return (
    <>
      <ChatWindow product={product} initialMessages={additionalMessages} chatGpt={chatGpt} chatExecuting={chatExecuting}/>
    </>
  );
}

function ChatWindow({ product, initialMessages, chatGpt, chatExecuting }) {
  const [messages, setMessages] = useState(initialMessages);
  const [saveDocRef, setSaveDocRef] = useState(null);

  useEffect(() => {
    // set the initial save location
    addDoc(collection(db, "merlinGpt"), {
      product: product}).then((docRef) => {
        setSaveDocRef(docRef);
      }).catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSendMessage = async (newMessage) => {
    setMessages((prev) => [...prev, newMessage]);
    const reqData = {product: product, additionalMessages: [newMessage]}
    const result = await chatGpt(reqData);
    setMessages((prev) => [...prev, {"role": "assistant", "content": `${result.data.choices[0].message.content}`}]);
    // save the messages to firebase
    await updateDoc(saveDocRef, {
      messages: [...messages, {"role": "assistant", "content": `${result.data.choices[0].message.content}`}]
    });

  };
  
  const lottieStyle = {
    alignSelf: 'flex-start',
    height: 100
  }

  return (
    <div>
      <MessageList messages={messages} />
      {chatExecuting && <div style={{display: 'flex'}}><Lottie style={lottieStyle} animationData={dotsAnimation} /></div> }
      <ChatInput onSendMessage={handleSendMessage} />
    </div>
  );
}

function ChatInput({ onSendMessage }) {
  const [newMessage, setNewMessage] = useState('');
  const handleInputChange = (e) => setNewMessage(e.target.value);
  const handleSend = () => {
    if(newMessage.trim() !== '') {
      onSendMessage({role: 'user', content: newMessage.trim()});
      setNewMessage('');
    }
  };
  const handleKeyDown = (e) => { if(e.key === 'Enter') handleSend(); };

  const inputStyle = {
    flex: 1,
    borderRadius: '15px',
    border: '1px solid #7E57C2',
    padding: '5px 10px',
    marginRight: '10px',
  };

  const buttonStyle = {
    backgroundColor: '#7E57C2',
    color: '#fff',
    border: 'none',
    padding: '5px 15px',
    borderRadius: '15px',
    cursor: 'pointer',
  };

  const chatInputStyle = {
    display: 'flex',
    padding: '10px',
  };

  return (
    <div style={chatInputStyle}>
      <input 
        type="text"
        style={inputStyle}
        value={newMessage}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Type a message..."
      />
      <button style={buttonStyle} onClick={handleSend}>Send</button>
    </div>
  );
}

function MessageList({ messages }) {
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  useEffect(scrollToBottom, [messages]);

  const messageListStyle = {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    marginBottom: '10px',
  };

  return (
    <div style={messageListStyle}>
      {messages.map((message, i) => <Message key={i} message={message} />)}
      <div ref={messagesEndRef} />
    </div>
  );
}

function Message({ message }) {
  const { role, content } = message;
  let messageStyle = { padding: '10px', margin: '5px 0', borderRadius: '10px', color: 'white', whiteSpace: 'pre-line'};

  if (role === 'system') messageStyle = {...messageStyle, width: '100%', backgroundColor: '#B0BEC5', color: 'black'};
  if (role === 'assistant') messageStyle = {...messageStyle, width: '90%', backgroundColor: '#26A69A', alignSelf: 'flex-start'};
  if (role === 'user') messageStyle = {...messageStyle, width: '90%', backgroundColor: '#7E57C2', alignSelf: 'flex-end'};

  return <div style={messageStyle}>{content}</div>;
}
