import React, { useState, useEffect, useCallback } from 'react';
import useSearchData from 'hooks/useSearchData';
import { debounce, set } from 'lodash';
import { Form } from 'react-bootstrap';
import * as fuzz from 'fuzzball';
import { db } from 'config/firebase';
import { collectionGroup, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
import { useSkuList } from 'components/pages/newProducts/context/SkuListContext';
import Lottie from "lottie-react";
import dotsAnimation from 'assets/lottie/dots.json';

export const ProductSearchBar = ({ fetchLoading, gridRef, tabController, searchTabId }) => {

    // if (!setSkuList) throw new Error('setSkuList is required');

    const { _, setSkuList } = useSkuList();
    const [searchTerm, setSearchTerm] = useState('');
    const [choiceData, choicesLoading, choicesError, excludeDisc, message] = useSearchData(/** Data Expired */ false);
    const [choiceLibrary, setChoiceLibrary] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    
    // Load the fuzzy search data and build a list of result skus
    // for later Firebase queries
    useEffect(() => {
        
        if (choicesLoading) return;
        if (choicesError) return;
        if (choiceData?.length > 0) {
            setChoiceLibrary(choiceData);
        }
    }, [choiceData, choicesLoading, choicesError])  
    
    // Update the skuList when the searchTerm changes
    useEffect(() => {
        if (choicesLoading) return;
        if (!searchTerm) return;
        if (searchTerm.length > 2) {
          setSearchLoading(true);
          searchProducts(searchTerm).then((results) => {

            const internalResults = [];
            if (results.fuzzy) {
                for (const result of results.fuzzy) {
                    internalResults.push({
                        sku: result[0].sku,
                        score: result[1],
                        source: 'fuzzy'
                    });
                }
            }
            
            if (results.exactSku) {
                for (const result of results.exactSku) {
                    if (result.sku) {
                    internalResults.push({
                            sku: result.sku,
                            score: 100,
                            source: 'exactSku'
                        });
                    }
                }
            }

            let allSkus = [];
            if (internalResults) allSkus = internalResults.map((result) => result.sku);

            return {allSkus, internalResults, results};
            
            }).then( (data) => {
                setSkuList(data.internalResults);
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setSearchLoading(false);
            });
        } else {
            setSkuList([]);
            setSearchLoading(false);

        }
    }, [searchTerm]);

    /** Search Functions  */
    const debounceSearch = useCallback(debounce((searchTerm) => {
        setSearchTerm(searchTerm);
    }, 350), []);

    function handleSearch(event) {
        debounceSearch(event.target.value);
    }

    async function searchProducts(searchTerm) {
        try {
            const searchPromises = [];
            searchPromises.push(fuzzySearch(searchTerm));
            searchPromises.push(searchExactSku(searchTerm));
            const [fuzzy, exactSku] = await Promise.all(searchPromises);
            const results = {
                fuzzy,
                exactSku
            }
            return results;
        } catch (error) {
            console.log(error);
        }
    }

    async function searchExactSku(searchTerm) {
        try {
            const q = query(collectionGroup(db, 'variants'), where('sku', '==', searchTerm));
            const snapshot = await getDocs(q);
            const results = [];
            if (snapshot.empty) return results;
            snapshot.forEach((doc) => {
                results.push(doc.data());
            })
            return results;
        } catch (error) {
            console.log(error);
        }
    }

    async function fuzzySearch(searchTerm) {
        try {
            
            const options = {
              scorer: fuzz.partial_ratio,
              processor: (choice) => choice.proc_sorted,
              cutoff: 75,
              unsorted: false,
              full_process: false,
            }

            const permutations = permutePhrase(searchTerm);
            const lib = choiceLibrary.filter((item) => item !== undefined);

            const permutationResults = permutations.map((term) => fuzz.extract(term, lib, options));
            const flatResults = permutationResults.flat();

            // remove duplicates
            const uniqueResults = [];
            const seen = new Set();
            for (const result of flatResults) {
                if (result && !seen.has(result[0].sku)) {
                    uniqueResults.push(result);
                    seen.add(result[0].sku);
                }
            }

            // sort by score
            uniqueResults.sort((a, b) => b[1] - a[1]);

            // remove scores
            const results = uniqueResults; //.map((result) => result[0]);
      
            return results;
          } catch (error) {
            console.log(error);
          }
    }

    function permutePhrase(searchPhrase) {
        const words = searchPhrase.split(' ');
        // remove any words that are part of the stop words list
        const filteredWords = words.filter((word) => !STOP_WORDS.includes(word));
        const permutations = [];
        for (let i = 0; i < words.length; i++) {
            const swapped = [...words];
            [swapped[i], swapped[i + 1]] = [swapped[i + 1], swapped[i]];
            permutations.push(swapped.join(' '));
        }
        // permutations.push(filteredWords.join(' '));
        filteredWords.map((word) => permutations.push(word));
        permutations.unshift(searchPhrase);
        return permutations;
    }

    const STOP_WORDS = [
        'a',
        'an',
        'and',
        'are',
        'as',
        'at',
        'be',
        'but',
        'by',
        'for',
        'if',
        'in',
        'into',
        'is',
        'it',
        'no',
        'not',
        'of',
        'on',
        'or',
        'such',
        'that',
        'the',
        'their',
        'then',
        'there',
        'these',
        'they',
        'this',
        'to',
        'was',
        'will',
        'with',
        'about',
        'above',
        'across',
        'after',
        'against',
        'along',
        'among', 
        'around',
        'as',
        'at',
        'before',
        'behind',
        'below',
        'beneath',
        'beside', 
        'between', 'beyond', 'but', 'by', 'concerning', 'considering', 'despite', 
  'down', 'during', 'except', 'for', 'from', 'in', 'inside', 'into', 'like', 
  'near', 'of', 'off', 'on', 'onto', 'out', 'outside', 'over', 'past', 
  'regarding', 'round', 'since', 'through', 'to', 'toward', 'under', 
  'underneath', 'until', 'up', 'upon', 'with', 'within', 'without', 'and', 
  'or', 'the', 'a', 'an', 'so', 'yet', 'but'
    ]

    const handleFocus = () => {
        if (gridRef.current) {
            gridRef.current.api.showLoadingOverlay();
            gridRef.current.api.setRowData([]);
            tabController?.focusOnSearchTab(searchTabId);
            gridRef.current.api.hideOverlay();
        }        
    }


    return (
        <div style={{ position: 'relative' }}>
            <Form.Control
                type="text"
                placeholder={choicesLoading ? 'Loading...' : 'Search for a product...'}
                onChange={handleSearch}
                onFocus={handleFocus}
                style={{
                    height: '25px',
                    borderRadius: '15px',
                    paddingRight: '50px' // Adjust the padding to avoid overlapping text with the loader
                }}
                className='w-100 border-0 fs--1 ms-2'
            />
        </div>

    )
}

/**
 * 
 */

