// System Imports
import React, {
    useState,
    useRef,
    useCallback,
    useContext,
    useMemo,
    useEffect,
    forwardRef,
    useImperativeHandle
} from 'react';
import { useNavigate } from 'react-router-dom';

// Firebase
import { db, realtime } from 'config/firebase';
import { ref as storageRef, set as storageSet } from 'firebase/database';
import {
    collection,
    query,
    getDocs,
    where,
    doc,
    updateDoc,
    collectionGroup,
    addDoc,
    serverTimestamp,
    onSnapshot,
    getDoc,
} from 'firebase/firestore';

// AG-Grid
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { LicenseManager } from  'ag-grid-enterprise'    


// Styling
import { Card, Col, Row, Form, Badge, Button, Image, Modal, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import { toast } from 'react-toastify';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/merlin/advance-table/AdvanceTablePagination';

// Lottie Animations
import Lottie from "lottie-react";
import dotsAnimation from 'assets/lottie/dots.json';

// Page Components
import { ProductSearchBar } from 'components/pages/newProducts/components/ProductSearchBar';
import { TabContainer } from 'components/pages/newProducts/components/TabContainer';
import { MenuBar } from 'components/pages/newProducts/components/MenuBar';

// Modals 
import { ManageShippingTemplatesModal } from 'components/pages/newProducts/modals/ManageShippingTemplatesModal';
import { ManagePricingTemplatesModal } from 'components/pages/newProducts/modals/ManagePricingTemplatesModal';
import { AsinDetailModal } from 'components/pages/newProducts/modals/AsinDetailModal';
import { ConfirmationModal } from 'components/pages/newProducts/modals/ConfirmationModal';
import { AssignTemplateModal } from 'components/pages/newProducts/modals/AssignTemplateModal';
import { BulkTagsModal } from 'components/pages/newProducts/modals/bulkTags';
import { NewListingModal } from 'components/pages/newProducts/modals/newListingModal';
import { OpenWorkspaceModal } from 'components/pages/newProducts/modals/OpenWorkspaceModal';
import { ListingReadinessOffcanvas } from 'components/pages/newProducts/modals/ListingReadinessOffcanvas';

// Icons
import { faAmazon } from '@fortawesome/free-brands-svg-icons';
import PinIcon from 'components/pages/newProducts/components/PinIcon';
import { faLink, faPlay, faHammer, faCheck, faArrowRight, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

// Hooks
import useUserTabs from 'components/pages/newProducts/hooks/useUserTabs';
import useGridState from 'components/pages/newProducts/hooks/useGridState';
import { useSkuList } from 'components/pages/newProducts/context/SkuListContext';
import { useWorkspaceCache } from 'components/pages/newProducts/context/WorkspaceCacheContext';
import useRequestPubSub from 'hooks/useRequestPubSub';
import useKeystroke from 'hooks/useKeystroke';
//import { useSkuList } from 'components/pages/newProducts/context/SkuListContext';

// Context
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import { ActionsContext } from 'context/MenuBarActionsContext';

// File Menu Actions
import { createActivePricerJobs } from 'components/pages/newProducts/logic/activePricerActions';
import { createAsinDiscoveryJobs } from 'components/pages/newProducts/logic/amazonActions';
import { createShippingEstimateJobs } from 'components/pages/newProducts/logic/shipStationActions';
import { createAmazonFeeDiscoveryJobs } from 'components/pages/newProducts/logic/amazonActions';
import { pinAllProducts } from 'components/pages/newProducts/logic/bulkActionFunctions';

// 3rd Party Utils
import { isEqual } from 'lodash';

// TODO Abstract to Secrets Manager
LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-049307 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( ARC Limited )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Merlin )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Merlin )_need_to_be_licensed___( Merlin )_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 16 October 2024 )____[v2]_MTcyOTAzMzIwMDAwMA==aadeefbfe111df847d24eaed4ef57023")

export default function AdvancedProductsGrid() {
    const { user, authLoading }= useContext(FirebaseAuthContext)
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [view] = useState('Products');
    const [selectedData, setSelectedData] = useState([]); // state for selected rows in the data grid
    const [assignmentType, setAssignmentType] = useState('pricing');
    const [selectedVariantData, setSelectedVariantData] = useState(null);
    const abortControllerRef = useRef(null);
    const [clipboard, saveToClipboard] = useState([]);
    // const { _, setSkuList } = useSkuList();
    const [allResults, setAllResults] = useState([]);

    // Search Related State
    const [searchResults, setSearchResults] = useState([]);

    // Custom Hooks
    const { gridRef, onGridReady, onColumnChanged, loadColumnState } = useGridState(user?.uid);
    const [channelStatus, userNotification, establishChannel, triggerRefetch, channelLoading, channelError] = useRequestPubSub();
    const [manualRefetch, setManualRefetch] = useState(false)
    const { skuList, setSkuList } = useSkuList();
    const { tabs, visibleTabs, tabsLoading, tabController } = useUserTabs(user?.uid, 'products');
    const { setCache, getCache, deleteCache } = useWorkspaceCache();

    // Modal State Managers
    const [tagsModalShow, setTagsModalShow] = useState(false);
    const [assignTemplateModalShow, setAssignTemplateModalShow] = useState(false);
    const [asinDetailModalShow, setAsinDetailModalShow] = useState(false);
    const [showManagePricingTemplatesModal, setShowManagePricingTemplatesModal] = useState(false);
    const [showManageShippingTemplatesModal, setShowManageShippingTemplatesModal] = useState(false);
    const [showOpenWorkspaceModal, setShowOpenWorkspaceModal] = useState(false);
    const [showListingReadinessModal, setShowListingReadinessModal] = useState(false);

    // Confirmation Modal Handlers
    const [confirmationModalShow, setConfirmationModalShow] = useState(false);
    const [confirmationModalAction, setConfirmationModalAction] = useState(null);
    const [confirmationModalTitle, setConfirmationModalTitle] = useState(null);
    const [confirmationModalMessage, setConfirmationModalMessage] = useState(null);

    const globalCache = {};
    
    // Redirect to login if not logged in
    useEffect(() => {
        if (authLoading) return;
        if (!user) return navigate('/login');
    }, [authLoading, user])

    // This Establishes the Real Time Messaging Channel for Async Job Updates to the UI
    useEffect(() => {
        if (!user) return
        if (channelLoading) return;
        if (channelError) {
            toast.error(channelError.message, { theme: 'colored' });
            return;
        };
        if (channelStatus === null) establishChannel(user.uid)
    }, [channelStatus, channelLoading, channelError, user]);

    // This will pop a toast with whatever message is received when the channel is triggered
    /**
    useEffect(() => {
        if (channelLoading) return;
        if (!channelStatus || channelStatus === 'idle') return;
        if (userNotification) {
            toast[userNotification.variant](userNotification.message, { theme: 'colored' });
        }
    }, [triggerRefetch])
     */

    // TODO - abstract to CSS file and rebuild CSS
    applyHeaderStyle();
    applyRowStyle();

    const fetchProducts = useCallback(async (complexSkuList) => {

        const cachedProducts = getCache(complexSkuList);


        if (cachedProducts) {
            return cachedProducts;
        }

        // setLoading(true);

        const bigSkuList = complexSkuList.map((searchResult) => searchResult.sku);
        const chunks = chunkArray(bigSkuList, 10);

        const variantPromises = [];
        const parentPromises = [];

        for (const skuList of chunks) {

            // data collection
            const variantsGroupRef = collectionGroup(db, "variants");
            variantPromises.push( getDocs(
                query(variantsGroupRef,
                    where("parentSku", "in", skuList),
                ))
            );
            const parentProductsRef = collection(db, "productCatalog/topicz/products");
            parentPromises.push( getDocs(
                query(parentProductsRef,
                    where("sku", "in", skuList),  
                ))
            );
            
        }

        const variantsSnapshots = await Promise.all(variantPromises);
        const parentProductsSnapshots = await Promise.all(parentPromises);

        // data processing
        const variants = [];
        variantsSnapshots.forEach((variantSnapshot) => {
            variantSnapshot.forEach((doc) => {
                let data = doc.data();
                data.docId = doc.id;
                variants.push(data);
            });
        });

        const parentProducts = [];
        parentProductsSnapshots.forEach((parentProductSnapshot) => {
            parentProductSnapshot.forEach((doc) => {
                let data = doc.data();
                data.docId = doc.id;
                parentProducts.push(data);
            });
        });

        // merge the parentData into the variants array
        for (const variant of variants) {
            for (const parentProduct of parentProducts) {
                if (variant.parentSku === parentProduct.sku) {
                    // copy the parent data into the variant
                    // property by property. If the property already exists on the variant
                    // log an error
                    for (const property in parentProduct) {
                        if (variant[property]) {
                            variant[`parent_${property}`] = parentProduct[property];
                        } else {
                            variant[property] = parentProduct[property];
                        }
                    }
                }
            }
            for (const sourceProduct of complexSkuList) {
                const sku = sourceProduct.sku;
                const source = sourceProduct.source;
                const score = sourceProduct.score;
                if (variant.parentSku === sku) {
                    variant.searchSource = source;
                    variant.searchScore = score;
                }
            }
        }
        const finalProducts = variants;

        // cache the results
        setCache(complexSkuList, finalProducts);
        
        // setLoading(false);

        return finalProducts;

    }, [skuList, db]);

    const flattenResults = (results) => {
        // make sure results is iterable
        if (!results) return [];
        if (!Array.isArray(results)) return [];
        const flattenedResults = [];
        for (const result of results) {
            flattenedResults.push(result);
            // if (Array.isArray(result)) {
            //     for (const product of result) {
            //         flattenedResults.push(product);
            //     }
            // } else {
            //     // If result is not an array, handle it appropriately
            //     // For example, you might want to log a warning or throw an error
            //     console.warn('Expected result to be an array, received:', result);
            // }
        }
        return flattenedResults;
    }

    // when the list of result skus changes, fetch the products from firebase    
    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;
    
        const fetchData = async () => {
            if (signal.aborted) {
                console.log("fetchData aborted before starting");
                return;
            }
    
            try {
                setLoading(true);
                if (gridRef.current) {
                    gridRef.current.api.showLoadingOverlay();
                }
                const bigChunks = chunkArray(skuList, 100);
                let allResults = [];
                for (const chunk of bigChunks) {
                    if (signal.aborted) {
                        console.log("fetchData aborted during execution");
                        setLoading(false)
                        if (gridRef.current) {
                            gridRef.current.api.hideOverlay();
                        }
                        return;
                    }
                    try {
                        const results = await fetchProducts(chunk);
                        const newResults = flattenResults(results);
                        allResults = allResults.concat(newResults);
                    } catch (error) {
                        if (error.name === 'AbortError') {
                            console.log('fetchData aborted due to AbortError');
                            return;
                        }
                        console.log(error);
                        setLoading(false);
                        if (gridRef.current) {
                            gridRef.current.api.hideOverlay();
                        }
                    }
                }
                if (!signal.aborted && gridRef.current) {
                    loadColumnState();
                    setAllResults(allResults);
                    gridRef.current.api.setRowData(allResults);
                    gridRef.current.api.refreshCells({ force: true });
                    setLoading(false);
                    gridRef.current.api.hideOverlay();
                }
            } catch (error) {
                console.log(error);
            }
        };
    
        if (skuList?.length > 0) {
            fetchData();
        } else {
            if (gridRef.current) {
                gridRef.current?.api?.setRowData([]);
                gridRef.current?.api?.refreshCells({ force: true });
                gridRef.current?.api?.hideOverlay();
            }
        }
    
        // Cleanup function
        return () => {
            abortController.abort();
        };
    }, [skuList, view, gridRef, fetchProducts]); // Ensure all necessary dependencies are listed

    /** refreshes the grid when signaled via triggerRefetch */
    useEffect(() => {

        const refreshData = async () => {
            
            const chunks = chunkArray(skuList, 10);
            let allResults = [];
            for (const chunk of chunks) {
                const results = await fetchProducts(chunk);
                allResults = allResults.concat(flattenResults(results));
            }
            allResults.sort((a, b) => b.searchScore - a.searchScore);
                
            if (gridRef.current) {
                // get current grid data
                const currentRowsMap = new Map();
                gridRef.current.api.forEachNode(node => {
                    if (!node.group) currentRowsMap.set(node.id, node.data)
                });
                const transaction = {
                    update: [],
                };
                allResults.forEach((newRow) => {
                    const currentRow = currentRowsMap.get(newRow.docId);
                    if (currentRow && !isEqual(currentRow, newRow)) {
                        transaction.update.push(newRow);
                    }
                });
                if (transaction.update.length > 0) {
                    try {
                        const result = gridRef.current.api.applyTransaction(transaction);
                        
                        if (result && result.update) {
                            gridRef.current.api.refreshCells({ rowNodes: result.update });
                            const groupNodesToRefresh = new Set();
                            result.update.forEach(node => {
                                let groupNode = node.parent;
                                while (groupNode && groupNode.level >= 0) {
                                    groupNodesToRefresh.add(groupNode);
                                    groupNode = groupNode.parent;
                                }
                            });
                            gridRef.current.api.refreshCells({ rowNodes: Array.from(groupNodesToRefresh) });
                            // loadColumnState();
                        }
                    } catch (error) {
                        console.log("transaction error", error)
                    }
                }
            }
        }
        if (skuList?.length > 0) { // Ensure skuList is not empty
            refreshData();
        }
    }, [triggerRefetch, manualRefetch])

    /** Custom Cell Renderers TODO - abstract these into their own files */

    const ProductGroupCellRenderer = forwardRef( (props, ref) => { // (props) => {
        const { node, api } = props;
        if (!node.group) {
            return null;  // not a group row, nothing to render
        }
        const [animate, setAnimate] = useState(false);
        const [expanded, setExpanded] = useState(false);
        const [checked, setChecked] = useState(false);
        const [pinned, setPinned] = useState(false);
        const timeoutRef = useRef(null);
        const animationLength = 1500; // Duration of animation in ms

        useEffect(() => {
            if (!node) return;
            if (node.expanded) {
                setExpanded(true);
            } else {
                setExpanded(false);
            }
            if (node.allLeafChildren[0]?.data.searchSource === 'exactSku') {
                toggleExpanded();
            }
            const isPinned = findPinnedStatus();
            setPinned(isPinned);
        }, [node]);

        useEffect(() => {
            return () => {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
            };
        }, []);

        const parentSku = node.key;  // Assume the key holds the parentSku
        const productName = node.allLeafChildren[0]?.data.name;
        const productImage = node.allLeafChildren[0]?.data.primaryImage;
        const productUrl = node.allLeafChildren[0]?.data.productUrl;
        const sourceInfo = node.allLeafChildren[0]?.data.searchSource;

        const dynamicStyle = {
            alignItems: 'center',
            padding: '0',
            backgroundColor: animate ? 'green' : 'white',
            transition: `background-color ${Number(animationLength/1000).toFixed(1)}s`
        };

        const toggleExpanded = () => {
            node.setExpanded(!node.expanded);
            setExpanded(!expanded);
        };

        const handleCheckboxChange = () => {
            const newCheckedState = !checked;
            setChecked(newCheckedState);
        
            const currentlySelectedNodes = gridRef.current.api.getSelectedNodes();
            const newSelectedNodes = new Set(currentlySelectedNodes);
            
            // Update the selected state of all rows within the current group.
            node.allLeafChildren.forEach(rowNode => {
                if (newCheckedState) {
                    newSelectedNodes.add(rowNode);
                } else {
                    newSelectedNodes.delete(rowNode);
                }
            });
            
            // Set the selected state of each row.
            newSelectedNodes.forEach(rowNode => rowNode.setSelected(true));
            currentlySelectedNodes
                .filter(rowNode => !newSelectedNodes.has(rowNode))
                .forEach(rowNode => rowNode.setSelected(false));
        };

        const findPinnedStatus = () => {
            if (!node.allLeafChildren) return false;
            let foundPin = false;
            for (const child of node.allLeafChildren) {
                if (child.data.searchSource === 'pinned') foundPin = true;
            }
            return foundPin;
        }

        useImperativeHandle(ref, () => ({
            refresh: (params) => {
                setAnimate(true); // Trigger animation
                // clear any existing timeouts
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
                // set new timeout and store id
                timeoutRef.current = setTimeout(() => {
                    setAnimate(false)
                }, animationLength); // Duration of animation
                return true; // Return false to indicate the cell should be rerendered
            }
        }));

        /**<div>
                    <Button variant='outline-default' size='sm' className='border-0 p-0 me-1'>
                        <PinIcon pinned={findPinnedStatus} togglePin={pinned ? handleRemovePinProduct : handleAddPinProduct}/>
                    </Button>
                </div> */

        return (
            <div className="ms-1 d-flex justify-content-between" style={dynamicStyle}>
                <div className="d-inline-flex" style={{ alignItems: 'center', fontSize: '0.70rem' }}>
                    <input 
                        type="checkbox" 
                        checked={checked} 
                        onChange={handleCheckboxChange}
                        className='me-1'
                        style={{ margin: '0', padding: '0' }}
                    />
                    <Button onClick={toggleExpanded} variant='outline-default' size='sm' className='border-0 p-0 me-1'>
                        {expanded ? '-' : '+'}
                    </Button>
                    <ImageCellRenderer value={productImage} />
                    { sourceInfo === 'exactSku' && <SoftBadge bg="success" className="me-1">Exact SKU</SoftBadge> }
                    <span className='ms-2 me-1'>{parentSku} {productName} ({node.allChildrenCount})</span>
                    <a href={productUrl} target="_blank" rel="noopener noreferrer" className='me-1' style={{ lineHeight: '1' }}>
                        <FontAwesomeIcon icon={faLink} size='xs'/>
                    </a>
                </div>
                
            </div>
        );
    });

    function ImageCellRenderer(params) {
        const { data } = params;
        const additionalImages = data?.files || [];
        const [modalShow, setModalShow] = useState(false);
        return params.value ?
            <div>
                <Image
                   
                    src={params.value}
                    alt="product"
                    width={20}
                    fluid
                    onClick={() => setModalShow(true)}
                    style={{maxHeight: '20px'}}
                />
                <ImageModal 
                    show={modalShow}
                    handleClose={() => setModalShow(false)}
                    imgSrc={params.value}
                    images={additionalImages}
                    productRef={data?.ref}
                />
            </div>
            : null;
    }

    const ImageModal = ({ show, handleClose, imgSrc, images, productRef }) => {
        if (!show || !imgSrc) return null;
        
        async function updatePrimaryImage(productRef, newSrc) {
            if (!productRef) return;
            try {
                await updateDoc(productRef, { primaryImage: newSrc });
            } catch (error) {
                toast.error('Error updating primary image', { theme: 'colored' });
                return null;
            }
        }
      
        return (
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Image Preview</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                    <Col>
                        <Image src={imgSrc} alt="Enlarged" fluid/>
                    </Col>
                </Row>
                {images.length > 0 &&
                    <Row>
                        {images.map((image, index) => (
                            <Col key={index}>
                                <Image 
                                    width={75}
                                    src={image.src}
                                    alt={image.alt}
                                    fluid
                                    thumbnail
                                    
                                />
                            </Col>
                        ))}
                    </Row>
                }
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
        );
    };

    const TagCellRenderer = forwardRef( ({ data }, ref) => {     
        const [tags, setTags] = useState(data.tags || []);
        const [isAdding, setIsAdding] = useState(false);
        const [newTag, setNewTag] = useState('');
        const [animateBackground, setAnimateBackground] = useState(false);
        const timeoutRef = useRef(null)
        const backgroundColor = animateBackground ? 'green' : 'white';

        useEffect(() => {
            setTags(data.tags || []);
        }, [data]);

        // refresh method for AG-Grid
        useImperativeHandle(ref, () => ({
            refresh: (params) => {
                setTags(params.data.tags || []);
                setAnimateBackground(true); // Trigger animation
                // clear any existing timeouts
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
                // set new timeout and store id
                timeoutRef.current = setTimeout(() => {
                    setAnimateBackground(false)
                }, 5000); // Duration of animation
                return true; // Return false to indicate the cell should be rerendered
            }
        }));

        // Cleanup on unmount
        useEffect(() => {
            return () => {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
            };
        }, []);

        // the product's reference 
        const variantRef = data.ref;
        if (!variantRef) {
            toast.error('No product reference found', { theme: 'colored' });
            // return null;
        }

        const handleAddTag = async (newTag) => {
            const updatedTags = [...tags, newTag];
            setTags(updatedTags);
            try {
                await updateDoc(variantRef, { tags: updatedTags });
                setNewTag('');
                setIsAdding(false);
            } catch (error) {
                toast.error('Error updating tags', { theme: 'colored' });
                return null;
            }
        }

        const handleRemoveTag = async (tagToRemove) => {
            const updatedTags = tags.filter(tag => tag !== tagToRemove);
            setTags(updatedTags);
            try {
                await updateDoc(variantRef, { tags: updatedTags });
            } catch (error) {
                toast.error('Error updating tags', { theme: 'colored' });
                return null;
            }
        }

        const handleInputChange = (event) => {
            setNewTag(event.target.value);
        }

        const handleInputKeyPress = (event) => {
            if (event.key === 'Enter') {
                handleAddTag(newTag);
            }
        }

        return (
            <div 
                className="d-flex flex-wrap justify-content-start align-items-center"
                style={{ height: '100%', backgroundColor: backgroundColor, transition: 'background-color 5s' }} 
            >
                {tags.map(tag => (
                    <div key={tag}
                      className="d-flex flex-wrap justify-content-start align-items-center" 
                    >
                        <TagDisplay tag={tag} onRemove={() => handleRemoveTag(tag)} />
                    </div>
                ))}
                {isAdding ?
                    <Form.Control
                        type="text"
                        value={newTag}
                        onChange={handleInputChange}
                        onKeyDown={handleInputKeyPress}
                        onBlur={() => setIsAdding(false)}
                        autoFocus
                        size="sm"
                        className="d-inline-block w-auto"
                    />
                    :
                    <div
                        style={{
                            width: '18px',
                            height: '18px',
                            borderRadius: '50%',
                            backgroundColor: 'green',
                            color: 'white',
                            fontSize: '14px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                        }}
                        
                        onClick={() => {setIsAdding(true); setNewTag('')}}
                    >+</div>
                }
            </div>
        );

    });

    function ListingsCellRenderer({ data }) {
        const [showNewModal, setShowNewModal] = useState(false);
        const { listings } = data;
        
        return (
            <>
            <div className="d-flex flex-wrap justify-content-start align-items-center" style={{ height: '100%' }}>
                {listings?.length > 0 ?
                    listings.map(listing => (
                        <div key={listing.id} className="position-relative d-flex align-items-center m-1">
                            <ListingDisplay listing={listing} />
                        </div>
                    ))
                    :
                    <div
                        as="button"
                        style={{
                            width: '18px',
                            height: '18px',
                            borderRadius: '50%',
                            backgroundColor: 'green',
                            color: 'white',
                            fontSize: '14px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                        }}
                        onClick={() => setShowNewModal(true)}
                    >+</div>
                }
            </div>
            <NewListingModal show={showNewModal} setShow={setShowNewModal} data={data} />
            </>
            
        )
    }

    function AsinDataRenderer(row) {
        const { data, node, api } = row;
        const variantRef = data.ref;
        const [snapData, setSnapData] = useState(null);
        useEffect(() => {
            let isMounted = true;
            if (!variantRef) return;
            const unsubscribe = onSnapshot(variantRef, (doc) => {
                if (doc.exists()) {
                    const data = doc.data();
                    const parentRef = data.parentRef;
                    getDoc(parentRef).then((parentDoc) => {
                        const parentData = parentDoc.data();
                        data.barcodes = parentData.barcodes;
                        if (isMounted) setSnapData(data);
                    });
                }
            });
            return () => {
                isMounted = false;
                unsubscribe();
            }
        }, [variantRef]);

        const handleModal = (data) => {
            setAsinDetailModalShow(true);
            setSelectedVariantData(data);
        }

        return (
            
            <div className="d-flex flex-wrap justify-content-start align-items-center" style={{ height: '100%' }}>
                {data.amazon?.asin ?
                    <div onClick={() => handleModal(snapData)}>
                        {data.amazon?.asin !== 'Not Found' ?
                            <SoftBadge 
                                bg="success"
                                className="me-1"
                            >
                                {data.amazon.asin}
                            </SoftBadge>
                            :
                            <SoftBadge
                                bg="danger"
                                className="me-1"
                            >
                                {data.amazon.asin}
                            </SoftBadge>
                        }
                    </div>
                    :
                    <div
                        style={{
                            width: '18px',
                            height: '18px',
                            borderRadius: '50%',
                            backgroundColor: 'red',
                            color: 'white',
                            fontSize: '14px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                        }}
                        
                    ><FontAwesomeIcon icon={faHammer} onClick={() => createSingleAsinDiscoveryJob(snapData)} /></div>
                }
            </div>
            
        )

        async function createSingleAsinDiscoveryJob(snapData) {
            console.log("job created", snapData)
            try {
                await addDoc(collection(db, 'amazonApiTasks'), {
                    status: "New",
                    handler: "amazonApiRequestHandler",
                    type: "gatherAsinIntel",
                    jobData: {
                    contentType: "application/json",
                    httpMethod: "POST",
                    inSeconds: 10,
                    payload: { // sku, listPrice, shippingCost, asin
                        sku: snapData.sku,
                        title: snapData.title,
                        type: "UPC",
                        values: [
                            {size: "unit", value: snapData.barcodes.unit}, 
                            {size: "stock", value: snapData.barcodes.stock}, 
                            {size: "other", value: snapData.barcodes.other}
                        ],
                    },
                    queueName: "amazon-api-queue",
                    },
                    timestamp: {
                        created: serverTimestamp(),
                        updated: serverTimestamp(),
                    }
                });
                toast.success('ASIN Discovery Job Created', { theme: 'colored' });
                console.log("here")
                return { success: true, message: 'Job created' };
            } catch (error) {
                console.log
                toast.error(error.message, { theme: 'colored' });
            }
        }
    }
    
    function ShippingDataRenderer(row) {
        const shippingMaster = row.node.data?.shippingMaster || {};
        const average = row.node.data?.estShippingCost || 0;

        return (
            average !== 0 ?
                <div className="d-flex flex-wrap justify-content-start align-items-center">
                    ${average}
                </div>
            :   <div className="d-flex flex-wrap justify-content-start align-items-center">
                    <FontAwesomeIcon icon={faHammer} />
                </div>
        )
    }

    function CurrentTitleRenderer(row) {
        const { data, node, api } = row;
        const { title, alternateTitles, previousTitles, ref } = data;
        const [displayTitle, setDisplayTitle] = useState();
        const displayTitles = [];
        if (title) displayTitles.push(title);
        if (alternateTitles) displayTitles.push(...alternateTitles);
        if (previousTitles) displayTitles.push(...previousTitles);
        const [showModal, setShowModal] = useState(false);

        useEffect(() => {
            if (displayTitles.length > 0) {
                setDisplayTitle(displayTitles[0]);
            }
        }, [displayTitles]);
        
        const saveTitle = async (e, title) => {
            console.log(title)
            e.preventDefault();
            try {
                setDisplayTitle(title);
                await updateDoc(ref, { title })
                console.log(user.uid)
                // triggerRefetch();
                // create random string
                const randomString = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                storageSet(storageRef(realtime, `reqMessages/${user.uid}`),{
                    message: 'Product title updated',
                    status: `success-${randomString}`,
                    userId: user.uid,
                })
                setShowModal(false);
            } catch (error) {
                toast.error(error.message, { theme: 'colored' });
                console.log(error)
            }
            
        }

        const Actions = ({title}) => (
            <div className="end-0 top-50 pe-3 translate-middle-y hover-actions">
              <Button variant="light" size="sm" className="border-300 me-1 text-600" onClick={(e) => saveTitle(e, title)}>
                <FontAwesomeIcon icon="check" />
              </Button>
              <Button variant="light" size="sm" className="border-300 text-600">
                <FontAwesomeIcon icon="trash-alt" />
              </Button>
            </div>
        );
          
        const HoverableTitleTable = ({titles}) => {
        
            const LineItem = ({ title, index }) => (
                <tr className="hover-actions-trigger" key={index}>
                    <td>{title}</td>
                    <td className="w-auto">
                        <Actions title={title}/>
                    </td>
                </tr>
            );

            return (
                <Table hover responsive>
                <thead>
                    <tr>
                    <th scope="col">Title</th>
                    </tr>
                </thead>
                <tbody>
                    {titles.map((title, index) => (
                        <LineItem key={index} title={title} />
                    ))}
                </tbody>
                </Table>
            );
        };
          
        
        return (
            <>
            <div className="d-inline-block flex-wrap justify-content-start align-items-center text-truncate" style={{ width: '250px'}}>
                {displayTitle}
            </div>
            <FontAwesomeIcon icon={faExternalLinkAlt} onClick={() => setShowModal(true)} />
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Choose an Alternate Title</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-wrap justify-content-start align-items-center">
                        <HoverableTitleTable titles={displayTitles} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            </>  
        )
    }

    function CurrentDescriptionRenderer(row) {
        const { data, node, api } = row;
        const { currentDescription, descriptionOptions, previousDescriptions, ref } = data;
        const [displayDescription, setDisplayDescription] = useState();
        const displayDescs = [];
        if (currentDescription) displayDescs.push({currentDescription: currentDescription});
        if (descriptionOptions) {
            descriptionOptions.forEach((desc) => {
                displayDescs.push({currentDescription: desc});
            });
        }
        if (previousDescriptions) {
            previousDescriptions.forEach((desc) => {
                displayDescs.push({currentDescription: desc});
            });
        }
        const [showModal, setShowModal] = useState(false);

        // give a key to each element in displayDescs
        // useEffect(() => {
        //     if (displayDescs) {
        //         const tableDataMap = displayDescs.map((desc) => {
        //             return { currentDescription: desc }
        //         })
        //         setTableData(tableDataMap);
        //     }
        // }, [displayDescs]);

        useEffect(() => {
            if (displayDescs.length > 0) {
                setDisplayDescription(displayDescs[0].currentDescription);
            }
        }, [displayDescs]);
        
        const saveDesc = async (e, desc) => {
            e.preventDefault();
            try {
                setDisplayDescription(desc);
                await updateDoc(ref, { currentDescription: desc })
               const randomString = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                storageSet(storageRef(realtime, `reqMessages/${user.uid}`),{
                    message: 'Product description updated',
                    status: `success-desc-${randomString}`,
                    userId: user.uid,
                })
                setShowModal(false);
            } catch (error) {
                toast.error(error.message, { theme: 'colored' });
                console.log(error)
            }
            
        }

        const Actions = ({desc}) => (
            <div className="end-0 top-50 pe-3 translate-middle-y hover-actions">
              <Button variant="light" size="sm" className="border-300 me-1 text-600" onClick={(e) => saveDesc(e, desc)}>
                <FontAwesomeIcon icon="check" />
              </Button>
              <Button variant="light" size="sm" className="border-300 text-600">
                <FontAwesomeIcon icon="trash-alt" />
              </Button>
            </div>
        );
          
        const HoverableDescTable = ({descs}) => {
        
            const LineItem = ({ desc, index }) => (
                <tr className="hover-actions-trigger" key={index}>
                    <td>{desc.currentDescription}</td>
                    <td className="w-auto">
                        <Actions desc={desc}/>
                    </td>
                </tr>
            );

            return (
                <Table hover responsive>
                <thead>
                    <tr>
                    <th scope="col">Title</th>
                    </tr>
                </thead>
                <tbody>
                    {descs.map((desc, index) => (
                        <LineItem key={index} desc={desc} />
                    ))}
                </tbody>
                </Table>
            );
        };

        function AdvanceDescriptionTable({ descriptions }) {
        
            const columns = [
                {
                    accessor: 'currentDescription',
                    Header: 'Description',
                    
                }
            ];

            return(
                <AdvanceTableWrapper
                    columns={columns}
                    data={descriptions}
                    sortable
                    pagination
                    perPage={3}
                >
                <AdvanceTable
                    table
                    headerClassName="bg-200 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                        bordered: true,
                        striped: true,
                        className: 'fs-10 mb-0'
                    }}
                />
                <div className="mt-3">
                    <AdvanceTablePagination
                    table
                    />
                </div>
                </AdvanceTableWrapper>
            )
        }
        
        return (
            <>
            <div className="d-inline-block flex-wrap justify-content-start align-items-center text-truncate" style={{ width: '250px'}}>
               {displayDescription}
            </div>
            <FontAwesomeIcon icon={faExternalLinkAlt} onClick={() => setShowModal(true)} />
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Choose a different Description</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-wrap justify-content-start align-items-center">
                        <HoverableDescTable descs={displayDescs} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            </>  
        )
    }

    /** Grid Components - these are components used in the custom cell renderers */

    function TagDisplay({ tag, onRemove }) {
        return (
            <div
                className="d-inline-flex align-items-center border border-1 rounded-2 border-500 fs--2 text-900"
                style={{maxHeight: '20px'}}
            >
                <span className="ms-1 me-1">{tag}</span>
                <div variant="link"
                    style={{
                            width: '10px',
                            height: '10px',
                            borderRadius: '50%',
                            backgroundColor: 'white',
                            color: 'red',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            fontSize: '0.5rem', 
                            lineHeight: '0.5rem'
                        }}
                    className='p-2 me-1'
                    size="sm" onClick={onRemove}
                >x</div>
            </div>
        )
    }

    function ListingDisplay({ listing }) {
        
        const ListingIcon = ({ listing }) => {
            switch (listing.channel) {
                case 'amazon': return <div
                                style={{
                                    width: '18px',
                                    height: '18px',
                                    borderRadius: '50%',
                                    backgroundColor: 'white',
                                    color: listing.status == 'active' ? 'green' : 'red',
                                    fontSize: '14px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                }}><FontAwesomeIcon icon={faAmazon} />
                                </div>;
                default: return null;
            }
        }

        return (
            <div
                style={{minWidth: '20px', minHeight: '20px'}}
                key={listing.id}
            >
                <ListingIcon listing={listing} />
            </div>
        )
    }

    // TODO - this needs to be updated to account for both the variant and product
    // refs being included in the same grid row, if a variant field is changed
    // then this function will erroneously update the product ref and not variant ref
    const onCellValueChanged = async (params) => {
        const parentDocId = params.data.parent_docId;
        if (params.newValue !== params.oldValue) {
            const docRef = doc(db, `productCatalog/topicz/products/${parentDocId}/variants`, params.data.docId);
            await updateDoc(docRef, { [params.colDef.field]: params.newValue });
        }
    };

    const LoadingAnimationComponent = ({size}) => {
        if (!size) size = 300;
        return (
            <div><Lottie animationData={dotsAnimation} style={{height: size}} /></div>
        )
    }
      
    /** Column Definitions */
    const basicColumnDefs = [
        { field: 'parentSku',
          headerName: 'Parent',
          filter: true,
          rowGroup: true,
          hide: true,
        },
        { headerName: 'Select', checkboxSelection: true, width: 50 },
        { field: 'primaryImage', cellRenderer: ImageCellRenderer, width: 70, },
        { field: 'sku', filter: true, width: 100 },
        { field: 'productFactor', 
          editable: true, 
          sort: 'asc',  // or 'desc' for descending
          sortIndex: 0,  // This will be the first column to be sorted
          filter: true,
          width: 50,
          sortable: true,
            comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
                return valueA - valueB;
            },
            valueGetter: (params) => {
                return params.data?.productFactor ? Number(params.data?.productFactor) : 1;
            },
            valueFormatter: params => `${params.value}`,
        },
        { field: 'searchSource', headerName: 'Source', filter: true, width: 100, hide: true },
        { field: 'title', filter: true, width: 300, cellRenderer: CurrentTitleRenderer},
        { field: 'currentDescription', headerName: 'Description', filter: true, width: 300, editable: true, wrapText: true, cellRenderer: CurrentDescriptionRenderer },
        { field: 'bullets', headerName: 'Product Features', filter: true, width: 300, wrapText: true },
        { field: 'brand', filter: true, width: 100, editable: true },
        { field: 'amazon.asin', headerName: 'ASIN', filter: true, width: 100, cellRenderer: AsinDataRenderer },
        { field: 'cost',
          filter: true,
          sortable: true,
          width: 100,
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return valueA - valueB;
          },
          valueGetter: (params) => {
            return params.data?.cost ? Number(params.data?.cost * params.data.productFactor).toFixed(2) : params.data?.ibmi.cost; 
          },
          valueFormatter: params => `$${params.value}`,
        },
        { field: 'weightOz', filter: true, width: 100, editable: true },
        { field: 'estShippingCost', headerName: 'Shipping Cost', filter: true, width: 100, cellRenderer: ShippingDataRenderer },
        { field: 'pricingTemplate', headerName: 'Pricing Template', filter: true, width: 100, hide: true },
        { field: 'shippingTemplate', headerName: 'Shipping Template', filter: true, width: 100, hide: true },
        {
            field: 'tags',
            headerName: 'Tags',
            cellRenderer: TagCellRenderer,
            width: 400,
            
        },
        { field: 'ecomm_onhand',
          filter: true,
          width: 100,
          headerName: 'On Hand',
          sortable: true,
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return valueA - valueB;
          },
          valueGetter: (params) => {
            const ecomm_onhand = params.data?.ecomm_onhand ? params.data?.ecomm_onhand : 0;
            const productFactor = params.data?.productFactor ? params.data?.productFactor : 1;
            return Math.floor(ecomm_onhand / productFactor);
          },
          valueFormatter: params => `${params.value.toFixed(0)}`,
          cellStyle: (params) => {
            const productFactor = params.data.productFactor ? params.data.productFactor : 1;
            return productFactor == 1 ? {color: 'green'} : {color: 'black'};  // Set color based on productFactor
          },
        },
        {
            field: 'mapPricing',
            headerName: 'MAP Price',
            filter: true,
            editable: true,
            valueFormatter: params => params.value ? `$${Number(params.value).toFixed(2)}` : null
        },
        {
            field: 'activePricer.baseCost',
            headerName: 'Pricing Base Cost',
            filter: true,
            valueFormatter: params => params.value ? `$${Number(params.value).toFixed(2)}` : null
        },
        {
            field: 'activePricer.shippingCost',
            headerName: 'Postage Cost',
            filter: true,
            valueFormatter: params => params.value ? `$${Number(params.value).toFixed(2)}` : null
        },
        {
            field: 'activePricer.totalShippingCost',
            headerName: 'Total Shipping Cost',
            filter: true,
            valueFormatter: params => params.value ? `$${Number(params.value).toFixed(2)}` : null
        },
        { field: 'activePricer.minPrice',
          headerName: 'Min Price',
          filter: true,
          valueFormatter: params => params.value ? `$${Number(params.value).toFixed(2)}` : null
        },
        { field: 'activePricer.prefPrice',
          headerName: 'Target Price',
          filter: true,
          valueFormatter: params => params.value ? `$${Number(params.value).toFixed(2)}` : null
        },
        { field: 'activePricer.maxPrice',
          headerName: 'Max Price',
          filter: true,
          valueFormatter: params => params.value ? `$${Number(params.value).toFixed(2)}` : null
        },
        { field: 'activePricer.bizPrice',
          headerName: 'B2B Price',
          filter: true,
          valueFormatter: params => params.value ? `$${Number(params.value).toFixed(2)}` : null
        },
        {
            field: 'activePricer.pricingTemplate.minMargin',
            headerName: 'Min Margin',
            filter: true,
            // convert to percent from decimal
            valueFormatter: params => params.value ? `${Number(params.value * 100).toFixed(2)}%` : null
        },
        {
            field: 'activePricer.pricingTemplate.prefMargin',
            headerName: 'Target Margin',
            filter: true,
            valueFormatter: params => params.value ? `${Number(params.value * 100).toFixed(2)}%` : null
        },
        {
            field: 'activePricer.pricingTemplate.bizMargin',
            headerName: 'B2B Margin',
            filter: true,
            valueFormatter: params => params.value ? `${Number(params.value * 100).toFixed(2)}%` : null
        },
        {
            field: 'activePricer.pricingTemplate.maxMargin',
            headerName: 'Max Margin',
            filter: true,
            valueFormatter: params => params.value ? `${Number(params.value * 100).toFixed(2)}%` : null
        },
        { field: 'listings', headerName: 'Listings', cellRenderer: ListingsCellRenderer},
    ];
    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
    }));

    /** Grid Options */
    const gridOptions = {
        animateRows: true,
        columnDefs: basicColumnDefs,// columnDefs,
        defaultColDef: defaultColDef,
        domLayout: 'normal',
        getRowId: params => params.data?.docId,
        getRowHeight: (params) => {
            if (params.node.group) {  // check if it's a group row
                return 35;  // return a fixed height for group rows
            }
            const tags = params.data.tags;
            const baseHeight = 35;
            const lineHeight = 20;
            const minHeight = 5;
            const numberOfLines = Math.ceil((tags?.length * 125) / params.columnApi.getColumn("tags")?.getActualWidth());  // assuming each tag is approx 10px wide
            const calculatedHeight = baseHeight + (numberOfLines - 1) * lineHeight;
            return Math.max(calculatedHeight, baseHeight + minHeight);
        },
        groupDefaultExpanded: 0, // -1, // to expand all groups by default
        groupUseEntireRow: true,
        groupDisplayType: 'groupRows',
        groupRowRenderer: ProductGroupCellRenderer,
        groupRowRendererParams: {
            someProp: 'someValue',
        },
        onCellValueChanged: onCellValueChanged,
        onFirstDataRendered: onGridReady,
        onColumnMoved: onColumnChanged,
        onColumnVisible: onColumnChanged,
        onColumnResized: onColumnChanged,
        onColumnPinned: onColumnChanged,
        loadingOverlayComponent: LoadingAnimationComponent,
        overlayNoRowsTemplate: '<span class="ag-overlay-loading-center">Search For Products Above</span>',
        rowSelection: "multiple",
        sideBar: {
            toolPanels: [
              {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                  suppressRowGroups: false,
                  suppressValues: true,
                  suppressPivots: true,
                  suppressPivotMode: true,
                  suppressSideButtons: false,
                  suppressColumnFilter: false,
                  suppressColumnSelectAll: false,
                  suppressColumnExpandAll: false,
                },
              },
              {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
              },
              // ... include other panels if necessary
            ],
          },
        suppressModelUpdateAfterUpdateTransaction: true,
    };

    // Menu Bar Action Handlers
    async function handleAction(actionType, payload) {
        const selectedNodes = gridRef.current.api.getSelectedNodes();
        const selectedDataRaw = selectedNodes.map(node => node.data);
        // selectedDataRaw will include the Group Row nodes as well which will be undefined
        const selectedData = selectedDataRaw.filter(Boolean); // remove group rows
        setSelectedData(selectedData);

        // setConfirmationModalShow(true);
                
        switch (actionType) {

            // PRODUCTS FILE MENU ACTIONS
            case 'NEW_WORKSPACE':
                // opens a new tab
                const newTabRef = await tabController.createTab({name: 'New Tab', type: 'workspace', data: selectedData});
                // setActiveTab(newTabRef.id);
                tabController.assignActiveTab(newTabRef.id);
                break;
            case 'OPEN_WORKSPACE':
                // opens the workspace manager modal to the browse tab
                setShowOpenWorkspaceModal(true);
                break;
            case 'MANAGE_WORKSPACES':
                // opens the workspace manager modal to the manage tab
                break;
            case 'TAGS':
                // setSelectedData(selectedData);
                setTagsModalShow(true);
                break;
            case 'MERLIN_AI':
                // opens the workspace manager modal
                break;
            case 'ADD_PRODUCTS_TO_WORKSPACE':
                // adds the currently visible products to the workspace permanently
                setConfirmationModalTitle('Add Products to Workspace');
                setConfirmationModalMessage(`Are you sure you want to add all products to the workspace?`);
                confirmPinAllProducts(searchResults, tabController);
                break;

            // EDIT FILE MENU ACTIONS
            case 'COPY':
                // copies the selected data to the clipboard
                copyToClipboard();
                break;
            case 'PASTE':
                // pastes the data from the clipboard into the workspace
                pasteFromClipboard();
                break;

            
            // PRICING FILE MENU ACTIONS
            case 'ASSIGN__PRICING_TEMPLATE':
                // opens the assign pricing template modal
                setAssignmentType('pricing');
                // setSelectedData(selectedData);
                setAssignTemplateModalShow(true);
                break;
            case 'MANAGE_PRICING_TEMPLATES':
                // opens the manage pricing template modal
                setShowManagePricingTemplatesModal(true);
                break;
            case 'REPRICE':
                setConfirmationModalTitle('Reprice Products');
                setConfirmationModalMessage(`Are you sure you want to create ${selectedData.length} repricer jobs for the selected products?`);
                confirmRepricerJobs(user.uid, selectedData);
                break;

            // SHIPPING FILE MENU ACTIONS
            case 'ESTIMATE_SHIPPING':
                setConfirmationModalTitle('Create Shipping Estimate');
                setConfirmationModalMessage(`Are you sure you want to create ${selectedData.length} shipping estimate jobs for the selected products?`);
                confirmShippingEstimate();
                break;
            case 'ASSIGN_SHIPPING_TEMPLATE':
                // opens the assign pricing template modal
                setAssignmentType('shipping');
                setAssignTemplateModalShow(true);
                break;
            case 'MANAGE_SHIPPING_TEMPLATES':
                // opens the manage pricing template modal
                setShowManageShippingTemplatesModal(true);
                break;

            // LISTINGS FILE MENU ACTIONS
            case 'ASSIGN_LISTING_TEMPLATE':
                // opens the assign pricing template modal
                break;
            case 'MANAGE_LISTING_TEMPLATES':
                // opens the manage pricing template modal
                break;
            case 'LISTING_READINESS_CHECK':
                setShowListingReadinessModal(true);
                break;
            
            // INTEL File   MENU ACTIONS
            case 'DISCOVER_ASINS':
                // setSelectedData(selectedData);
                // createAsinDiscoveryJobs(selectedData)
                setConfirmationModalTitle('ASIN Discovery');
                setConfirmationModalMessage(`Are you sure you want to create ${selectedData.length} ASIN Discovery jobs for the selected products?`);
                confirmAsinDiscovery();
                break;
            case 'ESTIMATE_AMAZON_FEES':
                setConfirmationModalTitle('Estimate Amazon Fees');
                setConfirmationModalMessage(`Are you sure you want to create ${selectedData.length} Amazon Fee Estimate jobs for the selected products?`);
                confirmAmazonFeeEstimate();
                console.log("CLICK")
                break;
            default:
                console.log('Error no action type found');
                break;
        }
        gridRef.current.api.refreshCells();
    }

    /** Menu Action Handlers */
    const handleConfirmationModalConfirm = async (data) => {
        if (confirmationModalAction) {
            confirmationModalAction(data);
        }
    }
    const execActionWithConfirmation = (action) => {
        setConfirmationModalAction(() => action);
        setConfirmationModalShow(true);
    };
    const confirmAsinDiscovery = () => execActionWithConfirmation(createAsinDiscoveryJobs);
    const confirmShippingEstimate = () => execActionWithConfirmation(createShippingEstimateJobs);
    const confirmRepricerJobs = (uid, selectedData) => execActionWithConfirmation(() => createActivePricerJobs(uid, selectedData));
    const confirmAmazonFeeEstimate = () => execActionWithConfirmation(createAmazonFeeDiscoveryJobs);
    const confirmPinAllProducts = (skus, controller) => execActionWithConfirmation(() => pinAllProducts(skus, controller));

    
    const copyToClipboard = () => {
        const selectedNodes = gridRef.current.api.getSelectedNodes();
        const selectedDataRaw = selectedNodes.map(node => node.data);
        // selectedDataRaw will include the Group Row nodes as well which will be undefined
        const selectedData = selectedDataRaw.filter(Boolean); // remove group rows
        const selectedSkus = selectedData.map(data => data.sku);
        saveToClipboard({skus: selectedSkus, data: selectedData});
    }

    const pasteFromClipboard = () => {
        const pastedData = clipboard?.data;
        const parentSkus = pastedData.map(data => data.parentSku);
        const uniqueSkus = [...new Set(parentSkus)];
        const complexSkuList = uniqueSkus.map(sku => {
            return {
                sku: sku,
                score: 103,
                searchSource: 'clipboard',
            }
        });
        tabController.addProduct(tabController.activeTab, complexSkuList);
        tabController.assignActiveTab(tabController.activeTab);
    }

    const nextTab = () => {

        // find the index of the activeTab
        const index = visibleTabs.findIndex(tab => tab.id === tabController.activeTab);
        if (gridRef.current) {
            gridRef.current.api.showLoadingOverlay();
            if (index === -1) {
                tabController.assignActiveTab(visibleTabs[0]?.id);
            } else if (index === visibleTabs.length - 1) {
                tabController.assignActiveTab(visibleTabs[1]?.id);
            } else {
                tabController.assignActiveTab(visibleTabs[index + 1]?.id);
            }
            gridRef.current.api.setRowData([]);
            setSkuList(visibleTabs[index]?.skus);
            gridRef.current.api.hideOverlay();
        }
        
    }

    const previousTab = () => {
        // find the index of the activeTab
        const index = visibleTabs.findIndex(tab => tab.id === tabController.activeTab);
        if (gridRef.current) {
            gridRef.current.api.showLoadingOverlay();
            if (index === -1) {
                tabController.assignActiveTab(visibleTabs[1]?.id);
            } else if (index === 0) {
                tabController.assignActiveTab(visibleTabs[visibleTabs.length - 1]?.id);
            } else {
                tabController.assignActiveTab(visibleTabs[index - 1]?.id);
            }
            gridRef.current.api.setRowData([]);
            setSkuList(visibleTabs[index]?.skus);
            gridRef.current.api.hideOverlay();
        }
    }

    useKeystroke({
        'Cmd+c': () => copyToClipboard(),
        'Ctrl+c': () => copyToClipboard(),
        'Cmd+v': () => pasteFromClipboard(),
        'Ctrl+v': () => pasteFromClipboard(),
        'Cmd+ArrowRight': () => nextTab(),
        'Ctrl+ArrowRight': () => nextTab(),
        'Cmd+ArrowLeft': () => previousTab(),
        'Ctrl+ArrowLeft': () => previousTab(),
    });

    return (
        !authLoading &&
        <ActionsContext.Provider value={{handleAction}}>
            <Card>
                <div className='ps-2 pe-2 pb-2 pt-1 rounded-top bg-primary align-middle'>
                    <div className='d-inline-block fw-semi-bold fs-1 text-white'>
                        <MenuBar
                            gridRef={gridRef}
                        />
                    </div>
                    <div className='d-inline-block float-end  fs--1 rounded mt-1 text-white w-50 pe-2'>
                        <ProductSearchBar
                            fetchLoading={loading}
                            gridRef={gridRef}
                            tabController={tabController}
                            searchTabId={tabs[0]?.id}
                        />
                    </div>
                </div>
                <Row>
                    <Col>
                        <TabContainer 
                            tabs={tabs}
                            loading={tabsLoading}
                            tabController={tabController}
                            uid={user.uid}
                            gridRef={gridRef}
                        />
                    </Col>
                    
                </Row>
                <div
                    className="ag-theme-alpine"
                    style={{ width: '100%', height: '75vh', maxHeight: '80vh' }}
                >
                    <AgGridReact className="border-0" style={{zIndex: 99999}} ref={gridRef} {...gridOptions} />
                </div>
            </Card>
            <BulkTagsModal
                show={tagsModalShow}
                setShow={setTagsModalShow}
                data={selectedData}
                gridRef={gridRef}
            />
            <ConfirmationModal
                show={confirmationModalShow}
                title={confirmationModalTitle}
                message={confirmationModalMessage}
                data={selectedData}
                onConfirm={handleConfirmationModalConfirm}
                onClose={() => setConfirmationModalShow(false)}
            />
            <AssignTemplateModal
                type={assignmentType}
                show={assignTemplateModalShow}
                setShow={setAssignTemplateModalShow}
                data={selectedData}
                gridRef={gridRef}
                setRefetch={setManualRefetch}
            />
            <AsinDetailModal
                show={asinDetailModalShow}
                onClose={() => setAsinDetailModalShow(false)}
                data={selectedVariantData}
            />
            <ManagePricingTemplatesModal
                show={showManagePricingTemplatesModal}
                onClose={() => setShowManagePricingTemplatesModal(false)}
            />
            <ManageShippingTemplatesModal
                show={showManageShippingTemplatesModal}
                onClose={() => setShowManageShippingTemplatesModal(false)}
            />
            <OpenWorkspaceModal
                show={showOpenWorkspaceModal}
                onClose={() => setShowOpenWorkspaceModal(false)}
                tabController={tabController}
            />
            <ListingReadinessOffcanvas
                show={showListingReadinessModal}
                onHide={() => setShowListingReadinessModal(false)}
                gridRef={gridRef}
                data={allResults}
            />

        </ActionsContext.Provider>
    )
}

function chunkArray(array, size) {
    const results = [];
    for (let i = 0; i < array.length; i += size) {
        results.push(array.slice(i, i + size));
    }
    return results;
}

function applyHeaderStyle(){
    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = `
        .ag-header-cell-label {
            padding: 1px 1px; /* Reduce padding */
            font-size: 11px; /* Smaller font size */
        }
        .ag-header-container {
            min-height: 15px; /* Reduce the height of the header row */
        }
        .ag-header-cell {
            line-height: 10px; /* Setting the line height to the desired height */
            height: 10px; /* Setting the height of the cell */
        }
        .ag-header-cell-label .ag-header-cell-text {
            line-height: normal; /* Adjust line-height as needed */
        }
        .ag-header-cell-label .ag-text-field-input,
        .ag-header-cell-label .ag-header-icon {
            margin: 0; /* Remove margin */
        }
        `;
    document.head.appendChild(style);
}

function applyRowStyle() {
    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = `
        /* Tighten up the cell padding */
        .ag-cell {
            padding: 2px 4px; /* Adjust padding to your preference */
            font-size: 12px; /* Smaller font size for content */
            line-height: 1; /* Tighten line height */
        }

        /* Adjust the height of the rows */
        .ag-row {
            height: 25px; /* Adjust the height to your preference */
        }

        /* Adjust header styles if needed */
        .ag-header-cell-label {
            padding: 1px 1px; /* Reduce padding */
            font-size: 10px; /* Smaller font size */
        }

        /* Style for pinned rows if necessary */
        .ag-pinned-left-cols-container .ag-cell,
        .ag-pinned-right-cols-container .ag-cell {
            padding: 2px 4px; /* Ensure consistent padding with other cells */
        }
    `;
    document.head.appendChild(style);
}
