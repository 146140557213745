import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
import { db } from 'config/firebase.js'
import { query, collection, addDoc, updateDoc, limit, where, getDocs, doc, orderBy, onSnapshot, collectionGroup } from 'firebase/firestore';
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';
import PageHeader from 'components/common/PageHeader';
import { Card, Row, Col, Button, Form, Tabs, Tab, Image, Overlay, Tooltip } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/merlin/advance-table/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/merlin/advance-table/AdvanceTableSearchBox';
import AdvanceTable from 'components/merlin/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/merlin/advance-table/AdvanceTableFooter';
import Lottie from 'lottie-react';
import dotsAnimation from 'assets/lottie/dots.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import useRoleData from 'hooks/useRoleData.js';

import { Link } from 'react-router-dom';


const BatchDetail = () => {

    const { user, authLoading }= useContext(FirebaseAuthContext)
    const { batchId } = useParams();
    const [batch, batchLoading, batchError] = useDocumentData(doc(db, 'batchState', batchId));
    const [ordersLoading, setOrdersLoading] = useState(true);
    const [enrichmentLoading, setEnrichmentLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [enrichedOrders, setEnrichedOrders] = useState([]);
    const [roleData, orgData, userLoading, userError] = useRoleData(user?.uid);
    const navigate = useNavigate();
  
    useEffect(() => {
      if (authLoading) return;
      if (!user) return navigate('/');
    }, [authLoading, user])

    async function gatherOrderItems(orderNumber) {
        try {
            const q = query(collection(db, 'orders'), where('orderNumber', '==', orderNumber))
            const orderSnapshot = await getDocs(q);
            const orders = [];
            orderSnapshot.forEach((doc) => {
                let temp = doc.data();
                temp.ref = doc.ref;
                orders.push(temp);
            });
            return orders;
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        
        if (batchLoading) return;
        if (!batch) return;
        
        const q = query(collection(db, 'fulfillments'), where('batchNumber', '==', batch.batchName))
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const orders = snapshot.docs.map(doc => ({...doc.data(), ref: doc.ref}));
            
            for(let i = 0; i < orders.length; i++) {
                const order = orders[i];
                const { orderNumber } = order;
                gatherOrderItems(orderNumber)
                .then((orderItems) => {
                    if (orderItems[0]) {
                        setEnrichedOrders((prev) => [...prev, orderItems[0]])
                    }
                    
                })
                .finally(() => {
                    setEnrichmentLoading(false);
                })
                .catch((error) => {
                    console.log(error)
                    setEnrichedOrders((prev) => [...prev])
                });
                
            }
        });
        
        return unsubscribe;
    }, [batchLoading, batch])

    async function getProductPageURL(item) {
        const { sku } = item;
        try {
            const q = query(collectionGroup(db, 'variants'), where('sku', '==', sku))
            const snap = await getDocs(q);
            const results = [];
            snap.forEach((doc) => {
                const pathSegments = doc.ref.path.split("/");
                
                if (pathSegments[0] === 'productCatalog') {
                    let temp = doc.data();
                    temp.ref = doc.ref;
                    temp.docId = doc.id;
                    results.push(temp);
                }
            });
            const urls = [];
            for (let i = 0; i < results.length; i++) {
                const result = results[i];
                const { docId } = result;
                urls.push(`/products/${docId}`);   
            }
            const urlItem = urls[0];
            return {urlItem, item}
        } catch (error) {
            console.log(error)
        }
    }
    
    const batchColumns = [
        {
          accessor: 'orderNumber',
          Header: 'Order Number',
        },
        // {
        //     accessor: 'shipTo.name',
        //     Header: 'Address',
        //     Cell: rowData => {
        //         const { shipTo } = rowData.row.original;
        //         return (
        //             <div className='text-700 fs--1'>
        //                 <Row>
        //                     <Col>{shipTo?.name}</Col>
        //                 </Row>
        //                 <Row>
        //                     <Col>{shipTo?.street1}</Col>
        //                 </Row>
        //                 <Row>
        //                     <Col>{shipTo?.city}, {shipTo?.state} {shipTo?.postalCode}</Col>
        //                 </Row>
        //             </div>
                    
        //         )
        //     }
        // },
        {
            accessor: 'items[0].sku',
            Header: 'SKU',
            Cell: rowData => {
                const { items } = rowData.row.original;
                
                const uniqueSkus = [...new Set(items.map(item => item.sku))]
                const uniqueImages = [...new Set(items.map(item => item.imageUrl))]

                return (
                    <>
                        {uniqueSkus.map((sku, index) => {
                            
                                return (
                                    <div key={`${index}sku`}>
                                        <Row>
                                            <Col>
                                                 
                                                <span className='fw-bold' > {sku} </span>
                                                
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            })
                        }
                        {uniqueImages.map((image, index) => {
                                return (
                                    <div key={`${index}img`}>
                                        <Row>
                                            <Col>
                                                <Image src={image} style={{width: 50}} className="mr-2" />
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            })
                        }
                            
                    </>
                )
            }
        },
        {
            accessor: 'items[0].name',
            Header: 'Name & Quantity',
            Cell: rowData => {
                const { items } = rowData.row.original;

                const itemsClone = [...items];
                // I need a function that 
                // 1. takes in an array of items
                // 2. counts the number of unique items
                // 3. returns an array of objects with the name and quantity of each unique item
                const processUniqueItems = (items) => {
                    const uniqueNames = [...new Set(items.map(item => item.name))]
                    const uniqueItemsWithQuantity = [];
                    for (let i = 0; i < items.length; i++) {
                        for (let j = 0; j < uniqueNames.length; j++) {
                            let uniqueName = uniqueNames[j];
                            const uniqueSet = items.filter(item => item.name === uniqueName);
                            const count = uniqueSet.length;
                            let uniqueQuantity = 0;
                            for (let k = 0; k < uniqueSet.length; k++) {
                                uniqueQuantity += uniqueSet[k].quantity;
                            }
                            uniqueItemsWithQuantity.push({uniqueName, count, uniqueQuantity})
                        }
                    }
                    
                    return uniqueItemsWithQuantity;
                }
                const finalItems = processUniqueItems(itemsClone);
                
                return (
                    <>
                        {finalItems?.map((item, index) => {
                           if (index > 0) return;
                           return (
                                <div key={index}>
                                    <Row>
                                        <Col>
                                            <span className='text-700 fs--1'>
                                                {item.uniqueName}
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className='mt-4 text-700 fw-bold fs-0 p-0'>
                                                x {item.uniqueQuantity}
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        })}
                            
                    </>
                    
                )
            }
        },
        {
            accessor: 'items[0].warehouseLocation',
            Header: 'Slot',
            Cell: rowData => {
                const { items } = rowData.row.original;
                const unique = [...new Set(items.map(item => item.warehouseLocation))]
                return (
                    <>
                        {unique.map((item, index) => {
                           return (
                                <div key={index}>
                                    <Row>
                                        <Col>
                                            <span className='text-700 fs--1'>
                                                {item}
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        })}
                            
                    </>
                    
                )
            }
        },
        {
            Header: 'Actions',
            Cell: rowData => {
                const { orderNumber, accountingExclusion } = rowData.row.original;
                return (
                    <>
                        {!accountingExclusion ?
                            <Button
                                size="sm"
                                variant='falcon-danger'
                                className="btn-icon btn-icon-only"
                                onClick={() => {
                                    processOrderExclusion(orderNumber)
                                    .then((order) => {
                                        console.log(order)
                                    })
                                    .catch((error) => {
                                        console.log(error)
                                    })
                                }}
                            >
                                <FontAwesomeIcon icon={['fas', 'times']} /> Remove
                            </Button>
                        :
                        
                                <Button
                                size="sm"
                                variant='falcon-success'
                                className="btn-icon btn-icon-only"
                                onClick={() => {
                                    processOrderExclusion(orderNumber)
                                    .then((order) => {
                                        console.log(order)
                                        
                                    })
                                    .catch((error) => {
                                        console.log(error)
                                    })
                                }}
                            >
                                <FontAwesomeIcon icon={['fas', 'plus']} /> Add
                            </Button>
                            
                        }
                    </>   
                )
            }
        },
        
        
      ];

    async function processOrderExclusion(orderNumber) {
        try {
            const q = query(collection(db, 'orders'), where('orderNumber', '==', orderNumber))
            const orderSnapshot = await getDocs(q);
            const orders = [];
            orderSnapshot.forEach((doc) => {
                let temp = doc.data();
                temp.ref = doc.ref;
                orders.push(temp);
            });
            orders.forEach((order) => {
                const { accountingExclusion } = order;
                let lineItem = enrichedOrders.filter((item) => item.orderNumber === orderNumber)[0];
                lineItem.accountingExclusion = !accountingExclusion;
                const lineItemIndex = enrichedOrders.findIndex((item) => item.orderNumber === orderNumber);
                // replace the item at lineItemIndex in the enrichedOrders array with the new lineItem
                setEnrichedOrders((prev) => [...prev.slice(0, lineItemIndex), lineItem, ...prev.slice(lineItemIndex + 1)])
                
                if (!accountingExclusion) {
                    updateDoc(order.ref, { accountingExclusion: true })
                } else {
                    updateDoc(order.ref, { accountingExclusion: false })
                }
                // updateDoc(order.ref, { accountingExclusion: !order.accountingExclusion })
                toast.success(!accountingExclusion ? "Order Removed": "Order Added to Batch" , {
                    theme: 'colored'
                })
            })
            
            return;
        } catch (error) {
            console.log(error)
            return error.message
        }
    }

    
    return (
            
        <>
            {!batchLoading ?
            <PageHeader
                title={batch.batchName} titleTag="h5" className="mb-3">
                <Button size='sm' className='mt-3' variant='falcon-primary'
                    onClick={() => {navigate(`/batches`)}}>    
                    <FontAwesomeIcon icon={['fas', 'arrow-left']} className="mr-2"/> 
                {"   "} Return to Batches</Button>
            </PageHeader>
            : null}
        
            <Card className="p-3 mb-3">
            {!enrichmentLoading ?
                <AdvanceTableWrapper
                    columns={batchColumns}
                    data={enrichedOrders}
                    sortable
                    pagination
                    perPage={10}
                    >
                        <Row className="flex-end-center mb-3">
                        
                       
                        
                        <Col xs="auto" sm={6} lg={4}>
                            <AdvanceTableSearchBox table/>
                        </Col>
                        </Row>
                        
                <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                    bordered: true,
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                }}
                />
                    <div className="mt-3">
                    <AdvanceTableFooter
                        rowCount={enrichedOrders?.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                    />
                    </div>
                </AdvanceTableWrapper>
                :
                    <div>
                        <Row className="align-items-center">
                        <Col>
                            <Lottie animationData={dotsAnimation} style={{height: 300}} />
                        </Col>
                        </Row>
                    </div>
                }
            </Card>
        </>
                 
            
    )
}

export default BatchDetail;



