/** useUserTabs Hook
 * ---------------------------------------------------------------------------------------------------
 * This hook will retrieve any stored tabs for the user and allow them to be edited, deleted, and saved.
 * The tabs will be stored in a collection in the database. The collection will be named 'tabs' and will
 * have a document for each tab the user has created. The document will have the following structure:
 * 
 
    Path: tabs/{tabId}
    tabModel = {
        id: 'fjf9430inf3qinf',
        name: 'Tab 1',
        skus: ['123456', '123457', '123458'] 
        workspace: 'products' // || 'orders' || 'inventory' || templates
        access: 'private' || 'public'
        users: ['user1', 'user2', 'user3']
        editable: true || false
        timestamp: {
            created: 'timestamp',
            updated: 'timestamp'
        }

        SUBCOLLECTION Path: tabs/{tabId}/comments/{commentId}
           comments: [
                {
                    id: 'fjf9430inf3qinf',
                    user: 'user1',
                    comment: 'This is a comment',
                    timestamp: 'timestamp'
                    channel: 'internal' // 'slack' || 'email' || 'internal'

                }
           ]     
    }

 */
import { useState, useEffect, useContext } from 'react';
import { db } from 'config/firebase';
import { 
    collection, 
    query, 
    where,  
    addDoc, 
    serverTimestamp, 
    doc, 
    deleteDoc, 
    updateDoc,
    onSnapshot,
    orderBy,
    arrayUnion,
    arrayRemove,
    getDocs
} from 'firebase/firestore';
import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';

const useUserTabs = (userId, workspace) => {
    if (!workspace) throw new Error('workspace is required');
    const { user, authLoading }= useContext(FirebaseAuthContext)
    const [tabs, setTabs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(null);
    const [activeTabIndex, setActiveTabIndex] = useState(null);
    const [userData, setUserData] = useState(null);
    const [visibleTabs, setVisibleTabs] = useState([]);  // Array of tab indexes that are visible
    
    useEffect(() => {
        if (authLoading) return;
        if (!user) return;
        getUserData(user.uid).then((data) => {
            setUserData(data);
        }).catch((error) => {
            console.log(error);
        });
    }, [authLoading, user]);

    useEffect(() => {
        if (!userData && !tabs) return;
        onLoad();
    }, [userData, tabs]);


    async function getUserData(userId) {
        const userQuery = query(collection(db, 'users'), where('uid', '==', userId));
        const userSnap = await getDocs(userQuery);
        const userData = [];
        userSnap.forEach((doc) => {
            userData.push({ id: doc.id, ref: doc.ref, ...doc.data() });
        });
        return userData[0];
    }

    async function onLoad() {
        if (!userData) return;
        // sort tabs to put the Search Results tab at the top
        // const sortedTabs = tabs.sort((a, b) => {
        //     if (a.name === 'Search Results') return -1;
        //     if (b.name === 'Search Results') return 1;
        //     return 0;
        // });
        // setTabs(sortedTabs);
        // assign the last tab
        if (userData['product-grid-lastTab']) {
            assignActiveTab(userData['product-grid-lastTab']);
        } else {
            assignActiveTab(tabs[0].id);
            saveLastTab(tabs[0].id);
        }
    }

    async function saveLastTab(tabId) {
        if (!userData) return;
        await updateDoc(userData.ref, { 'product-grid-lastTab': tabId });
    }

    useEffect(() => {
        if (!userId) return;
        const tabCollection = collection(db, 'tabs');
        const userTabsQuery = query(
            tabCollection,
            where('users', 'array-contains', userId),
            where('workspace', '==', workspace),
            orderBy('timestamp.updated', 'desc')
        );
        const unsub = onSnapshot(userTabsQuery, (querySnapshot) => {
           const tabData = [];
           querySnapshot.forEach((doc) => {
               tabData.push({ id: doc.id, ...doc.data(), ref: doc.ref });
           });
           // if tab data is empty, create a default tab
            if (tabData.length === 0) {
                const defaultTab = {
                    name: 'Untitled Workspace',
                    skus: [],
                    workspace: workspace,
                    access: 'private',
                    users: [userId],
                    editable: true,
                    timestamp: {
                        created: serverTimestamp(),
                        updated: serverTimestamp()
                    },
                }
                createTab(defaultTab).then((docRef) => {
                    tabData.push({ id: docRef.id, ...defaultTab, ref: docRef });
                    // reverse the order of tabData
                    tabData.reverse();
                    setTabs(tabData);
                    setLoading(false);
                });
            } else {
                // reverse the order of tabData
                tabData.reverse();
                const sortedTabData = tabData.sort((a, b) => {
                    if (a.name === 'Search Results') return -1;
                    if (b.name === 'Search Results') return 1;
                    return 0;
                });

                const filteredTabs = sortedTabData.filter((tab) => tab.open);
                setVisibleTabs(filteredTabs);
                setTabs(sortedTabData);
                setLoading(false);
            }
        });
        return () => unsub();
    }, [userId]);

    const focusOnSearchTab = (id) => {
        const tab = tabs.find((tab) => tab.id === id);
        if (!tab) return;
        assignActiveTab(id);
    }

    const addProduct = async (tabId, skus) => {
        console.log("add product", tabId, skus)
        if (!skus) return;
        if (skus.length === 1) {
            try {
                const sku = skus[0];
                const tabDoc = doc(db, 'tabs', tabId);
                await updateDoc(tabDoc, {
                    skus: arrayUnion(sku)
                });
            } catch (error) {
                console.log(error.message)
            }
        } else {
            console.log("add multiple products", tabId, skus)
            addProducts(tabId, skus);
        }
    };

    const addProducts = async (tabId, skus) => {
        try {
            const tabDoc = doc(db, 'tabs', tabId);
            await updateDoc(tabDoc, {
                skus: arrayUnion(...skus)
            });
        } catch (error) {
            console.log("addProducts", error.message)
        }
    };

    const removeProduct = async (tabId, sku) => {
        console.log('removeProduct', tabId, sku)
        const tabDoc = doc(db, 'tabs', tabId);
        try {
            await updateDoc(tabDoc, {
                skus: arrayRemove(sku)
            });
        } catch (error) {
            console.log(error)
        }
    };

    function findActiveTab(tabs, activeTabId) {
        if (!tabs) return;
        if (!activeTabId) {
            setActiveTab(tabs[0].id);
            return 0;
        }
        const activeTab = tabs.find(tab => tab.id === activeTabId);
        // return index
        return tabs.indexOf(activeTab);
    }

    const createTab = async (tabData) => {
        setLoading(true);
        const tabsCollection = collection(db, 'tabs');
        tabData.access = 'private';
        tabData.users = [userId];
        tabData.workspace = workspace;
        tabData.editable = true;
        tabData.open = true;
        const ref = await addDoc(tabsCollection, { ...tabData, timestamp: { created: serverTimestamp(), updated: serverTimestamp() } });
        setVisibleTabs((prev) => {
            if (prev) return [{ id: ref.id, ...tabData, ref: ref }, ...prev];
            return [{ id: ref.id, ...tabData, ref: ref }];
        });
        setTabs((prev) => {
            if (prev) return [{ id: ref.id, ...tabData, ref: ref }, ...prev];
            return [{ id: ref.id, ...tabData, ref: ref }];
        });
        setTimeout(() => {
            assignActiveTab(ref.id);
        }, 1500);
        setLoading(false);
        return ref;
    };

    const createSearchTab = async () => {
        setLoading(true);
        const tabsCollection = collection(db, 'tabs');
        const tabData = {};
        tabData.access = 'private';
        tabData.users = [userId];
        tabData.workspace = workspace;
        tabData.editable = false;
        tabData.open = true;
        tabData.name = 'Search Results';
        tabData.bottomBorderColor = '#99D6D8';
        const ref = await addDoc(tabsCollection, { ...tabData, timestamp: { created: serverTimestamp(), updated: serverTimestamp() } });
        setVisibleTabs((prev) => {
            if (prev) return [ ...prev, { id: ref.id, ...tabData, ref: ref }];
            return [{ id: ref.id, ...tabData, ref: ref }];
        });
        setTabs((prev) => {
            if (prev) return [{ id: ref.id, ...tabData, ref: ref }, ...prev];
            return [{ id: ref.id, ...tabData, ref: ref }];
        });
        setTimeout(() => {
            assignActiveTab(ref.id);
        }, 1500);
        setLoading(false);
        return ref;
    };

    const assignActiveTab = (tabId) => {
        setActiveTab(tabId);
        const activeIndex = findActiveTab(visibleTabs, tabId);
        setActiveTabIndex(activeIndex);
        saveLastTab(tabId);
    }

    const updateName = async (tabId, updatedName) => {
        const tabDoc = doc(db, 'tabs', tabId);
        await updateDoc(tabDoc, { name: updatedName, timestamp: { updated: serverTimestamp() } });
    };

    const deleteTab = async (tabId) => {
        const tabDoc = doc(db, 'tabs', tabId);
        await deleteDoc(tabDoc);
    };

    const closeTab = async (tabId) => {
        const tabDoc = doc(db, 'tabs', tabId);
        await updateDoc(tabDoc, { open: false });
    }

    const openTab = async (tabId) => {
        const tabDoc = doc(db, 'tabs', tabId);
        await updateDoc(tabDoc, { open: true });
    }

    // const updateSkusForActiveTab = debounce((skuList) => {

    //     // Only update the SKU list if it has changed
    //     if (!arraysAreEqual(mergedSkus, skuList)) {
    //         setSkuList(mergedSkus);
    //     }
    // }, 500);

    const selectNextTab = () => {
        if (!visibleTabs) return;
        if (activeTabIndex === visibleTabs.length - 1) {
            assignActiveTab(visibleTabs[0].id);
        } else {
            assignActiveTab(visibleTabs[activeTabIndex + 1].id);
        }
    }

    const selectPreviousTab = () => {
        if (!visibleTabs) return;
        if (activeTabIndex === 0) {
            assignActiveTab(visibleTabs[visibleTabs.length - 1].id);
        } else {
            assignActiveTab(visibleTabs[activeTabIndex - 1].id);
        }
    }

    const tabController = {
        createTab,
        createSearchTab,
        updateName,
        addProduct,
        removeProduct,
        deleteTab,
        closeTab,
        openTab,
        assignActiveTab,
        onLoad,
        focusOnSearchTab,
        selectNextTab,
        selectPreviousTab,
        activeTab,
        activeTabIndex
    };

    return {
        tabs,
        visibleTabs,
        loading,
        tabController
    };
};

export default useUserTabs;
    